import { Picker } from '@react-native-picker/picker'
import React, { useMemo, useState } from 'react'
import { Platform, Pressable, StyleProp, Text, TextInput, TextStyle, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Modal from 'react-native-modal'
import FlatChoose from '../FlatChoose'
import getTheme from '../../constants/Colors'
import { t } from 'i18n-js'

interface Props<T> {
  selectedValue?: T
  style?: StyleProp<TextStyle>
  outStyle?: StyleProp<TextStyle>
  accessibilityLabel?: string
  placeholder?: string
  onValueChange?: (item: T, index: number) => void
  itemToKey: (item: T) => string
  itemToLabel: (item: T) => string
  options: T[]
  showLabelAsOption?: boolean
}

function NativeModalPicker<T>(props: Props<T>) {

  const theme = getTheme()

  const [search, setSearch] = useState<string>('')
  const _clearSearch = () => setSearch('')

  const [open, setOpen] = useState<boolean>(false)
  const _open = () => setOpen(true)
  const _close = () => setOpen(false)

  const children = useMemo(() => {
    let children: JSX.Element[] = []
    if (props.showLabelAsOption) {
      children.push(
        <Picker.Item
          enabled={false}
          label={props.placeholder ? t(props.placeholder) : undefined}
          value='-1'
          key='-1'
        />
      )
    }
    children.push(
      ...props
        .options
        .map((item, index) => (
          <Picker.Item
            key={`${props.itemToKey(item)}-${index}`}
            label={props.itemToLabel(item)}
            value={props.itemToKey(item)}
          />
        ))
  )
    return children
  }, [props.options, search])

  const options = useMemo(() => {
    if (search == '') {
      return props.options
    }
    const s = search.toLowerCase().trim()
    return props.options.filter(
      item => props
          .itemToLabel(item)
          .trim()
          .toLowerCase()
          .includes(s)
    )
  }, [props.options, search])

  const _changeValuePicker = (key: string) => {
    const item = props.options.find(v => props.itemToKey(v) == key)
    if (item) {
      const index = props.options.findIndex(v => v == item)
      props.onValueChange && props.onValueChange(item, index)
      setOpen(false)
    }
  }


  if (Platform.OS != 'ios' && Platform.OS != 'android') {
    let selectedValue
    if (props.selectedValue) {
      selectedValue = props.itemToKey(props.selectedValue)
    } else {
      if (props.showLabelAsOption) {
        selectedValue = '-1'
      }
    }
    return (
      <Picker
        selectedValue={selectedValue}
        style={props.style}
        accessibilityLabel={props.accessibilityLabel}
        placeholder={props.placeholder}
        onValueChange={_changeValuePicker}
      >
        {children}
      </Picker>
    )
  }

  const _changeValue = (item: T) => {
    if (props.onValueChange) {
      const index = props.options.findIndex(v => v == item)
      props.onValueChange(item, index)
      setOpen(false)
    }
  }

  return (
    <>
      <Pressable
        style={[{
          padding: 10,
          flexDirection: 'row',
          backgroundColor: 'white',
          borderColor: 'black',
          borderWidth: 1,
          borderRadius: 5,
        }, props.outStyle]}
        onPress={_open}
      >
        <Text style={{paddingRight: 15}}>
          {props.selectedValue ? props.itemToLabel(props.selectedValue) : '-'}
        </Text>

        <MaterialCommunityIcons
          name='chevron-down'
          size={14}
          color='black'
        />
      </Pressable>

      {open && (
        <Modal
          isVisible={open}
          onBackdropPress={_close}
          style={{
            borderRadius: 15,
            backgroundColor: 'white',
            padding: 15,
          }}
        >
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Text style={{fontSize: 24, fontWeight: 'bold'}}>
              {props.placeholder && t(props.placeholder)}
            </Text>
            <MaterialCommunityIcons
              name='close'
              size={34}
              style={{paddingLeft: 10}}
              onPress={_close}
            />
          </View>
          <View style={{flex: 1, marginTop: 10}}>
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 5,
              backgroundColor: theme.background,
            }}>
              <TextInput
                onChangeText={setSearch}
                value={search}
                style={{
                  flex: 1,
                  padding: 10,
                  fontSize: 16,
                }}
                placeholderTextColor={theme.text}
                placeholder={t('SEARCH')}
              />
              <MaterialCommunityIcons
                name='close'
                size={20}
                style={{padding: 10}}
                onPress={_clearSearch}
              />
            </View>
            <View style={{paddingHorizontal: 10}}>
              <FlatChoose
                options={options}
                value={props.selectedValue}
                onChange={_changeValue}
                itemToKey={props.itemToKey}
                itemToString={props.itemToLabel}
                size='large'
              />
            </View>
          </View>
        </Modal>
      )}
    </>
  )
}

export default NativeModalPicker