import React from 'react'
import { DayReport } from '../../../models'
import { Text, View } from 'react-native'
import { t } from 'i18n-js'
import { FlatList } from 'react-native-gesture-handler'
import getTheme from '../../../constants/Colors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import DayReportContact from './DayReportContact'

interface DayReportNotesProps {
  dayReport: DayReport
  closeNotes: () => void
}

const DayReportNotes = ({ dayReport, closeNotes }: DayReportNotesProps) => {

  const theme = getTheme()

  return (
    <View style={{paddingBottom: 10, backgroundColor: theme.background, marginTop: 10}}>
      <View style={{flexDirection: 'row', alignItems: 'center', padding: 5}}>
        <Text style={{fontSize: 22, flex: 1}}>{t('NOTES')}</Text>
        <View style={{width: 5}} />
        <View style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Text style={{fontSize: 22, paddingRight: 5}}>{t('CONTACTS')}</Text>
          <MaterialCommunityIcons
            name='close'
            size={24}
            color='black'
            onPress={closeNotes}
          />
        </View>
      </View>
      <View style={{flexDirection: 'row', paddingHorizontal: 5}}>
        <Text style={{flex: 1, fontSize: 20}}>{dayReport.OrderNotes}</Text>
        <View style={{width: 5}} />
        <View style={{flex: 1}}>
          <FlatList
            data={dayReport.DocumentContacts}
            renderItem={({item}) => (
              <DayReportContact
                documentContact={item}
              />
            )}
          />
        </View>
      </View>
    </View>
  )
}

export default DayReportNotes