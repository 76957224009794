import {
  GET_BASIC_DATA_SUCCESS,
  GET_CATALOGS_SUCCESS,
  LOGOUT,
} from "../types";
import {
  UoM,
  VAT,
  Catalog,
  Branch,
  Currency,
  Language,
  ReadLevel,
  Resource,
  AlertEmail,
  Country,
  Seller,
  Industry,
  AbsenceType,
  WorkGroup,
} from "../../models";

export type general = {
  catalogs: Catalog[];
  branches: Branch[];
  currencies: Currency[];
  languages: Language[];
  uoms: UoM[];
  vats: VAT[];
  readLevels: ReadLevel[];
  countries: Country[];
  resource: Resource | null;
  alertsEmail: AlertEmail[];
  sellers: Seller[];
  industries: Industry[];
  absenceTypes: AbsenceType[]
  resources: Resource[]
  workGroups: WorkGroup[]
};

const initialState: general = {
  catalogs: [],
  branches: [],
  currencies: [],
  languages: [],
  uoms: [],
  readLevels: [],
  vats: [],
  resource: null,
  alertsEmail: [],
  countries: [],
  sellers: [],
  industries: [],
  absenceTypes: [],
  resources: [],
  workGroups: [],
};

export default function(
  state = initialState,
  action: {type: string; payload: any},
): general {
  switch(action.type) {
    case GET_BASIC_DATA_SUCCESS:
      return {
        ...state,
        branches: action.payload.branches,
        currencies: action.payload.currencies,
        languages: action.payload.languages,
        uoms: action.payload.uoms,
        vats: action.payload.vats,
        readLevels: action.payload.readLevels,
        resource: action.payload.resource,
        alertsEmail: action.payload.alertsEmail,
        countries: action.payload.countries,
        sellers: action.payload.sellers,
        industries: action.payload.industries,
        absenceTypes: action.payload.absenceTypes,
        resources: action.payload.resources,
        workGroups: action.payload.workGroups,
      };
    case GET_CATALOGS_SUCCESS:
      return {
        ...state,
        catalogs: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}