import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { t } from "i18n-js";
import { Contract, Doc } from "../../models";
import CreateDocButton from "./CreateDocButton";
import LoadPdfButton from "../LoadPdfButton";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import ContractFinancialOverview from "./ContractFinancialOverview";

interface Props {
  contract: Contract;
}

const ContractOverview = ({contract}: Props) => {

  const workSpace = useSelector((state:Reducer) => state.auth.workSpace!);
  const token = useSelector((state:Reducer) => state.auth.token);
  const sales = useSelector((state: Reducer) => state.user.permissions.sales);

  const [order, setOrder] = useState<Doc>();

  useEffect(() => {
    setOrder(contract.Docs.find(d => d.Type.Config.ConstructionSiteViewGroup === 'order'));
  }, [contract, contract.Docs]);

  let warrantyStatus;
  let warrantyExpire;
  if (contract.WarrantyExpire) {
    if (contract.WarrantyExpire > new Date()) {
      warrantyStatus = "active";
    } else {
      warrantyStatus = "Expired";
    }
    warrantyExpire = contract.WarrantyExpire.toLocaleDateString();
  } else {
    warrantyStatus = "No warranty";
    warrantyExpire = "-";
  }

  if (!sales) {
    return (
      <></>
    );
  }

  return (
    <View style={{flex: 1, width: "100%", padding: 1}}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={{flex: 1}}>{t("WARRANTY_STATUS")}:</Text>
        <Text style={{flex: 1}}>{warrantyStatus}</Text>
        <Text style={{flex: 1}}>{t("EXPIRE")}:</Text>
        <Text style={{flex: 1}}>{warrantyExpire}</Text>
      </View>

      <View style={{paddingVertical: 2}}>
        <CreateDocButton contract={contract} />
      </View>

      {order && (
        <>
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 2,
              justifyContent: 'space-between',
            }}
          >
            <LoadPdfButton
              buttonTitleT='MARGINALITY'
              modalTitle={`${t("MARGINALITY")} ${order.Type.Name} ${order.IDString}`}
              url={`${workSpace.url}/getMarginality`}
              params={[
                {key: "documentType", value: `${order.Type.ID}`},
                {key: "documentEntry", value: `${order.ID}`},
                {key: "token", value: token},
              ]}
            />
            <LoadPdfButton buttonTitle={t("MATERIALS_LIST")}
              modalTitle={`${t("MATERIALS_LIST")} ${order.Type.Name} ${order.IDString}`}
              url={`${workSpace.url}/getMaterialsListPDF`}
              params={[
                {key: "documentType", value: `${order.Type.ID}`},
                {key: "documentEntry", value: `${order.ID}`},
                {key: "token", value: token},
              ]} />
            <LoadPdfButton buttonTitle={t("HOURS_LIST")}
              modalTitle={`${t("HOURS_LIST")} ${order.Type.Name} ${order.IDString}`}
              url={`${workSpace.url}/getHoursListPDF`}
              params={[
                {key: "documentType", value: `${order.Type.ID}`},
                {key: "documentEntry", value: `${order.ID}`},
                {key: "token", value: token},
              ]} />
            <LoadPdfButton buttonTitle={t("HOURS_LIST_BUDGET_EFFECTIVE")}
              modalTitle={`${t("HOURS_LIST_BUDGET_EFFECTIVE")} ${order.Type.Name} ${order.IDString}`}
              url={`${workSpace.secondaryUrl}contract-hours-list`}
              params={[
                {key: "contractId", value: `${order.ContractID}`},
                {key: "token", value: token},
                {key: 'workspace', value: workSpace.name},
              ]}
            />
              <LoadPdfButton buttonTitle={t("BILLS_OF_MATERIALS")}
                modalTitle={`${t("BILLS_OF_MATERIALS")} ${order.Type.Name} ${order.IDString}`}
                url={`${workSpace.url}/getDocumentBillsOfMaterialsPDF`}
                params={[
                  {key: "documentType", value: `${order.Type.ID}`},
                  {key: "documentEntry", value: `${order.ID}`},
                  {key: "token", value: token},
                ]} />
          </View>
          <ContractFinancialOverview
            order={order}
            contract={contract}
          />
        </>
      )}
    </View>
  );
};

export default ContractOverview;