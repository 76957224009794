import axios from "axios";
import { Buffer } from "buffer"

const uploadFileURL = (fileTypeId: number, name: string, readLevelId: number, referredId: number): string => {
  if (name.length < 4 || name.substring(name.length - 4).toLowerCase() != ".pdf") {
    name += ".pdf";
  }
  return `uploadFile?name=${name}.pdf&fileTypeId=${fileTypeId}&readLevelId=${readLevelId}&referedObject=contract&referedId=${referredId}`;
}
export const uploadFile = async (uri: string, fileTypeId: number, name: string, readLevelId: number, referredId: number): Promise<void> => {
  const response = await fetch(uri);
  const blob = await response.blob();
  const res = await axios.post(
    uploadFileURL(fileTypeId, name, readLevelId, referredId),
    blob,
    {
      headers: {
        "Content-Type": "application/pdf"
      }
    }
  );

  if (res.status !== 200) {
    throw res;
  }

  console.log(res.data);
};

export const uploadSign = async (png: string):Promise<string> => {
  const url = `/uploadFile?referedObject=sign&name=sign.png`;
  const binary = Buffer.from(png.replace("data:image/png;base64,", ""), 'base64')
  const res = await axios.post<{url:string}>(
    url,
    binary,
    {
      headers: {
        "Content-Type": "image/png",
      }
    }
  )

  if (res.status !== 200) {
    throw res
  }

  return res.data.url
}

const updateFileDataURL = (fileId: number, fileTypeId: number, name: string, readLevelId: number, referredId: number): string => {
  return `updateFile?name=${name}.pdf&fileTypeId=${fileTypeId}&readLevelId=${readLevelId}&referedObject=contract&referedId=${referredId}&id=${fileId}`;
}

export const updateFileData = async (fileId: number, fileTypeId: number, name: string, readLevelId: number, referredId: number): Promise<void> => {
  const res = await axios.post(
    updateFileDataURL(fileId, fileTypeId, name, readLevelId, referredId),
  );

  if (res.status !== 200) {
    throw res;
  }
};