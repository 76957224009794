import axios from "axios";
import { WorkSpace } from "../models";

export const login = async (
  workspace: WorkSpace,
  username: string,
  password: string,
  uuid: string,
): Promise<{ data: {token: string; expire: string }}> => {
  return axios.post(workspace.url + "auth/login", {username, password, uuid})
}

export const logout = async (): Promise<{data:{message:string}}> => {
  return axios.get("auth/logout");
};
