import React, { useEffect, useState } from "react";
import { Contract, DayReport } from "../../../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import { getStringFromTime } from "../../../../utils/StringUtils";
import { t } from "i18n-js";
import getTheme from "../../../../constants/Colors";
import { FlatList, Switch, Pressable, Text, View, ActivityIndicator } from "react-native";
import { alert } from "../../../../hooks/Alert";
import { useNavigation } from "@react-navigation/native";
import { SearchScreens } from "../../../search/SearchScreens";
import DayReportPreviewModal from "../../../../components/DayReportPreviewModal";
import { round } from '../../../../shared-utils/NumberUtils'
import Button from "../../../../components/Button";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface Props {
  contract: Contract;
  pull: () => void
}

const DayReportsView = ({contract, pull}:Props) => {

  const theme = getTheme();
  const navigation = useNavigation();

  const reportCreate = useSelector((state:Reducer) => state.user.permissions.reportCreate);
  const reportUpdate = useSelector((state:Reducer) => state.user.permissions.reportUpdate);
  const reportApprove = useSelector((state:Reducer) => state.user.permissions.reportApprove);

  const [previewModalDayReportId, setPreviewModalDayReportID] = useState<number>();
  const [filterToApprove, setFilterToApprove] = useState<boolean>(false);

  const _closePreviewModal = () => setPreviewModalDayReportID(undefined);

  const resource = useSelector((state:Reducer) => state.general.resource);

  const [filteredDayReports, setFilteredDayReports] = useState<DayReport[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const filter = () => {
    const filtered = [];

    if (reportCreate == 0 || resource == null) {
      return;
    }

    for (let i = 0; i < contract.Reports.length; i++) {
      const d = contract.Reports[i];
      if (filterToApprove && d.ApprovedBy) {
        continue;
      }
      if (reportCreate > 2) {
        filtered.push(d);
      } else if (reportCreate == 1 && resource.ID === d.ResourceID) {
        filtered.push(d);
      }
    }

    setFilteredDayReports(filtered.sort((a: DayReport, b: DayReport) => Number(b.DateTime) - Number(a.DateTime)));
  };


  useEffect(() => {
    setLoading(true);
    filter();
    setLoading(false);
  }, [contract, filterToApprove]);

  const _render = ({item, index}: {item: DayReport, index: number}) => {

    const _press = () => {
      if (item.ApprovedBy) {
        if (resource?.ID === item.ResourceID || reportUpdate > 1 || reportApprove > 0) {
          setPreviewModalDayReportID(item.ID);
        }else {
          alert("NOT_ALLOWED", "NOT_ALLOWED").then();
        }
      } else {
        if (reportApprove > 1) {
          if (!contract.Measure) {
            // @ts-ignore
            navigation.navigate(SearchScreens.REPORT_APPROVE, {
              dayReportId: item.ID,
            });
          } else {
            setPreviewModalDayReportID(item.ID)
          }
        } else if (reportUpdate > 1) {
          // @ts-ignore
          navigation.navigate(SearchScreens.REPORT_UPDATE, {
            dayReportId: item.ID,
          });
        } else if (resource?.ID === item.ResourceID) {
          if (reportUpdate == 1) {
            // @ts-ignore
            navigation.navigate(SearchScreens.REPORT_UPDATE, {
              dayReportId: item.ID,
            });
          } else {
            setPreviewModalDayReportID(item.ID);
          }
        } else {
          alert("NOT_ALLOWED", "NOT_ALLOWED").then();
        }
      }
    };

    const date = new Date(item.DateTime);

    return (
      <Pressable
        style={{
          flexDirection: 'row',
          paddingHorizontal: 6,
          paddingVertical: 9,
          alignItems: 'center',
        }}
        onPress={_press}
        key={item.ID}
      >
        <Text style={{flex: 1}}>{item.ID}</Text>
        <Text style={{flex: 1.5}}>{item.Resource.FirstName} {item.Resource.LastName}</Text>
        <Text style={{flex: 1.25}}>
          {date.toLocaleDateString()} {date.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})}
        </Text>
        <Text style={{flex: 1.25, textAlign: 'center'}}>{getStringFromTime(round(item.TotalTime + item.TravelTime - item.BreakTime, 0))}</Text>
        <Text style={{flex: 1}}>{item.ApprovedBy ? t("APPROVED") : t("TO_BE_APPROVED")}</Text>
      </Pressable>
    );
  }

  const separator = () => <View style={{borderTopWidth: 1, borderTopColor: theme.border}}/>;

  return (
    <View style={{padding: 5, flex: 1}}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text style={{flex: 1, fontSize: 24}}>{t("DAY_REPORTS")}</Text>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={{paddingRight: 5}}>{t("FILTER_TO_APPROVE")}</Text>
          <Switch value={filterToApprove} onValueChange={setFilterToApprove}/>
          <Button
            style={{backgroundColor: theme.blue, marginLeft: 15}}
            onPress={() => {
              // @ts-ignore
              navigation.navigate(SearchScreens.REPORT_CREATE, {
                contractId: contract.ID,
              });
            }}
          >
            <MaterialCommunityIcons name='plus' size={24} color='white'/>
          </Button>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 6,
          marginVertical: 9,
          alignItems: 'center',
        }}
      >
        <View style={{flex: 1}}/>
        <Text style={{flex: 1.5}}>{t("RESOURCE")}</Text>
        <Text style={{flex: 1.25}}>{t("DATE")}</Text>
        <Text style={{flex: 1.25, textAlign: 'center'}}>{t("REPORT_HOURS")}</Text>
        <Text style={{flex: 1}}>{t("STATUS")}</Text>
      </View>

      <View 
        style={{
          backgroundColor: 'white',
          borderRadius: 5,
          flex: 1,
        }}
      >
        {
          loading ? <ActivityIndicator /> :
             filteredDayReports != null && filteredDayReports.length > 0 ? (
              <FlatList
                data={filteredDayReports}
                renderItem={_render}
                ItemSeparatorComponent={separator}
                style={{flex: 1}}
                key={`filtered-day-reports-${filteredDayReports.length}`}
                // @ts-ignore
                listKey={`filtered-day-reports-${filteredDayReports.length}`}
                keyExtractor={(item, index) => `filtered-day-reports-${index}-${item.ID}`}
              />
            ) : (
              <Text 
                style={{
                  textAlign: 'center',
                  paddingTop: 3,
                }}
              >
                {t(filterToApprove?"NO_WORK_REPORTS_TO_APPROVE":"NO_WORK_REPORTS")}
              </Text>
            ) 
        }
      </View>
      {previewModalDayReportId && <DayReportPreviewModal dayReportId={previewModalDayReportId}
                                                         visible={true}
                                                         pull={pull}
                                                         close={_closePreviewModal}/>}
    </View>
  );
};

export default DayReportsView;