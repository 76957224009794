import React from "react";
import { FlatList, Modal, StyleSheet, View, Text } from "react-native";
import { t } from "i18n-js";
import VersionItem from "./VersionItem";
import { ConstructionSite, Contract, Doc, Version } from "../../models";
import { DocumentVersionModalConfig } from "./DocumentVersionModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface Props {
  setDocumentVersionModalConfig: (config: DocumentVersionModalConfig) => void;
  doc?: Doc;
  onClose: () => void
  contract: Contract;
  constructionSite: ConstructionSite
}

const VersionsModal = ({
  contract,
  setDocumentVersionModalConfig,
  doc,
  onClose,
  constructionSite,
}: Props) => {

  const _onPress = (version: Version) => {
    setDocumentVersionModalConfig({
      visible: true,
      docID: version.ID,
      docType: doc?.Type,
      contract,
      constructionSite,
    });
  }

  const _renderItem = ({item, index}: {item: Version, index:number}) => {
    return <VersionItem item={item} onPress={_onPress} key={index}/>;
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={onClose}
    >
      <View style={styles.modal_container}>
        <View style={styles.modal}>
          <View 
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 300,
            }}
          >
            <Text style={styles.modal_title}>{t("SELECT_VERSION")}</Text>
            <MaterialCommunityIcons
              name="close"
              onPress={onClose}
              size={24}
            />
          </View>
          {doc?.Versions ?
            <>
              <FlatList
                data={doc.Versions}
                renderItem={_renderItem}
                style={{maxHeight: 300}}
                key={`versions`}
                // @ts-ignore
                listKey={`versions-${doc.ID}-${doc.Versions?.length}`}
                keyExtractor={(item, index) => `version-${doc.ID}-${index}-${item.ID}`}
              />
            </> :
            <Text>{t("NO_EARLIER_VERSION_AVAILABLE")}</Text>
          }
        </View>
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modal_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(128, 128, 128, 0.7)",
  },
  modal: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 50,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modal_select: {
    width: "80%",
    backgroundColor: "white",
  },
  modal_title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  modal_button: {
    marginTop: 10,
  },
});

export default VersionsModal;
