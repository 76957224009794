import React, { useMemo, useState } from 'react'
import { ConstructionSite, Contract, ContractPlanned } from '../../models'
import Modal from 'react-native-modal'
import { ActivityIndicator, Text, View, FlatList, TextInput } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import getTheme from '../../constants/Colors'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import ConstructionSiteItem from '../ConstructionSiteItem'
import { DocStatus } from '../../constants/Status'
import { getActivities } from '../../api/ReportAPI'
import { alert } from '../../hooks/Alert'

interface ChooseOpenContractModalProps {
  visible: boolean
  close: () => void
  onSelect: (choosenContract: ContractPlanned) => void
}

const ChooseOpenContractModal = ({visible, close, onSelect}: ChooseOpenContractModalProps) => {

  const theme = getTheme()

  const constructionSites = useSelector((state: Reducer) => state.constructionSite.constructionSites)
  const constructionSitesLoading = useSelector(
    (state: Reducer) => state.sync.constructionSitesLoading
  )

  const [search, setSearch] = useState<string>("")

  const constructionSitesFiltered = useMemo(() => {
    const s = search.trim().toLowerCase()
    return constructionSites.filter(cs => {
      return (
        cs.Name.toLowerCase().includes(s) ||
        cs.Code.toLowerCase().includes(s) ||
        cs.IDString.toLowerCase().includes(s) ||
        cs.Address?.toLowerCase().includes(s)
      ) && cs.Contracts.filter(c => {
        switch (Number(c.Status)) {
          case DocStatus.OPEN.code:
          case DocStatus.IN_EXECUTION.code:
          case DocStatus.TO_BE_APPROVED.code:
          case DocStatus.TO_BE_BILLED.code:
            return true
          default:
            return false
        }
      }).length > 0
    })
  }, [search, constructionSites])

  const _render = ({item, index}: {item: ConstructionSite, index: number}) => {
    const _select = async (contract: Contract) => {
      try {
        const activities = await getActivities(contract.ID)
        onSelect({
          ...contract,
          ...activities,
          constructionSite: item,
        })
      } catch (e) {
        alert('ERROR', 'NOT_VALID_CONTRACT')
      } finally {
        close()
      }
    }
    return (
      <ConstructionSiteItem
        constructionSite={item}
        onSelectContract={_select}
        key={`construction-site-${item.ID}-${index}`}
      />
    )
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: "row", alignItems: 'center'}}>
      <MaterialCommunityIcons name="close" size={34} onPress={close} />
        <Text style={{fontSize: 25, flex: 1, paddingLeft: 5}}>
          {t("CHOOSE_CONSTRUCTION_SITE")}
        </Text>
      </View>

      <TextInput
        style={{
          backgroundColor: 'white',
          padding: 5,
          borderColor: theme.border,
          borderWidth: 1,
          borderRadius: 5,
          marginVertical: 10,
          fontSize: 18,
          paddingVertical: 10,
        }}
        placeholder={t("SEARCH")}
        onChangeText={setSearch}
      />

      {constructionSitesLoading && <ActivityIndicator />}
      <View style={{flex: 1}}>
        <FlatList
          data={constructionSitesFiltered}
          renderItem={_render}
        />
      </View>
    </Modal>
  )
}

export default ChooseOpenContractModal