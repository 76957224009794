import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DayReport, ExternalWorkInvoice, LineType, ReportClientConfirmation } from '../../../models'
import { Image, Platform, StyleSheet, Text, TextInput, View } from 'react-native'
import DatePicker from '../../../components/DatePicker'
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons'
import Button from '../../../components/Button'
import { t } from 'i18n-js'
import { useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import getTheme from '../../../constants/Colors'
import { uploadSign } from '../../../api/FileAPI'
import { sentryCapture } from '../../../utils/sentryCapture'
import { alert } from '../../../hooks/Alert'
import SigScreen from '../../../components/SigScreen'
import Modal from 'react-native-modal'
import { SignatureViewRef } from 'react-native-signature-canvas'
import { checkIfCanSplitDayReport } from '../../../utils/reports/report-utils'
import { getContractById } from '../../../api/ConstructionSitesAPI'
import { FlatList } from 'react-native-gesture-handler'
import FlatChoose from '../../../components/FlatChoose'

interface DayReportSettingsProps {
  dayReport: DayReport
  setDayReport: (f: (dayReport: DayReport) => DayReport) => void
  mode: number
  saveAndSplit: () => void
}

const webView = `body, html { width: 650px; height: 300px; }   
   .m-signature-pad--footer .button {
        background-color: #ea580c;
        color: #FFF;
  }`

const DayReportSettings = ({ dayReport, setDayReport, mode, saveAndSplit }: DayReportSettingsProps) => {

  const os = Platform.OS
  const theme = getTheme()

  const reportClientConfirmation = useSelector((state: Reducer) => state.user.permissions.reportClientConfirmation)

  const [externalWorkInvoices, setExternalWorkInvoices] =
      useState<ExternalWorkInvoice[]>([])
  const [signatureVisible, setSignatureVisible] = useState(false)
  
  const canSplitDayReport = useMemo(
    () => checkIfCanSplitDayReport(dayReport, mode),
    [mode, dayReport],
  )

  const hasExternalWork = useMemo(() => {
    return dayReport.Reports?.some(r => {
      if (r.Type == LineType.POSITION_EXTERNAL_WORK) {
        return r.Levels?.some(l => l.Quantity > 0) || false
      }
      return false
    })
  }, [dayReport.Reports])

  useEffect(() => {
    getContractById(dayReport.ContractID).then(contract => {
      setExternalWorkInvoices(
        contract
            .ExternalWorkInvoices
            .filter(ewi => ewi.DayReportId == undefined)
      )
    }).catch(e => {
      sentryCapture(e);
      alert("ERROR", "ERROR_LOADING_CONTRACT");
    })
  }, [hasExternalWork, dayReport.ContractID])

  const ref = useRef<SignatureViewRef>(null)

  const _notes = (text: string) => setDayReport(d => ({...d, Notes: text}))

  const _signedBy = (signedBy: string) => setDayReport(d => ({...d, SignedBy: signedBy}))

  const _signedTime = (signedTime: Date | undefined) => {
    if (signedTime) {
      setDayReport(d => {
        return {
          ...d,
          SignedTime: signedTime,
        }
      })
    }
  }

  const _signedPlace = (place: string) => setDayReport(d => ({...d, SignedPlace: place}))

  const _clearSignature = () => setDayReport(d => ({...d, Signature: undefined}))

  const _signatureVisible = () => setSignatureVisible(true)
  const _closeSignature = () => setSignatureVisible(false)
  
  const _signature = (signature: string) => {
    uploadSign(signature).then(s => {
      setDayReport(d => {
        return {
          ...d,
          Sign: s,
        }
      })
    }).catch(e => {
      sentryCapture(e);
      alert("ERROR", "ERROR_UPLOADING_SIGNATURE");
    })

    _closeSignature()
  }

  const externalWorkInvoiceToString = (item: ExternalWorkInvoice) => {
    return `${item.IDString} - ${item.Client.Name} - ${item.Date.toLocaleDateString()} - ${item.ClientReference}`
  }

  const setExternalWorkInvoice = (externalWorkInvoice?: ExternalWorkInvoice) => {
    setDayReport(d => ({
      ...d,
      ExternalWorkInvoiceId: externalWorkInvoice?.ID,
      ExternalWorkInvoice: externalWorkInvoice,
    }))
  }

  const clearExternalWorkInvoice = () => {
    setExternalWorkInvoice(undefined)
  }

  return (
    <View style={{paddingVertical: 10, backgroundColor: theme.background, marginTop: 10}}>
      {os != "web" && (
        <Modal
          isVisible={signatureVisible}
          onBackdropPress={_closeSignature}
          style={{
            backgroundColor: 'white',
            padding: 15,

          }}
        >
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={{fontSize: 34}}>{t("SIGN")}</Text>
            <MaterialCommunityIcons
              name="close"
              onPress={_closeSignature}
              size={24}
            />
          </View>
          <View style={{width: 1200, height: 500, justifyContent: 'center'}}>
            <SigScreen
              onOK={_signature}
              webStyle={webView}
              ref={ref}
              autoClear={true}
              clearText={t("CLEAR")}
              confirmText={t("CONFIRM")}
              descriptionText={t("SIGN_ABOVE")}
            />
          </View>
        </Modal>
      )}
      <Text style={[styles.label, {
        paddingLeft: 17,
        paddingRight: 10,
      }]}>{t("NOTES")}:</Text>
      <TextInput
        style={[styles.inputs, {
          marginTop: 10,
          minHeight: 100,
          marginHorizontal: 10
        }]}
        value={dayReport?.Notes || ""}
        onChangeText={_notes}
        multiline={true}
      />

      {reportClientConfirmation != ReportClientConfirmation.HIDE && (
        <View>
          <View style={{backgroundColor: 'white', marginTop: 10, paddingTop: 10}}/>
          <View style={{flexDirection: 'row', backgroundColor: theme.background, padding: 10}}>
            <View style={{flex: 1, paddingRight: 5}}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={styles.label}>{t("SIGNED_BY")}:</Text>
                <TextInput
                  style={styles.inputs}
                  value={dayReport?.SignedBy}
                  onChangeText={_signedBy}
                />
              </View>
              <View style={{flexDirection: 'row', alignItems: 'center', marginVertical: 10}}>
                <Text style={styles.label}>{t("DATE")}:</Text>
                <View style={{flex: 1, alignItems: "center"}}>
                  <DatePicker value={dayReport?.SignedTime || new Date()}
                              onChange={_signedTime}
                              style={{width: 100}}/>
                </View>
              </View>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={styles.label}>{t("PLACE")}:</Text>
                <TextInput
                  style={styles.inputs}
                  value={dayReport?.SignedPlace}
                  onChangeText={_signedPlace}
                />
              </View>
            </View>
            <View style={{flexDirection: 'row', flex: 1, paddingLeft: 5}}>
              <Text style={{marginHorizontal: 2}}>{t("SIGNATURE")}:</Text>

              {dayReport?.Signature ? (
                <View style={{flex: 1, height: 110, position: 'relative'}}>
                  <MaterialCommunityIcons
                    style={styles.signatureIconClose}
                    onPress={_clearSignature}
                    name="close"
                    size={24}
                  />
                  <Image source={{uri: dayReport?.Signature}}
                        style={styles.signature}/>
                </View>
              ) : os != "web" ? (
                <Button
                  style={{
                    flex: 1,
                    backgroundColor: theme.secondaryBackground,
                  }}
                  onPress={_signatureVisible}
                  leftComponent={
                    <FontAwesome5 name="signature" size={11} color='white'/>
                  }
                  titleT='CLICK_HERE_TO_SIGN'
                />
              ) : (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                  <Text>{t("SIGNATURE_NOT_AVAILABLE")}</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      )}

      {hasExternalWork && (
        <>
          <Text style={{fontWeight: 'bold', padding: 10}}>
            {t('CHOOSE_EXTERNAL_WORK_INVOICE')}
          </Text>
          <FlatChoose
            options={externalWorkInvoices}
            itemToKey={item => item.ID.toString()}
            itemToString={externalWorkInvoiceToString}
            onChange={setExternalWorkInvoice}
            value={dayReport.ExternalWorkInvoice}
            size='small'
          />
        </>
      )}

      {dayReport.ExternalWorkInvoice && (
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
        }}>
          <Text style={{fontWeight: 'bold'}}>
            {t('EXTERNAL_WORK_INVOICE')}:
          </Text>
          <Text style={{flex: 1, paddingHorizontal: 10}}>
            {externalWorkInvoiceToString(dayReport.ExternalWorkInvoice)}
          </Text>
          <Button
            onPress={clearExternalWorkInvoice}
            style={{
              backgroundColor: theme.blue,
            }}
          >
            <MaterialCommunityIcons
              name='link-off'
              size={20}
              color='white'
            />
          </Button>
        </View>
      )}

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 10,
      }}>
        {canSplitDayReport && (
          <Button
            style={{
              backgroundColor: theme.blue,
              marginRight: 3,
            }}
            onPress={saveAndSplit}
            titleT='SAVE_AND_SPLIT'
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    width: 120,
    fontSize: 16,
  },
  inputs: {
    backgroundColor: "white",
    flex: 1,
    padding: 9,
    borderRadius: 6,
  },
  signatureIconClose: {
    top: 0,
    right: 0,
    position: "absolute",
    zIndex: 1,
  },
  signature: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
})

export default DayReportSettings