import React, { useEffect, useState } from "react";
import { Client } from "../../models";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { updateClient } from "../../api/ClientsAPI";
import { replaceClient } from "../../store/action/clientsActions";
import { ActivityIndicator, Text, View } from "react-native";
import { t } from "i18n-js";
import ClientScreen, { ClientMode } from "./ClientScreen";
import { alert } from "../../hooks/Alert";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { logout } from "../../store/action/authAction";
import { sentryCapture } from "../../utils/sentryCapture";
import { userCanUpdateBusinessPartners } from "../../models/permissions";

interface Props {
  route: {
    params?: {
      clientId?: string,
      setClient?: (client: Client) => void;
    }
  }
}

const UpdateClientScreen = ({route}: Props) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable);
  const clients = useSelector((state: Reducer) => state.client.clients);

  const user = useSelector((state: Reducer) => state.user)

  const [client, setClient] = useState<Client>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!userCanUpdateBusinessPartners(user)) {
      alert('ERROR', 'YOU_ARE_NOT_AUTHORIZED_TO_UPDATE_CLIENTS')
      navigation.goBack()
      return;
    }
    setClient(clients.find(c => String(c.ID) === route.params?.clientId));
    setLoading(false);
  }, []);

  const _save = async () => {
    if (!client) {
      // TODO alert
      return;
    }
    let updated = client;
    if (serverReachable) {
      try {
        setLoading(true);
        updated = await updateClient(client);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          await alert('ERROR_UPDATING_CLIENT', 'ERROR_UPDATING_CLIENT_DESC');
        }
        return;
      }
    }

    dispatch(replaceClient(updated));

    if (route.params?.setClient) {
      route.params.setClient({
        ...updated,
        toUpdate: !serverReachable,
      });
    }

    navigation.goBack();
  }

  const _back = () => navigation.goBack();

  if (loading) {
    return (
      <View
        style={{
          padding: 5,
        }}
      >
        <MaterialCommunityIcons
          name="close"
          size={34}
          onPress={_back}
        />
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ActivityIndicator />
        </View>
      </View>
    );
  }

  if (!client) {
    return (
      <View style={{padding: 5}}>
        <MaterialCommunityIcons
          name="close"
          size={34}
          onPress={_back}
        />
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text>{t("CLIENT_NOT_FOUND")}</Text>
        </View>
      </View>
    )
  }
  return (
    <ClientScreen setClient={setClient}
                  client={client}
                  mode={ClientMode.UPDATE}
                  save={_save}/>
  );
};

export default UpdateClientScreen;