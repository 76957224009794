import React, { useMemo, useState } from "react";
import { ConstructionSiteViewGroups, Doc } from "../../models";
import { ScrollView, Text, View } from "react-native";
import getTheme from "../../constants/Colors";
import { t } from "i18n-js";
import { MaterialIcons } from "@expo/vector-icons";
import QuickSendPdfClient from './QuickSendPdfClient';
import QuickSendPdfDocumentContact from './QuickSendPdfDocumentContact';
import Button from "../Button";
import Modal from "react-native-modal";

interface Props {
  email: string;
  setEmail: (email: string) => void;
  open: boolean;
  onClose: () => void;
  doc: Doc;
}

const QuickSendPdfModal = ({email, setEmail, open, onClose, doc}: Props) => {

  const theme = getTheme();

  const [e, setE] = useState<string>(email);

  const useBillEmail = doc.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice

  const contacts = useMemo(() => doc.DocumentContacts.map((d, i) => (
    <QuickSendPdfDocumentContact
      documentContact={d}
      email={e}
      setEmail={setE}
      key={i}
      useBillEmail={useBillEmail}
    />
  )), [doc.DocumentContacts, email])

  const _ok = () => {
    setEmail(e);
    onClose();
  }

  return (
    <Modal
      isVisible={open}
      onBackdropPress={onClose}
      style={{
        padding: 15,
        backgroundColor: 'white',
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: "row", justifyContent: 'space-between'}}>
        <Text style={{fontSize: 25, flex: 1}}>{t('CHOOSE_RECIPIENT')}</Text>
        <MaterialIcons
          name='close'
          size={24}
          onPress={onClose}
        />
      </View>

      <ScrollView>
        <QuickSendPdfClient
          client={doc.Client}
          clientContact={doc.ClientContact}
          email={e}
          setEmail={setE}
          description={t("CLIENT")}
          useBillEmail={useBillEmail}
        />
        {
          doc.OfferNamedTo && (
            <QuickSendPdfClient
              client={doc.OfferNamedTo}
              clientContact={doc.OfferNamedToContact}
              email={e}
              setEmail={setE}
              description={t("OFFER_NAMED_TO")}
              useBillEmail={useBillEmail}
            />
          )
        }
        {
          doc.By && (
            <QuickSendPdfClient
              client={doc.By}
              clientContact={doc.ByContact}
              email={e}
              setEmail={setE}
              description={t("BY")}
              useBillEmail={useBillEmail}
            />
          )
        }
        {
          doc.InvoiceTo && (
            <QuickSendPdfClient
              client={doc.InvoiceTo}
              clientContact={doc.InvoiceToContact}
              email={e}
              setEmail={setE}
              description={t("OFFER_NAMED_TO")}
              useBillEmail={useBillEmail}
            />
          )
        }
        {(contacts && contacts.length > 0) && <Text>{t("CONTACTS")}</Text>}
        {contacts}
      </ScrollView>

      <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
        <Button
          onPress={_ok}
          disabled={e === email}
          style={{
            backgroundColor: theme.blue,
          }}
          titleT="SAVE"
        />
      </View>
        
    </Modal>
  );
};

export default QuickSendPdfModal