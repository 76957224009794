import React, { useEffect, useState } from "react";
import { ConstructionSite, Contract, Doc } from "../../../models";
import { ActivityIndicator, Text, View, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { DocMode } from "../utils";
import { t } from "i18n-js";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import getTheme from "../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { FlatList } from "react-native";
import ConstructionSiteItem from "./ConstructionSiteItem";
import { getConstructionSites } from "../../../store/action/constructionSitesAction";
import ContractItem from "./ContractItem";
import { createConstructionSite, createContract, createDoc } from "../../../utils/doc/util";
import Modal from "react-native-modal";
import Button from "../../../components/Button";
import { DocStatus } from "../../../constants/Status";
import { setDocOffline } from "../../../utils/doc-offline/util";

interface Props {
  constructionSite?: ConstructionSite;
  contract?: Contract;
  doc?: Doc;
  setConstructionSite: (constructionSite: ConstructionSite) => void;
  setContract: (contract?: Contract) => void;
  setDoc: (doc?: Doc) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  mode: number;
  setUuid: (uuid: string) => void
}

const ChooseConstructionSiteContractModal = ({
  constructionSite,
  contract,
  doc,
  setConstructionSite,
  setContract,
  setDoc,
  visible,
  setVisible,
  mode,
  setUuid
}: Props) => {
  const navigation = useNavigation();
  const theme = getTheme();
  const dispatch = useDispatch();

  const user = useSelector((state: Reducer) => state.user);

  const constructionSites = useSelector(
    (state: Reducer) => state.constructionSite.constructionSites
  );
  const constructionSitesLoading = useSelector(
    (state: Reducer) => state.sync.constructionSitesLoading
  )

  const [filtered, setFiltered] =
    useState<ConstructionSite[]>(constructionSites);

  const _searchHandler = (search: string) => {
    // TODO order by distance
    const s = search.trim().toLowerCase();

    setFiltered(
      constructionSites.filter((c) => {
        return (
          c.IDString.toLowerCase().includes(s) ||
          c.Code.toLowerCase().includes(s) ||
          c.Name.toLowerCase().includes(s) ||
          c.Address?.toLowerCase().includes(s)
        );
      })
    );
  };

  useEffect(() => setFiltered(constructionSites), [constructionSites]);

  const _close = () => {
    setVisible(false);

    if (
      (constructionSite == undefined ||
        contract == undefined ||
        doc == undefined) &&
      mode == DocMode.CREATE
    ) {
      navigation.goBack();
    } else {
      if (constructionSite && contract && doc) {
        setDocOffline(constructionSite, contract, doc).then(setUuid)
      }
    }
  };

  const _renderConstructionSite = ({ item }: { item: ConstructionSite }) => (
    <ConstructionSiteItem
      constructionSite={item}
      select={(constructionSite) => {
        setConstructionSite(constructionSite);
        setContract();
        setDoc();
      }}
      selected={constructionSite}
    />
  );

  const _refreshConstructionSites = () => {
    dispatch(getConstructionSites())
  };

  const _renderContract = ({ item }: { item: Contract }) => (
    <ContractItem
      contract={item}
      select={(contract) => {
        setContract(contract);
        setDoc();
      }}
      selected={contract}
    />
  );

  const _newConstructionSite = () => {
    const { constructionSite, contract, doc } = createConstructionSite(
      -1,
      user
    );
    setConstructionSite(constructionSite);
    setContract(contract);
    setDoc(doc);
    if (constructionSite && contract && doc) {
      setDocOffline(constructionSite, contract, doc).then(setUuid)
    }
    setVisible(false);
  };

  const _newContract = () => {
    if (constructionSite) {
      const { contract, doc } = createContract(-1, constructionSite?.ID, user);
      setContract(contract);
      setDoc(doc);
      setVisible(false);
      if (constructionSite && contract && doc) {
        setDocOffline(constructionSite, contract, doc).then(setUuid)
      }
    }
  };

  const _choose = () => {
    if (
      constructionSite != undefined &&
      constructionSite.Contracts.length == 0
    ) {
      _newContract();
      return;
    }

    if (contract) {
      const doc = createDoc(-1, contract.ID, user)
      setDoc(doc)
      if (constructionSite && contract && doc) {
        setDocOffline(constructionSite, contract, doc).then(setUuid)
      }
      setVisible(false);
    }
  };

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={_close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: "row"}}>
        <Text style={{fontSize: 25, flex: 1}}>
          {t("CHOOSE_CONSTRUCTION_SITE")}
        </Text>
        <MaterialCommunityIcons name="close" size={24} onPress={_close} />
      </View>

      <TextInput
        style={{
          backgroundColor: 'white',
          padding: 5,
          borderColor: theme.border,
          borderWidth: 1,
          borderRadius: 5,
          marginVertical: 10,
        }}
        placeholder={t("SEARCH")}
        onChangeText={_searchHandler}
      />

      <View style={{flexDirection: 'row', flex: 1, paddingTop: 15}}>
        <View style={{flex: 1, paddingRight: 30, paddingVertical: 6}}>
          <View style={{flexDirection: "row", alignItems: "center"}}>
            <Text style={{fontSize: 20, paddingBottom: 2, flex: 1}}>
              {t("CONSTRUCTION_SITES")}
            </Text>
            <Button
              onPress={_newConstructionSite}
              titleT='CREATE_NEW_CONSTRUCTION_SITE'
              leftComponent={
                <MaterialCommunityIcons name="plus" size={14} style={{color: 'white'}} />
              }
              style={{
                backgroundColor: theme.blue,
              }}
            />
          </View>
          <FlatList
            data={filtered}
            renderItem={_renderConstructionSite}
            refreshing={constructionSitesLoading}
            onRefresh={_refreshConstructionSites}
            key={`construction-site-modal`}
            // @ts-ignore
            listKey={`construction-site-modal`}
            keyExtractor={(item, index) => `construction-site-modal-${index}-${item.ID}`}
          />
        </View>
        <View style={{flex: 1, paddingLeft: 30}}>
          {constructionSite ? (
            <>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={{fontSize: 20, paddingBottom: 2, flex: 1}}>
                  {t("CHOOSE_CONTRACT")}
                </Text>
                <Button
                  onPress={_newContract}
                  titleT='CREATE_NEW_CONTRACT'
                  leftComponent={
                    <MaterialCommunityIcons name="plus" size={14} color='white' />
                  }
                  style={{
                    backgroundColor: theme.blue,
                  }}
                />
              </View>
              <FlatList
                data={constructionSite?.Contracts?.filter(canCreateOfferOnContract) || []}
                renderItem={_renderContract}
                key={`construction-site-modal-contract`}
                // @ts-ignore
                listKey={`construction-site-modal-contract`}
                keyExtractor={(item, index) => `construction-site-modal-contract-${index}-${item.ID}`}
                ListEmptyComponent={
                  <Text>{t("CONSTRUCTION_SITE_HAS_NO_CONTRACTS")}</Text>
                }
              />
            </>
          ) : (
            <Text>{t("NO_CONSTRUCTION_SITE_SELECTED")}</Text>
          )}
        </View>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Button
          onPress={_refreshConstructionSites}
          disabled={constructionSitesLoading}
        >
          {constructionSitesLoading ? (
            <ActivityIndicator />
          ) : (
            <MaterialCommunityIcons name="refresh" color="white" />
          )}
        </Button>
        <Button 
          style={{backgroundColor: theme.mainColor}}
          onPress={_choose}
          titleT="CHOOSE"
        />
      </View>
    </Modal>
  );
};

const canCreateOfferOnContract = (contract: Contract) => {
  if (contract.WarrantyFor != null && contract.WarrantyFor != 0) {
    return false
  }

  switch (contract.Status) {
    case DocStatus.DONE.code:
    case DocStatus.CANCELLED.code:
    case DocStatus.LOST.code:
    case DocStatus.TO_BE_PAID.code:
      return false
  }

  return true
}

export default ChooseConstructionSiteContractModal
