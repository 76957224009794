import { MaterialCommunityIcons } from "@expo/vector-icons"
import React, { useState } from "react"
import { NativeSyntheticEvent, Platform, StyleSheet, Text, TextInputChangeEventData, View } from "react-native"
import Modal from "react-native-modal"
import { t } from 'i18n-js'
import { GooglePlaceData, GooglePlaceDetail, GooglePlacesAutocomplete } from "react-native-google-places-autocomplete"
import MapView, { LatLng, Marker, PROVIDER_GOOGLE } from "react-native-maps" //"react-native-web-maps"
import { coordinatesToGoogleMapsLink, googleMapsLinkToCoordinates } from "../../hooks/geo"
import getTheme from "../../constants/Colors"
import { reverseGeocodeAsync } from "expo-location"
import { useSelector } from "react-redux"
import { Reducer } from "../../store/reducers"
import Button from "../Button"

interface Props {
  address: string
  coordinate?: LatLng
  setCoordinate: (coordinates: LatLng) => void
  setAddressMaps: (address: string, maps: string) => void
  visible: boolean
  onClose: () => void
  mapRefProp?: React.RefObject<MapView>
}

const MapModal = ({address, setAddressMaps, visible, onClose, coordinate, setCoordinate, mapRefProp }: Props) => {
  
  const theme = getTheme()

  const token = useSelector((state: Reducer) => state.auth.token)
  const url = useSelector((state: Reducer) => state.auth.workSpace?.url || '')
  const branch = useSelector((state: Reducer) => state.user.branch.Maps)
  const branchCoordinates = googleMapsLinkToCoordinates(branch).coords

  const [savedAddress, setSavedAddress] = useState<boolean>(false)
  const _saveAddress = () => setSavedAddress(true)

  const mapRef = React.createRef<MapView>()

  const _addressHandler = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setAddressMaps(e.nativeEvent.text, '.')
  }

  const _addressChoose = (data: GooglePlaceData, detail: GooglePlaceDetail | null) => {
    if (Platform.OS === 'web') {
      mapRef.current?.animateCamera({
        center: {
          // @ts-ignore
          lat: detail?.geometry.location.lat || 0,
          lng: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
      mapRefProp?.current?.animateCamera({
        center: {
          // @ts-ignore
          lat: detail?.geometry.location.lat || 0,
          lng: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
    } else {
      mapRef.current?.animateCamera({
        center: {
          latitude: detail?.geometry.location.lat || 0,
          longitude: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
      mapRefProp?.current?.animateCamera({
        center: {
          latitude: detail?.geometry.location.lat || 0,
          longitude: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
    }
    const maps =  detail?.geometry.location ? coordinatesToGoogleMapsLink(
      detail.geometry.location.lat,
      detail.geometry.location.lng,
    ) : '.'
    setAddressMaps(
      data.description,
      maps,
    )
    setCoordinate({
      latitude: detail?.geometry.location.lat || 0,
      longitude: detail?.geometry.location.lng || 0,
    })
  }

  return (
    <Modal
      isVisible={visible}
      onBackButtonPress={onClose}
      style={{
        backgroundColor: theme.background,
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 25}}>{t('CHOOSE_ADDRESS_AND_LOCATION')}</Text>
        <MaterialCommunityIcons name="close" size={30} onPress={onClose} />
      </View>

      <View style={{flexDirection: 'row', alignItems: 'center', paddingVertical: 15}}>
        <Text style={{paddingRight: 15}}>
          {t('CONSTRUCTION_SITE_ADDRESS')}:
        </Text>
        <GooglePlacesAutocomplete
          //ref={ref}
          GooglePlacesDetailsQuery={{ fields: "geometry" }}
          fetchDetails={true}
          placeholder='Search'
          onPress={_addressChoose}
          textInputProps={{
            onChange: _addressHandler,
            value: address,
          }}
          query={{
            key: 'AIzaSyDqb1ADutBQksnAv43XLyjDQuJO9cTALNU',
            language: 'en',
          }}
          requestUrl={{
            useOnPlatform: 'web',
            url: `${url}googleMapsApiProxy`,
            headers: {
              Authorization: token,
            },
          }}
        />
        <Button
          onPress={_saveAddress}
          titleT='SAVE'
          style={{backgroundColor: theme.secondaryBackground, marginLeft: 15}}
        />
      </View>

      <Text style={{paddingVertical: 15}}>
        {t('MAP_MODAL_IF_YOU_WANT_TO_SAVE_ADDRESS')}
      </Text>

      <View style={{flex: 1}}>
        <MapView
          ref={mapRef}
          provider={PROVIDER_GOOGLE}
          showsUserLocation={Platform.OS !== 'web'}
          mapType="hybrid"
          showsPointsOfInterest={false}
          style={{
            ...StyleSheet.absoluteFillObject,
            borderRadius: 5,
          }}
          initialRegion={{
            ...(coordinate ? coordinate : branchCoordinates),
            latitudeDelta: 0.0122,
            longitudeDelta: 0.0051,
          }}
          onRegionChange={(region) => {
            if (region) {
              setCoordinate(region)
            }
          }}
          onRegionChangeComplete={(region) => {
            setCoordinate(region)
            if (savedAddress) {
              setAddressMaps(
                address,
                coordinatesToGoogleMapsLink(region.latitude, region.longitude),
              )
            } else {
              if (Platform.OS === 'web') {
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${region.latitude},${region.longitude}&key=${'AIzaSyDqb1ADutBQksnAv43XLyjDQuJO9cTALNU'}`,{headers:{}})
                  .then(r => r.json())
                  .then(r => {
                    if (r.results.length > 0) {
                      setAddressMaps(
                        `${r.results[0].formatted_address}`,
                        coordinatesToGoogleMapsLink(region.latitude, region.longitude),
                      )
                    }
                  })
              } else {
                reverseGeocodeAsync(region).then((response) => {
                  if (response.length > 0) {
                    setAddressMaps(
                      `${response[0].name}, ${response[0].isoCountryCode}-${response[0].postalCode} ${response[0].city}`,
                      coordinatesToGoogleMapsLink(region.latitude, region.longitude),
                    )
                  }
                })
              }
            }
            if (Platform.OS === 'web') {
              mapRefProp?.current?.animateCamera({
                center: {
                  // @ts-ignore
                  lat: region.latitude,
                  lng: region.longitude
                },
                zoom: 16,
              })
            } else {
              mapRefProp?.current?.animateCamera({
                center: {
                  latitude: region.latitude,
                  longitude: region.longitude,
                },
                zoom: 16,
              })
            }
          }}
        >
          {coordinate && (Platform.OS == "web" ? (
            // @ts-ignore
            <MapView.Marker coordinate={coordinate} title="tt" description="ddd"/>
          ) : (
            <Marker coordinate={coordinate} title="tt" description="ddd"/>
          ))}
        </MapView>
      </View>
    </Modal>
  )
}

export default MapModal