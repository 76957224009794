import * as ClientsAPI from "../../api/ClientsAPI";
import { Client } from "../../models";

import {
  GET_ALL_CLIENTS_LOADING,
  GET_ALL_CLIENTS_SUCCESS,
  NEW_CLIENT,
  UPDATE_CLIENT,
} from "../types";
import { Reducer } from "../reducers";
import { alert } from "../../hooks/Alert";
import { logout } from "./authAction";
import { sentryCapture } from "../../utils/sentryCapture";

const setClientsLoading =
  (loading: boolean) => async (dispatch: ({}) => void) => {
    dispatch({
      type: GET_ALL_CLIENTS_LOADING,
      payload: loading,
    });
  };

export const getAllClients =
  () => async (dispatch: ({}) => void, getState: () => Reducer) => {
    if (!getState().user.permissions.sales) {
      return;
    }
    if (!getState().sync.serverReachable) {
      return;
    }
    if (getState().sync.clientsLoading) {
      return
    }

    setClientsLoading(true)(dispatch)
    try {
      const payload = await ClientsAPI.getClients();

      const localClients = getState().client.clients.filter(
        c => !isNaN(Number(c.ID)) && Number(c.ID) < 0
      )
      const replaceChanged = payload.map(c => {
        if (getState().client.clients.find(lc => lc.ID == c.ID)?.toUpdate) {
          return getState().client.clients.find(lc => lc.ID == c.ID)
        }
        return c
      })

      dispatch({
        type: GET_ALL_CLIENTS_SUCCESS,
        payload: [...replaceChanged, ...localClients],
      });
    } catch (error: any) {
      if (error.response && error.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(error);
        alert("ERROR", "ERROR_LOADING_CLIENTS");
      }
    } finally {
      await setClientsLoading(false)(dispatch);
    }
  };

export const appendClient = (client:Client) => (dispatch: ({})=>void) => {
  dispatch({
    type: NEW_CLIENT,
    payload: client,
  });
}

export const replaceClient = (client: Client) => (dispatch: ({}) => void) => {
  dispatch({
    type: UPDATE_CLIENT,
    payload: client,
  });
};
