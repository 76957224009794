import { Text, View } from "react-native";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { t } from "i18n-js";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  country?: string;
  state?: string;
  setCountry: (country: string) => void;
  setState: (state: string) => void;
}

const CountryDropdown = ({country, state, setCountry, setState}: Props) => {

  const countries = useSelector((state: Reducer) => state.general.countries);

  const countryObject = useMemo(() => countries.find(c => c.ID == country), [country]);

  const _changeCountry = (countryId: string) => {
    if (country == countryId) {
      return;
    }

    setCountry(countryId);
  }

  const _changeState = (stateId: string) => {
    if (state == stateId) {
      return;
    }

    setState(stateId);
  }

  return (
    <View
      style={{
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
      }}
    >
      <Text style={{paddingRight: 5}}>{t("COUNTRY")}:</Text>
      <NativeModalPicker
        itemToLabel={id => countries.find(v => v.ID == id)?.Name ?? ''}
        itemToKey={id => id}
        style={{
          paddingVertical: 10,
          paddingHorizontal: 25,
          borderRadius: 5,
          backgroundColor: 'white',
        }}
        selectedValue={country}
        onValueChange={_changeCountry}
        placeholder='SELECT_COUNTRY'
        options={countries.map(v => v.ID)}
        showLabelAsOption={true}
      />
      <Text style={{paddingLeft: 10, paddingRight: 5}}>{t("STATE")}:</Text>
      {
        (countryObject && countryObject.States) ? (
          <NativeModalPicker
            itemToLabel={state => state}
            itemToKey={state => state}
            style={{
              paddingVertical: 10,
              paddingHorizontal: 25,
              borderRadius: 5,
              backgroundColor: 'white',
            }}
            selectedValue={state}
            onValueChange={_changeState}
            placeholder='SELECT_STATE'
            options={countryObject.States.map(v => v.ID)}
            showLabelAsOption={true}
          />
        ): (
          <View style={{flex: 1}}/>
        )
      }
    </View>
  )

  return (
    <View/>
  );
};

export default CountryDropdown;