import axios from "axios"

export const sendMail = async (recipient: string, subject: string, content: string, replyTo: string = "sap@drytech.ch", from?: string) => {
  await axios.post("sendEmail", {
    recipient,
    subject,
    content,
    from,
    replyTo,
  });
}