import React from "i18n-js";
import { ConstructionSiteViewGroups, Contract, Doc, FileType } from "../../../models";
import { Linking, Pressable, Text, View } from "react-native";
import styles from "./style";
import Status from "../../../components/Status";
import ConstructionSiteViews from "./ConstructionSiteViews";
import { t } from "i18n-js";
import getTheme from "../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { PriceLevel } from "../../../store/reducers/settingsReducer";
import { useMemo, useState } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { FlatList } from "react-native";
import SidebarOfferItem from "./SidebarOfferItem";
import ConstructionSiteDetailsFileTypes from "./ConstructionSiteDetailsFileTypes";
import { alert, alertText } from "../../../hooks/Alert";
import { getDocById } from "../../../api/DocAPI";
import { sapDocToDocConvert } from '../../../shared-utils/ConversionUtils'
import { logout } from "../../../api/AuthAPI";
import { sentryCapture } from "../../../utils/sentryCapture";
import AvancementProgressBar from "../../../components/AvancementProgressBar";
import { DocStatus } from "../../../constants/Status";

interface Props {
  contract: Contract;
  setSelectedContract: (contract?: Contract) => void;
  selectedContract?: Contract;
  setSelectedView: (selectedView: ConstructionSiteViews) => void;
  selectedView: ConstructionSiteViews;
  setDoc: (doc?: Doc) => void;
  doc?: Doc;
  setFileType: (fileType?: FileType) => void;
  fileType?: FileType;
}

const ConstructionSiteDetailsContract = ({contract, setSelectedContract, selectedContract, setSelectedView, selectedView, setDoc, doc, setFileType, fileType}: Props) => {

  const theme = getTheme();
  const dispatch = useDispatch();

  const user = useSelector((state: Reducer) => state.user);
  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  const offers = useMemo(() => contract.Docs.filter(d => d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Offer), [contract]);
  const order = useMemo(() => {
    const orders = contract.Docs.filter(d => d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Order);
    if (orders.length > 0) {
      return orders[0];
    }
  }, [contract]);
  const invoices = useMemo(() => contract.Docs.filter(d => d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice), [contract]);

  const [showOffers, setShowOffers] = useState<boolean>(user.permissions.sales);
  const _toggleShowOffers = () => setShowOffers(showOffers => !showOffers);

  const [showInvoices, setShowInvoices] = useState<boolean>(user.permissions.sales);
  const _toggleShowInvoices = () => setShowInvoices(showInvoices => !showInvoices);

  const _toggleShowDayReports = () => setSelectedView(ConstructionSiteViews.DayReports)
  const _openExternalWorkInvoices = () => setSelectedView(
    ConstructionSiteViews.ExternalWorkInvoices
  )

  const _press = () => setSelectedContract(contract);
  const _overview = () => setSelectedView(ConstructionSiteViews.ContractOverview);
  const _order = () => setSelectedView(ConstructionSiteViews.Order);
  const _measure = () => setSelectedView(ConstructionSiteViews.Measure);

  const _renderDocument = ({item}: {item: Doc}) => {

    const _pressDocument = () => {
      if (item.Type == null || item.Type == undefined) {
        alertText(
          "Document Error",
          "Missing some data in document: " + item.IDString
        );
        return;
      }
      getDocById(item.ID, item.Type)
        .then((sapDoc) => {
          const doc = sapDocToDocConvert(sapDoc, catalogs);
          setDoc(doc);
          setSelectedView(ConstructionSiteViews.Document);
        })
        .catch((e) => {
          if (e.response && e.response.code == 401) {
            alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
            dispatch(logout());
          } else {
            sentryCapture(e);
            alert("ERROR_UPDATING", "ERROR_LOADING_DOCUMENT");
          }
        });
    }

    const isSelected = selectedView == ConstructionSiteViews.Document && doc == item;

    return (
      <SidebarOfferItem
        onPress={_pressDocument}
        doc={item}
        isSelected={isSelected}
        contract={contract}
      />
    )
  }

  const isOpen =  contract.Status == DocStatus.OPEN.code
      || contract.Status == DocStatus.IN_EXECUTION.code
      || contract.Status == DocStatus.TO_BE_APPROVED.code
      || contract.Status == DocStatus.TO_BE_BILLED.code

  return (
    <View>
      <Pressable
        onPress={_press}
        style={{ margin: 5, borderRadius: 5, backgroundColor: "white" }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 9, paddingHorizontal: 5, alignItems: 'center' }}>
          <Text style={{ fontSize: 20, fontWeight: "bold" , flex: 1}}>
            {contract.Name}
          </Text>
          <Status status={contract.Status} size={"m"}/>
        </View>
        {isOpen && (
          <AvancementProgressBar
            workProgress={contract.workProgress}
            invoiceProgress={contract.invoiceProgress}
          />
        )}
      </Pressable>

      {(contract == selectedContract) && (
        <View style={styles.categoryContentContainer}>
          <Pressable onPress={_overview} >
            <View style={[
              styles.categoryContainer,
              {backgroundColor: selectedView == ConstructionSiteViews.ContractOverview ? theme.background : 'white'}
            ]}>
              <Text style={styles.categoryText}>{t("OVERVIEW")}</Text>
            </View>
          </Pressable>

          <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />

          {user.permissions.sales &&
            user.permissions.priceLevel >= PriceLevel.CLIENT && (
              <>
                <Pressable onPress={_toggleShowOffers}>
                  <View style={styles.categoryContainer}>
                    <Text style={styles.categoryText}>{t("OFFERS")}</Text>
                    <MaterialCommunityIcons
                      size={24}
                      name={
                        showOffers
                          ? "chevron-down"
                          : "chevron-right"
                      }
                    />
                  </View>
                </Pressable>
                <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
              </>
            )}

            {(user.permissions.sales && showOffers) && (
              <FlatList
                data={offers}
                renderItem={_renderDocument}
                key={`construction-site-details-contract-${contract.ID}-offers`}
                // @ts-ignore
                listKey={`construction-site-details-contract-${contract.ID}-offers`}
                keyExtractor={(item, index) => `construction-site-details-contract-${contract.ID}-offers-${index}-${item.ID}`}
              />
            )}
            
            {order && (
              <>
                <Pressable
                  onPress={_order}
                  style={[
                    styles.categoryContainer,
                    {backgroundColor: selectedView == ConstructionSiteViews.Order ? theme.background : 'white'}
                  ]}
                >
                  <Text style={styles.categoryText}>{t("ORDER")} - {order.IDString}</Text>
                </Pressable>
                <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
              </>
            )}

            {order && (
              <>
                <Pressable
                  onPress={_toggleShowDayReports}
                  style={[
                    styles.categoryContainer,
                    {backgroundColor: selectedView == ConstructionSiteViews.DayReports ? theme.background : 'white'}
                  ]}
                >
                  <Text style={styles.categoryText}>{t("WORK_REPORTS")}</Text>
                </Pressable>
                <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
              </>
            )}

            {order && (
              <>
                <Pressable
                  onPress={_openExternalWorkInvoices}
                  style={[
                    styles.categoryContainer,
                    { backgroundColor: selectedView == ConstructionSiteViews.ExternalWorkInvoices ? theme.background : 'white'}
                  ]}
                >
                  <Text style={styles.categoryText}>{t("EXTERNAL_WORKS_INVOICES")}</Text>
                </Pressable>
                <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
              </>
            )}

            {(user.permissions.sales && user.permissions.priceLevel >= PriceLevel.CLIENT && order && contract.Measure) && (
              <>
                <Pressable
                  onPress={_measure}
                  style={[
                    styles.categoryContainer,
                    {backgroundColor: selectedView == ConstructionSiteViews.Measure ? theme.background : 'white'}
                  ]}
                >
                  <Text style={styles.categoryText}>{t("MEASURES")}</Text>
                </Pressable>
                <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
              </>
            )}

            {(user.permissions.sales && user.permissions.priceLevel >= PriceLevel.CLIENT && order) && (
                <>
                  <Pressable onPress={_toggleShowInvoices}>
                    <View style={styles.categoryContainer}>
                      <Text style={styles.categoryText}>{t("INVOICES")}</Text>
                      <MaterialCommunityIcons
                        size={24}
                        name={
                          showInvoices
                            ? "chevron-down"
                            : "chevron-right"
                        }
                      />
                    </View>
                  </Pressable>
                  <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
                </>
              )}
  
              {(user.permissions.sales && showInvoices && order) && (
                <FlatList
                  data={invoices}
                  renderItem={_renderDocument}
                  key={`construction-site-details-contract-${contract.ID}-invoices`}
                  // @ts-ignore
                  listKey={`construction-site-details-contract-${contract.ID}-invoices`}
                  keyExtractor={(item, index) => `construction-site-details-contract-${contract.ID}-invoices-${index}-${item.ID}`}
                />
              )}

            {
              contract.Snapsite_folder_ID != undefined && contract.Snapsite_folder_ID.length > 0 && (
                <>
                  <Pressable onPress={() => Linking.openURL(`https://snapsite.xyz/dashboard/folders/${contract.Snapsite_folder_ID}`)}>
                    <View style={styles.categoryContainer}>
                      <Text style={styles.categoryText}>{t("PHOTOS")}</Text>
                    </View>
                  </Pressable>
                  <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
                </>
              )
            }

            {
              contract.FileTypes !== undefined && (
                <ConstructionSiteDetailsFileTypes
                  fileTypes={contract.FileTypes}
                  setFileTypesToOpen={setFileType}
                  setSelectedView={setSelectedView}
                  fileTypesToOpen={fileType}
                />
              )
            }
        </View>
      )}
    </View>
  );
};

export default ConstructionSiteDetailsContract;