import React from "react";
import { Industry } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  industry?: Industry;
  setIndustry: (industry: Industry) => void;
}

const IndustryDropdown = ({industry, setIndustry}: Props) => {

  const industries = useSelector((state:Reducer) => state.general.industries);

  const _handler = (selected: Industry) => {
    if (industry?.ID == selected.ID) {
      return;
    }

    setIndustry(selected)
  }

  return(
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      onValueChange={_handler}
      selectedValue={industry}
      options={industries}
      showLabelAsOption={true}
      placeholder='SELECT_INDUSTRY'
    />
  );
};

export default IndustryDropdown;