import React from "react";
import { Level, LineLevel } from "../../models";
import { Text, View } from "react-native";
import Formatter from "../../utils/formatters";

interface Props {
  lineLevel: LineLevel;
  levels: Level[];
  orderBlock: boolean;
  showPrice: boolean;
  index: number;
}

export const getLineTotal = (lineLevel: LineLevel): number => {
  const tot = (lineLevel.BillableQuantity || 0) * lineLevel.Price
  if (lineLevel.DiscountNumeric) {
    return tot - lineLevel.Discount;
  } else {
    return tot * (1 - lineLevel.Discount / 100); // TODO check formula_lineLevel.Price * (1 - _lineLevel.Discount / 100)
  }
} 

const BillPreviewLineLevel = ({lineLevel, levels, orderBlock, showPrice, index}:Props) => {

  const lineTotal = getLineTotal(lineLevel);

  const isBlock = lineLevel.FixedPriceBlock && lineLevel.FixedPriceBlock.length > 0 && lineLevel.FixedPriceBlock != "-";
  const showPriceLocal = !isBlock && orderBlock && showPrice;

  return (
    <View key={lineLevel.ID} style={{flexDirection: 'row'}}>
      <View style={{width: 100}} />
      <Text style={{flex: 4}}>{levels[index].Name}</Text>
      <View style={{flex: 1}}/>
      <Text style={{flex: 1, textAlign: 'right'}}>{Formatter.number2.format(lineLevel.BillableQuantity || 0)}</Text>
      <Text style={{flex: 1, textAlign: 'right'}}>{showPriceLocal ? "" : Formatter.number2.format(lineLevel.Price)}</Text>
      <Text style={{flex: 1, textAlign: 'right'}}>{showPriceLocal ? "" : Formatter.number2.format(lineTotal)}</Text>
    </View>
  );
};

export default BillPreviewLineLevel;