import React, { useEffect, useState } from "react";
import { CatalogGroup, Level, Position } from "../../../models";
import { FlatList, Text, View } from "react-native";
import DocTablePosition from "./DocTablePosition";

interface Props {
  levels: Level[];
  group: CatalogGroup;
  setGroup: (group: CatalogGroup) => void;
}

const DocTableGroup = ({levels, group, setGroup}: Props) => {

  const [g, setG] = useState<CatalogGroup>(group);

  useEffect(() => setG(group), [group]);

  const _setPositionIndex = (position: Position, index: number) => {
    setG(g => ({
      ...g,
      Positions: g.Positions.map((p, pi) => {
        if (pi === index) {
          return {
            ...p,
            ...position,
          }
        }
        return p;
      })
    }))
    setGroup({
      ...g,
      Positions: g.Positions.map((p, pi) => {
        if (pi === index) {
          return {
            ...p,
            ...position,
          }
        }
        return p;
      })
    });
  }

  const _render = ({item, index}: {item: Position, index: number}) => {
    const _setPosition = (position: Position) => {
      _setPositionIndex(position, index);
    }
    return (
      <DocTablePosition
        levels={levels}
        position={item}
        setPosition={_setPosition}
      />
    )
  }

  return (
    <View key={`group-${group.ID}`} style={{flex: 1, flexDirection: 'row'}}>
      <View style={{justifyContent: "center", width: 100, borderRightWidth: 1, borderBottomWidth: 1}}>
          <Text>{group.Name}</Text>
      </View>
      <View style={{flex: 1}}>
        <FlatList
          data={g.Positions}
          renderItem={_render}
          extraData={levels}
          key={`positions-${group.ID}`}
          // @ts-ignore
          listKey={`positions-${group.ID}`}
          keyExtractor={(item, index) => `positions-${group.ID}-${index}-${item.ID}`}
        />
      </View>
    </View>
  );
};

export default DocTableGroup;