import React from "react";
import { Level, Material, Report, ReportLevel, LineType } from '../../models'
import { FlatList, Text, View } from "react-native";
import HtmlText from "../HtmlText";
import { getStringFromTime } from "../../utils/StringUtils";
import NumberInput from "../../screens/document/PostitionComponent/NumberInput";
import { t } from "i18n-js";
import TimePicker from "../TimePicker";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";

interface Props {
  report: Report;
  levels: Level[];
  index: number;
}

const DayReportPreviewLine = ({report, levels, index}:Props) => {

  const reportApprove = useSelector((state: Reducer) => state.user.permissions.reportApprove);

  const background = report.BackgroundColor ?? (index % 2 == 0 ? "white" : "#999")

  if (report.Type === LineType.TITLE) {
    return (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: background,
          paddingVertical: 3,
        }}
      >
        <Text style={{width: 100, paddingLeft: 3}}>{report.ActivityIDString}</Text>
        <View style={{flex: 4}}>
          <HtmlText html={report.Name} maxLength={250} width={"100%"}/>
        </View>
        <View style={{flex: 4}}/>
        {reportApprove > 0 && <View style={{flex: 1}} />}
      </View>
    );
  }

  const _renderLevel = ({item, index}: {item: ReportLevel, index: number}) => {

    return (
      <View 
        style={{
          flexDirection: 'row',
          paddingVertical: 1,
        }}
        key={`level${index}-${item.ID}`}
      >
        <View style={{width: 100}}/>
        <Text style={{flex: 4}}>{levels[index].Name}</Text>
        <Text style={{
          flex: 1,
          textAlign: 'center',
          backgroundColor: item.Quantity > 0 ? "rgba(234, 88, 12, 0.4)" : undefined
        }}>
          {item.Quantity}
        </Text>
        <NumberInput defaultValue={item.ApprovedQuantity} disabled={true} min={0} style={{flex: 2}}/>
        {reportApprove > 0 && <NumberInput defaultValue={item.BillableQuantity} disabled={true} min={0} style={{flex: 2}}/> }
      </View>
    );
  }

  const _renderMaterial = ({item, index}: {item: Material, index: number}) => {

    return (
      <View style={{flexDirection: 'row'}} key={`material${index}-${item.ID}`}>
        <View style={{width: 100}}/>
        <Text style={{flex: 4}}>{item.Name}</Text>
        <Text style={{flex: 1}}>{item.Quantity} {item.UnitOfMeasure}</Text>
        <Text style={{flex: 1}}>{t("APPROVED_QUANTITY")}</Text>
        <Text style={{flex: 1}}>{item.ApprovedQuantity} {item.UnitOfMeasure}</Text>
        <View style={{flex: 1}}/>
        {reportApprove > 0 && <View style={{flex: 1}} />}
      </View>
    );
  };

  return (
    <View
      style={{
        flexDirection: 'column',
        backgroundColor: background,
        padding: 3,
      }}
      key={`report${report.ID}-${index}`}
    >
      <View style={{flexDirection: 'row'}}>
        <Text style={{width: 100, paddingLeft: 3}}>{report.ActivityIDString}</Text>
        <View style={{flex: 4}}>
          <HtmlText html={report.Name} maxLength={250} width={"100%"}/>
        </View>
        <Text style={{flex: 1}}>{report.UnitOfMeasure}</Text>
        <Text style={{flex: 3}} />
        {reportApprove > 0 && <View style={{flex: 1}} />}
      </View>

      <FlatList
        data={report.Levels}
        renderItem={_renderLevel}
        // @ts-ignore
        listKey={`list-report-level-${report.ID}-${index}`}
        key={`list-report-level${report.ID}-${index}`}
        keyExtractor={(item, index) => `list-report-material-${report.ID}-${index}-${item.ID}`}
      />

      <View style={{flexDirection: 'row'}}>
        <View style={{width: 100}}/>
        <Text style={{flex: 4}}>{t("TIME")}</Text>
        <Text style={{flex: 1}}>{getStringFromTime(report.Duration || 0)}</Text>
        <Text style={{flex: 1}}>{t("APPROVED_DURATION")}</Text>
        <View style={{flex: 1}}>
          <TimePicker value={getStringFromTime(report.ApprovedDuration || 0)} disabled={true} onChange={() => {}}/>
        </View>
        <View style={{flex: 1}}/>
        {reportApprove > 0 && <View style={{flex: 1}} />}
      </View>

      <FlatList
        data={report.Materials}
        renderItem={_renderMaterial}
        // @ts-ignore
        listKey={`list-report-material-${report.ID}-${index}`}
        key={`list-report-material${report.ID}-${index}`}
        keyExtractor={(item, index) => `list-report-material-${report.ID}-${index}-${item.ID}`}
      />

      {report.Notes.length > 0 && (
        <View style={{flexDirection: 'row'}}>
          <Text style={{width: 100, paddingLeft: 3}}>{t("NOTES")}</Text>
          <Text style={{flex: 1, color: 'red'}}>{report.Notes}</Text>
        </View>
      )}
    </View>
  );
};

export default DayReportPreviewLine;