import React, { useEffect, useMemo, useState } from 'react';
import { Catalog, Item } from '../../../models';
import getTheme from '../../../constants/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { Reducer } from '../../../store/reducers';
import { ActivityIndicator, Text, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import { t } from 'i18n-js'
import CatalogDropdown from '../../../components/CatalogDropdown';
import Button from '../../../components/Button';
import { getCatalogs } from '../../../store/action/generalAction';
import { TextInput } from 'react-native-gesture-handler';
import InsertPositionLine from './InsertPositionLine';
import NestedList from '../../../components/NestedList';
import { SearchScreens } from '../../search/SearchScreens';


interface Props {
  route: {
    params?: {
      initialCatalogId?: number
      onAdd?: (item: Item | Item[], keepOrder: boolean) => void
      showCheckboxes?: boolean
      showCreate?: boolean
    }
  }
}

const InsertPositionScreen = ({route}: Props) => {

  const theme = getTheme()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const sales = useSelector((state: Reducer) => state.user.permissions.sales)
  const createPosition = useSelector((state: Reducer) => state.user.permissions.salesCreateCustomBom);
  const salesFillBoMVariable = useSelector((state: Reducer) => state.user.permissions.salesFillBoMVariable)

  const catalogs =  useSelector(
    (state: Reducer) => state.general.catalogs
  )
  const catalogsLoading = useSelector(
    (state: Reducer) => state.sync.catalogsLoading
  )
  const initialCatalog = useMemo(
    () => catalogs.find(c => c.ID === route.params?.initialCatalogId) || (catalogs.length > 0 ? catalogs[0] : undefined),
    [catalogs, route.params?.initialCatalogId],
  )

  const [catalog, setCatalog] = useState<Catalog | undefined>(initialCatalog)

  useEffect(() => console.log(route.params?.initialCatalogId, initialCatalog, catalog), [route, initialCatalog, catalog])

  const [keepOrderActive, setKeepOrderActive] = useState<boolean>(false)
  const _keepOrderActiveToggle = () => setKeepOrderActive(!keepOrderActive)

  const [selectFatherActive, setSelectFatherActive] = useState<boolean>(false)
  const _selectFatherActiveToggle = () => setSelectFatherActive(!selectFatherActive)

  const [selectTwinsActive, setSelectTwinsActive] = useState<boolean>(true)
  const _selectTwinsActiveToggle = () => setSelectTwinsActive(!selectTwinsActive)

  const [search, setSearch] = useState<string>('')

  const [toAdd, setToAdd] = useState<Item[]>([])

  const contains = (item: Item):boolean => {
    if (!salesFillBoMVariable && item.Variable) {
      return false
    }
    if (item.IDString.toLowerCase().includes(search.toLowerCase())) {
      return true
    }
    if (item.Name.toLowerCase().includes(search.toLowerCase())) {
      return true
    }
    if (item.InternalName.toLowerCase().includes(search.toLowerCase())) {
      return true
    }
    return (catalog?.Items.filter(i => i.FatherID == item.ID).filter(contains).length || 0) > 0
  }

  const filtered = useMemo(() => {
    return catalog?.Items.filter(contains) || []
  }, [search, catalog])

  const _back = () => navigation.goBack()
  
  const _createCustomPositionVisible = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.CREATE_POSITION, {
      onAdd: route.params?.onAdd,
    })
  }
  
  const _add = () => {
    route.params?.onAdd && route.params.onAdd(toAdd.sort((a, b) => a.ID.localeCompare(b.ID)), keepOrderActive)
    setToAdd([])
    navigation.goBack()
  }

  const _reloadCatalogs = () => {
    if (!catalogsLoading) {
      dispatch(getCatalogs(!sales))
    }
  }

  const isSelected = (item: Item) => toAdd.find(i => i.ID === item.ID)

  const selectFather = (item: Item) => {
    if (item.FatherID) {
      for (let father of filtered) {
        if (father.ID === item.FatherID) {
          if (!isSelected(father)) {
            selectFather(father);
            setToAdd((old) => {
              return [...old, father];
            })
          }
        }
      }
    }
  }

  const selectTwins = (item: Item) => {
    if (item.TwinIDs) {
      for (let twin of filtered) {
        if (item.TwinIDs.includes(twin.ID)) {
          if (!isSelected(twin)) {
            if (selectFatherActive) {
              selectFather(twin);
            }
            setToAdd((old) => [...old, twin])
          }
        }
      }
    }
  }

  const _render = (item: Item, index: number, nestedIndex: number, showChildren?: boolean, toggleShowChildren?: () => void) => {
    const _change = (state: boolean): void => {
      if (state) {
        if (!isSelected(item)) {
          if (selectFatherActive) {
            selectFather(item)
          }
          setToAdd((old) => {
            return [...old, item]
          });
          if (selectTwinsActive) {
            selectTwins(item)
          }
        }
      } else {
        setToAdd((old) => {
          return old.filter((i: Item): boolean => i.ID !== item.ID)
        })
      }
    }

    const _press = () => {
      let toAdd = [item]
      if (item.TwinIDs && selectTwinsActive) {
        for (let twinId of item.TwinIDs) {
          let twin = catalog?.Items.find(i => i.ID === twinId)
          if (twin) {
            toAdd.push(twin)
          }
        }
      }
      route.params?.onAdd && route.params.onAdd(toAdd, keepOrderActive)
      navigation.goBack()
    }

    return (
      <InsertPositionLine
        change={_change}
        index={index}
        item={item}
        press={_press}
        toAdd={toAdd}
        showCheckboxes={route.params?.showCheckboxes}
        nestedIndex={nestedIndex}
        showChildren={showChildren}
        toggleShowChildren={toggleShowChildren}
      />
    )
  }

  return (
    <View style={{flex: 1, padding: 5}}>
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <MaterialCommunityIcons
          name="close"
          size={45}
          onPress={_back}
        />

        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <BouncyCheckbox
            fillColor={theme.mainColor}
            iconStyle={{borderColor: theme.border, borderRadius: 10}}
            innerIconStyle={{borderColor: theme.border, borderRadius: 10}}
            size={20}
            onPress={_keepOrderActiveToggle}
          />
          <Text>{t("KEEP_ORDER")}</Text>
        </View>

        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <BouncyCheckbox
            fillColor={theme.mainColor}
            iconStyle={{borderColor: theme.border, borderRadius: 10}}
            innerIconStyle={{borderColor: theme.border, borderRadius: 10}}
            size={20}
            onPress={_selectFatherActiveToggle}
          />
          <Text>{t("AUTO_SELECT_TITLE")}</Text>
        </View>

        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <BouncyCheckbox
            fillColor={theme.mainColor}
            iconStyle={{borderColor: theme.border, borderRadius: 10}}
            innerIconStyle={{borderColor: theme.border, borderRadius: 10}}
            size={20}
            isChecked={true}
            onPress={_selectTwinsActiveToggle}
          />
          <Text>{t("AUTO_SELECT_TWINS")}</Text>
        </View>

        {catalog && <CatalogDropdown setCatalog={setCatalog} catalog={catalog} />}
        {(createPosition && route.params?.showCreate) && (
          <Button
            style={{ backgroundColor: theme.secondaryBackground}}
            onPress={_createCustomPositionVisible}
            title={t("CREATE")}
          />
        )}
        {/* TODO show only if something to add */}
        <Button
          style={{ backgroundColor: theme.mainColor}}
          onPress={_add}
          title={t("ADD")}
        />
      </View>

      {catalogs.length == 0 && (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <Text>{t("NO_CATALOGS")}</Text>
          <Button
            titleT='RELOAD_CATALOGS'
            style={{
              marginTop: 10,
              backgroundColor: theme.blue,
            }}
            onPress={_reloadCatalogs}
          />
        </View>
      )}

      {catalogsLoading && (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator />
        </View>
      )}

      {!catalog && (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <Text>{t("NO_CATALOG_SELECTED")}</Text>
        </View>
      )}

      <View style={{
        flexDirection: 'row',
        marginVertical: 10,
        marginHorizontal: 5,
        paddingLeft: 10,
        borderColor: theme.secondaryBackground,
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: 'white',
      }}>
        <TextInput
          onChangeText={setSearch}
          placeholder={t("SEARCH")}
          style={{
            flex: 1,
            borderColor: theme.secondaryBackground,
          }}
        />
        <MaterialCommunityIcons
          name='close'
          style={{padding: 10}}
          size={24}
        />
      </View>

      <NestedList
        items={filtered}
        keyExtractor={i => i.ID}
        parentKeyExtractor={i => i.FatherID}
        renderItem={_render}
      />
    </View>
  )
}

export default InsertPositionScreen