import React, { useEffect, useState } from 'react';
import { t } from 'i18n-js';
import { ConstructionSite, Contract, Doc, DocType } from "../../models";
import { Text, View } from "react-native";
import { getDocById } from "../../api/DocAPI";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { sapDocToDocConvert } from '../../shared-utils/ConversionUtils'
import DocumentPreviewClient from "../../screens/constructionSites/views/DocumentView/DocumentPreviewClient";
import { ActivityIndicator, ScrollView } from "react-native";
import DocumentPreviewLines from "../../screens/constructionSites/views/DocumentView/DocumentPreviewLines";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import DocPreviewMenu from '../DocPreviewMenu';
import { SearchScreens } from '../../screens/search/SearchScreens';
import { alert } from '../../hooks/Alert';
import { logout } from '../../api/AuthAPI';
import Button from '../Button';
import getTheme from '../../constants/Colors';
import Modal from 'react-native-modal';
import { sentryCapture } from '../../utils/sentryCapture';
import Formatter from '../../utils/formatters';

export interface DocumentVersionModalConfig {
  docID?: number;
  contract: Contract;
  constructionSite: ConstructionSite
  docType?: DocType;
  visible: boolean;
}

interface Props extends DocumentVersionModalConfig {
  onClose: () => void;
  onDuplicate: () => void;
}

const DocumentVersionModal = ({
  docID,
  contract,
  constructionSite,
  docType,
  visible,
  onClose,
  onDuplicate,
}: Props) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const theme = getTheme();

  const [doc, setDoc] = useState<Doc | null>();

  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  useEffect(() => {
    if (docType && docID) {
      getDocById(docID, docType).then((sapDoc) => {
        try {
          setDoc(sapDocToDocConvert(sapDoc, catalogs));
        } catch (e) {
          sentryCapture(e);
          alert("ERROR", "ERROR_LOADING_DOCUMENT");
        }
      }).catch((e) => {
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_LOADING_DOCUMENT");
        }
      });
    } else {
      setDoc(null);
    }
  }, [docID, docType]);

  let updatedDate = doc && doc.UpdatedDate ? new Date(doc.UpdatedDate) : null;

  const _duplicate = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.DUPLICATE_DOC, {
      docId: doc?.ID,
      docType: doc?.Type,
    });
    onDuplicate();
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={onClose}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}  
    >
      {doc ?
        <View style={{flexDirection: 'column', padding: 6, flex: 1}}>
          <View
            style={{
              flexDirection: 'row',
              paddingHorizontal: 9,
              paddingTop: 9,
              alignItems: 'center',
            }}
          >
            <View style={{flex: 1}}>
              <Text style={{fontSize: 20}}>
                {`${doc.Type.Name} - ${doc.IDString} - ${t('VERSION')}: ${doc.Version} - ${doc.Name}`}
              </Text>
              <Text>
                {`${t('UPDATE_DATE')}: ${updatedDate?.toLocaleDateString()} ${updatedDate?.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})} - Updated By: ${doc.UpdatedBy?.LastName} ${doc.UpdatedBy?.LastName}`}
              </Text>
              <View style={{flexDirection: 'row'}}>
              <DocumentPreviewClient client={doc.Client} contact={doc.ClientContact} textT='CLIENT' />
              </View>
              <Text>
                {`${t("TOTAL")}: ${Formatter.currency(doc.Currency.ID).format(doc.Total)} (${Formatter.number2.format(doc.Gain / doc.Total * 100)}% - ${Formatter.currency(doc.Currency.ID).format(doc.Gain)})`}
              </Text>
            </View>
            <Button
              onPress={_duplicate}
              titleT='DUPLICATE'
              style={{
                backgroundColor: theme.blue,
              }}
            />
            <DocPreviewMenu
              doc={doc}
              contract={contract}
              constructionSite={constructionSite}
            />
            <MaterialCommunityIcons
              name="close"
              onPress={onClose}
              size={24}
            />
          </View>
          <ScrollView
            style={{
              backgroundColor: "white",
              margin: 5,
              borderRadius: 5,
              padding: 8,
              height: 400,
            }}
          >
            <View style={{flexDirection: 'row', padding: 4, marginBottom: 10}}>
              <DocumentPreviewLines doc={doc} largeView={true}/>
            </View>
          </ScrollView>
        </View> :
        <ActivityIndicator />
      }
    </Modal>
  )
};

export default DocumentVersionModal;