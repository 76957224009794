import React, { useEffect, useState } from "react";
import { Level, LineLevel, VAT } from "../../../models";
import { Text, View } from "react-native";
import { TextInput } from "react-native";
import VATSelector from "../../../components/VATSelector";
import { t } from "i18n-js";
import DualSelector from "../../../components/DualSelector";
import getTheme from "../../../constants/Colors";
import Formatter from "../../../utils/formatters";
import { PrintTwinLevel } from "./utils";

interface Props {
  lineLevel: LineLevel;
  setLineLevel: (lineLevel: LineLevel) => void;
  level: Level;
  unitOfMeasure: string;
  setTwinsPrices: (price: number, discount: number, discountNumeric: boolean) => void
  priceNoPrintByLevels?: PrintTwinLevel
}

const DocLineThreeDotsLineLevelModal = ({lineLevel, setLineLevel, level, unitOfMeasure, setTwinsPrices, priceNoPrintByLevels}: Props) => {

  const theme = getTheme();

  const [price, setPrice] = useState<string>(String(priceNoPrintByLevels ? priceNoPrintByLevels.price : lineLevel.Price))
  const [discount, setDiscount] = useState<string>(String(priceNoPrintByLevels ? priceNoPrintByLevels.discount : lineLevel.Discount))
  const [discountNumeric, setDiscountNumeric] = useState<boolean>(priceNoPrintByLevels ? priceNoPrintByLevels.discountNumeric : lineLevel.DiscountNumeric)
  const [lineTotal, setLineTotal] = useState<number>(
    priceNoPrintByLevels ?
        priceNoPrintByLevels.price * lineLevel.Quantity - (discountNumeric ? priceNoPrintByLevels.discount : priceNoPrintByLevels.discount * priceNoPrintByLevels.price / 100) :
        lineLevel.LineTotal
  )

  const _price = (text: string) => {
    const price = Number(text)

    const lineTotal = discountNumeric ?
      price * lineLevel.Quantity - Number(discount) :
      price * lineLevel.Quantity * (1 - Number(discount) / 100)
    setPrice(text)
    setLineTotal(lineTotal);

    if (priceNoPrintByLevels) {
      setTwinsPrices(price, Number(discount), discountNumeric)
      return
    }
    setLineLevel({
      ...lineLevel,
      Price: price,
      LineTotal: lineTotal,
    });
  };

  const _numericDiscount = (discountNumeric: boolean) => {
    const lineTotal = discountNumeric ?
      Number(price) * lineLevel.Quantity - Number(discount) :
      Number(price) * lineLevel.Quantity * (1 - Number(discount) / 100)
    setDiscountNumeric(discountNumeric);
    setLineTotal(lineTotal);

    if (priceNoPrintByLevels) {
      setTwinsPrices(Number(price), Number(discount), discountNumeric)
      return
    }
    setLineLevel({
      ...lineLevel,
      DiscountNumeric: discountNumeric,
      LineTotal: lineTotal,
    });
  };

  useEffect(() => {
    if (lineLevel.Price != Number(price) && !priceNoPrintByLevels)
      setPrice(String(lineLevel.Price))
  }, [lineLevel.Price])

  useEffect(() => {
    if (Number(discount) != lineLevel.Discount && !priceNoPrintByLevels)
      setDiscount(String(lineLevel.Discount))
  }, [lineLevel.Discount])

  useEffect(() => {
    if (discountNumeric != lineLevel.DiscountNumeric && !priceNoPrintByLevels)
      setDiscountNumeric(lineLevel.DiscountNumeric)
  }, [lineLevel.DiscountNumeric])

  useEffect(() => {
    if (lineTotal != lineLevel.LineTotal && !priceNoPrintByLevels)
      setLineTotal(lineLevel.LineTotal)
  }, [lineLevel.LineTotal])

  useEffect(() => {
    if (priceNoPrintByLevels) {
      setLineTotal(
        discountNumeric ?
            priceNoPrintByLevels.price * lineLevel.Quantity - priceNoPrintByLevels.discount :
            priceNoPrintByLevels.price * lineLevel.Quantity * (1 - priceNoPrintByLevels.discount / 100)
      )
    }
  }, [lineLevel.Quantity])

  const _discount = (text: string) => {
    const discount = Number(text)

    const lineTotal = discountNumeric ?
      Number(price) * lineLevel.Quantity - discount :
      Number(price) * lineLevel.Quantity * (1 - discount / 100)

    setDiscount(String(discount))
    setLineTotal(lineTotal);

    if (priceNoPrintByLevels) {
      setTwinsPrices(Number(price), discount, discountNumeric)
      return
    }
    setLineLevel({
      ...lineLevel,
      Discount: discount,
      LineTotal: lineTotal,
    })
  }

  const _vat = (vat: VAT | undefined) => {
    setLineLevel({
      ...lineLevel,
      Vat: vat,
      VatID: vat?.ID || '',
    });
  };

  const fixedPrice = lineLevel.FixedPriceBlock != "" && lineLevel.FixedPriceBlock != "-";
  const f2 = Formatter.number2.format;

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingTop: 9,
        alignItems: 'center',
      }}
    >
      <Text style={{flex: 1}}>{level.Name}</Text>

      <Text style={{flex: 1}}>{f2(lineLevel.Quantity)}</Text>

      <Text style={{flex: 1}}>{unitOfMeasure}</Text>

      {fixedPrice ?
        <Text style={{flex: 5}}>{t('NOT_AVAILABLE_FOR_FIXED_PRICE')}</Text> :
        <>
          <View style={{flex: 1, paddingHorizontal: 1}}>
            <TextInput
              value={price}
              onChangeText={_price}
              style={{borderColor: theme.border, borderWidth: 1, borderRadius: 5, padding: 2}}
              keyboardType={"number-pad"}
            />
          </View>

          <View style={{flex: 1}}>
            <DualSelector
              left={discountNumeric}
              setLeft={_numericDiscount}
              width={"100%"}
              leftLabel={"DISCOUNT_NUMERIC"}
              rightLabel={"DISCOUNT_PERCENTAGE"}
            />
          </View>

          <View style={{flex: 1, paddingHorizontal: 1}}>
            <TextInput
              value={discount}
              onChangeText={_discount}
              style={{borderColor: theme.border, borderWidth: 1, borderRadius: 5, padding: 2}}
              keyboardType={"number-pad"}
            />
          </View>

          <Text style={{flex: 1}}>{f2(lineTotal)}</Text>

          <View style={{flex: 2}}>
            <VATSelector
              vat={lineLevel.Vat}
              setVAT={_vat}
            />
          </View>
        </>
      }
    </View>
  );
}

export default DocLineThreeDotsLineLevelModal;