import React, { useEffect, useState } from "react";
import { Client } from "../../models";
import { useNavigation } from "@react-navigation/native";
import ClientScreen, { ClientMode } from "./ClientScreen";
import { useDispatch, useSelector } from "react-redux";
import { appendClient } from "../../store/action/clientsActions";
import { Reducer } from "../../store/reducers";
import { createClient } from "../../api/ClientsAPI";
import { ActivityIndicator, View } from "react-native";
import { alert } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { sentryCapture } from "../../utils/sentryCapture";
import { userCanCreateBusinessPartners } from "../../models/permissions";

const newClient = (clients:Client[]): Client => {
  let id = 0;
  for (let i = 0; i < clients.length; i++) {
    let nID = Number(clients[i].ID);
    if (!isNaN(nID) && nID < id) {
      id = nID;
    }
  }
  id--;
  return {
    ID: String(id),
    Phone: "",
    Name: "",
    VAT: "",
    Type: {
      ID: 0,
      Name: "",
    },
    Mobile: "",
    Contacts: [],
    Email: "",
    Addresses: [],
    FiscalCode: "",
    Company: false,
    IndustryID: 1,
    Industry: {
      ID: 1,
      Name: "",
    },
    EmailBillAddress: '',
    FreeText: '',
  };
};

interface Props {
  route: {
    params?: {
      setClient?: (client: Client) => void;
    }
  }
}

const CreateClientScreen = ({route}: Props) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const serverReachable = useSelector((state:Reducer) => state.sync.serverReachable);
  const clients = useSelector((state: Reducer) => state.client.clients);

  const user = useSelector((state: Reducer) => state.user)

  const [client, setClient] = useState<Client>(newClient(clients));

  useEffect(() => {
    if (!userCanCreateBusinessPartners(user)) {
      alert('ERROR', 'YOU_ARE_NOT_AUTHORIZED_TO_CREATE_CLIENTS')
      navigation.goBack()
    }
  }, [user])

  const _save = async () => {
    let updated = client;
    if (serverReachable) {
      try {
        setLoading(true);
        updated = await createClient(client);
        setLoading(false);
      } catch (e:any) {
        setLoading(false);
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
          return;
        } else {
          sentryCapture(e);
          await alert('ERROR_CREATING_CLIENT','ERROR_CREATING_CLIENT_DESC');
        }
      }
    }

    dispatch(appendClient(updated));

    if (route.params?.setClient) {
      route.params.setClient(updated);
    }
    navigation.goBack();
  }

  if (loading) {
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <ClientScreen setClient={setClient}
                  client={client}
                  mode={ClientMode.CREATE}
                  save={_save}/>
  );
};

export default CreateClientScreen;