import React from "react";
import { Contract } from "../../../models";
import { useNavigation } from "@react-navigation/native";
import Button from "../../Button";
import getTheme from "../../../constants/Colors";
import { SearchScreens } from "../../../screens/search/SearchScreens";

interface Props {
  contract: Contract;
}

const CreateDocButtonDone = ({contract}:Props) => {

  const navigation = useNavigation();
  const theme = getTheme();
  
  const _press = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.CREATE_CONTRACT, {
      constructionSiteId: contract.ConstructionSiteID,
      docTypeId: 5, // warranty
      warrantyId: contract.ID,
    });
  }

  return (
    <Button onPress={_press} titleT='CREATE_WARRANTY' style={{backgroundColor: theme.blue}}/>
  );
};

export default CreateDocButtonDone;