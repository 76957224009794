import {
  Address,
  Client, ClientOverview, Contact,
} from "../models";
import axios from "axios";

export const getClients = async (): Promise<Client[]> => {
  const response = await axios.get(`api/clients?t=${Date.now()}`);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const createClient = async (client: Client): Promise<Client> => {

  const clientID = "-1";
  client.ID = clientID;
  await client.Contacts.forEach((c:Contact, i:number) => {
    c.ClientID = clientID;
    c.ID = -i;
  });
  await client.Addresses.forEach((a:Address, i: number) => {
    if (a.Name == null || a.Name.length == 0) {
      a.Name = "Address: " + i + 1
    } else {
      a.Name = a.Name.substring(0, 50)
    }
    a.ClientID = clientID;
  });

  client.toUpdate = undefined
  const response = await axios.post("api/client/create", client);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const updateClient = async (client: Client): Promise<Client> => {

  await client.Contacts.forEach((c:Contact, i:number) => {
    c.ClientID = String(client.ID);
    if (c.ID == null || c.ID < 1) {
      c.ID = -i;
    }
  });
  await client.Addresses.forEach((a:Address, i:number) => {
    if (a.Name == null || a.Name.length == 0) {
      a.Name = "Address: " + i + 1;
    } else {
      a.Name = a.Name.substring(0, 50)
      a.ID = a.ID?.substring(0, 50)
    }
    a.ClientID = String(client.ID);
  });

  client.toUpdate = undefined
  const response = await axios.post("api/client/update", client);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const getClientOverview = async (clientId: string): Promise<ClientOverview> => {
  const response = await axios.get(`search?client=${clientId}`);

  if (response.status !== 200) {
    throw response;
  }

  return response.data;
}