import React, { useState } from "react";
import { Switch, Pressable, Text, View } from "react-native";
import { Client, Contact, DocumentContact, DocumentContactType } from "../../../models";
import DocumentContactTypeSelector from "../../../components/DocumentContactTypeSelector";
import { t } from "i18n-js";
import ChooseClientModal from "../../../components/ChooseClientModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ClientNotesModal from "../../../components/ClientNotesModal"

interface Props {
  item: DocumentContact;
  set: (item: DocumentContact) => void;
  index: number;
  remove: (index: number) => void;
}

const DocDetailsDocumentContact = ({item, index, set, remove}: Props) => {

  const [chooseModalVisible, setChooseModalVisible] = useState<boolean>(false)
  const [showClientNotesModal, setShowClientNotesModal] = useState<boolean>(false)
  const _openClientNotesModal = () => setShowClientNotesModal(true)
  const _closeClientNotesModal = () => setShowClientNotesModal(false)

  const _type = (type: DocumentContactType) => {
    set({
      ...item,
      Type: type,
      TypeID: String(type.ID),
    });
  }

  const _digital = (digital: boolean) => {
    set({
      ...item,
      DigitalDocuments: digital,
    });
  };

  const _press = () => {
    setChooseModalVisible(true);
  }

  const _remove = () => {
    remove(index);
  }

  const _client = (client: Client) => {
    set({
      ...item,
      Client: client,
      ClientID: client.ID,
      Email: "",
      Mobile: "",
      Lastname: "",
      Firstname: "",
      Name: "",
    });
  };

  const _contact = (contact: Contact) => {
    set({
      ...item,
      ClientContact: contact,
      ClientContactID: contact.ID,
    });
  };

  const _customContact = (customContact: DocumentContact) => {
    set(customContact);
  };

  let id = "", name = "", contact = "", email = "", phone = "";
  if (!item.Client) {
    name = `${item.Firstname} ${item.Lastname}`;
    email = item.Email;
    phone = item.Mobile;
  } else if (item.ClientContact) {
    contact = `${item.ClientContact.Firstname} ${item.ClientContact.Lastname}`;
    email = item.ClientContact.Email;
    phone = item.ClientContact.Phone;
  } else {
    email = item.Client.Email;
    phone = item.Client.Phone;
  }

  if (item.Client) {
    id = String(item.Client.ID);
    name = item.Client.Name;
  }

  return (
    <View style={{flexDirection: 'row', paddingTop: 9, alignItems: 'center'}}>
      <ChooseClientModal client={item.Client}
                         contact={item.ClientContact}
                         setClient={_client}
                         setContact={_contact}
                         visible={chooseModalVisible}
                         setVisible={setChooseModalVisible}
                         setCustomContact={_customContact}
                         customContact={item}/>

      <MaterialCommunityIcons
        name={"close"}
        size={24}
        onPress={_remove}
        marginRight={2}
      />

      <DocumentContactTypeSelector type={item.Type}
                                   setType={_type}/>
      <Pressable
        onPress={_press}
        style={{
          flex: 1,
          backgroundColor: 'white',
          padding: 6,
          borderRadius: 5,
          flexDirection: 'row',
          marginLeft: 5,
        }}
      >
        <Text style={{flex: 1}}>{id}</Text>
        <Text style={{flex: 2}}>{name}</Text>
        <Text style={{flex: 2}}>{contact}</Text>
        <Text style={{flex: 2}}>{email}</Text>
        <Text style={{flex: 2}}>{phone}</Text>
      </Pressable>
      {(item.Client && item.Client.FreeText.length > 0) && (
        <Pressable onPress={_openClientNotesModal}>
          <MaterialCommunityIcons
            name='comment'
            size={24}
            color='black'
            style={{paddingHorizontal: 5}}
          />
        </Pressable>
      )}
      <Text style={{paddingHorizontal: 6}}>{t('DIGITAL')}</Text>
      <Switch value={item.DigitalDocuments} onValueChange={_digital}/>

      {(item.Client && showClientNotesModal) && (
        <ClientNotesModal
          client={item.Client}
          onClose={_closeClientNotesModal}
        />
      )}
    </View>
  );
};

export default DocDetailsDocumentContact;