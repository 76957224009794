import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { setShowPlanningWeekDays } from '../../store/action/settingsAction'
import Modal from 'react-native-modal'
import { Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import FlatChoose from '../../components/FlatChoose'

interface ResourcesSettingsModalProps {
  onClose: () => void
}

const ResourcesSettingsModal = ({onClose}: ResourcesSettingsModalProps) => {

  const dispatch = useDispatch()

  const showPlanningWeekDays = useSelector(
    (state: Reducer) => state.settings.showPlanningWeekDays,
  )

  const setShowWeekReport = (value: 5 | 6 | 7) => {
    dispatch(setShowPlanningWeekDays(value))
  }

  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      onBackButtonPress={onClose}
      style={{
        alignSelf: 'center',
        width: 400,
        padding: 15,
        backgroundColor: 'white',
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 30}}>
          {t('SETTINGS')}
        </Text>

        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={onClose}
        />
      </View>
      <View style={{flex: 1, marginTop: 15}}>
        <Text style={{fontSize: 18}}>
          {t('SHOW_DAYS_BY_WEEK')}:
        </Text>
        <FlatChoose
          itemToKey={item => String(item)}
          itemToString={item => String(item)}
          options={[5, 6, 7]}
          value={showPlanningWeekDays || 5}
          onChange={setShowWeekReport}
        />
      </View>
    </Modal>
  )
}

export default ResourcesSettingsModal