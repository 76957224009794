import React from 'react'
import { Level, Report, ReportLevel } from '../../models';
import { Text, View } from 'react-native';
import getTheme from '../../constants/Colors';
import HtmlText from '../HtmlText';
import Formatter from '../../utils/formatters';

interface ExternalWorkInvoiceReportPreviewProps {
  report: Report
  index: number
  levels: Level[]
}

const ExternalWorkInvoiceReportPreview = ({
  report,
  index,
  levels,
}: ExternalWorkInvoiceReportPreviewProps) => {

  const theme = getTheme()

  const background = index % 2 == 0 ? theme.background : 'white'
  const f2 = Formatter.number2.format

  const _render = ({item, index}: {item: ReportLevel, index: number}) => {
    const level = levels.find((l) => l.ID == item.LevelID)
    return (
      <View
        key={`report-level-${item.ID}-${index}`}
        style={{
          flexDirection: 'row',
        }}
      >
        {(report.Levels?.length || 0) > 1 && (
          <Text style={{width: 100}}>
            {level?.Name}
          </Text>
        )}
        <View style={{
          width: 100,
          backgroundColor: item.Quantity > 0 ? 'rgba(234, 88, 12, 0.4)' : undefined,
          paddingVertical: 1,
          paddingHorizontal: 5,
          borderRadius: 5,
        }}>
          <Text style={{textAlign: 'right'}}>
            {f2(item.Quantity)}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <View style={{
      flexDirection: 'row',
      backgroundColor: background,
      paddingVertical: 5,
      paddingHorizontal: 5,
      borderRadius: 5,
    }}>
      <Text style={{width: 100}}>
        {report.ActivityIDString}
      </Text>
      <View style={{flex: 1}}>
        <HtmlText
          html={report.Name}
          width='100%'
        />
      </View>
      <Text style={{width: 100, textAlign: 'center'}}>
        {report.UnitOfMeasure}
      </Text>
      {report.Levels?.length == 1 ? (
        _render({item: report.Levels[0], index: 0})
      ) : (
        <View style={{width: 100}} />
      )}
    </View>
  )
}

export default ExternalWorkInvoiceReportPreview