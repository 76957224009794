import React, { useState } from "react";
import { View } from "react-native";
import NumberInput from "../../document/PostitionComponent/NumberInput";
import { Position, PositionLevel } from "../../../models";


interface Props {
  position: Position;
  positionLevel: PositionLevel;
  setPositionLevel: (positionLevel: PositionLevel) => void;
}

const DocTableLevelPosition = ({position, positionLevel, setPositionLevel}: Props) => {

  const [size, setSize] = useState<number>(positionLevel.Size);
  const [quantity, setQuantity] = useState<number>(positionLevel.Quantity);

  const _size = (value: number) => {
    setSize(value);
    setPositionLevel({Quantity: quantity, Size: value});
  };

  const _quantity = (value: number) => {
    setQuantity(value);
    setPositionLevel({Quantity: value, Size: size});
  }

  if (!position.ShowCompact) {
    return (
      <View style={{flex: 1}}>
        <View
          style={{height: 50, justifyContent: 'center'}}
        >
          <NumberInput
            min={0}
            defaultValue={positionLevel.Size}
            onChange={_size}
          />
        </View>
        <View
          style={{height: 50, justifyContent: 'center'}}
        >
          <NumberInput
            min={0}
            defaultValue={quantity}
            onChange={_quantity}
          />
        </View>
      </View>
    );
  }

  return (
    <View
      style={{flex: 1, height: 50, justifyContent: 'center'}}
    >
      <NumberInput
        min={0}
        defaultValue={quantity}
        onChange={_quantity}
      />
    </View>
  );
};

export default DocTableLevelPosition;