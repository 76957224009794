import { DocStatus } from "../../constants/Status";
import { ConstructionSite, Contract } from "../../models";

export const filterConstructionSites = (
  constructionSites: ConstructionSite[],
  search: string,
): ConstructionSite[] => {
  const s = search.trim().toLowerCase()
  if (s.length == 0) {
    return constructionSites
  }
  return constructionSites.filter(cs => {
    if (cs.Name.trim().toLowerCase().includes(s)) {
      return true
    }
    if (cs.Code.trim().toLowerCase().includes(s)) {
      return true
    }
    if (cs.IDString.trim().toLowerCase().includes(s)) {
      return true
    }
    if ((cs.Address || '').trim().toLowerCase().includes(s)) {
      return true
    }
    return false
  })
}

export const filterContracts = (
  constructionSite: ConstructionSite | undefined,
  includeOpenContracts: boolean,
): Contract[] => {
  return (constructionSite?.Contracts || []).filter(c => {
    if (c.Status == DocStatus.OFFER.code) {
      return true
    }
    if (includeOpenContracts) {
      switch (Number(c.Status)) {
        case DocStatus.OPEN.code:
        case DocStatus.IN_EXECUTION.code:
        case DocStatus.TO_BE_APPROVED.code:
        case DocStatus.TO_BE_BILLED.code:
          return true
      }
    }
    return false
  })
}