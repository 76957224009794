import React, { useState } from "react";
import { t } from "i18n-js";
import { FileType, File } from "../../../../models";
import UploadNewDocumentModal from "../../../../components/UploadNewDocumentModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { FlatList, Pressable, ScrollView, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import Button from "../../../../components/Button";
import getTheme from "../../../../constants/Colors";
import ConstructionSiteViews from "../../ConstructionSiteDetails/ConstructionSiteViews";

interface Props {
  fileType: FileType;
  setFileToOpen: (file: File) => void;
  contractId: number;
  setSelectedView: (setSelectedView: ConstructionSiteViews) => void;
}

const FileTypeView = ({fileType, setFileToOpen, contractId, setSelectedView}: Props) => {

  const theme = getTheme();

  const readLevel = useSelector((state: Reducer) => state.user.readLevel.Level);

  const [uploadNewDocumentModalVisible, setUploadNewDocumentModalVisible] = useState<boolean>(false);
  const [uploadNewDocumentFileTypeId, setUploadNewDocumentFileTypeId] = useState<number | null>(null);

  const _new = () => {
    setUploadNewDocumentFileTypeId(fileType.ID);
    setUploadNewDocumentModalVisible(true);
  }

  const _render = ({item, index}: {item: File, index: number}) => {

    const _press = () => {
      setFileToOpen(item);
      setSelectedView(ConstructionSiteViews.PDF);
    }

    return (
      <Pressable
        style={{
          padding: 6,
          flexDirection: 'row',
        }}
        onPress={_press}
      >
        <Text style={{flex: 2}}>{item.Name}</Text>
        <Text style={{flex: 1}}>{item.ReadLevel.Name}</Text>
        <Text style={{flex: 1}}>{item.UpdatedBy.FirstName} {item.UpdatedBy.LastName}</Text>
        <Text style={{flex: 1}}>{new Date(item.UpdatedDate).toLocaleString()}</Text>
      </Pressable>
    );
  }

  const _header = () => {
    return (
      <View
        style={{
          padding: 6,
          flexDirection: 'row',
          borderBottomColor: 'black',
          borderBottomWidth: 1,
          marginVertical: 3,
        }}
      >
        <Text style={{flex: 2}}>{t("NAME")}</Text>
        <Text style={{flex: 1}}>{t("READ_LEVEL")}</Text>
        <Text style={{flex: 1}}>{t("DOC_UPDATED_BY")}</Text>
        <Text style={{flex: 1}}>{t("UPDATE_DATE")}</Text>
      </View>
    );
  };

  const _separator = () => (
    <View
      style={{
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        marginHorizontal: 3,
      }}
    />
  );

  return (
    <View
      style={{
        paddingHorizontal: 3,
        flex: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={{flex: 1, fontSize: 18}}>{t("FILES")} - {fileType.Name}</Text>
        <Button
          onPress={_new}
          style={{
            backgroundColor: theme.blue,
          }}
        >
          <MaterialCommunityIcons
            name="plus"
            color="black"
            size={24}
          />
        </Button>
        <Text style={{paddingLeft: 2}}>{t("UPLOAD_NEW_FILE")}</Text>
      </View>

      <ScrollView style={{flex: 1, width: "100%"}}>

        <FlatList
          data={fileType.Files.filter(f => f.ReadLevel.Level <= readLevel)}
          renderItem={_render}
          ListHeaderComponent={_header}
          ItemSeparatorComponent={_separator}
          style={{
            backgroundColor: "white",
            borderRadius: 10,
          }}
          //@ts-ignore
          listKey={`files-${fileType.ID}`}
          key={`files-${fileType.ID}`}
          keyExtractor={(item, index) => `files-${fileType.ID}-${index}-${item.ID}`}
        />

      </ScrollView>


      <UploadNewDocumentModal visible={uploadNewDocumentModalVisible}
                              setVisible={setUploadNewDocumentModalVisible}
                              fileTypeId={uploadNewDocumentFileTypeId}
                              contractId={contractId}
                              setFileTypeId={setUploadNewDocumentFileTypeId}/>
    </View>
  );
};

export default FileTypeView;