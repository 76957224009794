import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { ConstructionSite, Contract, Doc } from "../../models";
import getTheme from "../../constants/Colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Status from "../Status";
import DocumentDetails from "../../screens/constructionSites/views/DocumentView/DocumentDetails";
import DocumentPreviewClient from "../../screens/constructionSites/views/DocumentView/DocumentPreviewClient";
import DocumentPreviewLines from "../../screens/constructionSites/views/DocumentView/DocumentPreviewLines";
import { filterLines } from '../../shared-utils/doc/lines'
import DocFooter from "../../screens/doc/DocFooter";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { setShowPriceLevel } from "../../store/action/settingsAction";
import { PriceLevel } from "../../store/reducers/settingsReducer";
import Modal from "react-native-modal";

interface Props {
  doc: Doc;
  contract: Contract;
  constructionSite: ConstructionSite;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  statusVisible?: boolean;
}

const PreviewModal = ({doc, contract, constructionSite, visible, setVisible, statusVisible = true}: Props) => {

  const theme = getTheme();

  const dispatch = useDispatch();

  const showPriceLevel = useSelector((state:Reducer) => state.settings.showPriceLevel);

  const [originalShowPriceLevel, setOriginalShowPriceLevel] = useState<number>(showPriceLevel);
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
  const [_doc, _setDoc] = useState<Doc>();

  useEffect(() => { 
    dispatch(setShowPriceLevel(PriceLevel.CLIENT));
    _setDoc({
      ...doc,
      Lines: filterLines(doc.Lines),
    });

    return () => {
      dispatch(setShowPriceLevel(originalShowPriceLevel));
    }
  }, []);

  const _close = () => {
    setVisible(false);
  }

  const _details = () => {
    setDetailsVisible(true);
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={_close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{backgroundColor: "white", margin: 5, borderRadius: 5, padding: 8}}>
        <View
          style={{
            borderBottomWidth: detailsVisible ? 1 : 0,
            borderBottomColor: theme.border,
            paddingBottom: detailsVisible ? 2 : 0
          }}
        >
          <View style={{flexDirection: 'row'}}>
            <Text>{constructionSite?.IDString || ""} {constructionSite?.Type?.Name || ""} {constructionSite?.Name} - {contract?.Name}</Text>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <MaterialCommunityIcons
              name={detailsVisible ? "chevron-up" : "chevron-down"}
              size={7} 
              color="black"
              style={{
                marginRight: 2,
              }}
              onPress={_details}
            />
            <Text style={{flex: 1, fontSize: 17}}>
              {`${doc?.Type.Name} ${doc?.IDString || ""}    ${doc?.Name || ""}`}
            </Text>

            {statusVisible && <Status status={doc.Status} style={{marginRight: 10}} size="m"/>}
          </View>
        </View>

        {detailsVisible &&
        <>
          <DocumentDetails doc={doc}/>
          <View style={{alignItems: "flex-end", paddingTop: 15}}>
            <View style={{flexDirection: "row", alignItems: "center", marginBottom: 5}}>
              <DocumentPreviewClient client={doc.Client} contact={doc.ClientContact} textT='CLIENT' />
            </View>
          </View>
        </>
        }

      </View>

      <View
        style={{
          margin: 12,
          marginBottom: 6,
          flexDirection: 'row',
          flex: 1,
          padding: 6,
          borderRadius: 15,
          backgroundColor: 'white',
        }}
      >
        {_doc && <DocumentPreviewLines doc={_doc} largeView={true}/>}
      </View>
      <View
        style={{
          marginHorizontal: 5,
          backgroundColor: "white",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        <DocFooter doc={doc}
                    setDocChanged={() => {}}
                    setDoc={() => {}}
                    lineChangeCounter={0}
                    readOnly={true}/>
      </View>
    </Modal>
  )
};

export default PreviewModal;