export const stringCompare = (a: string, b: string) => {
  return typeof a === "string" && typeof b === "string"
    ? a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
    : a === b;
};

export const stringContains = (a: string, b: string) => {
  if (!a || !b) {
    return false;
  }
  return a.toLocaleLowerCase().includes(b.toLocaleLowerCase());
};

/**
 * Convert time in string format to minutes, 01:26 = 86 minutes.
 *
 * @param time Time as string to convert to minutes, 01:26 = 86 minutes.
 * @returns Time in minutes.
 */
export const getTimeFromString = (time: string) => {
  let info = time.split(":")
  if (info.length == 2) {
    return parseInt(info[0]) * 60 + parseInt(info[1])
  }
  return null
}

/**
 * Convert time in minutes to string format, 86 minutes = 01:26.
 *
 * @param time Time in minutes, 86 minutes = 01:26.
 * @returns Hours and minutes in string format.
 */
export const getStringFromTime = (time: number) => {
  time = Math.round(time)
  let hour = Math.floor(time / 60)
  let minute = Math.round(time % 60)
  return `${hour < 10 ? "0" + hour : hour}:${
    minute < 10 ? "0" + minute : minute
  }`
}

export const getHoursAndMinutesFromString = (time: string) => {
  let info = time.split(":");
  if (info.length == 2) {
    return {
      hours: parseInt(info[0]),
      minutes: parseInt(info[1]),
    };
  }

  return {
    hours: 0,
    minutes: 0,
  };
}

export const getTimeFromMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;

  return `${hours.toString().padStart(2, "0")}:${minutesLeft
    .toString()
    .padStart(2, "0")}`;
}
