import React from "react";
import { VAT } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { t } from "i18n-js";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  vat?: VAT;
  setVAT: (vat?: VAT) => void;
}

const NO_VAT: VAT = {
  ID: '0',
  Name: t('NO_VAT'),
  IDString: '0',
  Percentage: 0,
}

const VATSelector = ({vat, setVAT}: Props) => {

  const vats = useSelector((state: Reducer) => state.general.vats);

  const _change = (selectedVat: VAT, index: number) => {
    if (vat?.ID == selectedVat.ID) {
      return;
    }
    if (index == 1) {
      setVAT(undefined)
    }

    setVAT(selectedVat)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={vat}
      onValueChange={_change}
      options={[NO_VAT, ...vats]}
      placeholder='SELECT_VAT'
      showLabelAsOption={true}
    />
  );
};

export default VATSelector;