import React from 'react'
import { ConstructionSiteType } from '../../../models'
import FlatChoose from '../../../components/FlatChoose'
import { useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'

interface NativeConstructionSiteTypeChooseProps {
  value?: ConstructionSiteType
  onChange: (value: ConstructionSiteType) => void
}

const NativeConstructionSiteTypeChoose = ({
  value,
  onChange,
}: NativeConstructionSiteTypeChooseProps) => {
  
  const types = useSelector((state: Reducer) => state.constructionSite.types)
  return (
    <FlatChoose
      options={types}
      value={value}
      onChange={onChange}
      itemToKey={(item) => String(item.ID)}
      itemToString={(item) => item.Name}
    />
  )
}

export default NativeConstructionSiteTypeChoose