import React, { useEffect, useMemo, useState } from 'react'
import { DayReport, DayReportMaterial, LineType, ReportClientConfirmation, Resource, WorkGroupFilter } from '../../../models'
import { useNavigation } from '@react-navigation/native'
import { ActivityIndicator, Pressable, Text, View } from 'react-native'
import getTheme from '../../../constants/Colors'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import { t } from 'i18n-js'
import DatePicker from '../../../components/DatePicker'
import TimePicker from '../../../components/TimePicker'
import { getHoursAndMinutesFromString, getStringFromTime, getTimeFromString } from '../../../utils/StringUtils'
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { DayReportMode } from './DayReportMode'
import {
  alertMailFinished,
  alertsDayReport,
  checkIfEmpty,
  checkIfQuantityExceed,
  checkNewPositionAdded,
  checkPreviousTwins,
  distributeCarPositions,
  getBreakTimeFromDayReport,
  getDepartureTimeFromDayReport,
  getEndTimeFromDayReport,
  getMaterialsByDayReport,
  getStartStopKey,
  getStartTimeFromDayReport,
  isDayReportEmpty,
  isWithinPreviousWeekLimit,
  placeMaterialsOnReports,
} from '../../../utils/reports/report-utils'
import { alert, alertConfirm } from '../../../hooks/Alert'
import { createDayReport, updateDayReport } from '../../../api/ReportAPI'
import { logout } from '../../../store/action/authAction'
import { DocStatus } from '../../../constants/Status'
import { updateOrderStatus } from '../../../api/DocAPI'
import { sentryCapture } from '../../../utils/sentryCapture'
import { roundToNearest15 } from '../../../shared-utils/reports/report-time-utils'
import { OPEN_DAY_REPORT } from '../DayReportsDashboard/util'
import ChooseResourcesModal from '../../../components/ChooseResourcesModal';
import DayReportNotes from './DayReportNotes'
import DayReportSettings from './DayReportSettings'
import DayReportPreviewList from './DayReportPreviewList'
import DayReportLines from './DayReportLines'
import KeyboardHide from '../../../components/KeyboardHide'
import e from '../../../App'
import storage from '../../../storage'

interface Props {
  mode: number
  initDayReport: DayReport
  initDepartureTime?: string
  uuid?: string
  initMaterials?: DayReportMaterial[]
  initShowNotes?: boolean
}

enum DayReportViewTab {
  LINES,
  SETTINGS,
  PREVIEW,
  NOTES,
}

const DayReportView = ({mode, initDayReport, initDepartureTime, uuid, initMaterials, initShowNotes = false}: Props) => {

  const navigation = useNavigation()
  const dispatch = useDispatch()

  const theme = getTheme()

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)
  const reportCreateLimitFromWeekStart = useSelector((state: Reducer) => state.user.ReportCreateLimitFromWeekStart)
  const reportClientConfirmation = useSelector((state: Reducer) => state.user.permissions.reportClientConfirmation)

  const catalogs = useSelector((state: Reducer) => state.general.catalogs)

  const [changed, setChanged] = useState<boolean>(mode == DayReportMode.CREATE && uuid != undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const [tab, setTab] = useState<DayReportViewTab>(
    mode == DayReportMode.CREATE ? DayReportViewTab.NOTES : DayReportViewTab.PREVIEW
  )
  const [chooseResourcesModalOpen, setChooseResourcesModalOpen] = useState<boolean>(false)

  const [departureTime, setDepartureTime] = useState<string | undefined>(getDepartureTimeFromDayReport(initDayReport, mode, initDepartureTime))
  const [startTime, setStartTime] = useState<string | undefined>(getStartTimeFromDayReport(initDayReport, mode))
  const [breakTime, setBreakTime] = useState<string | undefined>(getBreakTimeFromDayReport(initDayReport, mode))
  const [endTime, setEndTime] = useState<string | undefined>(getEndTimeFromDayReport(initDayReport, mode))

  const [breakBegin, setBreakBegin] = useState<string | undefined>()

  const [dayReport, setDayReport] = useState<DayReport>(initDayReport)

  const [materials, setMaterials] = useState<DayReportMaterial[]>(
    initMaterials || getMaterialsByDayReport(initDayReport)
  )

  useEffect(() => {
    if (uuid && uuid.length > 0) {
      storage.setItem(getStartStopKey(uuid), JSON.stringify({
        dayReport,
        departureTime,
        uuid,
        materials,
      }))
    }
  }, [dayReport, departureTime, materials])

  const driving = useMemo(() => {
    return dayReport
        .Reports
        ?.find(r => r.Type == LineType.POSITION_CAR)
        ?.Levels
        ?.some(l => l.Quantity > 0) || false
  }, [dayReport.Reports])

  const _changed = () => setChanged(true)

  const _driving = () => {
    _changed()
    setDayReport(d => ({
      ...d,
      Reports: d.Reports?.map((r, i, a) => {
        if (r.Type == LineType.POSITION_CAR && i == a.findIndex(r => r.Type == LineType.POSITION_CAR)) {
          return {
            ...r,
            Levels: r.Levels?.map(l => ({
              ...l,
              Quantity: l.Quantity == 0 ? 1 : 0
            }))
          }
        }

        return r
      })
    }))
  }

  const _setDayReport = (f: (dayReport: DayReport) => DayReport) => {
    _changed()
    setDayReport(d => f(d))
  }

  const _settings = () => {
    if (tab == DayReportViewTab.SETTINGS) {
      if (mode == DayReportMode.CREATE) {
        setTab(DayReportViewTab.LINES)
      } else {
        setTab(DayReportViewTab.PREVIEW)
      }
    } else {
      setTab(DayReportViewTab.SETTINGS)
    }
  }
  const _modify = () => {
    if (tab == DayReportViewTab.LINES && mode == DayReportMode.UPDATE) {
      setTab(DayReportViewTab.PREVIEW)
    } else {
      setTab(DayReportViewTab.LINES)
    }
  }
  const _notes = () => {
    if (tab == DayReportViewTab.NOTES) {
      if (mode == DayReportMode.CREATE) {
        setTab(DayReportViewTab.LINES)
      } else {
        setTab(DayReportViewTab.PREVIEW)
      }
    } else {
      setTab(DayReportViewTab.NOTES)
    }
  }
  const _lines = () => setTab(DayReportViewTab.LINES)

  const _chooseResourcesModalClose = () => setChooseResourcesModalOpen(false)
  const _saveAndSplit = () => setChooseResourcesModalOpen(true)

  const _date = (date: Date | undefined) => {
    if (date) {
      if (!isWithinPreviousWeekLimit(date, reportCreateLimitFromWeekStart)) {
        setDayReport(d => ({...d, DateTime: new Date(d.DateTime)}))
        alert("ERROR", "DATE_BEFORE_WEEK_START")
        return
      }
    }
    setDayReport((old) => {
      if (date != undefined) {
        _changed()
        if (startTime) {
          const {hours, minutes} = getHoursAndMinutesFromString(startTime)
          date.setHours(hours)
          date.setMinutes(minutes)
        }
        return {
          ...old,
          DateTime: date,
        }
      }

      return old
    })
  }

  const _updateCarTime = (departureTime: string, startTime?: string) => {
    setDepartureTime(departureTime)
    setStartTime(startTime)
    if (startTime) {
      const departure = getTimeFromString(departureTime) || 0
      const start = getTimeFromString(startTime) || 0

      if (departure < 300) {
        // if less than 5:00 alert
        alert("WARNING", "DEPARTURE_TIME_TOO_EARLY")
      }

      const travelTime = start - departure
      setDayReport(old => {
        const firstTravelIndex = old
          .Reports
          ?.findIndex(r => r.Type == LineType.POSITION_CAR)

        _changed()
        const {hours, minutes} = getHoursAndMinutesFromString(startTime)
        const date = old.DateTime
        date.setHours(hours)
        date.setMinutes(minutes)

        return {
          ...old,
          DateTime: date,
          TravelTime: travelTime,
          Reports: old.Reports?.map((r, i) => {
            if (r.Type == LineType.POSITION_CAR && i == firstTravelIndex) {
              return {
                ...r,
                Duration: travelTime / 60
              }
            }
            return r
          }),
        }
      })
    }
  }
  
  const _departureTime = (departureTime: string) => {
    setDepartureTime(departureTime)
    if (startTime) {
      _updateCarTime(departureTime, startTime)
    }
  }

  const _departureTimeNow = () => {
    const now = roundToNearest15(new Date())
    const departureTime = getStringFromTime(now.getHours() * 60 + now.getMinutes())
    _departureTime(departureTime)
  }

  const _startTime = (s: string) => {
    if (departureTime) {
      _updateCarTime(departureTime, s)
    }
  }

  const _startTimeNow = () => {
    const now = roundToNearest15(new Date())
    const startTime = getStringFromTime(now.getHours() * 60 + now.getMinutes())
    _startTime(startTime)
    setTab(DayReportViewTab.LINES)
  }

  const _breakTime = (event: string) => {
    setBreakTime(event)
    setBreakBegin(undefined)
    const time = getTimeFromString(event) || 0

    if (time != null) {
      setDayReport(old => {
        _changed();
        return {
          ...old,
          BreakTime: time,
        }
      })
    }
  }

  const _breakTimeNow = () => {
    if (breakBegin) {
      const time = roundToNearest15(new Date())
      const b = time.getHours() * 60 + time.getMinutes() - (getTimeFromString(breakBegin) || 0)
      const breakTime = getStringFromTime(b)
      _breakTime(breakTime)
      setBreakBegin(undefined)
    } else {
      const time = roundToNearest15(new Date())
      const breakTime = getStringFromTime(time.getHours() * 60 + time.getMinutes())
      setBreakBegin(breakTime)
    }
  }

  const _endTime = (event: string) => {
    setEndTime(event)
    const time = (getTimeFromString(event) || 0) - (getTimeFromString(startTime || "00:00") || 0)

    if (time != null) {
      setDayReport(old => {
        _changed();
        return {
          ...old,
          TotalTime: time,
        }
      })
    }
  }

  const _endTimeNow = () => {
    const now = roundToNearest15(new Date())
    const endTime = getStringFromTime(now.getHours() * 60 + now.getMinutes())
    _endTime(endTime)
  }

  const _cancel = async () => {
    if (mode == DayReportMode.CREATE && tab == DayReportViewTab.PREVIEW) {
      setTab(DayReportViewTab.LINES)
      return
    }
    storage.removeItem(OPEN_DAY_REPORT)
    navigation.goBack()
  }

  const save = async (close: boolean, resources?: Resource[], drivers?: Resource[]) => {
    if (!startTime || !endTime || !breakTime) {
      return
    }
    if (isDayReportEmpty(dayReport, materials)) {
      if (!await alertConfirm('WARNING', 'DAY_REPORT_EMPTY_CONTINUE')) {
        return
      }
    }
    if (wrongPreviousTwins.length > 0) {
      if (!await alertConfirm('WARNING', 'DAY_REPORT_WRONG_PREVIOUS_TWINS')) {
        return
      }
    }
    setLoading(true)
    let placed;
    try {
      placed = placeMaterialsOnReports(dayReport, materials, catalogs)
    } catch (e) {
      alert("ERROR", "ERROR_PLACING_MATERIALS_AND_TIME")
      sentryCapture(e)
      return
    }
    const d = {
      ...placed,
      BreakTime: getTimeFromString(breakTime) || 0,
      TotalTime: (getTimeFromString(endTime) || 0) - (getTimeFromString(startTime) || 0),
      TravelTime: placed.Reports?.reduce((p, v) => {
        if (v.Type === LineType.POSITION_CAR) {
          return p + (v.Duration || 0) * 60
        }
        return p
      }, 0) || 0,
    }
    if (d.BreakTime > d.TotalTime) {
      alert("ERROR", "BREAK_CANT_BE_BIGGER_THAN_TOTAL_TIME")
      setLoading(false)
      return
    }
    let difference = d.TotalTime - d.BreakTime + d.TravelTime - (d.Reports?.reduce((p, v) => p + (v.Duration || 0), 0) || 0) * 60
    if (Math.abs(difference) >= 1) {
      // included one minute tollerance
      alert("ERROR", "ERROR_IN_TIME_MANAGEMENT")
      setLoading(false)
      return
    }
    try {
      if (mode === DayReportMode.CREATE) {
        const addedPosition = checkNewPositionAdded(dayReport)
        const quantityExceed = checkIfQuantityExceed(dayReport)
        if (checkIfEmpty(dayReport)) {
          if (!await alertConfirm('WARNING', 'DAY_REPORT_EMPTY_CONTINUE')) {
            return null
          }
        }
        const updatedDayReport = await createDayReport(d, close)
        if (updatedDayReport == null) {
          setLoading(false)
          return
        }
        alertsDayReport(updatedDayReport, addedPosition, quantityExceed)
        if (close) alertMailFinished(dayReport)

        if (updatedDayReport.Contract.Status !== DocStatus.IN_EXECUTION.code) {
          const status = close ? DocStatus.TO_BE_APPROVED.code : DocStatus.IN_EXECUTION.code
          updateOrderStatus(updatedDayReport.ContractID, status)
        }
        setLoading(false)

        if (uuid) {
          await storage.removeItem(getStartStopKey(uuid))
        }

        navigation.goBack()
      } else if (mode === DayReportMode.UPDATE) {
        let dayReport = d
        const filteredResources = resources?.filter(r => r.ID != dayReport.ResourceID)
        if (filteredResources && filteredResources.length > 0) {
          dayReport = {
            ...d,
            Reports: d.Reports?.map((r, i) => {
              if (r.Type == LineType.POSITION_CAR) {
                return distributeCarPositions(d, r, d.Resource, i, drivers)
              }
              if (r.WorkGroup == WorkGroupFilter.HOURS_POSITION) {
                return {
                  ...r,
                  Materials: r.Materials?.map(m => ({
                    ...m,
                    Quantity: (m.Quantity || 0) / (filteredResources.length + 1),
                  })),
                }
              }
              return {
                ...r,
                Levels: r.Levels?.map(l => ({
                  ...l,
                  Quantity: l.Quantity / (filteredResources.length + 1),
                })),
                Materials: r.Materials?.map(m => ({
                  ...m,
                  Quantity: (m.Quantity || 0) / (filteredResources.length + 1),
                })),
              }
            }),
          }
        }
        const addedPosition = checkNewPositionAdded(dayReport)
        const quantityExceed = checkIfQuantityExceed(dayReport)
        let updatedDayReport = await updateDayReport(d)
        setDayReport(updatedDayReport)
        alertsDayReport(updatedDayReport, addedPosition, quantityExceed)
        if (close) alertMailFinished(dayReport)
        if (filteredResources && filteredResources.length > 0) {
          dayReport.Reports = dayReport.Reports?.map(r => ({
            ...r,
            ID: -1,
            Levels: r.Levels?.map(l => ({...l, ID: -1})),
            Materials: r.Materials?.map(m => ({...m, ID: '-1'})),
          }))
          let createdDayReportsIds: number[] = []
          for (let i = 0; i < filteredResources.length; i++) {
            const splitDayReportCreated = await createDayReport({
              ...dayReport,
              ID: -1,
              ResourceID: filteredResources[i].ID,
              Resource: filteredResources[i],
              Notes: t('DAY_REPORT_SPLIT_BY', {
                id: updatedDayReport.ID,
                notes: dayReport.Notes,
              }),
              Reports: dayReport.Reports?.map((r, reportIndex) => {
                if (r.Type === LineType.POSITION_CAR) {
                  return distributeCarPositions(dayReport, r, filteredResources[i], reportIndex, drivers)
                }
                return r
              })
            }, close)
            if (splitDayReportCreated !== null) {
              createdDayReportsIds.push(splitDayReportCreated.ID)
            }
          }
          if (createdDayReportsIds.length > 0) {
            updatedDayReport = await updateDayReport({
              ...updatedDayReport,
              Reports: updatedDayReport.Reports?.map((r, i) => {
                if (r.Type === LineType.POSITION_CAR) {
                  return distributeCarPositions(dayReport, r, updatedDayReport.Resource, i, drivers)
                }
                if (r.WorkGroup == WorkGroupFilter.HOURS_POSITION) {
                  return {
                    ...r,
                    Materials: r.Materials?.map(m => ({
                      ...m,
                      Quantity: (m.Quantity || 0) / (filteredResources.length + 1),
                    })),
                  }
                }
                return {
                  ...r,
                  Levels: r.Levels?.map(l => ({
                    ...l,
                    Quantity: l.Quantity / (filteredResources.length + 1),
                  })),
                  Materials: r.Materials?.map(m => ({
                    ...m,
                    Quantity: (m.Quantity || 0) / (filteredResources.length + 1),
                  })),
                }
              }),
              Notes: t('DAY_REPORT_SPLIT_WITH', {
                ids: createdDayReportsIds.join(", "),
                notes: updatedDayReport.Notes,
              }),
            })
          }
        }
        setDayReport(updatedDayReport)
        setTab(DayReportViewTab.LINES)
      }
      storage.removeItem(OPEN_DAY_REPORT)
    } catch (e: any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
        dispatch(logout())
      } else {
        sentryCapture(e)
        alert("ERROR_UPDATING", "ERROR_CREATING_DAY_REPORT")

        if (uuid) {
          await storage.removeItem(getStartStopKey(uuid))
        }

        navigation.goBack()
      }
    } finally {
      setLoading(false)
      setChanged(false)
    }
  }

  const _save = () => {
    if (tab == DayReportViewTab.PREVIEW) {
      save(false)
    } else {
      setTab(DayReportViewTab.PREVIEW)
    }
  }

  const _chooseResources = (resources: Resource[], drivers?: Resource[]) => {
    _chooseResourcesModalClose()
    save(false, resources, drivers)
  }

  const _workFinished = async () => save(true)

  const wrongPreviousTwins = useMemo(
    () => checkPreviousTwins(dayReport),
    [dayReport],
  )

  const canSave = useMemo(() => {
    if (!serverReachable) {
      return false
    }
    if (!startTime || !endTime || !breakTime) {
      return false
    }
    if (reportClientConfirmation == ReportClientConfirmation.REQUIRED && !dayReport.Signature) {
      return false
    }
    if (!changed) {
      return false
    }
//    if (wrongPreviousTwins.length > 0) {
//      return false
//    }
    return true
  }, [startTime, endTime, breakTime, dayReport.Signature, changed, wrongPreviousTwins])

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      {chooseResourcesModalOpen && (
        <ChooseResourcesModal
          onChoose={_chooseResources}
          onClose={_chooseResourcesModalClose}
          includeSelf={true}
          chooseDrivers={true}
          selfDriving={driving}
        />
      )}

      <KeyboardHide active={true}>
      <View style={{flexDirection: 'row', backgroundColor: theme.thirdBackground}}>
        <MaterialCommunityIcons
          size={34}
          name='chevron-left'
          onPress={_cancel}
          style={{alignSelf: 'center', padding: 15}}
        />
        <Text style={{fontSize: 24, alignSelf: 'center', marginRight: 15}}>
          {dayReport.ConstructionSite.Code} 
        </Text>
        
        <View style={{flex: 1, paddingVertical: 15}}>
          <Text style={{fontSize: 20}}>
            {dayReport.ConstructionSite.Name}
          </Text>
          <Text style={{fontSize: 18}}>
            {dayReport.Contract.Name}
          </Text>
        </View>

        <View style={{
          alignItems: 'flex-end',
          justifyContent: 'center',
          paddingHorizontal: 10,
          backgroundColor: theme.mainColor,
        }}>
          {(dayReport.ID > 0) && (
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Text style={{fontSize: 20, alignItems: 'center', fontWeight: '600', alignSelf: 'center', color: 'white'}}>
                {dayReport.ID}
              </Text>
            </View>
          )}
        </View>

        <Pressable
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 20,
            backgroundColor: theme.thirdBackground,
          }}
          onPress={_driving}
        >
          <MaterialCommunityIcons
            name={'steering'}
            size={34}
            color={driving ? theme.mainColor : theme.tint}
          />
        </Pressable>

        <Pressable
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 20,
            backgroundColor: theme.thirdBackground,
          }}
          onPress={_notes}
        >
          <MaterialCommunityIcons
            name={'information-outline'}
            size={34}
            color={tab == DayReportViewTab.NOTES ? theme.mainColor : theme.tint}
          />
        </Pressable>

        <Pressable
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 20,
            backgroundColor: theme.thirdBackground,
          }}
          onPress={_modify}
        >
          <MaterialCommunityIcons
            name='pencil'
            size={34}
            color={tab == DayReportViewTab.LINES ? theme.mainColor : theme.tint}
          />
        </Pressable>

        <Pressable
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 20,
            backgroundColor: theme.thirdBackground,
          }}
          onPress={_settings}
        >
          <MaterialIcons
            name='settings'
            size={34}
            color={tab == DayReportViewTab.SETTINGS ? theme.mainColor : theme.tint}
          />
        </Pressable>

        <View style={{backgroundColor: canSave ? theme.mainColor : theme.secondaryBackground, justifyContent: 'center', paddingHorizontal: 5}}>
          <FontAwesome
            size={34}
            name='send'
            color='white'
            disabled={!canSave}
            style={{padding: 15}}
            onPress={_save}
          />
        </View>
      </View>

      <View style={{
        paddingTop: 15,
        paddingRight: 15,
        paddingLeft: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        alignItems: 'center'
      }}>
        <DatePicker
          value={dayReport.DateTime}
          onChange={_date}
          disabled={tab == DayReportViewTab.PREVIEW}
        />

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderColor: theme.secondaryBackground,
          borderWidth: 1,
          alignSelf: 'center',
        }}>
          <View style={{
            backgroundColor: departureTime ? theme.secondaryBackground : theme.mainColor,
            width: 92,
            alignItems: 'center',
          }}>
            <MaterialCommunityIcons
              size={40}
              name='van-utility'
              color='white'
              style={{paddingHorizontal: 5}}
              onPress={_departureTimeNow}
              disabled={tab == DayReportViewTab.PREVIEW}
            />
          </View>
          <TimePicker
            style={{minWidth: 70}}
            value={departureTime || "00:00"}
            onChange={_departureTime}
            disabled={tab == DayReportViewTab.PREVIEW}
          />
        </View>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderColor: theme.secondaryBackground,
          borderWidth: 1,
          alignSelf: 'center',
          paddingRight: 1,
        }}>
          <Pressable
            style={{
              justifyContent: 'center',
              backgroundColor: departureTime ? (startTime ? theme.secondaryBackground : theme.mainColor ) : theme.thirdBackground,
              paddingVertical: 7,
              borderRightWidth: 1,
              borderColor: theme.border,
            }}
            disabled={departureTime == undefined || tab == DayReportViewTab.PREVIEW}
            onPress={_startTimeNow}
          >
            <Text style={{
              color: departureTime ? 'white' : 'black',
              paddingHorizontal: 10,
              fontSize: 20,
              width: 92,
              textAlign: 'center',
            }}>
              {t('BEGIN').toUpperCase()}
            </Text>
          </Pressable>
          <TimePicker
            style={{minWidth: 70}}
            value={startTime || "00:00"}
            onChange={_startTime}
            disabled={departureTime == undefined || tab == DayReportViewTab.PREVIEW}
            minimumTime={departureTime}
          />
        </View>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderColor: theme.secondaryBackground,
          borderWidth: 1,
          alignSelf: 'center',
          paddingRight: 1,
          flexWrap: 'wrap',
        }}>
          <Pressable
            style={{
              justifyContent: 'center',
              backgroundColor: startTime ? (endTime ? theme.secondaryBackground : theme.mainColor) : theme.thirdBackground,
              paddingVertical: 7,
              borderRightWidth: 1,
              borderColor: theme.border,
            }}
            disabled={startTime == undefined || tab == DayReportViewTab.PREVIEW}
            onPress={_endTimeNow}
          >
            <Text style={{
              color: startTime ? 'white' : 'black',
              paddingHorizontal: 10,
              fontSize: 20,
              width: 92,
              textAlign: 'center',
            }}>
              {t('END').toUpperCase()}
            </Text>
          </Pressable>
          <TimePicker
            style={{minWidth: 70}}
            value={endTime || "00:00"}
            onChange={_endTime}
            disabled={startTime == undefined || tab == DayReportViewTab.PREVIEW}
          />
        </View>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderColor: theme.secondaryBackground,
          borderWidth: 1,
          alignSelf: 'center',
          paddingRight: 1,
        }}>
          <Pressable
            style={{
              justifyContent: 'center',
              backgroundColor: (startTime && endTime) ? theme.mainColor : theme.thirdBackground,
              paddingVertical: 7,
              borderRightWidth: 1,
              borderColor: theme.border,
            }}
            disabled={startTime == undefined || tab == DayReportViewTab.PREVIEW}
            onPress={_breakTimeNow}
          >
            <Text style={{
              color: startTime && endTime ? 'white' : 'black',
              paddingHorizontal: 10,
              fontSize: 20,
              width: 92,
              textAlign: 'center',
            }}>
              {t('BREAK').toUpperCase()}
            </Text>
          </Pressable>
          <TimePicker
            style={{minWidth: 70}}
            value={breakTime || "00:00"}
            onChange={_breakTime}
            disabled={startTime == undefined || tab == DayReportViewTab.PREVIEW}
          />
        </View>
      </View>
      </KeyboardHide>

      {tab == DayReportViewTab.NOTES && (
        <DayReportNotes
          dayReport={dayReport}
          closeNotes={_notes}
        />
      )}

      {tab == DayReportViewTab.PREVIEW && (
        <>
          <View style={{flexDirection: 'row', padding: 5}}>
            <Text style={{width: 100}}>{t("CODE")}</Text>
            <Text style={{flex: 3}}>{t("NAME")}</Text>
            <Text style={{flex: 1, textAlign: 'center'}}>{t("UNIT_OF_MEASURE")}</Text>
            <Text style={{flex: 1, textAlign: 'right'}}>{t("QUANTITY")}</Text>
            <Text style={{flex: 1, textAlign: 'right'}}>{t("TODO")}</Text>
            <Text style={{flex: 1, textAlign: 'right'}}>{t("TOTAL")}</Text>
          </View>
          <DayReportPreviewList
            dayReport={dayReport}
            loading={loading}
            mode={mode}
            workFinished={_workFinished}
            saveAndSplit={_saveAndSplit}
          />
        </>
      )}

      {tab == DayReportViewTab.SETTINGS && (
        <DayReportSettings
          dayReport={dayReport}
          setDayReport={_setDayReport}
          mode={mode}
          saveAndSplit={_saveAndSplit}
        />
      )}

      {tab == DayReportViewTab.LINES && (
        <DayReportLines
          dayReport={dayReport}
          setDayReport={_setDayReport}
          materials={materials}
          setMaterials={setMaterials}
          setChanged={_changed}
          wrongPreviousTwins={wrongPreviousTwins}
        />
      )}

      {loading &&
        <View style={{
          zIndex: 100,
          height: "100%",
          width: "100%",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,.7)"
        }}>
          <ActivityIndicator color={theme.mainColor}/>
        </View>
      }
    </View>
  );
}

export default DayReportView