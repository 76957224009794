import React, { useEffect, useState } from "react";
import { ConstructionSite, Doc, Marginality } from "../../models";
import { View, Text } from "react-native";
import { t } from "i18n-js";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import DualSelector from "../DualSelector";
import { marginality } from "../../screens/doc/utils";
import { alertText } from "../../hooks/Alert";
import LoadPdfButton from "../LoadPdfButton";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import MarginalityOverview from "./MarginalityOverview";
import Modal from "react-native-modal";
import { sentryCapture } from "../../utils/sentryCapture";

interface Props {
  doc: Doc;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  getPDFVisible?: boolean;
  constructionSite?: ConstructionSite;
}

const MarginalityModal = ({doc, visible, setVisible, getPDFVisible = false, constructionSite}: Props) => {

  const url = useSelector((state:Reducer) => state.auth.workSpace!.url);
  const salesDiscount = useSelector((state:Reducer) => state.user.permissions.salesDiscount)
  const salesUnderMinimumPrice = useSelector((state:Reducer) => state.user.permissions.salesUnderMinimumPrice)

  const [onlyDoc, setOnlyDoc] = useState<boolean>(true);
  const [_m, setMarginality] = useState<Marginality>();

  useEffect(() => {
    console.log("MarginalityModal > useEffect", doc);
    marginality(doc, salesDiscount, salesUnderMinimumPrice).then(setMarginality).catch(e => {
      sentryCapture(e);
      alertText("Error", String(e)).then(); // TODO translate
      setVisible(false);
    });
  }, []);


  const _close = () => {
    setVisible(false);
  }

  if (!_m) {
    return <></>
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={_close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: 'row'}}>
        <Text style={{fontSize: 25, flex: 1}}>{t("MARGINALITY")}</Text>
        <MaterialCommunityIcons
          name={"close"}
          size={24}
          onPress={_close}
        />
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>{t("MARGINALITY_DOC_CONSTRUCTION_SITE")}</Text>
        <DualSelector
          left={onlyDoc}
          setLeft={setOnlyDoc}
          width={300}
          leftLabel={"DOCUMENT"}
          rightLabel={"CONSTRUCTION_SITE"}
        />

        {getPDFVisible && (
          <View style={{marginLeft: 6}}>
            <LoadPdfButton buttonTitle="PDF"
                            modalTitle={`${t("MARGINALITY")} ${doc.Type.Name}: ${doc.IDString}`}
                            url={`${url}/getMarginality`}
                            params={[
                            {key: 'documentType', value: String(doc.Type.ID)},
                            {key: 'documentEntry', value: String(doc.ID)}
                            // TODO add auth token
                            ]}/>
          </View>
        )}
      </View>
      
      <MarginalityOverview marginality={_m} constructionSite={onlyDoc ? undefined : constructionSite} />
    </Modal>
  );
};

export default MarginalityModal;