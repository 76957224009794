import React, { useEffect, useRef, useState } from "react";
import { StyleProp, View, ViewStyle, TextInput, Platform } from "react-native";
import { t } from "i18n-js";
import NumberInputIcon from "./NumberInputIcon";
import { alertText } from "../../../../hooks/Alert";
import getTheme from "../../../../constants/Colors";

interface Props {
  min?: number;
  max?: number;
  defaultValue?: number;
  onChange?: (value: number) => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const NumberInput = ({min, max, defaultValue = 0, onChange, style, disabled = false}: Props) => {

  const timer = useRef<any>(null);

  const theme = getTheme();

  const [_value, _setValue] = useState<string>(defaultValue !== 0 ? `${defaultValue}` : "");
  const [invalid, setInvalid] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<number>(12);

  useEffect(() => {
    if (timer.current == null) {
      _setValue(defaultValue !== 0 ? `${defaultValue}` : "")
    }
  }, [defaultValue]);

  useEffect(() => {
    const n = Number(_value);
    if (n && !isNaN(n)) {
      adjustFont(n);
    }
  }, [_value]);

  const adjustFont = (v: number) => {
    const length = String(v).length;

    if (length > 5) {
      setFontSize(6);
    } else if (length > 4) {
      setFontSize(7);
    } else if (length > 3) {
      setFontSize(9);
    } else if (length > 2) {
      setFontSize(11);
    } else {
      setFontSize(12);
    }
  };

  const parse = (value: number): number => {
    if (min && value < min) {
      throw `${t("MINIMUM_VALUE")}: ${min}`;
    }
    if (max && value > max) {
      throw `${t("MAXIMUM_VALUE")}: ${max}`;
    }

    if (timer.current) clearTimeout(timer.current);

    if (onChange) {
      timer.current = setTimeout(() => {
        onChange(value);
        if (value == 0) {
          _setValue("");
        }
        timer.current = null;
      });
    }
    
    return value;
  }

  const _decrease = () => {
    if (disabled) {
      return;
    }
    _setValue(old => {
      const n = Number(old);
      try {
        return `${parse(n - 1)}`;
      } catch (message) {
        alertText(String(message));
      }
      return old;
    });
  }

  const _increase = () => {
    if (disabled) {
      return;
    }
    _setValue(old => {
      const n = Number(old);
      try {
        return `${parse(n + 1)}`;
      } catch (message) {
        alertText(String(message));
      }
      return old;
    });
  }

  const _blur = () => {
    if (disabled) {
      return;
    }
    try {
      const n = Number(_value);
      if (isNaN(n)) {
        throw t("NOT_VALID_NUMBER");
      }
      parse(n);
    } catch (message) {
      setInvalid(true);
      alertText(String(message));
    }
  }

  return (
    <View
      style={[
        {
          alignItems: 'center',
          flexDirection: 'row',
          borderWidth: 1,
          borderRadius: 5,
          padding: 1,
          margin: 1,
          borderColor: invalid ? 'red' : theme.border,
        }, 
        style
      ]}
    >
      <NumberInputIcon
        disabled={disabled}
        onPress={_decrease}
        name="minus"
        style={{marginRight: 1}}
      />

      <TextInput
        value={_value}
        onChangeText={_setValue}
        textAlign="center"
        style={{
          flex: Platform.OS == "web" ? undefined : 1,
          width: Platform.OS == "web" ? '100%' : undefined,
          fontSize: fontSize,
          textAlign: 'center',
          paddingVertical: 3,
        }}
        placeholder="-"
        keyboardType="number-pad"
        editable={!disabled}
        onBlur={_blur}
      />

      <NumberInputIcon
        disabled={disabled}
        onPress={_increase}
        name="plus"
        style={{marginLeft: 1}}
      />
    </View>
  );
};

export default NumberInput;