import React, { useMemo, useState } from 'react'
import { DayReport, DayReportMaterial, Item, Level, LineType, Report, WorkGroup, WorkGroupFilter } from '../../../models'
import { KeyboardAvoidingView, Pressable, Text, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import getTheme from '../../../constants/Colors'
import { t } from 'i18n-js'
import { childCheck, getProgressByLevels, getProgressByWorkGroups, getTheoricalMaterials, ReportTwinCheck } from '../../../utils/reports/report-utils'
import DayReportLine from './DayReportLine'
import { useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import { itemToReport } from '../../../shared-utils/reports/convert'
import { useNavigation } from '@react-navigation/native'
import { SearchScreens } from '../../search/SearchScreens'
import DayReportMaterials from './DayReportMaterials'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface DayReportLinesProps {
  dayReport: DayReport
  setDayReport: (f: (dayReport: DayReport) => DayReport) => void
  materials: DayReportMaterial[]
  setMaterials: React.Dispatch<React.SetStateAction<DayReportMaterial[]>>
  wrongPreviousTwins: ReportTwinCheck[]
  setChanged: () => void
}

const DayReportLines = ({dayReport, setDayReport, materials, setMaterials, wrongPreviousTwins, setChanged}: DayReportLinesProps) => {

  const theme = getTheme()
  const navigation = useNavigation()

  const allWorkGroups = useSelector((state: Reducer) => state.general.workGroups)
  const workAddActivity = useSelector(
    (state: Reducer) => state.user.permissions.workAddActivity
  )
 
  const [selectedLevel, setSelectedLevel] = useState<number | undefined>(dayReport.Levels.length == 1 ? 0 : undefined) 
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<number | undefined>()

  const workGroups: WorkGroup[] = useMemo(() => {
    const workGroupFilters = dayReport.Reports?.map(r => r.WorkGroup) || []
    const data:WorkGroup[] = allWorkGroups.filter(w =>workGroupFilters.includes(w.workGroupFilter))
    if (data.length == 0) {
      setSelectedWorkGroup(dayReport.Levels.length)
    }
    data.sort((a, b) => b.weight - a.weight)
    const maxWeight = data.length > 0 ? data[data.length - 1].weight : 0
    data.push({name: t(WorkGroupFilter.OTHER), weight: maxWeight + 1, workGroupFilter: WorkGroupFilter.OTHER})
    data.push({name: t(WorkGroupFilter.MATERIALS), weight: maxWeight + 1, workGroupFilter: WorkGroupFilter.MATERIALS})
    if (workAddActivity) {
      data.push({name: t(WorkGroupFilter.ADD_WORK), weight: maxWeight + 1, workGroupFilter: WorkGroupFilter.ADD_WORK})
    }
    return data
  }, [])

  const workGroupFilter = useMemo(() => {
    if (!selectedWorkGroup) {
      return undefined
    }
    const index = selectedWorkGroup - dayReport.Levels.length
    return workGroups[index].workGroupFilter
  }, [selectedWorkGroup, workGroups])

  const reports = useMemo(() => {
    if (!workGroupFilter) {
      return []
    }
    if (workGroupFilter == WorkGroupFilter.OTHER) {
      return dayReport
        .Reports
        ?.map(r => ({...r, WorkGroup: WorkGroupFilter.HOURS_POSITION == r.WorkGroup ? '' : r.WorkGroup})) // remove work group horus position
        ?.filter((r, _, a) => {
          if (r.Type == LineType.POSITION_CAR) {
            return false
          }
          if (r.Type == LineType.TITLE) {
            return childCheck(r.LineCode, a.filter(w => w.WorkGroup == '' || w.Type == LineType.TITLE), true, true)
          }
          return !workGroups.some(w => r.WorkGroup == w.workGroupFilter)
        }) || []
    }
    return dayReport
      .Reports
      ?.filter(r => r.WorkGroup == workGroupFilter) || []
  }, [dayReport, workGroupFilter, workGroups])

  const levelId = useMemo(() => {
    if (selectedLevel == undefined) {
      return undefined
    }
    return dayReport.Levels[selectedLevel].ID
  }, [dayReport.Levels, selectedLevel])

  const levelsProgress = useMemo(() => getProgressByLevels(dayReport, workGroupFilter), [dayReport, workGroupFilter])
  const workGroupsProgress = useMemo(() => getProgressByWorkGroups(dayReport, levelId), [dayReport, levelId])

  const showMaterials = useMemo(
    () => workGroupFilter == WorkGroupFilter.MATERIALS,
    [workGroupFilter],
  )

  const theoricalMaterial = useMemo(
    () => getTheoricalMaterials(dayReport),
    [dayReport],
  )

  const materialsNotDeclared = useMemo(() => {
    return materials
      .filter(m => m.Quantity == 0 && theoricalMaterial[m.MaterialID || ''] > 0)
      .length > 0
  }, [theoricalMaterial, materials])

  const onAdd = (item: Item | Item[]) => setDayReport((old) => {
    const smallestReportId = old.Reports?.reduce((p, v) => v.ID < p ? v.ID : p, 0) || 0
    const reports = itemToReport(
      item,
      dayReport.Levels,
      dayReport.ID,
      old.Reports?.[old.Reports?.length - 1],
      smallestReportId,
    )
    if (reports.length > 0) {
      const i = workGroups.findIndex(w => w.workGroupFilter == reports[0].WorkGroup)
      if (i != -1) {
        setSelectedWorkGroup(i + dayReport.Levels.length)
      }
    }
    const m = reports.reduce((p, v) => {
      const value = v
        .Materials
        ?.filter(m => materials.findIndex(m2 => m2.MaterialID == m.MaterialID) == -1)
        .map((m):DayReportMaterial => ({
          ID: '',
          MaterialID: m.MaterialID,
          Name: m.Name,
          Quantity: 0,
          SoldQuantity: 0,
          UnitOfMeasure: m.UnitOfMeasure,
          IDString: m.IDString,
          Type: m.Type,
          UnitQuantity: m.UnitQuantity,
          ApprovedQuantity: 0,
          MaterialIDString: m.MaterialIDString,
        }))
      if (value && value?.length > 0) {
        return [...p, ...value]
      }
      return p
    }, [] as DayReportMaterial[])
    setMaterials((old) => {
      return [...old, ...m]
    })
    return {
      ...old,
      Reports: [
        ...(old.Reports || []),
        ...reports,
      ].sort((a, b) => a.ActivityIDString.localeCompare(b.ActivityIDString)),
    }
  })

  const addWork = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.INSERT_POSITION, {
      onAdd: onAdd,
      showCheckboxes: false,
      showCreate: false,
      initialCatalogId: dayReport.CatalogID,
    })
  }

  const _renderNavigation = ({item, index}: {item: WorkGroup | Level, index: number}) => {
    if ('weight' in item) {
      const workGroup = item as WorkGroup
      const showLine = index == dayReport.Levels.length || workGroup.workGroupFilter == WorkGroupFilter.MATERIALS
      const progress = workGroupsProgress.find(w => w.workGroup == workGroup.workGroupFilter)?.progress || 0
      const _press = () => {
        if (index == workGroups.length + dayReport.Levels.length - 1 && workAddActivity) {
          addWork()
        } else {
          setSelectedWorkGroup(index)
          if (workGroup.workGroupFilter == WorkGroupFilter.MATERIALS) {
            setSelectedLevel(undefined)
          } else if (dayReport.Levels.length == 1) {
            setSelectedLevel(0)
          }
        }
      }
      const showAlert = wrongPreviousTwins.some(r => r.workGroup == workGroup.workGroupFilter || (r.workGroup == '' && workGroup.workGroupFilter == WorkGroupFilter.OTHER))
      const showAlertMaterial = workGroup.workGroupFilter == WorkGroupFilter.MATERIALS && materialsNotDeclared
      return (
        <View key={`dayReport-side-${index}-workGroup-${workGroup.workGroupFilter}`}>
          {showLine && <View style={{height: 1, backgroundColor: theme.mainColor, marginTop: 10}} />}
          <Pressable
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 10,
              padding: 10,
              borderColor: '#707070',
              borderWidth: selectedWorkGroup == index ? 0 : 1,
              backgroundColor: selectedWorkGroup == index ? theme.mainColor : undefined,
            }}
            onPress={_press}
          >
            <Text style={{
              fontSize: 16,
              color: selectedWorkGroup == index ? 'white' : theme.tint,
              flex: 1,
            }}>
              {workGroup.name}
            </Text>
            {(showAlert || showAlertMaterial) && (
              <MaterialCommunityIcons
                name='alert'
                color={selectedWorkGroup == index ? 'white' : theme.red}
                size={18}
              />
            )}
          </Pressable>
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: isNaN(progress) ? 0: progress * 100, backgroundColor: theme.blue, height: 2}}/>
            <View style={{flex: isNaN(progress) ? 0 : (1 - progress) * 100}}/>
          </View>
        </View>
      )
    } else {
      const level = item as Level
      const progress = levelsProgress[index]
      const _press = () => {
        setSelectedLevel(index)
        if (workGroupFilter == WorkGroupFilter.MATERIALS) {
          setSelectedWorkGroup(undefined)
        }
      }
      const showAlert = wrongPreviousTwins.some(r => r.levelId == level.ID)
      return (
        <View key={`dayReport-side-${index}-level-${level.ID}`}>
          <Pressable
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: index == 0 ? 0 : 10,
              padding: 10,
              borderColor: theme.tint,
              borderWidth: selectedLevel == index ? 0 : 1,
              backgroundColor: selectedLevel == index ? theme.mainColor : undefined,
            }}
            onPress={_press}
          >
            <Text style={{
              flex: 1,
              fontSize: 16,
              color: selectedLevel == index ? 'white' : theme.tint,
            }}>
              {level.Name}
            </Text>
            {showAlert && (
              <MaterialCommunityIcons
                name='alert'
                color={selectedLevel == index ? 'white' : theme.red}
                size={18}
              />
            )}
          </Pressable>
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: isNaN(progress) ? 0 : (progress * 100), backgroundColor: theme.blue, height: 2}}/>
            <View style={{flex: isNaN(progress) ? 0 : (1 - progress) * 100}}/>
          </View>
        </View>
      )
    }
  }

  const _renderReport = ({item, index}: {item: Report, index: number}) => {
    const setReport = (f: (report: Report) => Report) => {
      setDayReport(dayReport => {
        const reports = dayReport.Reports || []
        return {...dayReport, Reports: reports.map(r => r.ID == item.ID ? f(r) : r)}
      })
    }
    if (!levelId) {
      return <></>
    }
    return (
      <DayReportLine
        report={item}
        setReport={setReport}
        levelId={levelId}
        index={index}
        onAdd={onAdd}
        wrongPreviousTwins={wrongPreviousTwins}
        last={index == reports.length - 1}
      />
    )
  }

  return (
    <View style={{flex: 1, flexDirection: 'row', backgroundColor: 'white', paddingTop: 10}}>
      <View style={{width: 220, paddingHorizontal: 10}}>
        <FlatList
          data={[...dayReport.Levels, ...workGroups]}
          renderItem={_renderNavigation}
          keyExtractor={(_, index) => `day-report-side-${dayReport.ID}-${index}`}
        />
      </View>

      <View style={{flex: 1, paddingRight: 10}}>
        {showMaterials ? (
          <DayReportMaterials
            materials={materials}
            setMaterials={setMaterials}
            theoricalMaterial={theoricalMaterial}
            setChanged={setChanged}
          />
        ) : (
          <>
            <View style={{flexDirection: 'row', padding: 10, backgroundColor: theme.background}}>
              <Text style={{width: 100, fontWeight: 'bold'}}>
                {t('CODE')}
              </Text>
              <Text style={{flex: 1, fontWeight: 'bold'}}>
                {t('DESCRIPTION')}
              </Text>
              <Text style={{width: 110, fontWeight: 'bold', textAlign: 'center'}}>
                {t('QUANTITY')}
              </Text>
              <Text style={{width: 80, fontWeight: 'bold', marginLeft: 5, textAlign: 'right'}}>
                {t('TODO')}
              </Text>
              <Text style={{width: 80, fontWeight: 'bold', textAlign: 'right'}}>
                {t('TOTAL')}
              </Text>
            </View>
            <KeyboardAvoidingView style={{flex: 1}} behavior='padding' keyboardVerticalOffset={100}>
              {selectedLevel == undefined && (
                <Text style={{fontWeight: 'bold', textAlign: 'center', paddingTop: 10}}>
                  {t('PLEASE_CHOOSE_A_LEVEL')}
                </Text>
              )}
              {selectedWorkGroup == undefined && (
                <Text style={{fontWeight: 'bold', textAlign: 'center', paddingTop: 10}}>
                  {t('PLEASE_CHOOSE_A_WORK_GROUP')}
                </Text>
              )}
              <FlatList
                data={reports}
                renderItem={_renderReport}
                keyExtractor={(item, index) => `day-report-lines-${dayReport.ID}-${index}-${item.ID}`}
              />
            </KeyboardAvoidingView>
          </>
        )}
      </View>
    </View>
  )
}

export default DayReportLines