import React, { useMemo } from "react";
import { Level, LineLevel, LineSumType } from '../../../../../models'
import { Text, View } from "react-native";
import { PriceLevel } from "../../../../../store/reducers/settingsReducer";
import OrderValue from "./OrderValue";

interface Props {
  level: Level;
  lineLevel: LineLevel;
  showAsMonetaryValue: boolean;
  showPriceLevel: number;
  largeView: boolean;
}

const OrderLinePositionLevel = ({level, lineLevel, showPriceLevel, showAsMonetaryValue, largeView}: Props) => {

  const internal = showPriceLevel >= PriceLevel.INTERNAL;
  const client = showPriceLevel >= PriceLevel.CLIENT;

  const showAsMonetaryValuePrice = showAsMonetaryValue && showPriceLevel >= PriceLevel.INTERNAL

  const sold = useMemo(
    () => (lineLevel.SoldQuantity || 0) * (showAsMonetaryValuePrice ? lineLevel.Price : 1),
    [lineLevel, showAsMonetaryValuePrice],
  )

  const done = useMemo(
    () => (lineLevel.DoneQuantity || 0) * (showAsMonetaryValuePrice ? lineLevel.Price : 1),
    [lineLevel, showAsMonetaryValuePrice],
  )

  const approved = useMemo(
    () => (lineLevel.DoneApprovedQuantity || 0) * (showAsMonetaryValuePrice ? lineLevel.Price : 1),
    [lineLevel, showAsMonetaryValuePrice],
  )

  const todo = sold - done > 0 ? sold - done : 0

  const billed = useMemo(
    () => (lineLevel.InvoicedQuantity || 0) * (showAsMonetaryValuePrice ? lineLevel.Price : 1),
    [lineLevel, showAsMonetaryValuePrice],
  )

  if (lineLevel.LineSumType === LineSumType.PRICE_LIST) {
    return (
      <View style={{flexDirection: "row", paddingVertical: 5}}>
        <Text style={{flex: 2.5}}>{level.Name}</Text>

        <OrderValue value={lineLevel.Price} show={internal || client} />

        <View style={{flex: 1}}/>

        <OrderValue value={sold} />

        <OrderValue value={done} />

        <OrderValue value={approved} show={internal && largeView} />

        <OrderValue value={todo} />

        <OrderValue value={billed} show={internal && client} />
      </View>
    )
  }

  return (
    <View style={{flexDirection: "row", paddingVertical: 5}}>
      <Text style={{flex: 2.5}}>{level.Name}</Text>

      <OrderValue value={lineLevel.Price} show={internal || client} />

      <View style={{flex: 1}}/>

      <OrderValue value={sold} />

      <OrderValue value={done} />

      <OrderValue value={approved} show={internal && largeView} />

      <OrderValue value={todo} />

      <OrderValue value={billed} show={internal && client} />
    </View>
  )
}

export default OrderLinePositionLevel;