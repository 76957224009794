import React from "react";
import { Platform } from "react-native";
import WebView from "react-native-webview";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";

interface Props {
  url: string;
}

const PdfComponent = ({url}:Props) => {

  const token = useSelector((reducer:Reducer) => reducer.auth.token);

  const web = Platform.OS === "web";
  const uri = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(url)}`;

  if (web) {
    return (
      <iframe src={uri} height={"100%"}></iframe>
    );
  }

  return (
    <WebView style={{flex: 1}}
             source={{
               uri,
               headers: {
                 Authorization: token,
               },
             }}
             scrollEnabled={true}
             bounces={false}
             originWhitelist={["*"]}
             javaScriptEnabled={true}
             domStorageEnabled={true}
             startInLoadingState={true}
             scalesPageToFit={true}/>
  );
};

export default PdfComponent;