import React, { useMemo } from 'react'
import { DayReport, LineType } from '../../../models'
import { FlatList, View } from 'react-native'
import ReportLinePreview from './ReportLinePreview'
import Button from '../../../components/Button'
import getTheme from '../../../constants/Colors'
import { checkIfCanSplitDayReport, childCheck } from '../../../utils/reports/report-utils'

interface DayReportPreviewListProps {
  dayReport: DayReport
  loading: boolean
  workFinished: () => void
  saveAndSplit: () => void
  mode: number
}

const DayReportPreviewList = ({ dayReport, loading, workFinished, saveAndSplit, mode }: DayReportPreviewListProps) => {

  const theme = getTheme()

  const canSplitDayReport = useMemo(
    () => checkIfCanSplitDayReport(dayReport, mode),
    [mode, dayReport],
  )

  const reportLinesPreview = useMemo(() => {
    return dayReport.Reports?.filter((r, _, a) => {
      if (r.Type == LineType.POSITION_CAR) {
        return true
      }
      if (r.Levels?.some(l => l.Quantity > 0)) {
        return true
      }
      
      if (r.Type == LineType.TITLE) {
        return childCheck(r.LineCode, a)
      }
      return false
    }) || []
  }, [dayReport.Reports])

  return (
    <View>
      <FlatList
        data={reportLinesPreview}
        renderItem={({item, index}) => (
          <ReportLinePreview
            index={index}
            report={item}
            levels={dayReport.Levels}
          />
        )}
        keyExtractor={(item, index) => `day-report-view-${dayReport.ID}-${index}-${item.ID}`}
      />

      <View style={{flexDirection: 'row', justifyContent: 'flex-end', marginTop: 3, marginBottom: 9}}>
        <Button
          style={{
            backgroundColor: theme.blue,
            marginRight: 3,
          }}
          disabled={loading}
          onPress={workFinished}
          titleT='WORK_FINISHED'
        />
        {canSplitDayReport && (
          <Button
            style={{
              backgroundColor: theme.blue,
              marginRight: 3,
            }}
            onPress={saveAndSplit}
            titleT='SAVE_AND_SPLIT'
          />
        )}
      </View>
    </View>
  )
}

export default DayReportPreviewList