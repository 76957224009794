import React from "react";
import { statusCodeToText } from "../../constants/Status";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  status?: number;
  setStatus: (status: number) => void;
}

const StatusDropdown = ({status, setStatus}: Props) => {

  const statuses = [0, 1, 2, 3, 4, 8, 9, 10, 11];

  const _change = (s: number) => {
    if (s == status) {
      return;
    }

    setStatus(s)
  }

  return (
    <NativeModalPicker
      itemToLabel={s => statusCodeToText(s).text}
      itemToKey={s => String(s)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={status != undefined ? status : -1}
      onValueChange={_change}
      placeholder='SELECT_STATUS'
      options={statuses}
      showLabelAsOption={true}
    />
  );
};

export default StatusDropdown;