import React from "react";
import { t } from "i18n-js";
import { StyleSheet, StyleProp, ViewStyle, TextStyle, Pressable, Text, View } from "react-native";

interface Props {
  title?: string;
  titleT?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  _textStyle?: StyleProp<TextStyle>;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
}

const Button = ({ title = "", titleT, onPress, style, _textStyle, leftComponent, rightComponent, children, disabled = false}: Props) => {

  const text = titleT ? t(titleT) : title;

  const _press = () => {
    if (disabled) {
      return;
    }
    onPress && onPress();
  }

  return (
    <Pressable style={[styles.container, style]} onPress={_press}>
      {leftComponent && (
        <View style={{ paddingRight: 5 }}>{leftComponent}</View>
      )}

      { children ? children : (
        <Text style={[{color: 'white', textAlign: 'center'}, _textStyle]}>{text}</Text>
      )}

      {rightComponent && (
        <View style={{ paddingLeft: 5 }}>{rightComponent}</View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    flexDirection: 'row',
    paddingHorizontal: 7,
    minWidth: 60,
    minHeight: 30,
    paddingVertical: 7,
    margin: 5,
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export default Button;
