import React from "react";
import { Contract } from "../../../models";
import getTheme from "../../../constants/Colors";
import { Pressable, Text, View } from "react-native";
import Status from "../../../components/Status";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";

interface Props {
  contract: Contract;
  select: (contract: Contract) => void;
  selected?: Contract;
}

const ContractItem = ({contract, select, selected}: Props) => {

  const currency = useSelector((state: Reducer) => state.user.currency.Name);

  const theme = getTheme();

  const background = contract.ID === selected?.ID ? "white" : theme.background;

  const _press = () => {
    select(contract);
  }

  return (
    <Pressable
      style={{
        borderBottomColor: theme.border,
        borderBottomWidth: 2,
        paddingBottom: 1,
        backgroundColor: background,
      }}
      onPress={_press}
    >
      <Text style={{fontSize: 18}}>{contract.Name}</Text>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={{flex: 1}}>{contract.Amount} {currency}</Text>
        <Status status={contract.Status} size={"m"} style={{marginRight: 5}}/>
      </View>
    </Pressable>
  );
};

export default ContractItem;