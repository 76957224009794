import * as GeneralAPI from "../../api/GeneralAPI";
import {
  Catalog,
} from "../../models";
import {
  CATALOGS_LOADING,
  GET_BASIC_DATA_LOADING,
  GET_BASIC_DATA_SUCCESS,
  GET_CATALOGS_SUCCESS,
} from "../types";
import {Reducer} from "../reducers";
import {BasicData} from "../../api/GeneralAPI";
import { alert } from "../../hooks/Alert";
import { logout } from "./authAction";
import { sentryCapture } from "../../utils/sentryCapture";

export const getBasicData = () => async (
  dispatch: ({}) => void,
  getState: () => Reducer,
) => {
  if (!getState().sync.serverReachable) {
    return
  }
  if (getState().sync.basicsLoading) {
    return
  }
  setBasicLoading(true)(dispatch)

  GeneralAPI.getBasicData().then((basicData: BasicData) => {
    dispatch({
      type: GET_BASIC_DATA_SUCCESS,
      payload: basicData,
    })
  }).catch(e => {
    if (e.response && e.response.code == 401) {
      alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
      dispatch(logout())
    } else {
      sentryCapture(e)
      alert("ERROR", "ERROR_CONNECTING_SERVER")
    }
  }).finally(() => {
    setBasicLoading(false)(dispatch)
  })
}

const setBasicLoading = (
  loading: boolean
) => (
  dispatch: ({}) => void
) => dispatch({
  type: GET_BASIC_DATA_LOADING,
  payload: loading,
})

export const getCatalogs = (showTC: boolean | undefined = false) => async (dispatch: ({}) => void, getState: () => Reducer) => {
  if (!getState().sync.serverReachable) {
    return
  }
  if (getState().sync.catalogsLoading) {
    return
  }
  setCatalogsLoading(true)(dispatch);
  try {
    const catalogs: Catalog[] = await GeneralAPI.getCatalogs(showTC)

    dispatch({
      type: GET_CATALOGS_SUCCESS,
      payload: catalogs,
    });
  } catch(e: any) {
    if (e.response && e.response.code == 401) {
      alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
      dispatch(logout());
    } else {
      sentryCapture(e);
      alert("ERROR", "ERROR_LOADING_CATALOGS");
    }
  } finally {
    setCatalogsLoading(false)(dispatch);
  }
};

const setCatalogsLoading = (loading: boolean) => (dispatch: ({}) => void) => {
  dispatch({
    type: CATALOGS_LOADING,
    payload: loading,
  });
};
