import React from 'react'
import { ContractPlanned } from '../../../models'
import { Pressable, Text, View } from 'react-native'
import getTheme from '../../../constants/Colors'

interface ContractPlannedItemProps {
  contract: ContractPlanned
  choose: (contract: ContractPlanned) => void
}

const ContractPlannedItem = ({contract, choose}: ContractPlannedItemProps) => {

  const theme = getTheme()

  const _choose = () => choose(contract)

  return (
    <Pressable
      onPress={_choose}
      style={{
        borderColor: theme.border,
        padding: 10,
        marginTop: 10,
        backgroundColor: 'white',
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: theme.border,
        borderBottomWidth: 1,
        paddingBottom: 5,
        marginBottom: 5,
      }}>
        <Text style={{flex: 1, fontSize: 18}}>
          {contract.constructionSite.Code}
        </Text>
        <Text style={{flex: 3, fontSize: 18}}>
          {contract.constructionSite.Name} - {contract.constructionSite.Address}
        </Text>
      </View>
      <Text style={{fontSize: 18}}>{contract.Name}</Text>
    </Pressable>
  )
}

export default ContractPlannedItem