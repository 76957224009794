import { CHECK_NETWORK, SERVER_REACHABLE } from "../types";

export const setServerReachable =
  (serverReachable: boolean) => (dispatch: ({}) => void) => {
    dispatch({
      type: SERVER_REACHABLE,
      payload: serverReachable,
    });
  };

export const checkNetwork = () => (dispatch: ({}) => void) => {
  dispatch({
    type: CHECK_NETWORK,
  })
}