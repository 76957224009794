import React, { useState } from "react";
import { Contract, Doc } from "../../../models";
import SidebarOfferItem from "../../../screens/constructionSites/ConstructionSiteDetails/SidebarOfferItem";
import { MaterialIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import { FlatList, Text, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { SearchScreens } from "../../../screens/search/SearchScreens";
import Button from "../../Button";
import Modal from "react-native-modal";
import getTheme from "../../../constants/Colors";

interface Props {
  contract: Contract;
}

const CreateDocButtonOffer = ({contract}:Props) => {

  const navigation = useNavigation();
  const theme = getTheme();
  
  const [createOfferModalOpen, setCreateOfferModalOpen] = useState<boolean>(false);

  const _press = () => setCreateOfferModalOpen(true);
  const _close = () => setCreateOfferModalOpen(false);

  const _render = ({item}: {item: Doc}) => {
    const _press = () => {
      setCreateOfferModalOpen(false);
      // @ts-ignore
      navigation.navigate(SearchScreens.DUPLICATE_DOC, {
        docId: item.ID,
        docType: item.Type,
      });
    }

    return (
      <SidebarOfferItem
        onPress={_press}
        doc={item}
        key={`offer-create-by-${item.ID}`}
        isSelected={false}
        contract={contract}
      />
    )
  }

  const Footer = () => {
    const _press = () => {
      setCreateOfferModalOpen(false);
      // @ts-ignore
      navigation.navigate(SearchScreens.CREATE_CONTRACT, {
        constructionSiteId: contract.ConstructionSiteID,
        contractId: contract.ID,
      });
    }

    return (
      <Button onPress={_press} titleT='CREATE_EMPTY_DOC' style={{backgroundColor: theme.blue}} />
    )
  }

  return (
    <>
      <Button onPress={_press} titleT='CREATE_OFFER' style={{backgroundColor: theme.blue}} />
      <Modal
        isVisible={createOfferModalOpen}
        onBackdropPress={_close}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
        }}
      >
        <View>
          <View 
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text>
              {t("CREATE_OFFER_BY_OFFER")}
            </Text>
            <MaterialIcons
              color="black"
              size={30}
              name="close"
              onPress={_close}
            />
          </View>

          <FlatList
            data={contract.Docs.filter(d => d.Type.Config.ConstructionSiteViewGroup === 'offer')}
            renderItem={_render}
            ListFooterComponent={Footer}
            key={`offers`}
            keyExtractor={(item, index) => `offer-${index}-${item.ID}`}
          />
        </View>
      </Modal>
    </>
  );
};

export default CreateDocButtonOffer;