import 'react-native-get-random-values';
import {v4} from 'uuid';
import storage from "../storage";

export const UUID_MEMORY_ADDRESS = '@dryapp_uuid';
export const uuidTest = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const getDeviceUUID = async (): Promise<string> => {
  let uuid = await storage.getItem(UUID_MEMORY_ADDRESS);

  if(uuid == null || !uuidTest.test(uuid)) {
    uuid = v4();
    await storage.setItem(UUID_MEMORY_ADDRESS, uuid);
  }

  return uuid;
}
