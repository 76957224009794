import React from "react";
import { Seller } from "../../../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import NativeModalPicker from "../../../../components/NativeModalPicker";

interface Props {
  seller?: Seller;
  setSeller: (seller: Seller) => void;
}

const DocThreeDotsSeller = ({seller, setSeller}: Props) => {

  const sellers = useSelector((state: Reducer) => state.general.sellers);

  const _handler = (selectedSeller: Seller) => {
    if(seller?.ID == selectedSeller.ID) {
      return;
    }
    setSeller(selectedSeller)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      selectedValue={seller}
      style={{minWidth: "100%"}}
      onValueChange={_handler}
      options={sellers}
      placeholder={'SELECT_SALE_EMPLOYEE'}
    />
  )
}

export default DocThreeDotsSeller;