import {
  getCurrentPositionAsync,
  LocationObject,
  LocationObjectCoords,
  requestForegroundPermissionsAsync,
  reverseGeocodeAsync
} from "expo-location";
import { alertText } from "./Alert";


export const getAddressAndMaps = async (): Promise<{ address: string; maps: string }> => {
  const { status } = await requestForegroundPermissionsAsync();
  if (status !== "granted") {
    await alertText("Permission not granted", "Allow the app to use location services.");
    throw "No location permissions";
  } else {
    const { coords } = await getCurrentPositionAsync();
    const response = await reverseGeocodeAsync(coords);

    if (response.length === 0) {
      throw "No found address";
    }

    const address = `${response[0].name}, ${response[0].isoCountryCode}-${response[0].postalCode} ${response[0].city}`;
    const maps = `https://www.google.com/maps/search/?api=1&query=${coords.latitude}%2C${coords.longitude}`;

    return {
      address,
      maps,
    };
  }
};

export const googleMapsLinkToCoordinates = (link: string): LocationObject => {
  let longitude: number, latitude: number;
  if (!link) {
    return {
      ...({} as LocationObject),
      coords: {
        ...({} as LocationObject).coords,
        longitude: 0,
        latitude: 0,
      },
    };
  }
  let start = link.indexOf("/@");
  if (start > 0) {
    const mid = link.indexOf(",", start);
    const end = link.indexOf(",", mid + 1);
    latitude = Number(link.substring(start + 2, mid));
    longitude = Number(link.substring(mid + 1, end));
  } else {
    start = link.indexOf("query=");
    const mid = link.indexOf("%2C", start);
    latitude = Number(link.substring(start + 6, mid));
    longitude = Number(link.substring(mid + 3, link.length));
  }

  return {
    ...({} as LocationObject),
    coords: {
      ...({} as LocationObject).coords,
      longitude,
      latitude,
    },
  };
}

export const coordinatesToGoogleMapsLink = (latitude: number, longitude: number): string => {
  return `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`;
}