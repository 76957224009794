import { User } from "../models";
import axios from "axios";

export const getUser = async (): Promise<User> => {
  const response = await axios.get("user")

  if (response.status != 200) {
    throw response;
  }

  return {
    ...response.data,
    reportActivitiesLinesFilter: JSON.parse(response.data.reportActivitiesLinesFilter),
  };
};
