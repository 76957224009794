import React, { useMemo, useState } from 'react'
import { Contract, DayReport } from '../../models'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { Text, View } from 'react-native'
import FlatChoose from '../FlatChoose'
import Button from '../Button'
import getTheme from '../../constants/Colors'
import { t } from 'i18n-js'

interface ExternalWorkInvoiceDayReportChooseProps {
  contract: Contract
  choose: (dayReportId: number) => void
}

const ExternalWorkInvoiceDayReportChoose = ({
  contract,
  choose,
}: ExternalWorkInvoiceDayReportChooseProps) => {

  const theme = getTheme()
  
  const resources = useSelector((state: Reducer) => state.general.resources)

  const [dayReport, setDayReport] = useState<DayReport | undefined>()

  const nonOperativeResources = useMemo(() => {
    return resources.filter(r => !r.ResourceType.Operative)
  }, [resources])

  const dayReports = useMemo(() => {
    return contract
        .Reports
        .filter(dr => {
          if (dr.ExternalWorkInvoiceId == undefined){
            const resource = nonOperativeResources
                .find(r => r.ID == dr.ResourceID)
            if (resource != undefined) {
              return true
            }
          }
          return false
        })
  }, [contract, nonOperativeResources])

  const _choose = () => {
    if (dayReport) {
      choose(dayReport.ID)
    }
  }

  return (
    <View style={{flex: 1, paddingTop: 15}}>
      <Text style={{fontWeight: 'bold', fontSize: 16, paddingVertical: 10}}>
        {t('CHOOSE_DAY_REPORT_FOR_EXTERNAL_WORK_INVOICE')}
      </Text>

      <FlatChoose
        options={dayReports}
        itemToKey={item => item.ID.toString()}
        itemToString={item => `${item.ID} - ${item.DateTime.toLocaleDateString()} - ${item.Resource.FirstName} ${item.Resource.LastName}`}
        onChange={setDayReport}
        value={dayReport}
        size='small'
      />

      <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
        <Button
          style={{backgroundColor: dayReport ? theme.mainColor : theme.secondaryBackground}}
          titleT='CHOOSE'
          onPress={_choose}
          disabled={!dayReport}
        />
      </View>
    </View>
  )
}

export default ExternalWorkInvoiceDayReportChoose