import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getNetworkStateAsync} from 'expo-network'
import NetInfo from '@react-native-community/netinfo'
import {Reducer} from './store/reducers'
import {getUser} from './api/UserAPI'
import {getBasicData, getCatalogs} from './store/action/generalAction'
import {getConstructionSites} from './store/action/constructionSitesAction'
import {getAllClients} from './store/action/clientsActions'
import StackNavigator, { RootStackParamList } from './navigation'
import {setServerReachable} from './store/action/syncAction'
import {checkServerStatus} from './api/ConfigAPI'
import { NavigationContainerRef } from '@react-navigation/native'
import { ActivityIndicator, View } from 'react-native'
import { setFirstStartAction } from './store/action/authAction'
import { getUserAction, setUserAction, setUserLoading } from './store/action/userAction'

// 1000 * 60 milliseconds
const MIN_WAIT_SYNC = 60000

let lastCheck = 0

export default function AppLoader() {

  const navigationRef = useRef<NavigationContainerRef<RootStackParamList>>(null)

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false)

  const firstStart = useSelector(
    (state: Reducer) => state.auth.firstStart
  )
  const userLoading = useSelector(
    (state: Reducer) => state.sync.userLoading
  )
  const basicsLoading = useSelector(
    (state: Reducer) => state.sync.basicsLoading
  )
  const constructionSitesLoading = useSelector(
    (state: Reducer) => state.sync.constructionSitesLoading
  )
  const clientsLoading = useSelector((state: Reducer) => state.sync.clientsLoading)
  const catalogsLoading = useSelector(
    (state: Reducer) => state.sync.catalogsLoading
  )
  const sales = useSelector((state: Reducer) => state.user.permissions.sales)
  const checkNetworkCounter = useSelector((state: Reducer) => state.sync.networkCheckCounter)

  const setFirstStart = () => setFirstStartAction(false)(dispatch)

  useEffect(() => {
    const networkUnsubscribe = NetInfo.addEventListener(async () => {
      if (loading) {
        return
      }
      if (Date.now() - MIN_WAIT_SYNC < lastCheck) {
         return
      }
      lastCheck = Date.now()
      try {
        const state = await NetInfo.fetch()
        if (!state.isConnected) {
          dispatch(setServerReachable(false))
          return
        }
        const expoNetworkState = await getNetworkStateAsync()
        if (!expoNetworkState.isInternetReachable) {
          dispatch(setServerReachable(false))
          return
        }
        const ok = await checkServerStatus()
        if (ok) {
          dispatch(setServerReachable(true))
          if (firstStart) {
            getUser().then(u => {
              dispatch(setUserAction(u))
              setFirstStart()
              setTimeout(() => {
                dispatch(getBasicData())
                dispatch(getConstructionSites())
                dispatch(getAllClients())
                dispatch(getCatalogs())
              }, 500)
            }).catch(e => {
              // TODO handle
            }).finally(() => dispatch(setUserLoading(false)))
          } else {
            dispatch(getUserAction())
            dispatch(getBasicData())
            dispatch(getConstructionSites())
            dispatch(getAllClients())
            dispatch(getCatalogs(!sales))
          }
        } else {
          dispatch(setServerReachable(false))
        }
      } catch (e) {
        dispatch(setServerReachable(false))
      }
    })

    return function cleanup() {
      networkUnsubscribe()
    };
  }, [])

  useEffect(() => {
    NetInfo.fetch().then(async state => {
      if (!state.isConnected) {
        dispatch(setServerReachable(false))
        return
      }
      const expoNetworkState = await getNetworkStateAsync()
      if (!expoNetworkState.isInternetReachable) {
        dispatch(setServerReachable(false))
        return
      }
      const ok = await checkServerStatus()
      dispatch(setServerReachable(ok))
    })
  }, [checkNetworkCounter])

  useEffect(() => {
    setLoading(userLoading || basicsLoading || constructionSitesLoading || catalogsLoading || clientsLoading)
  }, [userLoading, basicsLoading, constructionSitesLoading, clientsLoading, catalogsLoading])

  if (loading && firstStart) {
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <View style={{width: '100%', height: '100%'}}>
      <StackNavigator navigationRef={navigationRef} />
    </View>
  )
}
