import React from "react";
import { ScrollView, Text, View } from "react-native"
import { t } from "i18n-js";
import { ConstructionSite, Marginality } from "../../models";
import getTheme from "../../constants/Colors";
import Formatter from "../../utils/formatters";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { PriceLevel } from "../../store/reducers/settingsReducer";

interface Props {
  marginality: Marginality;
  constructionSite?: ConstructionSite;
}

const MarginalityOverview = ({marginality, constructionSite}: Props) => {

  const theme = getTheme();

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel)

  const basicOfferCost = marginality.materialCost
      + marginality.humanWorkCost
      + marginality.externalWorkCost
      + (constructionSite?.MaterialCost || 0)
      + (constructionSite?.HumanWorkCost || 0)
      + (constructionSite?.ExternalWorkCost || 0);
  const basicOfferRevenue = marginality.materialRevenue
      + marginality.humanWorkRevenue
      + marginality.externalWorkRevenue
      + (constructionSite?.MaterialRevenue || 0)
      + (constructionSite?.HumanWorkRevenue || 0)
      + (constructionSite?.ExternalWorkRevenue || 0);
  const basicOfferMargin = basicOfferRevenue - basicOfferCost;

  const ewCost = marginality.engineeringCost
    + (marginality.warrantyMaterialCost || 0)
    + (marginality.warrantyHumanWorkCost || 0)
    + (constructionSite?.EngineeringCost || 0)
    + (constructionSite?.WarrantyHumanCost || 0)
    + (constructionSite?.WarrantyMaterialCost || 0);
  const ewRevenue = marginality.engineeringRevenue
    + marginality.warrantyRevenue
    + (constructionSite?.EngineeringRevenue || 0)
    + (constructionSite?.WarrantyRevenue || 0);
  const ewMargin = ewRevenue - ewCost;

  const cost = basicOfferCost + ewCost;
  const revenue = basicOfferRevenue + ewRevenue;
  const margin = revenue - cost;

  if (showPriceLevel < PriceLevel.INTERNAL) {
    return <></>
  }

  return (
    <ScrollView>
      <Text style={{fontSize: 17}}>
        {t("BASIC_OFFER")}
      </Text>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("MATERIAL_COST")}
        </Text>
        <Text style={{flex: 2}}>
          {Formatter.number2.format(marginality.materialCost + (constructionSite?.MaterialCost || 0))}
        </Text>
      </View>
      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 2}}>
          {t("HUMAN_WORK_COST")}
        </Text>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(marginality.humanWorkHours + (constructionSite?.HumanWorkHours || 0))} {t("HOURS")}
        </Text>
        <Text style={{flex: 6}}>
          {Formatter.number2.format(marginality.humanWorkCost + (constructionSite?.HumanWorkCost || 0))}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("EXTERNAL_WORK_COST")}
        </Text>
        <Text style={{flex: 2}}>
          {Formatter.number2.format(marginality.externalWorkCost + (constructionSite?.ExternalWorkCost || 0))}
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 6,
          borderBottomWidth: 3,
          borderBottomColor: theme.border
        }}
      >
        <Text style={{flex: 1, fontWeight: 'bold'}}>
          {t("TOTAL_COSTS")}
        </Text>
        <Text style={{flex: 2}}>
          {Formatter.number2.format(basicOfferCost)}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("MATERIAL_REVENUE")}
        </Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(marginality.materialRevenue + (constructionSite?.MaterialRevenue || 0))}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("HUMAN_WORK_REVENUE")}
        </Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(marginality.humanWorkRevenue + (constructionSite?.HumanWorkRevenue || 0))}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("EXTERNAL_WORK_REVENUE")}
        </Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(marginality.externalWorkRevenue + (constructionSite?.ExternalWorkRevenue || 0))}
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 6,
          borderBottomWidth: 3,
          borderBottomColor: theme.border
        }}
      >
        <Text style={{flex: 1, fontWeight: 'bold'}}>
          {t("TOTAL_REVENUE")}
        </Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(basicOfferRevenue)}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>{t("MARGIN_BASIC_OFFER")}</Text>
        <Text>{Formatter.number2.format(basicOfferRevenue)} - {Formatter.number2.format(basicOfferCost)} = </Text>
        <Text
          style={{
            color: basicOfferMargin >= 0 ? "green" : "red"
          }}
        >{Formatter.number2.format(basicOfferMargin)} ({Formatter.number2.format(basicOfferMargin / basicOfferRevenue * 100)}%)</Text>
      </View>


      <Text style={{marginTop: 5, fontSize: 17}}>
        {t("WARRANTY_ENGINEERING")}
      </Text>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("WARRANTY_COSTS_MATERIAL")}
        </Text>
        <Text style={{flex: 2}}>
          {Formatter.number2.format((marginality.warrantyMaterialCost || 0) + (constructionSite?.WarrantyMaterialCost || 0))}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("WARRANTY_COSTS_HUMAN_WORK")}
        </Text>
        <Text style={{flex: 2}}>
          {Formatter.number2.format((marginality.warrantyHumanWorkCost || 0) + (constructionSite?.WarrantyHumanCost || 0))}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 2}}>
          {t("ENGINEERING_HOURS_COST")}
        </Text>
        <Text style={{flex: 1}}>{Formatter.number2.format(marginality.engineeringHours + (constructionSite?.EngineeringHours || 0))}</Text>
        <Text style={{flex: 6}}>
          {Formatter.number2.format(marginality.engineeringCost + (constructionSite?.EngineeringCost || 0))}
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 6,
          borderBottomWidth: 3,
          borderBottomColor: theme.border
        }}
      >
        <Text style={{flex: 1, fontWeight: 'bold'}}>
          {t("TOTAL_COSTS")}
        </Text>
        <Text style={{flex: 2}}>
          {Formatter.number2.format(ewCost)}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("WARRANTY_REVENUE")}
        </Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(marginality.warrantyRevenue + (constructionSite?.WarrantyRevenue || 0))}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>
          {t("ENGINEERING_REVENUE")}
        </Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(marginality.engineeringRevenue + (constructionSite?.EngineeringRevenue || 0))}
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 6,
          borderBottomWidth: 3,
          borderBottomColor: theme.border
        }}
      >
        <Text style={{flex: 1, fontWeight: 'bold'}}>
          {t("TOTAL_REVENUE")}
        </Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1}}>
          {Formatter.number2.format(ewRevenue)}
        </Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>{t("MARGIN_ENGINEERING_WARRANTY")}</Text>
        <Text>{Formatter.number2.format(ewRevenue)} - {Formatter.number2.format(ewCost)} = </Text>
        <Text
          style={{
            color: ewMargin >= 0 ? "green" : "red"
          }}
        >{Formatter.number2.format(ewMargin)} ({Formatter.number2.format(ewMargin / ewRevenue * 100)}%)</Text>
      </View>

      <Text style={{marginTop: 5, fontSize: 17}}>
        {t("GENERAL_RESULT")}
      </Text>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>{t("MARGIN_BASIC_OFFER")}</Text>
        <Text style={{flex: 1}}>{Formatter.number2.format(basicOfferMargin)}</Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>{t("MARGIN_ENGINEERING_WARRANTY")}</Text>
        <Text style={{flex: 1}}>{Formatter.number2.format(ewMargin)}</Text>
      </View>

      <View style={{flexDirection: 'row'}}>
        <Text style={{flex: 1}}>{t("GENERAL_RESULT")}</Text>
        <View style={{flexDirection: 'row', flex: 1}}>
          <Text>{Formatter.number2.format(revenue)} - {Formatter.number2.format(cost)} = </Text>
          <Text
            style={{
              color: margin >= 0 ? "green" : "red",
            }}
          >{Formatter.number2.format(margin)} ({Formatter.number2.format(margin / revenue * 100)}%)</Text>
        </View>
      </View>
    </ScrollView>
  );
};

export default MarginalityOverview;