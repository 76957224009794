import React from 'react'
import Modal from 'react-native-modal'
import { ConstructionSite, DayReport, Resource, WeekReportAssignement } from '../../models'
import { t } from 'i18n-js'
import { Text, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import DayReportItem from '../reports/DayReportItem'
import Button from '../../components/Button'
import getTheme from '../../constants/Colors'
import { useNavigation } from '@react-navigation/native'
import { SearchScreens } from '../search/SearchScreens'

interface Props {
  visible: boolean
  onClose: () => void
  assignment: WeekReportAssignement
}

const WeekReportModal = ({visible, onClose, assignment}: Props) => {

  const theme = getTheme()
  const navigation = useNavigation()

  const handleOpen = () => {
    // @ts-ignore
    navigation.navigate(`Sidebar`, {
      screen: 'SearchScreenStack',
      params: {
        screen: SearchScreens.CONSTRUCTION_SITES_DETAILS,
        params: assignment.ConstructionSite,
      },
    });
    onClose()
  };

  return (
    <Modal
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
        width: 400,
        alignSelf: 'center',
      }}
      isVisible={visible}
      onBackButtonPress={onClose}
    >
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 34}}>{t("MODIFY")}</Text>
        <MaterialCommunityIcons
          onPress={onClose}
          size={24}
          name="close"
        />
      </View>

      {
        assignment.DayReports.map((dayReport, index) => (
          <DayReportItem
            borderBottom={index < assignment.DayReports.length - 1}
            dayReport={{
              ...dayReport,
              ConstructionSite: assignment.ConstructionSite,

            } as DayReport}
            key={dayReport.ID}
            close={onClose}
          />
        ))
      }

      <Button
        onPress={handleOpen}
        style={{
          backgroundColor: theme.blue,
        }}
        titleT="OPEN_CONSTRUCTION_SITE"
      />
      <View style={{flex: 1}}/>
    </Modal>
  )
}

export default WeekReportModal