import { GET_USER_SUCCESS, SET_USER_LOADING } from "../types";
import * as UserAPI from "../../api/UserAPI";
import { User } from "../../models";
import { setShowPriceLevel } from "./settingsAction";
import {Reducer} from "../reducers";
import { alert } from "../../hooks/Alert";
import { logout } from "./authAction";
import { sentryCapture } from "../../utils/sentryCapture";

export const setUserLoading = (loading: boolean) => (dispatch: ({}) => void) => {
  dispatch({
    type: SET_USER_LOADING,
    payload: loading,
  });
};

export const setUserAction = (user: User) => async (
  dispatch: ({}) => void,
  getState: () => Reducer,
) => {
  dispatch({
    type: GET_USER_SUCCESS,
    payload: user,
  })
  setUserLoading(false)(dispatch)
  setShowPriceLevel(user.permissions.priceLevel)(dispatch, getState)
}

export const getUserAction = () => async (dispatch: ({}) => void, getState: () => Reducer) => {
  if (!getState().sync.serverReachable) {
    return
  }
  if (getState().sync.userLoading) {
    return
  }
  setUserLoading(true)(dispatch)
  UserAPI.getUser().then((user: User) => {
    setUserAction(user)(dispatch, getState)
  }).catch(e => {
    if (e.response && e.response.code == 401) {
      alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
      dispatch(logout())
    } else {
      sentryCapture(e)
      alert("ERROR", "ERROR_LOADING_USER")
    }
  }).finally(() => setUserLoading(false)(dispatch))
}
