import {
  Line,
  Item,
  LineLevel,
  Level,
  Doc,
  Catalog,
  LineSumType,
  LineType,
  SapDoc,
  SapLine,
  ConstructionSiteViewGroups,
  Activity,
  Discount,
  VAT,
} from '../models'
import { round } from './NumberUtils'
import { addFather } from './doc/lines'

// TODO(@filippofinke): Fix LineNum
export const convertItemToLine = (item: Item): Line => {
  return {
    ID: item.ID,
    LineNum: 0,
    Code: item.IDString,
    Name: item.Name,
    InternalName: item.InternalName,
    MinPrice: item.MinimumPrice || 0,
    CatalogPrice: item.Price || 0,
    LineTotal: 0,
    LineTotalCalculated: 0,
    Cost: item.Cost || 0,
    HumanWorkCost: item.HumanWorkCost || 0,
    HumanWorkRevenue: item.HumanWorkRevenue || 0,
    MaterialCost: item.MaterialCost || 0,
    MaterialRevenue: item.MaterialRevenue || 0,
    UnitOfMeasure: item.UnitOfMeasure || "",
    LineType: item.Type,
    LineLevel: [],
    Block: false,
    Quantity: 0,
    Discount: 0,
    Group: "-",
    LineLetter: "-",
    LineSumType: LineSumType.NORMAL,
    TwinIDs: item.TwinIDs || [],
    FatherID: item.FatherID,
    Items: null,
    FixedPriceBlock: null,
    Variable: null,
    PrintTwin: item.PrintTwin,
    BackgroundColor: item.BackgroundColor,
  };
};

const docFixedPriceLineToSapLineConvert = (
  line: Line,
  id: number,
  docId: number
): SapLine => {
  return {
    ID: id,
    docID: docId,
    IDString: line.Code,
    Code: line.LineType == LineType.FIXED_PRICE ? "TOT" : line.Code,
    InternalName: line.InternalName,
    Name: line.Name,
    LineType: line.LineType,
    FatherID: line.FatherID,
    Price: line.LineTotal,
    FixedPriceBlock: line.FixedPriceBlock,
    Quantity: 1,
  } as SapLine;
};

const docLineToSapLineConvert = (
  line: Line,
  lineLevel: LineLevel,
  level: Level,
  id: number,
  docId: number
): SapLine => {
  return {
    ID: id,
    docID: docId,
    levelID: level.ID,
    IDString: line.Code,
    Code: line.ID,
    InternalName: line.InternalName,
    Name: line.Name,
    LineType: line.LineType,
    FatherID: line.FatherID,
    Price: lineLevel.Price,
    Discount: lineLevel.Discount,
    CatalogPrice: line.CatalogPrice,
    DiscountNumeric: lineLevel.DiscountNumeric,
    MinPrice: line.MinPrice,
    LineTotalCalculated: lineLevel.LineTotalCalculated,
    LineTotal: lineLevel.LineTotal,
    Cost: line.Cost,
    Quantity: lineLevel.Quantity,
    HumanWorkCost: line.HumanWorkCost,
    MaterialCost: line.MaterialCost,
    HumanWorkRevenue: line.HumanWorkRevenue,
    MaterialRevenue: line.MaterialRevenue,
    UnitOfMeasure: line.UnitOfMeasure,
    LineSumType: lineLevel.LineSumType,
    TwinIDs: JSON.stringify(line.TwinIDs),
    FixedPriceBlock: lineLevel.FixedPriceBlock == "-" ? "" : lineLevel.FixedPriceBlock,
    Group: line.Group,
    Hours: lineLevel.Hours,
    PriceCalculated: lineLevel.PriceCalculated,
    Vat: lineLevel.Vat,
    VatID: lineLevel.Vat?.ID,
    BackgroundColor: line.BackgroundColor,
    PrintTwin: line.PrintTwin,
  } as SapLine;
};

const docTitleLineToSapLineConvert = (
  line: Line,
  id: number,
  docId: number
): SapLine => {
  return {
    ID: id,
    docID: docId,
    IDString: line.Code,
    Code: line.ID,
    InternalName: line.InternalName,
    Name: line.Name,
    LineType: line.LineType,
    FatherID: line.FatherID,
  } as SapLine;
};

const docDiscountsToSapLinesConvert = (discounts: Discount[], id: number, docID: number, blockPrice: number): SapLine[] => {
  let lines: SapLine[] = [];

  let lastTotal = blockPrice;
  for (let i = 0; i < discounts.length; i++) {
    if (discounts[i].isNumeric) {
      lastTotal = lastTotal - discounts[i].value;
    } else {
      lastTotal = round(lastTotal * (1 - discounts[i].value / 100), 5);
    }

    lines.push({
      ID: id + i,
      Discount: discounts[i].value,
      docID,
      PriceCalculated: 0,
      LineType: LineType.DISCOUNT,
      Price: discounts[i].totalValue,
      Group: "",
      Hours: 0,
      Code: `SC_1`,
      InternalName: "",
      FixedPriceBlock: "",
      FatherID: "",
      Quantity: -1,
      CatalogPrice: 0,
      LineTotal: 0,
      Vat: {} as VAT,
      VatID: "",
      TwinIDs: "",
      IDString: "",
      UnitOfMeasure: "",
      MaterialCost: 0,
      Cost: 0,
      Name: discounts[i].name,
      MaterialRevenue: 0,
      HumanWorkCost: 0,
      HumanWorkRevenue: 0,
      LineSumType: LineSumType.NORMAL,
      DiscountNumeric: discounts[i].isNumeric,
      LineTotalCalculated: 0,
      levelID: 0,
      MinPrice: 0,
      PrintTwin: true,
    });
  }

  return lines;
}

const docLinesToSapLinesConvert = (
  lines: Line[],
  levels: Level[],
  docId: number
): SapLine[] => {
  const l = [];

  for (let i = 0; i < lines.length; i++) {
    switch (lines[i].LineType) {
      case LineType.TITLE:
      case LineType.TEXT:
        l.push(docTitleLineToSapLineConvert(lines[i], lines[i].LineNum, docId));
        break;
      case LineType.FIXED_PRICE:
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        if (lines[i]?.FixedPriceBlock != "-" && (lines[i]?.FixedPriceBlock || "").length > 0) {
          l.push(docFixedPriceLineToSapLineConvert(lines[i], -i + 1, docId));
        }
        break;
      case LineType.POSITION_WORK_WARRANTY:
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_EXTERNAL_WORK:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < lines[i].LineLevel.length; j++) {
          if (
            lines[i].LineLevel[j]?.Quantity == 0 &&
            lines[i].LineLevel[j]?.LineSumType != LineSumType.PRICE_LIST
          ) {
            continue;
          }
          let id = lines[i].LineLevel[j]?.ID;
          if (id == undefined || id < 0) {
            id = -(levels.length * i + j + 1);
          }
          l.push(
            docLineToSapLineConvert(
              lines[i],
              lines[i].LineLevel[j],
              levels[j],
              id,
              docId
            )
          );
        }
        break;
    }
  }

  return l;
};

export const docToSapDocConvert = (doc: Doc): SapDoc => {
  const d: any = {...doc}
  d.Catalog = {} as Catalog;

  d.LanguageID = d.Language.ID;
  d.CurrencyID = d.Currency.ID;
  d.TypeID = d.Type.ID;
  if (d.DocumentContacts == undefined) {
    d.DocumentContacts = [];
  } else {
    for (let i = 0; i < d.DocumentContacts.length; i++) {
      if (d.DocumentContacts[i].Type != undefined) {
        d.DocumentContacts[i].DocumentContactTypeID =
          d.DocumentContacts[i].TypeID;
      }
    }
  }
  const lines: Line[] = [];
  const addedFather: string[] = [];
  for (let i = 0; i < d.Lines.length; i++) {
    if (d.Lines[i].LineType === LineType.TEXT) {
      lines.push(d.Lines[i]);
      continue;
    }
    let add = false;
    for (let j = 0; j < d.Lines[i].LineLevel.length; j++) {
      if (d.Lines[i].LineLevel[j].Quantity > 0) {
        add = true;
        j = d.Lines[i].LineLevel.length;
      }
    }

    if (add) {
      // check if has father id
      const add = addFather(d.Lines[i].FatherID, addedFather, d.Lines);
      for (let j = 0; j < add.length; j++) {
        lines.push(add[j]);
      }
    }
    if (
      add ||
      d.Lines[i].LineType === LineType.FIXED_PRICE ||
      d.Lines[i].LineType === LineType.FIXED_PRICE_ENGINEERING_WARRANTY
    ) {
      lines.push(d.Lines[i]);
    }
  }
  if (
    doc.Type.Config.ConstructionSiteViewGroup !==
    ConstructionSiteViewGroups.Order
  ) {
    for (let i = 0; i < d.Levels.length; i++) {
      d.Levels[i].ID = -i - 1;
      d.Levels[i].FkID = d.ID;
      d.Levels[i].docTypeID = d.Type.ID;
    }
  }
  d.Lines = docLinesToSapLinesConvert(lines, d.Levels, d.ID);
  d.Lines.push(...docDiscountsToSapLinesConvert(doc.Discounts, d.Lines.lines, doc.ID, doc.PriceBlock));
  return d;
};

const sapLineToDocLineConvert = (line: SapLine, levels: Level[]): Line => {
  if (line.TwinIDs == null) {
    line.TwinIDs = "";
  }
  const lineLevels: LineLevel[] = [];

  for (let i = 0; i < levels.length; i++) {
    lineLevels.push(emptyDocLineLevel(line));
  }

  let twinIDs = [];
  try {
    twinIDs = JSON.parse(line.TwinIDs);
  } catch (ignored) {
  }

  return {
    Code: line.IDString,
    ID: line.Code,
    LineNum: line.ID,
    InternalName: line.InternalName,
    Name: line.Name ?? '',
    LineType: line.LineType,
    FatherID: line.FatherID,
    MinPrice: line.MinPrice,
    Cost: line.Cost,
    CatalogPrice: line.CatalogPrice,
    HumanWorkRevenue: line.HumanWorkRevenue,
    HumanWorkCost: line.HumanWorkCost,
    MaterialRevenue: line.MaterialRevenue,
    MaterialCost: line.MaterialCost,
    UnitOfMeasure: line.UnitOfMeasure,
    TwinIDs: twinIDs,
    Group: line.Group,
    LineLevel: lineLevels,
    LineTotal: 0,
    PrintTwin: line.PrintTwin,
    BackgroundColor: line.BackgroundColor,
  } as Line;
};
const sapLineToDocLineLevelConvert = (line: SapLine): LineLevel => {
  return {
    ID: line.ID,
    Price: line.Price,
    Discount: line.Discount,
    DiscountNumeric: line.DiscountNumeric,
    LineTotalCalculated: line.LineTotalCalculated,
    Quantity: line.Quantity,
    LineSumType: line.LineSumType,
    FixedPriceBlock: line.FixedPriceBlock,
    Hours: line.Hours,
    LineTotal: line.LineTotal,
    PriceCalculated: line.PriceCalculated,
    Vat: line.Vat,
    VatID: line.VatID,
    DoneQuantity: line.DoneQuantity,
    DoneApprovedQuantity: line.DoneApprovedQuantity,
    InvoicedQuantity: line.InvoicedQuantity,
    BillableQuantity: line.BillableQuantity,
    NotBillableQuantity: line.NotBillableQuantity,
    SoldQuantity: line.SoldQuantity,
    Reports: line.Reports?.map(r => ({...r, Datetime: new Date(r.Datetime)}))
  } as LineLevel;
};

const emptyDocLineLevel = (line: SapLine): LineLevel => {
  return {
    Price: line.CatalogPrice,
    Discount: 0,
    DiscountNumeric: false,
    Quantity: 0,
    LineSumType: LineSumType.NORMAL,
    FixedPriceBlock: "",
    Hours: line.Hours,
    LineTotal: 0,
    Group: "",
    HumanWorkCost: line.HumanWorkCost,
    HumanWorkRevenue: line.HumanWorkRevenue,
    ID: -1,
    LineTotalCalculated: 0,
    MaterialCost: line.MaterialCost,
    MaterialRevenue: line.MaterialRevenue,
    Name: line.Name,
    PriceCalculated: 0,
    Vat: line.Vat || { ID: "", IDString: "", Name: "", Percentage: 0 },
    VatID: line.VatID || "",
  };
};

const sapLineToDocTitleConvert = (line: SapLine): Line => {
  return {
    ID: line.Code,
    LineNum: line.ID,
    Code: line.IDString,
    InternalName: line.InternalName,
    Name: line.Name,
    LineType: line.LineType,
    FatherID: line.FatherID,
    LineLevel: [] as LineLevel[],
  } as Line;
};

const sapLineToFixedPriceLineConvert = (line: SapLine): Line => {
  return {
    ID: line.Code,
    LineNum: line.ID,
    Code: line.IDString,
    InternalName: line.InternalName,
    Name: line.Name,
    LineType: line.LineType,
    FatherID: line.FatherID,
    LineTotal: line.Price,
    LineLevel: [] as LineLevel[],
    FixedPriceBlock: line.FixedPriceBlock,
  } as Line;
};

const sapLineToDocDiscountConvert = (line:SapLine): Discount => {
  return {
    name: line.Name,
    isNumeric: line.DiscountNumeric,
    value: line.Discount,
    totalValue: line.Price,
  };
};

const sapLinesToDocLinesConvert = (
  lines: SapLine[],
  levels: Level[]
): {lines: Line[], discounts:Discount[]} => {
  const l: Line[] = [];
  const d: Discount[] = [];

  // convert lines
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].levelID == 0) {
      switch (lines[i].LineType) {
        case LineType.TITLE:
        case LineType.TEXT:
          l.push(sapLineToDocTitleConvert(lines[i]));
          break;
        case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        case LineType.FIXED_PRICE:
          l.push(sapLineToFixedPriceLineConvert(lines[i]));
          break;
        case LineType.DISCOUNT:
          d.push(sapLineToDocDiscountConvert(lines[i]));
      }
    } else {
      if (l[l.length - 1]?.ID != lines[i].Code) {
        const line = sapLineToDocLineConvert(lines[i], levels);
        line.Quantity = 0;
        l.push(line);
      }

      for (let j = 0; j < levels.length; j++) {
        if (lines[i].levelID == levels[j].ID) {
          l[l.length - 1].LineLevel[j] = sapLineToDocLineLevelConvert(lines[i]);
          if (l[l.length - 1].LineLevel[j].LineSumType === LineSumType.NORMAL) {
            l[l.length - 1].Quantity += l[l.length - 1].LineLevel[j].Quantity;
            const fixedPriceBlock = l[l.length - 1].LineLevel[j].FixedPriceBlock;
            if (fixedPriceBlock === '' || fixedPriceBlock === '-') {
              l[l.length - 1].LineTotal += l[l.length - 1].LineLevel[j].LineTotal;
            }
          }
        }
      }
    }
  }

  return {
    lines: l,
    discounts: d,
  };
};

export const sapDocToDocConvert = (doc: any, catalogs: Catalog[]): Doc => {
  const d: Doc = {
    ...doc,
    ReceivedPlansDate: doc.ReceivedPlansDate ? new Date(Date.parse(doc.ReceivedPlansDate)): undefined,
  };
  if (d.ReceivedPlansDate) {
    // remove the timezone offset
    d.ReceivedPlansDate.setHours(d.ReceivedPlansDate.getHours() - d.ReceivedPlansDate.getTimezoneOffset() / 60)
  }

  for (let i = 0; i < catalogs.length; i++) {
    if (d.CatalogID == catalogs[i].ID) {
      d.Catalog = catalogs[i];
    }
  }

  if (d.DocumentContacts == undefined) {
    d.DocumentContacts = [];
  }

  for (let i = 0; i < d.DocumentContacts.length; i++) {
    d.DocumentContacts[i].Type = doc.DocumentContacts[i].DocumentContactType;
    d.DocumentContacts[i].TypeID =
      doc.DocumentContacts[i].DocumentContactType.ID;
  }

  const {
    lines,
    discounts
  } = sapLinesToDocLinesConvert(doc.Lines, d.Levels);
  d.Lines = lines;
  d.Discounts = discounts;

  return d;
};

export const convertItemToLineWithLevels = (
  item: Item,
  levels: Level[]
): Line => {
  const line = convertItemToLine(item);

  for (let j = 0; j < levels.length; j++) {
    line.LineLevel.push({
      Quantity: 0,
      Price: line.CatalogPrice,
      Discount: 0,
      DiscountNumeric: false,
      LineTotal: 0,
      LineSumType: LineSumType.NORMAL,
      Group: "",
      FixedPriceBlock: "",
      Hours: 0,
      HumanWorkCost: line.HumanWorkCost,
      HumanWorkRevenue: line.HumanWorkRevenue,
      MaterialRevenue: line.MaterialRevenue,
      MaterialCost: line.MaterialCost,
      DoneQuantity: 0,
      InvoicedQuantity: 0,
      BillableQuantity: 0,
      NotBillableQuantity: 0,
      LineTotalCalculated: 0,
      ID: -j,
      SoldQuantity: 0,
      Name: "",
      PriceCalculated: 0,
      Vat: item.VAT,
      VatID: item.VAT.ID,
    });
  }

  return line;
};

export const convertItemToActivity = (item: Item): Activity => {
  return {
    ID: Number(item.ID),
    Code: item.ID,
    IDString: item.IDString,
    Name: item.Name,
    UnitOfMeasure: item.UnitOfMeasure || "",
    Type: item.Type,
    WorkGroup: item.WorkGroup,
    TwinIDs: item.TwinIDs || [],
  };
};
