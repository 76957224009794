import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { t } from 'i18n-js'
import { Pressable, Text, View } from 'react-native'
import {
  toggleMeasureShowApprovedQuantityPrevious,
  toggleMeasureShowBillableQuantityPrevious,
  toggleMeasureShowBilledQuantity,
  toggleMeasureShowDoneQuantityPrevious,
  toggleMeasureShowSold,
} from '../../store/action/settingsAction'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import getTheme from '../../constants/Colors'

const HidableColumn = ({ title, hide }: { title: string, hide: () => void }) => {
  return (
    <Pressable onPress={hide} style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5 }}>
      <Text style={{ width: 75, textAlign: 'right', paddingRight: 5 }}>{t(title)}</Text>
    </Pressable>
  )
}

const MeasureTableHeader = () => {

  const theme = getTheme()
  const dispatch = useDispatch()

  const {
    measureShowSold,
    measureShowDoneQuantityPrevious,
    measureShowApprovedQuantityPrevious,
    measureShowBillableQuantityPrevious,
    measureShowBilledQuantity,
  } = useSelector((state: Reducer) => state.settings)

  const _toggleSold = () => dispatch(
    toggleMeasureShowSold()
  )
  const _toggleDoneQuantityPrevious = () => dispatch(
    toggleMeasureShowDoneQuantityPrevious()
  )
  const _toggleApprovedQuantityPrevious = () => dispatch(
    toggleMeasureShowApprovedQuantityPrevious()
  )
  const _toggleBillableQuantityPrevious = () => dispatch(
    toggleMeasureShowBillableQuantityPrevious()
  )
  const _toggleBilledQuantity = () => dispatch(
    toggleMeasureShowBilledQuantity()
  )

  const _showAll = () => {
    if (!measureShowSold) {
      _toggleSold()
    }
    if (!measureShowDoneQuantityPrevious) {
      _toggleDoneQuantityPrevious()
    }
    if (!measureShowApprovedQuantityPrevious) {
      _toggleApprovedQuantityPrevious()
    }
    if (!measureShowBillableQuantityPrevious) {
      _toggleBillableQuantityPrevious()
    }
    if (!measureShowBilledQuantity) {
      _toggleBilledQuantity()
    }
  }

  const showPrevious = measureShowApprovedQuantityPrevious || measureShowBillableQuantityPrevious || measureShowDoneQuantityPrevious

  return (
    <View style={{
      flexDirection: 'row',
      alignItems: 'flex-end',
      paddingVertical: 5,
      marginHorizontal: 5,
      paddingHorizontal: 2,
      backgroundColor: theme.background,
    }}>


      <View style={{ width: 100, flexDirection: 'row', alignItems: 'flex-end' }}>
        <Text style={{ paddingRight: 15 }}>
          {t('CODE')}
        </Text>
      </View>
      <Text style={{ flex: 1 }}>
        {t('DESCRIPTION')}
      </Text>
      <MaterialCommunityIcons
        color='black'
        size={24}
        name='arrow-expand-horizontal'
        onPress={_showAll}
        style={{ marginHorizontal: 5 }}
      />
      <Text style={{ width: 100, textAlign: 'center' }}>
        {t('UNIT_OF_MEASURE')}
      </Text>
      {measureShowSold && (
        <HidableColumn title='SOLD' hide={_toggleSold} />
      )}

      {showPrevious && (
        <View>
          <Text style={{ textAlign: 'center' }}>
            {t('PREVIOUS_MEASURE')}
          </Text>
          <View style={{
            flexDirection: 'row',
            alignSelf: 'flex-end',
            marginTop: 4,
            borderTopColor: theme.secondaryBackground,
            borderTopWidth: 1,
            paddingTop: 4,
          }}>
            {measureShowDoneQuantityPrevious && (
              <HidableColumn title='DONE' hide={_toggleDoneQuantityPrevious} />
            )}

            {measureShowApprovedQuantityPrevious && (
              <HidableColumn title='APPROVED' hide={_toggleApprovedQuantityPrevious} />
            )}

            {measureShowBillableQuantityPrevious && (
              <HidableColumn title='BILLABLE' hide={_toggleBillableQuantityPrevious} />
            )}
          </View>
        </View>
      )}

      {measureShowBilledQuantity && (
        <HidableColumn title='BILLED' hide={_toggleBilledQuantity} />
      )}
      <Text style={{ width: 80, textAlign: 'right', paddingRight: 5 }}>
        {t('DONE')}
      </Text>
      <Text style={{ width: 105, textAlign: 'center' }}>
        {t('APPROVABLE')}
      </Text>
      <Text style={{ width: 100, textAlign: 'center' }}>
        {t('BILLABLE')}
      </Text>
    </View>
  )
}

export default MeasureTableHeader