import * as React from "react";
import { useState } from "react";
import { DimensionValue, Text } from "react-native";
import { StyleSheet } from "react-native";

interface Props {
  html: string;
  width?: DimensionValue;
  maxLength?: number;
}

const getKeyCounter = (html: string): Function => {
  let key = 0;

  return (): string => html + `${key++}`;
};

const HtmlText = ({ html, width, maxLength }: Props) => {
  if (html === undefined) throw new Error("HtmlText: html is undefined");

  let [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((old) => !old);
  };

  const getKey = getKeyCounter(html);

  let totalLength = 0;

  let checkLength = (text: string) => {
    totalLength += text.length;
    if (!showMore && maxLength && totalLength > maxLength) {
      text = text.substring(0, text.length - (totalLength - maxLength));
    }

    return text;
  };

  const parseString = (line: string): any[] => {
    let parsed = [];
    let match = line.match(/<b>(.*?)<\/b>/);

    if (match) {
      let before = line.substring(0, match.index);
      before = checkLength(before);
      if (before.length > 0) parsed.push(<Text key={getKey()}>{before}</Text>);

      match[1] = checkLength(match[1]);
      if (match[1].length > 0) {
        parsed.push(
          <Text key={getKey()} style={{fontWeight: "bold"}}>
            {match[1]}
          </Text>
        );
      }

      let after = line.substring((match.index || 0) + match[0].length);
      if (after.length > 0) parsed.push(...parseString(after));
    } else {
      line = checkLength(line);
      if (line.length > 0) {
        parsed.push(<Text key={getKey()}>{line}</Text>);
      }
    }

    return parsed;
  };

  const renderTitle = (text: string) => {
    let components = parseString(
      text
        .replace(/<br>/g, "\n")
        .replace(/&#(\d+);/g, (_, dec) => String.fromCharCode(dec))
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/&amp;/g, "&")
        .replace(/&ge;/g, ">=")
        .replace(/&le;/g, "<=")
    )

    if (maxLength && totalLength > maxLength)
      components.push(
        <Text onPress={toggleShowMore} style={styles.more} key={getKey()}>
          {showMore ? "\nshow less" : "...more"}
        </Text>
      );

    return components;
  };
  return <Text style={width ? { width } : {}}>{renderTitle(html)}</Text>;
};

const styles = StyleSheet.create({
  more: {
    color: "gray",
  },
});

export default HtmlText;
