import React, { useState } from 'react'
import { Pressable, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { Client, Contact } from '../../../../models'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import ClientNotesModal from '../../../../components/ClientNotesModal'

interface DocumentPreviewClientsProps {
  text?: string
  textT?: string
  client: Client
  contact: Contact | null
}

const clientToString = (client: Client | null, contact: Contact | null): string | null => {
  if (client == null) {
    return null;
  }

  if (contact == null) {
    const mobile = client.Mobile?.length>0?" | " + client.Mobile:"";
    const phone = client.Phone?.length>0?" | " + client.Phone:"";
    const email = client.Email?.length>0?" | " + client.Email:"";

    return `${client.Name}${mobile}${phone}${email}`;
  }

  return `${client.Name} (${contactToString(contact)})`;
}

const contactToString = (contact: Contact): string => {
  if (!contact.Firstname || !contact.Lastname) {
    return "";
  }

  const mobile = contact.Mobile?.length>0?" | " + contact.Mobile:"";
  const phone = contact.Phone?.length>0?" | " + contact.Phone:"";
  const email = contact.Email?.length>0?" | " + contact.Email:"";

  return `${contact.Firstname} ${contact.Lastname}${mobile}${phone}${email}`;
}

const DocumentPreviewClient = ({text, textT, client, contact}: DocumentPreviewClientsProps) => {

  const [showClientNotes, setShowClientNotes] = useState<boolean>(false)
  const _showClientNotes = () => {
    if (client.FreeText.length > 0) {
      setShowClientNotes(true)
    }
  }
  const _closeClientNotes = () => setShowClientNotes(false)

  return (
    <Pressable
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 5,
        flex: 1,
      }}
      onPress={_showClientNotes}
    >
      <View style={{flexDirection: 'row', flex: 1}}>
        <Text style={{fontWeight: '600'}}>
          {textT ? t(textT) : (text || 'CLIENT')}:
        </Text>

        <Text style={{flex: 1, paddingLeft: 5}}>
          {clientToString(client, contact)}
        </Text>
      </View>

      {client.FreeText.length > 0 && (
        <MaterialCommunityIcons
          name='comment'
          size={16}
          color='black'
          style={{paddingLeft: 5}}
        />
      )}

      {showClientNotes && (
        <ClientNotesModal
          client={client}
          onClose={_closeClientNotes}
        />
      )}
    </Pressable>
  )
}

export default DocumentPreviewClient