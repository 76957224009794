import React, { useState } from "react";
import { CatalogTypes, Client, ConstructionSite, Contract, Doc, Resource } from "../../../models";
import getTheme from "../../../constants/Colors";
import { useNavigation } from "@react-navigation/native";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import DocThreeDots from "./DocThreeDots";
import MarginalityModal from "../../../components/MarginalityModal";
import { alertConfirm } from "../../../hooks/Alert";
import PreviewModal from "../../../components/PreviewModal";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { removeDocOffline } from "../../../utils/doc-offline/util";

interface Props {
  constructionSite?: ConstructionSite;
  contract?: Contract;
  doc?: Doc;
  setDoc: (doc: Doc) => void;
  setContract: (contract: Contract) => void
  setConstructionSite: (constructionSite: ConstructionSite) => void
  mode: number;
  setLoading: (loading: boolean) => void;
  errors: string[];
  warnings: string[];
  changed: boolean;
  createUpdateHandler: () => void;
  createUpdateDraftHandler: () => void
  loseOfferHandler: (client: Client | null, notes: string | null) => void
  setChanged: (changed: boolean) => void;
  setMode: (mode: number) => void;
  disableTwinIDAutomation: () => void;
  twinIDAutomationActive: boolean;
  previousVersion: () => void
  nextVersion: () => void
  previousVersionActive: boolean
  nextVersionActive: boolean
  savedLocally: boolean
  uuid?: string
}

const DocHeader = ({
                     constructionSite,
                     contract,
                     doc,
                     setDoc,
                     setContract,
                     setConstructionSite,
                     mode,
                     errors,
                     warnings,
                     changed,
                     setMode,
                     createUpdateHandler,
                     createUpdateDraftHandler,
                     loseOfferHandler,
                     setChanged,
                     disableTwinIDAutomation,
                     twinIDAutomationActive,
                     previousVersion,
                     nextVersion,
                     previousVersionActive,
                     nextVersionActive,
                     savedLocally,
                     uuid,
                   }: Props) => {

  const theme = getTheme();
  const navigation = useNavigation();

  const serverReachable = useSelector(
    (state: Reducer) => state.sync.serverReachable
  )

  const [marginalityModalVisible, setMarginalityModalVisible] = useState<boolean>(false);
  const [previewModalVisible, setPreviewModalVisible] = useState<boolean>(false);

  const _closeHandler = () => {
    if (changed) {
      alertConfirm("CLOSE_WITHOUT_SAVING", "ARE_YOU_SURE_CLOSE_WITHOUT_SAVING").then(r => {
        if (r) {
          navigation.goBack();
        }
      });
    } else {
      if (uuid) {
        removeDocOffline(uuid)
      }
      navigation.goBack();
    }
  }

  const toLocaleDate = (date: string): string => {
    let d = new Date(date);
    return `${d.toLocaleDateString()}   ${d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
  }

  const toStringResource = (r: Resource): string => {
    return `${r.FirstName} ${r.LastName}`;
  }

  const _marginality = () => {
    setMarginalityModalVisible(true);
  }

  const _preview = () => {
    setPreviewModalVisible(true);
  }

  if (!doc) {
    return (
      <View>
        <View style={styles.horizontalContainer}>
          <View style={{flexDirection: "row"}}>
            <MaterialIcons
              size={34}
              name="close"
              onPress={_closeHandler}
            />
            <Text>{t('NO DOCUMENT LOADED')}</Text>
          </View>
        </View>
      </View>
    );
  }

  return (
    <View>
      <View style={styles.horizontalContainer}>
        <MaterialIcons
          size={34}
          name='close'
          onPress={_closeHandler}
        />
        <View style={{paddingHorizontal: 10}}>
          <Text style={{color: theme.text}}>
            {doc.IDString}
          </Text>
          {(doc.Version != null && doc.Version > 0) && (
            <Text>
             {t('VERSION')} {String(doc.Version)}
            </Text>
          )}
        </View>
        <Ionicons
          name="return-up-back"
          size={24}
          onPress={previousVersion}
          color={previousVersionActive ? theme.text : theme.secondaryBackground}
        />
        <View style={{width: 10}}/>
        <Ionicons
          name='return-up-forward-outline'
          size={24}
          onPress={nextVersion}
          color={nextVersionActive ? theme.text : theme.secondaryBackground}
        />

        <View style={{flex: 1, paddingHorizontal: 10}}>
          {(doc.CreatedBy && doc.CreatedDate) && (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{color: theme.text, fontWeight: '600', paddingRight: 10}}>{t('CREATED')}:</Text>
              <Text>{toStringResource(doc.CreatedBy)} - {toLocaleDate(doc.CreatedDate)}</Text>
            </View>
          )}
          {(doc.CreatedBy && doc.CreatedDate && doc.UpdatedBy && doc.UpdatedDate) && (
            <View style={{height: 5}}/>
          )}
          {(doc.UpdatedBy && doc.UpdatedDate) && (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{color: theme.text, fontWeight: '600', paddingRight: 10}}>{t('UPDATED')}:</Text>
              <Text>{toStringResource(doc.UpdatedBy)} - {toLocaleDate(doc.UpdatedDate)}</Text>
            </View>
          )}
        </View>
        
        <View style={{justifyContent: 'center'}}>
          {savedLocally && (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <MaterialCommunityIcons
                name='content-save-check'
                size={20}
                color='black'
              />
              <Text style={{fontSize: 14, paddingHorizontal: 5}}>
                {t('SAVED_OFFLINE')}
              </Text>
            </View>
          )}

          {doc.ToBeChecked && (
            <View style={{flexDirection: 'row', alignItems: 'center', paddingTop: savedLocally ? 5 : 0}}>
              <MaterialCommunityIcons
                name='eye'
                size={20}
                color={theme.red}
              />
              <Text style={{fontSize: 14, paddingHorizontal: 5, color: theme.red}}>
                {t('DRAFT')}
              </Text>
            </View>
          )}
        </View>
        
        <View style={{flexDirection: "row", alignItems:'center'}}>
          {serverReachable && (
            <Button
              style={{
                marginRight: 6,
                backgroundColor: theme.blue,
              }}
              onPress={_marginality}
              title='%'
            />
          )}
          {(doc.Lines?.length || 0) > 0 && (
            <Button
              style={{
                marginRight: 6,
                backgroundColor: theme.blue,
              }}
              onPress={_preview}
              titleT='PREVIEW'
            />
          )}
          {serverReachable && (
            <Button
              style={{
                marginRight: 6,
                backgroundColor: theme.blue,
              }}
              onPress={createUpdateHandler}
              titleT={mode == 0 ? "CREATE" : "UPDATE"}
            />
          )}
          {(serverReachable && doc?.Type?.CatalogType == CatalogTypes.Normal) && (
            <Button
              style={{
                marginRight: 6,
                backgroundColor: theme.mainColor,
              }}
              onPress={createUpdateDraftHandler}
              titleT='DRAFT'
            />
          )}
          <Text style={{color: theme.text}}>&nbsp;&nbsp;</Text>
          {(contract && constructionSite) && (
            <DocThreeDots
              doc={doc}
              contract={contract}
              constructionSite={constructionSite}
              setDoc={setDoc}
              setContract={setContract}
              setConstructionSite={setConstructionSite}
              mode={mode}
              setMode={setMode}
              setChanged={setChanged}
              disableTwinIDAutomation={disableTwinIDAutomation}
              twinIDAutomationActive={twinIDAutomationActive}
              loseHandler={loseOfferHandler}
            />
          )}
        </View>
      </View>
      <View style={{paddingLeft: 30}}>
        <FlatList
          data={errors}
          renderItem={
            ({item, index}) => <Text key={index} style={{color: theme.red}}>- {item}</Text>
          }
          key={`errors`}
          // @ts-ignore
          listKey={`errors`}
          keyExtractor={(_, index) => `error-${index}`}
        />
        <FlatList
          data={warnings}
          renderItem={
            ({item, index}) => <Text key={index} style={{color: theme.mainColor}}>- {item}</Text>
          }
          key={`warnings`}
          // @ts-ignore
          listKey={`warnings`}
          keyExtractor={(_, index) => `warning-${index}`}
        />
      </View>

      {marginalityModalVisible && <MarginalityModal doc={doc}
                        visible={marginalityModalVisible}
                        getPDFVisible={doc?.ID > 0}
                        constructionSite={constructionSite}
                        setVisible={setMarginalityModalVisible}/> }

      {(previewModalVisible && doc && constructionSite && contract) && (
        <PreviewModal doc={doc}
                      visible={previewModalVisible}
                      setVisible={setPreviewModalVisible}
                      constructionSite={constructionSite}
                      contract={contract}
                      statusVisible={false}/>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  horizontalContainer: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: "center",
  },
  horizontal: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 5,
  },
  additionalInfoTable: {
    flex: 1,
    flexDirection: "row",
  },
  additionalInfo: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingHorizontal: 10,
  },
});

export default DocHeader;