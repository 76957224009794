import React, { useState } from 'react'
import { MeasureLine, MeasureReport } from '../../models'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { FlatList, Text, View } from 'react-native'
import { t } from 'i18n-js'
import MeasureLineReportDetails from './MeasureLineReportDetails'
import Modal from 'react-native-modal'

interface Props {
  isOpen: boolean
  close: () => void
  measureLine: MeasureLine
  setMeasureLine: (measureLine: MeasureLine) => void
  readOnly: boolean
}

const MeasureDetailsModal = ({ isOpen, close, measureLine, setMeasureLine, readOnly }: Props) => {

  const [_measureLine, _setMeasureLine] = useState<MeasureLine>(measureLine)

  const _render = ({ item, index }: { item: MeasureReport, index: number }) => {

    const setItem = (item: MeasureReport) => {
      const measureReports = _measureLine.MeasureReports.map((m, i) => {
        if (i === index) {
          return item
        }
        return m
      })
      _setMeasureLine(measureLine => ({
        ...measureLine,
        MeasureReports: measureReports,
        ApprovedDuration: measureReports.reduce((p, v) => p + (v.Report.ApprovedDuration || 0), 0),
        ApprovedQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => p + (v.ApprovedQuantity || 0), 0) || 0), 0),
        BillableQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => p + (v.BillableQuantity || 0), 0) || 0), 0),
      }))
      setMeasureLine({
        ..._measureLine,
        MeasureReports: measureReports,
        ApprovedDuration: measureReports.reduce((p, v) => p + (v.Report.ApprovedDuration || 0), 0),
        ApprovableQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => p + (v.ApprovedQuantity || 0), 0) || 0), 0),
        BillableQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => p + (v.BillableQuantity || 0), 0) || 0), 0),
      })
    }

    return (
      <MeasureLineReportDetails
        item={item}
        index={index}
        measureLine={measureLine}
        key={`mr-${index}`}
        setItem={setItem}
        readOnly={readOnly}
      />
    )
  }

  return (
    <Modal
      isVisible={isOpen}
      onBackdropPress={close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        <MaterialCommunityIcons
          name='close'
          size={34}
          onPress={close}
        />
        <Text style={{ fontWeight: 'bold', fontSize: 16, paddingHorizontal: 15 }}>
          {measureLine.ActivityIDString}
        </Text>
        <Text style={{ flex: 1, fontSize: 16 }}>{measureLine.Name}</Text>
        <Text style={{ paddingLeft: 15, fontSize: 16 }}>{measureLine.UnitOfMeasure}</Text>
      </View>

      <View
        style={{ flexDirection: 'row', alignItems: 'flex-end' }}
      >
        <Text style={{ flex: 2 }}>{t('RESOURCE')}</Text>
        <Text style={{ flex: 1 }}>{t('DATE')}</Text>
        <Text style={{ flex: 1, textAlign: 'right' }}>{t('HOURS')}</Text>
        <Text style={{ flex: 2, textAlign: 'center' }}>{t('APPROVED_HOURS')}</Text>
        <Text style={{ flex: 1, textAlign: 'center' }}>{t('BUDGET_HOURS')}</Text>
        <Text style={{ width: 85, textAlign: 'right', paddingRight: 5 }}>{t('DONE')}</Text>
        <Text style={{ width: 110, textAlign: 'center' }}>{t('APPROVED')}</Text>
        <Text style={{ width: 110, textAlign: 'center' }}>{t('BILLABLE')}</Text>
        <View style={{ width: 30 }} />
      </View>

      <FlatList
        renderItem={_render}
        data={measureLine.MeasureReports}
        key={`measure-details-modal`}
        // @ts-ignore
        listKey={`measure-details-modal`}
        keyExtractor={(item, index) => `measure-details-modal-${index}`}
      />
    </Modal>
  )
}

export default MeasureDetailsModal