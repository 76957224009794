import React from 'react'
import { Level, LineType, Report } from '../../../models'
import getTheme from '../../../constants/Colors'
import { Text, View } from 'react-native'
import HtmlText from '../../../components/HtmlText'
import Formatter from '../../../utils/formatters'
import { decimalHoursToSexagesimal, isHours } from '../../../utils/reports/report-utils'
import { t } from 'i18n-js'
import { FlatList } from 'react-native-gesture-handler'

interface ReportLinePreviewProps {
  report: Report
  index: number
  levels: Level[]
}

const ReportLinePreview = ({ report, index, levels }: ReportLinePreviewProps) => {

  const theme = getTheme()

  const background = report.BackgroundColor ?? (index % 2 === 0 ? 'white' : theme.background)

  const f2 = Formatter.number2.format

  if (report.Type == LineType.TITLE) {
    return (
      <View style={{flexDirection: 'row', backgroundColor: background, padding: 5}}>
        <Text style={{width: 100}}>
          {report.ActivityIDString}
        </Text>
        <View style={{flex: 1}}>
          <HtmlText html={report.Name} maxLength={250} width='100%'/>
        </View>
      </View>
    )
  }

  const hours = isHours(report.UnitOfMeasure)

  if (levels.length == 1 && report.Levels && report.Levels.length > 0) {
    const firstLevel = report.Levels[0]

    const todo = (firstLevel.SoldQuantity || 0) - (firstLevel.DoneQuantity || 0)
    return (
      <View style={{flexDirection: 'row', backgroundColor: background, padding: 5}}>
        <Text style={{width: 100}}>
          {report.ActivityIDString}
        </Text>
        <View style={{flex: 3}}>
          <HtmlText html={report.Name} maxLength={250} width='100%'/>
        </View>
        <Text style={{flex: 1, textAlign: 'center'}}>
          {report.UnitOfMeasure}
        </Text>
        <Text style={{flex: 1, textAlign: 'right'}}>
          {hours ? decimalHoursToSexagesimal(firstLevel.Quantity) : f2(firstLevel.Quantity)}
        </Text>
        <Text style={{flex: 1, textAlign: 'right', color: theme.secondaryBackground}}>
          {hours ? decimalHoursToSexagesimal(todo) : f2(todo)}
        </Text>
        <Text style={{flex: 1, textAlign: 'right', color: theme.secondaryBackground}}>
          {hours ? decimalHoursToSexagesimal(firstLevel.SoldQuantity || 0) : f2(firstLevel.SoldQuantity || 0)}
        </Text>
      </View>
    )
  }

  return (
    <View>
      <View style={{flexDirection: 'row', backgroundColor: background, padding: 5}}>
        <Text style={{width: 100}}>
          {report.ActivityIDString}
        </Text>
        <View style={{flex: 3}}>
          <HtmlText html={report.Name} maxLength={250} width='100%'/>
        </View>
        <View style={{flex: 4}}/>
      </View>
      <FlatList
        data={report.Levels}
        renderItem={({item}) => (
          <View style={{flexDirection: 'row', backgroundColor: background, padding: 5}}>
            <View style={{width: 100}}/>
            <Text style={{flex: 3}}>
              {levels.find(l => l.ID == item.LevelID)?.Name ?? ''}
            </Text>
            <Text style={{flex: 1, textAlign: 'center'}}>
              {report.UnitOfMeasure}
            </Text>
            <Text style={{flex: 1, textAlign: 'right'}}>
              {hours ? decimalHoursToSexagesimal(item.Quantity) : f2(item.Quantity)}
            </Text>
            <Text style={{flex: 1, textAlign: 'right', color: theme.secondaryBackground}}>
              {hours ? decimalHoursToSexagesimal(item.Quantity) : f2(item.Quantity)}
            </Text>
            <Text style={{flex: 1, textAlign: 'right', color: theme.secondaryBackground}}>
              {hours ? decimalHoursToSexagesimal(item.SoldQuantity || 0) : f2(item.SoldQuantity || 0)}
            </Text>
          </View>
        )}
        keyExtractor={item => `${report.ID}-${item.LevelID}-${index}`}
      />
    </View>
  )
}

export default ReportLinePreview