import React from "react";
import { ConstructionSite, Marginality } from "../../models";
import MarginalityOverview from "./MarginalityOverview";

interface Props {
  constructionSite: ConstructionSite;
}

const ConstructionSiteMarginalityOverview = ({constructionSite}: Props) => {
  const m: Marginality = {
    engineeringCost: constructionSite.EngineeringCost,
    engineeringHours: constructionSite.EngineeringHours,
    engineeringRevenue: constructionSite.EngineeringRevenue,
    externalWorkCost: constructionSite.ExternalWorkCost,
    externalWorkRevenue: constructionSite.ExternalWorkRevenue,
    humanWorkCost: constructionSite.HumanWorkCost,
    humanWorkHours: constructionSite.HumanWorkHours,
    humanWorkRevenue: constructionSite.HumanWorkRevenue,
    materialCost: constructionSite.MaterialCost,
    materialRevenue: constructionSite.MaterialRevenue,
    minPrice: 0,
    warrantyRevenue: constructionSite.WarrantyRevenue,
    warrantyHumanWorkCost: constructionSite.WarrantyHumanCost,
    warrantyMaterialCost: constructionSite.WarrantyMaterialCost,
    catalogPrice: 0,
  }

  return (
    <MarginalityOverview marginality={m} />
  );
};

export default ConstructionSiteMarginalityOverview;