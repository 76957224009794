import React, { useMemo, useState } from 'react'
import { ConstructionSite, Contract } from '../../models'
import Modal from 'react-native-modal'
import { FlatList, Pressable, Text, TextInput, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Button from '../Button'
import { alert } from '../../hooks/Alert'
import getTheme from '../../constants/Colors'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { filterConstructionSites, filterContracts } from './util'
import ConstructionSiteItem from '../ConstructionSiteItem'
import Status from '../Status'
import { createConstructionSite, createContract } from '../../utils/doc/util'

interface ChooseContractModalProps {
  onChoose: (constructionSite: ConstructionSite, contract: Contract) => void
  onClose: () => void
  defaultConstructionSite?: ConstructionSite
  defaultContract?: Contract
  includeOpenContracts?: boolean
}

const ChooseContractModal = ({
  onChoose,
  onClose,
  defaultConstructionSite,
  defaultContract,
  includeOpenContracts = false,
}: ChooseContractModalProps) => {

  const theme = getTheme()

  const user = useSelector((state: Reducer) => state.user)
  const constructionSites = useSelector((state: Reducer) => state.constructionSite.constructionSites)

  const [constructionSite, setConstructionSite] =
    useState<ConstructionSite | undefined>(defaultConstructionSite)
  const [contract, setContract] = useState<Contract | undefined>(defaultContract)

  const [search, setSearch] = useState<string>('')

  const filteredConstructionSites = useMemo(
    () => filterConstructionSites(constructionSites, search),
    [constructionSites, search],
  )

  const contracts = useMemo(
    () => filterContracts(constructionSite, includeOpenContracts),
    [constructionSite],
  )

  const _constructionSite = (constructionSite: ConstructionSite) => {
    setSearch('')
    setConstructionSite(constructionSite)
    setContract(undefined)
  }

  const _clearConstructionSite = () => {
    setConstructionSite(undefined)
    setContract(undefined)
  }

  const _new = () => {
    if (constructionSite) {
      const { contract } = createContract(-1, constructionSite.ID, user)
      onChoose(constructionSite, contract)
      onClose()
    } else {
      const { constructionSite, contract } = createConstructionSite(-1, user)
      onChoose(constructionSite, contract)
      onClose()
    }
  }

  const _press = () => {
    if (constructionSite && contract) {
      onChoose(constructionSite, contract)
      onClose()
    } else if (!constructionSite) {
      alert('ERROR', 'CHOOSE_CONSTRUCTION_SITE')
    } else {
      alert('ERROR', 'CHOOSE_CONTRACT')
    }
  }

  const _renderConstructionSite = ({ item, index }: { item: ConstructionSite, index: number }) => (
    <ConstructionSiteItem
      constructionSite={item}
      onSelectConstructionSite={_constructionSite}
      key={`construction-site-${item.ID}-${index}`}
    />
  )

  const _renderContract = ({ item, index }: { item: Contract, index: number }) => {
    const _press = () => {
      if (constructionSite) {
        onChoose(constructionSite, item)
      }
    }

    return (
      <Pressable
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 12,
          borderTopColor: theme.border,
          borderTopWidth: 1,
          backgroundColor: contract?.ID == item.ID ? theme.background : 'white',
        }}
        onPress={_press}
        key={`contract-${item.ID}-${index}`}
      >
        <Text style={{fontSize: 16}}>{item.Name}</Text>
        {(contract?.ID == item.ID) && (
          <MaterialCommunityIcons
            name='check'
            size={24}
            color='black'
          />
        )}
      </Pressable>
    )
  }

  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      style={{
        padding: 15,
        backgroundColor: 'white',
        borderRadius: 15,
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Text style={{fontSize: 24}}>
          {t(constructionSite ? 'CHOOSE_CONTRACT' : 'CHOOSE_CONSTRUCTION_SITE')}
        </Text>

        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={onClose}
        />
      </View>
      
      <View style={{flex: 1, paddingTop: 15}}>

        {constructionSite && (
          <View style={{flexDirection: 'row'}}>
            <Text style={{width: 100, fontSize: 16, paddingBottom: 12}}>
              {constructionSite.Code ? constructionSite.Code : constructionSite.IDString }
            </Text>
            <View style={{flex: 1, paddingBottom: 12}}>
              <Text style={{fontSize: 16}}>
                {constructionSite.Name}
              </Text>
              {((constructionSite.Address?.length || 0) > 0) && (
                <Text style={{fontSize: 14}}>
                  {constructionSite.Address}
                </Text>
              )}
            </View>
            <View style={{width: 10}} />
            <View style={{paddingBottom: 12}}>
              <Status status={constructionSite.Status} size='m' />
            </View>
          </View>
        )}

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <TextInput
            placeholder={t('SEARCH')}
            value={search}
            onChangeText={setSearch}
            style={{
              flex: 1,
              borderWidth: 1,
              borderColor: theme.border,
              padding: 7,
              borderRadius: 5,
              fontSize: 16,
              marginRight: 10,
            }}
          />
          <View style={{
            backgroundColor: theme.background,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: theme.border,
            padding: 5,
          }}>
            <MaterialCommunityIcons
              name='plus'
              size={24}
              color='black'
              onPress={_new}
            />
          </View>
        </View>
        {constructionSite ? (
          <FlatList
            style={{paddingTop: 15, paddingBottom: 60}}
            data={contracts}
            renderItem={_renderContract}
            ListEmptyComponent={(
              <Text style={{textAlign: 'center', paddingTop: 15}}>
                {t('NO_CONTRACTS_FOR_CONSTRUCTION_SITE')}
              </Text>
            )}
          />
          ) : (
            <FlatList
              style={{paddingTop: 15, paddingBottom: 60}}
              data={filteredConstructionSites}
              renderItem={_renderConstructionSite}
            />
          )}
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        {constructionSite && (
          <Button
            titleT='CHOOSE_CONSTRUCTION_SITE'
            onPress={_clearConstructionSite}
            style={{
              backgroundColor: theme.blue,
              marginRight: 10,
            }}
          />
        )}
        <View style={{flex: 1}} />
        <Button
          titleT='CHOOSE'
          onPress={_press}
          disabled={!constructionSite || !contract}
          style={{
            backgroundColor: theme.mainColor,
          }}
        />
      </View>
    </Modal>
  )
}

export default ChooseContractModal
