import React, { useState } from 'react';
import DocumentVersionModal, { DocumentVersionModalConfig } from "./DocumentVersionModal";
import VersionsModal from "./VersionsModal";
import { ConstructionSite, Contract, Doc } from "../../models";

interface Props {
  onClose: () => void
  doc: Doc;
  contract: Contract;
  constructionSite: ConstructionSite
}

const DocumentVersionsModal = ({
  onClose,
  contract,
  constructionSite,
  doc,
}: Props) => {

  const [
    documentVersionModalConfig,
    setDocumentVersionModalConfig
  ] = useState<DocumentVersionModalConfig>({
    visible: false,
    contract,
    constructionSite,
  })

  const _onCloseDocumentVersionModal = () => {
    setDocumentVersionModalConfig({
      visible: false,
      contract,
      constructionSite,
    })
  }

  const _onDuplicate = () => {
    setDocumentVersionModalConfig({
      visible: false,
      contract,
      constructionSite,
    })
  }

  if (documentVersionModalConfig.visible) {
    return (
      <DocumentVersionModal
        onClose={_onCloseDocumentVersionModal}
        onDuplicate={_onDuplicate}
        {...documentVersionModalConfig}
      />
    )
  }
  
  return (
    <VersionsModal
      setDocumentVersionModalConfig={setDocumentVersionModalConfig}
      onClose={onClose}
      contract={contract}
      constructionSite={constructionSite}
      doc={doc}
    />
  )
};

export default DocumentVersionsModal;