import {ActivityIndicator, Modal, StyleSheet, Text, View} from "react-native";
import {t} from 'i18n-js';
import React, { useEffect, useState } from "react";
import { ConstructionSiteViewGroups, Doc } from "../../models";
import { getDocById } from "../../api/DocAPI";
import { alert } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { useDispatch } from "react-redux";
import getTheme from "../../constants/Colors";
import Button from "../../components/Button";
import { sentryCapture } from "../../utils/sentryCapture";

interface Props {
  updateModalVisible: boolean;
  doc: Doc;
  setUpdateModalVisible: (visible: boolean) => void;
  docUpdate: (newVersion:boolean) => void;
}

const UpdateModal = ({updateModalVisible, doc, setUpdateModalVisible, docUpdate}:Props) => {

  const theme = getTheme();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  const [localDoc, setLocalDoc] = useState<Doc>();

  useEffect(() => {
    if (doc.ID && doc?.Type?.ID && doc.ID > 0 && doc.Type.Config.ConstructionSiteViewGroup === ConstructionSiteViewGroups.Offer) {
      setLoading(true)
      getDocById(doc.ID, doc.Type).then(setLocalDoc).catch(e => {
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_LOADING_DOCUMENT");
        }
      }).finally(() => setLoading(false))
    }
  }, [doc.ID, doc.Type]);

  const _closeRequestHandler = () => {
    setUpdateModalVisible(false);
  }

  const _pressNewVersion = () => {
    docUpdate(true);
    setUpdateModalVisible(false);
  }

  const _pressUpdateVersion = () => {
    docUpdate(false);
    setUpdateModalVisible(false);
  }

  const updateVersionDisabled = doc?.Client?.ID !== localDoc?.Client?.ID;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={updateModalVisible}
      onRequestClose={_closeRequestHandler}>
      <View style={styles.container}>
        <View style={styles.modal}>
          <Text style={styles.title}> {t('WOULD_YOU_CREATE_NEW_VERSION')}</Text>
          <Text>{doc.IDString?.length > 0 ? t('DOCUMENT_NUMBER') + " " + doc.IDString + " " + t('VERSION') : ""}</Text>
          <View style={{flexDirection: 'row'}}>
            <Button
              onPress={_pressNewVersion}
              style={[styles.button, {backgroundColor: theme.blue}]}
              titleT='CREATE_NEW_VERSION'
            />

            <Button
              onPress={_pressUpdateVersion}
              style={[styles.button, {
                backgroundColor: updateVersionDisabled?theme.background:theme.blue,
              }]}
              disabled={updateVersionDisabled || loading}
              titleT={loading ? undefined : 'UPDATE_VERSION'}
            >
              {loading && (
                <View>
                  <ActivityIndicator />
                </View>
              )}
            </Button>
          </View>
          <Button
            onPress={_closeRequestHandler}
            style={[styles.button, {backgroundColor: theme.blue}]}
            titleT="CANCEL"
          />
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(128, 128, 128, 0.7)",
  },
  modal: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 50,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  button: {
    marginTop: 5,
    marginHorizontal: 5,
  },
});

export default UpdateModal;