import React, { useState } from 'react'
import { Client } from '../../models'
import getTheme from '../../constants/Colors'
import Modal from 'react-native-modal'
import { Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { googleMapsLinkToCoordinates } from '../../hooks/geo'
import { GooglePlaceData, GooglePlaceDetail, GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import { alert } from '../../hooks/Alert'
import Button from '../Button'
import { googlePlacesToClient } from '../../utils/GooglePlacesUtils'

interface GoogleSearchClientProps {
  onClose: () => void
  onChoose: (client: Client) => void
}

const GoogleSearchClient = ({onClose, onChoose}: GoogleSearchClientProps) => {
  
  const theme = getTheme()

  const url = useSelector((state: Reducer) => state.auth.workSpace?.url || '')
  const token = useSelector((state: Reducer) => state.auth.token)
  const branch = useSelector((state: Reducer) => state.user.branch.Maps)
  const branchCoordinates = googleMapsLinkToCoordinates(branch).coords

  const [name, setName] = useState<string>('')
  const [client, setClient] = useState<Client>()

  const _selectAutoComplete = (data: GooglePlaceData, details: GooglePlaceDetail | null) => {
    if (details) {
      setClient(googlePlacesToClient(details))
    } else {
      alert('ERROR', 'ERROR_GETTING_CLIENT_DETAILS')
    }
  }

  const _choose = () => {
    if (client) {
      onChoose(client)
      onClose()
    }
  }

  return (
    <Modal
      onBackdropPress={onClose}
      isVisible={true}
      style={{
        padding: 15,
        borderRadius: 15,
        backgroundColor: 'white',
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Text style={{fontSize: 24}}>
          {t('SEARCH_CLIENT_TO_IMPORT_FROM_GOOGLE')}
        </Text>

        <MaterialCommunityIcons
          name='close'
          size={34}
          color={theme.text}
          onPress={onClose}
        />
      </View>

      <View style={{
        flex: 1,
        backgroundColor: theme.background,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: theme.border,
        marginTop: 15,
      }}>
        <GooglePlacesAutocomplete
          fetchDetails={true}
          textInputProps={{
            onChangeText: setName,
            value: name,
          }}
          placeholder='Search'
          onPress={_selectAutoComplete}
          query={{
            key: 'AIzaSyDqb1ADutBQksnAv43XLyjDQuJO9cTALNU',
            language: 'en',
            location: `${branchCoordinates.latitude},${branchCoordinates.longitude}`,
            radius: 5000,
            input: name,
          }}
          requestUrl={{
            useOnPlatform: 'web',
            url: `${url}googleMapsApiProxy`,
            headers: {
              Authorization: token,
            },
          }}
        />
      </View>

      <View style={{flex: 1, paddingTop: 15}}>
        {client && (
          <View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{flex: 1, fontSize: 18}}>
                {t('CLIENT')}: {client.Name}
              </Text>
              <Text style={{flex: 1, fontSize: 18}}>
                {t('PHONE')}: {client.Phone}
              </Text>
            </View>
            <View style={{flexDirection: 'row', marginTop: 10}}>
              <Text style={{fontSize: 18, width: 100}}>
                {t('ADDRESS')}:
              </Text>
              <Text style={{fontSize: 18}}>
                {client.Addresses[0].StreetLine0 + '\n'}
                {client.Addresses[0].PostCode} {client.Addresses[0].City + '\n'}
                {client.Addresses[0].Region + '\n'}
                {client.Addresses[0].Country + '\n'}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
        <Button
          titleT='CHOOSE'
          style={{backgroundColor: theme.mainColor}}
          onPress={_choose}
          disabled={!client}
        />
      </View>
    </Modal>
  )
}

export default GoogleSearchClient
