import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import BouncyCheckbox from "react-native-bouncy-checkbox";

import { LineType, Item } from '../../../models'
import HtmlText from "../../../components/HtmlText";
import { Reducer } from "../../../store/reducers";
import { PriceLevel } from "../../../store/reducers/settingsReducer";
import getTheme from "../../../constants/Colors";
import Button from "../../../components/Button";
import Formatter from "../../../utils/formatters";

interface Props {
  toAdd: Item[];
  item: Item;
  index: number;
  change: (state: boolean) => void;
  press: () => void;
  showCheckboxes?: boolean;
  nestedIndex: number
  showChildren?: boolean
  toggleShowChildren?: () => void
}

const InsertPositionLine = ({toAdd, item, index, change, press, showCheckboxes = true, nestedIndex, showChildren, toggleShowChildren}: Props) => {
  
  const theme = getTheme();

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);
  const client = showPriceLevel >= PriceLevel.CLIENT;

  const [selected, setSelected] = useState<boolean>(false);

  const _change = () => {
    setSelected(old => {
      change(!old);
      return !old;
    });
  }

  let bouncyCheckboxRef: BouncyCheckbox | null = null;

  useEffect(() => {
    for (let i = 0; i < toAdd.length; i++) {
      if (toAdd[i].ID == item.ID) {
        if (!selected) {
          bouncyCheckboxRef?.onPress()
        }
        setSelected(true);
      }
    }
  }, [toAdd]);

  const backgroundColor = item.BackgroundColor ?? (index % 2 == 0 ? "light-gray" : "white")

  return (
    <View
      key={item.ID}
      style={{
        flexDirection: 'row',
        marginTop: 10,
        backgroundColor: backgroundColor,
        paddingLeft: 10 * nestedIndex,
        borderTopColor: theme.border,
        borderTopWidth: 1,
      }}
    >
      { ((showChildren != undefined) && toggleShowChildren) ? ( 
        <AntDesign
          name={showChildren ? 'caretdown' : 'caretright'}
          size={15}
          onPress={toggleShowChildren}
          color={"black"}
          style={{marginRight: 10}}
        />
      ) : (
        <View style={{paddingHorizontal: 15}}/>
      )}

      <Text
        style={{
          width: 100,
          fontWeight: item.Type === LineType.TITLE ? 'bold' : 'normal',
        }}
      >
        {item.IDString}
      </Text>
      <View style={{flex: 1}}>
          <HtmlText width={"100%"} html={item.Name} maxLength={80}/>
        </View>
        <Text style={{width: 100}}>{(client && typeof item.Price == 'number') ?Formatter.number2.format(item.Price):""}</Text>
        <Text style={{width: 100}}>{item.UnitOfMeasure}</Text>
        <View
          style={{
            marginRight: 50,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {showCheckboxes && (
            <BouncyCheckbox
              onPress={_change}
              isChecked={selected}
              fillColor={theme.mainColor}
              iconStyle={{borderColor: theme.border, borderRadius: 10}}
              innerIconStyle={{borderColor: theme.border, borderRadius: 10}}
              disableBuiltInState
              ref={(ref: any) => (bouncyCheckboxRef = ref)}
            />
          )}
        </View>
        <Button
          style={{
            margin: 2,
            backgroundColor: theme.secondaryBackground,
            alignSelf: 'center',
          }}
          titleT="ADD"
          onPress={press}
          leftComponent={<MaterialIcons name={"add"} size={18} color={"white"}/>}
        />
    </View>
  )
}

export default InsertPositionLine;