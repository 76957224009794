import React, { useMemo } from 'react'
import { DayReport, ExternalWorkInvoice, LineType, Report } from '../../models'
import { Text, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import getTheme from '../../constants/Colors'
import ExternalWorkInvoiceReportPreview from './ExternalWorkInvoiceReportPreview'
import { t } from 'i18n-js'
import ExternalWorkInvoiceData from './ExternalWorkInvoiceData'

interface ExternalWorkInvoiceDayReportPreviewProps {
  externalWorkInvoice: ExternalWorkInvoice
  dayReport: DayReport
}

const ExternalWorkInvoiceDayReportPreview = ({
  externalWorkInvoice,
  dayReport,
}: ExternalWorkInvoiceDayReportPreviewProps) => {

  const theme = getTheme()

  const reports = useMemo(() => {
    if (!dayReport?.Reports) {
      return []
    }
    return dayReport.Reports.filter(
      (r) => r.Type == LineType.POSITION_EXTERNAL_WORK
    )
  }, [dayReport])

  const _render = ({item, index}: {item: Report, index: number}) => {
    return (
      <ExternalWorkInvoiceReportPreview
        report={item}
        index={index}
        levels={dayReport?.Levels || []}
      />
    )
  }

  return (
    <View style={{paddingTop: 15, flex: 1}}>
      <View style={{flexDirection: 'row', alignItems: 'flex-end', paddingHorizontal: 10}}>
        <Text style={{fontWeight: 'bold', width: 100}}>
          {t('CODE')}
        </Text>
        <Text style={{fontWeight: 'bold', flex: 1}}>
          {t('DESCRIPTION')}
        </Text>
        <Text style={{fontWeight: 'bold', width: 100, textAlign: 'center'}}>
          {t('UNIT_OF_MEASURE')}
        </Text>
        <Text style={{fontWeight: 'bold', width: 100, textAlign: 'right'}}>
          {t('QUANTITY')}
        </Text>
      </View>

      <FlatList
        style={{paddingTop: 15}}
        data={reports}
        renderItem={_render}
      />

      <Text style={{paddingTop: 15, fontWeight: 'bold'}}>
        {t('DAY_REPORT_NOTES')}:
      </Text>

      <Text style={{
        marginTop: 15,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: theme.border,
        padding: 10,
      }}>
        {
          (dayReport.Notes?.length || 0) > 0
            ? dayReport.Notes
            : '-'
        }
      </Text>
    </View>
  )
}

export default ExternalWorkInvoiceDayReportPreview