import { FileType } from "../../../models";
import styles from "./style";
import { Pressable, Text, View } from "react-native";
import React from "react";
import getTheme from "../../../constants/Colors";
import ConstructionSiteViews from "./ConstructionSiteViews";

interface Props {
  fileType: FileType;
  setFileTypesToOpen: (fileType: FileType) => void;
  fileTypesToOpen?: FileType;
  setSelectedView: (selectedView: ConstructionSiteViews) => void;
}

const ConstructionSiteDetailsFileType = ({fileType, setFileTypesToOpen, fileTypesToOpen, setSelectedView}: Props) => {

  const theme = getTheme();

  const _press = () => {
    setFileTypesToOpen(fileType);
    setSelectedView(ConstructionSiteViews.FileType);
  }

  const backgroundColor = fileType === fileTypesToOpen ? "lightgray" : "white";

  return (
    <View key={"Contract files" + fileType.ID}>
      <View>
        <Pressable style={[styles.categoryContainer, {backgroundColor}]} onPress={_press}>
          <Text style={styles.categoryText}>{fileType.Name}</Text>
        </Pressable>
      </View>
      <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}} />
    </View>
  );
}

export default ConstructionSiteDetailsFileType;