import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ConstructionSite, Contract, Doc } from '../../models'
import { alert } from '../../hooks/Alert'
import { getDocOffline } from '../../utils/doc-offline/util'
import { ActivityIndicator, View } from 'react-native'
import DocScreen from './DocScreen'
import { DocMode } from './utils'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { sentryCapture } from '../../utils/sentryCapture'

interface Props {
  route: {
    params?: {
      uuid?: string
    }
  }
}

const DocScreenOffline = ({route}: Props) => {
  
  const navigation = useNavigation()

  const catalogs = useSelector(
    (state: Reducer) => state.general.catalogs
  )

  const [constructionSite, setConstructionSite] = useState<ConstructionSite>()
  const [contract, setContract] = useState<Contract>()
  const [doc, setDoc] = useState<Doc>()

  useEffect(() => {
    if (!route.params?.uuid) {
      alert('ERROR', 'ERROR_OPENING_OFFLINE_DOC')
      navigation.goBack()
      return
    }

    getDocOffline(route.params.uuid, catalogs).then(docOffline => {
      if (!docOffline) {
        alert('ERROR', 'ERROR_OPENING_OFFLINE_DOC')
        navigation.goBack()
        return
      }
      setConstructionSite(docOffline.constructionSite)
      setContract(docOffline.contract)
      setDoc(docOffline.doc)
    }).catch(sentryCapture)
  }, [route.params?.uuid])

  if (!constructionSite || !contract || !doc || !route.params?.uuid) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <DocScreen
      initialMode={(doc?.ID > 0) ? DocMode.UPDATE : DocMode.CREATE}
      initialConstructionSite={constructionSite}
      initialContract={contract}
      initialDoc={doc}
      uuid={route.params.uuid}
    />
  )
}

export default DocScreenOffline