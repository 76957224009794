import React, { useMemo } from "react";
import { UoM } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import {Picker} from "@react-native-picker/picker";
import getTheme from "../../constants/Colors";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  selected: UoM | string | undefined;
  onChange: (uom: UoM) => void;
}

const UoMComponent = ({ selected, onChange }: Props) => {

  const theme = getTheme();

  const uoms = useSelector((state: Reducer) => state.general.uoms);

  const first = useMemo(() => {
    if (uoms.length > 0) {
      return uoms[0];
    } else {
      return { ID: 0, IDString: "", Hours: false, Name: "" };
    }
  }, [uoms]);

  const selectedItem = useMemo(() => {
    if (selected == undefined || selected == "" || selected == null) {
      onChange(first)
      return first
    }
    if (typeof selected === "string") {
      const s = selected?.toLowerCase()
      return uoms.find(u => u.Name.toLowerCase() === s || u.IDString.toLowerCase() === s) || first;
    }
    return selected;
  }, [selected, uoms]);

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        padding: 5,
        borderRadius: 5,
        backgroundColor: theme.background,
      }}
      selectedValue={selectedItem}
      onValueChange={(selected) => {
        const uom = uoms.find((uom) => uom.ID === selected.ID);
        if (uom != undefined) {
          onChange(uom);
        }
      }}
      options={uoms}
      placeholder='SELECT_UOM'
    />
  );
};

export default UoMComponent;
