import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import SearchScreen from "./SearchScreen";
import ClientOverview from "./ClientOverview";
import ConstructionSiteDetails from "../constructionSites/ConstructionSiteDetails";
import { SearchScreens } from "./SearchScreens";
import DayReportCreate from "../reports/DayReportCreate";
import DocScreenUpdate from "../doc/DocScreenUpdate";
import DayReportUpdate from "../reports/DayReportEdit";
import DayReportApprove from "../reports/DayReportApprove";
import DocScreenCreateContract from "../doc/DocScreenCreateContract";
import UpdateClientScreen from "../client/UpdateClientScreen";
import MeasureCreateView from "../measure/MeasureCreateView";
import MeasurePreviewView from "../measure/MeasurePreviewView";
import DocScreenCreate from "../doc/DocScreenCreate";
import CreateClientScreen from "../client/CreateClientScreen";
import DocScreenDuplicate from "../doc/DocScreenDuplicate";
import EditOrderView from "../EditOrderView";
import InsertPositionScreen from "../catalog/InsertPositionScreen";
import CreatePositionScreen from "../catalog/CreatePositionScreen";
import DayReportDraft from "../reports/DayReportDraft";
import DocScreenOffline from "../doc/DocScreenOffline";
import OffersToCheckScreen from "./OffersToCheckScreen";

const Stack = createStackNavigator();

export const SearchScreenStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen options={{headerShown: false}}
                    name={SearchScreens.SEARCH}
                    component={SearchScreen}/>

      <Stack.Screen options={{headerShown: false}}
                    name={SearchScreens.CLIENT_OVERVIEW}
                    component={ClientOverview}/>

      <Stack.Screen options={{headerShown: false}}
                    name={SearchScreens.CONSTRUCTION_SITES_DETAILS}
                    component={ConstructionSiteDetails}/>

      <Stack.Screen name={SearchScreens.DOC}
                    options={{headerShown: false}}
                    component={DocScreenUpdate} />

      <Stack.Screen name={SearchScreens.REPORT_CREATE}
                    options={{headerShown: false}}
                    component={DayReportCreate} />

      <Stack.Screen name={SearchScreens.REPORT_UPDATE}
                    options={{headerShown: false}}
                    component={DayReportUpdate}/>

      <Stack.Screen name={SearchScreens.REPORT_APPROVE}
                    options={{headerShown: false}}
                    component={DayReportApprove}/>

      <Stack.Screen name={SearchScreens.CREATE_CONTRACT}
                    options={{headerShown: false}}
                    component={DocScreenCreateContract}/>

      <Stack.Screen name={SearchScreens.CREATE_OFFER}
                    options={{headerShown: false}}
                    component={DocScreenCreate}/>

      <Stack.Screen name={SearchScreens.MEASURE_CREATE}
                    options={{headerShown: false}}
                    component={MeasureCreateView}/>

      <Stack.Screen name={SearchScreens.MEASURE_VIEW}
                    options={{headerShown: false}}
                    component={MeasurePreviewView}/>

      <Stack.Screen name={SearchScreens.CREATE_CLIENT}
                    options={{headerShown: false}}
                    component={CreateClientScreen}/>

      <Stack.Screen name={SearchScreens.UPDATE_CLIENT}
                    options={{headerShown: false}}
                    component={UpdateClientScreen}/>

      <Stack.Screen name={SearchScreens.DUPLICATE_DOC}
                    options={{headerShown: false}}
                    component={DocScreenDuplicate}/>

      <Stack.Screen name={SearchScreens.EDIT_ORDER}
                    options={{headerShown: false}}
                    component={EditOrderView} />

      <Stack.Screen name={SearchScreens.INSERT_POSITION}
                    options={{headerShown: false}}
                    component={InsertPositionScreen} />

      <Stack.Screen name={SearchScreens.CREATE_POSITION}
                    options={{headerShown: false}}
                    component={CreatePositionScreen} />

      <Stack.Screen
        name={SearchScreens.REPORT_DRAFT}
        options={{headerShown: false}}
        component={DayReportDraft}
      />

      <Stack.Screen
        name={SearchScreens.DOCS_OFFLINE}
        options={{headerShown: false}}
        component={DocScreenOffline}
      />

      <Stack.Screen
        name={SearchScreens.OFFER_TO_CHECK}
        options={{headerShown: false}}
        component={OffersToCheckScreen}
      />
    </Stack.Navigator>
  );
};
