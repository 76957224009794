import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ResourcesScreen from "./ResourceScreen";
import ConstructionSiteDetails from "../constructionSites/ConstructionSiteDetails";

const Stack = createStackNavigator();

export const ResourceStackNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="ResourcesScreen"
        component={ResourcesScreen}
      />
    </Stack.Navigator>
  );
};
