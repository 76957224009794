import { Doc, DocumentContact } from "../../../models";

export const newDocumentContact = (id: number, doc: Doc):DocumentContact => {
  return {
    ID: id,
    Name: "",
    ClientID: 0,
    DigitalDocuments: false,
    Email: "",
    Mobile: "",
    ClientContact: null,
    Client: null,
    DocumentID: doc.ID,
    Lastname: "",
    ClientContactID: 0,
    Firstname: "",
    DocTypeID: doc.Type.ID,
    TypeID: "",
  }
}