import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { Client, ConstructionSite, Contract, Doc } from "../../models";
import { alert, alertConfirm, docOverwriteChangesAlert } from "../../hooks/Alert";
import { cancelOrder, isDocSameVersion, updateOfferStatus, updateOrder } from "../../api/DocAPI";
import LoseOfferModal from "../../screens/doc/LoseOfferModal";
import { logout } from "../../api/AuthAPI";
import Modal from "react-native-modal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import getTheme from "../../constants/Colors";
import { ActivityIndicator, Text, View } from "react-native";
import Button from "../Button";
import SegmentedView from "../SegmentedView";
import PdfComponent from "../PDFComponent";
import SendPdf from "../SendPdf";
import { DocStatus } from "../../constants/Status";
import StatusDropdown from "../StatusDropdown";
import { t } from "i18n-js";
import { docToSapDocConvert } from '../../shared-utils/ConversionUtils'
import DocPreviewMenuMarginality from "./DocPreviewMenuMarginality";
import WinModal from "../WinModal";
import { sentryCapture } from "../../utils/sentryCapture";

interface Props {
  doc: Doc;
  contract: Contract;
  constructionSite: ConstructionSite
  setLoading?: (loading: boolean) => void;
  setSelectedViewOrder?: () => void;
  setSelectedOverview?: () => void;
  pull?: () => void;
  showWinLose?: boolean;
}

const DocPreviewMenu = ({
  doc,
  contract,
  constructionSite,
  setLoading,
  setSelectedViewOrder,
  setSelectedOverview,
  pull,
  showWinLose = false,
}: Props) => {

  const dispatch = useDispatch();
  const theme = getTheme();

  const [open, setOpen] = useState<boolean>(false);
  const _open = () => setOpen(true);
  const _close = () => setOpen(false);

  const [status, setStatus] = useState<number>(doc.Status);
  const [saveStatusLoading, setSaveStatusLoading] = useState<boolean>(false);

  const _saveStatus = () => {
    setSaveStatusLoading(true);
    const sapDoc = docToSapDocConvert(doc);
    updateOrder({...sapDoc, Status: status})
      .then(pull)
      .catch(e => {
        if (e.error?.response && e.error.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_UPDATING_OFFER_STATUS");
        }
      })
      .finally(() => setSaveStatusLoading(false));
  }

  const _cancelOrder = async () => {
    if (!setSelectedOverview) {
      alert("ERROR", "ERROR_CANCELING_ORDER");
      return;
    }
    if (!await alertConfirm("CONTINUE", "CONTINUE_CANCEL_ORDER")) {
      return;
    }
    if (!await alertConfirm("CONTINUE", "CONTINUE_CANCEL_ORDER_2")) {
      return;
    }
    const sapDoc = docToSapDocConvert(doc);
    cancelOrder(sapDoc)
      .then(setSelectedOverview)
      .catch(e => {
        if (e.error?.response && e.error.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_CANCELING_ORDER");
        }
      })
  }

  const sales = useSelector((state: Reducer) => state.user.permissions.sales);
  const url = useSelector((state: Reducer) => state.auth.workSpace!.url);
  const token = useSelector((state: Reducer) => state.auth.token);

  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  const [winOfferModalVisible, setWinOfferModalVisibile] = useState<boolean>(false);
  const _closeWinOfferModal = () => setWinOfferModalVisibile(false)
  const [loseOfferModalVisible, setLoseOfferModalVisible] = useState<boolean>(false);

  const _win = async () => setWinOfferModalVisibile(true);

  const _lose = () => {
    setLoseOfferModalVisible(true);
  }

  const _success = () => setSelectedViewOrder && setSelectedViewOrder();
  
  const _loseHandler = async (client: Client | null, notes: string | null) => {
    if (doc) {
      try {
        setLoading && setLoading(true)
        const c = await isDocSameVersion(doc)
        if (c.changed) {
          if (!await docOverwriteChangesAlert(c.doc)) {
            setLoading && setLoading(false)
            return
          }
        }
        const updatedDoc = await updateOfferStatus(doc, false, String(client?.ID), notes, catalogs);
        if (updatedDoc) {
          setLoseOfferModalVisible(false);
          pull && pull();
        }
      } catch (e: any) {
        if (e.error?.response && e.error.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_UPDATING_OFFER_STATUS");
        }
      } finally {
        setLoading && setLoading(false);
      }
    }
  }

  if (!sales) {
    return (
      <></>
    );
  }

  const titles = ['SEND_PDF', 'MARGINALITY', 'HOURS_LIST', 'MATERIALS_LIST', 'BILLS_OF_MATERIALS', 'MARGINALITY_CONSUNTIVE'];
  const pdfUrls = ['getHoursListPDF', 'getMaterialsListPDF', 'getDocumentBillsOfMaterialsPDF', 'getMarginalityConsuntivePDF']

  const buildUrl = (path: string) => {
    return `${url}/${path}?documentType=${doc.TypeID}&documentEntry=${doc.ID}&token=${encodeURIComponent(token)}`;
  }

  const views = (index: number) => {
    if (index == 0) {
      return (
        <SendPdf
          doc={doc}
          contract={contract}
          constructionSite={constructionSite}
          close={_close}
        />
      )
    } else if (index == 1) {
      return (
        <DocPreviewMenuMarginality doc={doc} />
      )
    }
    
    return (
      <PdfComponent url={buildUrl(pdfUrls[index - 2])} />
    )
  }

  if (loseOfferModalVisible) {
    return (
      <LoseOfferModal
          loseOfferModalVisible={loseOfferModalVisible}
          setLoseOfferModalVisible={setLoseOfferModalVisible}
          loseHandler={_loseHandler}
        />
    )
  }
  if (winOfferModalVisible) {
    return (
      <WinModal
        onClose={_closeWinOfferModal}
        doc={doc}
        contract={contract}
        constructionSite={constructionSite}
      />
    )
  }

  return (
    <>
      <MaterialCommunityIcons
        name="dots-vertical"
        size={24}
        color={theme.secondaryBackground}
        onPress={_open}
      />
      <Modal
        isVisible={open}
        onBackdropPress={_close}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text style={{flex: 1}}>{doc.Type.Name}: {doc.IDString}</Text>
          {
            doc.Type.Config.ConstructionSiteViewGroup == 'offer' && showWinLose && (contract.Status != DocStatus.OFFER.code || doc.Type.Config.TypeFilter != 'integrative-offer') && (
              <>
                <Button
                  onPress={_win}
                  titleT='WIN'
                  style={{
                    marginHorizontal: 2,
                    backgroundColor: theme.blue,
                  }}
                />
                <Button
                  onPress={_lose}
                  titleT='LOSE'
                  style={{
                    marginHorizontal: 2,
                    backgroundColor: theme.blue,
                  }}
                />
              </>
            )
          }
          {
            doc.Type.Config.ConstructionSiteViewGroup == 'order' && (
              <>
                <Button
                  onPress={_cancelOrder}
                  style={{backgroundColor: theme.secondaryBackground}}
                  titleT="CANCEL_ORDER"
                />
                <Text style={{marginRight: 2}}>{t("STATUS")}</Text>
                <StatusDropdown setStatus={setStatus} status={status} />
              </>
            )
          }
          {
            status != doc.Status && (
              <Button
                style={{backgroundColor: theme.mainColor}}
                onPress={_saveStatus}
                disabled={saveStatusLoading}
                titleT="SAVE"
              >
                {saveStatusLoading && <ActivityIndicator /> }
              </Button>
            )
          }
          <MaterialCommunityIcons
            name="close"
            size={34}
            color='black'
            onPress={_close}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 10,
          }}
        >
          <SegmentedView
            titlesT={titles}
            views={views}
          />
        </View>
      </Modal>
    </>
  );
};

export default DocPreviewMenu;