import React, { useMemo } from 'react'
import { Level, Line, LineLevel, MeasureLevel, MeasureLine } from '../../models';
import { FlatList, Text, View } from 'react-native';
import getTheme from '../../constants/Colors';
import Formatter from '../../utils/formatters';
import HtmlText from '../HtmlText';
import MeasureInvoiceLevelPreview from './MeasureInvoiceLevelPreview';

interface MeasureInvoiceLinePreviewProps {
  measureLine?: MeasureLine
  printLevels: boolean
  index: number
  orderLine: Line
  showTotalQuantity: boolean
}

const MeasureInvoiceLinePreview = ({
  measureLine,
  printLevels,
  index,
  orderLine,
  showTotalQuantity,
}: MeasureInvoiceLinePreviewProps) => {

  const theme = getTheme()

  const f2 = Formatter.number2.format

  const background = index % 2 === 0 ? theme.background : 'white'

  let quantity = measureLine?.BillableQuantity || 0
  if (showTotalQuantity) {
    quantity += measureLine?.BilledQuantity || 0
  }
  const quantityText = quantity != 0 ? f2(quantity) : ''

  const price = useMemo(() => {
    return orderLine
      .LineLevel
      ?.reduce((p, v) => p == 0 ? v.Price : p, 0) || 0
  }, [orderLine.LineLevel])
  const priceText = price != 0 ? f2(price) : ''

  const discount = useMemo(() => {
    return orderLine
      .LineLevel
      ?.reduce((p, v) => {
        if (p == '' && v.Discount != 0) {
          return v.DiscountNumeric ? `-${f2(v.Discount)}` : `-${f2(v.Discount)}%`
        }
        return p
      }, '') || ''
  }, [orderLine.LineLevel])

  const lineTotal = price * quantity
  const lineTotalText = lineTotal != 0 ? f2(lineTotal) : ''

  const _render = ({ item, index: levelIndex }: { item: MeasureLevel, index: number }) => {
    const orderLineLevel = orderLine.LineLevel[levelIndex]

    return (
      <MeasureInvoiceLevelPreview
        measureLevel={item}
        orderLevel={orderLineLevel}
        showTotalQuantity={showTotalQuantity}
        index={index}
        key={`line-level-${orderLine.ID}-${item.Level.ID}-${index}-${levelIndex}`}
      />
    )
  }

  return (
    <>
      <View style={{
        flexDirection: 'row',
        backgroundColor: background,
        padding: 5,
      }}>
        <Text style={{ width: 100 }}>
          {orderLine.Code}
        </Text>
        <View style={{ flex: 1 }}>
          <HtmlText html={orderLine.Name} />
        </View>
        <Text style={{ width: 100, textAlign: 'center' }}>
          {orderLine.UnitOfMeasure}
        </Text>
        {printLevels ? (
          <View style={{ width: 400 }} />
        ) : (
          <>
            <Text style={{ width: 100, textAlign: 'right' }}>
              {priceText}
            </Text>
            <Text style={{ width: 100, textAlign: 'right' }}>
              {quantityText}
            </Text>
            <Text style={{ width: 100, textAlign: 'right' }}>
              {discount}
            </Text>
            <Text style={{ width: 100, textAlign: 'right' }}>
              {lineTotalText}
            </Text>
          </>
        )}
      </View>
      {printLevels && (
        <FlatList
          data={measureLine?.Levels || []}
          renderItem={_render}
        />
      )}
    </>
  )
}

export default MeasureInvoiceLinePreview