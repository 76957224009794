import { Address } from "../../models";

export const newAddress = (clientId:string):Address => {
  return {
    ID: "",
    City: "",
    StreetLine0: "",
    Name: "",
    ClientID: clientId,
    Country: "",
    Region: "",
    PostCode: "",
    StreetLine2: "",
    StreetLine1: "",
  };
};