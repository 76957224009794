import { User } from '.'

export enum SalesManageBusinessPartners {
  NO_ACCESS = 0,
  READ_ONLY = 1,
  CREATE = 2,
  UPDATE = 3,
  DELETE = 4,
}

export const userCanReadBusinessPartners = (user: User) => {
  return user.permissions.salesManageBusinessPartners >= SalesManageBusinessPartners.READ_ONLY
}

export const userCanCreateBusinessPartners = (user: User) => {
  return user.permissions.salesManageBusinessPartners >= SalesManageBusinessPartners.CREATE
}

export const userCanUpdateBusinessPartners = (user: User) => {
  return user.permissions.salesManageBusinessPartners >= SalesManageBusinessPartners.UPDATE
}

export const userCanDeleteBusinessPartners = (user: User) => {
  return user.permissions.salesManageBusinessPartners >= SalesManageBusinessPartners.DELETE
}