import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, View } from "react-native";
import getTheme from "../../constants/Colors";
import { Client, ConstructionSite, Contract, Doc, Line, Resource } from "../../models";
import { DocMode, marginality } from "./utils";
import DocHeader from "./DocHeader";
import UpdateModal from "./UpdateModal";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import DocConstructionSite from "./DocConstructionSite";
import DocContract from "./DocContract";
import DocDetails from "./DocDetails";
import DocDetailsClients from "./DocDetails/DocDetailsClients";
import DocDetailsDocumentContacts from "./DocDetails/DocDetailsDocumentContacts";
import DocDetailsLevels from "./DocDetails/DocDetailsLevels";
import DocLines from "./DocLines";
import { NativeScrollEvent, NativeSyntheticEvent } from "react-native";
import DocLinesHeader from "./DocLines/DocLinesHeader";
import DocFooter from "./DocFooter";
import { createDoc, getDocById, isDocSameVersion, testCreateDoc, updateDoc, updateOfferStatus } from "../../api/DocAPI";
import { getNetworkStateAsync } from "expo-network";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { getConstructionSites } from "../../store/action/constructionSitesAction";
import { alert, alertConfirm, alertDocDraftCreated, alertUnderMiniumPrice, docOverwriteChangesAlert } from "../../hooks/Alert";
import { lineLevelLineTotal } from "./DocLines/utils";
import { LineSumType } from '../../models'
import { PriceLevel } from '../../store/reducers/settingsReducer';
import { SearchScreens } from '../search/SearchScreens';
import { useNavigation } from '@react-navigation/native';
import DocSegmentedView from './DocSegmentedView';
import DocTable from './DocTable';
import { logout } from '../../store/action/authAction';
import { sentryCapture } from '../../utils/sentryCapture';
import ConstructionSiteViews from '../constructionSites/ConstructionSiteDetails/ConstructionSiteViews';
import { sapDocToDocConvert } from '../../shared-utils/ConversionUtils'
import { setDocOffline } from '../../utils/doc-offline/util';

interface Props {
  initialMode: number;
  initialConstructionSite: ConstructionSite;
  initialContract: Contract;
  initialDoc: Doc;
  uuid?: string
}

const SegmentedView = {
  LIST: "list",
  TABLE: "table",
}
const SegmentedViews = [
  {title: "TABLE", key: SegmentedView.TABLE},
  {title: "LIST", key: SegmentedView.LIST},
]

type Version = {
  doc: Doc
  contract: Contract
  constructionSite: ConstructionSite
}
type Versions = {
  versions: Version[]
  versionIndex: number
}

const DocScreen = ({
  initialMode,
  initialDoc,
  initialContract,
  initialConstructionSite,
  uuid,
}: Props) => {

  const theme = getTheme();
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const catalogs = useSelector((state: Reducer) => state.general.catalogs);
  const salesDiscount = useSelector((state: Reducer) => state.user.permissions.salesDiscount)
  const salesUnderMinimumPrice = useSelector((state: Reducer) => state.user.permissions.salesUnderMinimumPrice)
  const resource = useSelector((state: Reducer) => state.general.resource)

  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(uuid != undefined && initialMode === DocMode.UPDATE)
  const [mode, setMode] = useState(initialMode);

  const [scrollOffset, setScrollOffset] = useState<number>(0);

  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);

  const [lineChangerCounter, setLineChangerCounter] = useState<number>(0);
  const linesChanged = () => setLineChangerCounter(v => v + 1);

  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);

  const [twinIDAutomationActive, setTwinIDAutomationActive] = useState<boolean>(true);

  const [segmentView, setSetgmentView] = useState<string>(DocMode.CREATE === mode ? SegmentedView.TABLE : SegmentedView.LIST);

  const [
    constructionSite,
    setConstructionSite
  ] = useState<ConstructionSite>(initialConstructionSite);
  const [contract, setContract] = useState<Contract>(initialContract);
  const [doc, setDoc] = useState<Doc>(initialDoc);

  const [savedLocally, setSavedLocally] = useState<boolean>(false)

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);

  const [versions, setVersions] = useState<Versions>({
    versions: [{
      doc: initialDoc,
      contract: initialContract,
      constructionSite: initialConstructionSite,
    }],
    versionIndex: 0,
  });

  const [updateAsDraft, setUpdateAsDraft] = useState<boolean>(false)

  const timer = useRef<any>(null)

  const saveAsOfflineDoc = () => {
    if (timer.current) {
      setSavedLocally(false)
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      if (constructionSite && contract && doc) {
        setDocOffline(
          constructionSite,
          contract,
          doc,
          uuid,
        ).then(() => setSavedLocally(true))
      }
    }, 5000)
  }
  useEffect(() => {
    saveAsOfflineDoc()
  }, [constructionSite, contract, doc])

  const addVersion = async (doc: Doc, contract: Contract, constructionSite: ConstructionSite) => {
    setVersions(v => {
      if (v.versions.length - 1 != v.versionIndex) {
        v.versions.splice(v.versionIndex + 1)
      }
      if (v.versions.length > 50) {
        v.versions.shift()
      }
      return {
        versions: [...v.versions, {doc, contract, constructionSite}],
        versionIndex: v.versions.length
      }
    })
  }

  const setConstructionSiteVersion = (constructionSite: ConstructionSite) => {
    setConstructionSite(constructionSite)
    addVersion(doc, contract, constructionSite)
  }
  const setContractVersion = (contract: Contract) => {
    setContract(contract)
    addVersion(doc, contract, constructionSite)
  }
  const setDocVersion = (doc: Doc) => {
    setDoc(doc)
    addVersion(doc, contract, constructionSite)
  }
  const setDocVersionFunctional = (doc: ((doc: Doc) => Doc) | Doc) => {
    if (typeof doc !== "function") {
      _setDoc(doc)
      return
    }
    setDoc(d => {
      const newDoc = doc(d)
      addVersion(newDoc, contract, constructionSite)
      return newDoc
    })
  }

  const _previous = () => {
    if (versions.versionIndex > 0) {
      const v = versions.versions[versions.versionIndex - 1]
      setDoc(v.doc)
      setContract(v.contract)
      setConstructionSite(v.constructionSite)
      setVersions(v => ({
        ...v,
        versionIndex: v.versionIndex - 1
      }))
      linesChanged()
    }
  }
  const _next = () => {
    if (versions.versionIndex < versions.versions.length - 1) {
      const v = versions.versions[versions.versionIndex + 1]
      setDoc(v.doc)
      setContract(v.contract)
      setConstructionSite(v.constructionSite)
      setVersions(v => ({
        ...v,
        versionIndex: v.versionIndex + 1
      }))
      linesChanged()
    }
  }

  const _setDoc = (doc: Doc) => {
    setChanged(true);
    setDocVersion(doc)
  }

  const _setDocChanged = (doc:Doc, changed: boolean) => {
    if (changed) {
      setChanged(true);
      setDocVersion(doc)
    } else {
      setDoc(doc)
    }
  }

  const _createUpdateHandler = async (draft: boolean = false) => {
    if (mode == DocMode.CREATE) {
      if (doc && constructionSite && contract) {
        const {
          errors,
          warnings
        } = testCreateDoc({constructionSite, doc, contract});
        setErrors(errors);
        setWarnings(warnings)

        if (errors.length > 0) {
          return;
        }
        if (warnings.length > 0) {
          const proceed = await alertConfirm("WARNING", "CREATE_DOC_WARNINGS");
          if (!proceed) {
            return;
          }
        }
        try {
          // TODO fix sync
          let state = await getNetworkStateAsync();
          if (!state.isConnected || !state.isInternetReachable) {
            await alert("NO_INTERNET_CONNECTION"); // TODO translate
          }
          setLoading(true);
          const m = await marginality(doc, salesDiscount, salesUnderMinimumPrice)
          if (!m.doc) {
            throw "ERROR marginality doc not loaded"
          }
          const res = await createDoc({
            constructionSite,
            doc: {
              ...m.doc,
              ToBeChecked: draft,
            },
            contract,
          }, catalogs)
          setConstructionSite(res.constructionSite);
          setContract(res.contract);
          setDoc(res.doc);
          if (m.doc.Total < m.minPrice) {
            if (resource) {
              alertUnderMiniumPrice(
                m,
                res.constructionSite,
                res.contract,
                res.doc,
                resource,
              )
            }
          }
          if (resource) {
            alertDocDraftCreated(
              res.constructionSite,
              res.contract,
              res.doc,
              resource,
              true,
            )
          }

          dispatch(getConstructionSites())
          setMode(DocMode.UPDATE);
          setChanged(false);
          if (res.doc.TypeID === 5) {
            // @ts-ignore
            navigation.navigate(`Sidebar`, {
              screen: 'SearchScreenStack',
              params: {
                screen: SearchScreens.CONSTRUCTION_SITES_DETAILS,
                params: {
                  ID: res.constructionSite.ID,
                  SelectedView: ConstructionSiteViews.Order,
                  ContractID: res.doc.ContractID,
                },
              },
            });
          }
        } catch (e:any) {
          if (e.error?.response && e.error.response.code == 401) {
            alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
            dispatch(logout());
          } else {
            sentryCapture(e);
            alert("ERROR_UPDATING", "ERROR_UPDATING_OFFER_STATUS");
          }
          if (e.constructionSite && e.contract && e.doc) {
            setConstructionSite(e.constructionSite);
            setContract(e.contract);
            setDoc(e.doc);
          } else {
            console.log(e);
          }
        } finally {
          setLoading(false);
        }
      }
    } else if (mode == DocMode.UPDATE) {
      setUpdateAsDraft(draft)
      setUpdateModalVisible(true);
    }
  }

  const _createUpdateDraftHandler = async () => {
    _createUpdateHandler(true)
  }

  const _updateHandler = async (newVersion: boolean) => {
    if (constructionSite && contract && doc) {
      const {
        errors,
        warnings
      } = testCreateDoc({constructionSite, doc, contract});
      setErrors(errors);
      setWarnings(warnings);
      if (errors.length > 0) {
        return;
      }
      if (warnings.length > 0) {
        const proceed = await alertConfirm("WARNING", "UPDATE_DOC_WARNINGS");
        if (!proceed) {
          return;
        }
      }

      try {
        // TODO fix sync
        setLoading(true)
        const c = await isDocSameVersion(doc)
        if (c.changed) {
          if (!await docOverwriteChangesAlert(c.doc)) {
            setLoading(false)
            return
          }
        }
        const m = await marginality(doc, salesDiscount, salesUnderMinimumPrice)
        if (!m.doc) {
          throw "ERROR marginality doc not loaded"
        }
        const res = await updateDoc({
          constructionSite,
          doc: {
            ...m.doc,
            ToBeChecked: updateAsDraft,
          },
          contract,
        }, catalogs, newVersion)

        setConstructionSite(res.constructionSite);
        setContract(res.contract);
        setDoc(res.doc);
        setUpdateAsDraft(false)

        if (m.doc.Total < m.minPrice) {
          if (resource) {
            alertUnderMiniumPrice(
              m,
              res.constructionSite,
              res.contract,
              res.doc,
              resource,
            )
          }
        }
        if (resource) {
          alertDocDraftCreated(
            res.constructionSite,
            res.contract,
            res.doc,
            resource,
            false,
          )
        }

        dispatch(getConstructionSites())
        setChanged(false);
      } catch (e: any) {
        if (e.error?.response && e.error.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else if (e.response?.status == 400 && e.response?.data?.errorType == "WRONG_VERSION" && e.response?.data?.newVersionId) {
          const serverDoc = sapDocToDocConvert(await getDocById(e.response.data.newVersionId, doc.Type), catalogs)
          if (await docOverwriteChangesAlert(serverDoc)) {
            const newDoc = {
              ...doc,
              ID: serverDoc.ID,
              Version: serverDoc.Version,
              UpdatedBy: serverDoc?.UpdatedBy,
              CreatedDate: serverDoc?.UpdatedDate,
            }

            return await updateDoc({
              constructionSite,
              doc: newDoc,
              contract
            }, catalogs, newVersion)
          }
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_UPDATING_OFFER_STATUS");
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const _loseHandler = async (client: Client | null, notes: string | null) => {
    if (doc) {
      try {
        setLoading(true);
        const c = await isDocSameVersion(doc);
        if (c.changed) {
          if (!await docOverwriteChangesAlert(c.doc)) {
            setLoading(false)
            return
          }
        }
        const updatedDoc = await updateOfferStatus(doc, false, String(client?.ID), notes, catalogs);
        setDoc(updatedDoc);
        setChanged(false);
      } catch (e: any) {
        if (e.error?.response && e.error.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_UPDATING_OFFER_STATUS");
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const _scrollHandler = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    setScrollOffset(e.nativeEvent.contentOffset.y);
  }

  const setLine = (line: Line, index: number) => {
    if (doc != undefined) {
      setDocVersionFunctional((old) => {
        if (!old || !old.Lines) return old;
        return {
          ...old,
          Lines: old.Lines.map((v:Line, i:number) => {
            if (index === i) {
              return line;
            } else if (line.TwinIDs != null && line.TwinIDs.includes(v.ID)) {
              if (twinIDAutomationActive) {
                for (let j = 0; j < line.LineLevel.length; j++) {
                  v.LineLevel[j].Quantity = line.LineLevel[j].Quantity;
                  v.LineLevel[j] = {
                    ...v.LineLevel[j],
                    Quantity: line.LineLevel[j].Quantity,
                    LineTotal: line.LineLevel[j].LineSumType == LineSumType.NORMAL ? lineLevelLineTotal(v.LineLevel[j]) : 0,
                    FixedPriceBlock: line.LineLevel[j].FixedPriceBlock,
                    LineSumType: line.LineLevel[j].LineSumType,
                  };
                }
              }
              v.Quantity = v.LineLevel.reduce((p, c) => {
                if (c.LineSumType == LineSumType.NORMAL) {
                  return p + c.Quantity;
                }
                return  p;
              }, 0);
              v.LineTotal = v.LineLevel.reduce((p, c) => {
                if (c.LineSumType == LineSumType.NORMAL && (c.FixedPriceBlock === "" || c.FixedPriceBlock === undefined)) {
                  return p + c.LineTotal;
                }
                return p;
              }, 0);

              return {
                ...v,
              };
            } else {
              return v;
            }
          }),
        };
      })
      setChanged(true);
      linesChanged()
    }
  };

  const _disableTwinIDAutomation = () => {
    setTwinIDAutomationActive(false);
  }

  const _sucess = () => {
    // @ts-ignore
    navigation.navigate(`Sidebar`, {
      screen: 'SearchScreenStack',
      params: {
        screen: SearchScreens.CONSTRUCTION_SITES_DETAILS,
        params: {
          ID: constructionSite.ID,
          SelectedView: ConstructionSiteViews.Order,
          ContractID: contract.ID,
        },
      },
    });
  }

  const _segmentedView = (key: string) => {
    if (key === SegmentedView.LIST) {
      let d = doc;
      d.Catalog.Groups.forEach(g => {
        g.Positions.forEach(p => {
          p.ItemsID.forEach(item => {
            const itemCatalog = d.Catalog.Items.find(c => c.ID === item);
            if (!itemCatalog) return;

            for (let i = 0; i < d.Lines.length; i++) {
              if (d.Lines[i].ID === item && d.Lines[i].LineLevel) {
                for (let j = 0; j < (p.Levels || []).length; j++) {
                  const l = (p.Levels || [])[j];
                  if (!p.ShowCompact) {
                    if (itemCatalog.RangeMin == null || l.Size < itemCatalog.RangeMin) {
                      return;
                    }
                    if (itemCatalog.RangeMax == null || l.Size > itemCatalog.RangeMax) {
                      return;
                    }
                  }
                  d.Lines[i].LineLevel[j].Quantity = l.Quantity;
                  const lineTotal = d.Lines[i].LineLevel[j].Quantity * d.Lines[i].LineLevel[j].Price;
                  if (d.Lines[i].LineLevel[j].LineSumType !== LineSumType.NORMAL) return;
                  if (d.Lines[i].LineLevel[j].FixedPriceBlock !== "" && d.Lines[i].LineLevel[j].FixedPriceBlock !== "-") return;

                  if (d.Lines[i].LineLevel[j].DiscountNumeric) {
                    d.Lines[i].LineLevel[j].LineTotal = lineTotal - d.Lines[i].LineLevel[j].Discount;
                  } else {
                    d.Lines[i].LineLevel[j].LineTotal = lineTotal * (1 - d.Lines[i].LineLevel[j].Discount);
                  }
                }
                d.Lines[i].Quantity = d.Lines[i].LineLevel.reduce((p, v) => {
                  if (v.LineSumType === LineSumType.NORMAL) {
                    return p + v.Quantity;
                  }
                  return p;
                }, 0);
                d.Lines[i].LineTotal = d.Lines[i].LineLevel.reduce((p, v) => {
                  if (v.LineSumType === LineSumType.NORMAL) {
                    if (v.FixedPriceBlock === "" || v.FixedPriceBlock === "-") {
                      if (v.DiscountNumeric) {
                        return p + v.Quantity * v.Price - v.Discount;
                      } else {
                        return p + v.Quantity * v.Price * (1 - v.Discount);
                      }
                    }
                  }
                  return p;
                }, 0);
                return;
              }
            }
          })
        })
      });
      setDocVersion(d)
    }
    setSetgmentView(key);
  }

  return (
    <View style={{backgroundColor: theme.background, flex: 1, paddingVertical: 5}}>
      {loading &&
      <View style={{
        zIndex: 100,
        height: "100%",
        width: "100%",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.7)"
      }}>
        <ActivityIndicator color={theme.mainColor}/>
      </View>
      }

      {(constructionSite && contract && doc) && <>
        {updateModalVisible && (
          <UpdateModal updateModalVisible={updateModalVisible}
                      doc={doc}
                      setUpdateModalVisible={setUpdateModalVisible}
                      docUpdate={_updateHandler}/>
        )}
          

        <DocHeader
          setDoc={_setDoc}
          setContract={setContractVersion}
          setConstructionSite={setConstructionSiteVersion}
          mode={mode}
          setLoading={setLoading}
          errors={errors}
          warnings={warnings}
          changed={changed}
          setChanged={setChanged}
          setMode={setMode}
          createUpdateHandler={_createUpdateHandler}
          createUpdateDraftHandler={_createUpdateDraftHandler}
          loseOfferHandler={_loseHandler}
          constructionSite={constructionSite}
          contract={contract}
          disableTwinIDAutomation={_disableTwinIDAutomation}
          twinIDAutomationActive={twinIDAutomationActive}
          doc={doc}
          previousVersion={_previous}
          nextVersion={_next}
          previousVersionActive={versions.versionIndex > 0}
          nextVersionActive={versions.versionIndex < versions.versions.length - 1}
          savedLocally={savedLocally}
          uuid={uuid}
        />

        {scrollOffset > 1000 && (!doc.Catalog.TableView || segmentView === SegmentedView.LIST) && <View style={{paddingVertical: 6}}><DocLinesHeader/></View>}

        <KeyboardAwareScrollView extraScrollHeight={100}
                                 enableOnAndroid={true}
                                 keyboardShouldPersistTaps='handled'
                                 onScroll={_scrollHandler}>
          <View style={{paddingHorizontal: 12, paddingBottom: 200}}>
            <DocConstructionSite constructionSite={constructionSite}
                                 setConstructionSite={setConstructionSiteVersion}
                                 setDoc={_setDoc}
                                 doc={doc}/>
            <DocContract
              contract={contract}
              setContract={setContractVersion}
              constructionSite={constructionSite}
            />
            <DocDetails
              doc={doc}
              setDoc={_setDoc}
              mode={mode}
              contacts={constructionSite.Contracts}
              contract={contract}
              setContract={setContract}
            />
            <DocDetailsClients doc={doc} setDoc={_setDoc}/>
            <DocDetailsDocumentContacts doc={doc} setDoc={_setDoc}/>
            <DocDetailsLevels doc={doc} setDoc={setDoc}/>

            {
              (doc.Catalog && doc.Catalog.TableView && mode === DocMode.CREATE) && (
                <DocSegmentedView views={SegmentedViews} viewKey={segmentView} setView={_segmentedView}/>
              )
            }
            {
              (doc.Catalog) && (
                doc.Catalog.TableView && segmentView === SegmentedView.TABLE ? (
                  <DocTable doc={doc} setDoc={setDocVersion} />
                ) : (
                  <DocLines doc={doc} setDoc={setDocVersionFunctional} setLine={setLine} linesChanged={linesChanged}/>
                )
              )
            }

          </View>
        </KeyboardAwareScrollView>
        {(doc.Lines && showPriceLevel >= PriceLevel.CLIENT) && <DocFooter doc={doc} lineChangeCounter={lineChangerCounter} setDoc={_setDoc} setDocChanged={_setDocChanged} />}
      </>
      }
    </View>
  );
};

export default DocScreen;