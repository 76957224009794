import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { AbsenceType } from "../../models"
import { Reducer } from "../../store/reducers"
import NativeModalPicker from "../NativeModalPicker"

interface Props {
  absenceType?: AbsenceType
  setAbsenceType: (absenceType: AbsenceType) => void
  disabled?: boolean
}

const AbsenceTypeDropdown = ({absenceType, setAbsenceType, disabled}: Props) => {

  const reportApprove = useSelector((state: Reducer) => state.user.permissions.reportApprove)
  const absenceTypes = useSelector((state: Reducer) => state.general.absenceTypes)

  const filteredAbsenceTypes = useMemo(
    () => absenceTypes.filter(t => reportApprove > 1 || !t.Negative),
    [absenceTypes]
  )

  const _change = (selectedAbsenceType: AbsenceType) => {
    if (absenceType?.Code == selectedAbsenceType.Code || disabled) {
      return
    }

    setAbsenceType(selectedAbsenceType)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.Code)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={absenceType}
      onValueChange={_change}
      options={filteredAbsenceTypes}
      showLabelAsOption={true}
      placeholder='SELECT_ABSENCE_TYPE'
    />
  )
}

export default AbsenceTypeDropdown