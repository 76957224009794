import { Contact } from "../../models";

export const newContact = (id: number, clientId: string):Contact => {
  return {
    ID: id,
    Title: "",
    Lastname: "",
    Firstname: "",
    Phone: "",
    Email: "",
    Mobile: "",
    ClientID: clientId,
    Position: "",
  };
};