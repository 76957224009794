import React from 'react'
import { Text, View } from 'react-native'
import DatePicker from '../DatePicker'
import { Absence } from '../../models'
import { t } from 'i18n-js'
import TimePicker from '../TimePicker'
import { getStringFromTime, getTimeFromString } from '../../utils/StringUtils'
import getTheme from '../../constants/Colors'

interface AbsenceDayProps {
  absence: Absence
  setAbsence: React.Dispatch<React.SetStateAction<Absence>>
  setChanged: (changed: boolean) => void
}

const AbsenceDay = ({
  absence,
  setAbsence,
  setChanged,
}: AbsenceDayProps) => {

  const theme = getTheme()

  const _date = (date?: Date) => {
    if (date) {
      setChanged(true)
      setAbsence(a => ({...a, Date: date}))
    }
  }

  const _start = (start: string) => {
    setChanged(true)
    const time = ((getTimeFromString(absence.End) || 0) - (getTimeFromString(start) || 0)) / 60
    setAbsence(a => ({...a, Start: start, Time: time}))
  }

  const _end = (end: string) => {
    setChanged(true)
    const time = ((getTimeFromString(end) || 0) - (getTimeFromString(absence.Start) || 0)) / 60
    setAbsence(a => ({...a, End: end, Time: time}))
  }

  return (
    <View>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      }}>
        <Text>{t('DATE')}</Text>
        <DatePicker
          value={absence.Date}
          onChange={_date}
        />
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      }}>
        <Text>{t('START_TIME')}</Text>
        <TimePicker
          value={absence.Start}
          onChange={_start}
        />
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      }}>
        <Text>{t('END_TIME')}</Text>
        <TimePicker
          value={absence.End}
          onChange={_end}
        />
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      }}>
        <Text>{t('TIME')}</Text>
        <Text>{absence.Type?.Negative && '- '}{getStringFromTime(absence.Time * 60)}</Text>
      </View>

      <Text style={{color: theme.red}}>
        {absence.Time < 0 && t('ERROR_INSERT_A_START_TIME_SMALLER_THAN_END_TIME')}
      </Text>
    </View>
  )
}

export default AbsenceDay