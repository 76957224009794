import React from 'react'
import { LineLevel, MeasureLevel } from '../../models';
import Formatter from '../../utils/formatters';
import { Text, View } from 'react-native';
import getTheme from '../../constants/Colors';

interface MeasureInvoiceLevelPreviewProps {
  measureLevel: MeasureLevel
  orderLevel: LineLevel
  showTotalQuantity: boolean
  index: number
}

const MeasureInvoiceLevelPreview = ({
  measureLevel,
  orderLevel,
  showTotalQuantity,
  index,
}: MeasureInvoiceLevelPreviewProps) => {

  const theme = getTheme()

  const f2 = Formatter.number2.format

  const background = index % 2 === 0 ? theme.background : 'white'

  const price = orderLevel.Price || 0
  const priceText = price != 0 ? f2(price) : ''

  let quantity = measureLevel.BillableQuantity || 0
  if (showTotalQuantity) {
    quantity += measureLevel.BilledQuantity || 0
  }
  const quantityText = quantity != 0 ? f2(quantity) : ''

  let discount = ''
  if (orderLevel.Discount != 0) {
    if (orderLevel.DiscountNumeric) {
      discount = `-${f2(orderLevel.Discount)}`
    } else {
      discount = `-${f2(orderLevel.Discount)}%`
    }
  }
  const discountText = discount != '' ? discount : ''

  const lineTotal = price * quantity
  const lineTotalText = lineTotal != 0 ? f2(lineTotal) : ''

  return (
    <View
      style={{ flexDirection: 'row', backgroundColor: background, paddingBottom: 5 }}
      key={`line-level-${orderLevel.ID}-${measureLevel.Level.ID}`}
    >
      <View style={{ width: 100 }} />
      <Text style={{ flex: 1 }}>
        {measureLevel.Level.Name}
      </Text>
      <View style={{ width: 100 }} />
      <Text style={{ width: 100, textAlign: 'right' }}>
        {priceText}
      </Text>
      <Text style={{ width: 100, textAlign: 'right' }}>
        {quantityText}
      </Text>
      <Text style={{ width: 100, textAlign: 'right' }}>
        {discount}
      </Text>
      <Text style={{ width: 100, textAlign: 'right' }}>
        {lineTotalText}
      </Text>
    </View>
  )
}

export default MeasureInvoiceLevelPreview