import {
  MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS,
  MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS,
  MEASURE_SHOW_BILLED_QUANTITY,
  MEASURE_SHOW_DONE_QUANTITY_PREVIOUS,
  MEASURE_SHOW_SOLD,
  SET_SEARCH_STRING,
  SET_SHOW_PRICE,
  SHOW_DOCS_OFFLINE,
  SHOW_OFFLINE_MEASURES,
  SHOW_PLANNING_WEEK_DAYS,
} from '../types'

export const setShowPriceLevel =
  (showPriceLevel: number) => async (dispatch: ({ }) => void, getState: () => { user: any }) => {
    const state = getState();

    if (showPriceLevel > state.user.permissions.priceLevel) {
      return;
    }

    dispatch({
      type: SET_SHOW_PRICE,
      payload: showPriceLevel,
    });
  };

export const toggleMeasureShowSold = () => async (dispatch: ({ }) => void) => {
  dispatch({
    type: MEASURE_SHOW_SOLD,
  })
}

export const toggleMeasureShowDoneQuantityPrevious = () => async (dispatch: ({ }) => void) => {
  dispatch({
    type: MEASURE_SHOW_DONE_QUANTITY_PREVIOUS,
  })
}

export const toggleMeasureShowApprovedQuantityPrevious = () => async (dispatch: ({ }) => void) => {
  dispatch({
    type: MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS,
  })
}

export const toggleMeasureShowBillableQuantityPrevious = () => async (dispatch: ({ }) => void) => {
  dispatch({
    type: MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS,
  })
}

export const toggleMeasureShowBilledQuantity = () => async (dispatch: ({ }) => void) => {
  dispatch({
    type: MEASURE_SHOW_BILLED_QUANTITY,
  })
}

export const toggleShowDocsOffline = () => (dispatch: ({ }) => void) => {
  dispatch({
    type: SHOW_DOCS_OFFLINE,
  })
}

export const toggleShowOfflineMeasures = () => async (dispatch: ({ }) => void) => {
  dispatch({
    type: SHOW_OFFLINE_MEASURES,
  })
}

export const search = (
  searchString: string,
) => (
  dispatch: ({}) => void,
) => dispatch({
  type: SET_SEARCH_STRING,
  payload: {
    searchString,
  },
})

export const setShowPlanningWeekDays = (
  days: 5 | 6 | 7,
) => (
  dispatch: ({}) => void,
) => dispatch({
  type: SHOW_PLANNING_WEEK_DAYS,
  payload: days,
})