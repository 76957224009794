import React, { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { DayReport, Doc, SapDoc } from "../../models";
import { getDayReport } from "../../api/ReportAPI";
import { ActivityIndicator, View } from "react-native";
import DayReportApproveView from "./dayReportApprove/DayReportApproveView";
import { prepareReportForApprove } from "../../utils/reports/report-utils";
import getTheme from "../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { getOrderByContractId } from "../../api/DocAPI";
import { sapDocToDocConvert } from '../../shared-utils/ConversionUtils'
import { alert } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { sentryCapture } from "../../utils/sentryCapture";

interface Props {
  route: {
    params?: {
      dayReportId?: number;
    }
  }
}

const DayReportApprove = (props: Props) => {

  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const theme = getTheme();

  const [dayReport, setDayReport] = useState<DayReport>();
  const [order, setOrder] = useState<Doc>();
  const [sapOrder, setSapOrder] = useState<SapDoc>();

  useEffect(() => {
    const dayReportId = props.route.params?.dayReportId;

    if (dayReportId == undefined || isNaN(dayReportId)) {
      // TODO alert & logs
      navigation.goBack();
      return;
    }

    getDayReport(dayReportId).then(d => {
      getOrderByContractId(d.ContractID).then(order => {
        const o = sapDocToDocConvert(order, catalogs)
        setOrder(o);
        setSapOrder(order);
        setDayReport(prepareReportForApprove(d, order))
      });
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_LOADING_DAY_REPORT");
        navigation.goBack();
      }
    });
  }, [props]);

  if (!dayReport || !order || !sapOrder) {
    return (
      <View style={{
        zIndex: 100,
        height: "100%",
        width: "100%",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.7)"
      }}>
        <ActivityIndicator color={theme.mainColor}/>
      </View>
    );
  }

  return (
    <DayReportApproveView initDayReport={dayReport} order={order} sapOrder={sapOrder} />
  );
};

export default DayReportApprove;