import { StyleProp, ViewStyle } from "react-native";
import { useEffect, useState } from "react";
const { createElement } = require("react-native-web");

interface Props {
  value: string;
  onChange: (time: string) => void;
  style: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const WebTimePicker = ({ value, onChange, style, disabled }: Props) => {
  const [v, setV] = useState(value);

  style = {
    ...(style as any),
    backgroundColor: "white",
    boxSizing: "border-box",
    border: "none",
    outline: "none",
    height: 25,
  };

  useEffect(() => {
    setV(value);
  }, [value]);

  const _change = (e:any) => {
    setV(e.currentTarget.value);
    onChange(e.currentTarget.value);
  }

  return createElement("input", {
    disabled: disabled || false,
    type: "time",
    value: v,
    onChange: _change,
    style,
  });
};

export default WebTimePicker;
