import React, { useState } from "react";
import { ConstructionSite, Contract } from "../../models";
import { Pressable, Switch, Text, TextInput, View } from "react-native";
import { t } from "i18n-js";
import getTheme from "../../constants/Colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface Props {
  contract: Contract;
  setContract: (contract: Contract) => void;
  constructionSite: ConstructionSite
}

const DocContract = ({contract, setContract, constructionSite}:Props) => {

  const theme = getTheme()

  const [contractNameOk, setContractNameOk] = useState<boolean>(true);
  const [showNotes, setShowNotes] = useState<boolean>(false)
  const _toggleNotes = () => setShowNotes(!showNotes)

  const _name = (name: string) => {
    setContract({
      ...contract,
      Name: name,
    })
    setContractNameOk(
      constructionSite?.Contracts?.find(c => c.Name === name && c.ID !== contract.ID) === undefined
    )
  };

  const _measure = (measure: boolean) => setContract({
    ...contract,
    Measure: measure,
  })

  const _publicProcurement = (publicProcurement: boolean) => setContract({
    ...contract,
    PublicProcurement: publicProcurement,
  })

  const _notes = (notes: string) => setContract({
    ...contract,
    Notes: notes,
  })

  return (
    <View style={{paddingTop: 15}}>
      <View style={{flexDirection: "row", alignItems: "center", paddingVertical: 2}}>
        <Text style={{paddingRight: 6}}>{t('APPALT')}:</Text>
        <View style={{flex: 1}}>
          <TextInput
            style={{
              backgroundColor: 'white',
              padding: 9,
              borderRadius: 6,
              borderColor: contractNameOk ? undefined : 'red',
              borderWidth: contractNameOk ? 0 : 1,
            }}
            value={contract.Name}
            onChangeText={_name}
          />
          {!contractNameOk && (
            <Text style={{color: theme.red}}>{t('NOT_VALID_CONTRACT_NAME')}</Text>
          )}
        </View>
        <Text style={{paddingHorizontal: 10}}>{t('MEASURE')}</Text>
        <Switch value={contract.Measure} onValueChange={_measure} />
        <Text style={{paddingHorizontal: 10}}>{t('PUBLIC_PROCUREMENT')}</Text>
        <Switch value={contract.PublicProcurement} onValueChange={_publicProcurement} />
        <Pressable style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 10}} onPress={_toggleNotes}>
          <Text style={{paddingRight: 6}}>{t('NOTES')}</Text>
          
          {constructionSite.Notes.length > 0 && (
            <MaterialCommunityIcons
              name='comment'
              size={16}
              color='black'
              style={{paddingHorizontal: 5}}
            />
          )}

          <MaterialCommunityIcons
            name={showNotes ? 'chevron-down' : 'chevron-up'}
            size={24}
            color='black'
          />
        </Pressable>
      </View>

      {showNotes && (
        <TextInput
          style={{
            backgroundColor: 'white',
            padding: 9,
            borderRadius: 6,
            marginTop: 5,
            borderColor: theme.border,
          }}
          placeholder={t('NOTES')}
          value={contract.Notes}
          onChangeText={_notes}
          multiline
          numberOfLines={3}
        />
      )}
    </View>
  );
};

export default DocContract;