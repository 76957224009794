import React from "react";
import { SendDocumentOrder } from "../../api/DocAPI";
import NativeModalPicker from "../NativeModalPicker";
import { t } from "i18n-js";
import { Picker } from "@react-native-picker/picker";

interface Props {
  sendDocument: SendDocumentOrder;
  setSendDocument: (sendDocument: SendDocumentOrder) => void;
}

const itemToLabel = (sendDocument: SendDocumentOrder): string => {
  switch (sendDocument) {
    case SendDocumentOrder.Order:
      return t('ORDER');
    case SendDocumentOrder.Beton:
      return t('BETON');
    case SendDocumentOrder.Financial:
      return t('FINANCIAL_REPORT');
    case SendDocumentOrder.FirstPage:
      return t('FIRST_PAGE');
  }
}

const DocumentOrderDropdown = ({sendDocument, setSendDocument}: Props) => {

  const _change = (_sendDocument: SendDocumentOrder) => {
    if (_sendDocument == sendDocument) {
      return;
    }

    setSendDocument(_sendDocument);
  }

  return (
    <NativeModalPicker
      itemToLabel={itemToLabel}
      itemToKey={item => item.toString()}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
        marginLeft: 10,
      }}
      selectedValue={sendDocument}
      onValueChange={_change}
      placeholder='SELECT_DOCUMENT_ORDER'
      options={[
        SendDocumentOrder.Order,
        SendDocumentOrder.Beton,
        SendDocumentOrder.Financial,
        SendDocumentOrder.FirstPage,
      ]}
    />
  );
};

export default DocumentOrderDropdown;