import * as AuthAPI from "../../api/AuthAPI";
import { alert } from "../../hooks/Alert";
import { WorkSpace } from "../../models";
import { sentryCapture } from "../../utils/sentryCapture";

import { LOGOUT, SET_FIRST_START, SET_WORK_SPACE } from "../types";
import axios from "axios";

export const setWorkSpace = (workSpace: WorkSpace) => async (dispatch: ({}) => void) => {
  axios.defaults.baseURL = workSpace.url;
  dispatch({
    type: SET_WORK_SPACE,
    payload: workSpace,
  });
};

export const logout = () => async (dispatch: ({}) => void) => {
  AuthAPI.logout().then().catch(error => {
    console.log(error);
    sentryCapture(error);
    alert("ERROR", "ERROR_LOGOUT");
  });
  dispatch({
    type: LOGOUT,
  });
};

export const setFirstStartAction = (firstStart: boolean) => async (dispatch: ({}) => void) => dispatch({
  type: SET_FIRST_START,
  payload: firstStart
});