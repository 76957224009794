import React, { useEffect } from "react";
import { Branch } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  branch?: Branch;
  setBranch: (branch: Branch) => void;
  disabled?: boolean;
}

const BranchDropdown = ({branch, setBranch, disabled = false}: Props) => {

  const branches = useSelector((state: Reducer) => state.general.branches);

  const _change = (selectedBranch: Branch) => {
    if (branch?.ID == selectedBranch.ID || disabled) {
      return;
    }
    setBranch(selectedBranch)
  }

  useEffect(() => {
    if (branch == null && branches.length == 1 && !disabled) {
      setBranch(branches[0])
    }
  }, [])

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={branch}
      onValueChange={_change}
      options={branches}
      placeholder='SELECT_BRANCH'
      showLabelAsOption={true}
    />
  );
};

export default BranchDropdown;