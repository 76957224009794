import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { getActivities } from "../../api/ReportAPI";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { DayReport, LineType } from '../../models'
import { activitiesToNewReport } from '../../shared-utils/reports/convert'
import DayReportView from "./DayReportView";
import { DayReportMode } from "./DayReportView/DayReportMode";
import { logout } from "../../store/action/authAction";
import { alert } from "../../hooks/Alert";
import { sentryCapture } from "../../utils/sentryCapture";

interface Props {
  route: {
    params?: {
      contractId?: number;
      startTime?: Date;
    }
  }
}

const DayReportCreate = (props:Props) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const reportActivitiesLinesFilter = useSelector(
    (state: Reducer) => state.user.reportActivitiesLinesFilter
  );
  const resource = useSelector((state:Reducer) => state.general.resource);

  const [dayReport, setDayReport] = useState<DayReport>();

  useEffect(() => {
    const contractId = props.route.params?.contractId;;

    if (contractId == undefined || isNaN(contractId)) {
      // TODO alert & logs
      navigation.goBack();
      return;
    }

    getActivities(contractId).then((data) => {
      data.activities = data.activities.filter(a => {
        return reportActivitiesLinesFilter.includes(a.Type as any);
      });

      data.activities = data.activities.filter(a => {
        if (a.Type != LineType.TITLE || !a.FatherID || a.FatherID == '') {
          return true
        }

        return data.activities
          .map(activity => activity.FatherID)
          .includes(a.Code)
      })


      if (!resource) {
        // TODO alert & logs
        navigation.goBack();
        return;
      }

      setDayReport(activitiesToNewReport(data, resource, props.route.params?.startTime));
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_LOADING_ACTIVITIES");
        navigation.goBack();
      }
    })
  }, [props])

  if (!dayReport) {
    return (
      <View/>
    );
  }

  return (
    <DayReportView mode={DayReportMode.CREATE}
                   initDayReport={dayReport}/>
  );
}

export default DayReportCreate;

