import axios from "axios";
import { Client } from "../models";
import { GooglePlaceDetail } from "react-native-google-places-autocomplete";

export const googlePlacesToClient = (details: GooglePlaceDetail | null): Client => {

  const route = details?.address_components.find((item:any) => item.types.includes('route'))?.long_name || ''
  const street_number = details?.address_components.find((item:any) => item.types.includes('street_number'))?.long_name || ''
  const postal_code = details?.address_components.find((item:any) => item.types.includes('postal_code'))?.long_name || ''
  const city = details?.address_components.find((item:any) => item.types.includes('locality'))?.long_name || ''
  const regionShort = details?.address_components.find((item:any) => item.types.includes('administrative_area_level_1'))?.short_name || ''
  const countryShort = details?.address_components.find((item:any) => item.types.includes('country'))?.short_name || ''
  return {
    ID: -1,
    // @ts-ignore
    Phone: details?.international_phone_number || '',
    Name: details?.name || '',
    VAT: '',
    Type: {
      ID: 0,
      Name: '',
    },
    Company: true,
    IndustryID: 1,
    Industry: {
      ID: 1,
      Name: '',
    },
    EmailBillAddress: '',
    FreeText: '',
    Email: '',
    Contacts: [],
    FiscalCode: '',
    Mobile: '',
    DefaultBillAddressID: '-1',
    DefaultShipAddressID: '-1',
    Addresses: [
      {
        ClientID: '-1',
        Name: details?.formatted_address || '',
        StreetLine0: route + ' ' + street_number,
        StreetLine1: '',
        StreetLine2: '',
        PostCode: postal_code,
        City: city,
        Region: regionShort,
        Country: countryShort,
        ID: '',
      },
    ],
  }
}