import React from "react";
import { Client } from "../../models";
import { Text, View } from "react-native";
import { t } from "i18n-js";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  addressId?: string;
  setAddressId: (addressId: string) => void;
  client: Client;
  label: string;
}

const ClientAddressDropdown = ({addressId, setAddressId, client, label}: Props) => {

  const _change = (item: string) => {
    if (addressId == item) {
      return;
    }
    const address = client.Addresses.find(v => v.ID == item)
    if (address) {
      setAddressId(address.ID)
    }
  }

  return (
    <View style={{alignItems: 'center', flex: 1, flexDirection: 'row'}}>
      <Text style={{paddingRight: 10}}>{t(label)}</Text>
      <NativeModalPicker
        itemToLabel={id => client.Addresses.find(v => v.ID == id)?.Name || ''}
        itemToKey={id => id}
        style={{
          paddingVertical: 10,
          paddingHorizontal: 25,
          borderRadius: 5,
          backgroundColor: 'white',
        }}
        selectedValue={addressId}
        onValueChange={_change}
        placeholder='SELECT_ADDRESS'
        options={client.Addresses.map(v => v.ID)}
        showLabelAsOption={true}
      />
    </View>
  );
};

export default ClientAddressDropdown;