import React, { useEffect, useMemo, useState } from "react";
import { DayReport, Report } from "../../models";
import { deleteDayReport, getDayReport, unapproveDayReport } from "../../api/ReportAPI";
import { alert, alertConfirm } from "../../hooks/Alert";
import { Text, View, TextInput } from "react-native";
import { t } from "i18n-js";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import { getEndTimeFromDayReport, getStartTimeFromDayReport } from "../../utils/reports/report-utils";
import { DayReportMode } from "../../screens/reports/DayReportView/DayReportMode";
import { ActivityIndicator, FlatList, Image, ScrollView, StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { getStringFromTime } from "../../utils/StringUtils";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import DayReportPreviewLine from "./DayReportPreviewLine";
import { logout } from "../../store/action/authAction";
import Modal from "react-native-modal";
import Button from "../Button";
import getTheme from "../../constants/Colors";
import { sentryCapture } from "../../utils/sentryCapture";

interface Props {
  dayReportId?: number;
  initDayReport?: DayReport;
  visible: boolean;
  close: () => void;
  pull?: () => void
}

const DayReportPreviewModal = ({dayReportId, visible, close, initDayReport, pull}:Props) => {

  const dispatch = useDispatch();
  const theme = getTheme();
  const [loading, setLoading] = useState<boolean>(false);

  const reportApprove = useSelector((state: Reducer) => state.user.permissions.reportApprove);

  const [dayReport, setDayReport] = useState<DayReport>();

  const lines = useMemo(() => {
    return dayReport?.Reports?.sort((a, b) => a.ActivityIDString.localeCompare(b.ActivityIDString)) || []
  }, [dayReport])

  const _close = () => {
    pull && pull()
    close()
  }

  useEffect(() => {
    if (initDayReport) {
      setDayReport(initDayReport);
    } else {
      if (dayReportId) {
        getDayReport(dayReportId).then(setDayReport).catch(e => {
          if (e.response && e.response.code == 401) {
            alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
            dispatch(logout());
          } else {
            sentryCapture(e);
            alert("ERROR", "ERROR_LOADING_DAY_REPORT");
          }
        });
      } else {
        alert("ERROR", "ERROR_LOADING_DAY_REPORT");
      }
    }
  }, []);


  if (!dayReport) {
    return (
      <Modal
        isVisible={visible}
        onBackdropPress={close}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
        }}
      >
        <ActivityIndicator />
      </Modal>
    );
  }

  const _render = ({item, index}: {item: Report, index: number}) => {

    return (
      <DayReportPreviewLine report={item}
                            levels={dayReport.Levels}
                            index={index}/>
    );
  };

  const breakTime = getStringFromTime(dayReport.BreakTime);
  const startTime = getStartTimeFromDayReport(dayReport, DayReportMode.APPROVE);

  const _unapprove = async () => {
    if (!await alertConfirm("CONTINUE", "ARE_YOU_SURE_YOU_WANT_UNAPPROVE_THE_DAY_REPORT")) {
      return;
    }
    try {
      setLoading(true);
      await unapproveDayReport(dayReport);
      setDayReport(await getDayReport(dayReport.ID));
    } catch (e: any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      }else {
        sentryCapture(e);
        alert("ERROR", "ERROR_UNAPPROVE_DAY_REPORT");
      }
    } finally {
      setLoading(false);
    }
  }

  const _delete = async () => {
    if (!await alertConfirm("CONTINUE", "ARE_YOU_SURE_YOU_WANT_DELETE_THE_DAY_REPORT")) {
      return;
    }
    try {
      setLoading(true);
      await deleteDayReport(dayReport);
      close();
    } catch (e: any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      }else {
        sentryCapture(e);
        alert("ERROR", "ERROR_UNAPPROVE_DAY_REPORT");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={_close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View>
        {
          loading && (
            <View style={{
              width: '100%',
              height: '100%',
              zIndex: 1,
              backgroundColor: '#000',
              opacity: 0.7
            }}>
              <ActivityIndicator />
            </View>
          )
        }
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{flex: 2}}>
            <Text style={{fontSize: 20}}>
              {t("REPORT")}: {dayReport.ID}
            </Text>
            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
              <Text style={{fontSize: 20, marginRight: 4}}>
                {dayReport.ConstructionSite.Code} - {dayReport.ConstructionSite.Name}
              </Text>
              <Text style={{fontSize: 16}}>
                {dayReport.Contract.Name}
              </Text>
            </View>
          </View>

          <View style={{flex: 1}}>
            <Text style={{fontSize: 16}}>{dayReport.Resource.FirstName} {dayReport.Resource.LastName}</Text>
          </View>

          {(dayReport.Billed == false
              && dayReport.ApprovedBy != null
              && reportApprove > 0
              && !dayReport.Contract.Measure) && (
            <Button
              style={{backgroundColor: theme.blue}}
              onPress={_unapprove}
              titleT="UNAPPROVE"
            />
          )}
          {
            (dayReport.ApprovedBy == null && reportApprove > 0) && (
              <Button
                style={{backgroundColor: theme.blue}}
                onPress={_delete}
                titleT="DELETE"
              />
            )
          }

          <MaterialIcons name="close" onPress={_close} size={34} />
        </View>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 5,
          }}
        >
          <DatePicker value={dayReport?.DateTime || new Date()}
                      disabled={true}
                      onChange={() => {}}/>

          <Text>{t("BREAK")}:</Text>
          <TimePicker style={{minWidth: 70}}
                      value={breakTime}
                      disabled={true}
                      onChange={() => {}}/>
          <Text>{t("START_TIME")}:</Text>
          <TimePicker style={{minWidth: 70}}
                      value={startTime || '00:00'}
                      disabled={true}
                      onChange={() => {}}/>
          <Text>{t("END_TIME")}:</Text>
          <TimePicker style={{minWidth: 70}}
                      value={getEndTimeFromDayReport(dayReport, DayReportMode.APPROVE) || '00:00'}
                      disabled={true}
                      onChange={() => {}}/>
        </View>
        <View style={{flexDirection: 'row', paddingVertical: 3, alignItems: 'center'}}>
          <Text style={{width: 100}}>{t("CODE")}</Text>
          <Text style={{flex: 4}}>{t("NAME")}</Text>
          <Text style={{flex: 1}}>{t("UNIT_OF_MEASURE")} / {t("QUANTITY")}</Text>
          <Text style={{flex: 2, textAlign: 'center'}}>{t("APPROVED")}</Text>
          {reportApprove > 0 && <Text style={{flex: 2, textAlign: 'center'}}>{t("BILLABLE")}</Text> }
        </View>
      </View>

      <ScrollView style={{flex: 1}}>

        <FlatList
          data={lines}
          renderItem={_render}
          key={`dayreport-lines-${dayReport.ID}`}
          // @ts-ignore
          listKey={`dayreport-lines-${dayReport.ID}`}
          keyExtractor={(item, index) => `dayreport-lines-${dayReport.ID}-${index}-${item.ID}`}
        />

        <View style={{flexDirection: 'row', marginTop: 3}}>
          <Text style={styles.label}>{t("NOTES")}:</Text>
          <TextInput
            style={styles.inputs}
            multiline={true}
            value={dayReport?.Notes || ""}
            editable={false}
            onChangeText={() => {}}
          />
        </View>
        <View style={{flexDirection: 'row', marginTop: 3}}>
          <Text style={styles.label}>{t("APPROVAL_NOTES")}:</Text>
          <TextInput
            style={styles.inputs}
            multiline={true}
            value={dayReport?.ApprovalNotes || ""}
            editable={false}
            onChangeText={() => {}}
          />
        </View>

        <View style={{flexDirection: 'row', marginTop: 3}}>
          <View style={{flex: 1}}>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.label}>{t("SIGNED_BY")}:</Text>
              <TextInput
                style={[styles.inputs, {flex: 1}]}
                value={dayReport?.SignedBy}
                editable={false}
                onChangeText={() => {}}
              />
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.label}>{t("DATE")}:</Text>
              <View style={{flex: 1, alignItems: 'center'}}>
                <DatePicker value={dayReport?.SignedTime || new Date()}
                            onChange={() => {}}
                            disabled={true}
                            style={{width: 100}}/>
              </View>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.label}>{t("PLACE")}:</Text>
              <TextInput
                style={[styles.inputs, {flex: 1}]}
                value={dayReport?.SignedPlace}
                editable={false}
                onChangeText={() => {}}
              />
            </View>
          </View>
          <View style={{flexDirection: 'row', flex: 1}}>
            <Text style={{marginHorizontal: 6}}>{t("SIGNATURE")}:</Text>

            {dayReport?.Signature ? (
              <View style={{flex: 1, height: 110, position: 'relative'}}>
                <Image source={{uri: dayReport?.Signature}}
                        style={styles.signature}/>
              </View>
            ) : (
              <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text>{t("SIGNATURE_NOT_AVAILABLE")}</Text>
              </View>
            )}
          </View>
        </View>

      </ScrollView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  label: {
    width: 120,
    fontSize: 16,
  },
  inputs: {
    backgroundColor: "white",
    flex: 1,
  },
  signatureIconClose: {
    top: 0,
    right: 0,
    position: "absolute",
    zIndex: 1,
  },
  signature: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
});

export default DayReportPreviewModal;