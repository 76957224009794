import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, Pressable, Text, View } from 'react-native'
import Modal from 'react-native-modal'
import { t } from 'i18n-js'
import { ConstructionSite } from '../../models'
import { FlatList, TextInput } from 'react-native-gesture-handler'
import getTheme from '../../constants/Colors'
import { Reducer } from '../../store/reducers'
import { useDispatch, useSelector } from 'react-redux'
import SegmentedView from '../SegmentedView'
import { getAssignedAndOpenConstructionSitesByResource, getOpenAndAssignedConstructionSites, getOpenAndNotAssignedConstructionSites } from '../../api/PlannningAPI'
import { alert } from '../../hooks/Alert'
import { sentryCapture } from '../../utils/sentryCapture'
import { getConstructionSites } from '../../store/action/constructionSitesAction'
import Status from '../Status'

interface ChooseConstructionSiteModalProps {
  visible: boolean
  close: () => void
  resourceID: number
  onSelect: (constructionSite: ConstructionSite) => void
  createAbsence: () => void
}

const Spearator = () => {
  const theme = getTheme();

  return (
    <View
      style={{
        borderBottomColor: theme.border,
        borderBottomWidth: 1,
      }}
    />
  )
}

const ChooseConstructionSiteModal = ({
  visible,
  close,
  resourceID,
  onSelect,
  createAbsence,
}: ChooseConstructionSiteModalProps) => {

  const theme = getTheme()
  const dispatch = useDispatch()

  const [search, setSearch] = useState<string>("")

  const constructionSites = useSelector((state: Reducer) => state.constructionSite.constructionSites)
  const constructionSitesLoading = useSelector(
    (state: Reducer) => state.sync.constructionSitesLoading
  )

  const [loadingOpenAndAssignedResource, setLoadingOpenAndAssignedResource] = useState<boolean>(false)
  const [loadingOpenAndAssigned, setLoadingOpenAndAssigned] = useState<boolean>(false)
  const [loadingOpenNotAssigned, setLoadingOpenNotAssigned] = useState<boolean>(false)

  const [assignedAndOpenResource, setAssignedAndOpenResource] = useState<ConstructionSite[]>([])
  const [openAndAssigned, setOpenAndAssigned] = useState<ConstructionSite[]>([])
  const [openNotAssigned, setOpenNotAssigned] = useState<ConstructionSite[]>([])

  const pull = (pullConstructionSites: boolean | undefined) => {
    setLoadingOpenAndAssignedResource(true)
    setLoadingOpenNotAssigned(true)
    getAssignedAndOpenConstructionSitesByResource(resourceID)
      .then(setAssignedAndOpenResource)
      .catch((e) => {
        alert('ERROR', 'DOWNLOAD_OPEN_AND_ASSIGNED_CONSTRUCTION_SITES_FAILED')
        sentryCapture(e)
        console.error(e)
      })
      .finally(() => setLoadingOpenAndAssignedResource(false))
    getOpenAndNotAssignedConstructionSites()
      .then(setOpenNotAssigned)
      .catch((e) => {
        alert('ERROR', 'DOWNLOAD_OPEN_NOT _ASSIGNED_CONSTRUCTION_SITES_FAILED')
        sentryCapture(e)
        console.error(e)
      })
      .finally(() => setLoadingOpenNotAssigned(false))
    getOpenAndAssignedConstructionSites()
      .then(setOpenAndAssigned)
      .catch((e) => {
        alert('ERROR', 'DOWNLOAD_OPEN_AND_ASSIGNED_CONSTRUCTION_SITES_FAILED')
        sentryCapture(e)
        console.error(e)
      })
      .finally(() => setLoadingOpenAndAssigned(false))
    if (pullConstructionSites) {
      dispatch(getConstructionSites())
    }
  }

  useEffect(() => {
    pull(false)
  }, [])

  const {
    constructionSitesFiltered,
    openAndAssignedFiltered,
    assignedAndOpenResourceFiltered,
    openNotAssignedFiltered,
  } = useMemo(() => {
    const s = search.trim().toLowerCase()
    return {
      constructionSitesFiltered: constructionSites.filter((c) => {
        return (
          c.IDString.toLowerCase().includes(s) ||
          c.Code.toLowerCase().includes(s) ||
          c.Name.toLowerCase().includes(s) ||
          c.Address?.toLowerCase().includes(s)
        )
      }),
      openAndAssignedFiltered: openAndAssigned.filter((c) => {
        return (
          c.IDString.toLowerCase().includes(s) ||
          c.Code.toLowerCase().includes(s) ||
          c.Name.toLowerCase().includes(s) ||
          c.Address?.toLowerCase().includes(s)
        )
      }),
      assignedAndOpenResourceFiltered: assignedAndOpenResource.filter((c) => {
        return (
          c.IDString.toLowerCase().includes(s) ||
          c.Code.toLowerCase().includes(s) ||
          c.Name.toLowerCase().includes(s) ||
          c.Address?.toLowerCase().includes(s)
        )
      }),
      openNotAssignedFiltered: openNotAssigned.filter((c) => {
        return (
          c.IDString.toLowerCase().includes(s) ||
          c.Code.toLowerCase().includes(s) ||
          c.Name.toLowerCase().includes(s) ||
          c.Address?.toLowerCase().includes(s)
        )
      }),
    }
  }, [constructionSites, openAndAssigned, assignedAndOpenResource, openNotAssigned, search])

  const _render = ({item, index}: {item: ConstructionSite, index: number}) => {
    const _press = () => {
      onSelect(item)
      close()
    }
    return (
      <Pressable
        key={`${item.ID}-${index}`}
        onPress={_press}
        style={{
          paddingHorizontal: 2,
          paddingVertical: 6,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <View>
          <Text>
            {item.Code} - {item.Name}
          </Text>
          <Text style={{fontSize: 12, paddingTop: 2}}>
            {item.Address}
          </Text>
        </View>
        <Status status={item.Status} size='m' />
      </Pressable>
    )
  }

  const views = (index: number) => {
    let data: ConstructionSite[] = [];
    let loading = false
    switch (index) {
      case 0:
        data = openAndAssignedFiltered
        loading = loadingOpenAndAssigned
        break
      case 1:
        data = assignedAndOpenResourceFiltered
        loading = loadingOpenAndAssignedResource
        break
      case 2:
        data = openNotAssignedFiltered
        loading = loadingOpenNotAssigned
        break
      case 3:
        data = constructionSitesFiltered
        loading = constructionSitesLoading
        break
    }

    return (
      <FlatList
        data={data}
        renderItem={_render}
        style={{
          flex: 1,
          backgroundColor: 'white',
          borderRadius: 10,
        }}
        ItemSeparatorComponent={Spearator}
        ListEmptyComponent={() => loading ? <ActivityIndicator /> : <></>}
        key={`search-client`}
        // @ts-ignore
        listKey={`search-client`}
        keyExtractor={(item, index) => `search-client-${index}-${item.ID}`}
      />
    )
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: "row"}}>
        <Text style={{fontSize: 25, flex: 1}}>
          {t("CHOOSE_CONSTRUCTION_SITE")}
        </Text>
        <Pressable
          style={{
            paddingHorizontal: 10,
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={createAbsence}
        >
          <MaterialCommunityIcons
            name='plus'
            color='black'
            size={18}
          />
          <Text style={{paddingLeft: 5}}>{t('CREATE_ABSENCE')}</Text>
        </Pressable>
        <MaterialCommunityIcons name="close" size={24} onPress={close} />
      </View>

      <TextInput
        style={{
          backgroundColor: 'white',
          padding: 5,
          borderColor: theme.border,
          borderWidth: 1,
          borderRadius: 5,
          marginVertical: 10,
        }}
        placeholder={t("SEARCH")}
        onChangeText={setSearch}
      />

      <SegmentedView
        titlesT={['OPEN_AND_ASSIGNED', 'OPEN_AND_ASSIGNED_TO_WORKER', 'OPEN_AND_NOT_ASSIGNED', 'ALL']}
        views={views}
      />
    </Modal>
  )
}

export default ChooseConstructionSiteModal