import React, { useEffect, useState } from 'react'
import { Measure } from '../../models'
import MeasureView from './MeasureView'
import { useNavigation } from '@react-navigation/native'
import { getMeasureByStorageKey } from '../../utils/measure/util'
import { sentryCapture } from '../../utils/sentryCapture'
import { alert } from '../../hooks/Alert'
import { ActivityIndicator, View } from 'react-native'

interface Props {
  route: {
    params?: {
      uuid?: string
    }
  }
}

const MeasureCreateView = ({ route }: Props) => {

  const navigation = useNavigation()

  const [initMeasure, setInitMeasure] = useState<Measure>()

  useEffect(() => {
    if (!route.params?.uuid) {
      navigation.goBack()
      return
    }
    getMeasureByStorageKey(route.params.uuid)
      .then(setInitMeasure)
      .catch(e => {
        console.log(e)
        sentryCapture(e)
        alert('ERROR', 'ERROR_LOADING_MEASURE')
        navigation.goBack()
      })
  }, [])

  if (!initMeasure) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <MeasureView initMeasure={initMeasure} />
  )
}

export default MeasureCreateView