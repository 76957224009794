import { Client } from "../../../models";
import React from "react";
import { FlatList, Text, View } from "react-native";

interface SearchItemProps {
  data: Client;
  setClient: (client: Client) => void;
}

const SearchItem = ({data, setClient}: SearchItemProps) => {

  return (<View style={{
    paddingVertical: 10,
    borderTopWidth: 1,
    borderTopColor: "black",
  }}><Text onPress={() => setClient(data)}>{data.Name}</Text></View>);
}

interface Props {
  filtered: Client[];
  clientHandler: (client: Client) => void;
}

const LoseOfferModalClientsList = ({filtered, clientHandler}: Props) => {

  const _render = ({item}: {item: Client}) => (
    <SearchItem data={item} setClient={clientHandler}/>
  );

  return (
    <FlatList
      data={filtered}
      renderItem={_render}
      key={`lose-offer-clients-list-${filtered.length}`}
      // @ts-ignore
      listKey={`lose-offer-clients-list-${filtered.length}`}
      keyExtractor={(item, index) => `lose-offer-clients-list-${index}-${item.ID}`}
      style={{height: 300, maxHeight: 300, borderWidth: 1, width: "100%"}}
    />
  );
};

export default LoseOfferModalClientsList;