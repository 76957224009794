import React from 'react'
import { DayReport, ExternalWorkInvoice, Report } from '../../models'
import { Text, TextInput, View } from 'react-native'
import ExternalWorkInvoiceData from './ExternalWorkInvoiceData'
import { FlatList } from 'react-native-gesture-handler'
import { t } from 'i18n-js'
import Button from '../Button'
import getTheme from '../../constants/Colors'
import ExternalWorkInvoiceReportCreate from './ExternalWorkInvoiceReportCreate'
import { createDayReport } from '../../api/ReportAPI'

interface ExternalWorkInvoiceDayReportCreateProps {
  dayReport: DayReport
  setDayReport: React.Dispatch<React.SetStateAction<DayReport>>
  closeExternalWorkInvoiceModal: () => void
  createExternalWorkInvoiceReport: () => void
  loading: boolean
}

const ExternalWorkInvoiceDayReportCreate = ({
  dayReport,
  setDayReport,
  closeExternalWorkInvoiceModal,
  createExternalWorkInvoiceReport,
  loading,
}: ExternalWorkInvoiceDayReportCreateProps) => {

  const theme = getTheme()

  const _report = ({item, index}: {item: Report, index: number}) => {

    const setReport = (report: Report) => setDayReport(d => ({
      ...d,
      Reports: d.Reports?.map((r, i) => i == index ? report : r),
    }))

    return (
      <ExternalWorkInvoiceReportCreate
        key={`report-${index}-${item.ID}`}
        report={item}
        index={index}
        setReport={setReport}
        levels={dayReport.Levels || []}
      />
    )
  }

  const _notes = (notes: string) => {
    if (loading) {
      return
    }
    setDayReport({
      ...dayReport!,
      Notes: notes,
    })
  }

  return (
    <View style={{paddingTop: 15, flex: loading ? undefined : 1}}>
      {!loading && (
        <>
          <View style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
            paddingBottom: 10,
          }}>
            <Text style={{width: 100}}>
              {t('CODE')}
            </Text>
            <Text style={{flex: 1}}>
              {t('DESCRIPTION')}
            </Text>
            <Text style={{width: 120, textAlign: 'center'}}>
              {t('UNIT_OF_MEASURE')}
            </Text>
            <Text style={{width: 70, textAlign: 'right', paddingRight: 10}}>
              {t('DONE')}
            </Text>
            <Text style={{width: 70, textAlign: 'right', paddingRight: 10}}>
              {t('SOLD')}
            </Text>
            <View style={{width: 120}} />
          </View>
          <FlatList
            data={dayReport.Reports}
            renderItem={_report}
          />
        </>
      )}

      <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: 15,
      }}>
        <View style={{flex: 1}}>
          <Text style={{fontWeight: 'bold'}}>
            {t('DAY_REPORT_NOTES')}:
          </Text>

          <TextInput
            placeholder={t('NOTES')}
            value={dayReport.Notes}
            onChangeText={_notes}
            multiline={true}
            style={{
              marginTop: 10,
              borderRadius: 5,
              borderWidth: 1,
              borderColor: theme.border,
              padding: 7,
              marginBottom: 4,
              marginRight: 6,
            }}
          />
        </View>

        <Button
          onPress={closeExternalWorkInvoiceModal}
          style={{backgroundColor: theme.blue}}
          titleT='CANCEL'
          disabled={loading}
        />

        <Button
          onPress={createExternalWorkInvoiceReport}
          style={{backgroundColor: theme.mainColor}}
          titleT='CREATE'
          disabled={loading}
        />
      </View>
    </View>
  )
}

export default ExternalWorkInvoiceDayReportCreate
