import React from "react";
import { Doc } from "../../../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import { Text, View } from "react-native";
import { t } from "i18n-js";
import Formatter from "../../../../utils/formatters";

interface Props {
  doc: Doc;
}

const DocumentDetails = ({doc}: Props) => {

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);

  const date = new Date(doc.UpdatedDate);

  const perc = doc.Gain / doc.Total * 10000;

  const currencyFormatter = Formatter.currency(doc.Currency.ID);

  return (
    <View 
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 2,
      }}
    >
      <Text style={{flex: 1, paddingRight: 5}}>
        {`${t('VERSION')}: ${doc.Version}   ${t('DOC_UPDATED_BY')}: ${doc.UpdatedBy?.FirstName} ${doc.UpdatedBy?.LastName}   -   ${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})}`}
      </Text>

      <Text style={{color: doc.Gain >= 0 ? "green" : "red"}}>
        {showPriceLevel > 1 ? `${Formatter.number2.format(perc)}% (${currencyFormatter.format(doc.Gain)})` : ""}
      </Text>

      <Text style={{marginLeft: 3}}>
        {showPriceLevel > 0 ? `${currencyFormatter.format(doc.Total)}` : ""}
      </Text>
    </View>
  );
};

export default DocumentDetails;