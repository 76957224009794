import React, { useMemo, useState } from 'react'
import { Level, Line, LineLevel, LineSumType } from '../../../../../models'
import HtmlText from '../../../../../components/HtmlText'
import { FlatList, Text, View } from 'react-native'
import { PriceLevel } from '../../../../../store/reducers/settingsReducer'
import OrderLinePositionLevel from './OrderLinePositionLevel'
import OrderValue from './OrderValue'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import OrderLineReportsModal from '../../../../../components/OrderLineReportsModal'

interface Props {
  line: Line
  largeView: boolean
  showAsMonetaryValue: boolean
  showPriceLevel: number
  levels: Level[]
}

const OrderLinePosition = ({line, largeView, showAsMonetaryValue, showPriceLevel, levels}: Props) => {

  const [showDetails, setShowDetails] = useState<boolean>(false)
  const _toggleShowDetails = () => setShowDetails(s => !s)

  const lineDescription = line.InternalName.length > 0 ? line.InternalName : line.Name

  const internal = showPriceLevel >= PriceLevel.INTERNAL
  const client = showPriceLevel >= PriceLevel.CLIENT

  const showAsMonetaryValuePrice = showAsMonetaryValue && showPriceLevel >= PriceLevel.CLIENT

  const sold = useMemo( () => line.LineLevel.reduce((sold, level) => {
    if (level.LineSumType != LineSumType.NORMAL) {
      return sold
    }
    return sold + (level.SoldQuantity || 0) * (showAsMonetaryValuePrice ? level.Price : 1)
  }, 0), [line, showAsMonetaryValuePrice])

  const done = useMemo( () => line.LineLevel.reduce((sold, level) => {
    if (level.LineSumType != LineSumType.NORMAL) {
      return sold
    }
    return sold + (level.DoneQuantity || 0) * (showAsMonetaryValuePrice ? level.Price : 1)
  }, 0), [line, showAsMonetaryValuePrice])

  const approved = useMemo( () => line.LineLevel.reduce((sold, level) => {
    if (level.LineSumType != LineSumType.NORMAL) {
      return sold
    }
    return sold + (level.DoneApprovedQuantity || 0) * (showAsMonetaryValuePrice ? level.Price : 1)
  }, 0), [line, showAsMonetaryValuePrice])

  const todo = sold - done > 0 ? sold - done : 0

  const billed = useMemo( () => line.LineLevel.reduce((sold, level) => {
    if (level.LineSumType != LineSumType.NORMAL) {
      return sold
    }
    return sold + (level.InvoicedQuantity || 0) * (showAsMonetaryValuePrice ? level.Price : 1)
  }, 0), [line, showAsMonetaryValuePrice])

  const _render = ({item, index}: {item: LineLevel, index: number}) => {
    const level = levels[index]
    return (
      <OrderLinePositionLevel
        level={level}
        lineLevel={item}
        key={`level-${index}-${line.ID}-${item.ID}`}
        showPriceLevel={showPriceLevel}
        showAsMonetaryValue={showAsMonetaryValue}
        largeView={largeView}
      />
    )
  }

  return (
    <View style={{flexDirection: 'row'}}>
      {showDetails && (
        <OrderLineReportsModal
          onClose={_toggleShowDetails}
          levels={levels}
          line={line}
          sold={sold}
          done={done}
          approved={approved}
          todo={todo}
          billed={billed}
        />
      )}
      <View style={{width: 90}}>
        <Text>{line.Code}</Text>
        {showPriceLevel >= PriceLevel.INTERNAL && (
          <MaterialCommunityIcons
            name='arrow-expand'
            color='black'
            size={20}
            style={{marginTop: 2}}
            onPress={_toggleShowDetails}
          />
        )}
      </View>
      <View style={{flex: 1}}>
        <View style={{flexDirection: 'row'}}>

          <View style={{flex: 2.5}}>
            <HtmlText html={lineDescription}/>
          </View>
          
          {(internal || client) && <Text style={{flex: 1}}/>}
          
          <Text style={{
            flex: 1,
            textAlign: 'center',
          }}>
            {line.UnitOfMeasure}
          </Text>
          
          <OrderValue value={sold} bold />

          <OrderValue value={done} bold />

          <OrderValue value={approved} show={internal && largeView} bold />

          <OrderValue value={todo} bold />

          <OrderValue value={billed} show={internal && client} bold />
        </View>
        <FlatList
          renderItem={_render}
          data={line.LineLevel}
        />
      </View>
    </View>
  )
}

export default OrderLinePosition