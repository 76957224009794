import React, { useMemo } from 'react'
import { MeasureLine, MeasureLevel, LineType } from '../../models'
import NumberInput from '../document/PostitionComponent/NumberInput'
import { isPosition } from '../../shared-utils/items'
import { FlatList, Text, View } from 'react-native'
import getTheme from '../../constants/Colors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Formatter from '../../utils/formatters'
import HtmlText from '../../components/HtmlText'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'

interface Props {
  index: number
  item: MeasureLine
  setApprovableQuantityMeasure: (quantity: number, i: number, index: number) => void
  setBillableQuantityMeasure: (quantity: number, i: number, index: number) => void
  readOnly: boolean
  setMeasureDetailsModal: (item: MeasureLine) => void
}

const MeasureItem = ({
  index,
  item,
  setApprovableQuantityMeasure,
  setBillableQuantityMeasure,
  readOnly,
  setMeasureDetailsModal,
}: Props) => {

  const theme = getTheme()

  const {
    measureShowSold,
    measureShowDoneQuantityPrevious,
    measureShowApprovedQuantityPrevious,
    measureShowBillableQuantityPrevious,
    measureShowBilledQuantity,
  } = useSelector((state: Reducer) => state.settings)

  const _approvableQuantityIndex = (q: number, i: number) => setApprovableQuantityMeasure(q, i, index)
  const _billableQuantityIndex = (q: number, i: number) => setBillableQuantityMeasure(q, i, index)
  const _approvableQuantity = (q: number) => _approvableQuantityIndex(q, 0)
  const _billableQuantity = (q: number) => _billableQuantityIndex(q, 0)

  const f2 = Formatter.number2.format

  const unitOfMeasure = item.UnitOfMeasure || ''

  const _render = ({ item, index }: { item: MeasureLevel, index: number }) => {

    const _approvableQuantity = (q: number) => _approvableQuantityIndex(q, index)
    const _billableQuantity = (q: number) => _billableQuantityIndex(q, index)
    const sold = (item.SoldQuantity || 0) != 0 ? f2(item.SoldQuantity) : '-'
    const doneQuantityPrevious = (item.DoneQuantityPrevious || 0) != 0 ? f2(item.DoneQuantityPrevious) : '-'
    const approvedPrevious = (item.ApprovedQuantityPrevious || 0) != 0 ? f2(item.ApprovedQuantityPrevious) : '-'
    const billablePrevious = (item.BillableQuantityPrevious || 0) != 0 ? f2(item.BillableQuantityPrevious) : '-'
    const billed = (item.BilledQuantity || 0) != 0 ? f2(item.BilledQuantity) : '-'
    const quantity = (item.Quantity || 0) != 0 ? f2(item.Quantity) : '-'

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={{ flex: 1 }}>{item.Level.Name}</Text>
        {isP && (
          <Text style={{ width: 100, textAlign: 'center' }}>{unitOfMeasure}</Text>
        )}
        {(isP && measureShowSold) && (
          <Text style={{ width: 85, textAlign: 'right' }}>{sold}</Text>
        )}
        {(isP && measureShowDoneQuantityPrevious) && (
          <Text style={{ width: 85, textAlign: 'right' }}>{doneQuantityPrevious}</Text>
        )}
        {(isP && measureShowApprovedQuantityPrevious) && (
          <Text style={{ width: 85, textAlign: 'right' }}>{approvedPrevious}</Text>
        )}
        {(isP && measureShowBillableQuantityPrevious) && (
          <Text style={{ width: 85, textAlign: 'right' }}>{billablePrevious}</Text>
        )}
        {(isP && measureShowBilledQuantity) && (
          <Text style={{ width: 85, textAlign: 'right' }}>{billed}</Text>
        )}
        {isP && (
          <View style={{ width: 85, paddingHorizontal: 5 }}>
            <Text style={{
              paddingRight: 5,
              borderRadius: 5,
              textAlign: 'right',
              backgroundColor: quantity != '-' ? 'rgba(234, 88, 12, 0.4)' : undefined,
            }}>
              {quantity}
            </Text>
          </View>
        )}
        {isP && (
          <NumberInput
            style={{ width: 100 }}
            defaultValue={item.ApprovableQuantity}
            onChange={_approvableQuantity}
            disabled={readOnly}
          />
        )}
        {isP && (
          <NumberInput
            style={{ width: 100 }}
            defaultValue={item.BillableQuantity}
            onChange={_billableQuantity}
            disabled={readOnly}
          />
        )}
      </View>
    )
  }

  const _details = () => setMeasureDetailsModal(item)

  const isP = isPosition(item)

  const exceedTimeOrMaterial = useMemo(() => {
    let notes = item.MeasureReports.reduce((p, v) => {
      if (p) return p
      return v.Report.Notes !== ''
    }, false)
    if (notes) {
      return true
    }
    if (item.Type == LineType.POSITION_CAR) {
      return false
    }

    let approvedQuantity = item.MeasureReports.reduce((p, v) => {
      return p + (v.Report.Levels?.reduce((p, v) => {
        return p + (v.ApprovedQuantity || 0)
      }, 0) || 0)
    }, 0)

    let approvedDuration = item.MeasureReports.reduce((p, v) => {
      return p + (v.Report.ApprovedDuration || 0)
    }, 0)

    let unitTime = item.MeasureReports?.[0]?.Report?.UnitTime || 0

    if (approvedDuration > approvedQuantity * unitTime) {
      return true
    }

    let materials: { code: string, unit: number, quantity: number }[] = []
    for (let i = 0; i < item.MeasureReports.length; i++) {
      const material = item.MeasureReports[i].Report.Materials || []
      for (let j = 0; j < material.length; j++) {
        const mi = materials.findIndex(m => m.code === material[j].MaterialID)
        if (mi < 0) {
          materials.push({
            code: material[j].MaterialID || '',
            quantity: material[j].ApprovedQuantity || 0,
            unit: material[j].UnitQuantity,
          })
        } else {
          materials[mi].quantity += material[j].ApprovedQuantity || 0
        }
      }
    }

    return materials.reduce((p, v) => {
      if (p) return p
      return v.quantity > v.unit * approvedQuantity
    }, false)
  }, [item])

  const hasNotes = useMemo(() => {
    return item.MeasureReports.reduce((p, v) => {
      if (p) return p
      return v.Report.Notes !== ''
    }, false)
  }, [item])

  const sold = (item.SoldQuantity || 0) != 0 ? f2(item.SoldQuantity) : '-'
  const doneQuantityPrevious = (item.DoneQuantityPrevious || 0) != 0 ? f2(item.DoneQuantityPrevious) : '-'
  const approvedPrevious = (item.ApprovedQuantityPrevious || 0) != 0 ? f2(item.ApprovedQuantityPrevious) : '-'
  const billablePrevious = (item.BillableQuantityPrevious || 0) != 0 ? f2(item.BillableQuantityPrevious) : '-'
  const billed = (item.BilledQuantity || 0) != 0 ? f2(item.BilledQuantity) : '-'
  const quantity = (item.Quantity || 0) != 0 ? f2(item.Quantity) : '-'
  const approvable = (item.ApprovableQuantity || 0) != 0 ? f2(item.ApprovableQuantity) : '-'
  const billable = (item.BillableQuantity || 0) != 0 ? f2(item.BillableQuantity) : '-'

  const background = item.BackgroundColor || (index % 2 == 0 ? 'white' : theme.background)

  return (
    <View style={{
      flexDirection: 'row',
      borderTopColor: theme.border,
      backgroundColor: background,
      paddingVertical: 5,
      paddingHorizontal: 2,
    }}>
      <View>
        <Text style={{ width: 100 }}>{item.ActivityIDString}</Text>
        {isP && (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <MaterialCommunityIcons
              name='arrow-expand'
              color='black'
              size={20}
              style={{ marginRight: 5 }}
              onPress={_details}
            />
            {exceedTimeOrMaterial && (
              <MaterialCommunityIcons
                name='alert'
                color='red'
                size={20}
                style={{ marginLeft: 5 }}
              />
            )}
            {hasNotes && (
              <MaterialCommunityIcons
                name='message-text'
                color='black'
                size={20}
                style={{ marginLeft: 5 }}
              />
            )}
          </View>
        )}
      </View>
      <View
        key={index}
        style={{
          flex: 1,
          paddingTop: 1,
          backgroundColor: item.BackgroundColor,
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <HtmlText html={item.Name} />
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {(isP) && (
              <Text style={{ width: 100, textAlign: 'center' }}>{unitOfMeasure}</Text>
            )}
            {(isP && measureShowSold) && (
              <Text style={{ width: 85, textAlign: 'right' }}>{sold}</Text>
            )}
            {(isP && measureShowDoneQuantityPrevious) && (
              <Text style={{ width: 85, textAlign: 'right' }}>{doneQuantityPrevious}</Text>
            )}
            {(isP && measureShowApprovedQuantityPrevious) && (
              <Text style={{ width: 85, textAlign: 'right' }}>{approvedPrevious}</Text>
            )}
            {(isP && measureShowBillableQuantityPrevious) && (
              <Text style={{ width: 85, textAlign: 'right' }}>{billablePrevious}</Text>
            )}
            {(isP && measureShowBilledQuantity) && (
              <Text style={{ width: 85, textAlign: 'right' }}>{billed}</Text>
            )}
            {isP && (
              <View style={{ width: 85, paddingHorizontal: 5 }}>
                <Text style={{
                  paddingRight: 5,
                  borderRadius: 5,
                  textAlign: 'right',
                  backgroundColor: quantity != '-' ? 'rgba(234, 88, 12, 0.4)' : undefined,
                }}>
                  {quantity}
                </Text>
              </View>
            )}

            {(isP && (item.Levels?.length || 0) > 1) && (
              <Text style={{ width: 105, textAlign: 'right', paddingRight: 15 }}>
                {approvable}
              </Text>
            )}
            {(isP && (item.Levels?.length || 0) == 1) && (
              <NumberInput
                style={{ width: 100 }}
                defaultValue={item.ApprovableQuantity}
                onChange={_approvableQuantity}
                disabled={readOnly}
              />
            )}

            {(isP && (item.Levels?.length || 0) > 1) && (
              <Text style={{ width: 105, textAlign: 'right', paddingRight: 15 }}>
                {billable}
              </Text>
            )}
            {(isP && (item.Levels?.length || 0) == 1) && (
              <NumberInput
                style={{ width: 100 }}
                defaultValue={item.BillableQuantity}
                onChange={_billableQuantity}
                disabled={readOnly}
              />
            )}
          </View>
        </View>
        {(isP && (item.Levels?.length || 0) > 1) && (
          <FlatList
            renderItem={_render}
            data={item.Levels}
            key={`measure-item-levels`}
            // @ts-ignore
            listKey={`measure-item-levels`}
            keyExtractor={(_, index) => `measure-item-levels-${index}`}
          />
        )}
      </View>
    </View>
  )
}

export default MeasureItem