import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Level, LineLevel } from "../../../models";
import { Text, View } from "react-native";
import { DocLineColumnWidth } from "./DocLinesHeader";
import NumberInput from "../../document/PostitionComponent/NumberInput";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { t } from "i18n-js";
import { LineSumType } from '../../../models'
import { lineLevelShow, PrintTwinLevel } from "./utils";
import DocLinePositionLevelBlockSelect from "./DocLinePositionLevelBlockSelect";
import NativeModalPicker from "../../../components/NativeModalPicker";
import Formatter from "../../../utils/formatters";

interface Props {
  lineLevel: LineLevel;
  level: Level;
  setLineLevel: (lineLevel: LineLevel) => void;
  letters: string[];
  addPriceFixBlock: () => void;
  priceNoPrintByLevel?: PrintTwinLevel
  twinPrint: boolean
}

const areEqual = (p: Readonly<Props>, n:Readonly<Props>) => {
  return JSON.stringify(p) === JSON.stringify(n) && p.lineLevel === n.lineLevel;
}

const DocLinePositionLevel = React.memo(({
                                           lineLevel,
                                           level,
                                           setLineLevel,
                                           letters,
                                           addPriceFixBlock,
                                           priceNoPrintByLevel,
                                           twinPrint,
                                         }: Props) => {

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);

  const [_lineLevel, _setLineLevel] = useState<LineLevel>(lineLevel);

  useEffect(() => {
    _setLineLevel(lineLevel);
  }, [lineLevel]);

  const _quantity = useCallback((q: number) => {
    let lineTotal;
    if (_lineLevel.DiscountNumeric) {
      lineTotal = q * _lineLevel.Price - _lineLevel.Discount;
    } else {
      lineTotal = q * _lineLevel.Price * (1 - _lineLevel.Discount / 100);
    }
    _setLineLevel({
      ..._lineLevel,
      Quantity: q,
      LineTotal: lineTotal,
    });
    setLineLevel({
      ...lineLevel,
      Quantity: q,
      LineTotal: lineTotal,
    });
  }, [lineLevel, _lineLevel]);

  const _sumType = useCallback((s: string) => {
    let quantity = 0;
    if (s === LineSumType.PRICE_LIST) {
      quantity = 1
    } else if (_lineLevel.LineSumType === LineSumType.PRICE_LIST) {
      quantity = 0;
    } else {
      quantity = _lineLevel.Quantity;
    }
    _setLineLevel({
      ..._lineLevel,
      LineSumType: s,
      Quantity: quantity,
      FixedPriceBlock: s === LineSumType.NORMAL ? _lineLevel.FixedPriceBlock : "",
      LineTotal: s === LineSumType.PRICE_LIST ? 0 : _lineLevel.LineTotal,
    });
    setLineLevel({
      ...lineLevel,
      LineSumType: s,
      Quantity: quantity,
      FixedPriceBlock: s === LineSumType.NORMAL ? lineLevel.FixedPriceBlock : "",
      LineTotal: s === LineSumType.PRICE_LIST ? 0 : lineLevel.LineTotal,
    });
  }, [lineLevel, _lineLevel]);

  const _block = useCallback((s: string) => {
    _setLineLevel({
      ..._lineLevel,
      FixedPriceBlock: s,
    })
    setLineLevel({
      ...lineLevel,
      FixedPriceBlock: s,
    });
  }, [lineLevel, _lineLevel]);

  const {
    price,
    lineTotal,
    lineTotalColor
  } = useMemo(() => lineLevelShow(_lineLevel, showPriceLevel, priceNoPrintByLevel), [_lineLevel, showPriceLevel]);

  const _itemToLabel = (sumType: string) => {
    switch (sumType) {
      case LineSumType.PRICE_LIST:
        return t('PRICE_LIST');
      case LineSumType.TO_CONCORD:
        return t('TO_CONCORD');
      default:
        return '-';
    }
  }

  const f2 = Formatter.number2.format;

  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <Text style={{flex: 1}}>{level?.Name}</Text>
      <View style={{width: DocLineColumnWidth.QUANTITY}}>
        {
          _lineLevel.LineSumType !== LineSumType.PRICE_LIST ? (
            <NumberInput min={0}
                         defaultValue={_lineLevel.Quantity}
                         onChange={_quantity}/>
          ) : <Text style={{width: '100%', textAlign: 'center'}}>{t("PRICE_LIST")}</Text>
        }
      </View>
      <View style={{width: DocLineColumnWidth.UOM}} />
      <Text style={{width: DocLineColumnWidth.PRICE, textAlign: 'center'}}>{twinPrint ? (price) : ''}</Text>
      <Text style={{width: DocLineColumnWidth.TOTAL, color: lineTotalColor, textAlign: 'center'}}>{twinPrint ? lineTotal : ''}</Text>

      {(!twinPrint && !priceNoPrintByLevel) ? (
        <View style={{width: DocLineColumnWidth.SUM_TYPE, marginHorizontal: 2}}/>
      ) : (
        <NativeModalPicker
          itemToLabel={_itemToLabel}
          itemToKey={item => item.toString()}
          outStyle={{width: DocLineColumnWidth.SUM_TYPE}}
          style={{width: DocLineColumnWidth.SUM_TYPE}}
          placeholder={'CHOOSE_A_SUM_TYPE'}
          accessibilityLabel={'CHOOSE_A_SUM_TYPE'}
          selectedValue={_lineLevel.LineSumType || LineSumType.NORMAL}
          onValueChange={_sumType}
          options={[
            LineSumType.NORMAL,
            LineSumType.PRICE_LIST,
            LineSumType.TO_CONCORD,
          ]}
        />
      )}

      {
        (_lineLevel.LineSumType === LineSumType.PRICE_LIST || (!twinPrint && !priceNoPrintByLevel)) ?
          <View style={{width: DocLineColumnWidth.FLAT_RATE}}/> :
          <DocLinePositionLevelBlockSelect setBlock={_block}
                                           block={_lineLevel.FixedPriceBlock}
                                           letters={letters}
                                           addPriceFixBlock={addPriceFixBlock}/>
      }
    </View>
  );
}, areEqual);

export default DocLinePositionLevel;