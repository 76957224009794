import { LineType, MeasureLine } from "../models"


export const isPositionType = (type: string): boolean => {
  switch (type) {
    case LineType.POSITION:
    case LineType.POSITION_CAR:
    case LineType.POSITION_ENGINEERING:
    case LineType.POSITION_EXTERNAL_WORK:
    case LineType.POSITION_SELL_WARRANTY:
    case LineType.POSITION_WORK_WARRANTY:
      return true
    default:
      return false
  }
}

export const isPosition = (m: MeasureLine): boolean => {
  return isPositionType(m.Type)
}