import React, { useState } from 'react'
import { Resource } from '../../models'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
import getTheme from '../../constants/Colors'
import { Image, Text, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface ResourceItemProps {
  resource: Resource
  onSelect: (resource: Resource) => void
  onSelectDriver: (driver: Resource) => void
  defaultSelected?: boolean
  chooseDrivers?: boolean
  defaultSelectedDriver?: boolean
}

const ResourceItem = ({
  resource,
  onSelect,
  onSelectDriver,
  defaultSelected = false,
  chooseDrivers = false,
  defaultSelectedDriver = false,
}: ResourceItemProps) => {

  const theme = getTheme()

  const [selected, setSelected] = useState<boolean>(defaultSelected)
  const [selectedDriver, setSelectedDriver] = useState<boolean>(defaultSelectedDriver)

  const _toggleDriver = () => {
    setSelectedDriver(!selectedDriver && selected)
    onSelectDriver(resource)
  }

  const _select = () => {
    onSelect(resource)
    setSelected(!selected)
  }

  if (resource.ID < 0) {
    return (
      <View style={{flex: 1, padding: 10}} />
    )
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 10,
        flex: 1,
        marginTop: 20,
      }}
      key={`resource-${resource.ID}`}
    >
      <BouncyCheckbox
        fillColor={theme.mainColor}
        iconStyle={{borderColor: theme.border, borderRadius: 10}}
        innerIconStyle={{borderColor: theme.border, borderRadius: 10}}
        size={20}
        isChecked={defaultSelected}
        onPress={_select}
      />
      <Text style={{fontSize: 20}}>{resource.FirstName} {resource.LastName}</Text>
      {resource.ProfileImage.length > 0 ? (
        <Image
          style={{
            marginHorizontal: 10,
          }}
          source={{ uri: resource.ProfileImage }}
        />
      ) : (
        <View style={{ width: 30 }} />
      )}
      {chooseDrivers && (
        <MaterialCommunityIcons
          name='steering'
          size={22}
          color={selected && selectedDriver ? theme.mainColor : theme.border}
          onPress={_toggleDriver}
        />
      )}
    </View>
  )
}

export default ResourceItem