import React, { useEffect, useState } from "react";
import { Doc, Marginality } from "../../models";
import { marginality } from "../../screens/doc/utils";
import { alert } from "../../hooks/Alert";
import { ActivityIndicator, Text, View } from "react-native";
import { t } from 'i18n-js';
import MarginalityOverview from "../MarginalityModal/MarginalityOverview";
import { sentryCapture } from "../../utils/sentryCapture";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";

interface Props {
  doc: Doc;
}

const DocPreviewMenuMarginality = ({doc}: Props) => {

  const salesDiscount = useSelector((state: Reducer) => state.user.permissions.salesDiscount)
  const salesUnderMinimumPrice = useSelector((state: Reducer) => state.user.permissions.salesUnderMinimumPrice)
  
  const [loading, setLoading] = useState<boolean>(false);
  const [_marginality, _setMarginality] = useState<Marginality>();

  useEffect(() => {
    setLoading(true);
    marginality(doc, salesDiscount, salesUnderMinimumPrice)
      .then(_setMarginality)
      .catch(e => {
        sentryCapture(e);
        alert('ERROR', 'ERROR_LOADING_MARGINALITY');
      })
      .finally(() => setLoading(false));
  }, [doc]);

  if (!_marginality) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? <ActivityIndicator /> : <Text>{t('ERROR_LOADING_MARGINALITY')}</Text>}
      </View>
    );
  }

  return <MarginalityOverview marginality={_marginality} />
}

export default DocPreviewMenuMarginality;