import React from "react";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import { Language } from "../../../../models";
import NativeModalPicker from "../../../../components/NativeModalPicker";

interface Props {
  language?: Language;
  setLanguage: (language: Language) => void;
}

const DocThreeDotsLanguages = ({language, setLanguage}: Props) => {

  const languages = useSelector((state: Reducer) => state.general.languages);

  const _handler = (selectedLanguage: Language) => {
    if (language?.ID == selectedLanguage.ID) {
      return;
    }
    setLanguage(selectedLanguage)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      selectedValue={language}
      style={{minWidth: "100%"}}
      onValueChange={_handler}
      placeholder='SELECT_LANGUAGE'
      options={languages}
      showLabelAsOption={true}
    />
  );
}

export default DocThreeDotsLanguages;