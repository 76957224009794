import React, { useEffect, useState } from "react";
import { Doc, Item, Line, LineType } from '../../../models'
import { FlatList } from "react-native";
import DocLine from "./DocLine";
import DocLinesHeader from "./DocLinesHeader";
import { linesAddItems, linesAddLines, linesRemoveLine } from "../utils";
import { t } from "i18n-js";
import { Picker } from "@react-native-picker/picker";

interface Props {
  doc: Doc;
  setDoc: React.Dispatch<React.SetStateAction<Doc>>;
  setLine: (line: Line, index: number) => void;
  linesChanged: () => void
}

const DocLines = ({doc, setDoc, setLine, linesChanged}: Props) => {

  const [letters, setLetters] = useState<string[]>([]);

  const addPriceFixBlock = () => {
    const l: string[] = []
    for (let i = 0; i < letters.length; i++) {
      const letter = String.fromCharCode(65 + i);
      l.push(letter)
    }

    l.push(t('NEW_BLOCK'))
    setLetters(l);
  }

  useEffect(() => {
    const letter = String.fromCharCode(65 + letters.length);
    setLetters([letter])
  }, []);

  useEffect(() => {
    const letters: string[] = []
    for (let i = 0; i < doc.Lines.length; i++) {
      switch(doc.Lines[i].LineType) {
        case LineType.POSITION:
        case LineType.POSITION_CAR:
        case LineType.POSITION_ENGINEERING:
        case LineType.POSITION_EXTERNAL_WORK:
        case LineType.POSITION_SELL_WARRANTY:
        case LineType.POSITION_WORK_WARRANTY:
          for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
            if (doc.Lines[i].LineLevel[j].FixedPriceBlock != "" &&
              doc.Lines[i].LineLevel[j].FixedPriceBlock != null &&
              !letters.includes(doc.Lines[i].LineLevel[j].FixedPriceBlock)) {
              letters.push(doc.Lines[i].LineLevel[j].FixedPriceBlock);
            }
          }
          break;
      }
    }
    const l: string[] = [];
    for (let i = 0; i < letters.length; i++) {
      const letter = String.fromCharCode(65 + i);
      l.push(letter)
    }

    l.push(t("NEW_BLOCK"))
    setLetters(l);
  }, []);

  const setTwinsPrices = (lineIndex: number, levelIndex: number, price: number, discount: number, discountNumeric: boolean) => {
    setDoc(old => {
      const line = old.Lines[lineIndex]
      const lines = old.Lines.filter(l => line.TwinIDs?.includes(l.ID) && !l.PrintTwin)
      const linesPrice = line.LineLevel[levelIndex].Price + lines.reduce((p, v) => p + v.LineLevel[levelIndex].Price, 0)
      let calculatedLinesPrice = 0
      lines.map(l => {
        calculatedLinesPrice += price / linesPrice * l.LineLevel[levelIndex].Price
      })
      linesChanged()
      return {
        ...old,
        Lines: old.Lines.map((l, i) => {
          if (lineIndex == i) {
            return {
              ...l,
              LineLevel: l.LineLevel.map((ll, j) => {
                if (j === levelIndex) {
                  const p = price - calculatedLinesPrice
                  const lineTotal = discountNumeric ?
                        (p * ll.Quantity - discount) :
                        (p * ll.Quantity * (1 - discount / 100))
                  return {
                    ...ll,
                    Price: p,
                    Discount: discount,
                    DiscountNumeric: discountNumeric,
                    LineTotal: lineTotal,
                  }
                }
                return ll
              })
            }
          }
          if (line.TwinIDs?.includes(l.ID) && !l.PrintTwin) {
            return {
              ...l,
              LineLevel: l.LineLevel.map((ll, j) => {
                if (j === levelIndex) {
                  const p = price / linesPrice * l.LineLevel[levelIndex].Price
                  const lineTotal = discountNumeric ?
                        (p * ll.Quantity - discount) :
                        (p * ll.Quantity * (1 - discount / 100))
                  return {
                    ...ll,
                    Price: p,
                    Discount: discount,
                    DiscountNumeric: discountNumeric,
                    LineTotal: lineTotal,
                  }
                }
                return ll
              })
            }
          }
          return l
        })
      }
    })
  }

  const _render = ({item, index}: {item: Line, index: number}) => {

    const _addLine = (line: Line) => {
      setDoc(old => {
        if (!old) return old;
        const lines = linesAddLines(old.Lines, [line], index + 1)
        linesChanged()
        return {
          ...old,
          Lines: lines,
        };
      });
    };
    const _removeLine = () => {
      setDoc(old => {
        if (!old) return old;
        const lines = linesRemoveLine(old.Lines, index)
        linesChanged()
        return {
          ...old,
          Lines: lines,
        };
      });
    }
    const _addItem = (item: Item | Item[], keepOrder: boolean) => {
      let items: Item[];
      if (Array.isArray(item)) {
        items = item;
      } else {
        items = [item];
      }
      setDoc(old => {
        if (!old) return old;
        const lines = linesAddItems(old.Lines, items, index + 1, old.Levels)
        linesChanged()
        if (!keepOrder) {
          lines.sort((a: Line, b: Line) => a.Code.localeCompare(b.Code));
        }
        return {
          ...old,
          Lines: lines,
        };
      });
    }

    let twinLinesNoPrint: Line[] | undefined = undefined
    if (item.TwinIDs && item.TwinIDs.length > 0 && item.PrintTwin) {
      twinLinesNoPrint = doc.Lines.filter(l => item.TwinIDs?.includes(l.ID) && !l.PrintTwin)
    }

    const _setLine = (line: Line) => setLine(line, index);

    const fixedPriceLine = item.LineType === LineType.FIXED_PRICE || item.LineType == LineType.FIXED_PRICE_ENGINEERING_WARRANTY;
    return (
      <DocLine
        line={item}
        doc={fixedPriceLine ? doc : undefined}
        setLine={_setLine}
        deleteLine={_removeLine}
        addLine={_addLine}
        index={index}
        addItem={_addItem}
        levels={doc.Levels}
        key={item.ID + item.Code + Number(index)}
        addPriceFixBlock={addPriceFixBlock}
        letters={letters}
        setTwinsPrices={setTwinsPrices}
        twinLinesNoPrint={twinLinesNoPrint}
      />
    );
  }

  return (
    <FlatList
      data={doc.Lines}
      renderItem={_render}
      ListHeaderComponent={DocLinesHeader}
      maxToRenderPerBatch={20}
      removeClippedSubviews={true}
      key={`lines`}
      // @ts-ignore
      listKey={`lines`}
      keyExtractor={(item, index) => `lines-${doc.ID}-${index}-${item.ID}`}
    />
  );
};

export default DocLines;