import React, { useEffect, useState } from 'react'
import { ActivityIndicator, FlatList, Pressable, Text, View } from 'react-native'
import { OfferToCheck } from '../../../models'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { getOffersToCheck } from '../../../api/DocAPI'
import { useDispatch } from 'react-redux'
import { logout } from '../../../store/action/authAction'
import { alert } from '../../../hooks/Alert'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { t } from 'i18n-js'
import OffersToCheckItem from './OffersToCheckItem'

const OffersToCheckScreen = () => {

  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [offersToCheck, setOffersToCheck] = useState<OfferToCheck[]>([])

  useEffect(() => {
    setLoading(true)
    getOffersToCheck()
      .then(setOffersToCheck)
      .catch(e => {
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
          dispatch(logout())
        } else {
          alert("ERROR", "ERROR_LOADING_OFFERS_TO_CHECK")
        }
      })
      .finally(() => setLoading(false))
  }, [isFocused])

  const _back = () => {
    navigation.goBack()
  }

  const _render = ({item, index}: {item: OfferToCheck, index: number}) => {
    return (
      <OffersToCheckItem
        offerToCheck={item}
        index={index}
        key={`offerToCheck${index}-${item.ID}`}
      />
    )
  }

  return (
    <View style={{paddingTop: 5, paddingHorizontal: 5, flex: 1}}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <MaterialCommunityIcons
          size={34}
          name='close'
          onPress={_back}
          color='black'
        />
        <Text style={{fontSize: 20, marginLeft: 10}}>{t('OFFERS_TO_CHECK')}</Text>
      </View>
      <View style={{flex: 1}}>
        {loading ? (
          <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator />
          </View>
        ) : (
          <>
            <View style={{flexDirection: 'row', alignItems: 'flex-end', padding: 5, paddingTop: 15}}>
              <Text style={{flex: 1}}>
                {t('DOC_TYPE')}
              </Text>
              <Text style={{width: 100}}>
                {t('OFFER')}
              </Text>
              <Text style={{flex: 1}}>
                {t('CLIENT')}
              </Text>
              <Text style={{width: 100}}>
                {t('CONSTRUCTION_SITE')}
              </Text>
              <Text style={{flex: 2}}>
                {t('CONSTRUCTION_SITE_NAME')}
              </Text>
              <Text style={{flex: 2}}>
                {t('CONTRACT')}
              </Text>
              <Text style={{flex: 1}}>
                {t('CATALOG')}
              </Text>
              <Text style={{flex: 2}}>
                {t('CREATED_BY')}
              </Text>
            </View>
            <FlatList
              data={offersToCheck}
              renderItem={_render}
            />
          </>
        )}
      </View>
    </View>
  )
}

export default OffersToCheckScreen