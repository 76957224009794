import { FontAwesome6, MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { Text, View } from 'react-native'
import Modal from 'react-native-modal'
import { t } from 'i18n-js'

interface Props {
  onClose: () => void
}

const MeasuresHelpModal = ({ onClose }: Props) => {
  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <MaterialCommunityIcons
          onPress={onClose}
          size={34}
          color='black'
          name='close'
        />
        <Text style={{ fontSize: 24, paddingLeft: 15 }}>
          {t('MEASURES_HELP')}
        </Text>
      </View>
      <View style={{ flex: 1, marginTop: 20 }}>
        <Text style={{ fontSize: 16 }}>
          {t('MEASURES_HELP_TEXT')}
        </Text>

        <View style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 20 }}>
          <FontAwesome6
            name='file-invoice-dollar'
            color='black'
            style={{ paddingRight: 10 }}
            size={20}
          />
          <Text style={{ flex: 1, fontSize: 16 }}>
            {t('MEASURES_HELP_INVOICE')}
          </Text>
          <FontAwesome6
            name='file-circle-minus'
            color='black'
            style={{ paddingRight: 10 }}
            size={20}
          />
          <Text style={{ flex: 1, fontSize: 16 }}>
            {t('MEASURES_HELP_CREDIT_NOTE')}
          </Text>
          <MaterialCommunityIcons
            name='download'
            color='black'
            style={{ paddingRight: 10 }}
            size={20}
          />
          <Text style={{ flex: 1, fontSize: 16 }}>
            {t('MEASURES_HELP_DOWNLOAD')}
          </Text>
          <MaterialCommunityIcons
            name='file-document'
            color='black'
            style={{ paddingRight: 10 }}
            size={20}
          />
          <Text style={{ flex: 1, fontSize: 16 }}>
            {t('MEASURES_HELP_DAY_REPORT')}
          </Text>
        </View>
      </View>
    </Modal>
  )
}

export default MeasuresHelpModal