import React from "react";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import { Currency } from "../../../../models";
import NativeModalPicker from "../../../../components/NativeModalPicker";

interface Props {
  currency?: Currency;
  setCurrency: (currency: Currency) => void;
}

const DocThreeDotsCurrencies = ({currency, setCurrency}: Props) => {

  const currencies = useSelector((state: Reducer) => state.general.currencies);

  const _handler = (selectedCurrency: Currency) => {
    if (currency?.ID == selectedCurrency.ID) {
      return;
    }
    setCurrency(selectedCurrency)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      selectedValue={currency}
      style={{minWidth: '100%'}}
      onValueChange={_handler}
      placeholder={'SELECT_CURRENCY'}
      options={currencies}
      showLabelAsOption={true}
    />
  );
}

export default DocThreeDotsCurrencies;