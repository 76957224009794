import React, { useEffect, useState } from "react";
import { WorkSpace } from "../../models";
import { Text, View, Pressable } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import CheckingWorkSpaceUrl from "./CheckingWorkSpaceUrl";

interface Props {
  workSpace: WorkSpace;
  back: () => void;
  ok: () => void;
}

const CheckingWorkSpace = ({workSpace, back, ok}: Props) => {

  const [urlOk, setUrlOk] = useState<boolean>(false);
  const [urlSecondaryOk, setSecondaryUrlOk] = useState<boolean>(false);

  const _urlOk = () => setUrlOk(true);
  const _urlSecondaryOk = () => setSecondaryUrlOk(true);

  useEffect(() => {
    if (urlOk && urlSecondaryOk) {
      setTimeout(() => {
        ok();
      }, 500);
    }
  }, [urlOk, urlSecondaryOk]);

  return (
    <>
      <View style={{flexDirection: 'row', paddingTop: 10}}>
        <Pressable onPress={back}>
          <MaterialCommunityIcons
            name="chevron-left-box"
            size={24}
            color="#555"
          />
        </Pressable>

        <Text style={{paddingLeft: 10, fontSize: 24, color: "#555"}}>
          {t("CHECKING_WORKSPACE")}
        </Text>
        <Text style={{fontSize: 24, paddingLeft: 10}}>
          {workSpace.name}
        </Text>
      </View>

      <CheckingWorkSpaceUrl
        description={t("PRIMARY_SERVER_URL")}
        url={workSpace.url}
        ok={_urlOk}
      />

      <View style={{borderBottomColor: '#555', borderBottomWidth: 1}} />

      <CheckingWorkSpaceUrl
        description={t("PRIMARY_SERVER_URL")}
        url={workSpace.secondaryUrl}
        ok={_urlSecondaryOk}
      />
    </>
  );
};

export default CheckingWorkSpace;