import React from "react";
import { Line } from "../../../models";
import { View, Text } from "react-native";
import { DocLineColumnWidth } from "./DocLinesHeader";
import HtmlText from "../../../components/HtmlText";
import { t } from "i18n-js";

interface Props {
  line: Line;
}

const DocLineText = React.memo(({line}: Props) => {

  return (
    <View style={{flexDirection: 'row', flex: 1}}>
      <Text style={{width: DocLineColumnWidth.CODE}}>{t('TEXT')}</Text>
      <View style={{flex: 1}}>
        <HtmlText html={line.Name} maxLength={100} width={"100%"}/>
      </View>
    </View>
  );
});

export default DocLineText;