import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { ReportsScreens } from "./ReportsScreens";
import DayReportCreate from "./DayReportCreate";
import DayReportsToApproveView from "./DayReportsToApproveView";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import DayReportEdit from "./DayReportEdit";
import DayReportApprove from "./DayReportApprove";
import DayReportsDashboard from "./DayReportsDashboard/DayReportsDashboard";
import DayReportDraft from "./DayReportDraft";
import InsertPositionScreen from "../catalog/InsertPositionScreen";
import { SearchScreens } from "../search/SearchScreens";

const Stack = createStackNavigator();

export const ReportsStackNavigator = () => {

  const reportApprove = useSelector((state:Reducer) => state.user.permissions.reportApprove)

  return (
    <Stack.Navigator initialRouteName={reportApprove > 0 ? ReportsScreens.TO_APPROVE : ReportsScreens.DASHBOARD}>
      <Stack.Screen options={{headerShown: false}}
                    name={ReportsScreens.DASHBOARD}
                    component={DayReportsDashboard}/>
      <Stack.Screen name={ReportsScreens.NEW_REPORT}
                    options={{headerShown: false}}
                    component={DayReportCreate}/>

      <Stack.Screen name={ReportsScreens.EDIT_REPORT}
                    options={{headerShown: false}}
                    component={DayReportEdit}/>

      <Stack.Screen name={ReportsScreens.TO_APPROVE}
                    options={{headerShown: false}}
                    component={DayReportsToApproveView}/>

      <Stack.Screen name={ReportsScreens.APPROVE}
                    options={{headerShown: false}}
                    component={DayReportApprove} />

      <Stack.Screen
        name={ReportsScreens.REPORT_DRAFT}
        options={{headerShown: false}}
        component={DayReportDraft}
      />

      <Stack.Screen
        name={SearchScreens.INSERT_POSITION}
        options={{headerShown: false}}
        component={InsertPositionScreen}
      />
    </Stack.Navigator>
  );
};