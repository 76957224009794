import {
  GET_CONSTRUCTION_SITES_SUCCESS,
  LOGOUT,
  GET_BASIC_DATA_SUCCESS,
} from "../types";
import {
  ConstructionSite,
  ConstructionSiteType,
} from "../../models";

export type constructionSite = {
  types: ConstructionSiteType[];
  constructionSites: ConstructionSite[];
};

const initialState: constructionSite = {
  types: [],
  constructionSites: [],
};

export default function (
  state = initialState,
  action: { type: string; payload: any },
): constructionSite {
  switch (action.type) {
    case GET_BASIC_DATA_SUCCESS:
      return {
        ...state,
        types: action.payload.constructionSitesTypes,
      }
    case GET_CONSTRUCTION_SITES_SUCCESS:
      return {
        ...state,
        constructionSites: action.payload,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
