export const formatNumber = (number: number, digits: number = 2): string => {
  if (number == 0 || number == undefined) return "-";

  number = round(number, 2);

  return number.toLocaleString("it-CH", {
    minimumFractionDigits: digits,
  });
};

export const round = (n: number, d: number) => {
  const p = 10 ** d;
  return Math.round(n * p) / p;
}

/**
 * Get the number decimal separator for the current locale.
 *
 * @return Decimal separator of the current locale.
 */
export const getDecimalSeparator = ():string => {
  let n = 1.1;
  return n.toLocaleString().substring(1, 2);
}

export const quantityDivider = (quantity: number, by: number, index: number): number => {
  const decimals = 2
  if (index < by - 1) {
    return round(quantity / by, decimals)
  }

  return quantity - index * round(quantity / by, decimals)
}