import React, { useEffect } from "react";
import { TextInput, Text, View } from "react-native";
import { Discount } from "../../../models";
import DualSelector from "../../../components/DualSelector";
import { Currency } from "../../../models";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import Formatter from "../../../utils/formatters";

interface Props {
  discount: Discount;
  setDiscount: (discount: Discount) => void;
  totalBeforeDiscount: number;
  currency: Currency;
  remove: () => void;
  readOnly?: boolean;
}

const DocFooterDiscount = ({discount, setDiscount, totalBeforeDiscount, currency, remove, readOnly = false}: Props) => {

  const calc = (isNumeric: boolean, value: number):number => {
    if (isNumeric) {
      return value
    } else {
      return Math.round(totalBeforeDiscount * value) / 100;
    }
  }

  const _name = (s: string) => {
    if (readOnly) {
      return;
    }
    setDiscount({
      ...discount,
      name: s,
      totalValue: calc(discount.isNumeric, discount.value),
    });
  };

  const _isNumeric = (v: boolean) => {
    if (readOnly) {
      return;
    }
    setDiscount({
      ...discount,
      isNumeric: v,
      totalValue: calc(v, discount.value),
    });
  };

  const _value = (v: string) => {
    if (readOnly) {
      return;
    }
    const n = Number(v)
    setDiscount({
      ...discount,
      value: n,
      totalValue: calc(discount.isNumeric, n),
    })
  }

  useEffect(() => {
    setDiscount({
      ...discount,
      totalValue: calc(discount.isNumeric, discount.value)
    })
  }, [totalBeforeDiscount])


  useEffect(() => {
    setDiscount({
      ...discount,
      totalValue: calc(discount.isNumeric, discount.value)
    })
  }, [])

  let total
  if (discount.isNumeric) {
    total = totalBeforeDiscount - discount.value
  } else {
    total = Math.round(totalBeforeDiscount * (1 - discount.value / 100) * 100) / 100;
  }

  return (
    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 3}}>
      <MaterialCommunityIcons
        name={"close"}
        size={24}
        onPress={remove}
      />

      <TextInput
        defaultValue={discount.name}
        onChangeText={_name}
        style={{
          flex: 4,
          marginRight: 15,
          padding: 9,
          borderRadius: 6,
          backgroundColor: 'white',
        }}
      />

      <View style={{flex: 1}}>
        <DualSelector
          left={discount.isNumeric}
          setLeft={_isNumeric}
          width={"100%"}
          leftLabel={"DISCOUNT_NUMERIC"}
          rightLabel={"DISCOUNT_PERCENTAGE"}
          readOnly={readOnly}
        />
      </View>

      <Text style={{marginLeft: 15, marginRight: 3}}>-</Text>
      <TextInput
        defaultValue={String(discount.value)}
        onChangeText={_value}
        keyboardType={"number-pad"}
        style={{
          flex: 1,
          padding: 9,
          borderRadius: 6,
          backgroundColor: 'white',
        }}
      />
      <Text style={{marginHorizontal: 3}}>{discount.isNumeric ? currency.Name : t("DISCOUNT_PERCENTAGE")}</Text>

      {!discount.isNumeric &&
      <Text style={{flex: 1, marginLeft: 3}}>{Formatter.number2.format(-totalBeforeDiscount * discount.value / 100)} {currency.Name}</Text>}

      <Text style={{flex: 1, textAlign: 'right'}}>{Formatter.currency(currency.ID).format(total)}</Text>
    </View>
  );
};

export default DocFooterDiscount;