import React from 'react';
import { AccountingDocumentType, ConstructionSiteViewGroups, Contract, Doc, InvoiceDoc, invoiceDetailsToString } from "../../../models";
import { Pressable, Text, View } from "react-native";
import styles from './style';
import { t } from 'i18n-js';
import Status from "../../../components/Status";
import getTheme from '../../../constants/Colors';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface Props {
  doc: Doc;
  onPress: () => void;
  isSelected: boolean;
  contract: Contract
}

const SidebarOfferItem = ({doc, onPress, isSelected, contract}: Props) => {

  const theme = getTheme();

  let date;
  if (doc.UpdatedDate && doc.Type.Config.ConstructionSiteViewGroup == 'offer') {
    date = new Date(doc.UpdatedDate);
  } else {
    date = new Date(doc.CreatedDate);
  }

  const name = `${contract.Name} ${doc.Client.Name}` == doc.Name ? '' : `${doc.Name}\n`;

  let invoiceDetails
  if (doc.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice && doc.Type.Config.AccountingDocument == AccountingDocumentType.Invoice) {
    invoiceDetails = invoiceDetailsToString(doc as InvoiceDoc)
  }

  return (
    <View key={"document" + doc.ID}>
      <Pressable onPress={onPress} style={{backgroundColor: isSelected ? 'lightgray' : 'white', paddingTop: 5}}>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <View>
            <Text>
              {doc.IDString}
            </Text>
            <Text style={{fontSize: 12}}>{doc.Client.Name}</Text>
          </View>
          <Status status={doc.Status} size={"s"}/>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
          <Text style={{fontSize: 12, paddingVertical: 5, flex: 1}}>
            {`${name}`}
            {`${date.toLocaleDateString()} - ${doc.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice ? `${doc.Type.Name} - ` : ''} ${t('VERSION')}: ${doc.Version}`}
            {invoiceDetails && `\n${invoiceDetails}`}
          </Text>
          {doc.ToBeChecked && (
            <MaterialCommunityIcons
              name='eye'
              size={20}
              color={theme.red}
            />
          )}
        </View>
      </Pressable>
      <View style={{borderBottomColor: theme.border, borderBottomWidth: 1}}/>
    </View>
  );
}

export default SidebarOfferItem;