import React, { useEffect, useMemo, useState } from "react";
import { Pressable, View, Text } from "react-native";
import { Doc, Line, LineLevel, VAT, LineSumType, LineType } from '../../../models'
import { t } from "i18n-js";
import DualSelector from "../../../components/DualSelector";
import { TextInput } from "react-native";
import KeyboardHide from "../../../components/KeyboardHide";
import { Discount } from "../../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { PriceLevel } from "../../../store/reducers/settingsReducer";
import DocFooterDiscounts from "./DocFooterDiscounts";
import getTheme from "../../../constants/Colors";
import Formatter from "../../../utils/formatters";
import VATSelector from "../../../components/VATSelector";
import Button from "../../../components/Button";

interface Props {
  doc: Doc;
  setDoc: (doc: Doc) => void;
  lineChangeCounter: number;
  readOnly?: boolean;
  setDocChanged: (doc: Doc, changed: boolean) => void;
}

const DocFooter = ({doc, lineChangeCounter, setDoc, readOnly = false, setDocChanged}: Props) => {

  const theme = getTheme();

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);
  const client = showPriceLevel >= PriceLevel.CLIENT;
  const internal = showPriceLevel >= PriceLevel.INTERNAL;
  const vats = useSelector((state: Reducer) => state.general.vats);

  const [show, setShow] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(0);
  const [totalToConcord, setTotalToConcord] = useState<number>(0);

  const [totals, setTotals] = useState<number[]>([doc.Block ? doc.PriceBlock : total]);

  const [vat, setVat] = useState<VAT>();
  const [vatTotal, setVatTotal] = useState<number>(0);

  const _press = () => {
    setShow(!show);
  }

  const _fixedTotal = (text: string) => {
    if (readOnly) {
      return;
    }
    setDoc({
      ...doc,
      PriceBlock: Number(text),
    });
  };

  const filterNoFixedPrice = (l:Line) => {
    switch (l.LineType) {
      case LineType.FIXED_PRICE:
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        return false
      default:
        return true
    }
  }

  useEffect(() => {
    let total = 0;
    let totalToConcord = 0;
    let lineLevel: LineLevel;
    let vatTotal = 0;
    var vats: VAT[] = [];

    for (let i = 0; i < doc.Lines.length; i++) {
      switch (doc.Lines[i].LineType) {
        case LineType.POSITION:
        case LineType.POSITION_SELL_WARRANTY:
        case LineType.POSITION_CAR:
        case LineType.POSITION_ENGINEERING:
        case LineType.POSITION_EXTERNAL_WORK:
          for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
            lineLevel = doc.Lines[i].LineLevel[j];
            if (lineLevel.LineSumType === LineSumType.NORMAL && (lineLevel.FixedPriceBlock === "-" || lineLevel.FixedPriceBlock === "")) {
              total += lineLevel.LineTotal;
              vatTotal += lineLevel.LineTotal * (lineLevel.Vat?.Percentage || 0) / 100;
              if (lineLevel.Vat && !vats.includes(lineLevel.Vat)) {
                vats.push(lineLevel.Vat);
              }
            } else if (lineLevel.LineSumType === LineSumType.TO_CONCORD) {
              totalToConcord += lineLevel.LineTotal;
            }
          }
          break;
        case LineType.FIXED_PRICE:
        case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
          if (doc.Lines[i]?.FixedPriceBlock !== "-" && doc.Lines[i]?.FixedPriceBlock !== "") {
            total += doc.Lines[i].LineTotal || 0;
          }
          break;
      }
    }

    setTotal(total);
    if (!doc.Block) {
      setDocChanged({
        ...doc,
        PriceBlock: total,
      }, false);
    }

    setTotalToConcord(totalToConcord);

    if (vats.length == 1) {
      setVat(vats[0])
    }
    setVatTotal(Math.round(vatTotal * 100) / 100);
  }, [lineChangeCounter]);

  const _discounts = (discounts: Discount[]) => {
    setDoc({
      ...doc,
      Discounts: discounts
    });
  };

  const _block = (b: boolean) => {
    if (readOnly) {
      return;
    }
    if (b) {
      setDoc({
        ...doc,
        Lines: doc.Lines.filter(filterNoFixedPrice).map(l => ({
          ...l,
          LineLevel: l.LineLevel?.map(ll => ({
            ...ll,
            FixedPriceBlock: "",
            Discount: 0,
            LineTotal: ll.Price * ll.Quantity,
          })),
          LineTotal: l.LineLevel?.reduce((p, v) => {
            if (v.LineSumType === LineSumType.NORMAL) {
              return p + v.Quantity * v.Price;
            }
            return p;
          }, 0),
          Quantity: l.LineLevel?.reduce((p, v) => {
            if (v.LineSumType === LineSumType.NORMAL) {
              return p + v.Quantity;
            }
            return p;
          }, 0),
        })),
        Block: b,
      })
    } else {
      setDoc({
        ...doc,
        Block: b,
      })
    }
  }

  useEffect(() => {
    const _totals = [doc.Block ? doc.PriceBlock : total];

    for (let i = 0; i < doc.Discounts.length; i++) {
      _totals.push(0);
      if (doc.Discounts[i].isNumeric) {
        _totals[i + 1] = _totals[i] - doc.Discounts[i].value;
      } else {
        _totals[i + 1] = Math.round(_totals[i] * (1 - doc.Discounts[i].value / 100) * 100) / 100;
      }
    }

    setTotals(_totals);
    setDocChanged({
      ...doc,
      Total: _totals[_totals.length - 1],
    }, false);
  }, [doc.Discounts, (doc.Block ? doc.PriceBlock : total)]);

  const _notes = (notes: string) => {
    setDocChanged({
      ...doc,
      Notes: notes,
    }, true);
  }

  const gain = doc.PriceBlock - total;
  const gainPercentage = Math.round((doc.PriceBlock - total) / total * 10000) / 100;

  const footerResumee = useMemo(() => {
    let resumee = "";

    if (doc.Block) {
      resumee += t("FLAT_RATE"); + "      "
    }
    resumee += doc.Discounts.reduce((p, c, i) => {
      let b = p;
      if (i == 0) {
        b += "   " + t("DISCOUNTS") + ": ";
      } else {
        b += ", ";
      }

      return `${b} -${c.value} ${c.isNumeric ? doc.Currency.Name : '%'}`
    }, "");

    return resumee;
  }, [lineChangeCounter, doc.Discounts]);

  const currencyFormatter = Formatter.currency(doc.Currency.ID).format;
  const f2 = Formatter.number2.format;

  const _vat = (vat: VAT | undefined) => {
    setVat(vat);
    setDoc({
      ...doc,
      Lines: doc.Lines.map(l => ({
        ...l,
        LineLevel: l.LineLevel.map(ll => ({
          ...ll,
          Vat: vat,
          VatID: vat?.ID || '',
        })),
      })),
    });
  }

  const _equalVat = () => {
    if (vats.length > 0) {
      setVat(vats[0]);
    }
  }

  return (
    <KeyboardHide active={!show}>
      <View>
        <Pressable
          style={{
            flexDirection: 'row',
            padding: 12,
            backgroundColor: 'white',
            borderTopColor: theme.border,
            borderTopWidth: 1,
            justifyContent: 'space-between',
          }}
          onPress={_press}
        >
          <Text>{t("TOTAL")}</Text>
          {!show && <Text>{footerResumee}</Text>}
          <Text style={{fontWeight: 'bold'}}>{currencyFormatter(show&&internal?total:doc.Total)}</Text>
        </Pressable>

        {
          show && (
            <View
              style={{
                padding: 12,
                backgroundColor: theme.background,
                justifyContent: 'space-between',
                borderTopColor: theme.border,
                borderTopWidth: 1,
              }}
            >
              <View style={{flexDirection: 'row', paddingBottom: 3}}>
                <Text style={{paddingRight: 3}}>{t("NOTES")}:</Text>
                <TextInput
                  multiline={true}
                  style={{
                    flex: 1,
                    backgroundColor: 'white',
                    padding: 9,
                    borderRadius: 6,
                  }}
                  value={doc.Notes}
                  onChangeText={_notes}
                />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text>{t("TOTAL_TYPE")}</Text>
                <DualSelector
                  left={doc.Block}
                  setLeft={_block}
                  width={300}
                  leftLabel={"FLAT_RATE"}
                  rightLabel={"NORMAL"}
                />
                <Text>{internal && `${currencyFormatter(total)}`}</Text>

                {
                  doc.Block && (
                    <>
                      <Text>{internal && `(${f2(gainPercentage)} %) ${currencyFormatter(gain)}`}</Text>
                      <TextInput
                        defaultValue={String(doc.PriceBlock)}
                        onChangeText={_fixedTotal}
                        editable={!readOnly}
                        style={{backgroundColor: "white", padding: 2, borderWidth: 1, borderColor: "gray", borderRadius: 5}}
                        keyboardType={"number-pad"}
                      />
                    </>
                  )
                }

              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: 15,
                }}
              >
                <Text>{t("TOTAL_TO_CONCORD")}</Text>
                <Text>({currencyFormatter(totalToConcord)})</Text>
              </View>

              <DocFooterDiscounts discounts={doc.Discounts}
                                  setDiscounts={_discounts}
                                  currency={doc.Currency}
                                  totals={totals}
                                  readOnly={readOnly}/>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 15,
                }}
              >
                <Text>{t("TOTAL_WITH_DISCOUNT")}</Text>
                <Text>{currencyFormatter(doc.Total)}</Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 15,
                }}
              >
                <Text>{t("VAT")}</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {
                    vat ? (readOnly ? (
                      <Text>{vat.Name}</Text>
                    ) : (
                      <VATSelector setVAT={_vat} vat={vat}/>
                    )) : (
                      readOnly ? (<></>) : (<Button
                        titleT='DOC_FOOTER_SET_EQUAL_VAT'
                        onPress={_equalVat}
                        style={{backgroundColor: theme.blue}}
                      />
                    ))
                  }
                  {/*<Text>{vatTotal} {doc.Currency.Name}</Text>*/}
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 15,
                }}
              >
                <Text>{t("TOTAL_WITH_VAT")}</Text>
                {/*<Text>{doc.Total + vatTotal}</Text>*/}
              </View>
            </View>
          )
        }
      </View>
    </KeyboardHide>
  );
};

export default DocFooter;