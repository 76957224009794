import React from "react";
import { Text, View } from "react-native";
import { t } from "i18n-js";

const DayReportApproveHeader = () => {
  return (
    <View style={{flexDirection: "row", paddingVertical: 1, alignItems: "center"}}>
        <Text style={{width: 100}}>{t("CODE")}</Text>
        <Text style={{flex: 4}}>{t("NAME")}</Text>
        <View style={{flex: 3, alignItems: "center", flexDirection: "row"}}>
          <Text style={{flex: 1, textAlign: "center"}}>{t("SOLD")}</Text>
          <Text style={{flex: 1, textAlign: "center"}}>{t("APPROVED")}</Text>
          <Text style={{flex: 1, textAlign: "center"}}>{t("TODO")}</Text>
          <Text style={{flex: 1, textAlign: "center"}}>{t("WORKER")}</Text>
        </View>
        <Text style={{flex: 1, textAlign: "center"}}>{t("APPROVE")}</Text>
        <Text style={{flex: 1, textAlign: "center"}}>{t("BILLABLE")}</Text>
    </View>
  );
};

export default DayReportApproveHeader;