import React, { useState } from "react";
import { Item } from "../../../models";
import { View, Text } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import TimePicker from "../../../components/TimePicker";
import Formatter from "../../../utils/formatters";
import NumberInput from "../../../screens/document/PostitionComponent/NumberInput";

interface Props {
  item: Item;
  setItem: (item: Item) => void;
  deleteItem: (item: Item) => void;
}

const hoursMinutes = (q: number) => {
  const hours = Math.floor(q);
  const minutes = q % 60;

  return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
}

const CreateCustomPositionLine = ({item, setItem, deleteItem}: Props) => {

  const [lineTotal, setLineTotal] = useState<number>(0);

  const _set = (quantity: number) => {
    setLineTotal(quantity * (item.Price || 0));
    setItem({
      ...item,
      Quantity: quantity,
    });
  }

  const _time = (value: string) => {
    let [hours, minutes] = value ? value.toString().split(":") : ["0", "0"];

    _set(parseInt(hours) + parseInt(minutes) / 60);
  };

  const _delete = () => {
    deleteItem(item);
  }

  return (
    <View style={{flexDirection: 'row', alignItems: 'center', paddingVertical: 2}}>
      <Text style={{flex: 2}}>{item.IDString}</Text>
      <Text style={{flex: 5, paddingRight: 5}}>{item.Name}</Text>
      <View style={{flex: 2, alignItems: 'center'}}>
        { item.UnitOfMeasure === "ore" ? (
          <TimePicker value={hoursMinutes(item.Quantity || 0)} onChange={_time}/>
        ) : (
          <NumberInput
            defaultValue={item.Quantity || 0}
            onChange={_set}
            min={0}
          />
        )}
      </View>
      <View style={{flex: 2}}>
        <Text style={{textAlign: 'center'}}>{item.UnitOfMeasure}</Text>
      </View>
      <View style={{flex: 2}}>
        <Text style={{textAlign: 'center'}}>{Formatter.number2.format(item.Price || 0)}</Text>
      </View>
      <View style={{flex: 2}}>
        <Text style={{textAlign: 'center'}}>{Formatter.number2.format(lineTotal)}</Text>
      </View>
      <View style={{flex: 1}}>
        <MaterialCommunityIcons
          name="delete"
          size={24}
          color="#555"
          onPress={_delete}
        />
      </View>
    </View>
  );
};

export default CreateCustomPositionLine;