import { sendMail } from "../api/MailAPI";
import { AlertEmail, ConstructionSite, Contract, Doc } from "../models";
import store from "../store/store";
import { translations } from 'i18n-js'


export const AlertEmailType = {
  DAY_REPORT_QUANTITY_EXCEEDED: "DAY_REPORT_QUANTITY_EXCEEDED",
  DAY_REPORT_ADDED_POSITION: "DAY_REPORT_ADDED_POSITION",
  DAY_REPORT_QUANTITY_EXCEEDED_ADDED_POSITION: "DAY_REPORT_QUANTITY_EXCEEDED_A",
  DAY_REPORT_FINISHED: "DAY_REPORT_FINISHED",
  OFFER_WON: "OFFER_WON",
  OFFER_UNDER_MINIMUM_PRICE: "OFFER_UNDER_MINIMUM_PRICE",
  DOC_DRAFTED: "DOC_DRAFTED",
}

export const getAlertsEmail = (type: string): AlertEmail[] => {
  const alertsEmail = (store.getState().general.alertsEmail  || []) as AlertEmail[];

  return alertsEmail.filter(t => t.Type === type);
}

export const alertOfferWon = async (doc: Doc) => {

  const s = store.getState().constructionSite.constructionSites as ConstructionSite[];
  let contract: Contract | undefined;
  let constructionSite: ConstructionSite | undefined;
  for (let i = 0; i < s.length; i++) {
    for (let j = 0; j < s[i].Contracts.length; j++) {
      if (s[i].Contracts[j].ID === doc.ContractID) {
        contract = s[i].Contracts[j];
        constructionSite = s[i];
        i = s.length;
        break;
      }
    }
  }
  const recipients = getAlertsEmail(AlertEmailType.OFFER_WON);
  // TODO add seller if != by

  recipients.forEach(r => {
    const code = (constructionSite?.Code?.length || 0) > 0 ? constructionSite?.Code : constructionSite?.IDString
    sendMail(
      r.Email,
      // @ts-ignore
      `${doc.UpdatedBy?.FirstName} ${doc.UpdatedBy?.LastName} ${translations[r.Language]["HAS_WON_OFFER"]} - ${doc.IDString}`,
      // @ts-ignore
      `${translations[r.Language]["CONSTRUCTION_SITE"]}: ${code}  ${constructionSite?.Name}   -    ${contract?.Name}   - ${doc.IDString}`,
    )
  })
}