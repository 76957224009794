import React from 'react';
import { statusCodeToText } from "../../constants/Status";
import { ViewStyle,Text, View } from "react-native";

interface Props {
  status: number;
  style?: ViewStyle;
  size: "xs" | "s" | "m" | "l" | "xl";
}

interface Style {
  paddingX: number;
  paddingY: number;
  borderRadius: number;
  fontSize: number;
}

const sizeToStyle = (size: "xs" | "s" | "m" | "l" | "xl"): Style => {
  switch (size) {
    case "xs":
      return {
        paddingX: 6,
        paddingY: 4,
        borderRadius: 4,
        fontSize: 10,
      }
    case "s":
      return {
        paddingX: 9,
        paddingY: 6,
        borderRadius: 6,
        fontSize: 12,
      }
    case "l":
      return {
        paddingX: 12,
        paddingY: 7,
        borderRadius: 7,
        fontSize: 16,
      }
    case "xl":
      return {
        paddingX: 14,
        paddingY: 7,
        borderRadius: 7,
        fontSize: 18,
      }
    default:
      return {
        paddingX: 12,
        paddingY: 7,
        borderRadius: 7,
        fontSize: 14,
      }
  }
}

const Status = ({status, style, size}: Props) => {

  const s = statusCodeToText(status);

  const {
    paddingY,
    paddingX,
    borderRadius,
    fontSize,
  } = sizeToStyle(size);

  return (
    <View 
      style={[{
        backgroundColor: s.color,
        paddingHorizontal: paddingX,
        paddingVertical: paddingY,
        borderRadius: borderRadius,
      }, style]}
    >
      <Text style={{color: 'white', fontSize, textTransform: 'uppercase'}}>
        {s.text}
      </Text>
    </View>
  );
};

export default Status;