import React from "react";
import { Line } from "../../../../../models";
import { Text, View } from "react-native";
import HtmlText from "../../../../../components/HtmlText";
import { PriceLevel } from "../../../../../store/reducers/settingsReducer";

interface Props {
  line: Line;
  largeView: boolean;
  showPriceLevel: number;
}

const OrderLineTitle = ({line, largeView, showPriceLevel}:Props) => {

  const lineDescription = line.InternalName.length > 0 ? line.InternalName : line.Name;
  const internal = showPriceLevel >= PriceLevel.INTERNAL;
  const client = showPriceLevel >= PriceLevel.CLIENT;

  return (
    <View style={{flexDirection: "row"}}>
      <Text style={{width: 90}}>{line.Code}</Text>
      <View style={{flex: 3}}>
        <HtmlText html={lineDescription}/>
      </View>
    </View>
  );
}

export default OrderLineTitle;