import React from 'react'
import { Text } from 'react-native'
import Formatter from '../../../../../utils/formatters'

interface OrderValueProps {
  value: number
  bold?: boolean
  show?: boolean
}

const OrderValue = ({value, bold = false, show = true}: OrderValueProps) => {

  if (!show) {
    return <></>
  }

  const f2 = Formatter.number2.format
  const v = value != 0 ? f2(value) : '-'

  return (
    <Text style={{
      flex: 1,
      textAlign: 'right',
      fontWeight: bold && value != 0 ? 'bold' : 'normal',
      paddingRight: 5,
    }}>
      {v}
    </Text>
  )
}

export default OrderValue