import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import getTheme from "../../../../constants/Colors";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 5,
  },
  pdf: {
    flex: 1,
  },
  headerTextContainer: {
    padding: 10,
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: RFValue(8),
  },
  button: {
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    padding: 5,
    width: 50,
    height: 50,
  },
  leftSide: {
    flex: 1,
    justifyContent: "flex-start",
  },
  rightSide: {},
  normalText: {
    justifyContent: "flex-start",
    textAlign: "left",
    fontSize: 16,
  },
});

export default styles;
