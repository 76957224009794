import React, { useMemo, useState } from 'react'
import { FlatList, Pressable, Text, View } from 'react-native'
import { AccountingDocumentType, Discount, Invoice, Line, Measure } from '../../models'
import MeasureInvoiceLinePreview from './MeasureInvoiceLinePreview'
import { t } from 'i18n-js'
import { FontAwesome } from '@expo/vector-icons'
import Formatter from '../../utils/formatters'
import getTheme from '../../constants/Colors'

interface MeasureInvoicePreviewProps {
  measure: Measure
}

const MeasureInvoicePreview = ({ measure }: MeasureInvoicePreviewProps) => {

  const theme = getTheme()

  const f2 = Formatter.number2.format

  const [showTotalQuantity, setShowTotalQuantity] = useState<boolean>(true)
  const _toggleShowTotalQuantity = () => setShowTotalQuantity(s => !s)

  const filteredInvoices = useMemo(() => {
    return measure
      .invoices
      .filter(i => new Date(i.CreatedDate).getTime() <= measure.createdAt.getTime())
  }, [measure])

  const total = useMemo(() => {
    return measure
      .order
      .Lines
      .reduce((p, v) => {
        const measureLine = measure.measureLines.find(ml => ml.ActivityID === v.LineNum)

        return v.LineLevel?.reduce((p, v, levelIndex) => {
          const level = measureLine?.Levels?.[levelIndex]
          let quantity = level?.BillableQuantity || 0
          if (showTotalQuantity) {
            quantity = level?.BilledQuantity || 0
          }
          if (v.DiscountNumeric) {
            return p + quantity * v.PriceCalculated
          } else {
            return p + quantity * v.PriceCalculated
          }
        }, p) || 0
      }, 0)
  }, [measure, showTotalQuantity])

  const invocesDiscounts = useMemo(() => {
    if (!showTotalQuantity) {
      return 0
    }
    return filteredInvoices.reduce((p, v) => {
      return v.Lines.reduce((p, v) => {
        if (v.Code.substring(0, 3) === 'SC_') {
          return p + v.LineTotal
        }
        return p
      }, p)
    }, 0)
  }, [measure, showTotalQuantity, filteredInvoices])

  const totalWithDiscounts = useMemo(() => {
    return measure.order.Discounts.reduce((p, v) => {
      if (v.isNumeric) {
        return p - v.value / measure.order.Total * total
      } else {
        return p - v.value / 100 * p
      }
    }, total + invocesDiscounts)
  }, [measure, total])

  const totalWithInvoices = useMemo(() => {
    if (!showTotalQuantity) {
      return totalWithDiscounts
    }
    return filteredInvoices.reduce((p, v) => {
      return p + v.Total * (v.Type.Config.AccountingDocument == AccountingDocumentType.Invoice ? -1 : 1)
    }, totalWithDiscounts)
  }, [measure, totalWithDiscounts, showTotalQuantity, filteredInvoices])

  const vat = useMemo(() => {
    return measure.order.Lines.reduce((p, v) => {
      const measureLine = measure.measureLines.find(ml => ml.ActivityID === v.LineNum)
      return v.LineLevel?.reduce((p, v, levelIndex) => {
        const measureLevel = measureLine?.Levels?.[levelIndex]
        return p + (measureLevel?.BillableQuantity || 0) * (v.Vat?.Percentage || 0) / 100 * v.Price
      }, p) || 0
    }, 0)
  }, [measure])

  const _renderMeasureLine = ({ item, index }: { item: Line, index: number }) => {

    const measureLine = measure.measureLines.find(ml => ml.ActivityID === item.LineNum)

    return (
      <MeasureInvoiceLinePreview
        measureLine={measureLine}
        printLevels={false}
        key={`ml-${index}`}
        index={index}
        orderLine={item}
        showTotalQuantity={showTotalQuantity}
      />
    )
  }

  const _renderDiscount = ({ item, index }: { item: Discount, index: number }) => {
    const value = item.value / measure.order.Total * total
    return (
      <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
        <Text style={{ flex: 1, fontSize: 16 }}>
          {item.name}
        </Text>
        <Text key={`discount-${index}`} style={{ flex: 1, textAlign: 'right', fontSize: 16 }}>
          {item.isNumeric ? -f2(value) : `-${item.value}%`}
        </Text>
      </View>
    )
  }

  const _renderInvoice = ({ item, index }: { item: Invoice, index: number }) => {
    const total = item.Total * (item.Type.Config.AccountingDocument == AccountingDocumentType.Invoice ? -1 : 1)
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingBottom: 5 }} key={`invoice-${index}`}>
        <Text style={{ flex: 1, fontSize: 16 }}>
          {item.IDString}
        </Text>
        <Text style={{ flex: 1, fontSize: 16 }}>
          {new Date(item.CreatedDate).toLocaleDateString()}
        </Text>
        <Text key={`invoice-${index}`} style={{ flex: 1, textAlign: 'right', fontSize: 16 }}>
          {f2(total)}
        </Text>
      </View>
    )
  }

  const FooterComponent = () => (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1 }} />
      <View style={{ width: 400 }}>
        <View style={{ flexDirection: 'row', paddingVertical: 5, borderBottomColor: theme.border, borderBottomWidth: 1 }}>
          <View style={{ flex: 2 }} />
          <Text style={{ flex: 1, textAlign: 'right', fontSize: 16, fontWeight: 'bold' }}>
            {t('TOTAL')}
          </Text>
        </View>
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5, borderBottomColor: theme.border, borderBottomWidth: 1 }}>
            <Text style={{ fontSize: 16, flex: 1 }}>
              Totale fatturato:
            </Text>
            <Text style={{ textAlign: 'right', fontSize: 16 }}>
              {f2(total)}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', paddingTop: 5, borderBottomColor: theme.border, borderBottomWidth: 1 }}>
            <Text style={{ flex: 1, fontSize: 16 }}>Sconti</Text>
            <View style={{ flex: 2 }}>
              <FlatList
                data={measure.order.Discounts}
                renderItem={_renderDiscount}
              />
              {showTotalQuantity && (
                <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                  <Text style={{ flex: 1, fontSize: 16 }}>
                    Sconti fatture:
                  </Text>
                  <Text style={{ flex: 1, textAlign: 'right', fontSize: 16 }}>
                    {f2(invocesDiscounts)}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={{ flexDirection: 'row', paddingVertical: 5, borderBottomColor: theme.border, borderBottomWidth: 1 }}>
            <Text style={{ flex: 1, fontSize: 16 }}>
              Totale dopo gli sconti:
            </Text>
            <Text style={{ textAlign: 'right', fontSize: 16 }}>
              {f2(totalWithDiscounts)}
            </Text>
          </View>
          {showTotalQuantity && (<>
            <Text style={{ fontSize: 16, paddingTop: 5 }}>Fatture</Text>
            <View style={{ flexDirection: 'row', paddingVertical: 5 }}>
              <Text style={{ flex: 1, fontSize: 16 }}>Numero</Text>
              <Text style={{ flex: 1, fontSize: 16 }}>Data</Text>
              <Text style={{ flex: 1, fontSize: 16, textAlign: 'right' }}>Totale Iva Esclusa</Text>
            </View>
            <FlatList
              data={filteredInvoices}
              renderItem={_renderInvoice}
            />
            <View style={{ flexDirection: 'row', borderTopColor: theme.border, borderTopWidth: 1, borderBottomColor: theme.border, borderBottomWidth: 1, paddingVertical: 5 }}>
              <Text style={{ flex: 1, fontSize: 16 }}>Totale dopo le fatture precedenti:</Text>
              <Text style={{ textAlign: 'right', fontSize: 16 }}>
                {f2(totalWithInvoices)}
              </Text>
            </View>
          </>)}
          <View style={{ flexDirection: 'row', paddingVertical: 5 }}>
            <Text style={{ flex: 1, fontSize: 16 }}>Iva:</Text>
            <Text style={{ textAlign: 'right', fontSize: 16 }}>
              {f2(vat)}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', paddingVertical: 5, borderTopColor: theme.border, borderTopWidth: 1 }}>
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 16 }}>
                Totale:
              </Text>
            </View>
            <Text style={{ textAlign: 'right' }}>
              {f2(totalWithInvoices + vat)}
            </Text>
          </View>
          <Text style={{ fontSize: 12 }}>
            Attenzione calcolo provvisiorio, dato reale calcolato in programma fatturazione.
            Quote anticipo non calcolate.
          </Text>
        </View>
      </View>
    </View>
  )

  return (
    <View style={{ paddingLeft: 5, paddingRight: 10, paddingBottom: 100, flex: 1 }}>
      <View style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingBottom: 5,
        paddingHorizontal: 5,
      }}>
        <Text style={{ width: 100, fontWeight: 'bold' }}>
          {t('CODE')}
        </Text>
        <Text style={{ flex: 1, fontWeight: 'bold' }}>
          {t('DESCRIPTION')}
        </Text>
        <Text style={{ width: 100, fontWeight: 'bold', textAlign: 'center' }}>
          {t('UNIT_OF_MEASURE')}
        </Text>
        <Text style={{ width: 100, fontWeight: 'bold', textAlign: 'right' }}>
          {t('PRICE')}
        </Text>
        <Pressable
          style={{ width: 100, alignItems: 'flex-end' }}
          onPress={_toggleShowTotalQuantity}
        >
          <View style={{ alignItems: 'center' }}>
            <FontAwesome
              name='exchange'
              size={16}
            />
            <Text style={{ fontWeight: 'bold', textAlign: 'right' }}>
              {t('QUANTITY')}
            </Text>
          </View>
        </Pressable>
        <Text style={{ width: 100, fontWeight: 'bold', textAlign: 'right' }}>
          {t('DISCOUNT')}
        </Text>
        <Text style={{ width: 100, fontWeight: 'bold', textAlign: 'right' }}>
          {t('TOTAL')}
        </Text>
      </View>
      <FlatList
        data={measure.order.Lines}
        keyExtractor={(item, index) => `measure-line-${index}`}
        renderItem={_renderMeasureLine}
        ListFooterComponent={<FooterComponent />}
      />
    </View>
  )
}

export default MeasureInvoicePreview