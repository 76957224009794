import React, { useState } from 'react'
import getTheme from '../../../constants/Colors'
import { Item, ItemType, Line, UoM, VAT, LineType } from '../../../models'
import { useDispatch } from 'react-redux'
import { createPosition } from '../../../api/GeneralAPI'
import { useNavigation } from '@react-navigation/native'
import { alert } from '../../../hooks/Alert'
import { logout } from '../../../store/action/authAction'
import { sentryCapture } from '../../../utils/sentryCapture'
import { ActivityIndicator, Text, TextInput, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { t } from 'i18n-js'
import Button from '../../../components/Button'
import UoMComponent from '../../../components/UoMComponent'
import CreateCustomPositionTable from './CreateCustomPositionTable'
import Formatter from '../../../utils/formatters'
import { SearchScreens } from '../../search/SearchScreens'

interface Props {
  route?: {
    params?: {
      onAdd: (item: Item, keepOrder: boolean) => void
      toEdit?: Line
    }
  }
}

const CreatePositionScreen = ({route}: Props) => {

  const theme = getTheme()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)

  const [name, setName] = useState<string>(route?.params?.toEdit?.Name || "")
  const [items, setItems] = useState<Item[]>((route?.params?.toEdit && route.params?.toEdit.Items && route.params?.toEdit.Items.length > 0) ? route.params?.toEdit.Items : [])
  const [uom, setUom] = useState<UoM | string>(route?.params?.toEdit?.UnitOfMeasure || "")
  const [total, setTotal] = useState<number>(0)

  const handleAdd = (item: Item | Item[]) => {
    if (Array.isArray(item)) {
      setItems((old) => [...old, ...item])
    } else {
      setItems((old) => [...old, item])
    }
  }

  const handleDelete = (item: Item) => {
    setItems((old) => old.filter((i) => i.IDString !== item.IDString))
  }

  const handleCreate = async () => {
    setLoading(true)
    let Price = 0
    let HumanWorkRevenue = 0
    let MaterialRevenue = 0
    let Hours = 0
    let MinimumPrice = 0
    let Cost = 0
    let HumanWorkCost = 0
    let MaterialCost = 0

    for (let i = 0; i < items.length; i++) {
      const q = (items[i].Quantity || 0)
      Price += q * (items[i].Price || 0)
      Cost += q * (items[i].Cost || 0)
      MinimumPrice += q * (items[i].MinimumPrice || 0)

      if (items[i].ItemType === ItemType.WORK) {
        Hours += q;
        HumanWorkCost += q * (items[i].Cost || 0)
        HumanWorkRevenue += q * (items[i].Price || 0)
      } else {
        MaterialCost += q * (items[i].Cost || 0)
        MaterialRevenue += q * (items[i].Price || 0)
      }
    }

    const position = {
      ID: "",
      IDString: route?.params?.toEdit?.Code,
      UnitOfMeasure: uom,
      Type: LineType.POSITION,
      Price,
      HumanWorkRevenue,
      MaterialRevenue,
      Hours,
      MinimumPrice,
      Cost,
      HumanWorkCost,
      MaterialCost,
      Items: items,
      Name: name,
      InternalName: name.substring(0, 90),
      ItemType: "",
      FatherID: route?.params?.toEdit?.FatherID,
      Special: "",
      AutoComplete: false,
      RangeMax: null,
      TwinIDs: null,
      VAT: {} as VAT,
      RangeMin: null,
      Variable: false,
    } as Item
   
    console.log(name, uom, items)
    console.log(name.length > 0)
    if (name.length > 0 && uom && items.length > 0 && (items[0].Quantity || 0) > 0) {
      try {
        const item = await createPosition(position);
        route?.params?.onAdd(item, true);
        navigation.goBack()
      } catch (e: any) {
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR", "CREATE_CUSTOM_POSITION_ERROR");
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false)
      alert("ERROR", "CREATE_CUSTOM_POSITION_ERROR").then();
    }
  }

  const _setItem = (item: Item, index: number) => {
    let total = 0
    for (let i = 0; i < items.length; i++) {
      if (i === index) {
        total += (item.Quantity || 0) * (item.Price || 0)
      } else {
        total += (items[i].Quantity || 0) * (items[i].Price || 0)
      }
    }
    items[index] = item
    setItems(items)
    setTotal(total)
  }

  const _close = () => {
    if (!loading) {
      navigation.goBack()
    }
  }

  const _insertPositionView = () => {
    // @ts-ignore
    navigation.push(SearchScreens.INSERT_POSITION, {
      showCreate: false,
      onAdd: handleAdd,
      initialCatalogId: 7,
    })
  }

  return (
    <View style={{flex: 1, padding: 5}}>
      {loading && (
        <View style={{
          zIndex: 100,
          height: "100%",
          width: "100%",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,.7)"
        }}>
          <ActivityIndicator size="large" color={theme.mainColor}/>
        </View>
      )}
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}> 
        <MaterialCommunityIcons
          name="close"
          size={35}
          onPress={_close}
        />
        <Text style={{fontSize: 24}}>{t("CREATE_POSITION")}</Text>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            titleT="CREATE"
            style={{
              marginTop: 4,
              backgroundColor: theme.mainColor,
            }}
            onPress={handleCreate}
          />
        </View>
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 25,
        paddingHorizontal: 5,
      }}>
        <View style={{flexDirection: 'row'}}>
          <Text style={{color: 'black', paddingTop: 5, paddingRight: 5}}>{t("NAME")}:</Text>
          <TextInput
            multiline={true}
            style={{
              height: 50,
              width: 500,
              marginLeft: 3,
              borderWidth: 1,
              borderRadius: 5,
              borderColor: name.length == 0 ? 'red' : 'gray',
              backgroundColor: 'white',
            }}
            placeholder={t("NAME_OF_THE_POSITION")}
            value={name}
            onChangeText={setName}
          />
        </View>
        <View style={{flex: 1}}/>
        <Text style={{marginRight: 3}}>{t("UNIT_OF_MEASURE")}:</Text>
        <UoMComponent
          selected={uom}
          onChange={setUom}
        />
      </View>
      
      <CreateCustomPositionTable
        deleteItem={handleDelete}
        items={items}
        setItem={_setItem}
      />
      
      {!route?.params?.toEdit && (
        <View style={{justifyContent: 'center', marginVertical: 25}}>
          <Button
            leftComponent={<MaterialCommunityIcons name="plus" size={20} />}
            onPress={_insertPositionView}
            titleT="ADD_A_ROW"
            _textStyle={{color: 'black'}}
          />
        </View>
      )}
      <View style={{flexDirection: 'row', marginVertical: 25, justifyContent: 'space-between'}}>
        <Text>
          {t("TOTAL")} {t("PRICE").toLowerCase()}:
        </Text>
        <Text style={{fontWeight: 'bold', marginLeft: 2}}>
          {Formatter.number2.format(total)}
        </Text>
      </View>
    </View>
  )
}

export default CreatePositionScreen