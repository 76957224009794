import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { AccountingDocumentType, ConstructionSite, ConstructionSiteViewGroups, Contract, Doc, File, FileType } from "../../../models";
import { getConstructionSiteById } from "../../../api/ConstructionSitesAPI";
import { alert } from "../../../hooks/Alert";
import { logout } from "../../../store/action/authAction";
import { sentryCapture } from "../../../utils/sentryCapture";
import { FlatList, ScrollView, ActivityIndicator, KeyboardAvoidingView, Platform, Text, View, Pressable } from "react-native";
import ConstructionSiteDetailsHeader from "./ConstructionSiteDetailsHeader";
import { t } from "i18n-js";
import { Reducer } from "../../../store/reducers";
import { SearchScreens } from "../../search/SearchScreens";
import styles from "./style";
import ContractHeader from "./ContractHeader";
import ConstructionSiteMarginalityOverview from "../../../components/MarginalityModal/ConstructionSiteMarginalityOverview";
import ConstructionSiteViews from "./ConstructionSiteViews";
import ConstructionSiteDetailsContract from "./ConstructionSiteDetailsContract";
import DocumentView from "../views/DocumentView";
import DayReportsView from "../views/DayReportsView";
import FileTypeView from "../views/FileTypeView";
import PDFView from "../views/PDFView";
import OrderView from "../views/OrderView";
import Measures from "../views/Measures";
import ContractOverview from "../../../components/ContractOverview";
import { getDocById } from "../../../api/DocAPI";
import { sapDocToDocConvert } from "../../../shared-utils/ConversionUtils";
import store from "../../../store/store";
import ExternalWorkInvoices from "../views/ExternalWorkInvoices";
import IndustrialAccountingMultiContractsModal from "../../../components/IndustrialAccountingMultiContractsModal";

interface Props {
  route?: {
    params?: {
      ID?: number;
      ContractID?: number;
      SelectedView?: ConstructionSiteViews;
    };
  };
}

const ConstructionSiteDetails = ({route}: Props) => {

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const isFocused = useIsFocused();

  const user = useSelector((state: Reducer) => state.user);

  const [selectedView, setSelectedView] = useState<ConstructionSiteViews>(ConstructionSiteViews.BuildingSiteOverview);
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [pulled, setPulled] = useState<boolean>(false)

  const [constructionSite, setConstructionSite] = useState<ConstructionSite>();
  const [contract, setContract] = useState<Contract>();
  const [doc, setDoc] = useState<Doc>();
  const [fileType, setFileType] = useState<FileType>();
  const [file, setFile] = useState<File>();

  const contracts = useMemo(() => constructionSite?.Contracts.filter(c => {
    if (user.permissions.sales) {
      return true;
    }

    return c.Docs.find(d => d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Order) != undefined;
  }), [constructionSite]) || [];

  const pull = async () => {
    if (loading) {
      return
    }
    if (route?.params?.ID == undefined) {
      alert('ERROR', 'ERROR_CONSTRUCTION_SITE');
      navigation.goBack();
      return;
    }
    setLoading(true);
    try {
      const c = await getConstructionSiteById(route.params.ID)
      setConstructionSite(c)
      if (route.params?.ContractID) {
        const index = c.Contracts.findIndex(c => c.ID == route.params?.ContractID)
        if (index >= 0) {
          setContract(c.Contracts[index])
        } else {
          setContract(undefined)
          alert('ERROR', 'ERROR_LOADING_CONTRACT')
        }
      } else if (contract) {
        setContract(c.Contracts.find(c => c.ID === contract.ID))
      } else if (c.Contracts.length == 1 && !user.permissions.sales) {
        setContract(c.Contracts[0])
      } else {
        setContract(undefined)
      }
      if (route.params.SelectedView) {
        setSelectedView(route.params.SelectedView)
      } else if (selectedView) {
        setSelectedView(selectedView)
      }
    } catch (e:any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout())
      } else {
        sentryCapture(e)
        alert("ERROR", "ERROR_CONSTRUCTION_SITE")
        navigation.goBack()
      }
    } finally {
      setLoading(false)
      setPulled(true)
    }
  };

  useEffect(() => {
    if (pulled) {
      isFocused && pull();
    }
  }, [isFocused]);

  useEffect(() => {
    pull()
  }, [route?.params]);

  const _setContract = (contract?: Contract) => {
    setContract(contract);
    setDoc(undefined);
    setFileType(undefined);
    setFile(undefined);
    setSelectedView(ConstructionSiteViews.ContractOverview);
  };

  const _renderContract = ({item, index}: {item: Contract, index: number}) => {
    return (
      <ConstructionSiteDetailsContract
        contract={item}
        selectedView={selectedView}
        setDoc={setDoc}
        setSelectedView={setSelectedView}
        setFileType={setFileType}
        setSelectedContract={_setContract}
        doc={doc}
        fileType={fileType}
        selectedContract={contract}
      />
    );
  }

  const _constructionSiteOverview = () => setContract(undefined)

  const _createContract = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.CREATE_CONTRACT, {
      constructionSiteId: constructionSite!.ID,
    });
  }

  const _setSelectedViewOrder = () => setSelectedView(ConstructionSiteViews.Order);
  const _setSelectedViewOverview = () => {
    setSelectedView(ConstructionSiteViews.BuildingSiteOverview)
    pull().then(() => {
      setSelectedView(ConstructionSiteViews.ContractOverview)
    })
  }

  const _setInvoice = (invoiceId: number, type: AccountingDocumentType) => {
    const d = contract
      ?.Docs
      .find(d => d.ID == invoiceId
        && d.Type.Config.AccountingDocument == type
      )

    if (d) {
      getDocById(d.ID, d.Type).then(sapDoc => {
        const doc = sapDocToDocConvert(
          sapDoc,
          store.getState().general.catalogs,
        )
        setDoc(doc)
        setSelectedView(ConstructionSiteViews.Document)
      })
      .catch((e) => {
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
          dispatch(logout())
        } else {
          sentryCapture(e)
          alert("ERROR_UPDATING", "ERROR_LOADING_DOCUMENT")
        }
      })
    }
  }

  const _renderView = () => {
    switch (selectedView) {
      case ConstructionSiteViews.Document:
        if (doc && contract) {
          return (
            <DocumentView
              documentSelected={doc}
              contract={contract}
              largeView={expanded}
              pull={pull}
              setLoading={setLoading}
              setSelectedViewOrder={_setSelectedViewOrder}
              constructionSite={constructionSite}
            />
          );
        } else {
          return <Text>{t('ERROR')}</Text>
        }
      case ConstructionSiteViews.DayReports:
        if (contract) {
          return (
            <DayReportsView contract={contract} pull={pull} />
          );
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          );
        }
      case ConstructionSiteViews.FileType:
        if (fileType && contract) {
          return (
            <FileTypeView
              fileType={fileType}
              contractId={contract.ID}
              setFileToOpen={setFile}
              setSelectedView={setSelectedView}
            />
          );
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          );
        }
      case ConstructionSiteViews.PDF:
        if (file && contract) {
          return (
            <PDFView file={file} contractId={contract.ID} />
          );
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          );
        }
      case ConstructionSiteViews.Order:
        if (contract && constructionSite) {
          return (
            <OrderView
              contract={contract}
              constructionSite={constructionSite}
              largeView={expanded}
              setSelectedOverview={_setSelectedViewOverview}
            />
          );
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          );
        }
      case ConstructionSiteViews.Measure:
        if (contract && constructionSite) {
          return (
            <Measures
              contract={contract}
              constructionSite={constructionSite}
              setInvoice={_setInvoice}
            />
          );
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          );
        }
      case ConstructionSiteViews.ContractOverview:
        if (contract) {
          return (
            <ContractOverview
              contract={contract}
            />
          );
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          );
        }
      case ConstructionSiteViews.BuildingSiteOverview:
        if (constructionSite) {
          if (user.permissions.sales) {
            return (
              <ConstructionSiteMarginalityOverview
                constructionSite={constructionSite}
              />
            );
          } else {
            return <></>;
          }
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          );
        }
      case ConstructionSiteViews.ExternalWorkInvoices:
        if (contract && user.permissions.sales) {
          return (
            <ExternalWorkInvoices
              contract={contract}
              pull={pull}
            />
          )
        } else {
          return (
            <Text>{t('ERROR')}</Text>
          )
        }
    }
  }

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator />
      </View>
    )
  }

  if (!constructionSite) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text>{t('ERROR_CONSTRUCTION_SITE')}</Text>
      </View>
    )
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS == "ios" ? "padding" : "height"}
      style={{flex: 1}}
      keyboardVerticalOffset={100}
    >
      <ConstructionSiteDetailsHeader
        constructionSite={constructionSite}
        reload={pull}
        navigation={navigation}
      />
      <View style={{ flexDirection: "row", flex: 1 }}>
        <View style={{
          width: 300,
          margin: 5,
          marginRight: 0,
          display: expanded ? "none" : "flex",
        }}>
          <ScrollView>
            {
              user.permissions.sales && (
                <View key={"ConstructionSiteOverView"}>
                  <Pressable
                    onPress={_constructionSiteOverview}
                    style={[styles.categoryContentContainer, { paddingHorizontal: 8, paddingVertical: 2, margin: 5 }]}
                  >
                    <View style={styles.categoryContainer}>
                      <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                        {t("OVERVIEW")}
                      </Text>
                    </View>
                  </Pressable>
                </View>
              )
            }
            <FlatList
              data={contracts}
              renderItem={_renderContract}
              key={`construction-site-details-contracts`}
              // @ts-ignore
              listKey={`construction-site-details-contracts`}
              keyExtractor={(item, index) => `construction-site-details-contract-${index}-${item.ID}`}
            />
            {
              user.permissions.sales && (
                <View key={"Contract-new"}>
                  <Pressable
                    onPress={_createContract}
                    style={[styles.categoryContentContainer, { padding: 8, margin: 5 }]}
                  >
                    <View style={styles.categoryContainer}>
                      <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                        + {t("CREATE_CONTRACT")}
                      </Text>
                    </View>
                  </Pressable>
                </View>
              )
            }
          </ScrollView>
        </View>
        <View style={{flex: 1, marginTop: 5}}>
          {contract ? (
            <>
              <ContractHeader
                contracts={constructionSite.Contracts}
                contract={contract}
                expand={expanded}
                setExpand={setExpanded}
                reload={pull}
              />
              {_renderView()}
            </>
          ):user.permissions.sales ? (
            <View>
              {constructionSite.Notes.length > 0 && (
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginBottom: 10,
                }}>
                  <Text style={{marginTop: 5, marginRight: 5}}>{t('NOTES')}:</Text>
                  <Text style={{
                    flex: 1,
                    padding: 5,
                    borderRadius: 5,
                    borderColor: 'black',
                    borderWidth: 1,
                  }}>
                    {constructionSite.Notes}
                  </Text>
                </View>
              )}
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={{fontSize: 20, flex: 1}}>
                  {t("CONSTRUCTION_SITE")} {t("MARGINALITY")}
                </Text>

                <IndustrialAccountingMultiContractsModal
                  constructionSite={constructionSite}
                />
              </View>
              <ConstructionSiteMarginalityOverview
                constructionSite={constructionSite}
              />
            </View>
            ):(<></>)
          }
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default ConstructionSiteDetails;