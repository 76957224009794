import React, { useMemo, useState } from 'react'
import { ConstructionSite, Contract } from '../../models'
import { Pressable, Text, FlatList, View, ActivityIndicator } from 'react-native'
import { DocStatus } from '../../constants/Status'
import Status from '../Status'
import getTheme from '../../constants/Colors'

interface ConstructionSiteItemProps {
  constructionSite: ConstructionSite
  onSelectContract?: (contract: Contract) => void
  onSelectConstructionSite?: (constructionSite: ConstructionSite) => void
}

const ConstructionSiteItem = ({
  constructionSite,
  onSelectContract,
  onSelectConstructionSite,
}: ConstructionSiteItemProps) => {

  const theme = getTheme()

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const contracts = useMemo(() => {
    if (onSelectConstructionSite) {
      return []
    }
    return constructionSite.Contracts.filter(c => {
      switch (Number(c.Status)) {
        case DocStatus.OPEN.code:
        case DocStatus.IN_EXECUTION.code:
        case DocStatus.TO_BE_APPROVED.code:
        case DocStatus.TO_BE_BILLED.code:
          return true
        default:
          return false
      }
    })
  }, [constructionSite])

  const _press = () => {
    if (onSelectConstructionSite) {
      onSelectConstructionSite(constructionSite)
      return
    }
    if (contracts.length == 1) {
      setLoading(true)
      if (onSelectContract) {
        onSelectContract(contracts[0])
      }
    } else {
      setOpen(!open)
    }
  }

  const _render = ({item, index}: {item: Contract, index: number}) => {
    const _press = () => {
      setLoading(true)
      if (onSelectContract) {
        onSelectContract(item)
      }
    }
    return (
      <Pressable
        onPress={_press}
        style={{
          paddingTop: index == 0 ? 0 : 12,
          paddingBottom: 18,
        }}
        key={`contract-${item.ID}-${index}`}
      >
        <Text style={{fontSize: 16}}>{item.Name}</Text>
      </Pressable>
    )
  }

  return (
    <View>
      <Pressable
        onPress={_press}
        style={{
          borderTopColor: theme.border,
          borderTopWidth: 1,
          paddingHorizontal: 2,
          paddingTop: 12,
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Text style={{width: 100, fontSize: 16, paddingBottom: 12}}>
          {constructionSite.Code ? constructionSite.Code : constructionSite.IDString }
        </Text>
        <View style={{flex: 1, paddingBottom: 12}}>
          <Text style={{fontSize: 16}}>
            {constructionSite.Name}
          </Text>
          {((constructionSite.Address?.length || 0) > 0) && (
            <Text style={{fontSize: 14}}>
              {constructionSite.Address}
            </Text>
          )}
        </View>
        <View style={{width: 10}} />

        <View style={{flex: 1, paddingRight: 10}}>
          <FlatList
            data={contracts}
            renderItem={_render}
            ItemSeparatorComponent={() => <View style={{height: 1, backgroundColor: theme.border}} />}
          />
        </View>
        {loading && (
          <ActivityIndicator style={{paddingHorizontal: 10}} />
        )}
        <View style={{paddingBottom: 12}}>
          <Status status={constructionSite.Status} size='m' />
        </View>
      </Pressable>
    </View>
  )
}

export default ConstructionSiteItem