import React from 'react'
import { OfferToCheck } from '../../../models'
import { useNavigation } from '@react-navigation/native'
import { Pressable, Text } from 'react-native'
import { SearchScreens } from '../SearchScreens'
import { DocMode } from '../../doc/utils'
import getTheme from '../../../constants/Colors'

interface OffersToCheckItemProps {
  offerToCheck: OfferToCheck
  index: number
}

const OffersToCheckItem = ({offerToCheck, index}: OffersToCheckItemProps) => {

  const navigation = useNavigation()
  const theme = getTheme()

  const background = index % 2 == 0 ? 'white' : theme.background
  
  const _press = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.DOC, {
      mode: DocMode.UPDATE,
      docId: offerToCheck.ID,
      docType: offerToCheck.DocType,
    })
  }

  return (
    <Pressable
      style={{
        flexDirection: 'row',
        backgroundColor: background,
        padding: 5,
      }}
      onPress={_press}
    >
      <Text style={{flex: 1}}>
        {offerToCheck.DocType.Name}
      </Text>
      <Text style={{width: 100}}>
        {offerToCheck.IDString}
      </Text>
      <Text style={{flex: 1}}>
        {offerToCheck.ClientName}
      </Text>
      <Text style={{width: 100}}>
        {offerToCheck.ConstructionSite.Code || offerToCheck.ConstructionSite.IDString}
      </Text>
      <Text style={{flex: 2}}>
        {offerToCheck.ConstructionSite.Name}
      </Text>
      <Text style={{flex: 2}}>
        {offerToCheck.Contract.Name}
      </Text>
      <Text style={{flex: 1}}>
        {offerToCheck.CatalogName}
      </Text>
      <Text style={{flex: 2}}>
        {offerToCheck.CreatedBy.FirstName} {offerToCheck.CreatedBy.LastName}
      </Text>
    </Pressable>
  )
}

export default OffersToCheckItem