import React, { useState } from "react";
import { Text, View, TextInput, ActivityIndicator } from "react-native"
import { t } from "i18n-js";
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Button from "../Button";
import getTheme from "../../constants/Colors";
import { isDocSameVersion, updateOfferStatus } from "../../api/DocAPI";
import { ConstructionSite, Contract, Doc } from "../../models";
import { alert, docOverwriteChangesAlert } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { updateContract } from "../../api/ConstructionSitesAPI";
import { sentryCapture } from "../../utils/sentryCapture";
import Modal from "react-native-modal"
import { alertOfferWon } from "../../utils/AlertsEmail";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { SearchScreens } from "../../screens/search/SearchScreens";
import ConstructionSiteViews from "../../screens/constructionSites/ConstructionSiteDetails/ConstructionSiteViews";
import { useNavigation } from "@react-navigation/native";

interface Props {
  onClose: () => void;
  doc: Doc;
  contract: Contract;
  constructionSite: ConstructionSite
}

const WinModal = ({onClose, doc, contract, constructionSite}: Props) => {

  const navigation = useNavigation()
  const theme = getTheme();
  const dispatch = useDispatch();
  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const _close = () => !loading && onClose()

  const _win = async () => {
    try {
      setLoading(true)
      const c = await isDocSameVersion(doc)
      if (c.changed) {
        if (!await docOverwriteChangesAlert(c.doc)) {
          setLoading(false)
          return
        }
      }
      const updatedDoc = await updateOfferStatus(doc, true, null, notes, catalogs);
      if (updatedDoc) {
        alertOfferWon(updatedDoc)
      }

      // @ts-ignore
      navigation.navigate(`Sidebar`, {
        screen: 'SearchScreenStack',
        params: {
          screen: SearchScreens.CONSTRUCTION_SITES_DETAILS,
          params: {
            ID: constructionSite.ID,
            SelectedView: ConstructionSiteViews.Order,
            ContractID: contract.ID,
          },
        },
      });
    } catch (e: any) {
      console.log(e);
      if (e.error?.response && e.error.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_UPDATING_DESC");
      }
    } finally {
      setLoading(false);
    }
  }

  const addNotes = (notesToAdd: string) => {
    if (notesToAdd.length > 0) {
      if (notes.length > 0) {
        setNotes(`${notes}\n${notesToAdd}`)
      } else {
        setNotes(notesToAdd)
      }
    } else {
      alert('WARNING', 'NO_NOTES_TO_COPY')
    }
  }

  const _constructionSiteNotes = () => addNotes(constructionSite.Notes)

  const _contractNotes = () => addNotes(contract.Notes)

  const _docNotes = () => addNotes(doc.Notes)

  const _clearNotes = () => setNotes('')

  return (
    <Modal
      isVisible={true}
      onBackdropPress={_close}
      style={{
        backgroundColor: 'white',
        borderRadius: 15,
        padding: 15,
        width: 400,
        alignSelf: 'center',
      }}
    >
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 24}}>{t("WIN_OFFER")}</Text>

        <MaterialIcons
          name='close'
          size={34}
          onPress={_close}
        />
      </View>

      <KeyboardAwareScrollView
        extraScrollHeight={100}
        enableOnAndroid={true}
        keyboardShouldPersistTaps='handled'
        style={{flex: 1}}
      >
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10,
        }}>
          <Text style={{fontSize: 20}}>
            {t('CONSTRUCTION_SITE_NOTES')}
          </Text>
          <FontAwesome
            name='copy'
            size={24}
            color='black'
            onPress={_constructionSiteNotes}
          />
        </View>

        <Text style={{paddingTop: 10}}>
          {constructionSite.Notes.length > 0 ? constructionSite.Notes : '-'}
        </Text>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10,
        }}>
          <Text style={{fontSize: 20}}>
            {t('CONTRACT_NOTES')}
          </Text>
          <FontAwesome
            name='copy'
            size={24}
            color='black'
            onPress={_contractNotes}
          />
        </View>

        <Text style={{paddingTop: 10}}>
          {contract.Notes.length > 0 ? contract.Notes : '-'}
        </Text>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10,
        }}>
          <Text style={{fontSize: 20}}>
            {t('OFFER_NOTES')}
          </Text>
          <FontAwesome
            name='copy'
            size={24}
            color='black'
            onPress={_docNotes}
          />
        </View>

        <Text style={{paddingTop: 10}}>
          {doc.Notes.length > 0 ? doc.Notes : '-'}
        </Text>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10,
        }}>
          <Text style={{fontSize: 20}}>
            {t('NOTES_FOR_DAY_REPORT')}
          </Text>
          <MaterialCommunityIcons
            name='close'
            size={24}
            color='black'
            onPress={_clearNotes}
          />
        </View>
        <TextInput
          style={{
            marginTop: 10,
            borderColor: theme.background,
            backgroundColor: theme.thirdBackground,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            fontSize: 16,
          }}
          value={notes}
          onChangeText={setNotes}
          multiline={true}
          numberOfLines={4}
        />
      </KeyboardAwareScrollView>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          style={{
            backgroundColor: theme.mainColor,
          }}
          onPress={_win}
          disabled={loading}
          titleT={loading ? undefined : "WIN"}
        >
          {loading && <ActivityIndicator color="white" />}
        </Button>
      </View>

    </Modal>
  );
};

export default WinModal;