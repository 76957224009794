import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { alert } from '../../hooks/Alert'
import { getStartStopKey } from '../../utils/reports/report-utils'
import { StartStop } from '../../models'
import { ActivityIndicator, View } from 'react-native'
import DayReportView from './DayReportView'
import { DayReportMode } from './DayReportView/DayReportMode'
import storage from '../../storage'

interface DayReportDraftProps {
  route: {
    params?: {
      uuid?: string
      initShowNotes?: boolean
    }
  }
}

const DayReportDraft = ({ route }: DayReportDraftProps) => {

  const navigation = useNavigation()

  const [startStop, setStartStop] = useState<StartStop | undefined>()

  useEffect(() => {
    if (!route.params?.uuid) {
      alert("ERROR_UPDATING", "ERROR_LOADING_DAY_REPORT")
      navigation.goBack()
      return
    }
    storage.getItem(getStartStopKey(route.params.uuid)).then(value => {
      if (!value) {
        alert("ERROR_UPDATING", "ERROR_LOADING_DAY_REPORT")
        navigation.goBack()
        return
      }
      let startStop: StartStop = JSON.parse(value)
      startStop.dayReport.DateTime = new Date(startStop.dayReport.DateTime)
      startStop.dayReport.SignedTime = startStop.dayReport.SignedTime ? new Date(startStop.dayReport.SignedTime) : undefined
      setStartStop(startStop)
    })
  }, [])

  if (!startStop) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator size='large'/>
      </View>
    )
  }

  return (
    <DayReportView
      initDayReport={startStop.dayReport}
      mode={startStop.dayReport.ID < 0 ? DayReportMode.CREATE : DayReportMode.UPDATE}
      initDepartureTime={startStop.departureTime}
      initMaterials={startStop.materials?.length > 0 ? startStop.materials : undefined}
      uuid={route.params?.uuid}
      initShowNotes={route.params?.initShowNotes}
    />
  )
}

export default DayReportDraft