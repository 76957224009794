import { Alert, Platform } from "react-native";
import { t } from "i18n-js";
import { ConstructionSite, Contract, Doc, Marginality, Resource } from "../models";
import { AlertEmailType, getAlertsEmail } from "../utils/AlertsEmail";
import { sendMail } from "../api/MailAPI";
import { translations } from 'i18n-js'

const webAlertConfirmText = (title:string, description: string=""):Promise<boolean> => {
  return new Promise((resolve) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'));

    resolve(result);
  });
};

const webAlertConfirm = (titleT:string, descriptionT: string=""):Promise<boolean> => {
  return webAlertConfirmText(t(titleT), descriptionT.length>0?t(descriptionT):"");
};

const mobileAlertConfirmText = (title: string, description: string=""): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    Alert.alert(
      title,
      description,
      [
        {text: t("YES"), onPress: () => resolve(true), style: "destructive"},
        {text: t("NO"), onPress: () => resolve(false), style: "cancel"},
      ],
      {cancelable: false},
    );
  });
};

const mobileAlertConfirm = (titleT: string, descriptionT: string=""):Promise<boolean> => {
  return mobileAlertConfirmText(t(titleT), descriptionT.length>0?t(descriptionT):"");
};

const alertConfirmText = Platform.OS === "web" ? webAlertConfirmText : mobileAlertConfirmText;
const alertConfirm = Platform.OS === "web" ? webAlertConfirm : mobileAlertConfirm;

const webAlertText = (title:string, description:string=""):Promise<void> => {
  return new Promise<void>((resolve) => {
    window.alert([title, " " + description]);
    resolve();
  });
};

const webAlert = (titleT:string, descriptionT:string=""):Promise<void> => {
  return webAlertText(t(titleT), descriptionT.length>0?t(descriptionT):"");
};

const mobileAlertText = (title:string, description:string=""):Promise<void> => {
  return new Promise<void>((resolve) => {
    Alert.alert(title, description);
    resolve();
  });
};

const mobileAlert = (titleT:string, descriptionT: string=""):Promise<void> => {
  return mobileAlertText(t(titleT), descriptionT.length>0?t(descriptionT):"");
};

const alertText = Platform.OS === "web" ? webAlertText : mobileAlertText;
const alert = Platform.OS === "web" ? webAlert : mobileAlert;

export const docOverwriteChangesAlert = (serverDoc: Doc): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const by = serverDoc?.UpdatedBy ? serverDoc.UpdatedBy : serverDoc.CreatedBy
    const date = serverDoc?.UpdatedDate ? serverDoc.UpdatedDate : serverDoc.CreatedDate

    let desc = t("VERSION_ALREADY_CHANGED_DESC")

    if (by) {
      desc = desc.replace("{0}", by.FirstName)
      desc = desc.replace("{1}", by.LastName)
    }
    if (date) {
      const d = new Date(date);
      desc = desc.replace("{2}", d.toLocaleDateString(undefined, {hour: '2-digit', minute: '2-digit'}))
      desc = desc.replace("{3}", d.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'}))
    }
    resolve(await alertConfirmText(
      t("VERSION_ALREADY_CHANGED"),
      desc,
    ))
  })
}

export const alertUnderMiniumPrice = (m: Marginality, constructionSite: ConstructionSite, contract: Contract, createdDoc: Doc, by: Resource) => {
  if (!m.doc) {
    return
  }
  const recipients = getAlertsEmail(AlertEmailType.OFFER_UNDER_MINIMUM_PRICE)
  recipients.forEach(r => {
    sendMail(
      r.Email,
      // @ts-ignore
      `${by.FirstName} ${by.LastName}  ${translations[r.Language]["HAS_OFFERED_UNDER_PRICE"]}`,
      // @ts-ignore
      `${translations[r.Language]["CONSTRUCTION_SITE"]}: ${constructionSite.Code ?? constructionSite.IDString} ${constructionSite.Name}    -    ${contract.Name}\n\n${translations[r.Language]["OFFER"]}: ${createdDoc.IDString}   ${translations[r.Language]["VERSION"]}\n\n${translations[r.Language]["TOTAL"]}: ${createdDoc.Total} ${createdDoc.Currency.Name} ${translations[r.Language]["MINIMUM_PRICE"]}: ${m.minPrice} ${createdDoc.Currency.Name}`
    )
  })
}

export const alertDocDraftCreated = (constructionSite : ConstructionSite, contract: Contract, doc: Doc, by: Resource, created: boolean) => {
  if (!doc.ToBeChecked) {
    return
  }
  const recipients = getAlertsEmail(AlertEmailType.DOC_DRAFTED)
  recipients.forEach(r => {
    sendMail(
      r.Email,
      // @ts-ignore
      `${by.FirstName} ${by.LastName}  ${translations[r.Language][created ? 'DOC_DRAFTED_CREATE' : 'DOC_DRAFTED_UPDATE']}`,
      // @ts-ignore
      `${translations[r.Language]["CONSTRUCTION_SITE"]}: ${constructionSite.Code ?? constructionSite.IDString} ${constructionSite.Name}    -    ${contract.Name}\n\n${translations[r.Language]["OFFER"]}: ${doc.IDString}   ${translations[r.Language]["VERSION"]}`
    )
  })
}

export {
  alertConfirmText,
  alertConfirm,
  alertText,
  alert,
};
