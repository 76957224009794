import React, { useState } from "react";

import { ConstructionSite, Contract, Doc } from "../../models";
import DocScreen from "./DocScreen";
import { View } from "react-native";
import ChooseConstructionSiteContractModal from "./ChooseConstructionSiteContractModal";
import { DocMode } from "./utils";

const DocScreenCreate = () => {

  const [constructionSite, setConstructionSite] = useState<ConstructionSite>();
  const [contract, setContract] = useState<Contract>();
  const [doc, setDoc] = useState<Doc>();
  const [uuid, setUuid] = useState<string>();

  const [selectModalVisible, setSelectModalVisible] = useState<boolean>(true);

  if (constructionSite && contract && doc && uuid) {
    return (
      <DocScreen
        initialMode={DocMode.CREATE}
        initialConstructionSite={constructionSite}
        initialContract={contract}
        initialDoc={doc}
        uuid={uuid}
      />
    );
  }

  return (
    <View>
      <ChooseConstructionSiteContractModal
        setConstructionSite={setConstructionSite}
        setContract={setContract}
        setDoc={setDoc}
        visible={selectModalVisible}
        setVisible={setSelectModalVisible}
        doc={doc}
        contract={contract}
        constructionSite={constructionSite}
        mode={DocMode.CREATE}
        setUuid={setUuid}
      />
    </View>
  );
}

export default DocScreenCreate;