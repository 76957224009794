import React from "react";
import { Doc } from "../../../models";
import { Text, View } from "react-native";
import { t } from 'i18n-js';
import DocDetailsClient, { ClientOptions } from "./DocDetailsClient";

interface Props {
  doc: Doc;
  setDoc: (doc: Doc) => void;
  clientLocked?: boolean;
}

const DocDetailsClients = ({doc, setDoc, clientLocked}: Props) => {
  return (
    <View style={{paddingTop: 15}}>
      <View style={{paddingHorizontal: "10%", flexDirection: "row"}}>
        <View style={{width: 50}} />
        <Text style={{flex: 1}}>{t('DOC_CLIENT_CODE')}</Text>
        <Text style={{flex: 2}}>{t("NAME")}</Text>
        <Text style={{flex: 2}}>{t("DOC_CLIENT_CONTACT")}</Text>
        <Text style={{flex: 2}}>{t("EMAIL")}</Text>
        <Text style={{flex: 2}}>{t("PHONE")}</Text>
      </View>

      <DocDetailsClient option={ClientOptions.CLIENT} setDoc={clientLocked ? () => {} : setDoc} doc={doc} />
      <DocDetailsClient option={ClientOptions.BY} setDoc={setDoc} doc={doc} />
      <DocDetailsClient option={ClientOptions.OFFER_NAMED_TO} setDoc={setDoc} doc={doc} />
      <DocDetailsClient option={ClientOptions.INVOICE_TO} setDoc={setDoc} doc={doc} />
    </View>
  );
};

export default DocDetailsClients;