import React from "react";
import { Discount } from "../../../models";
import { View, Text } from "react-native";
import { t } from "i18n-js";
import { FlatList } from "react-native";
import DocFooterDiscount from "./DocFooterDiscount";
import { Currency } from "../../../models";
import Button from "../../../components/Button";
import getTheme from "../../../constants/Colors";

interface Props {
  discounts: Discount[];
  setDiscounts: (discounts: Discount[]) => void;
  currency: Currency;
  totals: number[];
  readOnly?: boolean;
}

const DocFooterDiscounts = ({discounts, setDiscounts, currency, totals, readOnly = false}: Props) => {

  const theme = getTheme();

  const _render = ({item, index}: {item: Discount, index: number}) => {
    const _discount = (discount: Discount) => {
      setDiscounts(discounts.map((item2, index2) => {
        if (index !== index2) return item2;
        return discount;
      }));
    };
    const _remove = () => {
      const d = [...discounts];
      d.splice(index, 1);
      setDiscounts(d);
    }
    return (
      <DocFooterDiscount discount={item}
                         setDiscount={_discount}
                         totalBeforeDiscount={totals[index]}
                         currency={currency}
                         remove={_remove}
                         readOnly={readOnly}/>
    );
  };

  const _newDiscount = () => {
    setDiscounts([
      ...discounts,
      {
        value: 0,
        isNumeric: false,
        name: t("DISCOUNT"),
        totalValue: 0,
      }
    ])
  }

  return (
    <View style={{marginTop: 15}}>
      <Text>{t("DISCOUNTS")}</Text>

      <FlatList
        data={discounts}
        renderItem={_render}
        ListFooterComponent={!readOnly ? <Button onPress={_newDiscount} style={{marginTop: 3, backgroundColor: theme.blue}} titleT='NEW_DISCOUNT' /> : <></>}
        key={`discounts`}
        // @ts-ignore
        listKey={`discounts`}
        keyExtractor={(item, index) => `discount-${index}`}
      />
    </View>
  );
};

export default DocFooterDiscounts;