import React, { useEffect } from "react";
import { ClientType } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  type?: ClientType;
  setType: (type: ClientType) => void;
  disabled?: boolean;
}

const ClientTypeDropdown = ({type, setType, disabled = false}: Props) => {

  const clientTypes = useSelector((state: Reducer) => state.client.clientTypes);

  useEffect(() => {
    if (clientTypes.length == 1 && (!type || type.ID == 0) && !disabled) {
      setType(clientTypes[0])
    }
  }, [])

  const _change = (selectedClientType: ClientType) => {
    if (type?.ID == selectedClientType.ID || disabled) {
      return;
    }

    setType(selectedClientType)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={type}
      onValueChange={_change}
      placeholder='SELECT_TYPE'
      options={clientTypes}
      showLabelAsOption={true}
    />
  );
};

export default ClientTypeDropdown;