import React, { useEffect, useState } from "react";
import { Doc, Item, Line, LineSumType, LineType } from '../../../models'
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { PriceLevel } from "../../../store/reducers/settingsReducer";
import { DocLineColumnWidth } from "./DocLinesHeader";
import HtmlText from "../../../components/HtmlText";
import { t } from "i18n-js";
import DocLinePositionLevelBlockSelect from "./DocLinePositionLevelBlockSelect";
import { TextInput, Text, View } from "react-native";
import { alert, alertText } from "../../../hooks/Alert";
import { round } from '../../../shared-utils/NumberUtils'
import getTheme from "../../../constants/Colors";
import Formatter from "../../../utils/formatters";

interface Props {
  line: Line;
  setLine: (setLine:(old:Line) => Line) => void;
  letters: string[];
  addItem: (item: Item | Item[], keepOrder: boolean) => void;
  doc?: Doc;
}

const areEqual = (p: Readonly<Props>, n: Readonly<Props>) => {
  return JSON.stringify(p) === JSON.stringify(n);
}

const DocLineFixedPrice = React.memo(({line, letters, setLine, doc}: Props) => {

  const theme = getTheme();

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);
  const client = showPriceLevel >= PriceLevel.CLIENT;
  const internal = showPriceLevel >= PriceLevel.INTERNAL;

  const [_line, _setLine] = useState<Line>(line);
  const [tot, setTot] = useState<number>(0);
  const [catalogPrice, setCatalogPrice] = useState<number>(0);

  const [value, setValue] = useState<string>(String(line.LineTotal));
  const [invalid, setInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (doc) {
      let warrantyEngineering = line.LineType === LineType.FIXED_PRICE_ENGINEERING_WARRANTY;
      let tot = 0;
      let catalogPrice = 0;

      for (let i = 0; i < doc.Lines.length; i++) {
        switch (doc.Lines[i].LineType) {
          case LineType.POSITION_ENGINEERING:
          case LineType.POSITION_SELL_WARRANTY:
            for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
              if (doc.Lines[i].LineLevel[j].LineSumType === LineSumType.NORMAL && doc.Lines[i].LineLevel[j].FixedPriceBlock == _line.FixedPriceBlock) {
                if (!warrantyEngineering) {
                  alert(
                    "ERROR",
                    "ENGINEERING_WARRANTY_LINE_IN_NORMAL_FIXED_PRICE"
                  )
                  return;
                }
                catalogPrice += round(doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity, 2);
                tot += round(doc.Lines[i].LineLevel[j].LineTotal ,2);
              }
            }
            break;
          case LineType.POSITION:
          case LineType.POSITION_CAR:
          case LineType.POSITION_EXTERNAL_WORK:
            for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
              if (doc.Lines[i].LineLevel[j].LineSumType === LineSumType.NORMAL && doc.Lines[i].LineLevel[j].FixedPriceBlock == _line.FixedPriceBlock) {
                if (warrantyEngineering) {
                  alert(
                    "ERROR",
                    "NORMAL_LINE_IN_ENGINEERING_WARRANTY_FIXED_PRICE"
                  )
                  return;
                }
                catalogPrice += round(doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity, 2);
                tot += round(doc.Lines[i].LineLevel[j].LineTotal ,2);
              }
            }
            break;
        }
      }

      setTot(tot);
      setCatalogPrice(catalogPrice);
      if ((_line.LineTotal === 0 || _line.LineTotal === undefined) && tot != line.LineTotal) {
        _setLine({
          ..._line,
          LineTotal: tot,
        });
        setLine(old => {
          return {
            ...old,
            LineTotal: tot,
          };
        });
        setValue(String(tot));
      }
    }
  }, [doc, line.FixedPriceBlock]);

  const _block = (block: string) => {
    _setLine({
      ..._line,
      FixedPriceBlock: block,
    });
    setLine(old => {
      return {
        ...old,
        FixedPriceBlock: block,
      };
    });
  };

  const _priceBlur = () => {
    try {
      const n = Number(value);
      if (isNaN(n)) {
        throw t("NOT_VALID_NUMBER");
      }
      _setLine({
        ..._line,
        LineTotal: n,
      });
      setLine(old => {
        return {
          ...old,
          LineTotal: n,
        };
      });
      setInvalid(false);
    } catch (message) {
      setInvalid(true);
      alertText(t("ERROR"), String(message));
    }
  };

  const color = _line.LineTotal - catalogPrice < 0 ? theme.red : theme.green;
  const f2 = Formatter.number2.format;

  return (
    <View style={{flexDirection: 'row', flex: 1}}>
      <Text style={{width: DocLineColumnWidth.CODE}}>{_line.Code}</Text>

      <View style={{flex: 1}}>
        <HtmlText html={_line.Name} maxLength={100} width={"100%"}/>
      </View>

      <Text style={{width: DocLineColumnWidth.UOM}}>{t("FLAT_RATE")}</Text>
      <View style={{width: DocLineColumnWidth.QUANTITY}}/>
      <View style={{width: DocLineColumnWidth.PRICE}}>
        {
          internal && (
            <>
              <Text>{catalogPrice}</Text>
              <Text style={{color}}>{`(${f2(_line.LineTotal - catalogPrice)} - ${f2((_line.LineTotal - catalogPrice) / catalogPrice * 100)}%)`}</Text>
            </>
          )
        }
      </View>
      <View style={{width: DocLineColumnWidth.TOTAL}}>
        {
          (client || internal) && <TextInput value={value}
                                             style={{borderColor: invalid ? 'red' : theme.border, borderWidth: 1, borderRadius: 5, padding: 2}}
                                             keyboardType={"number-pad"}
                                             onBlur={_priceBlur}
                                             onChangeText={setValue}/>
        }
      </View>

      <DocLinePositionLevelBlockSelect setBlock={_block}
                                       block={_line.FixedPriceBlock || ""}
                                       letters={letters}/>
    </View>
  );
}, areEqual);

export default DocLineFixedPrice;