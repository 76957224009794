import React, { useEffect, useState } from "react";
import { Level, Line, Material, Report, ReportLevel, LineType } from '../../../models'
import HtmlText from "../../../components/HtmlText";
import NumberInput from "../../document/PostitionComponent/NumberInput";
import { FlatList, Text, View } from "react-native";
import TimePicker from "../../../components/TimePicker";
import { getStringFromTime, getTimeFromString } from "../../../utils/StringUtils";
import { t } from "i18n-js";
import Formatter from "../../../utils/formatters";
import { isHours } from "../../../utils/reports/report-utils";
import getTheme from "../../../constants/Colors";

interface Props {
  report: Report;
  setReport: ({s, i}: {s: (report: Report) => Report, i: number}) => void;
  levels: Level[];
  index: number;
  orderLine: Line;
}

const DayReportApproveLine = ({report, setReport, levels, index, orderLine}:Props) => {

  const theme = getTheme()

  const reportIndex = index;

  const background = report.BackgroundColor ?? (index % 2 == 0 ? "white" : theme.background)

  const [approvedQuantity, setApprovedQuantity] = useState<number>(0);

  useEffect(() => {
    setApprovedQuantity(
      report.Levels?.reduce((p, v) => p + (v.ApprovedQuantity || 0), 0) || 0
    );
  }, [report]);

  if (report.Type === LineType.TITLE) {
    return (
      <View 
        style={{
          flexDirection: 'row',
          backgroundColor: background,
          paddingVertical: 1
        }}
      >
        <Text style={{width: 100, paddingLeft: 1}}>{report.ActivityIDString}</Text>
        <View style={{flex: 4}}>
          <HtmlText html={report.Name} maxLength={250} width={"100%"}/>
        </View>
        <View style={{flex: 5}}/>
      </View>
    );
  }

  const _time = (s: string) => {
    setReport({
      s: (r: Report) => {
        r.ApprovedDuration = getTimeFromString(s) || 0;
        return r;
      },
      i: reportIndex,
    })
  }

  const f2 = Formatter.number2.format;
  const hours = isHours(report.UnitOfMeasure)

  const _renderLevel = ({item, index}: {item: ReportLevel, index: number}) => {

    const orderLineLevel = orderLine.LineLevel[item.LevelIndex];
    if (!orderLineLevel) console.log(item);

    const _approveQuantity = (value: number) => {
      setReport({
        s: (r: Report) => {
          if (r.Levels) {
            if (r.Levels[index].ApprovedQuantity == r.Levels[index].BillableQuantity) {
              r.Levels[index].BillableQuantity = value;
            }
            r.Levels[index].ApprovedQuantity = value;
          }
          return r;
        },
        i: reportIndex,
      });
    }

    const _approveQuantityTime = (time: string) => {
      const value = (getTimeFromString(time) || 0) / 60
      setReport({
        s: (r: Report) => {
          if (r.Levels) {
            if (r.Levels[index].ApprovedQuantity == r.Levels[index].BillableQuantity) {
              r.Levels[index].BillableQuantity = value
            }
            r.Levels[index].ApprovedQuantity = value
          }
          return r
        },
        i: reportIndex,
      })
    }

    const _billableQuantity = (value: number) => {
      setReport({
        s: (r: Report) => {
          if (r.Levels) {
            r.Levels[index].BillableQuantity = value;
          }
          return r;
        },
        i: reportIndex,
      });
    };

    const _billableQuantityTime = (time: string) => {
      const value = (getTimeFromString(time) || 0) / 60
      setReport({
        s: (r: Report) => {
          if (r.Levels) {
            if (r.Levels[index].ApprovedQuantity == r.Levels[index].BillableQuantity) {
              r.Levels[index].BillableQuantity = value
            }
            r.Levels[index].ApprovedQuantity = value
          }
          return r
        },
        i: reportIndex,
      })
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 1,
        }}
        key={`level${index}-${item.ID}`}
      >
        <View style={{width: 100}}/>
        <Text style={{flex: 4}}>{levels[item.LevelIndex].Name}</Text>
        <View style={{flex: 3, flexDirection: 'row'}}>
          <Text style={{flex: 1, textAlign: "center"}}>{f2(orderLineLevel?.Quantity || 0)}</Text>
          <Text style={{flex: 1, textAlign: "center"}}>{f2(orderLineLevel?.DoneApprovedQuantity || 0)}</Text>
          <Text style={{flex: 1, textAlign: "center"}}>{f2((orderLineLevel?.Quantity || 0) - (orderLineLevel?.DoneApprovedQuantity || 0))}</Text>
          <Text style={{
            flex: 1,
            textAlign: "center",
            padding: 1,
            backgroundColor: item.Quantity > 0 ? "rgba(234, 88, 12, 0.4)" : undefined,
            borderRadius: 5,
          }}>
            {hours ? getStringFromTime((item.ApprovedQuantity || 0) * 60) : f2(item.Quantity)}
          </Text>
        </View>
        {
          hours ? (
            <View style={{flex: 1, paddingHorizontal: 2}}>
              <TimePicker value={getStringFromTime((item.ApprovedQuantity || 0)*60)} onChange={_approveQuantityTime}/>
            </View>
          ) : (
            <NumberInput defaultValue={item.ApprovedQuantity} onChange={_approveQuantity} min={0} style={{flex: 1}}/>
          )
        }
        {
          hours ? (
            <View style={{flex: 1, paddingHorizontal: 2}}>
              <TimePicker value={getStringFromTime((item.BillableQuantity || 0)*60)} onChange={_billableQuantityTime}/>
            </View>
          ) : (
            <NumberInput defaultValue={item.BillableQuantity} onChange={_billableQuantity} min={0} style={{flex: 1}}/>
          )
        }
      </View>
    );
  }

  const _renderMaterial = ({item, index}: {item: Material, index: number}) => {

    const _change = (value: number) => {
      setReport({
        s: (r: Report) => {
          if (r.Materials) {
            r.Materials[index].ApprovedQuantity = value;
          }
          return r;
        },
        i: reportIndex,
      });
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
        key={`material${index}-${item.ID}`}
      >
        <View style={{width: 100}}/>
        <Text style={{flex: 4}}>{item.Name}</Text>
        <View style={{flex: 3, flexDirection: 'row', alignItems: 'center'}}>
          <View style={{flex: 3}}>
            {(item.ApprovedQuantity || 0) > approvedQuantity * (item.UnitQuantity || 0) && (
              <Text style={{color: 'red', paddingLeft: 6}}>
                {t("BUDGET_MATERIAL")}: {f2(approvedQuantity * (item.UnitQuantity || 0))}
              </Text>
            )}
          </View>
          <Text style={{flex: 1, textAlign: "center"}}>{f2(item.Quantity || 0)} {item.UnitOfMeasure}</Text>
        </View>
        
        <View style={{flex: 1, flexDirection: "row", alignItems: 'center'}}>
          <NumberInput defaultValue={item.ApprovedQuantity} onChange={_change} min={0} style={{flex: 1}}/>
          <Text style={{paddingLeft: 3}}>{item.UnitOfMeasure}</Text>
        </View>
        <View style={{flex: 1}}/>
      </View>
    );
  };

  return (
    <View 
      style={{
        backgroundColor: background,
        padding: 3,
      }}
      key={`report${report.ID}-${index}`}
    >
      <View style={{flexDirection: 'row'}}>
        <Text style={{width: 100, paddingLeft: 3}}>{report.ActivityIDString}</Text>
        <View style={{flex: 4}}>
          <HtmlText html={report.Name} maxLength={250} width={"100%"}/>
        </View>
        <Text style={{flex: 1}}>{report.UnitOfMeasure}</Text>
        <Text style={{flex: 4}} />
      </View>

      <FlatList
        data={report.Levels}
        renderItem={_renderLevel}
        key={`report-levels-${report.ID}`}
        // @ts-ignore
        listKey={`material-levels-${report.ID}`}
        keyExtractor={(item, index) => `report-levels-${report.ID}-${index}-${item.ID}`}
      />

      <View style={{flexDirection: "row", alignItems: 'center'}}>
        <View style={{width: 100}}/>
        <Text style={{flex: 4}}>{t("TIME")}</Text>
        <View style={{flex: 3, flexDirection: "row", alignItems: "center"}}>
          <View style={{flex: 3}}>
            {(report.ApprovedDuration || 0) > approvedQuantity * (report.UnitTime || 0) && (
              <Text style={{color: 'red', paddingLeft: 2}}>
                {t("BUDGET_TIME")}: {getStringFromTime(approvedQuantity * (report.UnitTime || 0))}
              </Text>
            )}
          </View>
          <Text style={{flex: 1, textAlign: "center"}}>{getStringFromTime(report.Duration || 0)}</Text>
        </View>
        <View style={{flex: 1}}>
          <TimePicker value={getStringFromTime(report.ApprovedDuration || 0)} onChange={_time}/>
        </View>
        <View style={{flex: 1}}/>
      </View>

      <FlatList
        data={report.Materials}
        renderItem={_renderMaterial}
        key={`report-material-${report.ID}`}
        // @ts-ignore
        listKey={`material-material-${report.ID}`}
        keyExtractor={(item, index) => `report-material-${report.ID}-${index}-${item.ID}`}
      />

      {report.Notes.length > 0 && (
        <View style={{flexDirection: 'row'}}>
          <Text style={{width: 100, paddingLeft: 3}}>{t("NOTES")}</Text>
          <Text style={{flex: 1, color: 'red'}}>{report.Notes}</Text>
        </View>
      )}
    </View>
  );
};

export default DayReportApproveLine;