import { Catalog, Doc, Item, Level, Line, LineLevel, Marginality, LineType, LineSumType } from '../../models'
import { t } from "i18n-js";
import { round } from '../../shared-utils/NumberUtils'
import { alert, alertConfirm } from "../../hooks/Alert";

export const DocMode = {
  CREATE: 0,
  UPDATE: 1,
};

const itemToLineTitleText = (item: Item, lineNum: number): Line => {
  return {
    ID: item.ID,
    LineType: item.Type,
    LineNum: 0,
    Name: item.Name,
    Code: item.IDString,
    InternalName: item.InternalName,
    FatherID: item.FatherID,
  } as Line;
}

const itemToLineLevelPosition = (item: Item, lineNum: number): LineLevel => {
  return {
    ID: lineNum,
    Name: item.Name,
    LineTotal: 0,
    LineSumType: LineSumType.NORMAL,
    LineTotalCalculated: 0,
    PriceCalculated: 0,
    InvoicedQuantity: 0,
    BillableQuantity: 0,
    NotBillableQuantity: 0,
    Discount: 0,
    DiscountNumeric: false,
    Quantity: 0,
    Price: Number(item.Price),
    Group: String(item.Special),
    Hours: item.Hours,
    DoneQuantity: 0,
    MaterialCost: Number(item.MaterialCost),
    FixedPriceBlock: "",
    SoldQuantity: 0,
    HumanWorkCost: Number(item.HumanWorkCost),
    MaterialRevenue: Number(item.MaterialRevenue),
    HumanWorkRevenue: Number(item.HumanWorkRevenue),
    Vat: item.VAT,
    VatID: item.VAT.ID,
  };
}

const itemToLinePosition = (item: Item, levels: Level[], lineNum: number): Line => {
  const lineLevels: LineLevel[] = [];

  for (let i = 0; i < levels.length; i++) {
    lineLevels.push(itemToLineLevelPosition(item, lineNum + i));
  }

  return {
    ID: item.ID,
    LineNum: lineNum,
    Name: item.Name,
    LineType: item.Type,
    LineLevel: lineLevels,
    Code: item.IDString,
    InternalName: item.InternalName,
    FatherID: item.FatherID,
    LineTotal: 0,
    MaterialCost: item.MaterialCost,
    Group: item.Special,
    HumanWorkRevenue: item.HumanWorkRevenue,
    MaterialRevenue: item.MaterialRevenue,
    UnitOfMeasure: item.UnitOfMeasure,
    TwinIDs: item.TwinIDs,
    Quantity: 0,
    LineTotalCalculated: 0,
    LineSumType: LineSumType.NORMAL,
    HumanWorkCost: item.HumanWorkCost,
    LineLetter: "",
    Discount: 0,
    Cost: item.Cost,
    CatalogPrice: item.Price,
    MinPrice: item.MinimumPrice,
    Block: false,
    Variable: item.Variable,
    Items: (item.Items && item.Items.length > 0) ? item.Items : null,
    PrintTwin: item.PrintTwin,
    BackgroundColor: item.BackgroundColor,
  } as Line;
}

const itemToLineFixPrice = (item: Item, lineNum: number): Line => {
  return {
    ID: item.ID,
    LineNum: lineNum,
    Name: item.Name,
    LineType: item.Type,
    Code: item.IDString,
    InternalName: item.InternalName,
    FatherID: item.FatherID,
    Group: item.Special,
    TwinIDs: item.TwinIDs,
  } as Line;
}

const itemToLine = (item: Item, levels: Level[], lineNum: number): {line: Line, lineNum: number} => {
  switch (item.Type) {
    case LineType.TITLE:
    case LineType.TEXT:
      return {
        line: itemToLineTitleText(item, lineNum),
        lineNum: lineNum + 1,
      };
    case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
    case LineType.FIXED_PRICE:
      return {
        line: itemToLineFixPrice(item, lineNum),
        lineNum: lineNum + 1,
      };
    default:
      return {
        line: itemToLinePosition(item, levels, lineNum),
        lineNum: lineNum + levels.length,
      };
  }
}

export const catalogToLines = (catalog: Catalog, levels: Level[], salesFillBoMVariable: boolean): Line[] => {
  const lines: Line[] = [];

  let lineNumG = 0;

  for (let i = 0; i < catalog.Items.length; i++) {
    if (salesFillBoMVariable || !catalog.Items[i].Variable) {
      const {
        line,
        lineNum,
      } = itemToLine(catalog.Items[i], levels, lineNumG)
      lines.push(line)
      lineNumG = lineNum
    }
  }

  return lines;
}

export const catalogGroupToCatalog = (catalog: Catalog, levels: Level[]): Catalog => ({
  ...catalog,
  Groups: catalog.Groups?.map(g => ({
    ...g,
    Positions: g.Positions.map(p => ({
      ...p,
      Levels: levels.map(() => ({
        Quantity: 0,
        Size: 0,
      }))
    })),
  })),
})

const lineRemoveLevel = (line: Line, index: number, lineNum: number): Line => {
  let lineLevel = line.LineLevel.filter((v, i) => i !== index);
  lineLevel = lineLevel.map((v, i):LineLevel => {
    return {
      ...v,
      ID: lineNum + i,
    };
  });

  return {
    ...line,
    LineNum: lineNum,
    LineLevel: lineLevel,
  };
};

export const linesRemoveLevel = (lines: Line[], index: number, length: number): Line[] => {
  let lineNum = 0;

  return lines.map((v, i) => {
    switch (lines[i].LineType) {
      case LineType.TEXT:
      case LineType.TITLE:
      case LineType.FIXED_PRICE:
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        return {
          ...lines[i],
          LineNum: lineNum++,
        };
      default:
        let l = lineRemoveLevel(lines[i], index, lineNum);
        lineNum += length;
        return l;
    }
  });
};

const lineAddLevel = (line: Line, level: Level, lineNum: number): Line => {
  const lineLevels: LineLevel[] = line.LineLevel;
  lineLevels.push({
    ID: 0,
    Name: line.Name,
    LineTotal: 0,
    LineSumType: LineSumType.NORMAL,
    LineTotalCalculated: 0,
    PriceCalculated: 0,
    InvoicedQuantity: 0,
    BillableQuantity: 0,
    NotBillableQuantity: 0,
    Discount: 0,
    DiscountNumeric: false,
    Quantity: 0,
    Price: line.CatalogPrice,
    Group: line.Group,
    Hours: line.LineLevel[0].Hours,
    DoneQuantity: 0,
    MaterialCost: line.MaterialCost,
    FixedPriceBlock: "",
    SoldQuantity: 0,
    HumanWorkRevenue: line.HumanWorkRevenue,
    HumanWorkCost: line.HumanWorkCost,
    MaterialRevenue: line.MaterialRevenue,
    Vat: line.LineLevel[0].Vat,
    VatID: line.LineLevel[0].Vat?.ID || '',
  });

  return {
    ...line,
    LineNum: lineNum,
    LineLevel: lineLevels.map((v, i):LineLevel => {
      return {
        ...v,
        ID: lineNum+i,
      };
    }),
  };
}

export const linesAddLevel = (lines: Line[], level: Level, length: number): Line[] => {
  let lineNum = 0;

  return lines.map((v, i) => {
    switch (lines[i].LineType) {
      case LineType.TEXT:
      case LineType.TITLE:
      case LineType.FIXED_PRICE:
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        return {
          ...lines[i],
          LineNum: lineNum++,
        };
      default:
        let l = lineAddLevel(lines[i], level, lineNum);
        lineNum += length;
        return l;
    }
  })
};

const lineNumFixer = (lines: Line[]): Line[] => {
  let lineNum = 0;
  for (let i = 0; i < lines.length; i++) {
    switch (lines[i].LineType) {
      case LineType.TEXT:
      case LineType.TITLE:
      case LineType.FIXED_PRICE:
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        lines[i].LineNum = lineNum;
        lineNum++;
        break;
      default:
        lines[i].LineNum = lineNum;
        for (let j = 0; j < lines[i].LineLevel.length; j++) {
          lines[i].LineLevel[j].ID = lineNum;
          lineNum++;
        }
    }
  }
  return lines;
}

export const linesAddLines = (lines: Line[], toAddLines: Line[], index: number): Line[] => {
  lines.splice(index, 0, ...toAddLines);

  return [...lineNumFixer(lines)];
};

export const linesRemoveLine = (lines: Line[], index: number): Line[] => {
  lines.splice(index, 1);

  return [...lineNumFixer(lines)]
}

export const linesAddItems = (lines: Line[], items: Item[], index: number, levels: Level[]) => {
  const toAddLines: Line[] = items.map(i => {
    const {line} = itemToLine(i, levels, 0);
    return line;
  });

  return [...linesAddLines(lines, toAddLines, index)]
}

export const newTextLine = (): Line => {
  return {
    LineType: LineType.TEXT,
    InternalName: "",
    Name: "",
    LineLevel: [] as LineLevel[],
  } as Line;
};

export const newFlatRateLine = (): Line => {
  return {
    ID: "TOT",
    Code: "TOT",
    LineType: LineType.FIXED_PRICE,
    Name: t("FLAT_RATE"),
    LineLevel: [] as LineLevel[],
    LineTotal: 0,
  } as Line;
};

export const newFlatRateEngineeringWarrantyLine = (): Line => {
  return {
    ID: "TOT_EW",
    Code: "172.391.125",
    LineType: LineType.FIXED_PRICE_ENGINEERING_WARRANTY,
    Name: t("FIXED_PRICE_ENGINEERING_WARRANTY"),
    LineTotal: 0,
  } as Line;
};

const isBlockSummableLineLevel = (lineLevel: LineLevel): boolean => {
  return lineLevel.LineSumType === LineSumType.NORMAL && (lineLevel.FixedPriceBlock === "" || lineLevel.FixedPriceBlock === "-");
}

const round5 = (n: number): number => round(n, 5);

const marginalityBlock = (doc: Doc) => {
  let engineeringCost = 0;
  let materialCost = 0;
  let humanWorkCost = 0;
  let externalWorkCost = 0;
  let warrantyCost = 0;

  let engineeringHours = 0;
  let humanWorkHours = 0;

  let minPrice = 0;
  let catalogPrice = 0;

  let warrantyRevenue = 0;
  let engineeringRevenue = 0;
  let materialRevenue = 0;
  let humanWorkRevenue = 0;
  let externalWorkRevenue = 0;

  let total = 0;

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (isBlockSummableLineLevel(doc.Lines[i].LineLevel[j])) {
            externalWorkCost += doc.Lines[i].Cost * doc.Lines[i].LineLevel[j].Quantity;
            minPrice += doc.Lines[i].MinPrice * doc.Lines[i].LineLevel[j].Quantity;
            catalogPrice += doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            externalWorkRevenue += doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            total += doc.Lines[i].LineLevel[j].Price * doc.Lines[i].LineLevel[j].Quantity;
          }
        }
        break;
      case LineType.POSITION_ENGINEERING:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l)) {
            engineeringCost += doc.Lines[i].Cost * l.Quantity;
            engineeringHours += l.Hours * l.Quantity;
            minPrice += doc.Lines[i].MinPrice * l.Quantity;
            catalogPrice += doc.Lines[i].CatalogPrice * l.Quantity;
            engineeringRevenue += doc.Lines[i].CatalogPrice * l.Quantity;
            total += doc.Lines[i].LineLevel[j].Price * doc.Lines[i].LineLevel[j].Quantity;
          }
        }
        break;
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l)) {
            materialCost += doc.Lines[i].MaterialCost * l.Quantity;
            humanWorkCost += doc.Lines[i].HumanWorkCost * l.Quantity;
            humanWorkHours += l.Hours * l.Quantity;
            minPrice += doc.Lines[i].MinPrice * l.Quantity;
            catalogPrice += doc.Lines[i].CatalogPrice * l.Quantity;
            materialRevenue += doc.Lines[i].MaterialRevenue * l.Quantity;
            humanWorkRevenue += doc.Lines[i].HumanWorkRevenue * l.Quantity;
            total += doc.Lines[i].LineLevel[j].Price * doc.Lines[i].LineLevel[j].Quantity;
          }
        }
        break;
      case LineType.POSITION_SELL_WARRANTY:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l)) {
            warrantyCost += doc.Lines[i].Cost * l.Quantity;
            warrantyRevenue += doc.Lines[i].CatalogPrice * l.Quantity;
            minPrice += doc.Lines[i].MinPrice * l.Quantity;
            catalogPrice += doc.Lines[i].CatalogPrice * l.Quantity;
            total += doc.Lines[i].LineLevel[j].Price * doc.Lines[i].LineLevel[j].Quantity;
          }
        }
        break;
    }
  }

  const offerPrice = warrantyRevenue + humanWorkRevenue + materialRevenue + engineeringRevenue + externalWorkRevenue;

  const originalWarrantyRevenue = warrantyRevenue;
  warrantyRevenue = warrantyRevenue / offerPrice * doc.Total;

  const originalEngineeringRevenue = engineeringRevenue;
  engineeringRevenue = engineeringRevenue / offerPrice * doc.Total;

  if (warrantyCost > warrantyRevenue) {
    let diff = warrantyCost - warrantyRevenue;

    if (engineeringRevenue - diff > 0) {
      engineeringRevenue -= diff;
      warrantyRevenue += diff;
    } else {
      if (engineeringRevenue > 0) {
        warrantyRevenue += engineeringRevenue;
        engineeringRevenue = 0;
      }
    }
  } else {
    if (engineeringRevenue > originalEngineeringRevenue) {
      let diff = engineeringRevenue - originalEngineeringRevenue;

      engineeringRevenue -= diff;
      warrantyRevenue += diff;
    }
  }

  const originalRevenueNoWarrantyNoEngineering = offerPrice - originalEngineeringRevenue - originalWarrantyRevenue;
  const revenueNoWarrantyNoEngineering = doc.Total - engineeringRevenue - warrantyRevenue;


  externalWorkRevenue = 0;
  materialRevenue = 0;
  humanWorkRevenue = 0;

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_SELL_WARRANTY:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l)) {
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(l.Quantity * doc.Lines[i].CatalogPrice / originalWarrantyRevenue * warrantyRevenue);
          }
        }
        break;
      case LineType.POSITION_ENGINEERING:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l)) {
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(l.Quantity * doc.Lines[i].CatalogPrice / originalEngineeringRevenue * engineeringRevenue);
          }
        }
        break;
      case LineType.POSITION:
      case LineType.POSITION_CAR:
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l) && doc.Lines[i].Quantity > 0) {
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(l.Quantity * doc.Lines[i].CatalogPrice / originalRevenueNoWarrantyNoEngineering * revenueNoWarrantyNoEngineering);
          } else {
            doc.Lines[i].LineLevel[j].LineTotalCalculated = 0
          }
        }
    }
  }

  // sum lineTotal calculated
  let tot = 0;
  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION:
      case LineType.POSITION_CAR:
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l) && doc.Lines[i].Quantity > 0) {
            tot += l.LineTotalCalculated;
          }
        }
    }
  }

  if (tot !== doc.Total) {
    // place difference between line total calculated total and total to last line

    let added = false;
    for (let i = 0; !added && i < doc.Lines.length; i++) {
      switch (doc.Lines[i].LineType) {
        case LineType.POSITION_SELL_WARRANTY:
        case LineType.POSITION_ENGINEERING:
        case LineType.POSITION:
        case LineType.POSITION_CAR:
        case LineType.POSITION_EXTERNAL_WORK:
          if (doc.Lines[i].LineLevel) {
            for (let j = 0; !added && j < doc.Lines[i].LineLevel.length; j++) {
              const l = doc.Lines[i].LineLevel[j];
              if (isBlockSummableLineLevel(l) && doc.Lines[i].Quantity > 0) {
                doc.Lines[i].LineLevel[j].LineTotalCalculated += doc.Total - tot;
                added = true;
              }
            }
          }
          break;
      }
    }
  }

  let diff = 0;
  let lineTotal = 0;
  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_EXTERNAL_WORK:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l) && doc.Lines[i].Quantity > 0) {
            lineTotal = doc.Lines[i].LineLevel[j].LineTotalCalculated;
            doc.Lines[i].LineLevel[j].PriceCalculated = round5(lineTotal / doc.Lines[i].LineLevel[j].Quantity);
            doc.Lines[i].LineLevel[j].LineTotalCalculated = doc.Lines[i].LineLevel[j].PriceCalculated * doc.Lines[i].LineLevel[j].Quantity;
            diff += lineTotal - doc.Lines[i].LineLevel[j].LineTotalCalculated;
          } else {
            doc.Lines[i].LineLevel[j].PriceCalculated = 0
            doc.Lines[i].LineLevel[j].LineTotalCalculated = 0
          }
        }
        break;
    }
  }

  // calculate position
  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION:
      case LineType.POSITION_CAR:
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (isBlockSummableLineLevel(l) && doc.Lines[i].Quantity > 0) {
            if (doc.Lines[i].LineType === LineType.POSITION_EXTERNAL_WORK) {
              externalWorkRevenue += doc.Lines[i].LineLevel[j].LineTotalCalculated;
            } else {
              materialRevenue += doc.Lines[i].MaterialRevenue * doc.Lines[i].LineLevel[j].PriceCalculated * doc.Lines[i].LineLevel[j].Quantity / doc.Lines[i].CatalogPrice;
              humanWorkRevenue += doc.Lines[i].HumanWorkRevenue * doc.Lines[i].LineLevel[j].PriceCalculated * doc.Lines[i].LineLevel[j].Quantity / doc.Lines[i].CatalogPrice;
            }
          }
        }
    }
  }

  // TODO add diff to doc object
  diff = round5(diff);
  console.log("marginality > diff", round5(diff));

  return {
    engineeringCost,
    materialCost,
    humanWorkCost,
    externalWorkCost,
    warrantyCost,
    engineeringHours,
    humanWorkHours,
    minPrice,
    catalogPrice,
    warrantyRevenue,
    engineeringRevenue,
    externalWorkRevenue,
    doc,
    materialRevenue,
    humanWorkRevenue,
  };
};

const marginalityNormalEngineeringWarranty = (doc: Doc, fixedPrice: string): Doc => {

  let warrantyRevenue = 0;
  let warrantyCost = 0;
  let engineeringRevenue = 0;
  let engineeringCost = 0;

  let revenue = 0;

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_SELL_WARRANTY:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            warrantyRevenue += doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            warrantyCost += doc.Lines[i].Cost * doc.Lines[i].LineLevel[j].Quantity;
          }
        }
        break;
      case LineType.POSITION_ENGINEERING:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            engineeringRevenue += doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            engineeringCost += doc.Lines[i].Cost * doc.Lines[i].LineLevel[j].Quantity;
          }
        }
        break;
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        if (doc.Lines[i].FixedPriceBlock === fixedPrice) {
          revenue = doc.Lines[i].LineTotal;
        }
        break;
      case LineType.POSITION:
      case LineType.POSITION_CAR:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            throw `Marginality line ${i + 1} cannot be in a block group ${fixedPrice} because is normal line`; // TODO translate
          }
        }
        break;
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            throw `Marginality line ${i + 1} cannot be in a block group ${fixedPrice} because is external work`; // TODO translate
          }
        }
        break;
      case LineType.POSITION_WORK_WARRANTY:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            throw `Marginality line ${i + 1} cannot be in a block group ${fixedPrice} because is warranty`; // TODO translate
          }
        }
        break;
    }
  }

  let total = warrantyRevenue + engineeringRevenue;

  let originalWarrantyRevenue = warrantyRevenue;
  warrantyRevenue = warrantyRevenue / total * revenue;
  warrantyRevenue = warrantyRevenue / doc.PriceBlock * doc.Total;

  let originalEngineeringRevenue = engineeringRevenue;
  engineeringRevenue = engineeringRevenue / total * revenue;
  engineeringRevenue = engineeringRevenue / doc.PriceBlock * doc.Total;

  if (warrantyCost > warrantyRevenue) {
    let diff = warrantyCost - warrantyRevenue;

    if (engineeringRevenue - diff > 0) {
      engineeringRevenue -= diff;
      warrantyRevenue += diff;
    } else {
      if (engineeringRevenue > 0) {
        warrantyRevenue += engineeringRevenue;
        engineeringRevenue = 0;
      }
    }
  } else {
    if (engineeringRevenue > originalEngineeringRevenue) {
      let diff = engineeringRevenue - originalEngineeringRevenue;

      engineeringRevenue -= diff;
      warrantyRevenue += diff;
    }
  }

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_ENGINEERING:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            const q = doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(q / originalEngineeringRevenue * engineeringRevenue);
          }
        }
        break;
      case LineType.POSITION_SELL_WARRANTY:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            const q = doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(q / originalWarrantyRevenue * warrantyRevenue);
          }
        }
        break;
    }
  }

  return doc;
};

const marginalityNormalFixedPrice = (doc: Doc, fixedPrice: string): Doc => {
  let revenue = 0;
  let total = 0;

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION:
      case LineType.POSITION_CAR:
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            total += doc.Lines[i].LineLevel[j].Quantity * doc.Lines[i].CatalogPrice;
          }
        }
        break;
      case LineType.FIXED_PRICE:
        if (doc.Lines[i].FixedPriceBlock === fixedPrice) {
          revenue = doc.Lines[i].LineTotal;
        }
        break;
      case LineType.POSITION_ENGINEERING:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            throw `Marginality line ${i + 1} cannot be in a block group ${fixedPrice} because is engineering`; // TODO translate
          }
        }
        break;
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_WORK_WARRANTY:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            throw `Marginality line ${i + 1} cannot be in a block group ${fixedPrice} because is warranty`; // TODO translate
          }
        }
        break;
    }
  }

  revenue = revenue / doc.PriceBlock * doc.Total;

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_EXTERNAL_WORK:
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].FixedPriceBlock === fixedPrice) {
            const q = doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(q / total * revenue);
          }
        }
        break;
    }
  }

  return doc;
};

const marginalityNormal = (doc: Doc) => {
  let cost = 0;
  let minPrice = 0;
  let catalogPrice = 0;
  let offerPrice = 0;

  const fixedPricesEngineeringWarranty: string[] = [];
  const fixedPrices: string[] = [];

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_EXTERNAL_WORK:
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].LineSumType === LineSumType.NORMAL) {
            cost += doc.Lines[i].Cost * doc.Lines[i].LineLevel[j].Quantity;
            minPrice += doc.Lines[i].MinPrice * doc.Lines[i].LineLevel[j].Quantity;
            catalogPrice += doc.Lines[i].CatalogPrice * doc.Lines[i].LineLevel[j].Quantity;
            if (doc.Lines[i].LineLevel[j].FixedPriceBlock === "" || doc.Lines[i].LineLevel[j].FixedPriceBlock === "-") {
              offerPrice += doc.Lines[i].LineLevel[j].LineTotal;
            }
          }
        }
        break;
      case LineType.FIXED_PRICE:
        offerPrice += doc.Lines[i].LineTotal;
        if (doc.Lines[i]?.FixedPriceBlock !== null) {
          if (!fixedPrices.includes(String(doc.Lines[i].FixedPriceBlock))) {
            fixedPrices.push(String(doc.Lines[i].FixedPriceBlock));
          }
        }
        break;
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
        offerPrice += doc.Lines[i].LineTotal;
        if (doc.Lines[i]?.FixedPriceBlock !== null) {
          if (!fixedPricesEngineeringWarranty.includes(String(doc.Lines[i].FixedPriceBlock))) {
            fixedPricesEngineeringWarranty.push(String(doc.Lines[i].FixedPriceBlock));
          }
        }
        break;
    }
  }

  for (let i = 0; i < fixedPricesEngineeringWarranty.length; i++) {
    doc = marginalityNormalEngineeringWarranty(doc, fixedPricesEngineeringWarranty[i]);
  }

  for (let i = 0; i < fixedPrices.length; i++) {
    doc = marginalityNormalFixedPrice(doc, fixedPrices[i]);
  }

  let discount = doc.Total / doc.PriceBlock;

  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_EXTERNAL_WORK:
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (isBlockSummableLineLevel(doc.Lines[i].LineLevel[j])) {
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(doc.Lines[i].LineLevel[j].LineTotal * discount);
          }
        }
        break;
    }
  }

  let engineeringCost = 0;
  let materialCost = 0;
  let humanWorkCost = 0;
  let externalWorkCost = 0;

  let warrantyCost = 0;

  let engineeringHours = 0;
  let humanWorkHours = 0;

  let warrantyRevenue = 0;
  let engineeringRevenue = 0;
  let materialRevenue = 0;
  let humanWorkRevenue = 0;
  let externalWorkRevenue = 0;

  let tot = 0;
  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION:
      case LineType.POSITION_CAR:
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (l.LineSumType === LineSumType.NORMAL && l.Quantity > 0) {
            tot += l.LineTotalCalculated;
          }
        }
    }
  }

  let diff = 0;
  let lineTotal = 0;
  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_EXTERNAL_WORK:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (l.LineSumType === LineSumType.NORMAL && doc.Lines[i].Quantity > 0) {
            lineTotal = doc.Lines[i].LineLevel[j].LineTotalCalculated;
            doc.Lines[i].LineLevel[j].PriceCalculated = round5(lineTotal / doc.Lines[i].LineLevel[j].Quantity);
            doc.Lines[i].LineLevel[j].LineTotalCalculated = round5(doc.Lines[i].LineLevel[j].PriceCalculated * doc.Lines[i].LineLevel[j].Quantity);
            diff += round5(doc.Lines[i].LineLevel[j].LineTotalCalculated - lineTotal);
          } else {
            doc.Lines[i].LineLevel[j].PriceCalculated = 0
            doc.Lines[i].LineLevel[j].LineTotalCalculated = 0
          }
        }
        break;
    }
  }

  tot = 0;
  for (let i = 0; i < doc.Lines.length; i++) {
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION:
      case LineType.POSITION_CAR:
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (l.LineSumType === LineSumType.NORMAL && l.Quantity > 0) {
            tot += l.LineTotalCalculated;
          }
        }
    }
  }

  doc.RoundingDiff = round5(diff);

  let roundedMaterialRevenue, roundedHumanWorkRevenue;
  for (let i = 0; i < doc.Lines.length; i++) {
    if (doc.Lines[i].Variable) {
      continue;
    }
    switch (doc.Lines[i].LineType) {
      case LineType.POSITION_EXTERNAL_WORK:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          if (doc.Lines[i].LineLevel[j].LineSumType === LineSumType.NORMAL) {
            externalWorkCost += doc.Lines[i].Cost * doc.Lines[i].LineLevel[j].Quantity;
            externalWorkRevenue += doc.Lines[i].LineLevel[j].LineTotalCalculated;
          }
        }
        break;
      case LineType.POSITION_ENGINEERING:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (l.LineSumType === LineSumType.NORMAL) {
            engineeringCost += doc.Lines[i].Cost * l.Quantity;
            engineeringHours += l.Hours * l.Quantity;
            engineeringRevenue += l.LineTotalCalculated;
          }
        }
        break;
      case LineType.POSITION_CAR:
      case LineType.POSITION:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (l.LineSumType === LineSumType.NORMAL) {
            materialCost += doc.Lines[i].MaterialCost * l.Quantity;
            humanWorkCost += doc.Lines[i].HumanWorkCost * l.Quantity;
            humanWorkHours += l.Hours * l.Quantity;
            roundedMaterialRevenue = round5(l.LineTotalCalculated / doc.Lines[i].CatalogPrice * doc.Lines[i].MaterialRevenue);
            roundedHumanWorkRevenue = round5(l.LineTotalCalculated / doc.Lines[i].CatalogPrice * doc.Lines[i].HumanWorkRevenue);
            if (l.LineTotalCalculated != roundedHumanWorkRevenue + roundedMaterialRevenue) {
              roundedHumanWorkRevenue += l.LineTotalCalculated - (roundedHumanWorkRevenue + roundedMaterialRevenue);
            }
            materialRevenue += roundedMaterialRevenue;
            humanWorkRevenue += roundedHumanWorkRevenue;
          }
        }
        break;
      case LineType.POSITION_SELL_WARRANTY:
        for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
          const l = doc.Lines[i].LineLevel[j];
          if (l.LineSumType === LineSumType.NORMAL) {
            warrantyRevenue += l.LineTotalCalculated;
            warrantyCost += l.Quantity * doc.Lines[i].Cost;
          }
        }
        break;
    }
  }

  return {
    engineeringCost,
    materialCost,
    humanWorkCost,
    externalWorkCost,
    warrantyCost,
    engineeringHours,
    humanWorkHours,
    minPrice,
    catalogPrice,
    warrantyRevenue,
    engineeringRevenue,
    externalWorkRevenue,
    materialRevenue,
    humanWorkRevenue,
    doc,
  };
};

export const marginality = async (doc: Doc, salesDiscount: number, salesUnderMinimumPrice: number): Promise<Marginality> => {

  let m;
  if (doc.Block) {
    m = marginalityBlock(doc);
  } else {
    m = marginalityNormal(doc);
  }

  if (m.minPrice > m.doc?.Total) {
    if (doc.Total / m.minPrice < salesUnderMinimumPrice) {
      alert("MARGINALITY", "UNDER_MINIMUM_PRICE");
      throw t("UNDER_MINIMUM_PRICE");
    } else {
      const c = await alertConfirm("MARGINALITY", "UNDER_MINIMUM_PRICE");
      if (!c) {
        throw "UNDER_MINIMUM_PRICE";
      }
    }
  }

  if (m.catalogPrice > m.doc.Total) {
    if (1 - m.doc.Total / m.catalogPrice > salesDiscount) {
      alert("MARGINALITY", "TOO_MUCH_DISCOUNT");
      throw t("TOO_MUCH_DISCOUNT");
    }
  }

  return m;
};