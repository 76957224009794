const mainColor = "#ea580c"; //warning.600
const secondaryBackground = "#a3a3a3"; //muted.400
const thirdBackground = "#f5f5f5"; //muted.100

const border = "#a3a3a3"; //muted.400

const textColorLight = "black";
//const textColorDark = "white";

const backgroundColorLight = "#e5e5e5"; //muted.200
//const backgroundColorDark = "#3f3f3f"; //muted.200

const tintColorLight = "black";
//const tintColorDark = "white";

const tabIconDefaultColorLight = "black";
//const tabIconDefaultColorDark = "white";

export default function getTheme() {
  const text = textColorLight;
  const background = backgroundColorLight;
  const tint = tintColorLight;
  const tabIconDefault = tabIconDefaultColorLight;

  const red = "#E74C3C";
  const green = "#32CD32";

  return {
    text: text,
    background: background,
    tint: tint,
    tabIconDefault: tabIconDefault,
    red,
    green,
    mainColor,
    secondaryBackground,
    thirdBackground,
    border,
    blue: 'rgb(8, 145, 178)',
  };
}
