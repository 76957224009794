import React from "react";
import { Contract } from "../../../models";
import Button from "../../Button";
import { useNavigation } from "@react-navigation/native";
import { SearchScreens } from "../../../screens/search/SearchScreens";
import getTheme from "../../../constants/Colors";

interface Props {
  contract: Contract;
}

const CreateDocButtonOpen = ({contract}:Props) => {

  const navigation = useNavigation();
  const theme = getTheme();

  const _press = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.CREATE_CONTRACT, {
      constructionSiteId: contract.ConstructionSiteID,
      contractId: contract.ID,
      docTypeId: 4,// integrative offer
    });
  }

  return (
    <Button onPress={_press} titleT='CREATE_INTEGRATIVE_OFFER' style={{backgroundColor: theme.blue}}/>
  );
};

export default CreateDocButtonOpen