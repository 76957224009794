import React from "react";
import { ListRenderItemInfo, StyleProp, ViewStyle, FlatList, View } from "react-native";

interface Props {
  sizes: number[];
  head: any[];
  rows: any[][];
  rowStyle?: StyleProp<ViewStyle>;
  headStyle?: StyleProp<ViewStyle>;
  listStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  separator?: React.ComponentType<any>;
}

const Table = ({ sizes, head, rows, rowStyle, style, headStyle, listStyle, separator }: Props) => {
  const renderItem = (info: ListRenderItemInfo<any>) => {
    return (
      <View style={[{flexDirection: 'row'}, rowStyle]}>
        {sizes.map((size, index) => (
          <View key={"tablerowcell" + info.index + "" + index} style={{flex: size}}>
            {info.item[index] || <View />}
          </View>
        ))}
      </View>
    );
  };

  return (
    <View style={style}>
      <View style={[{flexDirection: 'row'}, headStyle]}>
        {sizes.map((size, index) => (
          <View key={"tablehader" + index} style={{flex: size}}>
            {head[index] || <View />}
          </View>
        ))}
      </View>
      <FlatList
        ItemSeparatorComponent={separator}
        style={listStyle}
        data={rows}
        renderItem={renderItem}
        // @ts-ignore
        listKey={"tablelist" + rows.length}
        key={`table-list-${rows.length}`}
        keyExtractor={(item, index) => `table-list-${index}`}
      />
    </View>
  );
};

export default Table;
