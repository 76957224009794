
export const SearchScreens = {
  SEARCH: "SearchScreen",
  CLIENT_OVERVIEW: "ClientOverview",
  CLIENT_CREATE_OR_CHANGE: "CreateOrEditClientScreen",
  CONSTRUCTION_SITES_DETAILS: "ConstructionSitesDetails",
  DOC: "SEARCH_STACK_DOC",
  CREATE_CONTRACT: "SEARCH_STACK_CREATE_CONTRACT",
  CREATE_OFFER: "SEARCH_STACK_CREATE_OFFER",
  REPORT_CREATE: "SearchReportCreate",
  REPORT_UPDATE: "SearchReportUpdate",
  REPORT_APPROVE: "SearchReportApprove",
  MEASURE_CREATE: "MeasureCreate",
  MEASURE_VIEW: "MeasureView",
  CREATE_CLIENT: "CREATE_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  DUPLICATE_DOC: "DUPLICATE_DOC",
  DOCS_OFFLINE: "DOCS_OFFLINE",
  EDIT_ORDER: "EDIT_ORDER",
  INSERT_POSITION: "INSERT_POSITION",
  CREATE_POSITION: "CREATE_POSITION",
  REPORT_DRAFT: "REPORT_DRAFT",
  OFFER_TO_CHECK: "OFFER_TO_CHECK",
};