

export const formatDate = (date: Date): string => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  return `${yyyy}-${mm < 10 ? "0" : ""}${mm}-${dd < 10 ? "0" : ""}${dd}`
}

export const isValidDate = (d:any):boolean => {
  return d instanceof Date && !isNaN(d.getTime());
}

export const getWeekNumber = (d: Date) => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7))
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1))
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d.getTime() - yearStart.getTime()) / 86400000) + 1)/7)
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo]
}

export const getDateOfIsoWeek = (year: number, week: number) => {

  if (week < 1 || week > 53) {
    throw new RangeError("ISO 8601 weeks are numbered from 1 to 53")
  } else if (!Number.isInteger(week)) {
    throw new TypeError("Week must be an integer")
  } else if (!Number.isInteger(year)) {
    throw new TypeError("Year must be an integer")
  }

  const simple = new Date(year, 0, 1 + (week - 1) * 7)
  const dayOfWeek = simple.getDay()
  const isoWeekStart = simple

  // Get the Monday past, and add a week if the day was
  // Friday, Saturday or Sunday.

  isoWeekStart.setDate(simple.getDate() - dayOfWeek + 1)
  if (dayOfWeek > 4) {
      isoWeekStart.setDate(isoWeekStart.getDate() + 7)
  }

  // The latest possible ISO week starts on December 28 of the current year.
  if (isoWeekStart.getFullYear() > year ||
      (isoWeekStart.getFullYear() == year &&
       isoWeekStart.getMonth() == 11 &&
       isoWeekStart.getDate() > 28)) {
      throw new RangeError(`${year} has no ISO week ${week}`)
  }

  return isoWeekStart
}