import React, { useEffect, useState } from "react";
import { Level } from "../../../models";
import { TextInput, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeSyntheticEvent, TextInputSubmitEditingEventData } from "react-native";

interface Props {
  item: Level,
  set: (level: Level) => void;
  index: number;
  remove: (index: number) => void;
  removable: boolean;
}

const DocDetailsLevel = ({item, index, set, remove, removable}:Props) => {

  const [name, setName] = useState<string>(item.Name);

  const _name = (n: string) => {
    setName(n);
  };

  useEffect(() => setName(item.Name), [item.Name]);

  const _submit = (e:NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
    set({
      ...item,
      Name: e.nativeEvent.text,
    })
  }

  const _remove = () => {
    remove(index);
  }

  return (
    <View style={{flexDirection: 'row', paddingTop: 9, alignItems: 'center'}}>
      {
        removable ?
          <MaterialCommunityIcons
            name={"close"}
            size={24}
            onPress={_remove}
            style={{marginRight: 6}}/> :
          <View style={{width: 9}} />
      }

      <TextInput
        style={{
          flex: 1,
          backgroundColor: 'white',
          padding: 9,
          borderRadius: 6,
        }}
        value={name} onChangeText={_name}
        onSubmitEditing={_submit}
      />
    </View>
  );
};

export default DocDetailsLevel;