import { t } from "i18n-js";

const STATUS_CODE_OPEN = 0;
const STATUS_CODE_LOST = 1;
export const STATUS_CODE_OFFER = 2;
const STATUS_CODE_DONE = 3;
const STATUS_CODE_CANCELLED = 4;
const STATUS_CODE_WIN = 5;
const STATUS_CODE_NOT_AWARDED = 6;
const STATUS_CODE_OLD_VERSION = 7;
const STATUS_CODE_IN_EXECUTION = 8;
const STATUS_CODE_TO_BE_APPROVED = 9;
const STATUS_CODE_TO_BE_BILLED = 10;
const STATUS_CODE_TO_BE_PAID = 11;

export const STATUS_COLOR_OPEN = "#32CD32";
const STATUS_COLOR_LOST = "#881337";
const STATUS_COLOR_OFFER = "#0284c7";
export const STATUS_COLOR_DONE = "#ea580c";
const STATUS_COLOR_CANCELLED = "#a3a3a3";
const STATUS_COLOR_WIN = "#32CD32";
const STATUS_COLOR_OTHER = "#ea580c";
const STATUS_COLOR_NOT_AWARDED = "#90ee90";
const STATUS_COLOR_OLD_VERSION = "#ADD8E6";
const STATUS_COLOR_IN_EXECUTION = "#32CD32";
const STATUS_COLOR_TO_BE_APPROVED = "#32CD32";
const STATUS_COLOR_TO_BE_BILLED = "#32CD32";
const STATUS_COLOR_TO_BE_PAID = "#32CD32";

export const statusCodeToText = (code: number): { text: string; color: string } => {
  if (code == STATUS_CODE_OPEN) {
    return {
      text: t("STATUS_OPEN"),
      color: STATUS_COLOR_OPEN,
    };
  } else if (code == STATUS_CODE_LOST) {
    return {
      text: t("STATUS_LOST"),
      color: STATUS_COLOR_LOST,
    };
  } else if (code == STATUS_CODE_OFFER) {
    return {
      text: t("OFFER"),
      color: STATUS_COLOR_OFFER,
    };
  } else if (code == STATUS_CODE_DONE) {
    return {
      text: t("STATUS_DONE"),
      color: STATUS_COLOR_DONE,
    };
  } else if (code == STATUS_CODE_CANCELLED) {
    return {
      text: t("STATUS_CANCELLED"),
      color: STATUS_COLOR_CANCELLED,
    };
  } else if (code == STATUS_CODE_WIN) {
    return {
      text: t("STATUS_WON"),
      color: STATUS_COLOR_WIN,
    };
  } else if (code == STATUS_CODE_NOT_AWARDED) {
    return {
      text: t("STATUS_NOT_AWARDED"),
      color: STATUS_COLOR_NOT_AWARDED,
    }
  } else if (code == STATUS_CODE_OLD_VERSION) {
    return {
      text: t('STATUS_OLD_VERSION'),
      color: STATUS_COLOR_OLD_VERSION,
    }
  } else if (code == STATUS_CODE_IN_EXECUTION) {
    return {
      text: t('STATUS_IN_EXECUTION'),
      color: STATUS_COLOR_IN_EXECUTION,
    };
  } else if (code == STATUS_CODE_TO_BE_APPROVED) {
    return {
      text: t('STATUS_TO_BE_APPROVED'),
      color: STATUS_COLOR_TO_BE_APPROVED,
    };
  } else if (code == STATUS_CODE_TO_BE_BILLED) {
    return {
      text: t('STATUS_TO_BE_BILLED'),
      color: STATUS_COLOR_TO_BE_BILLED,
    };
  } else if (code == STATUS_CODE_TO_BE_PAID) {
    return {
      text: t('STATUS_TO_BE_PAID'),
      color: STATUS_COLOR_TO_BE_PAID,
    }
  } else {
    return {
      text: t("STATUS_OTHER"),
      color: STATUS_COLOR_OTHER,
    };
  }
};

export const DocStatus = {
  OPEN: {
    code: STATUS_CODE_OPEN,
    color: STATUS_COLOR_OPEN,
    text: statusCodeToText(STATUS_CODE_OPEN).text,
  },
  LOST: {
    code: STATUS_CODE_LOST,
    color: STATUS_COLOR_LOST,
    text: statusCodeToText(STATUS_CODE_LOST).text,
  },
  OFFER: {
    code: STATUS_CODE_OFFER,
    color: STATUS_COLOR_OFFER,
    text: statusCodeToText(STATUS_CODE_OFFER).text,
  },
  DONE: {
    code: STATUS_CODE_DONE,
    color: STATUS_COLOR_DONE,
    text: statusCodeToText(STATUS_CODE_DONE).text,
  },
  CANCELLED: {
    code: STATUS_CODE_CANCELLED,
    color: STATUS_COLOR_CANCELLED,
    text: statusCodeToText(STATUS_CODE_CANCELLED).text,
  },
  WIN: {
    code: STATUS_CODE_WIN,
    color: STATUS_COLOR_WIN,
    text: statusCodeToText(STATUS_CODE_WIN).text,
  },
  IN_EXECUTION: {
    code: STATUS_CODE_IN_EXECUTION,
    color: statusCodeToText(STATUS_CODE_IN_EXECUTION).color,
    text: statusCodeToText(STATUS_CODE_IN_EXECUTION).text,
  },
  TO_BE_APPROVED: {
    code: STATUS_CODE_TO_BE_APPROVED,
    color: statusCodeToText(STATUS_CODE_TO_BE_APPROVED).color,
    text: statusCodeToText(STATUS_CODE_TO_BE_APPROVED).text,
  },
  TO_BE_BILLED: {
    code: STATUS_CODE_TO_BE_BILLED,
    color: statusCodeToText(STATUS_CODE_TO_BE_BILLED).color,
    text: statusCodeToText(STATUS_CODE_TO_BE_BILLED).text,
  },
  TO_BE_PAID: {
    code: STATUS_CODE_TO_BE_PAID,
    color: statusCodeToText(STATUS_CODE_TO_BE_PAID).color,
    text: statusCodeToText(STATUS_CODE_TO_BE_PAID).text,
  },
}

export const canCreateDayReportByStatus = (status: number) => {
  switch (status) {
    case DocStatus.OPEN.code:
    case DocStatus.IN_EXECUTION.code:
    case DocStatus.TO_BE_APPROVED.code:
    case DocStatus.TO_BE_BILLED.code:
      return true;
    default:
      return false;
  }
}