import React, { useMemo } from 'react'
import { Catalog, DocType } from '../../../models'
import { useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import FlatChoose from '../../../components/FlatChoose'

interface NativeCatalogChooseProps {
  value?: Catalog
  onChange: (value: Catalog) => void
  docType: DocType
}

const NativeCatalogChoose = ({
  value,
  onChange,
  docType,
}: NativeCatalogChooseProps) => {
  const catalogs = useSelector((state: Reducer) => state.general.catalogs)

  const filtered = useMemo(() => catalogs.filter(c => {
    return docType == undefined || c.Type === docType.CatalogType
  }), [catalogs, docType])

  return (
    <FlatChoose
      options={filtered}
      value={value}
      onChange={onChange}
      itemToKey={(item) => String(item.ID)}
      itemToString={(item) => item.Name}
      size='large'
    />
  )
}

export default NativeCatalogChoose