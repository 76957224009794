import React, { useMemo } from "react";
import { Level, Line, LineLevel, LineType } from '../../models'
import { FlatList, Text, View } from "react-native";
import HtmlText from "../HtmlText";
import BillPreviewLineLevel, { getLineTotal } from "./BillPreviewLineLevel";
import getTheme from "../../constants/Colors";
import Formatter from "../../utils/formatters";

interface Props {
  levels: Level[];
  orderLine: Line;
  index: number;
  orderBlock: boolean;
  showPrice: boolean;
  hideEmpty: boolean;
}

const BillPreviewLine = ({levels, orderLine, index, orderBlock, showPrice, hideEmpty}: Props) => {

  const theme = getTheme();
    
  const background = index % 2 == 0 ? "white" : theme.background;

  const quantity = useMemo(() => 
    orderLine.LineLevel?.reduce((p, v) => 
      p + (v.BillableQuantity || 0) - (v.InvoicedQuantity || 0), 0), 
    [orderLine]
  );

  const total = useMemo(() => orderBlock ? 0 : (
    orderLine.LineLevel?.reduce((p, v) => {
      if (orderLine.LineType === LineType.TITLE) {
        return p;
      }
      if (v.FixedPriceBlock && v.FixedPriceBlock.length > 0 && v.FixedPriceBlock != "-") {
        return p;
      }
      // TODO discounts
      return p + getLineTotal(v);
    }, 0) || 0
  ), [orderLine]);

  const lineLevels = useMemo(
    () => orderLine.LineLevel.filter(l => hideEmpty || (l.BillableQuantity || 0) > 0), 
    [orderLine, hideEmpty]
  );

  if (orderLine.LineType === LineType.TITLE) {
    return (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: background,
          paddingVertical: 10,
        }}
      >
        <Text
          style={{
            width: 100,
            paddingLeft: 5,
          }}
        >{orderLine.Code}</Text>
        <View style={{flex: 4}}>
          <HtmlText html={orderLine.Name} maxLength={250} width={"100%"}/>
        </View>
        <View style={{flex: 4}}/>
      </View>
    );
  }

  if (orderLine.LineType === LineType.FIXED_PRICE || orderLine.LineType == LineType.FIXED_PRICE_ENGINEERING_WARRANTY) {
    // TODO fix calculation after clarified DRYapp #454
    return (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: background,
          paddingVertical: 10,
        }}
      >
        <Text style={{width: 100, paddingLeft: 5 }}>{orderLine.Code}</Text>
        <View style={{flex: 4}}>
          <HtmlText html={orderLine.Name} maxLength={250} width={"100%"}/>
        </View>
        <Text style={{flex: 1, textAlign: 'center'}}>{orderLine.UnitOfMeasure}</Text>
        <View style={{flex: 1}} />
        <Text style={{flex: 1, textAlign: 'right'}}>{orderLine.FixedPriceBlock}</Text>
        <Text style={{flex: 1, textAlign: 'right'}}>{showPrice ? orderLine.LineTotal : ""}</Text>
      </View>
    );
  }

  const _renderLevel = ({item, index}: {item: LineLevel, index: number}) => {
    return (
      <BillPreviewLineLevel
        lineLevel={item}
        index={index}
        levels={levels}
        orderBlock={orderBlock}
        showPrice={showPrice}
        key={`bill-preview-line${index}`}
      />
    );
  }

  return (
    <View 
      style={{
        flexDirection: 'column',
        backgroundColor: background,
        padding: 10,
      }}
      key={`report${orderLine.ID}-${index}`}
    >
      <View style={{flexDirection: 'row'}}>
        <Text style={{width: 100, paddingLeft: 5}}>{orderLine.Code}</Text>
        <View style={{flex: 4}}>
          <HtmlText html={orderLine.Name} maxLength={250} width={"100%"}/>
        </View>
        <Text style={{flex: 1, textAlign: 'center'}}>{orderLine.UnitOfMeasure}</Text>
        <Text style={{flex: 1, textAlign: 'right'}}>{showPrice ? Formatter.number2.format(quantity) : ""}</Text>
        <Text style={{flex: 1, textAlign: 'right'}}>{orderLine.LineLevel.length == 1 && Formatter.number2.format(orderLine.LineLevel[0].Price)}</Text>
        <Text style={{flex: 1, textAlign: 'right'}}>{showPrice ? Formatter.number2.format(total) : ""}</Text>
      </View>

      {orderLine.LineLevel.length > 1 && (
        <FlatList
          data={lineLevels}
          renderItem={_renderLevel}
          key={`flat-list-bill-preview-${orderLine.ID}`}
          // @ts-ignore
          listKey={`flat-list-bill-preview-${orderLine.ID}`}
          keyExtractor={(item, index) => `flat-list-bill-preview-${orderLine.ID}-${index}-${item.ID}`}
        />
      )}
    </View>
  );
};

export default BillPreviewLine;