import React, { useMemo } from 'react';
import { View, Text, ScrollView } from "react-native";
import { Doc, Line, LineSumType, LineType } from "../../../../models";
import OrderLinesHeader from "./OrderLinesHeader";
import OrderLine from "./OrderLine";
import { t } from 'i18n-js';
import KeyboardHide from "../../../../components/KeyboardHide";
import { PriceLevel } from "../../../../store/reducers/settingsReducer";
import Formatter from '../../../../utils/formatters';
import OrderValue from './OrderLine/OrderValue';

interface Props {
  order: Doc;
  largeView: boolean;
  showAsMonetaryValue: boolean;
  showPriceLevel: number;
}

const OrderLines = ({order, largeView, showAsMonetaryValue, showPriceLevel}: Props) => {

  const internal = showPriceLevel >= PriceLevel.INTERNAL

  const _lineMapper = (line: Line, index: number) => {
    return (
      <OrderLine line={line}
                 index={index}
                 key={index}
                 showAsMonetaryValue={showAsMonetaryValue}
                 largeView={largeView}
                 showPriceLevel={showPriceLevel}
                 levels={order.Levels}
      />
    );
  }

  const discounts = useMemo(() => {
    let s = ''
    if (order.Block) {
      s += `   ${t('FLAT_RATE')}`
    }
    if (order.Discounts.length > 0) {
      const total = Formatter.currency(order.Currency.ID).format(order.PriceBlock)
      const discounts = order.Discounts.map(d => d.isNumeric ? `-${d.value}` : `-${d.value}%`).join(', ')
      s = `${total}${s},   ${t('DISCOUNTS')}: ${discounts}`
    }
    return s
  }, [order])

  const sold = useMemo(() => order.Lines.reduce((sold, line) => {
    if (line.LineType != LineType.POSITION && line.LineType != LineType.POSITION_CAR) {
      return sold
    }
    return sold + line.LineLevel.reduce((sold, level) => {
      if (level.LineSumType != LineSumType.NORMAL) {
        return sold
      }
      return sold + (level.SoldQuantity || 0) * (showAsMonetaryValue ? level.Price : 1)
    }, 0)
  }, 0), [order])

  const done = useMemo(() => order.Lines.reduce((done, line) => {
    if (line.LineType != LineType.POSITION && line.LineType != LineType.POSITION_CAR) {
      return done
    }
    return done + line.LineLevel.reduce((done, level) => {
      if (level.LineSumType != LineSumType.NORMAL) {
        return done
      }
      return done + (level.DoneQuantity || 0) * (showAsMonetaryValue ? level.Price : 1)
    }, 0)
  }, 0), [order])

  const approved = useMemo(() => order.Lines.reduce((approved, line) => {
    if (line.LineType != LineType.POSITION && line.LineType != LineType.POSITION_CAR) {
      return approved
    }
    return approved + line.LineLevel.reduce((approved, level) => {
      if (level.LineSumType != LineSumType.NORMAL) {
        return approved
      }
      return approved + (level.DoneApprovedQuantity || 0) * (showAsMonetaryValue ? level.Price : 1)
    }, 0)
  }, 0), [order])

  const billed = useMemo(() => order.Lines.reduce((billed, line) => {
    if (line.LineType != LineType.POSITION && line.LineType != LineType.POSITION_CAR) {
      return billed
    }
    return billed + line.LineLevel.reduce((billed, level) => {
      if (level.LineSumType != LineSumType.NORMAL) {
        return billed
      }
      return billed + (level.InvoicedQuantity || 0) * level.Price
    }, 0)
  }, 0), [order])

  const todo = sold - done > 0 ? sold - done : 0

  return (
    <View style={{flexDirection: "column", flex: 1}}>
      <OrderLinesHeader largeView={largeView} showPriceLevel={showPriceLevel}/>
      <ScrollView>
        {order.Lines.map(_lineMapper)}
      </ScrollView>
      {(showAsMonetaryValue && internal) && (
        <View style={{flexDirection: 'row', alignItems: 'center', paddingLeft: 5}}>
          <View style={{width: 90}} />
          <View style={{flex: 4.5}} />
          
          <OrderValue value={sold} bold />

          <OrderValue value={done} bold />

          <OrderValue value={approved} show={largeView} bold />

          <OrderValue value={todo} bold />

          <OrderValue value={billed} bold />
        </View>
      )}
      <KeyboardHide>
        {showPriceLevel >= PriceLevel.CLIENT && (
          <View style={{paddingVertical: 5, justifyContent: "space-between", flexDirection: "row"}}>
            <Text>{t('TOTAL')} {order.Block && t('FLAT_RATE')}</Text>
            <Text>{discounts}</Text>
            <Text>{`${Formatter.currency(order.Currency.ID).format(order.Total)}`}</Text>
          </View>
        )}
      </KeyboardHide>
    </View>
  );
}

export default OrderLines;