import React, { useState } from "react";
import { File } from "../../../../models";
import styles from "./styles";
import { AntDesign, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { View, Text } from "react-native";
import getTheme from "../../../../constants/Colors";
import { t } from "i18n-js";
import moment from "moment";
import EditFileModal from "../../../../components/EditFileModal";
import PdfComponent from "../../../../components/PDFComponent";
import Modal from "react-native-modal";

interface Props {
  file: File;
  contractId: number;
}

const PDFView = ({file, contractId}:Props) => {
  const theme = getTheme();

  const [fullScreen, setFullScreen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const _edit = () => {
    setEditModalOpen(true);
  }

  const _fullScreen = () => {
    setFullScreen(true);
  }

  const _newVersion = () => {

  }

  return (
    <View style={styles.container}>
      <Modal
        isVisible={fullScreen} 
        onBackdropPress={() => setFullScreen(false)}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
        }}
      >
        <PdfComponent url={file.URL} />
      </Modal>

      <View style={styles.headerTextContainer}>
        <View style={styles.leftSide}>
          <View style={{flex: 1}}>
            <Text style={styles.headerText}>{file.Name}</Text>
          </View>
          <View style={{flex: 1, justifyContent: "flex-end"}}>
            <Text style={styles.normalText}>
              {moment(file.UpdatedDate).format("DD/MM/YYYY")}
            </Text>
          </View>
        </View>
        <View style={styles.rightSide}>
          <View style={{flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>

            <Text style={[styles.normalText, {textAlign: "right", paddingRight: 5}]}>
              {t("CREATED_BY")}: {file.UpdatedBy.FirstName} {file.UpdatedBy.LastName}
            </Text>

            <View style={[{backgroundColor: theme.mainColor}, styles.button]}>
              <MaterialCommunityIcons
                size={18}
                color={"white"}
                name="pencil"
                onPress={_edit}
              />
            </View>
            <View style={[{backgroundColor: theme.mainColor}, styles.button]}>
              <MaterialCommunityIcons
                size={18}
                color={"white"}
                name="fullscreen"
                onPress={_fullScreen}
              />
            </View>
          </View>
        </View>
      </View>

      <PdfComponent url={file.URL} />

      <EditFileModal isOpen={editModalOpen}
                     setOpen={setEditModalOpen}
                     file={file}
                     contractId={contractId} />
    </View>
  );
};

export default PDFView;
