import React, { useEffect, useState } from 'react'
import { StartStop } from '../../../models'
import { FlatList, Pressable, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import getTheme from '../../../constants/Colors'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { ReportsScreens } from '../ReportsScreens'
import { getStartStopKey } from '../../../utils/reports/report-utils'
import { OPEN_DAY_REPORT } from './util'
import storage from '../../../storage'

interface OfflineDayReportsProps {
  pull: () => void
}

const OfflineDayReports = ({pull}: OfflineDayReportsProps) => {

  const theme = getTheme()
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const [offlineDayReports, setOfflineDayReports] = useState<StartStop[]>([])

  const load = async () => {
    setOfflineDayReports([])
    storage
      .getAllKeys()
      .then(keys => keys.filter(k => k.startsWith(getStartStopKey(''))))
      .then(keys => keys.forEach(k => storage.getItem(k).then(value => {
        if (value) {
          const startStop: StartStop = JSON.parse(value)
          setOfflineDayReports(s => [...s, {
            ...startStop,
            dayReport: {
              ...startStop.dayReport,
              DateTime: new Date(startStop.dayReport.DateTime),
              SignedTime: startStop.dayReport.SignedTime ? new Date(startStop.dayReport.SignedTime) : undefined,
            }
          }])
        }
      })))
  }

  useEffect(() => {
    isFocused && load()
  }, [isFocused])

  const _render = ({item, index}: {item: StartStop, index: number}) => {

    const _remove = () => {
      storage.removeItem(getStartStopKey(item.uuid))
      setOfflineDayReports(d => d.filter(d => d.uuid != item.uuid))
      pull()
    }

    const _open = () => {
      storage.setItem(OPEN_DAY_REPORT, item.uuid)
      // @ts-ignore
      navigation.navigate(ReportsScreens.REPORT_DRAFT, {
        uuid: item.uuid,
      })
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 6,
          borderTopColor: theme.border,
          borderTopWidth: 1,
        }}
        key={`offline-day-report-${index}`}
      >
        <Pressable
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center'
          }}
          onPress={_open}
        >
          <View style={{flex: 1, paddingRight: 15}}>
            <Text style={{fontSize: 18}}>
              {item.dayReport.ConstructionSite.Name}
            </Text>
            <Text style={{fontSize: 16, paddingTop: 5}}>
              {item.dayReport.Contract.Name}
            </Text>
          </View>
          <View style={{alignItems: 'flex-end'}}>
            <Text style={{fontSize: 16, fontWeight: '600'}}>
              {item.dayReport.ConstructionSite.Code}
            </Text>
            <Text style={{paddingTop: 5}}>
              {item.dayReport?.DateTime?.toLocaleDateString()}
            </Text>
          </View>
        </Pressable>
        <MaterialCommunityIcons
          style={{paddingLeft: 15}}
          name='delete'
          size={24}
          onPress={_remove}
        />
      </View>
    )
  }

  return (
    <View style={{
      paddingTop: 15,
      paddingBottom: 5,
    }}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={{fontSize: 24, marginBottom: 6, flex: 1}}>
          {t("OFFLINE")}
        </Text>
      </View>
      <FlatList
        data={offlineDayReports}
        renderItem={_render}
      />
      {offlineDayReports.length == 0 && <Text>{t('NO_ITEMS_TO_SYNC')}</Text>}
    </View>
  )
}

export default OfflineDayReports