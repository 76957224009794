import React, { useMemo } from "react"
import NativeModalPicker from "../NativeModalPicker"
import { Contract } from "../../models"

interface WarrantyForDropdownProps {
  contracts: Contract[]
  warrantyForId?: number
  setWarrantyForId: (warrantyForId?: number) => void
  disabled?: boolean
}

const WarrantyForDropdown = ({
  contracts,
  warrantyForId,
  setWarrantyForId,
  disabled = false,
}: WarrantyForDropdownProps) => {

  const filtered = useMemo(() => contracts?.filter(c => !c.WarrantyFor) || [], [contracts])

  const _change = (selectedContract: Contract) => {
    if (warrantyForId == selectedContract.ID || disabled) {
      return
    }

    setWarrantyForId(selectedContract.ID)
  }

  const warrantyFor = useMemo(() => {
    return contracts?.find(c => c.ID == warrantyForId)
  }, [contracts, warrantyForId])

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
        flex: 1,
      }}
      selectedValue={warrantyFor}
      onValueChange={_change}
      options={filtered}
      placeholder='SELECT_CONTRACT'
      showLabelAsOption={true}
    />
  )
}

export default WarrantyForDropdown