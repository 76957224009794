import { DocStatus } from '../../constants/Status'
import { ConstructionSite, ConstructionSiteViewGroups, Contract } from '../../models'

export const getContractsWithOrder = (
  constructionSite: ConstructionSite,
): Contract[] => {
  return constructionSite
    .Contracts
    .filter(contract => {
      return contract
        .Docs
        .find(d => d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Order) != undefined
    })
}

export const getContractsWithOpenOrder = (
  constructionSite: ConstructionSite,
): Contract[] => {
  return getContractsWithOrder(constructionSite)
      .filter(contract => {
        switch (Number(contract.Status)) {
          case DocStatus.OPEN.code:
          case DocStatus.IN_EXECUTION.code:
          case DocStatus.TO_BE_APPROVED.code:
          case DocStatus.TO_BE_BILLED.code:
          case DocStatus.TO_BE_PAID.code:
            return true
          default:
            return false
        }
      })
}
