import { ConstructionSite, Planning, Resource, WeekReport } from "../models";
import axios from "axios";
import { DocStatus } from "../constants/Status";

/**
 * This function returns all the construction sites which have an open order but the they are not assigned.
 * The construction site should be returned only with the open contracts.
 */
export const getOpenAndNotAssignedConstructionSites = async (): Promise<ConstructionSite[]> => {
  const response = await axios.get(
    `assign/openAndNotAssignedConstructionSites?t=${Date.now()}`
  )

  if (response.status != 200) {
    throw response
  }

  return response.data?.filter((c: ConstructionSite) => c.Status == DocStatus.OPEN.code) || []
};

/**
 * This function returns all the construction sites which have an open order and are already assigned to the same resource.
 *
 * @param resourceId Id of the resource to filter.
 */
export const getAssignedAndOpenConstructionSitesByResource = async (
  resourceId: number
): Promise<ConstructionSite[]> => {
  const response = await axios.get(`assign/constructionSiteByResource?resourceID=${resourceId}`);

  if (response.status != 200) {
    throw response;
  }

  return response.data?.filter((c: ConstructionSite) => {
    switch (Number(c.Status)) {
      case DocStatus.OPEN.code:
      case DocStatus.IN_EXECUTION.code:
      case DocStatus.TO_BE_APPROVED.code:
      case DocStatus.TO_BE_BILLED.code:
      case DocStatus.TO_BE_PAID.code:
        return true;
      default:
        return false;
    }
  }) || [];
};

export const getOpenAndAssignedConstructionSites = async (): Promise<ConstructionSite[]> => {
  const response = await axios.get(`assign/getOpenAndAssignedConstructionSites?t=${Date.now()}`);
  
  if (response.status !== 200) {
    throw response;
  }

  return response.data;
}

/**
 * This function returns the assignments of a specific week.
 *
 * @param weekSelector Chooses the week starting from today (/1 last week, 0 this week, 1 next week).
 */
export const getAssignments = async (weekSelector: number = 0): Promise<Planning[]> => {
  const response = await axios.get<Planning[]>(`assignments?weekSelector=${weekSelector}&t=${Date.now()}`);

  if (response.status !== 200) {
    throw response;
  }

  return parsePlanningArrayResponse(response.data);
};

/**
 * Assignees a construction site to a resource on a given day.
 *
 * @param constructionSiteId Id of the construction site to assign.
 * @param resourceId Id of the resource to assign.
 * @param date Date to assign.
 */
export const assign = async (constructionSiteId: number, resourceId: number, date: Date) => {
  const data = {
    ConstructionSiteID: constructionSiteId,
    ResourceID: resourceId,
    Date: date,
  }

  const response = await axios.post(`assign/create`, data);

  if (response.status !== 200) {
    throw response;
  }

  return response.data;
};

/**
 * Remove a construction site from a resource on a given day.
 *
 * @param constructionSiteId Id of the construction site to remove.
 * @param resourceId Id of the resource
 * @param date Date
 */
export const remove = async (constructionSiteId: number, resourceId: number, date: Date) => {
  const data = {
    ConstructionSiteID: constructionSiteId,
    ResourceID: resourceId,
    Date: date,
  }

  const response = await axios.post(`assign/delete`, data);

  if (response.status !== 200) {
    throw response;
  }

  return response.data;
};

/**
 * Returns the my assignments for the given week.
 * The construction site should be returned only with the open contracts.
 *
 * @param weekSelector Chooses the week starting from today (/1 last week, 0 this week, 1 next week).
 */
export const getMyAssignments = async (weekSelector: number = 0): Promise<Planning> => {
  const response = await axios.get<Planning>(`assignments/my?week=${weekSelector}&t=${Date.now()}`);

  if (response.status !== 200) {
    throw response;
  }

  return parsePlanningResponse(response.data);
};

const parsePlanningArrayResponse = (a:Planning[]):Planning[] => {
  const r:Planning[] = a;

  for (let i = 0; i < r.length; i++) {
    r[i] = parsePlanningResponse(a[i])
  }

  return r;
}

const parsePlanningResponse = (p:Planning): Planning => {
  p.StartDate = new Date(p.StartDate);
  p.EndDate = new Date(p.EndDate);

  for (let j = 0; j < p.Days.length; j++) {
    p.Days[j].Date = new Date(p.Days[j].Date);
  }

  return p;
}

/**
 * This function returns the report of a week.
 *
 * @param weekSelector Chooses the week starting from today (/1 last week, 0 this week, 1 next week).
 */
export const getWeekReport = async (weekSelector: number = 0): Promise<WeekReport> => {
  const response = await axios.get<WeekReport>(`getWeekReport?weekSelector=${weekSelector}&t=${Date.now()}`);

  if (response.status !== 200) {
    throw response;
  }

  return {
    timeTargets: response.data.timeTargets.map(
      t => ({...t, date : new Date(t.date)})
    ),
    assignments: response.data.assignments.map(
      a => ({
        ...a,
        StartDate: new Date(a.StartDate),
        EndDate: new Date(a.EndDate),
        Days: a.Days.map(d => ({
          ...d,
          Date: d.Date,
          Absences: d.Absences.map(
            a => ({...a, Date: new Date(a.Date)})
          ),
        })),
      }),
    ),
  };
};