import React from "react";
import { LineType, Level, Line } from '../../../../../models'
import OrderLineTitle from "./OrderLineTitle";
import OrderLineFixedPrice from "./OrderLineFixedPrice";
import { View } from "react-native";
import OrderLinePosition from "./OrderLinePosition";
import getTheme from "../../../../../constants/Colors";

interface Props {
  index: number;
  line: Line;
  largeView: boolean;
  showAsMonetaryValue: boolean;
  showPriceLevel: number;
  levels: Level[];
}

const OrderLineParser = (props: Props) => {
  switch (props.line.LineType) {
    case LineType.TEXT:
    case LineType.TITLE:
      return <OrderLineTitle {...props}/>;
    case LineType.FIXED_PRICE:
    case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
      return <OrderLineFixedPrice />;
    case LineType.POSITION:
    case LineType.POSITION_CAR:
    case LineType.POSITION_ENGINEERING:
    case LineType.POSITION_SELL_WARRANTY:
    case LineType.POSITION_EXTERNAL_WORK:
    case LineType.POSITION_WORK_WARRANTY:
      return <OrderLinePosition {...props}/>;
    default:
      return <View/>;
  }
}

const OrderLine = (props: Props) => {
  const theme = getTheme()
  const backgroundColor = props.index % 2 == 0 ? "white" : theme.background;
  return (
    <View style={{flexDirection: "column", backgroundColor, paddingVertical: 5, paddingLeft: 5}}>
      <OrderLineParser {...props}/>
    </View>
  )
}

export default OrderLine;