import {
  ReadLevel,
  Resource,
  UoM,
  VAT,
  Branch,
  Catalog,
  Currency,
  Item,
  Language,
  DocType,
  AlertEmail,
  Country,
  Seller,
  Industry,
  AbsenceType,
  WorkGroup,
} from "../models";
import { ClientType, ConstructionSiteType, DocumentContactType } from "../models";
import axios from "axios";
import { round } from '../shared-utils/NumberUtils'

export const getCatalogs = async (showTC: boolean | undefined = false): Promise<Catalog[]> => {
  const response = await axios.get(`catalog?showTC=${showTC}&t=${Date.now()}`)

  if (response.status != 200) {
    throw response;
  }

  const r = response.data;
  for (let i = 0; i < r.length; i++) {
    for (let j = 0; j < r[i]?.Items?.length; j++) {
      if (r[i].Items[j].TwinID) {
        r[i].Items[j].TwinIDs = JSON.parse(r[i].Items[j].TwinID);
      }
    }
    if (r[i].TableView && r[i].Groups) {
      r[i].Groups = JSON.parse(r[i].Groups)
    }
  }

  return r;
};

export const getPositionsByFatherId = async (fatherId: string): Promise<Item[]> => {
  const response = await axios.get(`getPositionsByFatherId/${fatherId}?t=${Date.now()}`)

  if (response.status != 200) {
    throw response;
  }

  for (let i = 0; i < response.data.length; i++) {
    if (response.data[i].TwinID) {
      response.data[i].TwinIDs = JSON.parse(response.data[i].TwinID)
    }
  }

  return response.data;
}

export const createPosition = async (p:Item): Promise<Item> => {
  var UnitOfMeasure = p.UnitOfMeasure;
  if (typeof UnitOfMeasure == "object") {
    // @ts-ignore
    UnitOfMeasure = UnitOfMeasure.IDString
  }

  const data = {
    ...p,
    ID: -1,
    UnitOfMeasure,
    Price: round(p.Price || 0, 2),
  };
  const res = await axios.post(`catalog/create`, data);

  if (res.status !== 200) {
    throw res;
  }

  return res.data;
}

export interface BasicData {
  clientTypes: ClientType[];
  documentContactTypes: DocumentContactType[];
  constructionSitesTypes: ConstructionSiteType[];
  docTypes: DocType[];
  branches: Branch[];
  currencies: Currency[];
  languages: Language[];
  uoms: UoM[];
  readLevels: ReadLevel[];
  resource: Resource;
  resources: Resource[];
  vats: VAT[];
  countries: Country[];
  alertsEmail: AlertEmail;
  sellers: Seller[];
  industreis: Industry[];
  absenceTypes: AbsenceType[]
  workGroups: WorkGroup[]
}

export const getBasicData = async (): Promise<BasicData> => {
  const response = await axios.get(`basic?t=${Date.now()}`);

  if (response.status != 200) {
    throw response;
  }
  const r = response.data;

  for (let i = 0; i < r.docTypes.length; i++) {
    r.docTypes[i].Config = JSON.parse(r.docTypes[i].Config);
  }

  return r
};
