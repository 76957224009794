import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Sidebar from "./sidebar";
import getTheme from "../constants/Colors";
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native";

const Stack = createStackNavigator();

export type RootStackParamList = {
  SearchScreenStack: { params: {} };
};

interface Props {
  navigationRef: React.RefObject<NavigationContainerRef<RootStackParamList>>;
}

export default function StackNavigator({navigationRef}: Props) {
  const theme = getTheme();
  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator
        initialRouteName="Sidebar"
        screenOptions={{
          headerShown: false,
          cardStyle: {
            backgroundColor: theme.background,
          },
        }}
      >
        <Stack.Screen name="Sidebar" component={Sidebar} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
