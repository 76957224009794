import React, { useEffect, useState } from "react";
import { ConstructionSite, Contract, Doc, DocType } from "../../models";
import { getDocById } from "../../api/DocAPI";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { sapDocToDocConvert } from '../../shared-utils/ConversionUtils'
import { alert } from "../../hooks/Alert";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator, View } from "react-native";
import DocScreen from "./DocScreen";
import { DocMode } from "./utils";
import { logout } from "../../store/action/authAction";
import { sentryCapture } from "../../utils/sentryCapture";
import { setDocOffline } from "../../utils/doc-offline/util";

interface Props {
  route: {
    params?: {
      docId?: number;
      docType?: DocType;
    }
  }
}

const DocScreenUpdate = ({route}: Props) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [constructionSite, setConstructionSite] = useState<ConstructionSite>();
  const [contract, setContract] = useState<Contract>();
  const [doc, setDoc] = useState<Doc>();
  const [uuid, setUuid] = useState<string>()

  const catalogs = useSelector((state: Reducer) => state.general.catalogs);
  const constructionSites = useSelector((state: Reducer) => state.constructionSite.constructionSites);

  useEffect(() => {
    if (!route.params || !route.params.docId || !route.params.docType) {
      // TODO logs
      console.error("ERROR");
      return;
    }

    getDocById(route.params.docId, route.params.docType).then(sapDoc => {
      try {
        const doc = sapDocToDocConvert(sapDoc, catalogs);
        const constructionSite = constructionSites.find(cs => cs.Contracts.some(c => c.ID === doc.ContractID))
        if (!constructionSite) {
          sentryCapture({
            'source': `screens/doc/DocScreenUpdate.tsx:getDocById(${route?.params?.docId}, ${route?.params?.docType}})`,
            'error': 'construction site not found',
          });
          alert('ERROR_OPENING_DOCUMENT', 'ERROR_OPENING_DOCUMENT_DESC').then()
          navigation.goBack()
          return
        }
        const contract = constructionSite.Contracts.find(c => c.ID === doc.ContractID)
        if (!contract) {
          sentryCapture({
            'source': `screens/doc/DocScreenUpdate.tsx:getDocById(${route?.params?.docId}, ${route?.params?.docType}})`,
            'error': 'contract not found',
          });
          alert('ERROR_OPENING_DOCUMENT', 'ERROR_OPENING_DOCUMENT_DESC').then()
          navigation.goBack()
          return
        }
        setDocOffline(
          constructionSite,
          contract,
          doc,
        ).then(uuid => {
          setUuid(uuid)
          setConstructionSite(constructionSite)
          setContract(contract)
          setDoc(doc)
        }).catch(sentryCapture)
      } catch (e) {
        sentryCapture(e);
        alert("ERROR_OPENING_DOCUMENT", "ERROR_OPENING_DOCUMENT_DESC").then();
        console.error(e);
        navigation.goBack();
      }
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_LOADING_DOCUMENT");
      }
    });
  }, []);

  if (doc && contract && constructionSite) {
    return (
      <DocScreen
        initialMode={DocMode.UPDATE}
        initialConstructionSite={constructionSite}
        initialContract={contract}
        initialDoc={doc}
        uuid={uuid}
      />
    );
  }

  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ActivityIndicator />
    </View>
  );
};

export default DocScreenUpdate;