import React from 'react'
import { Level, Line, LineLevel } from '../../models'
import Modal from 'react-native-modal'
import { Text, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { t} from 'i18n-js'
import HtmlText from '../HtmlText'
import Formatter from '../../utils/formatters'
import { FlatList, ScrollView } from 'react-native-gesture-handler'
import OrderLineReportsLevel from './OrderLineReportsLevel'
import getTheme from '../../constants/Colors'

interface OrderLineReportsModalProps {
  onClose: () => void
  line: Line
  levels: Level[]
  sold: number,
  done: number,
  approved: number,
  todo: number,
  billed: number,
}

const OrderLineReportsModal = ({onClose, line, levels, sold, done, approved, todo, billed}: OrderLineReportsModalProps) => {

  const theme = getTheme()

  const f2 = Formatter.number2.format

  const _render = ({item, index}: {item: LineLevel, index: number}) => {

    return (
      <OrderLineReportsLevel
        lineLevel={item}
        level={levels[index]}
        first={index == 0}
        last={index == line.LineLevel.length - 1}
      />
    )
  }
  
  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <MaterialCommunityIcons
          onPress={onClose}
          size={34}
          color='black'
          name='close'
        />
        <Text style={{paddingHorizontal: 10,fontSize: 20}}>
          {t('ADVANCEMENTS_DETAILS_LINE')}:
        </Text>
        <Text style={{fontSize: 20, fontWeight: 'bold'}}>
          {line.Code}
        </Text>
      </View>

      <View style={{flex: 1, paddingTop: 10, paddingHorizontal: 5}}>
        <HtmlText html={line.Name} />

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 10,
          justifyContent: 'space-between'
        }}>
          <Text>{t('SOLD')}: {sold != 0 ? f2(sold) : '-'}</Text>
          <Text>{t('DONE')}: {done != 0 ? f2(done) : '-'}</Text>
          <Text>{t('APPROVED')}: {approved != 0 ? f2(approved) : '-'}</Text>
          <Text>{t('TODO')}: {todo != 0 ? f2(todo) : '-'}</Text>
          <Text>{t('BILLED')}: {billed != 0 ? f2(billed) : '-'}</Text>
        </View>
        
        <View style={{
          flexDirection: 'row',
          paddingHorizontal: 15,
          paddingTop: 10,
          paddingBottom: 10,
          borderTopColor: theme.border,
          borderTopWidth: 1,
          borderBottomColor: theme.background,
          borderBottomWidth: 1,
        }}>
          <Text style={{flex: 1, paddingRight: 5}}>{t('DATE')}</Text>
          <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{t('DAY_REPORT')}</Text>
          <Text style={{flex: 2, textAlign: 'center', paddingRight: 5}}>{t('RESOURCE')}</Text>
          <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{t('QUANTITY')}</Text>
          <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{t('APPROVED_QUANTITY')}</Text>
          <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{t('BILLABLE')}</Text>
          <Text style={{flex: 2, textAlign: 'center', paddingRight: 5}}>{t('APPROVED_BY')}</Text>
        </View>

        <ScrollView style={{flex: 1}}>
          <FlatList
            data={line.LineLevel}
            renderItem={_render}
          />
        </ScrollView>
      </View>
    </Modal>
  )
}

export default OrderLineReportsModal