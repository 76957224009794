import React, { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { DayReport } from "../../models";
import { getDayReport } from "../../api/ReportAPI";
import { ActivityIndicator, View } from "react-native";
import DayReportView from "./DayReportView";
import { DayReportMode } from "./DayReportView/DayReportMode";
import getTheme from "../../constants/Colors";
import { alert } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { useDispatch, useSelector } from "react-redux";
import { sentryCapture } from "../../utils/sentryCapture";
import { Reducer } from "../../store/reducers";
import { canEditDayReport, isWithinPreviousWeekLimit } from "../../utils/reports/report-utils";

interface Props {
  route: {
    params?: {
      dayReportId?: number;
    }
  }
}

const DayReportUpdate = (props: Props) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const theme = getTheme();

  const reportCreateLimitFromWeekStart = useSelector((state: Reducer) => state.user.ReportCreateLimitFromWeekStart)
  const reportApprove = useSelector((state: Reducer) => state.user.permissions.reportApprove)
  const reportEditTimeLimit = useSelector((state: Reducer) => state.user.ReportEditTimeLimit)

  const [dayReport, setDayReport] = useState<DayReport>();

  useEffect(() => {
    const dayReportId = props.route.params?.dayReportId;

    if (dayReportId == undefined || isNaN(dayReportId)) {
      alert("ERROR_UPDATING", "ERROR_LOADING_DAY_REPORT");
      navigation.goBack();
      return;
    }

    getDayReport(dayReportId).then(dayReport => {
      if (reportApprove == 0) {
        if (!isWithinPreviousWeekLimit(dayReport.DateTime, reportCreateLimitFromWeekStart)) {
          alert("ERROR", "DATE_BEFORE_WEEK_START");
          navigation.goBack();
          return;
        }
        if (!canEditDayReport(dayReport, reportEditTimeLimit)) {
          alert("ERROR", "DATE_BEFORE_WEEK_START");
          navigation.goBack();
          return;
        }
      }

      dayReport.Reports = dayReport.Reports?.sort(
        (a, b) => a.ActivityIDString.localeCompare(b.ActivityIDString)
      )

      setDayReport(dayReport)
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_LOADING_DAY_REPORT");
        navigation.goBack();
      }
    });
  }, [props]);

  if (!dayReport) {
    return (
      <View style={{
        zIndex: 100,
        height: "100%",
        width: "100%",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.7)"
      }}>
        <ActivityIndicator color={theme.mainColor}/>
      </View>
    );
  }

  return (
    <DayReportView mode={DayReportMode.UPDATE} initDayReport={dayReport}/>
  );
};

export default DayReportUpdate;