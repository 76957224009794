import axios from 'axios'
import {
  AccountingDocumentType,
  ConstructionSite,
  ConstructionSiteViewGroups,
  Contract,
  DayReport,
  Doc,
  Invoice,
  Level,
  Line,
  LineType,
  Measure,
  MeasureApprovedBilled,
  MeasureLevel,
  MeasureLine,
  MeasureReport,
} from '../models'
import { approveDayReport, getDayReport, unapproveDayReport } from './ReportAPI'
import { getDocById, getOrderByContractId } from './DocAPI'
import { sapDocToDocConvert } from '../shared-utils/ConversionUtils'
import store from '../store/store'
import { isPosition } from '../shared-utils/items'
import { round } from '../shared-utils/NumberUtils'

/**
 * Get invoices by contract before a certain date.
 *
 * @param contract Contract to get invoices for.
 * @param beforeDate Before date.
 * @param setProgress Update the download progress.
 * @returns Invoices before the given date for the contract.
 */
const getInvoices = async (
  contract: Contract,
  setProgress?: () => void
): Promise<Invoice[]> => {
  const invoicesData = contract.Docs.filter((doc: Doc) => {
    if (
      doc.Type.Config.ConstructionSiteViewGroup !=
      ConstructionSiteViewGroups.Invoice
    ) {
      return false
    }
    return true
  })

  return await Promise.all(
    invoicesData.map(async (invoice) => {
      // @ts-ignore
      const i = (await getDocById(invoice.ID, invoice.Type)) as Invoice
      setProgress && setProgress()
      return i
    })
  )
}

export const filterInvoicesByDate = (docs: Doc[], date?: Date): Doc[] => {
  docs = docs.filter(
    (doc) =>
      doc.Type.Config.ConstructionSiteViewGroup ==
      ConstructionSiteViewGroups.Invoice
  )
  if (!date) {
    return docs
  }
  return docs.filter((docs) => {
    const invoiceDate = new Date(docs.CreatedDate)
    return invoiceDate.getTime() <= date.getTime()
  })
}

interface MeasureData {
  order: Doc
  dayReports: DayReport[]
  invoices: Invoice[]
}

const downloadMeasureData = async (
  measure: Measure,
  setProgress: (progress: number) => void
): Promise<MeasureData> => {
  const invoicesData = filterInvoicesByDate(
    measure.contract.Docs,
    measure.createdAt
  )
  const dayReportsData =
    measure.constructionSite.Contracts.find(
      (c) => c.ID == measure.contract.ID
    )?.Reports.filter((d) => (d.measureId == 0 ? null : d.measureId) == measure.id) || []  // TODO remove null check

  let progress = 0
  const _progress = () => {
    progress += 1
    setProgress(progress / (dayReportsData.length + invoicesData.length + 1))
  }

  const orderSap = await getOrderByContractId(measure.contract.ID)
  const order = sapDocToDocConvert(orderSap, store.getState().general.catalogs)
  _progress()

  const dayReports = await Promise.all(
    dayReportsData.map(async (d, index) => {
      const dayReport = await getDayReport(d.ID)
      _progress && _progress()
      return dayReport
    })
  )

  let invoices = await getInvoices(measure.contract, _progress)

  return {
    order,
    dayReports,
    invoices,
  }
}

const orderLineToMeasureLine = (
  line: Line,
  levels: Level[],
  dayReports: DayReport[],
  invoices: Invoice[],
  approvedBilled?: MeasureApprovedBilled[]
): MeasureLine => {
  const duration = dayReports.reduce((duration, dayReport) => {
    const report = dayReport.Reports?.find(
      (report) => report.ActivityID === Number(line.LineNum)
    )
    return duration + (report?.Duration || 0)
  }, 0)
  const approvedDuration = dayReports.reduce((approvedDuration, dayReport) => {
    const report = dayReport.Reports?.find(
      (report) => report.ActivityID === Number(line.LineNum)
    )
    return approvedDuration + (report?.ApprovedDuration || 0)
  }, 0)

  const measureLevels: MeasureLevel[] =
    line.LineLevel?.map((lineLevel, lineLevelIndex) => {
      const levelId = levels[lineLevelIndex].ID

      let doneQuantityPrevious = lineLevel.DoneQuantity || 0
      let approvedQuantityPrevious = lineLevel.DoneApprovedQuantity || 0
      let billableQuantityPrevious = lineLevel.BillableQuantity || 0
      if (approvedBilled) {
        const approvedBilledLevel = approvedBilled.find(
          (ab) => ab.levelId === levelId && lineLevel.ID == ab.activityId
        )
        doneQuantityPrevious = approvedBilledLevel?.quantity || 0
        approvedQuantityPrevious = approvedBilledLevel?.approved || 0
        billableQuantityPrevious = approvedBilledLevel?.billable || 0
      }

      return {
        SoldQuantity: lineLevel.Quantity,
        DoneQuantityPrevious: doneQuantityPrevious,
        ApprovedQuantityPrevious: approvedQuantityPrevious,
        BillableQuantityPrevious: billableQuantityPrevious,
        BilledQuantity: invoices.reduce((billedQuantity, invoice) => {
          let sign =
            invoice.Type.Config.AccountingDocument ==
            AccountingDocumentType.Invoice
              ? 1
              : -1
          let invoiceLine = invoice.Lines.find(
            (l) =>
              l.OrderLineNum === Number(lineLevel.ID) && l.levelID === levelId
          )
          return billedQuantity + (invoiceLine?.Quantity || 0) * sign
        }, 0),
        Quantity: dayReports.reduce((dayReportSum, dayReport) => {
          const report = dayReport.Reports?.find(
            (report) => report.ActivityID === Number(line.LineNum)
          )
          const level = report?.Levels?.find(
            (reportLevel) => reportLevel.LevelID === levelId
          )

          return dayReportSum + (level?.Quantity || 0)
        }, 0),
        ApprovableQuantity: dayReports.reduce((dayReportSum, dayReport) => {
          const report = dayReport.Reports?.find(
            (report) => report.ActivityID === Number(line.LineNum)
          )
          const level = report?.Levels?.find(
            (reportLevel) => reportLevel.LevelID === levelId
          )

          return dayReportSum + (level?.ApprovedQuantity || 0)
        }, 0),
        BillableQuantity: dayReports.reduce((dayReportSum, dayReport) => {
          const report = dayReport.Reports?.find(
            (report) => report.ActivityID === Number(line.LineNum)
          )
          const level = report?.Levels?.find(
            (reportLevel) => reportLevel.LevelID === levelId
          )

          return dayReportSum + (level?.BillableQuantity || 0)
        }, 0),
        Level: levels[lineLevelIndex],
      }
    }) || []

  let measureReports: MeasureReport[] = dayReports
    .filter(
      (d) =>
        d.Reports?.find((r) => r.ActivityID === Number(line.LineNum)) !=
        undefined
    )
    .map((dayReport) => ({
      DayReport: dayReport,
      Report: dayReport.Reports?.find(
        (report) => report.ActivityID === Number(line.LineNum)
      )!,
    }))

  return {
    ActivityID: Number(line.LineNum),
    ActivityIDString: line.Code,
    Name: line.Name,
    Type: line.LineType,
    UnitOfMeasure: line.UnitOfMeasure,
    BackgroundColor: line.BackgroundColor,
    Duration: duration,
    ApprovedDuration: approvedDuration,
    SoldQuantity: measureLevels.reduce(
      (quantity, level) => quantity + level.SoldQuantity,
      0
    ),
    DoneQuantityPrevious: measureLevels.reduce(
      (quantity, level) => quantity + level.DoneQuantityPrevious,
      0
    ),
    ApprovedQuantityPrevious: measureLevels.reduce(
      (quantity, level) => quantity + level.ApprovedQuantityPrevious,
      0
    ),
    BillableQuantityPrevious: measureLevels.reduce(
      (quantity, level) => quantity + level.BillableQuantityPrevious,
      0
    ),
    BilledQuantity: measureLevels.reduce(
      (quantity, level) => quantity + level.BilledQuantity,
      0
    ),
    Quantity: measureLevels.reduce(
      (quantity, level) => quantity + level.Quantity,
      0
    ),
    ApprovableQuantity: measureLevels.reduce(
      (quantity, level) => quantity + level.ApprovableQuantity,
      0
    ),
    BillableQuantity: measureLevels.reduce(
      (quantity, level) => quantity + level.BillableQuantity,
      0
    ),
    Levels: measureLevels,
    MeasureReports: measureReports,
  }
}

/**
 * Get measure by ID.
 *
 * @param id ID of the measure to get.
 * @param setProgress Progress callback, value between 0 and 1.
 */
export const getMeasureById = async (
  contract: Contract,
  constructionSite: ConstructionSite,
  id: number,
  setProgress: (progress: number) => void
): Promise<Measure> => {
  const response = await axios.get(`/measure/${id}?t=${Date.now()}`)

  if (response.status != 200) {
    throw response
  }
  setProgress(0.05)

  let { order, dayReports, invoices } = await downloadMeasureData(
    {
      ...response.data,
      constructionSite,
      contract,
      createdAt: new Date(response.data.createdAt),
    },
    setProgress
  )

  const approvedBilled = response.data.approvedBilled.map(
    (ab: MeasureApprovedBilled) => ({ ...ab, levelId: Number(ab.levelId) })
  )

  const filteredInvoices = invoices.filter(
    (i) =>
      new Date(i.CreatedDate).getTime() <=
      new Date(response.data.createdAt).getTime()
  )

  const measureLines = order.Lines.map((line: Line) => {
    return orderLineToMeasureLine(
      line,
      order.Levels,
      dayReports,
      filteredInvoices,
      approvedBilled
    )
  })

  return {
    ...response.data,
    createdAt: new Date(response.data.createdAt),
    DayReports: dayReports,
    constructionSite,
    contract,
    order,
    measureLines,
    invoices,
  }
}

/**
 * Prepare new measure from contract.
 *
 * @param contract Contract to prepare measure from.
 */
export const prepareMeasure = async (
  contract: Contract,
  constructionSite: ConstructionSite,
  setProgress: (progress: number) => void
): Promise<Measure> => {
  let { order, dayReports, invoices } = await downloadMeasureData(
    {
      contract,
      constructionSite,
      createdBy: store.getState().general.resource,
      createdById: store.getState().general.resource!.ID,
      notes: '',
      createdAt: new Date(),
    } as Measure,
    setProgress
  )

  dayReports = dayReports.map((d) => ({
    ...d,
    Reports: d.Reports?.map((r) => ({
      ...r,
      ApprovedDuration: r.Duration,
      Levels: r.Levels?.map((l) => ({
        ...l,
        ApprovedQuantity: round(l.Quantity, 2),
      })),
    })),
  }))
  
  const travelsByDay = dayReports.reduce((travelsByDay, dayReport) => {
    const date = new Date(dayReport.DateTime)
    date.setHours(0, 0, 0, 0)
    if (!travelsByDay.has(date.toUTCString())) {
      travelsByDay.set(date.toUTCString(), 0)
    }
    console.log(travelsByDay, date.toUTCString())
    let travels = travelsByDay.get(date.toUTCString()) || 0
    console.log(travels)
    travels += dayReport.Reports?.reduce((travels, report) => {
      if (report.Type == LineType.POSITION_CAR) {
        return travels + (report.Levels?.reduce((travels, level) => {
          return travels + level.Quantity
        }, 0) || 0)
      }
      return travels
    }, 0) || 0
    travelsByDay.set(date.toUTCString(), travels)
    return travelsByDay
  }, new Map<string, number>())
  console.log(travelsByDay)

  dayReports = dayReports.map((dayReport) => {
    const date = new Date(dayReport.DateTime)
    date.setHours(0, 0, 0, 0)
    return {
      ...dayReport,
      Reports: dayReport.Reports?.map((report) => {
        if (report.Type == LineType.POSITION_CAR) {
          const travels = travelsByDay.get(date.toUTCString()) || 0
          console.log(travels, date.toUTCString())
          if (travels == 0) {
            return {
              ...report,
              Levels: report.Levels?.map((level) => ({
                ...level,
                BillableQuantity: 0,
              })),
            }
          }
          return {
            ...report,
            Levels: report.Levels?.map((level) => ({
              ...level,
              BillableQuantity: round(1 / travels, 2),
            })),
          }
        }
        return {
          ...report,
          Levels: report.Levels?.map((level) => ({
            ...level,
            BillableQuantity: level.Quantity,
          })),
        }
      }),
    }
  })

  const measureLines = order.Lines.map((line: Line) => {
    return orderLineToMeasureLine(line, order.Levels, dayReports, invoices)
  })

  return {
    contractId: contract.ID,
    createdBy: store.getState().general.resource!,
    createdById: store.getState().general.resource!.ID,
    notes: '',
    createdAt: new Date(),
    DayReports: dayReports,
    constructionSite,
    contract,
    order,
    measureLines,
    approvedBilled: [],
    invoices,
  }
}

/**
 * Create a new measure.
 *
 * @param measure Measure to create.
 */
export const createMeasure = async (
  measure: Measure,
  setProgress: (progress: number) => void
): Promise<void> => {
  setProgress(0)
  const dayReports = measure.DayReports

  for (let i = 0; i < measure.measureLines.length; i++) {
    if (isPosition(measure.measureLines[i])) {
      for (let j = 0; j < measure.measureLines[i].MeasureReports.length; j++) {
        const dayReportIndex = dayReports.findIndex(
          (d) => d.ID === measure.measureLines[i].MeasureReports[j].DayReport.ID
        )
        if (dayReportIndex >= 0) {
          const reportIndex = (
            dayReports[dayReportIndex].Reports || []
          ).findIndex(
            (r) => r.ID === measure.measureLines[i].MeasureReports[j].Report.ID
          )
          if (reportIndex >= 0) {
            dayReports[dayReportIndex]!.Reports![reportIndex].Levels =
              measure.measureLines[i].MeasureReports[j].Report.Levels
            dayReports[dayReportIndex]!.Reports![reportIndex].Duration =
              measure.measureLines[i].MeasureReports[j].Report.Duration
            dayReports[dayReportIndex]!.Reports![reportIndex].ApprovedDuration =
              measure.measureLines[i].MeasureReports[j].Report.ApprovedDuration
          }
        }
      }
    }
  }

  const response = await axios.post('/measure/create', {
    ...measure,
    DayReports: undefined,
    measureLines: undefined,
    contract: undefined,
    constructionSite: undefined,
  })

  if (response.status != 200) {
    throw response
  }

  setProgress(1 / (dayReports.length + 2))
  for (let i = 0; i < dayReports.length; i++) {
    await approveDayReport({
      ...dayReports[i],
      measureId: response.data.id,
      ApprovedBy: measure.createdBy,
      ApprovedByID: measure.createdById,
    }, response.data.id)
    setProgress((i + 2) / (dayReports.length + 2))
  }
}

/**
 * Delete a measure.
 *
 * @param measure Measure to delete.
 */
export const deleteMeasure = async (
  measure: Measure,
  setProgress: (progress: number) => void
): Promise<void> => {
  setProgress(0)
  for (let i = 0; i < measure.DayReports.length; i++) {
    await unapproveDayReport(measure.DayReports[i])
    setProgress((i + 1) / (measure.DayReports.length + 1))
  }

  const response = await axios.post(`/measure/delete/${measure.id}`, {
    id: measure.id,
    createdBy: measure.createdBy,
    createdById: measure.createdById,
    createdAt: measure.createdAt,
    notes: measure.notes,
    contractId: measure.contractId,
  })

  if (response.status != 200) {
    throw response
  }
}
