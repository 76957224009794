import React from "react";
import { Text, View } from "react-native";
import { t } from "i18n-js";

interface Props {
  errors: string[];
  warnings: string[];
  info: string[];
}

const ErrorWarningsLister = ({errors = [], warnings = [], info = []}:Props) => {

  const _mapper = (item: string, index: number, color: string) => {
    return (
      <Text key={`e-${index}`} style={{color}}>
        - {item}
      </Text>
    );
  }

  return (
    <View>
      { errors.length > 0 && <Text>{t('ERRORS')}</Text>}
      { errors.map((item: string, index: number) => _mapper(item, index, "red")) }
      { warnings.length > 0 && <Text>{t('WARNINGS')}</Text>}
      { warnings.map((item: string, index: number) => _mapper(item, index, "orange")) }
      { info.length > 0 && <Text>{t('INFO')}</Text>}
      { info.map((item: string, index: number) => _mapper(item, index, "blue")) }
    </View>
  );
};

export default ErrorWarningsLister;