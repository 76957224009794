import { WorkSpace } from "../../models";
import { LOGOUT, LOGIN_ERROR, LOGIN_SUCCESS, SET_FIRST_START, SET_WORK_SPACE } from "../types";

export type auth = {
  token: string;
  expire: string;
  error: string;
  firstStart: boolean;
  workSpace?: WorkSpace;
};

const initialState: auth = {
  token: "",
  expire: "",
  error: "",
  firstStart: false,
};

export default function (state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case SET_WORK_SPACE:
      return {
        ...state,
        workSpace: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        expire: action.payload.expire,
        firstStart: true,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
        error: null,
        url: null,
        firstStart: false,
        workSpace: state.workSpace,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_FIRST_START:
      return {
        ...state,
        firstStart: action.payload,
      };
    default:
      return state;
  }
}
