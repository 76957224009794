import React, { useEffect, useState } from 'react'
import { Item } from '../../models'
import { useDispatch } from 'react-redux'
import Modal from 'react-native-modal'
import { ActivityIndicator, Pressable, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { FlatList } from 'react-native-gesture-handler'
import HtmlText from '../HtmlText'
import { getPositionsByFatherId } from '../../api/GeneralAPI'
import { alert } from '../../hooks/Alert'
import { logout } from '../../store/action/authAction'
import { sentryCapture } from '../../utils/sentryCapture'
import getTheme from '../../constants/Colors'

interface Props {
  fatherId: string
  onAdd: (position: Item | Item[]) => void
  open: boolean
  onClose: () => void
}

const ChoosePositionByFather = ({ fatherId, onAdd, open, onClose }: Props) => {

  const dispatch = useDispatch()

  const theme = getTheme()

  const [positions, setPositions] = useState<Item[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    getPositionsByFatherId(fatherId)
      .then(setPositions)
      .catch(e => {
        if (e.error?.response && e.error.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR", "ERROR_CREATING_ABSENCE");
        }
      })
      .finally(() => setLoading(false))
  }, [fatherId])

  const _render = ({item, index}: {item: Item, index: number}) => {
    const _add = () => {
      let toAdd = [item, ...positions.filter(p => item.TwinIDs?.includes(p.ID))]
      onAdd(toAdd)
      onClose()
    }

    return (
      <Pressable
        onPress={_add}
        style={{
          paddingVertical: 5,
          borderTopColor: 'black',
          borderTopWidth: index == 0 ? 0 : 1
        }}
      >
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Text style={{paddingBottom: 2}}>{item.IDString}</Text>
          <Text style={{paddingBottom: 2}}>{item.UnitOfMeasure}</Text>
        </View>
        <HtmlText html={item.Name} />
      </Pressable>
    )
  }

  return (
    <Modal
      isVisible={open}
      onBackdropPress={onClose}
      style={{
        borderRadius: 15,
        backgroundColor: 'white',
        padding: 20,
        justifyContent: 'space-between',
        height: 300,
        maxHeight: 300,
        width: 500,
        alignSelf: 'center',
      }}
    >
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <Text style={{fontSize: 18, fontWeight: 'bold'}}>
          {t('ADD_SIMILAR_POSITIONS')}
        </Text>

        <MaterialCommunityIcons
          name='close'
          size={36}
          onPress={onClose}
        />
      </View>

      {loading && (
        <ActivityIndicator color={theme.mainColor} />
      )}

      <FlatList
        data={positions}
        keyExtractor={item => item.ID}
        renderItem={_render}
      />
    </Modal>
  )
}

export default ChoosePositionByFather