import React, { useEffect, useMemo, useState } from "react"
import { ActivityIndicator, Text, TextInput, View } from "react-native"
import { ConstructionSite, ConstructionSiteViewGroups, Contract, Doc, DocType, Language } from "../../models"
import { useNavigation } from "@react-navigation/native"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { t } from "i18n-js"
import { ScrollView } from "react-native"
import DocDetailsClients from "../doc/DocDetails/DocDetailsClients"
import DocDetailsDocumentContacts from "../doc/DocDetails/DocDetailsDocumentContacts"
import Button from "../../components/Button"
import getTheme from "../../constants/Colors"
import { docToSapDocConvert } from '../../shared-utils/ConversionUtils'
import { updateOrder } from "../../api/DocAPI"
import { alert } from "../../hooks/Alert"
import { useDispatch } from "react-redux"
import { logout } from "../../store/action/authAction"
import { sentryCapture } from "../../utils/sentryCapture"
import DocTypeDropdown from "../../components/DocTypeDropdown"
import DocThreeDotsLanguages from "../doc/DocHeader/DocThreeDots/DocThreeDotsLanguages"

interface EditOrderViewProps {
  route: {
    params?: {
      order?: Doc
      contract?: Contract
      constructionSite?: ConstructionSite
    }
  }
}

const EditOrderView = ({route: {params}}: EditOrderViewProps) => {

  const navigation = useNavigation()
  const theme = getTheme()
  const dispatch = useDispatch()

  const [order, setOrder] = useState<Doc | undefined>(params?.order)
  const [loading, setLoading] = useState<boolean>(false)

  const back = () => navigation.goBack()

  const hasInvoices = useMemo(() => {
    return (params
    ?.contract
    ?.Docs
    ?.filter(d => d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice)
    .length || 0) > 0
  }, [params])

  useEffect(() => {
    if (!params?.contract || !params?.constructionSite || !order) {
      back()
    }
  }, [params])

  const _save = () => {
    const allTypesSet = order?.DocumentContacts.reduce((p, v) => {
      if (v.TypeID == null || v.TypeID.length == 0 || p) {
        return true
      }
      return false
    }, false)
    if (allTypesSet) {
      alert("ERROR", "PLEASE_SET_ALL_DOCUMENT_CONTACT_TYPES")
      return
    }
    setLoading(true);
    try {
      const sapDoc = docToSapDocConvert(order!);

      updateOrder(sapDoc).then(back).catch((e) => {
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR_UPDATING", "ERROR_UPDATING_DOC");
        }
      }).finally(() => setLoading(false));
    } catch (e) {
      setLoading(false);
      sentryCapture(e);
      alert("ERROR", "ERROR_UPDATING_DOC");
    }
  }

  if (!params?.contract || !params?.constructionSite || !order) {
    return <></>
  }

  const _docType = (docType: DocType) => setOrder({
    ...order,
    Type: docType,
  })

  const _notes = (notes: string) => setOrder({
    ...order,
    Notes: notes,
  })

  const _language = (language: Language) => setOrder({
    ...order,
    Language: language,
    LanguageID: language.ID,
  })

  return (
    <View
      style={{
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <MaterialCommunityIcons name="close" size={34} onPress={back} />
        <View style={{flexDirection: 'column', paddingLeft: 15, flex: 1}}>
          <Text style={{fontSize: 20}}>
            {params.constructionSite.Code} {params.constructionSite.Name}
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={{marginTop: 5, fontSize: 17}}>
              {params.contract.Name}  -
            </Text>
            <Text style={{marginTop: 5, fontSize: 17, paddingLeft: 10, fontWeight: 'bold'}}>
              {order.IDString}
            </Text>
          </View>
        </View>
        <Button
          style={{
            backgroundColor: theme.mainColor,
          }}
          onPress={_save}
          disabled={loading}>
          {loading?<ActivityIndicator /> :<Text style={{color: 'white'}}>{t("SAVE").toUpperCase()}</Text>}
        </Button>
      </View>

      <ScrollView>
        <View style={{flexDirection: 'row', marginVertical: 15, alignItems: 'center'}}>
          <Text style={{paddingRight: 15}}>
            {t('LANGUAGE')}:
          </Text>
          <DocThreeDotsLanguages
            language={order.Language}
            setLanguage={_language}
          />
          {!hasInvoices && (<>
            <Text style={{paddingHorizontal: 15}}>
              {t('TYPE')}:
            </Text>
            <DocTypeDropdown
              type={order.Type}
              setType={_docType}
              orderDocType={true}
            />
          </>)}
        </View>
        <DocDetailsClients doc={order} setDoc={setOrder} clientLocked={true} />
        <DocDetailsDocumentContacts doc={order} setDoc={setOrder} useSoftDelete={true} />

        <Text style={{fontSize: 20, marginTop: 15}}>
          {t('NOTES')}
        </Text>
        <TextInput
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            padding: 10,
            marginTop: 10,
            height: 100,
            textAlignVertical: 'top',
          }}
          placeholderTextColor={theme.secondaryBackground}
          placeholder={t('NOTES')}
          multiline={true}
          value={order.Notes}
          onChangeText={_notes}
        />
      </ScrollView>
    </View>
  )
}

export default EditOrderView