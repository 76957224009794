import React from 'react';
import { View, Text } from "react-native";
import { t } from 'i18n-js';
import { PriceLevel } from "../../../../store/reducers/settingsReducer";

interface Props {
  largeView: boolean;
  showPriceLevel: number;
}

const Title = ({title, show = true}: {title: string, show?: boolean}) => show ? (
  <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{t(title)}</Text>
) : <></>

const OrderLinesHeader = ({largeView, showPriceLevel}: Props) => {

  const internal = showPriceLevel >= PriceLevel.INTERNAL;
  const client = showPriceLevel >= PriceLevel.CLIENT;

  return (
    <View style={{flexDirection: "row", paddingLeft: 5}}>
      <Text style={{width: 90}}>{t('CODE')}</Text>
      <View style={{flex: 2.5}} />
      <Title title='PRICE' show={internal && client} />
      <Text style={{flex: 1, paddingRight: 5, textAlign: 'center'}}>{t('UNIT_OF_MEASURE')}</Text>
      <Title title='SOLD' />
      <Title title='DONE' />
      <Title title='APPROVED' show={internal && largeView} />
      <Title title='TODO' />
      <Title title='BILLED' show={internal || client} />
    </View>
  )
}

export default OrderLinesHeader