import axios from "axios";

export const checkServerStatus = async (): Promise<boolean> => {
  try {
    const response = await axios
      .get("files/active.txt", { headers: { "Cache-Control": "no-cache" }, timeout: 5000 })
      .then((r) => {
        if (r.status != 200) {
          throw "files/active.txt gave response: " + JSON.stringify(r);
        }
        return r;
      })
      .catch((e) => {
        throw e;
      });

    return response.data.status === "ok";
  } catch (e) {
    return false;
  }
};
