import { Catalog, ConstructionSite, Contract, Doc, DocOfflineMetaData } from '../../models'
import { v4 as uuidV4 } from 'uuid'
import { alert } from '../../hooks/Alert'
import storage from '../../storage'

const DOCS_OFFLINE = 'DOCS_OFFLINE'

const getDocOfflineStorageKey = (uuid: string): string => {
  return `docs-offline-${uuid}`
}

export const getDocsOfflineMetadata = async (): Promise<DocOfflineMetaData[]> => {
  const d = await storage.getItem(DOCS_OFFLINE)
  const json = JSON.parse(d || '[]')

  return json.map((d: DocOfflineMetaData) => ({
    ...d,
    createdAt: new Date(d.createdAt),
  }))
}

export const removeDocOffline = async (uuid: string) => {
  await storage.removeItem(getDocOfflineStorageKey(uuid))
  const docsOffline = await getDocsOfflineMetadata()
  const newDocsOffline = docsOffline.filter(d => d.uuid !== uuid)
  await storage.setItem(DOCS_OFFLINE, JSON.stringify(newDocsOffline))
}

export const setDocOffline = async (
  constructionSite: ConstructionSite,
  contract: Contract,
  doc: Doc,
  uuid?: string,
): Promise<string> => {

  let localUuid = uuid || uuidV4()
  let key = getDocOfflineStorageKey(localUuid)

  const docsOffline = await getDocsOfflineMetadata()
  if (uuid) {
    const docsOfflineIndex = docsOffline.findIndex(d => d.uuid === uuid)
    if (docsOfflineIndex > -1) {
      docsOffline[docsOfflineIndex].catalogName = doc?.Catalog?.Name
      docsOffline[docsOfflineIndex].constructionSiteName = constructionSite?.Name
      docsOffline[docsOfflineIndex].constructionSiteCode = constructionSite.Code ? constructionSite.Code : (constructionSite?.IDString || '')
      docsOffline[docsOfflineIndex].contractName = contract?.Name
      docsOffline[docsOfflineIndex].client = doc?.Client?.Name
      docsOffline[docsOfflineIndex].docTypeName = doc?.Type?.Name
    }
  } else {
    docsOffline.push({
      uuid: localUuid,
      catalogName: doc?.Catalog?.Name,
      constructionSiteName: constructionSite?.Name || '',
      constructionSiteCode: constructionSite.Code ? constructionSite.Code : (constructionSite?.IDString || ''),
      contractName: contract?.Name,
      client: doc?.Client?.Name,
      docTypeName: doc?.Type?.Name,
      createdAt: new Date(),
    })
  }

  await storage.setItem(DOCS_OFFLINE, JSON.stringify(docsOffline))
  await storage.setItem(key, JSON.stringify({
    constructionSite: {
      ...constructionSite,
      Contracts: constructionSite?.Contracts?.map(m => ({
        ...m,
        Docs: [],
        Reports: [],
        FileTypes: [],
        measures: [],
      })),
    },
    contract: {...contract, Reports: [], Docs: [], FileTypes: [], measures: []},
    doc: {...doc, Catalog: undefined},
  }))

  return localUuid
}

interface DocOffline {
  constructionSite: ConstructionSite
  contract: Contract
  doc: Doc
}

export const getDocOffline = async (uuid: string, catalogs: Catalog[]): Promise<DocOffline | null> => {
  const key = getDocOfflineStorageKey(uuid)
  const data = await storage.getItem(key)
  if (!data) {
    return null
  }
  if (!catalogs) {
    alert('ERROR', 'ERROR_OPENING_OFFLINE_DOC')
    return null
  }
  
  const json = JSON.parse(data)
  
  const catalog = catalogs.find(c => c.ID === json.doc.CatalogID)

  return {
    constructionSite: json.constructionSite,
    contract: json.contract,
    doc: {
      ...json.doc,
      Catalog: catalog,
    }
  }
}