import { View, Text } from "react-native";
import React from "react";

import { Level, Line, LineLevel, LineType } from '../../../../models'
import HtmlText from "../../../../components/HtmlText";
import DocumentPreviewLineLevel from "./DocumentPreviewLineLevel";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import Formatter from "../../../../utils/formatters";
import getTheme from "../../../../constants/Colors";


interface Props {
  line: Line;
  levels: Level[];
  largeView: boolean;
  index: number;
  blockOffer: boolean;
}

const DocumentPreviewLine = ({line, levels, largeView, index, blockOffer}: Props) => {

  const theme = getTheme()

  const showPriceLevel = useSelector((state:Reducer) => state.settings.showPriceLevel);

  const _lineLevelMapper = (lineLevel: LineLevel, index: number) => {
    const level = levels[index];

    return (
      <DocumentPreviewLineLevel line={line} lineLevel={lineLevel} blockOffer={blockOffer} level={level} largeView={largeView} key={`${lineLevel.ID}-${index}-previewLineLevel`}/>
    );
  };

  const bgColor = index % 2 == 0 ? theme.background : 'white';

  if (line.LineType == LineType.FIXED_PRICE || line.LineType == LineType.FIXED_PRICE_ENGINEERING_WARRANTY) {
    // TODO check that line.LineTotal is the ritht property
    return (
      <View
        key={line.ID}
        style={{
          flexDirection: "row",
          paddingTop: 6,
          paddingBottom: 3,
          backgroundColor: bgColor,
          borderRadius: 5,
          paddingHorizontal: 2,
        }}
      >
        <Text style={{flex: 1}}>{line.Code}</Text>
        <View style={{flex: 2, paddingLeft: 1}}>
          <HtmlText html={line.Name} maxLength={50}/>
        </View>
        <View style={{flex: 2}}/>
        {largeView && <View style={{flex: 1}}/>}
        <Text style={{flex: 1}}>{showPriceLevel > 0 && Formatter.number2.format(line.LineTotal)}</Text>
        {largeView && <View style={{flex: 1}}/>}
      </View>
    );
  }

  return (
    <View
      key={`${line.ID}-${index}-previewLine`}
      style={{
        flexDirection: "column",
        paddingTop: 6,
        paddingBottom: 3,
        backgroundColor: bgColor,
        borderRadius: 5,
        paddingHorizontal: 2,
      }}
    >
      <View style={{flexDirection: "row"}}>
        <Text style={{flex: 1}}>{line.Code}</Text>
        <View style={{flex: 2, paddingLeft: 1}}>
          <HtmlText html={line.Name} maxLength={50}/>
        </View>
        <View style={{flex: 4}}/>
        {largeView && <View style={{flex: 2}}/>}
      </View>

      {line.LineLevel?.map(_lineLevelMapper)}
    </View>
  );
}

export default DocumentPreviewLine;