import React from "react";
import { t } from "i18n-js";
import { Doc, Level } from "../../../models";
import getTheme from "../../../constants/Colors";
import DocDetailsLevel from "./DocDetailsLevel";
import { FlatList, Switch, Pressable, Text, View } from "react-native";
import { linesAddLevel, linesRemoveLevel } from "../utils";

interface Props {
  doc: Doc,
  setDoc: React.Dispatch<React.SetStateAction<Doc>>;
}

const DocDetailsLevels = ({doc, setDoc}: Props) => {

  const theme = getTheme();

  const _new = () => {
    const _press = () => {
      let id = 0;
      for (let i = 0; i < doc.Levels.length; i++) {
        if (doc.Levels[i].ID < id) {
          id = doc.Levels[i].ID;
        }
      }
      id--;
      const level: Level = {ID: id, Name: ""};
      const lines = doc.Lines ? linesAddLevel(doc.Lines, level, doc.Levels.length + 1) : doc.Lines;
      const catalog = (doc.Catalog && doc.Catalog.TableView && doc.Catalog.Groups )? {
        ...doc.Catalog,
        Groups: doc.Catalog.Groups.map(g => ({
          ...g,
          Positions: g.Positions.map(p => ({
            ...p,
            Levels: p.Levels ? [
              ...p.Levels,
              {Quantity: 0, Size: 0},
            ] : p.Levels,
          }))
        }))
      } : doc.Catalog;
      setDoc({
        ...doc,
        Levels: [
          ...doc.Levels,
          level,
        ],
        Catalog: catalog,
        Lines: lines,
      });
    };

    return (
      <Pressable
        onPress={_press}
        style={{
          flex: 1,
          padding: 6,
          alignItems: 'center',
          borderWidth: 1,
          borderColor: theme.border,
          borderRadius: 5,
          marginTop: 5,
        }}
      >
        <Text>+ {t('NEW_LEVEL')}</Text>
      </Pressable>
    );
  };

  const _remove = (index: number) => {
    setDoc(old => {
      if (old.Levels.length > 1) {
        const levels = old.Levels;
        levels.splice(index, 1);
        const lines = linesRemoveLevel(old.Lines, index, levels.length);
        const catalog = (doc.Catalog && doc.Catalog.TableView && doc.Catalog.Groups )? {
          ...doc.Catalog,
          Groups: doc.Catalog.Groups.map(g => ({
            ...g,
            Positions: g.Positions.map(p => ({
              ...p,
              Levels: p.Levels?.splice(index, 1),
            }))
          }))
        } : doc.Catalog;
        return {
          ...old,
          Catalog: catalog,
          Levels: levels,
          Lines: lines,
        };
      } else {
        return old;
      }
    });
  };

  const _render = ({item, index}: {item: Level, index: number}) => {
    const set = (item: Level) => {
      setDoc(old => {
        if (!old) return old;
        return {
          ...old,
          Levels: old.Levels.map((v,i) => {
            if (i === index) {
              return item;
            }
            return v;
          })
        };
      });
    };

    return (
      <DocDetailsLevel item={item}
                       set={set}
                       index={index}
                       remove={_remove}
                       removable={doc.Levels.length > 1}/>
    );
  };

  const _printLevels = (p: boolean) => {
    setDoc(old => {
      if (!old) return old;
      return {
        ...old,
        PrintLevels: p,
      };
    });
  };

  return (
    <View style={{paddingTop: 15}}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={{fontSize: 20, flex: 1}}>{t("LEVELS")}</Text>
        <Text style={{marginRight: 6}}>{t("LEVEL_PRINT_FLAG")}:</Text>
        <Switch value={doc.PrintLevels} onValueChange={_printLevels} />
      </View>
      <FlatList
        data={doc.Levels}
        renderItem={_render}
        ListFooterComponent={_new}
        key={`doc-levels`}
        //@ts-ignore
        listKey={`doc-levels`}
        keyExtractor={(item, index) => `doc-levels-${index}-${item.ID}`}
      />
    </View>
  );
}

export default DocDetailsLevels;