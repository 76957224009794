import { MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { View, Text } from 'react-native'
import Modal from 'react-native-modal'
import { t } from 'i18n-js'

interface MeasureHelpModalProps {
  onClose: () => void
}

const MeasureHelpModal = ({ onClose }: MeasureHelpModalProps) => {
  return (
    <Modal
      isVisible={true}
      onBackButtonPress={onClose}
      style={{
        padding: 15,
        borderRadius: 15,
        backgroundColor: 'white'
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={onClose}
        />
        <Text style={{ paddingLeft: 15, fontSize: 22 }}>{t('MEASURE_HELP')}</Text>
      </View>
      <View style={{ flex: 1, paddingTop: 15, paddingHorizontal: 5 }}>
        <Text>{t('MEASURE_HELP_DESC')}</Text>
      </View>
    </Modal>
  )
}

export default MeasureHelpModal