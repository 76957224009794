import { RFValue } from "react-native-responsive-fontsize";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    padding: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  leftSide: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  rightSide: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  horizontalContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
  },
  advancedSwitchLabelStyle: {
    paddingEnd: RFValue(10),
  },
  paginationListcontainer: {
    flex: 1,
    width: "90%",
  },
  constructionSiteCustomHeaderStyle: {
    flexDirection: "row",
    padding: 10,
    width: "100%",
  },
  constructionSiteCustomHeaderText: {
    fontWeight: "bold",
  }
});

export default styles;
