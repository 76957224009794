import React, { useState } from "react";
import { Text, TextInput, View } from "react-native";
import { File, ReadLevel } from "../../models";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import ReadLevelSelect from "../UploadNewDocumentModal/ReadLevelSelect";
import getTheme from "../../constants/Colors";
import { updateFileData } from "../../api/FileAPI";
import Modal from "react-native-modal";
import Button from "../Button";
import { sentryCapture } from "../../utils/sentryCapture";
import { alert } from "../../hooks/Alert";

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  file: File;
  contractId: number;
}

const EditFileModal = ({isOpen, setOpen, file, contractId}:Props) => {

  const theme = getTheme();

  const [readLevel, setReadLevel] = useState<ReadLevel>(file.ReadLevel);
  const [name, setName] = useState<string>(file.Name);

  const _close = () => {
    setOpen(false);
  }

  const _save = async  () => {
    try {
      await updateFileData(file.ID, file.FileTypeID, name, readLevel.ID, contractId);
      _close();
    } catch (e) {
      sentryCapture(e);
      alert("ERROR", "ERROR_UPDATING_FILE");
    }
  }

  return (
    <Modal
      isVisible={isOpen}
      onBackdropPress={_close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
        flex: undefined,
      }}
    >
      <View style={{flexDirection: 'row'}}>
        <MaterialCommunityIcons
          size={24}
          color='black'
          name='close'
          onPress={_close}
        />
      </View>
      <View style={{flexDirection: 'row'}}>
        <Text style={{paddingTop: 9, paddingRight: 15}}>{t('READ_LEVEL')}</Text>
        <ReadLevelSelect readLevel={readLevel} setReadLevel={setReadLevel}/>
      </View>

      <View style={{flexDirection: 'row', paddingTop: 9}}>
        <Text style={{paddingTop: 3, paddingRight: 15}}>{t('NAME')}</Text>
        <TextInput
          value={name}
          onChangeText={setName}
          style={{
            flex: 1,
            padding: 6,
            borderRadius: 6,
            borderColor: theme.border,
          }}
        />
      </View>

      <Button
        onPress={_save}
        style={{
          marginTop: 6,
          backgroundColor: theme.mainColor,
        }}
        titleT='SAVE'
      />
    </Modal>
  );
};

export default EditFileModal;