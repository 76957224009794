import AsyncStorage from '@react-native-async-storage/async-storage'
import localforage from 'localforage'
import { Platform } from 'react-native'

const storage = {
  getItem: async (key: string): Promise<string | null> => {
    if (Platform.OS === 'web') {
      return localforage.getItem(key)
    } else {
      return AsyncStorage.getItem(key)
    }
  },
  setItem: async (key: string, value: string) => {
    if (Platform.OS === 'web') {
      return localforage.setItem(key, value)
    } else {
      return AsyncStorage.setItem(key, value)
    }
  },
  removeItem: async (key: string) => {
    if (Platform.OS === 'web') {
      return localforage.removeItem(key)
    } else {
      return AsyncStorage.removeItem(key)
    }
  },
  clear: async () => {
    if (Platform.OS === 'web') {
      return localforage.clear()
    } else {
      return AsyncStorage.clear()
    }
  },
  getAllKeys: async () => {
    if (Platform.OS === 'web') {
      return localforage.keys()
    } else {
      return AsyncStorage.getAllKeys()
    }
  },
}

export default storage