import React, { useState } from "react";
import translations from "./constants/translations";
import * as Localization from "expo-localization";
import I18n from "i18n-js";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Reducer } from "./store/reducers";
import AppLoader from "./AppLoader";
import AuthScreen from "./screens/auth/AuthScreen";
import { ActivityIndicator, View } from "react-native";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import getTheme from "./constants/Colors";
import { setUser as setSentryUser } from "@sentry/react-native"

I18n.translations = translations;
I18n.locale = Localization.locale;
I18n.fallbacks = true;

export default function AppContent() {
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets()
  const theme = getTheme()

  const token = useSelector((state: Reducer) => state.auth.token);
  const expire = useSelector((state:Reducer) => state.auth.expire);
  const workSpace = useSelector((state:Reducer) => state.auth.workSpace);

  const user = useSelector((state: Reducer) => state.user)

  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    if (new Date(expire) > new Date() && token != null && token.length > 0 && workSpace) {
      axios.defaults.headers.common["Content-Type"] = "application/json";
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
    setLoading(false);
  }, [token, expire, workSpace]);

  React.useEffect(() => {
    if (user.username == '') {
      setSentryUser(null)
    } else {
      setSentryUser({
        id: user.username,
        username: `${user.name} - ${user.company}`
      })
    }
  }, [user])

  if (loading) return <ActivityIndicator />

  if (token != null && token.length > 0 && new Date(expire) > new Date() && (workSpace?.url?.length || 0) > 0) {
    axios.defaults.baseURL = workSpace?.url;
    axios.defaults.headers.common["Authorization"] = token;
    // Logged in: Show navigator
    return (
      <View style={{
        backgroundColor: theme.thirdBackground,
        paddingTop: insets.top,
        paddingRight: insets.right,
        paddingBottom: insets.bottom,
        paddingLeft: insets.left,
        flex: 1,
      }}>
        <AppLoader />
      </View>
    );
  } else {
    // Not logged in: Show login page
    return (
      <AuthScreen />
    );
  }
}
