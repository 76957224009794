import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Doc, Item, Level, Line, LineType } from '../../../models'
import { View } from "react-native";
import DocLineTitle from "./DocLineTitle";
import DocLineText from "./DocLineText";
import getTheme from "../../../constants/Colors";
import DocLineThreeDotsModal from "./DocLineThreeDotsModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import DocLinePosition from "./DocLinePosition";
import DocLineFixedPrice from "./DocLineFixedPrice";
import { useNavigation } from "@react-navigation/native";
import { SearchScreens } from "../../search/SearchScreens";
import { PrintTwinLevel } from "./utils";

type Props = {
  line: Line;
  setLine: (line: Line) => void;
  deleteLine: () => void;
  addLine: (line: Line) => void;
  addItem: (item: Item | Item[], keepOrder: boolean) => void;
  index: number;
  levels: Level[];
  addPriceFixBlock: () => void;
  letters: string[];
  doc?: Doc;
  setTwinsPrices: (lineIndex: number, levelIndex: number, price: number, discount: number, discountNumeric: boolean) => void
  twinLinesNoPrint?: Line[]
}

type DocLineParseProps = Omit<Props, 'setLine'> & {
  setLine: (setL:(old:Line)=>Line) => void;
}
const areEqual = (p: Readonly<Props>, n:Readonly<Props>) => {
  return JSON.stringify(p) === JSON.stringify(n) && p.line == n.line;
}

const DocLineParse = (props: DocLineParseProps & {priceNoPrintByLevels?: PrintTwinLevel[]}) => {

  switch (props.line.LineType) {
    case LineType.TITLE:
      return <DocLineTitle {...props} />;
    case LineType.TEXT:
      return <DocLineText {...props} />;
    case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
    case LineType.FIXED_PRICE:
      return <DocLineFixedPrice {...props} />;
    default :
      return <DocLinePosition {...props} />;
  }
};

const DocLine = React.memo((props:Props) => {

  const theme = getTheme();
  const navigation = useNavigation()

  const [
    docLineThreeDotsModalVisible,
    setDocLineThreeDotsModalVisible
  ] = useState<boolean>(false);
  const _insertPositionView = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.INSERT_POSITION, {
      showCreate: true,
      onAdd: _addItem,
      initialCatalogId: props.doc?.CatalogID,
    })
  }

  const [line, setLine] = useState<Line>(props.line);

  const priceNoPrintByLevels = useMemo(() => {
    if (line.LineLevel == undefined) {
      return undefined
    }
    let levelsPrices:PrintTwinLevel[] = []
    props.levels.map((_, index) => {
      let price = line.LineLevel[index]?.Price || 0
      let discount = line.LineLevel[index]?.Discount || 0
      let discountNumeric = line.LineLevel[index]?.DiscountNumeric || false
      props.twinLinesNoPrint?.map((line) => {
        price += line.LineLevel[index]?.Price
        if (line.LineLevel[index]?.DiscountNumeric) {
          discount += line.LineLevel[index]?.Discount
        }
      })
      const lineTotal = props.line.LineLevel[index]?.DiscountNumeric ?
        price * props.line.LineLevel[index]?.Quantity - discount :
        price * props.line.LineLevel[index]?.Quantity * (1 - discount / 100)
      levelsPrices.push({price, discount, discountNumeric, lineTotal})
    })
    if (props.twinLinesNoPrint) {
      return levelsPrices
    }
    return undefined
  }, [props.twinLinesNoPrint])

  useEffect(() => {
    setLine(props.line);
  }, [props.line]);

  const _setLine = (setL: (old:Line) => Line) => {
    setLine(old => {
      const v = setL(old);
      props.setLine(v);
      return v;
    })
  }

  const _threeDots = useCallback(() => {
    setDocLineThreeDotsModalVisible(true);
  }, []);

  const background = props.index % 2 == 0 ? 'white' : theme.background;

  const _addItem = useCallback((item: Item | Item[], keepOrder: boolean) => {
    props.addItem(item, keepOrder);
  }, []);

  const setTwinsPrices = (levelIndex: number, price: number, discount: number, discountNumeric: boolean) => {
    props.setTwinsPrices(props.index, levelIndex, price, discount, discountNumeric)
  }

  return (
    <View 
      style={{
        flexDirection: 'row',
        paddingVertical: 6,
        paddingHorizontal: 9,
        backgroundColor: background,
      }}
    >
      {docLineThreeDotsModalVisible && (
        <DocLineThreeDotsModal
          line={line}
          setLine={_setLine}
          addLine={props.addLine}
          levels={props.levels}
          visible={docLineThreeDotsModalVisible}
          setVisible={setDocLineThreeDotsModalVisible}
          navigateToInsertPositionView={_insertPositionView}
          setTwinsPrices={setTwinsPrices}
          priceNoPrintByLevels={priceNoPrintByLevels}
        />
      )}

      <DocLineParse
        {...props}
        line={line}
        setLine={_setLine}
        priceNoPrintByLevels={priceNoPrintByLevels}
      />

      <View style={{alignItems: 'center'}}>
        <MaterialCommunityIcons
          name={"delete"}
          size={24}
          onPress={props.deleteLine}
        />
        <MaterialCommunityIcons
          name={"dots-vertical"}
          size={24}
          onPress={_threeDots}
          marginTop={9}
        />
      </View>
    </View>
  );
}, areEqual);

export default DocLine;