import React from "react";
import { View } from "react-native";
import { t } from "i18n-js";
import { DocLineColumnWidth } from "./DocLinesHeader";
import { charToCode } from "./utils";
import NativeModalPicker from "../../../components/NativeModalPicker";

interface Props {
  setBlock: (block: string) => void;
  block: string;
  letters: string[];
  addPriceFixBlock?: () => void;
}

const areEqual = (p: Readonly<Props>, n:Readonly<Props>) => {
  return JSON.stringify(p) === JSON.stringify(n) && p.setBlock === n.setBlock;
}

const DocLinePositionLevelBlockSelect = React.memo(({block, setBlock, letters, addPriceFixBlock}: Props) => {

  const _setBlock = (block:string, index :number) => {
    console.log(block)
console.log(letters.length, index, String.fromCharCode(65 + letters.length - 1))
    if (index === letters.length) {
      if (addPriceFixBlock) {
        const letter = String.fromCharCode(65 + letters.length - 1)
        addPriceFixBlock()
        setBlock(letter)
      }
    } else {
      setBlock(block)
    }
  }

  return (
    <View style={{paddingLeft: 3}}>
      <NativeModalPicker
        itemToLabel={a => a}
        itemToKey={a => a}
        outStyle={{width: DocLineColumnWidth.FLAT_RATE}}
        style={{width: DocLineColumnWidth.FLAT_RATE}}
        placeholder={'CHOOSE_A_LETTER'}
        accessibilityLabel={'CHOOSE_A_LETTER'}
        selectedValue={block}
        onValueChange={_setBlock}
        options={['-', ...letters]}
      />
    </View>
  );
}, areEqual);

export default DocLinePositionLevelBlockSelect;