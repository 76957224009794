import React, { useEffect, useState } from "react";
import { View, Text, Switch, TextInput, StyleSheet, Platform, NativeSyntheticEvent, TextInputChangeEventData, Pressable } from "react-native";
import {  Branch, ConstructionSite, ConstructionSiteType, Doc } from "../../../models";
import { t } from 'i18n-js';
import ConstructionSiteTypeDropdown from "../../../components/ConstructionSiteTypeDropdown";
import { GooglePlaceData, GooglePlaceDetail, GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import BranchDropdown from "../../../components/BranchDropdown";
import MapView, { LatLng, Marker, PROVIDER_GOOGLE } from "react-native-maps" //"react-native-web-maps"
import { coordinatesToGoogleMapsLink, googleMapsLinkToCoordinates } from "../../../hooks/geo";
import { Accuracy, getCurrentPositionAsync, requestForegroundPermissionsAsync, reverseGeocodeAsync } from "expo-location";
import MapModal from "../../../components/MapModal";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import getTheme from "../../../constants/Colors";
import NativeConstructionSiteTypeChoose from "./NativeConstructionSiteTypeChoose";

interface Props {
  constructionSite: ConstructionSite;
  setConstructionSite: (constructionSite: ConstructionSite) => void;
  doc: Doc;
  setDoc: (doc: Doc) => void;
}

const DocConstructionSite = ({constructionSite, setConstructionSite, doc, setDoc}: Props) => {

  const theme = getTheme()
  const isNativeApp = Platform.OS == 'android' || Platform.OS == 'ios'

  const serverReachable = useSelector(
    (state: Reducer) => state.sync.serverReachable
  )
  const token = useSelector((state: Reducer) => state.auth.token)
  const url = useSelector((state: Reducer) => state.auth.workSpace?.url || '')
  const branch = useSelector((state: Reducer) => state.user.branch.Maps)
  const branchCoordinates = googleMapsLinkToCoordinates(branch).coords

  const [mapModalVisible, setMapModalVisible] = useState<boolean>(false)
  const _showMapModal = () => setMapModalVisible(true)
  const _closeMapModal = () => setMapModalVisible(false)

  const [showNativeTypePicker, setShowNativeTypePicker] = useState<boolean>(
    isNativeApp && constructionSite.Type == null
  )
  const _toggleNativeTypePicker = () => {
    if (isNativeApp) {
      setShowNativeTypePicker(s => !s || constructionSite.Type == null)
    }
  }
  const [showNotes, setShowNotes] = useState<boolean>(false)
  const _toggleNotes = () => setShowNotes(!showNotes)

  const [coordinate, setCoordinate] = useState<LatLng | undefined>()

  const code = constructionSite.Code.length > 0 ? ` (${constructionSite.Code})` : "";

  const mapRef = React.createRef<MapView>();

  useEffect(() => {
    if (constructionSite.Address.length == 0) {
      if (Platform.OS === 'web') {
        setConstructionSite({
          ...constructionSite,
          Maps: '.',
        })
      } else {
        requestForegroundPermissionsAsync().then(status => {
          if (!status.granted) {
            return;
          }
          getCurrentPositionAsync({
            accuracy: Accuracy.Balanced,
          }).then(location => {
            setCoordinate(location.coords)
            if (Platform.OS === 'web') {
              mapRef.current?.animateCamera({
                center: {
                  // @ts-ignore
                  lat: location?.coords?.latitude || 0,
                  lng: location?.coords?.longitude || 0,
                },
                zoom: 16,
              })
            } else {
              mapRef.current?.animateCamera({
                center: {
                  latitude: location?.coords?.latitude || 0,
                  longitude: location?.coords?.longitude || 0,
                },
                zoom: 16,
              })
            }
            reverseGeocodeAsync(location.coords).then(response => {
              if (response.length > 0) {
                setConstructionSite({
                  ...constructionSite,
                  Address: `${response[0].name}, ${response[0].isoCountryCode}-${response[0].postalCode} ${response[0].city}`,
                  Maps: coordinatesToGoogleMapsLink(location.coords.latitude, location.coords.longitude),
                })
              }
            })
          })
        });
      }
    }
  }, [])

  const _name = (name: string) => {
    setConstructionSite({
      ...constructionSite,
      Name: name,
    });
  };

  const _type = (type: ConstructionSiteType) => {
    let name = "";
    if (constructionSite.Type) {
      if (constructionSite.Name.startsWith(constructionSite.Type.Name)) {
        name = type.Name + " " + constructionSite.Name.substring(constructionSite.Type.Name.length + 1);
      } else {
        name = constructionSite.Name;
      }
    } else {
      name = type.Name + " " + constructionSite.Name;
    }
    setConstructionSite({
      ...constructionSite,
      Name: name,
      Type: type,
      TypeID: type.ID,
    });
  }

  const _typeNative = (type: ConstructionSiteType) => {
    _type(type)
    setShowNativeTypePicker(false)
  }

  const _addressMaps = (address: string, maps: string) => setConstructionSite({
    ...constructionSite,
    Address: address,
    Maps: maps,
  })

  const _address = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setConstructionSite({
      ...constructionSite,
      Address: e.nativeEvent.text,
      Maps: '.',
    })
  }

  const _addressChoose = (data: GooglePlaceData, detail: GooglePlaceDetail | null) => {
    if (Platform.OS === 'web') {
      mapRef.current?.animateCamera({
        center: {
          // @ts-ignore
          lat: detail?.geometry.location.lat || 0,
          lng: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
    } else {
      mapRef.current?.animateCamera({
        center: {
          latitude: detail?.geometry.location.lat || 0,
          longitude: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
    }
    const maps =  detail?.geometry.location ? coordinatesToGoogleMapsLink(
      detail.geometry.location.lat,
      detail.geometry.location.lng,
    ) : '.'
    setConstructionSite({
      ...constructionSite,
      Address: data.description,
      Maps: maps,
    })
    setCoordinate({
      latitude: detail?.geometry.location.lat || 0,
      longitude: detail?.geometry.location.lng || 0,
    })
  }

  const _distance = (distance: number) => {
    setDoc({
      ...doc,
      Distance: distance,
    })
  }

  const _branch = (branch: Branch) => {
    setConstructionSite({
      ...constructionSite,
      Branch: branch,
      BranchID: branch.ID,
    });
    setDoc({
      ...doc,
      Branch: branch,
      BranchID: branch.ID,
    })
  }

  const _accessible = (accessbile: boolean) => {
    setConstructionSite({
      ...constructionSite,
      Accessible: accessbile,
    });
  };

  const _aquifer = (aquifer: boolean) => {
    setConstructionSite({
      ...constructionSite,
      Aquifer: aquifer,
    });
  };

  const _notes = (notes: string) => setConstructionSite({
    ...constructionSite,
    Notes: notes,
  })

  return (
    <View style={{paddingTop: 9}}>
      {mapModalVisible && (
        <MapModal
          address={constructionSite.Address}
          setAddressMaps={_addressMaps}
          visible={mapModalVisible}
          onClose={_closeMapModal}
          setCoordinate={setCoordinate}
          coordinate={coordinate}
          mapRefProp={mapRef}
        />
      )}

      <Text style={{fontSize: 20}}>
        {`${t(constructionSite.Code.length > 0 ? 'CONSTRUCTION_SITE' : 'PLACE')}   ${constructionSite.IDString} ${code}`}
      </Text>

      <View style={{flexDirection: 'row', alignItems: 'center', paddingTop: 6}}>
        <View style={{flex: 1}}>
          {showNativeTypePicker && (
            <View style={{paddingBottom: 15}}>
              <Text>{t('CONSTRUCTION_SITE')} {t('CONSTRUCTION_SITE_TYPE')}:</Text>
              <NativeConstructionSiteTypeChoose
                value={constructionSite.Type}
                onChange={_typeNative}
              />
            </View>
          )}
          <View style={{flexDirection: 'row', alignItems: 'center', paddingBottom: 6}}>
            <Text style={{paddingRight: 6}}>{t('CONSTRUCTION_SITE_TYPE')}:</Text>
            {!isNativeApp ? (
              <ConstructionSiteTypeDropdown setType={_type} type={constructionSite.Type}/>
            ): (
              <Pressable
                style={{paddingHorizontal: 10, flexDirection: 'row'}}
                onPress={_toggleNativeTypePicker}
              >
                <Text>{constructionSite.Type?.Name || '-'}</Text>
                <MaterialCommunityIcons
                  name={showNativeTypePicker ?  'chevron-up' : 'chevron-down'}
                  size={20}
                  style={{paddingLeft: 5}}
                />
              </Pressable>
            )}
            <Text style={{paddingHorizontal: 6}}>{t('NAME')}:</Text>
            <TextInput
              style={{
                flex: 1,
                backgroundColor: 'white',
                padding: 9,
                borderRadius: 6,
              }}
              value={constructionSite.Name}
              onChangeText={_name}
            />
          </View>

          <View style={{flexDirection: 'row', alignItems: 'center', paddingTop: 5}}>
            <Text style={{paddingRight: 6}}>{t('CONSTRUCTION_SITE_ADDRESS')}:</Text>
            <GooglePlacesAutocomplete
              //ref={ref}
              GooglePlacesDetailsQuery={{ fields: "geometry" }}
              fetchDetails={true}
              placeholder='Search'
              onPress={_addressChoose}
              textInputProps={{
                onChange: _address,
                value: constructionSite.Address,
              }}
              query={{
                key: 'AIzaSyDqb1ADutBQksnAv43XLyjDQuJO9cTALNU',
                language: 'en',
                types: 'address',
                location: `${coordinate?.latitude || branchCoordinates.latitude},${coordinate?.longitude || branchCoordinates.longitude}`,
                radius: 5000,
                input: constructionSite.Address,
              }}
              requestUrl={{
                useOnPlatform: 'web',
                url: `${url}googleMapsApiProxy`,
                headers: {
                  Authorization: token,
                },
              }}
            />
          </View>

          <View style={{flexDirection: 'row', alignItems: 'center', paddingTop: 5, justifyContent: 'space-between'}}>
            <Pressable style={{flexDirection: 'row', alignItems: 'center'}} onPress={_toggleNotes}>
              <Text style={{paddingRight: 6}}>{t('NOTES')}</Text>
              
              {constructionSite.Notes.length > 0 && (
                <MaterialCommunityIcons
                  name='comment'
                  size={16}
                  color='black'
                  style={{paddingHorizontal: 5}}
                />
              )}

              <MaterialCommunityIcons
                name={showNotes ? 'chevron-down' : 'chevron-up'}
                size={24}
                color='black'
              />
            </Pressable>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{paddingRight: 2}}>
                {t("ACCESSIBLE")}
              </Text>
              <Switch value={constructionSite.Accessible} onValueChange={_accessible}/>
            </View>

            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{paddingLeft: 20, paddingRight: 2}}>
                {t("AQUIFER")}
              </Text>
              <Switch value={constructionSite.Aquifer} onValueChange={_aquifer}/>
            </View>

            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{paddingLeft: 20, paddingRight: 5}}>{t('BRANCH')}:</Text>
              <BranchDropdown setBranch={_branch} branch={constructionSite.Branch}/>
            </View>
          </View>

          {showNotes && (
            <TextInput
              style={{
                backgroundColor: 'white',
                padding: 9,
                borderRadius: 6,
                marginTop: 5,
                borderColor: theme.border,
              }}
              value={constructionSite.Notes}
              onChangeText={_notes}
              multiline
              numberOfLines={3}
              placeholder={t('NOTES')}
            />
          )}
        </View>

        <View style={{minWidth: 200, width: '20%', height: '100%', marginLeft: 12}}>
          {serverReachable ? (
            <MapView
              ref={mapRef}
              provider={PROVIDER_GOOGLE}
              showsUserLocation={Platform.OS !== 'web'}
              initialRegion={{
                ...(coordinate ? coordinate : branchCoordinates),
                latitudeDelta: 0.0122,
                longitudeDelta: 0.0051,
              }}
              mapType="hybrid"
              showsPointsOfInterest={false}
              style={{
                ...StyleSheet.absoluteFillObject,
                borderRadius: 5,
              }}
              onPress={_showMapModal}
            >
              {coordinate && (Platform.OS == "web" ? (
                // @ts-ignore
                <MapView.Marker coordinate={coordinate} title="tt" description="ddd"/>
              ) : (
                <Marker coordinate={coordinate} title="tt" description="ddd"/>
              ))}
            </MapView>
          ) : (
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}>
              <Text style={{textAlign: 'center'}}>
                {t('MAPS')+'\n'}{t('NOT_AVAILABLE_OFFLINE')}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default DocConstructionSite;