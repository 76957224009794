import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRightWidth: 5,
    padding: 10,
  },
  categoryText: {
    fontWeight: "bold",
    alignSelf: "center",
    fontSize: 16,
    paddingVertical: 5,
  },
  categoryContainer: { flexDirection: "row", justifyContent: "space-between", paddingVertical: 9, alignItems: 'center' },
  text: { marginLeft: 20, fontSize: 12, paddingVertical: 8 },
  categoryContentContainer: { borderRadius: 5, paddingHorizontal: 8, marginHorizontal: 5, backgroundColor: "white" },
});

export default styles;
