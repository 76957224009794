import React from 'react'
import { t } from 'i18n-js'
import { Client } from '../../models'
import Modal from 'react-native-modal'
import { Text, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import getTheme from '../../constants/Colors'

interface ClientNotesModalProps {
  client: Client
  onClose: () => void
}

const ClientNotesModal = ({client, onClose}: ClientNotesModalProps) => {

  const theme = getTheme()

  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{fontSize: 18, fontWeight: '600'}}>{client.Name}</Text>
        <MaterialCommunityIcons
          name='close'
          size={34}
          onPress={onClose}
          color='black'
        />
      </View>
      <Text style={{marginTop: 10}}>{t('NOTES')}</Text>
      <Text style={{
        padding: 5,
        backgroundColor: 'white',
        borderColor: theme.border,
        borderWidth: 1,
        borderRadius: 5,
        marginTop: 5,
      }}>
        {client.FreeText}
      </Text>

      <View style={{flex: 1}} />
    </Modal>
  )
}

export default ClientNotesModal