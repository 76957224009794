import React from "react";
import { t } from "i18n-js";
import { Text, View } from "react-native";
import getTheme from "../../../constants/Colors";

export const DocLineColumnWidth = {
  CODE: 100,
  QUANTITY: 100,
  UOM: 70,
  PRICE: 70,
  TOTAL: 70,
  SUM_TYPE: 100,
  FLAT_RATE: 70,
  ICONS: 30,
}

export const DocLineColumnWidthNoCode = DocLineColumnWidth.QUANTITY +
  DocLineColumnWidth.UOM +
  DocLineColumnWidth.PRICE +
  DocLineColumnWidth.TOTAL +
  DocLineColumnWidth.SUM_TYPE +
  DocLineColumnWidth.FLAT_RATE;

export const DocLineColumnWidthSumFlat = DocLineColumnWidth.SUM_TYPE +
  DocLineColumnWidth.FLAT_RATE;


const DocLinesHeader = () => {

  const theme = getTheme();

  return (
    <View 
      style={{
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 6,
        borderBottomWidth: 1,
        borderBottomColor: theme.border,
        paddingHorizontal: 6,
      }}
    >
      <Text style={{width: DocLineColumnWidth.CODE}}>{t('CODE')}</Text>
      <Text style={{flex: 1}}>{t('DESCRIPTION')}</Text>
      <Text style={{width: DocLineColumnWidth.QUANTITY}}>{t('QUANTITY')}</Text>
      <Text style={{width: DocLineColumnWidth.UOM, textAlign: 'center'}}>{t('UOM')}</Text>
      <Text style={{width: DocLineColumnWidth.PRICE, textAlign: 'center'}}>{t('PRICE')}</Text>
      <Text style={{width: DocLineColumnWidth.TOTAL, textAlign: 'center'}}>{t('TOTAL')}</Text>
      <Text style={{width: DocLineColumnWidth.SUM_TYPE, textAlign: 'center'}}>{t('SUM_TYPE')}</Text>
      <Text style={{width: DocLineColumnWidth.FLAT_RATE, textAlign: 'center'}}>{t('FLAT_RATE')}</Text>
      <View style={{width: DocLineColumnWidth.ICONS}}/>
    </View>
  );
};

export default DocLinesHeader;