import React from "react";
import { FlatList, View, Text } from "react-native";
import { t } from "i18n-js";
import { Item } from "../../../models";
import CreateCustomPositionLine from "./CreateCustomPositionLine";

interface Props {
  items: Item[];
  setItem: (item: Item, index: number) => void;
  deleteItem: (item: Item) => void;
}

const CreateCustomPositionTable = ({items, setItem, deleteItem}:Props) => {

  const renderItem = ({item, index}: {item: Item, index: number}) => {
    const _set = (item: Item) => setItem(item, index);

    return (
      <CreateCustomPositionLine
        item={item}
        setItem={_set}
        deleteItem={deleteItem}
      />
    )
  }

  return (
    <View>
      <View style={{flexDirection: 'row'}}>
        <Text style={{fontWeight: 'bold', flex: 2}}>{t("CODE")}</Text>
        <Text style={{fontWeight: 'bold', flex: 5}}>{t("NAME")}</Text>
        <Text style={{fontWeight: 'bold', textAlign: 'center', flex: 2}}>{t("QUANTITY")}</Text>
        <Text style={{fontWeight: 'bold', textAlign: 'center', flex: 2}}>{t("UOM")}</Text>
        <Text style={{fontWeight: 'bold', textAlign: 'center', flex: 2}}>{t("UNIT_PRICE")}</Text>
        <Text style={{fontWeight: 'bold', textAlign: 'center', flex: 2}}>{t("PRICE")}</Text>
        <View style={{flex: 1}}/>
      </View>
      <FlatList
        data={items}
        renderItem={renderItem}
        key={`create-custom-position-flat-list${items.length}`}
        // @ts-ignore
        listKey={`create-custom-position-flat-list${items.length}`}
        keyExtractor={(item, index) => `create-custom-position-${index}-${item.ID}`}
      />
    </View>
  )
}

export default CreateCustomPositionTable;