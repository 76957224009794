import React, { useEffect, useState } from 'react'
import { Pressable, Text, View } from 'react-native'
import getTheme from '../../constants/Colors'

interface FlatChooseProps<T> {
  item?: T
  onChange: (item: T) => void
  defaultSelected?: T
  itemToKey: (item: T) => string
  itemToText: (item: T) => string
}

const ChooseItem = <T,>({
  item,
  onChange,
  defaultSelected,
  itemToKey,
  itemToText,
}: FlatChooseProps<T>) => {

  const theme = getTheme()

  const [selected, setSelected] = useState<boolean>(
    (item != undefined && defaultSelected != undefined) && itemToKey(item) === itemToKey(defaultSelected)
  )

  useEffect(() => {
    if (item != undefined && defaultSelected != undefined) {
      setSelected(itemToKey(item) === itemToKey(defaultSelected))
    } else {
      setSelected(false)
    }
  }, [defaultSelected])

  const _select = () => {
    if (item != undefined) {
      onChange(item)
      setSelected(true)
    }
  }

  if (item == undefined) {
    return <View style={{flex: 1}} />
  }

  return (
    <Pressable
      onPress={_select}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 8,
        flex: 1,
        marginTop: 5,
      }}
    >
      <View style={{
        height: 15,
        width: 15,
        borderColor: theme.border,
        borderWidth: 1,
        borderRadius: 15,
        padding: 2,
      }}>
        <View style={{
          flex: 1,
          borderRadius: 15,
          backgroundColor: selected ? theme.mainColor : 'transparent',
        }}/>
      </View>
      <Text style={{marginLeft: 10, fontSize: 16, flex: 1}}>
        {itemToText(item)}
      </Text>
    </Pressable>
  )
}

export default ChooseItem