import { Line, LineType } from '../../models'

export const addFather = (fatherID: string | null, addedFathers: string[], lines: Line[]): Line[] => {
  if (fatherID == null || fatherID.length == 0) {
    return [];
  }
  let exist = false;
  for (let i = 0; i < addedFathers.length; i++) {
    if (fatherID == addedFathers[i]) {
      exist = true;
      i = addedFathers.length;
    }
  }

  if (exist) {
    return [];
  }

  let father = null;
  for (let i = 0; i < lines.length; i++) {
    if (fatherID == lines[i].ID) {
      father = lines[i];
      i = lines.length;
    }
  }

  if (father == null) {
    return [];
  }

  addedFathers.push(father.ID);

  const add = addFather(father.FatherID, addedFathers, lines);
  return [...add, father];
};

export const filterLines = (lines: Line[]): Line[] => {
  const l:Line[] = [];
  const addedFather: string[] = [];

  for (let i = 0; i < lines.length; i++) {
    if (!lines[i].LineLevel) {
      lines[i].LineLevel = [];
    }
    let add = false;
    for (let j = 0; j < lines[i].LineLevel.length; j++) {
      if (lines[i].LineLevel[j].Quantity > 0) {
        add = true;
        j = lines[i].LineLevel.length;
      }
    }

    if (add) {
      const add = addFather(lines[i].FatherID, addedFather, lines);
      for (let j = 0; j < add.length; j++) {
        l.push(add[j]);
      }
    }

    switch (lines[i].LineType) {
      case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
      case LineType.FIXED_PRICE:
      case LineType.TEXT:
        l.push(lines[i]);
        break;
      default:
        if (add) {
          l.push(lines[i]);
        }
        break;
    }
  }

  return l;
};