import { MaterialIcons } from "@expo/vector-icons";
import { Text, View } from "react-native";
import React, { useMemo } from "react";
import PdfComponent from "../PDFComponent";
import Modal from "react-native-modal";

interface Props {
  modalTitle?: string;
  url: string;
  params?: {
    key: string;
    value: string;
  }[];
  open: boolean;
  close: () => void;
}

const PdfModal = ({modalTitle, url, params = [], open, close}:Props) => {

  const fullUrl = useMemo(() => {
     return url + params.reduce((o, v, i) => {
      if (i == 0) {
        return o + `?${v.key}=${v.value}`;
      } else {
        return o + `&${v.key}=${v.value}`;
      }
    }, "");
  }, [url, params]);

  return (
    <Modal
      isVisible={open} 
      onBackdropPress={close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: 'row', marginBottom: 10}}>
        <Text style={{fontSize: 25, flex: 1}}>{modalTitle}</Text>
        <MaterialIcons
          name="close"
          size={34}
          onPress={close}
        />
      </View>
      <View style={{width: "100%", flex: 1}}>
        <PdfComponent url={fullUrl} />
      </View>
    </Modal>
  )
};

export default PdfModal;