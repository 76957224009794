import React, { useEffect, useState } from "react";
import PdfModal from "./PdfModal";
import Button from "../Button";
import getTheme from "../../constants/Colors";

interface Props {
  modalTitle?: string;
  buttonTitle?: string;
  buttonTitleT?: string;
  url: string;
  params?: {
    key: string;
    value: string;
  }[];
  active?: boolean;
}

const LoadPdfButton = ({
  modalTitle,
  buttonTitle,
  buttonTitleT,
  url,
  params = [],
  active = true
}:Props) => {

  const theme = getTheme();

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if(active != undefined && !active) {
      setOpen(false);
    }
  }, [active]);

  const _open = () => setOpen(true);
  const _close = () => setOpen(false);

  return (
    <>
      <Button
        onPress={_open}
        titleT={buttonTitleT}
        title={buttonTitle}
        style={{
          paddingHorizontal: 12,
          paddingVertical: 8,
          backgroundColor: theme.blue,
          borderRadius: 6,
        }}
      />

      <PdfModal
        open={open}
        close={_close}
        modalTitle={modalTitle}
        url={url}
        params={params}
      />
    </>
  );
};

export default LoadPdfButton;