import React, { useMemo } from "react";
import { t } from "i18n-js";
import { Doc, DocumentContact } from "../../../models";
import { FlatList, View, Text, Pressable } from "react-native";
import DocDetailsDocumentContact from "./DocDetailsDocumentContact";
import { newDocumentContact } from "./utils";
import getTheme from "../../../constants/Colors";
import { alert } from "../../../hooks/Alert";

interface Props {
  doc: Doc;
  setDoc: (doc: Doc) => void;
  useSoftDelete?: boolean;
}

const DocDetailsDocumentContacts = ({doc, setDoc, useSoftDelete = false}: Props) => {

  const theme = getTheme();

  const _new = () => {
    const _press = () => {
      if (!doc.Type) {
        alert("SELECT_DOCUMENT_TYPE","SELECT_DOCUMENT_TYPE_DESC").then();
        return;
      }
      let id = 0;
      for (let i = 0; i < doc.DocumentContacts.length; i++) {
        if (doc.DocumentContacts[i].ID < id) {
          id = doc.DocumentContacts[i].ID;
        }
      }
      id--;
      setDoc({
        ...doc,
        DocumentContacts: [
          ...doc.DocumentContacts,
          newDocumentContact(id, doc),
        ],
      });
    };

    return (
      <Pressable
        onPress={_press}
        style={{
          flex: 1,
          padding: 6,
          alignItems: 'center',
          borderWidth: 1,
          borderColor: theme.border,
          borderRadius: 5,
          marginTop: 5,
        }}
      >
        <Text>+ {t('ADD_NEW_DOCUMENT_CONTACT')}</Text>
      </Pressable>
    );
  };

  const _remove = (index: number) => {
    if (useSoftDelete) {
      setDoc({
        ...doc,
        DocumentContacts: doc.DocumentContacts.map((d, i) => {
          if (i === index) {
            return {
              ...d,
              Deleted: true,
            };
          }
          return d;
        }),
      });
    } else {
      setDoc({
        ...doc,
        DocumentContacts: doc.DocumentContacts.filter((v, i) => i !== index),
      });
    }
  };

  const Header = () => (
    <View style={{flexDirection: 'row', paddingRight: 110}}>
      <Text style={{width: 170}}>{t('CONTACT_TYPE')}</Text>
      <Text style={{flex: 1}}>{t('CODE')}</Text>
      <Text style={{flex: 2}}>{t('NAME')}</Text>
      <Text style={{flex: 2}}>{t('CONTACT')}</Text>
      <Text style={{flex: 2}}>{t('EMAIL')}</Text>
      <Text style={{flex: 2}}>{t('PHONE')}</Text>
      <Text style={{flex: 2}}>{t('MOBILE_PHONE')}</Text>
    </View>
  );

  const _render = ({item, index}: {item: DocumentContact, index: number}) => {
    const set = (item: DocumentContact) => {
      setDoc({
        ...doc,
        DocumentContacts: doc.DocumentContacts.map((item2, index2) => {
          if (item2.Deleted) {
            index++;
          }
          if (index !== index2) {
            return item2;
          }

          return item;
        }),
      });
    };

    return (
      <DocDetailsDocumentContact item={item}
                                 set={set}
                                 index={index}
                                 remove={_remove}/>
    );
  }

  const documentContacts = useMemo(() => {
    return doc.DocumentContacts.filter(d => d.Deleted === undefined || d.Deleted === false);
  }, [doc.DocumentContacts]);

  return (
    <View style={{paddingTop: 15}}>
      <Text style={{fontSize: 20}}>{t('CONTACTS')}</Text>
      <FlatList
        data={documentContacts}
        renderItem={_render}
        ListHeaderComponent={Header}
        ListFooterComponent={_new}
        key={`document-detail-contacts-${documentContacts.length}`}
        // @ts-ignore
        listKey={`document-detail-contaccts-${documentContacts.length}`}
        keyExtractor={(item, index) => `document-detail-contact-${index}-${item}`}
      />
    </View>
  );
};

export default DocDetailsDocumentContacts;