import React, { useEffect, useState } from 'react'
import { ActivityIndicator, FlatList, Pressable, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { DocOfflineMetaData } from '../../models'
import getTheme from '../../constants/Colors'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { toggleShowDocsOffline } from '../../store/action/settingsAction'
import { getDocsOfflineMetadata, removeDocOffline } from '../../utils/doc-offline/util'
import { sentryCapture } from '../../utils/sentryCapture'
import { alert } from '../../hooks/Alert'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { t } from 'i18n-js'
import { SearchScreens } from '../../screens/search/SearchScreens'

const DocsOffline = () => {

  const theme = getTheme()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const showDocsOffline = useSelector(
    (state: Reducer) => state.settings.showDocsOffline
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [docsOffline, setDocsOffline] = useState<DocOfflineMetaData[]>([])

  const _toggleShowDocsOffline = () => dispatch(toggleShowDocsOffline())

  const loadDocsOffline = async () => {
    try {
      setLoading(true)
      const docsOffline = await getDocsOfflineMetadata()
      setDocsOffline(docsOffline)
    } catch (e) {
      sentryCapture(e)
      alert('ERROR', 'ERROR_LOADING_DOCS_OFFLINE')
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isFocused) {
      loadDocsOffline()
    }
  }, [isFocused])

  const _render = ({ item, index }: { item: DocOfflineMetaData, index: number }) => {
    const _press = () => {
      // @ts-ignore
      navigation.navigate(SearchScreens.DOCS_OFFLINE, {
        uuid: item.uuid,
      })
    }

    const _delete = () => {
      removeDocOffline(item.uuid)
        .then(() => {
          setDocsOffline(
            docsOffline.filter(d => d.uuid !== item.uuid)
          )
        })
        .catch(e => {
          sentryCapture(e)
          alert('ERROR', 'ERROR_DELETING_DOCS_OFFLINE')
        })
    }

    return (
      <Pressable
        key={`doc-offline-${index}-${item.uuid}`}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          borderBottomWidth: index == docsOffline.length - 1 ? 0 : 1,
          borderBottomColor: theme.border,
        }}
        onPress={_press}
      >
        <Text style={{fontSize: 16, width: 100, paddingRight: 5}}>
          {item.constructionSiteCode}
        </Text>
        <Text style={{fontSize: 16, flex: 1, paddingHorizontal: 10}}>
          {item.constructionSiteName}
        </Text>
        <Text style={{fontSize: 16, flex: 1, paddingHorizontal: 10}}>
          {item.contractName}
        </Text>
        <Text style={{fontSize: 16, flex: 1, paddingHorizontal: 10}}>
          {item.client || '-'}
        </Text>
        <Text style={{fontSize: 16, width: 100, paddingHorizontal: 10}}>
          {item.docTypeName || '-'}
        </Text>
        <Text style={{fontSize: 16, width: 100, paddingHorizontal: 10}}>
          {item.catalogName || '-'}
        </Text>

        <Text style={{ fontSize: 16, width: 200, paddingHorizontal: 10 }}>
          {item.createdAt.toLocaleDateString()}  {item.createdAt.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})}
        </Text>

        <MaterialCommunityIcons
          size={24}
          color='black'
          name='delete'
          onPress={_delete}
          style={{ padding: 5 }}
        />
      </Pressable>
    )
  }

  if (docsOffline.length == 0) {
    return (
      <></>
    )
  }

  return (
    <View>
      <Pressable
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          paddingBottom: showDocsOffline ? 10 : 0,
        }}
        onPress={_toggleShowDocsOffline}
      >
        <Text style={{ fontSize: 18, flex: 1 }}>
          {t('DOCS_OFFLINE')}
        </Text>

        {loading && (
          <ActivityIndicator />
        )}

        <MaterialCommunityIcons
          size={24}
          color='black'
          name={showDocsOffline ? 'eye' : 'eye-off'}
          style={{ paddingLeft: 10 }}
        />
      </Pressable>

      {showDocsOffline && (
        <View style={{
          paddingHorizontal: 10,
          backgroundColor: 'white',
          borderRadius: 5,
          maxHeight: 300,
        }}>
          <FlatList
            data={docsOffline}
            renderItem={_render}
          />
        </View>
      )}
    </View>
  )
}

export default DocsOffline