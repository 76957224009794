import React, { useState } from "react";
import { Pressable, Text, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import getTheme from "../../constants/Colors";

interface Props {
  text: string;
  children: JSX.Element | JSX.Element[];
}

const Accordion = ({text, children}: Props) => {

  const theme = getTheme();

  const [show, setShow] = useState<boolean>(false);

  const _show = () => {
    setShow(!show);
  }

  return (
    <View
      style={{
        flexDirection: 'column',
        borderColor: theme.border,
        borderWidth: 1,
        borderRadius: 7,
        marginTop: 3,
      }}
    >
      <Pressable
        style={{
          padding: 9,
          flexDirection: 'row',
          alignItems: 'center',
        }}
        onPress={_show}
      >
        <Text style={{flex: 1}}>{text}</Text>
        <MaterialCommunityIcons
          name={show ? 'chevron-up' : 'chevron-down'}
          size={18}
        />
      </Pressable>
      {show && <View>
        {children}
      </View>
      }
    </View>
  );
}

export default Accordion;