import React, { useEffect, useMemo, useState } from 'react'
import { LineType, Material, MeasureLine, MeasureReport, ReportLevel } from '../../models'
import { FlatList, Text, View } from 'react-native'
import { getStringFromTime, getTimeFromString } from '../../utils/StringUtils'
import { decimalHoursToSexagesimal, isHours } from '../../utils/reports/report-utils'
import TimePicker from '../../components/TimePicker/TimePicker'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import NumberInput from '../document/PostitionComponent/NumberInput'
import { t } from 'i18n-js'
import { round } from '../../shared-utils/NumberUtils'
import getTheme from '../../constants/Colors'
import Formatter from '../../utils/formatters'
import { minutesToHours } from '../../shared-utils/reports/report-time-utils'

interface Props {
  item: MeasureReport
  index: number
  measureLine: MeasureLine
  setItem: (item: MeasureReport) => void
  readOnly: boolean
}

const MeasureLineReportDetails = ({ item, index, measureLine, setItem, readOnly }: Props) => {

  const theme = getTheme()

  const [_item, _setItem] = useState<MeasureReport>(item)
  const [open, setOpen] = useState<boolean>(false)
  const _toggleOpen = () => setOpen(o => !o)

  const hours = isHours(item.Report.UnitOfMeasure)

  const _approvedDuration = (duration: string) => {
    _setItem(item => ({
      ...item,
      Report: {
        ...item.Report,
        Duration: getTimeFromString(duration) || 0,
      },
    }))
    setItem({
      ..._item,
      Report: {
        ..._item.Report,
        Duration: getTimeFromString(duration) || 0,
      }
    })
  }

  const _approvedQuantityIndex = (quantity: number, index: number) => {
    _setItem(item => ({
      ...item,
      Report: {
        ...item.Report,
        Levels: item.Report.Levels?.map((l, i) => {
          if (i === index) {
            return {
              ...l,
              ApprovedQuantity: quantity,
            }
          }
          return l
        }),
      },
    }))
    setItem({
      ..._item,
      Report: {
        ..._item.Report,
        Levels: _item.Report.Levels?.map((l, i) => {
          if (i === index) {
            return {
              ...l,
              ApprovedQuantity: quantity,
            }
          }
          return l
        }),
      },
    })
  }

  const _billableQuantityIndex = (quantity: number, index: number) => {
    _setItem(item => ({
      ...item,
      Report: {
        ...item.Report,
        Levels: item.Report.Levels?.map((l, i) => {
          if (i === index) {
            return {
              ...l,
              BillableQuantity: quantity,
            }
          }
          return l
        }),
      },
    }))
    setItem({
      ..._item,
      Report: {
        ..._item.Report,
        Levels: _item.Report.Levels?.map((l, i) => {
          if (i === index) {
            return {
              ...l,
              BillableQuantity: quantity,
            }
          }
          return l
        }),
      },
    })
  }

  const _approveMaterialQuantityIndex = (quantity: number, index: number) => {
    _setItem(item => ({
      ...item,
      Report: {
        ...item.Report,
        Materials: item.Report.Materials?.map((l, i) => {
          if (i === index) {
            return {
              ...l,
              BillableQuantity: quantity,
            }
          }
          return l
        }),
      },
    }))
    setItem({
      ..._item,
      Report: {
        ..._item.Report,
        Materials: _item.Report.Materials?.map((m, i) => {
          if (i === index) {
            return {
              ...m,
              BillableQuantity: quantity,
            }
          }
          return m
        }),
      },
    })
  }

  useEffect(() => {
    _setItem(item)
  }, [item])

  const approvedQuantity = useMemo(
    () => _item.Report.Levels?.reduce((p, v) => p + (v.ApprovedQuantity || 0), 0) || 0,
    [_item]
  )

  const f2 = Formatter.number2.format

  const _render = ({ item, index }: { item: ReportLevel, index: number }) => {

    const _approvedQuantity = (v: number) => _approvedQuantityIndex(v, index)
    const _billableQuantity = (v: number) => _billableQuantityIndex(v, index)

    const _approvedQuantityTime = (time: string) => {
      _approvedQuantityIndex((getTimeFromString(time) || 0) / 60, index)
    }
    const _billableQuantityTime = (time: string) => {
      _billableQuantityIndex((getTimeFromString(time) || 0) / 60, index)
    }

    return (
      <View
        key={`rl-${index}`}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 3,
        }}
      >
        {(_item.Report.Levels?.length || 0) > 1 && (
          <View style={{ flex: 1 }} />
        )}
        {(_item.Report.Levels?.length || 0) > 1 && (
          <Text style={{ width: 200 }}>{measureLine.Levels?.[index].Level.Name}</Text>
        )}
        <Text style={{
          width: 75,
          textAlign: 'right',
          padding: 1,
          borderRadius: 5,
          marginHorizontal: 5
        }}>
          {f2(item.Quantity)}
        </Text>
        <View style={{ width: 110 }}>
          {hours ? (
            <TimePicker value={getStringFromTime((item.ApprovedQuantity || 0) * 60)} onChange={_approvedQuantityTime} />
          ) : (
            <NumberInput defaultValue={item.ApprovedQuantity} onChange={_approvedQuantity} disabled={readOnly} />
          )}
        </View>
        <View style={{ width: 110 }}>
          {hours ? (
            <TimePicker value={getStringFromTime((item.BillableQuantity || 0) * 60)} onChange={_billableQuantityTime} />
          ) : (
            <NumberInput defaultValue={item.BillableQuantity} onChange={_billableQuantity} disabled={readOnly} />
          )}
        </View>
        {(_item.Report.Levels?.length == 1 && (_item.Report.Materials?.length || 0) > 0) ? (
          <MaterialCommunityIcons
            name={open ? 'chevron-up' : 'chevron-down'}
            size={28}
            onPress={_toggleOpen}
            style={{ paddingLeft: 2 }}
          />
        ) : (
          <View style={{ width: 30 }} />
        )}
      </View>
    )
  }

  const _renderMaterial = ({ item, index }: { item: Material, index: number }) => {
    const _approvedQuantity = (v: number) => _approveMaterialQuantityIndex(v, index)
    return (
      <View
        key={`rm-${index}`}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 3,
        }}
      >
        <View style={{ flex: 2 }} />
        <Text style={{ flex: 3 }}>{item.Name}</Text>
        <Text style={{ flex: 2, textAlign: 'center' }}>{item.UnitOfMeasure}</Text>
        <View style={{ flex: 1 }}>
          {(((item.ApprovedQuantity || 0) == 0 && approvedQuantity > 0) || (item.ApprovedQuantity || 0) > approvedQuantity * item.UnitQuantity) && (
            <Text style={{ color: 'red', textAlign: 'center' }}>
              {round(approvedQuantity * item.UnitQuantity, 2).toFixed(2)}
            </Text>
          )}
        </View>
        <Text style={{ width: 85, paddingHorizontal: 5, textAlign: 'right' }}>
          {f2(item.Quantity || 0)}
        </Text>
        <View style={{ width: 110 }}>
          <NumberInput defaultValue={item.ApprovedQuantity} onChange={_approvedQuantity} disabled={readOnly} />
        </View>
        <View style={{ width: 140 }} />
      </View>
    )
  }

  return (
    <View key={`mrm-${index}`}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 2,
          borderTopColor: theme.border,
          borderTopWidth: 1,
          paddingTop: 3
        }}
      >
        <Text style={{ flex: 2 }}>{_item.DayReport.Resource.FirstName} {item.DayReport.Resource.LastName}</Text>
        <Text style={{ flex: 1 }}>{_item.DayReport.DateTime.toLocaleDateString()}</Text>
        <Text style={{ flex: 1, textAlign: 'right' }}>{decimalHoursToSexagesimal(_item.Report.Duration || 0)}</Text>
        <View style={{ flex: 2, alignItems: 'center' }}>
          <TimePicker
            value={getStringFromTime((_item.Report.ApprovedDuration || 0) * 60)}
            onChange={_approvedDuration}
            disabled={readOnly}
          />
        </View>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          {((_item.Report.ApprovedDuration || 0) > (_item.Report.UnitTime || 0) * approvedQuantity && _item.Report.Type != LineType.POSITION_CAR) ? (
            <Text style={{ flex: 1, color: 'red', paddingLeft: 6, textAlign: 'center' }}>
              {getStringFromTime((_item.Report?.UnitTime || 0) * approvedQuantity * 60)}
            </Text>
          ) : (<View style={{ flex: 1 }} />)}
        </View>

        {_item.Report.Levels?.length == 1 ? (
          _render({ item: _item.Report.Levels[0], index: 0 })
        ) : (
          <View style={{ width: 505 }} />
        )}

        {((_item.Report.Levels?.length || 0) > 1 && (_item.Report.Materials?.length || 0) > 0) && (
          <MaterialCommunityIcons
            name={open ? 'chevron-up' : 'chevron-down'}
            size={24}
            onPress={_toggleOpen}
          />
        )}

      </View>

      {_item.Report.Notes.length > 0 && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: 100, paddingLeft: 3 }}>{t('NOTES')}</Text>
          <Text style={{ flex: 1, color: 'red' }}>{_item.Report.Notes}</Text>
        </View>
      )}

      {(_item.Report.Levels?.length || 0) > 1 && (
        <FlatList
          data={_item.Report.Levels || []}
          renderItem={_render}
          // @ts-ignore
          listKey={`report-levels-${_item.Report.ID}`}
          key={`report-levels-${_item.Report.ID}`}
          keyExtractor={(item, index) => `report-levels-${_item.Report.ID}-${index}-${item.ID}`}
        />
      )}
      {(open && _item.Report.Materials && _item.Report.Materials.length > 0) && (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 0.5 }} />
          <Text style={{ flex: 4.5, fontWeight: 'bold' }}>{t('MATERIALS')}</Text>
        </View>
      )}

      {open && (
        <FlatList
          data={_item.Report.Materials || []}
          renderItem={_renderMaterial}
          // @ts-ignore
          listKey={`report-material-${_item.Report.ID}`}
          key={`report-material-${_item.Report.ID}`}
          keyExtractor={(item, index) => `report-material-${_item.Report.ID}-${index}-${item.ID}`}
        />
      )}
    </View>
  )
}

export default MeasureLineReportDetails