import React, { useState } from "react";
import { t } from "i18n-js";
import { Address, Client } from "../../models";
import getTheme from "../../constants/Colors";
import { FlatList, Pressable, Text, View } from "react-native";
import { newAddress } from './utils';
import ClientAddress from "./ClientAdress";

interface Props {
  client: Client;
  setClient: (client: Client) => void;
}

const ClientAddresses = ({client, setClient}: Props) => {
  const theme = getTheme();

  const [selected, setSelected] = useState<number>(0);

  const _addressItem = ({item, index}: {item: Address, index: number}) => {
    const _press = () => {
      setSelected(index);
    }

    const background = selected === index ? "white" : theme.background;

    return (
      <Pressable
        style={{
          backgroundColor: background,
          padding: 5,
          borderWidth: 1,
          borderColor: theme.border,
          borderRadius: 5,
          marginTop: 1,
          alignItems: 'center',
        }}
        onPress={_press}
      >
        <Text>{item.Name}</Text>
      </Pressable>
    );
  };

  const _new = () => {
    const _press = () => {
      const address = newAddress(String(client.ID));
      setSelected(client.Addresses.length);
      setClient({
        ...client,
        Addresses: [
          ...client.Addresses,
          address,
        ],
      });
    };

    return (
      <Pressable
        onPress={_press}
        style={{
          width: "100%",
          paddingVertical: 2,
          borderWidth: 1,
          borderColor: theme.border,
          borderRadius: 5,
          marginTop: 1,
          alignItems: 'center',
        }}
      >
        <Text>+ {t('NEW_ADDRESS')}</Text>
      </Pressable>
    );
  };

  const setAddress = (address: Address) => {
    setClient({
      ...client,
      Addresses: client.Addresses.map((item, index) => {
        if (index !== selected) {
          return item;
        }
        return address;
      }),
    });
  };

  return (
    <View style={{flexDirection: 'column', paddingTop: 5}}>
      <Text style={{fontSize: 20}}>{t('ADDRESSES')}</Text>

      <View style={{flexDirection: 'row', paddingTop: 3}}>
        <View style={{width: "15%"}}>
          <FlatList
            data={client.Addresses}
            renderItem={_addressItem}
            ListFooterComponent={_new}
            key={`client-address-flat-list`}
            keyExtractor={(item, index) => `client-address-${index}-${item.ClientID}-${item.ID}`}
          />
        </View>

        { client.Addresses[selected] && (
          <ClientAddress 
            address={client.Addresses[selected]}
            setAddress={setAddress}
          />
        )}
      </View>
    </View>
  );
};

export default ClientAddresses;