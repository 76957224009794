import React, { useEffect, useMemo, useState } from "react";
import { AccountingDocumentType, Contract, Discount, Doc, LineType } from '../../models'
import { FlatList, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { PriceLevel } from "../../store/reducers/settingsReducer";
import { t } from "i18n-js";
import { getOrderByContractId } from "../../api/DocAPI";
import ContractFinancialOverviewInvoice from "./ContractFinancialOverviewInvoice";

import { locale } from "i18n-js";
import { alert } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { sentryCapture } from "../../utils/sentryCapture";
import getTheme from "../../constants/Colors";

interface Props {
  order: Doc;
  contract: Contract;
}

const ContractFinancialOverview = ({order, contract}: Props) => {

  const theme = getTheme()

  const dispatch = useDispatch();

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);

  const [m2, setM2] = useState<number>();

  const invoices = useMemo(
    () => contract.Docs.filter(d => d.Type.Config.ConstructionSiteViewGroup == 'invoice').sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime()), 
    [contract]
  );

  const invoicesTotalVatExcl = useMemo(
    () => invoices.reduce((p, v) => p + (v.Type.Config.AccountingDocument == AccountingDocumentType.CreditNote ? -1 : 1) * ((v.SapDocTotal || 0) - (v.SapVatSum || 0) - (v.SapRoundDif || 0)), 0),
    [invoices]
  )
  const invoicesTotal = useMemo(
    () => invoices.reduce((p, v) => p + (v.Type.Config.AccountingDocument == AccountingDocumentType.CreditNote ? -1 : 1) * (v.SapDocTotal || 0), 0),
    [invoices]
  )
  const paymentsTotal = useMemo(
    () => invoices.reduce((p, v) => p + (v.Payments?.reduce((p, v) => p + v.Amount, 0) || 0), 0),
    [invoices]
  )
  const paymentsDiscount = useMemo(
    () => invoices.reduce((p, v) => p + ((v.Payments?.length || 0) > 0 ? (v.SapDocTotal || 0) - (v.Payments?.reduce((p, v) => p + v.Amount, 0) || 0) : 0), 0),
    [invoices]
  )

  const currencyFormatter = new Intl.NumberFormat(locale, { style: 'currency', currency: order.Currency.ID });
  const formatter = new Intl.NumberFormat(locale);

  useEffect(() => {
    getOrderByContractId(contract.ID).then(o => {
      setM2(o.Lines.reduce((p, v) => v.LineType == LineType.POSITION_ENGINEERING ? p + v.Quantity : p, 0));
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert('ERROR','ERROR');
      }
    });
  }, [contract]);

  if (showPriceLevel == PriceLevel.NO_PRICE) {
    return (
      <></>
    );
  }

  const _renderDiscount = ({item}: {item: Discount}) => {
    return (
      <View style={{
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Text>{item.name}</Text>
        <Text>{item.isNumeric ? `-${currencyFormatter.format(item.value)}` : `(-${currencyFormatter.format(item.totalValue)})   -${formatter.format(item.value)}%`}</Text>
      </View>
    )
  }

  const _renderInvoice = ({item}: {item: Doc}) => (
    <ContractFinancialOverviewInvoice item={item} />
  );

  const vatPerc = (order.SapVatSum || 0) / ((order.SapDocTotal || 0) - (order.SapVatSum || 0)) * 100;
  const total = (order.SapDocTotal || 0) - (order.SapVatSum || 0) - (order.SapRoundDif || 0);

  return (
    <View style={{marginTop: 10}}>
      {(m2 != undefined && m2 > 0) && <Text style={{marginBottom: 10}}>{t('PIT_WALL_M2')} {formatter.format(m2)}</Text>}

      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <Text>{t('TOTAL_VAT_EXCL')}:</Text>
        <Text>{currencyFormatter.format(order.PriceBlock)}</Text>
      </View>

      <FlatList
        data={order.Discounts}
        renderItem={_renderDiscount}
        key={`contract-financial-overview-discount-${order.ID}`}
        // @ts-ignore
        listKey={`contract-financial-overview-discount-${order.ID}`}
        keyExtractor={(_, index) => `contract-financial-overview-dicount=${order.ID}-${index}`}
      />

      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10}}>
        <Text>{t('TOTAL_VAT_EXCL')}:</Text>
        <Text>{currencyFormatter.format(total)}</Text>
      </View>

      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10}}>
        <Text>{t('VAT')}: {formatter.format(vatPerc)} %</Text>
        <Text>{currencyFormatter.format(order.SapVatSum || 0)}</Text>
      </View>

      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10}}>
        <Text>{t('ROUNDING')}:</Text>
        <Text>{currencyFormatter.format(order.RoundingDiff || 0)}</Text>
      </View>

      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10}}>
        <Text>{t('TOTAL_VAT_INCL')}:</Text>
        <Text>{currencyFormatter.format(order.SapDocTotal || 0)}</Text>
      </View>

      <Text style={{marginTop: 15, fontWeight: 'bold'}}>{t('INVOICES')}</Text>

      <View style={{
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Text style={{flex: 1, paddingRight: 2}}>{t('DATE')}</Text>
        <Text style={{flex: 1, paddingHorizontal: 2}}>{t('NUMBER')}</Text>
        <Text style={{flex: 2, paddingHorizontal: 2}}>{t('DESCRIPTION')}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{t('TOTAL_VAT_EXCL')}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{t('TOTAL_VAT_INCL')}</Text>
        <Text style={{flex: 1, textAlign: 'right'}}>{t('PAYMENT_DATE')}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{t('PAYMENT_AMOUNT')}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{t('DISCOUNT')}</Text>
      </View>

      <FlatList
        data={invoices}
        renderItem={_renderInvoice}
        key={`contract-financial-overview-invoices`}
        // @ts-ignore
        listKey={`contract-financial-overview-invoices`}
        keyExtractor={(item, index) => `contract-financial-overview-invoice-${index}-${item.TypeID}-${item.ID}`}
      />

      <View
        style={{
          marginTop: 10,
          paddingTop: 5,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTopColor: theme.border,
          borderTopWidth: 1,
        }}
      >
        <Text style={{flex: 1, paddingHorizontal: 2, fontWeight: 'bold'}}>{t('TOTAL')}</Text>
        <View style={{flex: 3}}/>
        <Text style={{flex: 1, textAlign: 'right', fontWeight: 'bold'}}>{formatter.format(invoicesTotalVatExcl)}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2, fontWeight: 'bold'}}>{formatter.format(invoicesTotal)}</Text>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2, fontWeight: 'bold'}}>{formatter.format(paymentsTotal)}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2, fontWeight: 'bold'}}>{formatter.format(paymentsDiscount)}</Text>
      </View>
    </View>
  );
};

export default ContractFinancialOverview;