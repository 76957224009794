import React, { useMemo, useState } from 'react';
import { AccountingDocumentType, Doc, Payment } from '../../models';
import { Pressable, Text, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { locale } from "i18n-js";

interface Props {
  item: Doc;
}

const ContractFinancialOverviewInvoice = ({item}: Props) => {

  const [open, setOpen] = useState<boolean>(false);
  const _toggle = () => setOpen(open => !open);

  const vatExcl = (item.SapDocTotal || 0) - (item.SapVatSum || 0) - (item.SapRoundDif || 0);
  
  const paymentsTotal = useMemo(
    () => item.Payments?.reduce((p, v) => p + v.Amount, 0) || 0,
    [item]
  );

  const paymentDiscount = useMemo(() => {
    if ((item.Payments?.length || 0) == 0) {
      return 0;
    }
    return (item.SapDocTotal || 0) - paymentsTotal;
  }, [item, paymentsTotal])

  const paymentDate = item.Payments?.length == 1 ? new Date(item.Payments[0].Date) : undefined;
  const formatter = new Intl.NumberFormat(locale, {minimumFractionDigits: 2});
  const sign = item.Type.Config.AccountingDocument == AccountingDocumentType.Invoice ? '' : '-'

  const _renderPayment = ({item}: {item: Payment}) => {
    return (
      <View style={{
        marginTop: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <View style={{flex: 6}}/>
        <Text style={{flex: 1, paddingHorizontal: 2}}>{new Date(item.Date).toLocaleDateString()}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{formatter.format(item.Amount)}</Text>
        <View style={{flex: 1}}/>
      </View>
    )
  } 

  return (
    <View>
      <Pressable
        onPress={_toggle}
        style={{
          marginTop: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text style={{flex: 1, paddingHorizontal: 2}}>{new Date(item.CreatedDate).toLocaleDateString()}</Text>
        <Text style={{flex: 1, paddingHorizontal: 2}}>{item.IDString}</Text>
        <Text style={{flex: 2, paddingHorizontal: 2}}></Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{sign}{formatter.format(vatExcl)}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{sign}{formatter.format(item.SapDocTotal || 0)}</Text>
        <Text style={{flex: 1, textAlign: 'right'}}>{paymentDate?.toLocaleDateString()}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{formatter.format(paymentsTotal)}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 2}}>{formatter.format(paymentDiscount)}</Text>
      </Pressable>
      {((item.Payments?.length || 0) > 2 && open) && (
        <FlatList
          data={item.Payments}
          renderItem={_renderPayment}
          key={`contract-financial-overview-invoice-${item.ID}-payments`}
          // @ts-ignore
          listKey={`contract-financial-overview-invoice-${item.ID}-payments`}
          keyExtractor={(_, index) => `contract-financial-overview-invoice-${item.ID}-p-${index}`}
        />
      )}
    </View>
  );
};

export default ContractFinancialOverviewInvoice;