import React from 'react'
import { Text, View } from 'react-native'
import DatePicker from '../DatePicker'
import { t } from 'i18n-js'
import TimePicker from '../TimePicker'

interface AbsenceDaysProps {
  startDate?: Date
  endDate?: Date
  time: string
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  setTime: React.Dispatch<React.SetStateAction<string>>
}

const AbsenceDays = ({
  startDate,
  endDate,
  time,
  setStartDate,
  setEndDate,
  setTime,
}: AbsenceDaysProps) => {
  return (
    <View>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      }}>
        <Text style={{flex: 1}}>{t('START_DATE')}</Text>
        <DatePicker
          value={startDate || new Date()}
          onChange={setStartDate}
        />
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      }}>
        <Text style={{flex: 1}}>{t('END_DATE')}</Text>
        <DatePicker
          value={endDate || new Date()}
          onChange={setEndDate}
        />
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      }}>
        <Text style={{flex: 1}}>{t('END_DATE')}</Text>
        <TimePicker
          value={time}
          onChange={setTime}
        />
      </View>
    </View>
  )
}

export default AbsenceDays