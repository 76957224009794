import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import { ActivityIndicator, FlatList, TextInput } from "react-native";
import React, { useEffect, useMemo, useState } from "react";
import { View, Text } from "react-native";
import getTheme from "../../../constants/Colors";
import { ClientOverview, ConstructionSite, Invoice } from "../../../models";
import ClientOverViewConstructionSiteItem from "./ClientOverviewConstructionSiteItem";
import NoElementTemplate from "./NoElementTemplate";
import styles from "./style";
import { SearchScreens } from "../SearchScreens";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { alert } from "../../../hooks/Alert";
import { getClientOverview } from "../../../api/ClientsAPI";
import { sentryCapture } from "../../../utils/sentryCapture";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/action/authAction";
import { userCanUpdateBusinessPartners } from "../../../models/permissions";
import { Reducer } from "../../../store/reducers";

type RenderConstructionSiteItem = {
  item: ConstructionSite;
};

type RenderInvoiceItem = {
  item: Invoice;
};

interface Props {
  route: {
    params?: {
      clientId?: string;
    }
  }
}

const _filter = (constructionSites: ConstructionSite[], search: string) => {
  if (search.length == 0) {
    return constructionSites
  }
  const s = search.toLowerCase()
  return constructionSites.filter(cs => 
    cs.Name.toLowerCase().includes(s)
    || cs.Code?.toLowerCase().includes(s)
    || cs.IDString.toLowerCase().includes(s)
    || cs.Address.toLowerCase().includes(s)
  )
}

const ClientOverviewScreen = ({route}:Props) => {

  const navigation = useNavigation();
  const theme = getTheme();
  const dispatch = useDispatch();

  const isFocused = useIsFocused()

  const user = useSelector((state: Reducer) => state.user)

  const [clientOverview, setClientOverview] = useState<ClientOverview>();

  const [search, setSearch] = useState<string>('')

  const constructionSites = useMemo(() => {
    if (!clientOverview) {
      return []
    }
    return _filter(clientOverview.ConstructionSites, search)
  }, [search, clientOverview])

  const offers = useMemo(() => {
    if (!clientOverview) {
      return []
    }
    return _filter(clientOverview.Offers, search)
  }, [search, clientOverview])

  const pull = () => {
    if (!route.params?.clientId) {
      alert("ERROR", "NOT_LOADED_CLIENT");
      navigation.goBack();
      return;
    }
    getClientOverview(route.params.clientId).then(setClientOverview).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR", "LOADING_CLIENT_OVERVIEW");
        navigation.goBack();
      }
    })
  }

  useEffect(() => {
    isFocused && pull()
  }, [isFocused])

  if (!clientOverview) return (
    <View style={{alignItems: 'center', justifyContent: 'center'}}>
      <ActivityIndicator />
    </View>
  );

  const noOfferElement = NoElementTemplate("NO_OFFER_FOUND_FOR_CLIENT");
  const noConstructionSiteElement = NoElementTemplate("NO_CONSTRUCTION_SITE_FOUND_FOR_CLIENT");
  const noInvoiceElement = NoElementTemplate("NO_INVOICE_FOUND_FOR_CLIENT");

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <AntDesign
          style={{ alignSelf: "center" }}
          size={24}
          color={"black"}
          name="left"
          onPress={() => navigation.goBack()}
        />
        <View style={styles.headerText}>
          <View style={{ flexDirection: "row", alignItems: 'center' }}>
            <Text style={{ fontSize: 16, color: theme.text, paddingRight: 5}}>
              {clientOverview.ID} -
            </Text>
            <Text style={{ fontSize: 26, color: theme.text }}>
              {clientOverview.Name}
            </Text>
            {userCanUpdateBusinessPartners(user) && (
              <MaterialCommunityIcons
                style={{ alignSelf: "center", marginLeft: 20 }}
                size={24}
                color={"black"}
                name="account-edit"
                // @ts-ignore
                onPress={() => navigation.navigate(SearchScreens.UPDATE_CLIENT, {clientId: clientOverview.ID})}
              />
            )}
          </View>
        </View>
      </View>

      {clientOverview.FreeText.length > 0 && (
        <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingTop: 5}}>
          <Text style={{marginTop: 5, marginRight: 5}}>{t('NOTES')}:</Text>

          <Text style={{flex: 1, padding: 5, borderRadius: 5, borderColor: theme.border, borderWidth: 1}}>
            {clientOverview.FreeText}
          </Text>
        </View>
      )}

      <TextInput
        style={{
          borderColor: theme.border,
          borderWidth: 1,
          borderRadius: 5,
          padding: 5,
          margin: 10,
          marginBottom: 5,
          backgroundColor: 'white',

        }}
        placeholder={t('FILTER')}
        onChangeText={setSearch}
      />

      <View style={{flex: 1, flexDirection: 'row', marginBottom: 10 }}>
        <View style={{flex: 1, margin: 10, backgroundColor: 'white', padding: 10, borderRadius: 5}}>
          <Text style={{alignSelf: 'center', fontSize: 18}}>
            {t('CONSTRUCTION_SITES')}
          </Text>
          <FlatList
            data={constructionSites}
            renderItem={({item, index}) => <ClientOverViewConstructionSiteItem item={item} index={index} />}
            ListEmptyComponent={noConstructionSiteElement}
            key={`client-overview-construction-sites`}
            // @ts-ignore
            listKey={`client-overview-construction-sites`}
            keyExtractor={(item, index) => `client-overview-construction-sites-${index}-${item.ID}`}
          />
        </View>
        
        <View style={{flex: 1, margin: 10, backgroundColor: 'white', padding: 10, borderRadius: 5}}>
          <Text style={{alignSelf: 'center', fontSize: 18}}>
            {t('OFFERS')}
          </Text>
          <FlatList
            data={offers}
            renderItem={({item, index}) => <ClientOverViewConstructionSiteItem item={item} index={index} />}
            ListEmptyComponent={noConstructionSiteElement}
            key={`client-overview-offers`}
            // @ts-ignore
            listKey={`client-overview-offers`}
            keyExtractor={(item, index) => `client-overview-offers-${index}-${item.ID}`}
          />
        </View>

        <View style={{flex: 1}}/>
      </View>
    </View>
  );
};

export default ClientOverviewScreen;
