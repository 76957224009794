import React, { useEffect, useState } from 'react'
import { Contract, DayReport, ExternalWorkInvoice, LineType } from '../../models'
import Modal from 'react-native-modal'
import { ActivityIndicator, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import getTheme from '../../constants/Colors'
import { createDayReport, getActivities, getDayReport, updateDayReport } from '../../api/ReportAPI'
import { alert } from '../../hooks/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../store/action/authAction'
import { sentryCapture } from '../../utils/sentryCapture'
import Button from '../Button'
import ExternalWorkInvoiceDayReportPreview from './ExternalWorkInvoiceDayReportPreview'
import ExternalWorkInvoiceData from './ExternalWorkInvoiceData'
import { activitiesToNewReport } from '../../shared-utils/reports/convert'
import { Reducer } from '../../store/reducers'
import ExternalWorkInvoiceDayReportCreate from './ExternalWorkInvoiceDayReportCreate'
import ExternalWorkInvoiceDayReportChoose from './ExternalWorkInvoiceDayReportChoose'

interface ExternalWorkInvoiceModalProps {
  contract: Contract
  externalWorkInvoice: ExternalWorkInvoice
  onClose: () => void
  pull: () => void
  initCreateExternalWorkInvoiceReport?: boolean
}

const ExternalWorkInvoiceModal = ({
  contract,
  externalWorkInvoice,
  onClose,
  pull,
  initCreateExternalWorkInvoiceReport = false,
}: ExternalWorkInvoiceModalProps) => {

  const theme = getTheme()
  const dispatch = useDispatch()

  const resource = useSelector((state: Reducer) => state.general.resource)

  const [
    createExternalWorkInvoiceReport,
    setCreateExternalWorkInvoiceReport,
  ] = useState<boolean>(initCreateExternalWorkInvoiceReport)
  const [
    chooseExternalWorkInvoiceReport,
    setChooseExternalWorkInvoiceReport,
  ] = useState<boolean>(false)
  const [dayReportLoading, setDayReportLoading] = useState(false)
  const [dayReport, setDayReport] = useState<DayReport | undefined>()

  useEffect(() => {
    if (initCreateExternalWorkInvoiceReport) {
      _showCreateExternalWorkInvoiceReport()
    }
  }, [])

  useEffect(() => {
    if (externalWorkInvoice.DayReportId != undefined) {
      setDayReportLoading(true)
      getDayReport(externalWorkInvoice.DayReportId)
        .then((response) => {
          setDayReport(response)
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            alert('ERROR', 'UNAUTHORIZED')
            dispatch(logout())
            return
          }
          sentryCapture(error)
          alert('ERROR', 'ERROR_LOADING_DAY_REPORT')
        })
        .finally(() => {
          setDayReportLoading(false)
        })
    }
  }, [externalWorkInvoice.DayReportId])

  const _showChooseExternalWorkInvoiceReport = () => {
    setChooseExternalWorkInvoiceReport(true)
  }

  const _showCreateExternalWorkInvoiceReport = async () => {
    if (!resource) {
      alert('ERROR', 'ERROR_CREATING_DAY_REPORT')
      return
    }
    setDayReportLoading(true)
    getActivities(externalWorkInvoice.ContractID)
        .then((response) => {
          const dayReport = activitiesToNewReport({
            ...response,
            activities: response.activities.filter(a => a.Type == LineType.POSITION_EXTERNAL_WORK),
          }, resource)
          dayReport.DateTime = new Date()
          dayReport.TravelTime = 0
          dayReport.TotalTime = 0
          dayReport.BreakTime = 0

          setCreateExternalWorkInvoiceReport(true)
          setDayReport(dayReport)
        })
        .catch(e => {
          if (e.response?.status == 401) {
            alert('ERROR', 'UNAUTHORIZED')
            dispatch(logout())
            return
          }
          sentryCapture(e)
          alert('ERROR', 'ERROR_LOADING_ACTIVITIES')
        })
        .finally(() => setDayReportLoading(false))
  }

  const _closeExternalWorkInvoiceReport = () => setCreateExternalWorkInvoiceReport(false)

  const _close = () => {
    if (externalWorkInvoice.DayReportId == undefined && dayReport != undefined) {
      pull()
    }
    if (externalWorkInvoice.DayReportId != undefined && dayReport == undefined) {
      pull()
    }
    onClose()
  }

  const _createExternalWorkInvoiceReport = () => {
    if (dayReport == undefined) {
      alert('ERROR', 'ERROR_CREATING_DAY_REPORT')
      return
    }

    setDayReportLoading(true)
    createDayReport(dayReport, false)
        .then((dayReport) => {
          if (dayReport) {
            setDayReport(dayReport)
          }
          setCreateExternalWorkInvoiceReport(false)
        })
        .catch(e => {
          if (e.response?.status == 401) {
            alert('ERROR', 'UNAUTHORIZED')
            dispatch(logout())
            return
          }
          sentryCapture(e)
          alert('ERROR', 'ERROR_CREATING_DAY_REPORT')
        })
        .finally(() => setDayReportLoading(false))
  }

  const _choose = (dayReportId: number) => {
    setDayReportLoading(true)
    getDayReport(dayReportId)
      .then( dayReport => {
        updateDayReport({
          ...dayReport,
          ExternalWorkInvoiceId: externalWorkInvoice.ID,
          ExternalWorkInvoice: externalWorkInvoice,
        }).then(setDayReport).catch(e => {
          if (e.response?.status == 401) {
            alert('ERROR', 'UNAUTHORIZED')
            dispatch(logout())
            return
          }
          sentryCapture(e)
          alert('ERROR', 'ERROR_UPDATING_DAY_REPORT')
        }).finally(() => setDayReportLoading(false))
      })
      .catch(e => {
        setDayReportLoading(false)
        if (e.response?.status == 401) {
          alert('ERROR', 'UNAUTHORIZED')
          dispatch(logout())
          return
        }
        sentryCapture(e)
        alert('ERROR', 'ERROR_LOADING_DAY_REPORT')
      })
  }

  const _unlink = () => {
    if (externalWorkInvoice.DayReportId) {
      getDayReport(externalWorkInvoice.DayReportId!)
          .then((dayReport) => {
            updateDayReport({
              ...dayReport,
              ExternalWorkInvoiceId: undefined,
            }).then(setDayReport).catch(e => {
              if (e.response?.status == 401) {
                alert('ERROR', 'UNAUTHORIZED')
                dispatch(logout())
                return
              }
              sentryCapture(e)
              alert('ERROR', 'ERROR_UNLINKING_DAY_REPORT')
            }).finally(() => setDayReportLoading(false))
          })
          .catch(e => {
            setDayReportLoading(false)
            if (e.response?.status == 401) {
              alert('ERROR', 'UNAUTHORIZED')
              dispatch(logout())
              return
            }
            sentryCapture(e)
            alert('ERROR', 'ERROR_LOADING_DAY_REPORT')
          })
    }
  }

  return (
    <Modal
      onBackdropPress={_close}
      isVisible={true}
      style={{
        padding: 15,
        borderRadius: 15,
        backgroundColor: 'white',
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Text style={{fontSize: 24, flex: 1}}>
          {t('EXTERNAL_WORK_INVOICE')}
        </Text>

        {externalWorkInvoice.DayReportId != undefined && (
          <Button
            onPress={_unlink}
            style={{backgroundColor: theme.blue}}
          >
            <MaterialCommunityIcons
              name='link-off'
              size={24}
              color='white'
            />
          </Button>
        )}

        {(externalWorkInvoice.DayReportId == undefined && !createExternalWorkInvoiceReport) && (
          <>
            <Button
              onPress={_showChooseExternalWorkInvoiceReport}
              style={{backgroundColor: theme.blue}}
            >
              <MaterialCommunityIcons
                name='file-document-multiple'
                size={24}
                color='white'
              />
            </Button>

            <Button
              onPress={_showCreateExternalWorkInvoiceReport}
              style={{backgroundColor: theme.blue}}
            >
              <MaterialCommunityIcons
                name='plus'
                size={24}
                color='white'
              />
            </Button>
          </>
        )}

        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={_close}
        />
      </View>

      <ExternalWorkInvoiceData
        externalWorkInvoice={externalWorkInvoice}
        dayReport={dayReport}
        createDayReport={createExternalWorkInvoiceReport}
      />

      {dayReportLoading && (
        <View style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <ActivityIndicator color={theme.mainColor} />
        </View>
      )}

      {(dayReport && !createExternalWorkInvoiceReport) && (
        <ExternalWorkInvoiceDayReportPreview
          externalWorkInvoice={externalWorkInvoice}
          dayReport={dayReport}
        />
      )}

      {(!chooseExternalWorkInvoiceReport && !createExternalWorkInvoiceReport && externalWorkInvoice.DayReportId == undefined && dayReport == undefined) && (
        <View style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Text>{t('NO_DAY_REPORT')}</Text>
        </View>
      )}

      {(createExternalWorkInvoiceReport && dayReport != undefined) && (
        <ExternalWorkInvoiceDayReportCreate
          dayReport={dayReport}
          setDayReport={e => setDayReport(e as DayReport | undefined)}
          closeExternalWorkInvoiceModal={_closeExternalWorkInvoiceReport}
          createExternalWorkInvoiceReport={_createExternalWorkInvoiceReport}
          loading={dayReportLoading}
        />
      )}

      {(chooseExternalWorkInvoiceReport && dayReport == undefined) && (
        <ExternalWorkInvoiceDayReportChoose
          contract={contract}
          choose={_choose}
        />
      )}
    </Modal>
  )
}

export default ExternalWorkInvoiceModal