enum ConstructionSiteViews {
  BuildingSiteOverview,
  ContractOverview,
  Document,
  Order,
  DayReports,
  Measure,
  FileType,
  PDF,
  ExternalWorkInvoices,
}

export default ConstructionSiteViews;