import React, { useEffect, useState } from 'react'
import getTheme from '../../constants/Colors'
import { useDispatch, useSelector } from 'react-redux'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Reducer } from '../../store/reducers'
import { ActivityIndicator, FlatList, Pressable, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { OfflineMeasure } from '../../models'
import { toggleShowOfflineMeasures } from '../../store/action/settingsAction'
import { getOfflineMeasures, removeOfflineMeasure } from '../../utils/measure/util'
import { alert } from '../../hooks/Alert'
import { sentryCapture } from '../../utils/sentryCapture'
import { SearchScreens } from '../../screens/search/SearchScreens'


const OfflineMeasures = () => {

  const theme = getTheme()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const showOfflineMeasures = useSelector(
    (state: Reducer) => state.settings.showOfflineMeasures
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [offlineMeasures, setOfflineMeasures] = useState<OfflineMeasure[]>([])

  const _toggleShowOfflineMeasures = () => dispatch(toggleShowOfflineMeasures())

  const loadOfflineData = async () => {
    try {
      setLoading(true)
      const offlineMeasures = await getOfflineMeasures()
      setOfflineMeasures(offlineMeasures)
    } catch (e) {
      sentryCapture(e)
      alert('ERROR', 'ERROR_LOADING_OFFLINE_MEASURES')
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isFocused) {
      loadOfflineData()
    }
  }, [isFocused])

  const _render = ({ item, index }: { item: OfflineMeasure, index: number }) => {
    const _press = () => {
      // @ts-ignore
      navigation.navigate(SearchScreens.MEASURE_CREATE, {
        uuid: item.uuid,
      })
    }
    const _delete = () => {
      removeOfflineMeasure(item.uuid)
        .then(() => {
          setOfflineMeasures(offlineMeasures.filter(m => m.uuid !== item.uuid))
        })
        .catch(e => {
          sentryCapture(e)
          alert('ERROR', 'ERROR_DELETING_OFFLINE_MEASURE')
        })
    }
    return (
      <Pressable
        key={`offline-measure-${index}-${item.uuid}`}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 5,
          borderTopWidth: index == 0 ? 0 : 1,
          borderTopColor: theme.border,
        }}
        onPress={_press}
      >
        <Text style={{ fontSize: 16, width: 100, paddingRight: 5 }}>
          {item.constructionSiteCode}
        </Text>
        <Text style={{ fontSize: 16, flex: 1, paddingHorizontal: 10 }}>
          {item.constructionSiteName}
        </Text>
        <Text style={{ fontSize: 16, flex: 1, paddingHorizontal: 10 }}>
          {item.contractName}
        </Text>

        <Text style={{ fontSize: 16, width: 200, paddingHorizontal: 10 }}>
          {item.date.toLocaleDateString()}  {item.date.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})}
        </Text>

        <MaterialCommunityIcons
          size={24}
          color='black'
          name='delete'
          onPress={_delete}
          style={{ padding: 5 }}
        />
      </Pressable>
    )
  }

  if (offlineMeasures.length === 0) {
    return <></>
  }

  return (
    <View>
      <Pressable
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          paddingBottom: showOfflineMeasures ? 10 : 0,
        }}
        onPress={_toggleShowOfflineMeasures}
      >
        <Text style={{ fontSize: 18, flex: 1 }}>
          {t('OFFLINE_MEASURES')}
        </Text>

        {loading && (
          <ActivityIndicator />
        )}

        <MaterialCommunityIcons
          size={24}
          color='black'
          name={showOfflineMeasures ? 'eye' : 'eye-off'}
          style={{ paddingLeft: 10 }}
        />
      </Pressable>

      {showOfflineMeasures && (
        <View style={{ paddingHorizontal: 10, backgroundColor: 'white', borderRadius: 5 }}>
          <FlatList
            data={offlineMeasures}
            renderItem={_render}
          />
        </View>
      )}
    </View>
  )
}

export default OfflineMeasures