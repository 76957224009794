import React from "react";
import { FileType } from "../../../models";
import ConstructionSiteDetailsFileType from "./ConstructionSiteDetailsFileType";
import { FlatList } from "react-native";
import ConstructionSiteViews from "./ConstructionSiteViews";

interface Props {
  fileTypes: FileType[];
  setFileTypesToOpen: (fileType: FileType) => void;
  fileTypesToOpen?: FileType;
  setSelectedView: (selectedView: ConstructionSiteViews) => void;
}

const ConstructionSiteDetailsFileTypes = ({fileTypes, setFileTypesToOpen, fileTypesToOpen, setSelectedView}: Props) => {

  const _render = ({item}: {item: FileType}) => {
    return (
      <ConstructionSiteDetailsFileType
          fileType={item}
          setSelectedView={setSelectedView}
          setFileTypesToOpen={setFileTypesToOpen}
          fileTypesToOpen={fileTypesToOpen}
        />
    );
  }

  return (
    <>
      <FlatList
        data={fileTypes}
        renderItem={_render}
        key={`construction-site-details-file-type`}
        // @ts-ignore
        listKey={`construction-site-details-file-type`}
        keyExtractor={(item, index) => `construction-site-details-file-type-${index}-${item.ID}`}
      />
    </>
  );
};

export default ConstructionSiteDetailsFileTypes;