import React, { useEffect, useState } from "react";
import { DayReport } from "../../models";
import { getDayReportsToApprove } from "../../api/ReportAPI";
import { t } from "i18n-js";
import { FlatList, View, Text, Pressable, ActivityIndicator } from "react-native";
import getTheme from "../../constants/Colors";
import { getStringFromTime } from "../../utils/StringUtils";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { ReportsScreens } from "./ReportsScreens";
import { logout } from "../../store/action/authAction";
import { alert } from "../../hooks/Alert";
import { useDispatch } from "react-redux";
import { sentryCapture } from "../../utils/sentryCapture";
import { round } from '../../shared-utils/NumberUtils'
import { SapDayReport } from '../../models/sap'

const DayReportsToApproveView = () => {

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [dayReportsToApprove, setDayReportsToApprove] = useState<SapDayReport[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const theme = getTheme();

  const pull = () => {
    if (loading) {
      return
    }
    setLoading(true);
    getDayReportsToApprove().then(r => {
      r = r.filter(d => !d.Contract.Measure)
      r.sort((a, b) => new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime())

      setDayReportsToApprove(r)
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_LOADING_ACTIVITIES");
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    isFocused && pull();
  }, [isFocused]);

  const _render = ({item, index}: {item: SapDayReport, index: number}) => {

    const _press = () => {
      // @ts-ignore
      navigation.navigate(ReportsScreens.APPROVE, {
        dayReportId: item.ID,
      });
    };

    return (
      <Pressable
        style={{
          flexDirection: 'row',
          paddingHorizontal: 6,
          paddingVertical: 9,
        }}
        onPress={_press}
        key={item.ID}
      >
        <Text style={{flex: 1}}>{item.ID}</Text>
        <Text style={{flex: 3}}>{item.ConstructionSite.Code} {item.ConstructionSite.Name}</Text>
        <Text style={{flex: 2}}>{item.Contract.Name}</Text>
        <Text style={{flex: 2}}>{item.Resource.FirstName} {item.Resource.LastName}</Text>
        <Text style={{flex: 2}}>{new Date(item.DateTime).toLocaleDateString()}</Text>
        <Text style={{flex: 1}}>{getStringFromTime(round(item.TotalTime + item.TravelTime - item.BreakTime, 0))}</Text>
      </Pressable>
    );
  }

  const separator = () => <View style={{borderTopWidth: 1, borderTopColor: theme.border}}/>;

  return (
    <View style={{padding: 15, flex: 1}}>
      <Text style={{fontSize: 24}}>{t("REPORT_TO_APPROVE")}</Text>

      <View 
        style={{
          flexDirection: 'row',
          paddingHorizontal: 6,
          marginTop: 9,
          alignItems: 'center',
        }}
      >
        <View style={{flex: 1}}/>
        <Text style={{flex: 3}}>{t("CONSTRUCTION_SITE")}</Text>
        <Text style={{flex: 2}}>{t("CONTRACT_NAME")}</Text>
        <Text style={{flex: 2}}>{t("RESOURCE")}</Text>
        <Text style={{flex: 2}}>{t("DATE")}</Text>
        <Text style={{flex: 1}}>{t("REPORT_HOURS")}</Text>
      </View>

      <View 
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          flex: 1
        }}
      >
        {
          loading ? <ActivityIndicator /> :
            <FlatList
              data={dayReportsToApprove}
              renderItem={_render}
              ItemSeparatorComponent={separator}
              style={{flex: 1}}
              key={`dayReportstoApprove`}
              // @ts-ignore
              listKey={`dayReportsToApprove`}
              keyExtractor={(item, index) => `dayReportsToApprove-${index}-${item.ID}`}
            />
        }
      </View>
    </View>
  );
};

export default DayReportsToApproveView;