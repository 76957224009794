import React, { useMemo } from 'react'
import { FlatList, View, Text, useWindowDimensions } from 'react-native'
import ChooseItem from './ChooseItem'

type Sizes = 'extra-small' | 'small' | 'medium' | 'large'

interface FlatChooseProps<T> {
  options: T[]
  value?: T
  onChange: (value: T) => void
  itemToKey: (item: T) => string
  itemToString: (item: T) => string
  size?: Sizes
}

const widthToColumns = (width: number, size: Sizes) => {
  if (size == 'extra-small') {
    return 1
  } else if (size === 'small') {
    if (width < 400) {
      return 1
    } else if (width < 800) {
      return 1
    } else if (width < 1000) {
      return 1
    } else if (width < 1200) {
      return 2
    } else {
      return 3
    }
  } else if (size === 'medium') {
    if (width < 400) {
      return 1
    } else if (width < 800) {
      return 1
    } else if (width < 1000) {
      return 2
    } else if (width < 1200) {
      return 3
    } else {
      return 4
    }
  } else {
    if (width < 400) {
      return 1
    } else if (width < 800) {
      return 2
    } else if (width < 1000) {
      return 3
    } else if (width < 1200) {
      return 4
    } else {
      return 5
    }
  }
}

const FlatChoose = <T,>({
  options,
  value,
  onChange,
  itemToKey,
  itemToString,
  size = 'medium',
}: FlatChooseProps<T>) => {

  const {
    width,
  } = useWindowDimensions()
  const numColumns = widthToColumns(width, size)

  const regularizedOptions = useMemo(() => {
    const regularizedOptions: (T | undefined)[] = [...options]
    const length = options.length % numColumns
    for (let i = 0; i < length; i++) {
      regularizedOptions.push(undefined)
    }
    return regularizedOptions
  }, [options, numColumns])

  const _render = ({item, index}: {item?: T, index: number}) => {

    return (
      <ChooseItem
        defaultSelected={value}
        item={item}
        itemToKey={itemToKey}
        itemToText={itemToString}
        onChange={onChange}
        key={`flat-choose-${index}-${item ? itemToKey(item) : ''}`}
      />
    )
  }

  return (
    <FlatList
      data={regularizedOptions}
      numColumns={numColumns}
      key={numColumns}
      renderItem={_render}
    />
  )
}

export default FlatChoose