import React, { useState } from 'react'
import { ConstructionSite, Contract, Doc } from '../../models'
import Modal from 'react-native-modal'
import { Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Button from '../Button'
import getTheme from '../../constants/Colors'
import FlatChoose from '../FlatChoose'
import ChooseContractModal from '../ChooseContractModal'
import { duplicateDoc } from '../../utils/doc/util'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'

interface DuplicateModalProps {
  onClose: () => void
  initDoc: Doc
  initContract: Contract
  initConstructionSite: ConstructionSite
  set: (constructionSite: ConstructionSite, contract: Contract, doc: Doc) => void
}

enum DuplicateType {
  DOC,
  CONTRACT,
  CONSTRUCTION_SITE,
}

const duplicateTypeToString = (type: DuplicateType) => {
  switch (type) {
    case DuplicateType.DOC:
      return t('DUPLICATE_DOC')
    case DuplicateType.CONTRACT:
      return t('DUPLICATE_CONTRACT')
    case DuplicateType.CONSTRUCTION_SITE:
      return t('DUPLICATE_CONSTRUCTION_SITE')
  }
}

const DuplicateModal = ({
  onClose,
  initDoc,
  initContract,
  initConstructionSite,
  set,
}: DuplicateModalProps) => {

  const theme = getTheme()

  const user = useSelector((state: Reducer) => state.user)

  const [contract, setContract] =
      useState<Contract>(initContract)
  const [constructionSite, setConstructionSite] =
      useState<ConstructionSite>(initConstructionSite)

  const [showChooseContract, setShowChooseContract] =
      useState<boolean>(false)
  const [duplicateType, setDuplicateType] =
      useState<DuplicateType>(DuplicateType.DOC)

  const _toggleChooseContract = () => setShowChooseContract(!showChooseContract)

  const onChooseContract = (constructionSite: ConstructionSite, contract: Contract) => {
    setConstructionSite(constructionSite)
    setContract(contract)
    setShowChooseContract(false)
  }

  const _duplicate = async () => {
    let d = duplicateDoc(-1, initDoc, user)
    let c = contract
    let cs = constructionSite
    if (duplicateType == DuplicateType.CONSTRUCTION_SITE) {
      cs = {
        ...initConstructionSite,
        Code: '',
        IDString: '',
        ID: -1,
      }
    }
    if (duplicateType != DuplicateType.DOC) {
      c = {
        ...initContract,
        ID: -1,
        ConstructionSiteID: constructionSite.ID,
      }
      d.ContractID = -1
    }

    set(cs, c, d)
    onClose()
  }

  if (showChooseContract) {
    return (
      <ChooseContractModal
        onChoose={onChooseContract}
        onClose={_toggleChooseContract}
        defaultConstructionSite={constructionSite}
        defaultContract={contract}
      />
    )
  }

  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      style={{
        width: 500,
        maxHeight: 500,
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
        alignSelf: 'center',
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Text style={{fontSize: 24}}>
          {t('DUPLICATE')} {initDoc?.IDString} {initDoc?.Type.Name}
        </Text>

        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={onClose}
        />
      </View>

      <View style={{flex: 1, paddingTop: 15}}>
        <FlatChoose
          itemToKey={i => i.toString()}
          options={[
            DuplicateType.DOC,
            DuplicateType.CONTRACT,
            DuplicateType.CONSTRUCTION_SITE,
          ]}
          itemToString={duplicateTypeToString}
          onChange={setDuplicateType}
          value={duplicateType}
          size='extra-small'
        />
      </View>

      {(duplicateType != DuplicateType.CONSTRUCTION_SITE && constructionSite.ID > 0) && (
        <View style={{flexDirection: 'row', paddingTop: 15}}>
          <Text style={{width: 140}}>
            {t('CONSTRUCTION_SITE')}:
          </Text>
          <Text style={{width: 80}}>
            {constructionSite.Code ? constructionSite.Code : constructionSite.IDString}
          </Text>
          <View style={{flex: 1}}>
            <Text>
              {constructionSite.Name}
            </Text>
            {constructionSite.Address && (
              <Text>
                {constructionSite.Address}
              </Text>
            )}
          </View>
        </View>
      )}

      {(duplicateType != DuplicateType.CONSTRUCTION_SITE && constructionSite.ID < 0) && (
        <Text>
          {t('DUPLICATE_IN_NEW_CONSTRUCTION_SITE_AND_NEW_CONTRACT')}
        </Text>
      )}

      {(duplicateType == DuplicateType.DOC && contract.ID > 0) && (
        <View style={{flexDirection: 'row', paddingTop: 15}}>
          <Text style={{width: 140}}>
            {t('CONTRACT')}: 
          </Text>
          <Text style={{flex: 1}}>
            {contract.Name}
          </Text>
        </View>
      )}

      {(duplicateType == DuplicateType.DOC && contract.ID < 0 && constructionSite.ID > 0) && (
        <Text>
          {t('DUPLICATE_IN_NEW_CONTRACT')}
        </Text>
      )}

      <View style={{flexDirection: 'row', paddingTop: 15}}>
        {duplicateType != DuplicateType.CONSTRUCTION_SITE && (
          <Button
            titleT='CHANGE_DESTINATION'
            style={{
              backgroundColor: theme.blue,
              flex: 1,
              margin: 0,
              marginRight: 7,
            }}
            onPress={_toggleChooseContract}
          />
        )}
        <Button
          titleT='DUPLICATE'
          style={{
            backgroundColor: theme.mainColor,
            flex: 1,
            margin: 0,
            marginLeft: duplicateType == DuplicateType.CONSTRUCTION_SITE ? 0 : 7,
          }}
          onPress={_duplicate}
        />
      </View>
    </Modal>
  )
}

export default DuplicateModal