import React from "react";
import { Contact } from "../../models";
import { View } from "react-native";
import LabelInput from "../LabelInput";

interface Props {
  contact: Contact;
  setContact: (contact: Contact) => void;
}

const ClientContact = ({contact, setContact}: Props) => {

  const _firstname = (firstname: string) => {
    setContact({
      ...contact,
      Firstname: firstname,
    });
  };

  const _lastname = (lastname: string) => {
    setContact({
      ...contact,
      Lastname: lastname,
    });
  };

  const _position = (position: string) => {
    setContact({
      ...contact,
      Position: position,
    });
  };

  const _email = (email: string) => {
    setContact({
      ...contact,
      Email: email,
    });
  };

  const _phone = (phone: string) => {
    setContact({
      ...contact,
      Phone: phone,
    });
  };

  const _mobile = (mobile: string) => {
    setContact({
      ...contact,
      Mobile: mobile,
    });
  };

  const _title = (title: string) => {
    setContact({
      ...contact,
      Title: title,
    });
  };

  return (
    <View style={{flex: 1, paddingLeft: 5}}>

      <LabelInput label={"FIRST_NAME"} value={contact.Firstname} setValue={_firstname} maxLength={50}/>
      <LabelInput label={"LAST_NAME"} value={contact.Lastname} setValue={_lastname} maxLength={50}/>
      <LabelInput label={"TITLE"} value={contact.Title} setValue={_title} maxLength={10} />
      <LabelInput label={"POSITION"} value={contact.Position} setValue={_position} maxLength={90}/>
      <LabelInput
        label={"EMAIL"}
        value={contact.Email}
        setValue={_email}
        maxLength={100}
        autoCapitalize='none'
      />
      <LabelInput label={"PHONE"} value={contact.Phone} setValue={_phone} maxLength={50}/>
      <LabelInput label={"MOBILE_PHONE"} value={contact.Mobile} setValue={_mobile} maxLength={50}/>

    </View>
  );
};

export default ClientContact;