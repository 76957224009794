import React, {useEffect, useState} from "react";
import {ActivityIndicator, Platform, View} from "react-native";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import store, { persistor } from "./store/store";
import AppContent from "./AppContent";
import {getDeviceUUID} from "./utils/DeviceUUID";
import * as Sentry from 'sentry-expo';
import { SafeAreaProvider } from "react-native-safe-area-context";

Sentry.init({
  dsn: "https://c758702c87527bc4362f21456520e939@o4503918426980352.ingest.sentry.io/4506376659075072",
  debug: true,
  release: "dryapp@1.0.81",
});

function App() {

  const [uuid, setUUID] = useState<string|null>(null);

  useEffect(() => {
    getDeviceUUID().then(setUUID);
  }, []);

  if (uuid == null) {
    return (
      <View style={{alignContent: "center", justifyContent:"center", flex: 1}}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<View />}>
        <SafeAreaProvider>
          <AppContent />
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
}

let e:any = App;
if (Platform.OS != "web") {
  e = Sentry.Native.wrap(App);
} else {
  e = Sentry.Browser.wrapUseRoutes(App);
}
export default e;
