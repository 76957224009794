import { WorkSpace } from "../models";


const workSpaces: WorkSpace[] = [
  {
    id: 0,
    name: 'drytech-sa',
    url: 'https://susten.drytech.ch/drytech-sa/',
    secondaryUrl: 'https://dryapp-print.drytech.ch/',
  },
  {
    id: 1,
    name: 'test',
    url: 'https://susten.drytech.ch/drytech-test/',
    secondaryUrl: 'https://dryapp-print-dev.drytech.ch/',
  },
  {
    id: 2,
    name: 'test2',
    url: 'https://susten.drytech.ch/drytech-test2/',
    secondaryUrl: 'https://dryapp-print-dev.drytech.ch/',
  },
  {
    id: 3,
    name: 'drytech-int',
    url: 'https://susten.drytech.ch/drytech-int/',
    secondaryUrl: 'https://dryapp-print.drytech.ch/',
  },
  {
    id: 4,
    name: 'drytech-ltd',
    url: 'https://susten.drytech.ch/drytech-ltd/',
    secondaryUrl: 'https://dryapp-print.drytech.ch/',
  },
];

export const GetWorkspaces = async (): Promise<WorkSpace[]> => {
  return workSpaces;
}
