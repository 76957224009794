import { Absence, AlertEmail, Catalog, DayReport, DayReportMaterial, LineType, Material, Report, Resource, SapDoc, WorkGroupFilter } from '../../models'
import { DayReportMode } from '../../screens/reports/DayReportView/DayReportMode'
import { getStringFromTime, getTimeFromString } from "../StringUtils";
import { quantityDivider, round } from '../../shared-utils/NumberUtils'
import { sendMail } from '../../api/MailAPI';
import { AlertEmailType, getAlertsEmail } from '../AlertsEmail';
import { getActivities } from '../../api/ReportAPI';
import { translations } from 'i18n-js'
import { itemToReport } from '../../shared-utils/reports/convert';

export const dateToMinutes = (date:Date):number => {
  return date.getHours() * 60 + date.getMinutes();
}

export const getTravelTimeFromDayReport = (dayReport: DayReport, mode: number): string => {
  if (mode !== DayReportMode.CREATE) {
    return getStringFromTime(dayReport.TravelTime);
  }

  if (dayReport.Reports) {
    for (let i = 0; i < dayReport.Reports.length; i++) {
      if (dayReport.Reports[i].Type === LineType.POSITION_CAR) {
        return getStringFromTime(dayReport.Reports[i].UnitTime || 1);
      }
    }
  }

  return "01:00";
};

export const getDepartureTimeFromDayReport = (dayReport: DayReport, mode: number, initDepartureTime?: string): string | undefined => {
  if (mode === DayReportMode.CREATE && dayReport.TravelTime === 0) {
    return initDepartureTime
  }

  return getStringFromTime(dateToMinutes(dayReport.DateTime) - dayReport.TravelTime)
}

export const getStartTimeFromDayReport = (dayReport: DayReport, mode: number): string | undefined => {
  if (mode === DayReportMode.CREATE) {
    if (dayReport.DateTime.getHours() == 0 && dayReport.DateTime.getMinutes() == 0) {
      return
    }
  }
  return getStringFromTime(dateToMinutes(dayReport.DateTime));
}

export const getEndTimeFromDayReport = (dayReport: DayReport, mode: number): string | undefined => {
  if (mode === DayReportMode.CREATE) {
    if (dayReport.TotalTime === 0) {
      return
    }
    return getStringFromTime(dateToMinutes(dayReport.DateTime) + dayReport.TotalTime)
  }
  let time = dateToMinutes(dayReport.DateTime)-dayReport.TravelTime+dayReport.BreakTime;

  if (dayReport.Reports) {
    for (let i = 0; i < dayReport.Reports.length; i++) {
      time += (dayReport.Reports[i].Duration || 0) * 60
    }
  }

  return getStringFromTime(time);
}

export const getBreakTimeFromDayReport = (dayReport: DayReport, mode: number): string => {
  if (mode == DayReportMode.CREATE && dayReport.BreakTime == 0) {
    return "00:00"
  }
  return getStringFromTime(dayReport.BreakTime)
}

export const prepareReportForApprove = (d: DayReport, order: SapDoc):DayReport => {
  if (d.Reports) {
    for (let i = 0; i < d.Reports.length; i++) {
      switch (d.Reports[i].Type) {
        case  LineType.POSITION_WORK_WARRANTY:
        case LineType.POSITION_SELL_WARRANTY:
        case LineType.POSITION_EXTERNAL_WORK:
        case LineType.POSITION_ENGINEERING:
        case LineType.POSITION_CAR:
        case LineType.POSITION:
          if (d.Reports[i].Levels) {
            for (let j = 0; j < (d.Reports[i].Levels || []).length; j++) {
              (d.Reports[i].Levels || [])[j].ApprovedQuantity = (d.Reports[i].Levels || [])[j].Quantity;
              if (d.Reports[i].Type == LineType.POSITION_CAR) {
                const orderLine = order.Lines[d.Reports?.[i].ActivityID]
                if ((orderLine?.BillableQuantity || 0) > 0) {
                  (d.Reports[i].Levels || [])[j].BillableQuantity = 0
                } else {
                  (d.Reports[i].Levels || [])[j].BillableQuantity = 1 / (d.Reports[i].Levels || []).length
                }
              } else {
                (d.Reports[i].Levels || [])[j].BillableQuantity = (d.Reports[i].Levels || [])[j].Quantity
              }
            }
            d.Reports[i].ApprovedDuration = d.Reports[i].Duration;
            for (let j = 0; j < (d.Reports[i].Materials || []).length; j++) {
              (d.Reports[i].Materials || [])[j].ApprovedQuantity = (d.Reports[i].Materials || [])[j].Quantity;
            }
          }
      }
    }
  }

  return d;
}

export const isHours = (uom?: string) => {
  const uoms = [
    "ore",
    "ora",
    "hour",
    "hours",
    "stunden",
    "stunde",
    "heures",
    "heure",
    "horas",
    "hora",
  ]

  return uoms.includes(uom?.toLocaleLowerCase() || "");
}

export const decimalHoursToSexagesimal = (hours:number):string => {
  let minutes = round((Math.abs(hours) - Math.floor(Math.abs(hours))) * 60, 2);
  let m = minutes < 10 ? `0${minutes}` : `${round(minutes, 0)}`;

  let h;
  if (hours < 0) {
    h = Math.abs(Math.ceil(hours));
  } else {
    h = Math.floor(hours);
  }
  if (hours < 10) {
    h = `0${h}`;
  }
  return `${hours < 0 ? "-":""}${h}:${m}`;
}

/**
 * Checks if the user can create or update a day report, for the given date.
 * If the limit is set to 0 the user can update. Otherwise:
 * - If the date is in the current week, the user can update.
 * - If the date is in the previous week, the user can update if the limit is not exceeded.
 * - Otherwise the user can not create a new day report.
 * 
 * @param date Date to check.
 * @param limit Minutes from the start of the week.
 * @returns True if user can create or update the day report.
 */
export const isWithinPreviousWeekLimit = (date:Date, limit:number):boolean => {
  if (limit > 0) {
    limit *= 60 // convert to seconds
    limit *= 1000 // convert to milliseconds
    const d = new Date()
    const day = d.getDay()
    const diff = d.getDate() - day + (day == 0 ? -6 : 1)
    const weekStart = new Date(d.setDate(diff))
    weekStart.setHours(0, 0, 0, 0)

    if (date < weekStart) {
      if (d.getTime() - limit > weekStart.getTime()) {
        return false
      }

      const previousWeekStart = new Date(weekStart.setDate(weekStart.getDate() - 7))
      const dateMidnight = new Date(date)
      dateMidnight.setHours(0, 0, 0, 0)
      
      if (dateMidnight < previousWeekStart) {
        return false
      }
    }
  }

  return true
}

/**
 * Check if the user can update the day report. After the end of the day report
 * the user can edit the day report within the given limit.
 * 
 * @param dayReport Day report to check.
 * @param limit Limit to update the day report in minutes, from the end of the day report.
 * @returns True if the user can update the day report.
 */
export const canEditDayReport = (dayReport:DayReport, limit:number):boolean => {
  if (limit > 0) {
    const date = new Date(dayReport.DateTime)
    const endDate = new Date(date.setMinutes(date.getMinutes() + dayReport.TotalTime))

    return endDate.getTime() + limit > new Date().getTime()
  }
 
  return true
}

export const canEditAbsence = (absence:Absence, limit:number):boolean => {
  if (limit > 0) {
    const date = new Date(absence.Date)
    date.setHours(0)
    const endDate = new Date(date.setMinutes(date.getMinutes() + (getTimeFromString(absence.End) || 0)))

    return endDate.getTime() + limit > new Date().getTime()
  }

  return true
}

export const distributeCarPositions = (dayReport: DayReport, report: Report, resource: Resource, index: number, drivers?: Resource[]): Report => {
  if (dayReport.Reports?.findIndex(r => r.Type === LineType.POSITION_CAR) === index && drivers) {
    if (drivers.find(d => d?.ID == resource?.ID) !== undefined) {
      return {
        ...report,
        Levels: report.Levels?.map(l => {
          return {
            ...l,
            Quantity: 1 / (report.Levels?.length || 1),
          }
        })
      }
    } else {
      return {
        ...report,
        Levels: report.Levels?.map(l => {
          return {
            ...l,
            Quantity: 0,
          }
        })
      }
    }
  }

  return report
}

export const checkIfQuantityExceed = (dayReport: DayReport): boolean => {
  for (let i = 0; i < (dayReport.Reports?.length || 0); i++) {
    for (let j = 0; j < (dayReport.Reports?.[i].Levels?.length || 0); j++) {
      const l = dayReport.Reports?.[i].Levels?.[j]
      if ((l?.Quantity || 0) > 0) {
        if ((l?.Quantity || 0) + (l?.DoneQuantity || 0) > (l?.SoldQuantity || 0)) {
          return true
        }
      }
    }
  }

  return false;
}

export const checkIfEmpty = (dayReport: DayReport): boolean => {
  let empty = true

  for (let i = 0; i < dayReport.Reports!.length; i++) {
    if (dayReport.Reports?.[i].Type != LineType.POSITION_CAR) {
      for (let j = 0; j < (dayReport.Reports![i].Levels?.length || 0); j++) {
        if ((dayReport.Reports?.[i].Levels?.[j].Quantity || 0) > 0) {
          empty = false
        }
      }
    }
  }

  return empty
}

export const checkNewPositionAdded = (dayReport: DayReport): boolean => {
  for (let i = 0; i < (dayReport.Reports?.length || 0); i++) {
    if ((dayReport.Reports?.[i].ActivityID || 0) < 0) {
      return true
    }
  }

  return false
}

const alertNewPositionAdded = async (dayReport: DayReport, recipiens: AlertEmail[]): Promise<void> => {
  recipiens.forEach(v => {
    // @ts-ignore
    const subject = `${dayReport.Resource.FirstName} ${dayReport.Resource.LastName} - ${translations[v.Language]["HAS_REPORT_ADDED"]}`
    // @ts-ignore
    const content = `${subject}\n${translations[v.Language]["CONSTRUCTION_SITE"]}: ${dayReport.ConstructionSite.Code}   -   ${dayReport.ConstructionSite.Name}   -    ${dayReport.Contract.Name}\n\n${translations[v.Language]["DAY_REPORT"]}: ${dayReport.ID}`
    sendMail(v.Email, subject, content)
  });
}

const alertQuantityExceed = async (dayReport: DayReport, recipiens: AlertEmail[]): Promise<void> => {
  recipiens.forEach(v => {
    // @ts-ignore
    const subject = `${dayReport.Resource.FirstName} ${dayReport.Resource.LastName} - ${translations[v.Language]["HAS_REPORTED_MORE"]}`
    // @ts-ignore
    const content = `${subject}\n${translations[v.Language]["CONSTRUCTION_SITE"]}: ${dayReport.ConstructionSite.Code}   -   ${dayReport.ConstructionSite.Name}   -    ${dayReport.Contract.Name}\n\n${translations[v.Language]["DAY_REPORT"]}: ${dayReport.ID}`
    sendMail(v.Email, subject, content)
  })
}

const alertQuantityExceedAndPositionAdded = async (dayReport: DayReport, recipiens: AlertEmail[]): Promise<void> => {
  recipiens.forEach(v => {
    // @ts-ignore
    const subject = `${dayReport.Resource.FirstName} ${dayReport.Resource.LastName} - ${translations[v.Language]["HAS_REPORTED_MORE_AND_ADDED"]}`
    // @ts-ignore
    const content = `${subject}\n${translations[v.Language]["CONSTRUCTION_SITE"]}: ${dayReport.ConstructionSite.Code}   -   ${dayReport.ConstructionSite.Name}   -    ${dayReport.Contract.Name}\n\n${translations[v.Language]["DAY_REPORT"]}: ${dayReport.ID}`
    sendMail(v.Email, subject, content)
  })
}

const getAlertsEmailTypeAndF = (quantityExceed: boolean, addedPosition: boolean) => {
  if (quantityExceed) {
    if (addedPosition) {
      return {
        type: AlertEmailType.DAY_REPORT_QUANTITY_EXCEEDED_ADDED_POSITION,
        f: alertQuantityExceedAndPositionAdded,
      }
    } else {
      return {
        type: AlertEmailType.DAY_REPORT_QUANTITY_EXCEEDED,
        f: alertQuantityExceed,
      }
    }
  } else {
    return {
      type: AlertEmailType.DAY_REPORT_ADDED_POSITION,
      f: alertNewPositionAdded,
    }
  }
}

export const alertsDayReport = async (dayReport: DayReport, addedPosition: boolean, quantityExceed: boolean): Promise<void> => {

  if (!quantityExceed && !addedPosition) {
    return;
  }
  const { type, f } = getAlertsEmailTypeAndF(quantityExceed, addedPosition)
  const recipients: AlertEmail[] = getAlertsEmail(type)

  const activities = await getActivities(dayReport.ContractID)
  if (activities.contract.sellerEmail) {
    recipients.push({
      Language: 'it',// TODO fix loading seller Language instead of locale
      Code: "",
      Type: type,
      Email: activities.contract.sellerEmail
    })
  }
  f(dayReport, recipients)
}

export const alertMailFinished = async (dayReport:DayReport) => {
  const recipients = getAlertsEmail(AlertEmailType.DAY_REPORT_FINISHED)
  
  const activities = await getActivities(dayReport.ContractID)
  if (activities.contract.sellerEmail) {
    recipients.push({
      Code: "",
      Language: 'it',
      Email: activities.contract.sellerEmail,
      Type: AlertEmailType.DAY_REPORT_FINISHED,
    })
  }
  
  recipients.forEach(v => {
    sendMail(
      v.Email,
      // @ts-ignore
      `${dayReport.Resource.FirstName} ${dayReport.Resource.LastName} ${translations[v.Language]["HAS_FINSHED_REPORT"]}`,
      // @ts-ignore
      `${translations[v.Language]["CONSTRUCTION_SITE"]}: ${dayReport.ConstructionSite.Code}   -   ${dayReport.ConstructionSite.Name}    -    ${dayReport.Contract.Name}\n\n${translations[v.Language]["DAY_REPORT"]} ${dayReport.ID}`,
    )
  })
}

export const getStartStopKey = (uuid: string) => `dryapp-day-report-${uuid}`

export const childCheck = (code: string, reports: Report[], allowEmpty?: boolean, hideCars?: boolean): boolean => {
  const child = reports.find(r => r.FatherID == code)
  if (child?.Type == LineType.TITLE) {
    return childCheck(child.LineCode, reports, allowEmpty)
  }
  if (allowEmpty && child) {
    if (hideCars && child.Type == LineType.POSITION_CAR) {
      return false
    }
    return true
  }
  return child?.Levels?.some(l => l.Quantity > 0) || false
}

export const splitTimesOverDayReport = (dayReport: DayReport): DayReport => {

  // in minutes
  const dayReportDuration = (dayReport.TotalTime - dayReport.BreakTime) / 60

  const estimatedDuration = dayReport.Reports?.reduce((acc, report) => {
    return acc + (report.UnitTime || 0) * (report.Levels?.reduce((acc, level) => acc + level.Quantity, 0) || 0)
  }, 0) || 0

  return {
    ...dayReport,
    Reports: dayReport.Reports?.map((report, index, reports) => {
      if (report.Type == LineType.TITLE) {
        return report
      }
      if (report.Type == LineType.POSITION_CAR) {
        const firstCarIndex = reports.findIndex(r => r.Type == LineType.POSITION_CAR)
        if (index == firstCarIndex) {
          return {
            ...report,
            Duration: (dayReport.TravelTime / 60)
          }
        }
      }
      const estimatedDurationReport = (report.UnitTime || 0) * (report.Levels?.reduce((acc, level) => acc + level.Quantity, 0) || 0)

      return {
        ...report,
        Duration: estimatedDurationReport / estimatedDuration * dayReportDuration
      }
    })
  }
}

/**
 * Checks if the day report uses too much time, compared to the estimated duration for the work done.
 * Car time is not included in the calculation.
 *
 * @param dayReport Day report to check.
 * @param magin Add a percentage margin to the estimated duration, default is 1.
 * @returns True if the day report uses too much time.
 */
export const usesTooMuchTime = (dayReport: DayReport, magin?: number): boolean => {
  const estimatedDuration = dayReport.Reports?.reduce((acc, report) => {
    if (report.Type == LineType.POSITION_CAR || report.Type == LineType.TITLE) {
      return acc
    }
    return acc + (report.UnitTime || 0) * (report.Levels?.reduce((acc, level) => acc + level.Quantity, 0) || 0)
  }, 0) || 0

  const marginValue = (magin || 1)

  const time = (dayReport.TotalTime - dayReport.BreakTime) / 60

  if (estimatedDuration == 0) {
    return false
  }
  return estimatedDuration * marginValue < time
}

/**
 * Calculate the percentage progress of the day report, for each level.
 *
 * @param dayReport Day report to calculate the progress for.
 * @returns Array of progress for each level.
 */
export const getProgressByLevels = (dayReport: DayReport, workGroup?: string): number[] => {
  if (workGroup && workGroup == WorkGroupFilter.OTHER || workGroup == WorkGroupFilter.MATERIALS) {
    workGroup = ''
  }
  return dayReport.Levels.map(l => {
    const data = dayReport.Reports?.reduce((p, v) => {
      const level = v.Levels?.find(level => level.LevelID == l.ID)
      if (!level) {
        return p
      }
      if (level.SoldQuantity == 0) {
        return p
      }
      if (workGroup && v.WorkGroup != workGroup) {
        return p
      }
      if ((level.SoldQuantity || 0) < (level.DoneQuantity || 0)) {
        return {
          total: p.total + (level.SoldQuantity || 0),
          done: p.done + (level.SoldQuantity || 0),
        }
      }
      return {
        total: p.total + (level.SoldQuantity || 0),
        done: p.done + (level.DoneQuantity || 0),
      }
    }, {total: 0, done: 0})

    if (!data) {
      return 0
    }

    return data.done / data.total
  })
}

export const getProgressByWorkGroups = (dayReport: DayReport, levelId?: number): {workGroup: string, progress: number}[] => {
  const workGroups = dayReport.Reports?.reduce((p, v) => {
    if (p.includes(v.WorkGroup)) {
      return p
    }

    return [...p, v.WorkGroup || '']
  }, [] as string[]) || []
  workGroups.push('')
  return workGroups.map(w => {
    const data = dayReport.Reports?.reduce((p, v) => {
      const level = v.Levels?.find(level => level.LevelID == levelId)
      if (!level) {
        const sold =  v.Levels?.reduce((p, v) => (v.SoldQuantity || 0) > 0 ? p + (v.SoldQuantity || 0) : p, 0) || 0
        const done =  v.Levels?.reduce((p, v) => (v.DoneQuantity || 0) > 0 ? p + (v.DoneQuantity || 0) : p, 0) || 0
        if (done > sold) {
          return {
            total: p.total + sold,
            done: p.done + sold,
          }
        }
        return {
          total: p.total + sold,
          done: p.done + done,
        }
      }
      if (v.WorkGroup != w) {
        return p
      }
      if (level.SoldQuantity == 0) {
        return p
      }
      if (levelId && level.LevelID != levelId) {
        return p
      }
      if ((level.DoneQuantity || 0) > (level.SoldQuantity || 0)) {
        return {
          total: p.total + (level.SoldQuantity || 0),
          done: p.done + (level.SoldQuantity || 0),
        }
      }

      return {
        total: p.total + (level.SoldQuantity || 0),
        done: p.done + (level.DoneQuantity || 0),
      }
    }, {total: 0, done: 0})
    if (!data) {
      return {
        workGroup: w,
        progress: 0,
      }
    }

    return {
      workGroup: w,
      progress: data.total == 0 ? 0 : data.done / data.total,
    }
  }).map(w => w.workGroup == '' ? ({...w, workGroup: WorkGroupFilter.OTHER}) : w) || []
}

/**
 * Convert the day report to a list of materials used in the day report.
 * The materials have the quantity used, the quantity sold.
 *
 * @param dayReport Day report to get the materials from.
 * @returns List of materials used in the day report.
 */
export const getMaterialsByDayReport = (dayReport: DayReport): DayReportMaterial[] => {
  if (!dayReport.Reports) {
    return []
  }
  const materials: DayReportMaterial[] = []
  for (let i = 0; i < dayReport.Reports!.length; i++) {
    const report = dayReport.Reports![i]
    if (report.Materials) {
      const soldQuantity = report
          .Levels
          ?.reduce((p, v) => p + (v.SoldQuantity || 0), 0) || 0
      for (let j = 0; j < report.Materials!.length; j++) {
        const material = report.Materials![j]
        let index = materials.findIndex(m => m.MaterialID == material.MaterialID)
        if (index == -1) {
          materials.push({
            ...material,
            Quantity: 0,
            SoldQuantity: 0,
          })
          index++
        }
        materials[index].Quantity += (material.Quantity || 0)
        materials[index].SoldQuantity += (material.UnitQuantity || 0) * soldQuantity
      }
    }
  }

  return materials
}

export const getTheoricalMaterials = (dayReport: DayReport): {[m:string]: number} => {
  const materials: {[m: string]: number} = {}

  if (!dayReport.Reports) {
    return materials
  }

  for (let i = 0; i < dayReport.Reports!.length; i++) {
    const report = dayReport.Reports![i]
    if (report.Materials) {
      const quantity = report
          .Levels
          ?.reduce((p, v) => p + v.Quantity, 0) || 0
      for (let j = 0; j < report.Materials!.length; j++) {
        const material = report.Materials![j]
        if (!materials[material.MaterialID || '']) {
          materials[material.MaterialID || ''] = 0
        }
        materials[material.MaterialID || ''] += (material.UnitQuantity || 0) * quantity
      }
    }
  }

  return materials
}

const placeTimeOnHoursPosition = (dayReport: DayReport, catalogs: Catalog[]): DayReport => {
  if (!dayReport.Reports) {
    return dayReport
  }
  const time = (dayReport.TotalTime - dayReport.BreakTime) / 60
  let index = dayReport.Reports.findIndex(r => r.WorkGroup == WorkGroupFilter.HOURS_POSITION)

  if (index == -1) {
    const item = catalogs
      .find(c => c.ID == dayReport.CatalogID)
      ?.Items
      .find(i => i.WorkGroup == WorkGroupFilter.HOURS_POSITION)
    if (!item) {
      // error some how
      throw new Error('Work hours positions not found')
    }
    const r = itemToReport(
      item,
      dayReport.Levels,
      dayReport.ID,
      dayReport.Reports[dayReport.Reports.length - 1],
      dayReport.Reports.reduce((p, v) => v.ID < p ? v.ID : p, -1),
    )

    dayReport.Reports.push(r[0])
    index = dayReport.Reports.length - 1
  }

  dayReport.Reports[index].Levels = dayReport.Reports[index].Levels?.map((l, i) => ({
    ...l,
    Quantity: quantityDivider(
      time,
      dayReport.Reports![index].Levels!.length,
      i,
    ),
  }))
  dayReport.Reports[index].Duration = time

  return dayReport
}

const placeMaterialsOnReportsByExisting = (dayReport: DayReport, materials: DayReportMaterial[], onAllReports: boolean): DayReport => {
  // TODO check with fabrizio lanci if user
  // should choose on which works to place the materials
  if (!dayReport.Reports) {
    return dayReport
  }

  const theoricalMaterials = dayReport.Reports.reduce((p, v) => {
    if (!v.Materials) {
      return p
    }
    const remainingQuantities = v.Levels?.reduce((p, v) => {
      if ((v.SoldQuantity || 0) < (v.DoneQuantity || 0)) {
        return p
      }
      if (onAllReports || v.Quantity > 0) {
        return p + (v.SoldQuantity || 0) - (v.DoneQuantity || 0)
      }
      return p
    }, 0) || 0

    for (let i = 0; i < v.Materials.length; i++) {
      if (!p[v.Materials[i].MaterialID || '']) {
        p[v.Materials[i].MaterialID || ''] = 0
      }
      p[v.Materials[i].MaterialID || ''] += v.Materials[i].UnitQuantity * remainingQuantities
    }
    return p
  }, {} as {[m: string]: number})

  return {
    ...dayReport,
    Reports: dayReport.Reports.map(r => {
      if (!r.Materials) {
        return r
      }
      let quantities: number;
      if (onAllReports) {
        quantities = r.Levels?.reduce((p, v) => {
          if ((v.SoldQuantity || 0) < (v.DoneQuantity || 0)) {
            return p
          }
          return p + (v.SoldQuantity || 0) - (v.DoneQuantity || 0)
        }, 0) || 0
      } else {
        quantities = r.Levels?.reduce((p, v) => {
          return p + (v.Quantity || 0)
        }, 0) || 0
      }
      return {
        ...r,
        Materials: r.Materials.map(m => {
          const material = materials.find(m2 => m2.MaterialID == m.MaterialID)
          const theoricalQuantity = m.UnitQuantity * quantities
          if (material) {
            return {
              ...m,
              Quantity: theoricalQuantity / theoricalMaterials[m.MaterialID || ''] * material.Quantity,
            }
          }
          return m
        })
      }
    })
  }
}

const placeTimeOnPositions = (dayReport: DayReport): DayReport => {

  const time = (dayReport.TotalTime - dayReport.BreakTime) / 60

  const estimatedDuration = dayReport.Reports?.reduce((acc, report) => {
    if (report.Type == LineType.POSITION_CAR || report.Type == LineType.TITLE) {
      return acc
    }
    return acc + (report.UnitTime || 0) * (report.Levels?.reduce((acc, level) => acc + level.Quantity, 0) || 0)
  }, 0) || 0

  return {
    ...dayReport,
    Reports: dayReport.Reports?.map((report, index, reports) => {
      if (report.Type == LineType.TITLE) {
        return report
      }
      if (report.Type == LineType.POSITION_CAR) {
        const firstCarIndex = reports.findIndex(r => r.Type == LineType.POSITION_CAR)
        if (index == firstCarIndex) {
          return {
            ...report,
            Duration: (dayReport.TravelTime / 60)
          }
        }
      }
      const estimatedDurationReport = (report.UnitTime || 0) * (report.Levels?.reduce((acc, level) => acc + level.Quantity, 0) || 0)
      return {
        ...report,
        Duration: estimatedDurationReport / estimatedDuration * time
      }
    })
  }
}

const sumMaterials = (materials: DayReportMaterial[]): number => {
  return materials
    .reduce((p, v) => {
      return p + v.Quantity 
    }, 0)
}

const sumQuantitiesWithoutCar = (dayReport: DayReport): number | undefined => {
  return dayReport
    .Reports
    ?.reduce((p, v) => {
      if (v.Type == LineType.POSITION_CAR) {
        return p
      }
      if (v.WorkGroup == WorkGroupFilter.HOURS_POSITION) {
        return p
      }
      return p + (v.Levels?.reduce((p, v) => p + v.Quantity, 0) || 0)
    }, 0)
}

export const isDayReportEmpty = (dayReport: DayReport, materials: DayReportMaterial[]): boolean => {
  return sumMaterials(materials) == 0 && (sumQuantitiesWithoutCar(dayReport) || 0) == 0 && dayReport.TotalTime - dayReport.BreakTime > 0
}

export const placeMaterialsOnReports = (dayReport: DayReport, materials: DayReportMaterial[], catalogs: Catalog[]) => {
  if (!dayReport.Reports) {
    return dayReport
  }

  const quantitiesWithoutCar = sumQuantitiesWithoutCar(dayReport)

  const materialsSet = sumMaterials(materials) > 0

  if (quantitiesWithoutCar == 0) {
    if (materialsSet) {
      return placeMaterialsOnReportsByExisting(
        placeTimeOnHoursPosition(
          dayReport,
          catalogs,
        ),
        materials,
        true,
      )
    } else {
      return placeTimeOnHoursPosition(
        dayReport,
        catalogs,
      )
    }
  }

  return placeMaterialsOnReportsByExisting(
    placeTimeOnPositions(dayReport),
    materials,
    false,
  )
}

export type ReportTwinCheck = {
  activityIDString:string
  id: number
  levelId: number
  workGroup: string
  maximum: number
}

export const checkPreviousTwins = (dayReport: DayReport): ReportTwinCheck[] => {
  let wrong: ReportTwinCheck[] = []
  if (!dayReport.Reports) {
    return wrong
  }
  for (let i = 0; i < dayReport.Reports.length; i++) {
    const r = dayReport.Reports[i]
    if (r.TwindIDs.length > 0) {
      if (r.Levels) {
        const twins = dayReport.Reports.filter((r2, r2i) => r2.TwindIDs.includes(r.LineCode) && r2i > i)
        for (let j = 0; j < r.Levels.length; j++) {
          const l = r.Levels[j]
          for (let k = 0; k < twins.length; k++) {
            const twin = twins[k]
            const twinLevel = twin.Levels?.find(tl => tl.LevelID == l.LevelID)
            if (twinLevel && twinLevel.Quantity + (twinLevel.DoneQuantity || 0) > l.Quantity + (l.DoneQuantity || 0) && twinLevel.Quantity > 0) {
              wrong.push({
                activityIDString: twin.ActivityIDString,
                id: twin.ID,
                levelId: twinLevel.LevelID,
                workGroup: twin.WorkGroup,
                maximum: l.Quantity + (l.DoneQuantity || 0) - (twinLevel.DoneQuantity || 0),
              })
            }
          }
        }
      }
    }
  }

  return wrong
}

export const checkIfCanSplitDayReport = (dayReport: DayReport, mode: number): boolean => {
  if (mode == DayReportMode.UPDATE) {
    const totalTime = dayReport.TotalTime + dayReport.TravelTime - dayReport.BreakTime

    const linesTime = dayReport
      .Reports
      ?.reduce((p, v) => p + (v.Duration || 0), 0) || 0

    if (Math.abs(totalTime - linesTime * 60) < 5) {
      return true
    }
  }
  return false
}