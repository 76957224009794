import React, { useCallback, useState } from "react";
import { t } from "i18n-js";
import { DayReport, Doc, Item, Report, SapDoc, LineSumType, LineType, CatalogTypes } from '../../../models'
import getTheme from "../../../constants/Colors";
import { getHoursAndMinutesFromString, getStringFromTime, getTimeFromString } from "../../../utils/StringUtils";
import { getEndTimeFromDayReport, getStartTimeFromDayReport } from "../../../utils/reports/report-utils";
import { DayReportMode } from "../DayReportView/DayReportMode";
import DatePicker from "../../../components/DatePicker";
import TimePicker from "../../../components/TimePicker";
import DayReportApproveHeader from "./DayReportApproveHeader";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ActivityIndicator, FlatList, Image, StyleSheet, Text, View, TextInput } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { alert, alertConfirm } from "../../../hooks/Alert";
import { useNavigation } from "@react-navigation/native";
import { itemToReport } from '../../../shared-utils/reports/convert'
import { approveDayReport, deleteDayReport } from "../../../api/ReportAPI";
import DayReportApproveLine from "./DayReportApproveLine";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import BillPreviewModal from "../../../components/BillPreviewModal";
import { logout } from "../../../store/action/authAction";
import Button from "../../../components/Button";
import { sentryCapture } from "../../../utils/sentryCapture";
import { SearchScreens } from "../../search/SearchScreens";
import { updateOrderStatus } from "../../../api/DocAPI";
import { DocStatus } from "../../../constants/Status";

interface Props {
  initDayReport: DayReport;
  order: Doc;
  sapOrder: SapDoc;
}

const DayReportApproveView = ({initDayReport, order, sapOrder}: Props) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const theme = getTheme();

  const resource = useSelector((state: Reducer) => state.general.resource);

  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const [billPreviewVisible, setBillPreviewVisible] = useState<boolean>(false);

  const _billPreview = () => setBillPreviewVisible(true);

  const [breakTime, setBreakTime] = useState<string>(getStringFromTime(initDayReport.BreakTime));
  const [startTime, setStartTime] = useState<string>(getStartTimeFromDayReport(initDayReport, DayReportMode.APPROVE) || '00:00');
  const endTime = getEndTimeFromDayReport(initDayReport, DayReportMode.APPROVE);

  const [dayReport, setDayReport] = useState<DayReport>(initDayReport);

  const setReport = ({s, i}: {s: (report: Report) => Report, i: number}) => {

    setDayReport(old => {
      setChanged(true);
      if (old.Reports) {
        old.Reports[i] = s(old.Reports[i]);
      }
      return {...old};
    });
  };

  const _onAdd = (item: Item | Item[]) => {
    setDayReport((old) => {
      const smallestReportId = old.Reports?.reduce((p, v) => v.ID < p ? v.ID : p, 0) || 0
      setChanged(true);
      return {
        ...old,
        Reports: [
          ...(old.Reports || []),
          ...itemToReport(item, dayReport.Levels, dayReport.ID, old.Reports?.[old.Reports?.length - 1], smallestReportId),
        ],
      };
    });
  };

  const _addWork = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.INSERT_POSITION, {
      onAdd: _onAdd,
      showCheckboxes: false,
      showCreate: false,
      initialCatalogId: dayReport.CatalogID,
    })
  }

  const _date = (date: Date | undefined) => {
    setDayReport((old) => {
      setChanged(true);
      if (date != undefined) {
        const {hours, minutes} = getHoursAndMinutesFromString(startTime);
        date.setHours(hours);
        date.setMinutes(minutes);
        return {
          ...old,
          DateTime: date,
        };
      }

      return old;
    });
  };

  const _startTime = (s: string) => {
    setStartTime(s);

    setDayReport(old => {
      setChanged(true);
      const {hours, minutes} = getHoursAndMinutesFromString(s);
      const date = old.DateTime;
      date.setHours(hours);
      date.setMinutes(minutes);
      return {
        ...old,
        DateTime: date,
      }
    });
  }

  const _breakTime = (event: string) => {
    setBreakTime(event);
    const time = getTimeFromString(event);

    if (time != null) {
      setDayReport(old => {
        setChanged(true);
        return {
          ...old,
          BreakTime: time,
        };
      });
    }
  };

  const _notes = (notes: string) => {
    setDayReport(old => {
      setChanged(true);
      return {
        ...old,
        Notes: notes,
      }
    });
  };

  const _approvalNotes = (notes: string) => {
    setDayReport(old => {
      setChanged(true);
      return {
        ...old,
        ApprovalNotes: notes,
      };
    });
  };

  const _signedBy = (signedBy: string) => {
    setDayReport(old => {
      setChanged(true);
      return {
        ...old,
        SignedBy: signedBy,
      };
    });
  };

  const _signedTime = (signedTime: Date | undefined) => {
    if (signedTime) {
      setDayReport(old => {
        setChanged(true);
        return {
          ...old,
          SignedTime: signedTime,
        };
      });
    }
  }

  const _signedPlace = (place: string) => {
    setDayReport(old => {
      setChanged(true);
      return {
        ...old,
        SignedPlace: place,
      };
    });
  };

  const activityIdToOrderLine = useCallback((activityId: number) => {

    let activityIdLocal = 0;
    let discountCount = 0;
    
    for (let i = 0; i < order.Lines.length; i++) {
      if (sapOrder.Lines[activityIdLocal].LineType === LineType.DISCOUNT) {
        activityIdLocal++;
      }
      switch (order.Lines[i].LineType) {
        case LineType.TITLE:
        case LineType.TEXT:
        case LineType.FIXED_PRICE:
        case LineType.FIXED_PRICE_ENGINEERING_WARRANTY:
          activityIdLocal++;
          break;
        case LineType.DISCOUNT:
          discountCount++;
          break;
        default:
          for (let j = 0; j < order.Lines[i].LineLevel.length; j++) {
            if (
              order.Lines[i].LineLevel[j]?.Quantity != 0 ||
              order.Lines[i].LineLevel[j]?.LineSumType == LineSumType.PRICE_LIST
            ) {
              activityIdLocal++;
            }
          }
      }

      if (activityIdLocal > activityId) {
        return order.Lines[i - discountCount];
      }
    }
    return order.Lines[0];
  }, [])

  const _render = ({item, index}: {item: Report, index: number}) => {

    const orderLine = activityIdToOrderLine(item.ActivityID);

    return (
      <DayReportApproveLine report={item}
                            orderLine={orderLine}
                            setReport={setReport}
                            levels={dayReport.Levels}
                            index={index}/>
    );
  };

  const _cancel = async () => {
    if (changed) {
      if (!await alertConfirm("ARE_YOU_SURE", "REPORT_MODAL_CLOSE_MESSAGE")) {
        return;
      }
    }
    navigation.goBack();
  };

  const approve = async (close: boolean) => {
    if (!resource) {
      alert("ERROR", "ERROR_DESC").then();
      return;
    }
    console.log(dayReport)
    setLoading(true);
    dayReport.BreakTime = getTimeFromString(breakTime) || 0;
    dayReport.TotalTime = (getTimeFromString(endTime || '00:00') || 0) - (getTimeFromString(startTime) || 0);
    dayReport.ApprovedBy = resource;
    dayReport.ApprovedByID = resource.ID;
    // dayReport.TravelTime = getTimeFromString(travelTime) || 0; TODO fix
    try {
      await approveDayReport(dayReport);
      if (close) {
        await updateOrderStatus(order.ContractID, DocStatus.DONE.code)
      }
      setLoading(false);
      navigation.goBack();
    } catch (e: any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_APPROVING_DAY_REPORT");
        navigation.goBack();
      }
    } finally {
      setLoading(false);
      setChanged(false);
    }
  }

  const _approve = () => approve(false)
  const _approveAndClose = () => approve(true)

  const _delete = async () => {
    if (!await alertConfirm("CONTINUE", "ARE_YOU_SURE_YOU_WANT_DELETE_THE_DAY_REPORT")) {
      return;
    }
    try {
      setLoading(true);
      await deleteDayReport(dayReport);
      navigation.goBack();
    } catch(e: any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR", "ERROR_DELETING_DAY_REPORT");
      }
    } finally {
      setLoading(false);
    }
  }

  const isWarrantyOrder = order.Type.CatalogType == CatalogTypes.Warranty

  return (
    <View style={{padding: 15, flex: 1}}>
      {
        billPreviewVisible && (
          <BillPreviewModal
            dayReport={dayReport}
            initOrder={order}
            setVisible={setBillPreviewVisible}
            visible={billPreviewVisible}
            activityIdToOrderLine={activityIdToOrderLine}
          />
        )
      }

      <View style={{flexDirection: "row", alignItems: "center"}}>
        <MaterialCommunityIcons
          size={34}
          name='close'
          onPress={_cancel}
        />
        <View style={{flexDirection: "column", flex: 1, paddingLeft: 6}}>
          <Text style={{fontSize: 20}}>
            {t("REPORT")}: {dayReport.ID}
          </Text>
          <View style={{flexDirection: "row", paddingTop: 5}}>
            <Text style={{fontSize: 18, marginRight: 12, fontWeight: 'bold'}}>
              {dayReport.ConstructionSite.Code}
            </Text>
            <Text style={{fontSize: 18, marginRight: 12}}>
              {dayReport.ConstructionSite.Name}
            </Text>
          </View>
          <Text style={{fontSize: 16, paddingTop: 5}}>
            {dayReport.Contract.Name}
          </Text>
        </View>

        <View style={{alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row'}}>
          <Button
            style={{
              marginRight: 3,
              backgroundColor: theme.secondaryBackground,
            }}
            onPress={_delete}
            titleT='DELETE'
          />
          <Button
            style={{
              marginRight: 3,
              backgroundColor: theme.secondaryBackground,
            }}
            onPress={_billPreview}
            titleT='BILL_PREVIEW'
          />
          {isWarrantyOrder && (
            <Button
              style={{
                marginRight: 3,
                backgroundColor: theme.blue,
              }}
              onPress={_approveAndClose}
              titleT='APPROVE_AND_CLOSE'
            />
          )}
          <Button
            style={{
              marginRight: 3,
              backgroundColor: theme.mainColor,
            }}
            onPress={_approve}
            titleT='APPROVE'
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 15,
        }}
      >
        <Text style={{fontSize: 16}}>
          {dayReport.Resource.FirstName} {dayReport.Resource.LastName}
        </Text>

        <DatePicker value={dayReport?.DateTime || new Date()}
                    onChange={_date}/>

        <Text>{t("BREAK")}:</Text>
        <TimePicker style={{minWidth: 70}}
                    value={breakTime}
                    onChange={_breakTime}/>
        <Text>{t("START_TIME")}:</Text>
        <TimePicker style={{minWidth: 70}}
                    value={startTime}
                    onChange={_startTime}/>
        <Text>{t("END_TIME")}:</Text>
        <TimePicker style={{minWidth: 70}}
                    value={getEndTimeFromDayReport(dayReport, DayReportMode.APPROVE) || '00:00'}
                    disabled={true}
                    onChange={() => {}}/>
      </View>

      <DayReportApproveHeader/>

      <KeyboardAwareScrollView style={{flex: 1}}>

        <FlatList
          data={dayReport.Reports}
          renderItem={_render}
          key={`report-${dayReport.ID}`}
          // @ts-ignore
          listKey={`report-${dayReport.ID}`}
          keyExtractor={(item, index) => `report-${dayReport.ID}-${index}-${item.ID}`}
        />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 6,
            marginBottom: 6,
          }}
        >
          <Button
            style={{
              backgroundColor: theme.mainColor,
            }}
            onPress={_addWork}
            titleT="ADD_WORK"
          />
        </View>
        <View style={{flexDirection: 'row', marginTop: 3}}>
          <Text style={styles.label}>{t("NOTES")}:</Text>
          <TextInput
            style={styles.inputs}
            multiline={true}
            value={dayReport?.Notes || ""}
            onChangeText={_notes}/>
        </View>
        <View style={{flexDirection: 'row', marginTop: 3}}>
          <Text style={styles.label}>{t("APPROVAL_NOTES")}:</Text>
          <TextInput
            style={styles.inputs}
            multiline={true}
            value={dayReport?.ApprovalNotes || ""}
            onChangeText={_approvalNotes}/>
        </View>

        <View style={{marginTop: 3}}>
          <View style={{flex: 1}}>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.label}>{t("SIGNED_BY")}:</Text>
              <TextInput
                style={styles.inputs}
                value={dayReport?.SignedBy}
                onChangeText={_signedBy}
              />
            </View>
            <View style={{flexDirection: 'row', marginVertical: 3}}>
              <Text style={styles.label}>{t("DATE")}:</Text>
              <View style={{flex: 1, alignItems: "center"}}>
                <DatePicker value={dayReport?.SignedTime || new Date()}
                            onChange={_signedTime}
                            style={{width: 100}}/>
              </View>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.label}>{t("PLACE")}:</Text>
              <TextInput
                style={styles.inputs}
                value={dayReport?.SignedPlace}
                onChangeText={_signedPlace}/>
            </View>
          </View>
          <View style={{flexDirection: 'row', flex: 1}}>
            <Text style={{marginHorizontal: 2}}>{t("SIGNATURE")}:</Text>

            {dayReport?.Signature ? (
              <View style={{flex: 1, height: 110, position: 'relative'}}>
                <Image source={{uri: dayReport?.Signature}}
                       style={styles.signature}/>
              </View>
            ) : (
              <View style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
                <Text>{t("SIGNATURE_NOT_AVAILABLE")}</Text>
              </View>
            )}
          </View>
        </View>
      </KeyboardAwareScrollView>
      {loading && <View style={{
        zIndex: 100,
        height: "100%",
        width: "100%",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.7)"
      }}>
        <ActivityIndicator color={theme.mainColor}/>
      </View> }
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    width: 120,
    fontSize: 16,
  },
  inputs: {
    backgroundColor: "white",
    flex: 1,
    padding: 9,
    borderRadius: 6,
  },
  signatureIconClose: {
    top: 0,
    right: 0,
    position: "absolute",
    zIndex: 1,
  },
  signature: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
});

export default DayReportApproveView;