import React from "react";
import { Pressable, Text, View } from "react-native";
import { t } from "i18n-js";
import getTheme from "../../../constants/Colors";

interface Props {
  views: {
    title: string;
    key: string;
  }[];
  viewKey: string;
  setView: (key: string) => void;
}

const DocSegmentedView = ({views, setView, viewKey}: Props) => {

  const theme = getTheme();

  const _render = (view: {title: string, key: string}) => {

    const _press = () => {
      setView(view.key);
    }

    return (
      <Pressable
        style={{
          paddingVertical: 3,
          flex: 1,
          backgroundColor: viewKey === view.key ? theme.background : "white",
        }}
        key={view.key}
        onPress={_press}
      >
        <Text style={{color: viewKey === view.key ? "light-gray" : "gray", textAlign: "center"}}>
          {t(view.title)}
        </Text>
      </Pressable>
    )
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        borderRadius: 9,
        borderWidth: 1,
        borderColor: theme.border,
        marginVertical: 14,
      }}
    >
      {views.map(_render)}
    </View>
  )
};

export default DocSegmentedView;