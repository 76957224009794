import React, { useEffect, useMemo, useState } from "react";
import { Level, Line, LineLevel, UoM, VAT, LineSumType, LineType } from '../../../models'
import { FlatList, TextInput, Text, View } from "react-native";
import getTheme from "../../../constants/Colors";
import { t } from "i18n-js";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Accordion from "../../../components/Accordion";
import { newFlatRateEngineeringWarrantyLine, newFlatRateLine, newTextLine } from "../utils";
import DocLineThreeDotsLineLevelModal from "./DocLineThreeDotsLineLevelModal";
import UoMComponent from "../../../components/UoMComponent/UoMComponent";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Button from "../../../components/Button";
import Modal from "react-native-modal";
import Formatter from "../../../utils/formatters";
import DualSelector from "../../../components/DualSelector";
import VATSelector from "../../../components/VATSelector";
import { PrintTwinLevel } from "./utils";

interface Props {
  line: Line;
  setLine: (setLine:(old: Line)=>Line) => void;
  addLine: (line: Line, keepOrder: boolean) => void;
  levels: Level[];
  navigateToInsertPositionView: () => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setTwinsPrices: (levelIndex: number, price: number, discount: number, discountNumeric: boolean) => void
  priceNoPrintByLevels?: PrintTwinLevel[]
}

const areEqual = (p: Readonly<Props>, n: Readonly<Props>) => {
  return JSON.stringify(p) === JSON.stringify(n) && p.setLine === n.setLine;
}

const ModalContent = ({line, setLine, addLine, levels, navigateToInsertPositionView, setVisible, setTwinsPrices, priceNoPrintByLevels}: Props) => {
  const theme = getTheme();

  const [name, setName] = useState<string>(line.Name);
  const [internalName, setInternalName] = useState<string>(line.InternalName);

  const [internalNameError, setInternalNameError] = useState<string>("");

  const allPrice = line.LineLevel?.[0]?.Price || 0
  const allDiscount = line.LineLevel?.[0]?.Discount || 0
  const allDiscountNumeric = line.LineLevel?.[0]?.DiscountNumeric || false
  const allFixedPrice = line.LineLevel?.[0]?.FixedPriceBlock != '' && line.LineLevel?.[0]?.FixedPriceBlock != '-'
  const allVat = line.LineLevel?.[0]?.Vat
    
  const allQuantity = useMemo(() => {
    if (line.LineLevel == undefined || line.LineLevel.length < 2) {
      return undefined
    }

    for (let i = 1; i < line.LineLevel.length; i++) {
      if (line.LineLevel[i].Price !== allPrice) {
        return undefined
      }
      if (line.LineLevel[i].Discount !== allDiscount) {
        return undefined
      }
      if (line.LineLevel[i].DiscountNumeric !== allDiscountNumeric) {
        return undefined
      }
      if (line.LineLevel[i].FixedPriceBlock !== line.LineLevel[0].FixedPriceBlock) {
        return undefined
      }
      if (line.LineLevel[i].Vat !== allVat) {
        return undefined
      }
    }

    return line.LineLevel.reduce((p, v) => {
      if (v.LineSumType == LineSumType.NORMAL) {
        return p + v.Quantity
      }
      return p
    }, 0)
  }, [line.LineLevel])

  const usedLevels = useMemo(
    () => line.LineLevel?.filter(l => l.Quantity != 0 && l.LineSumType === LineSumType.NORMAL).length || 0,
    [line.LineLevel]
  )
  
  let allLineTotal = 0
  if (allDiscountNumeric) {
    allLineTotal = allPrice * (allQuantity || 0) - (allDiscount * usedLevels)
  } else {
    allLineTotal = allPrice * (allQuantity || 0) * (1 - allDiscount / 100)
  }

  const _price = (price: string) => {
    setLine(old => {
      return {
        ...old,
        LineLevel: old.LineLevel.map(item => {
          let lineTotal
          if (allDiscountNumeric) {
            lineTotal = Number(price) * item.Quantity - allDiscount
          } else {
            lineTotal = Number(price) * item.Quantity * (1 - allDiscount / 100)
          }
          return {
            ...item,
            Price: Number(price),
            LineTotal: lineTotal,
          }
        }),
      }
    })
  }

  const _numericDiscount = (discountNumeric: boolean) => {
    setLine(old => {
      return {
        ...old,
        LineLevel: old.LineLevel.map(item => {
          let lineTotal
          if (discountNumeric) {
            lineTotal = allPrice * item.Quantity - allDiscount
          } else {
            lineTotal = allPrice * item.Quantity * (1 - allDiscount / 100)
          }
          return {
            ...item,
            DiscountNumeric: discountNumeric,
            LineTotal: lineTotal,
          }
        }),
      }
    })
  }

  const _discount = (discount: string) => {
    setLine(old => {
      return {
        ...old,
        LineLevel: old.LineLevel.map(item => {
          let lineTotal
          if (allDiscountNumeric) {
            lineTotal = allPrice * item.Quantity - Number(discount)
          } else {
            lineTotal = allPrice * item.Quantity * (1 - Number(discount) / 100)
          }
          return {
            ...item,
            Discount: Number(discount),
            LineTotal: lineTotal,
          }
        }),
      }
    })
  }

  const _vat = (vat?: VAT) => {
    setLine(old => {
      return {
        ...old,
        LineLevel: old.LineLevel.map(item => {
          return {
            ...item,
            Vat: vat,
          }
        }),
      }
    })
  }

  useEffect(() => {
    if ((internalName?.length || 0) > 100) {
      setInternalNameError(t("INTERNAL_NAME_CAN_BE_MAX_100"));
    } else {
      setInternalNameError("");
    }
  }, [internalName])

  const _close = () => {
    setVisible(false);
    setLine(old => {
      return {
        ...old,
        Name: name,
        LineLevel: old.LineLevel ? old.LineLevel.map(item => {
          return {
            ...item,
            Name: name,
          };
        }) : [],
        InternalName: internalName.substring(0, 100),
      }
    });
  }

  const _addPosition = () => {
    setVisible(false)
    navigateToInsertPositionView()
  }

  const _insertText = () => {
    setVisible(false);
    addLine(newTextLine(), true);
  }

  const _insertFlatRateLine = () => {
    setVisible(false);
    addLine(newFlatRateLine(), true);
  }

  const _insertEngineeringWarrantyFlatRateLine = () => {
    setVisible(false);
    addLine(newFlatRateEngineeringWarrantyLine(), true);
  }

  const _duplicateLine = () => {
    setVisible(false)
    addLine(line, true);
  }

  const _render = ({item, index}: {item: LineLevel, index: number}) => {
    const _setLineLevel = (lineLevel: LineLevel) => {
      setLine(old => ({
        ...old,
        LineLevel: old.LineLevel.map((item2, index2) => {
          if (index !== index2) {
            return item2;
          }
          return lineLevel;
        }),
        Quantity: old.LineLevel.reduce((p, n, index2) => {
          if (index !== index2 && n.LineSumType === LineSumType.NORMAL) {
            return p + n.Quantity;
          } else if (index === index2 && lineLevel.LineSumType === LineSumType.NORMAL) {
            return p + lineLevel.Quantity;
          } else {
            return p;
          }
        }, 0),
        LineTotal: old.LineLevel.reduce((p, n, index2) => {
          if (index !== index2 && n.LineSumType === LineSumType.NORMAL && (n.FixedPriceBlock === "-" || n.FixedPriceBlock === "")) {
            return p + n.LineTotal;
          } else if (index === index2 && lineLevel.LineSumType === LineSumType.NORMAL && (lineLevel.FixedPriceBlock === "-" || lineLevel.FixedPriceBlock === "")) {
            return p + lineLevel.LineTotal;
          } else {
            return p;
          }
        }, 0),
      }));
    };

    const _setTwinsPrices = (price: number, discount: number, discountNumeric: boolean) => {
      setTwinsPrices(index, price, discount, discountNumeric)
    }

    return (
      <DocLineThreeDotsLineLevelModal
        lineLevel={item}
        setLineLevel={_setLineLevel}
        level={levels[index]}
        key={index}
        unitOfMeasure={line.UnitOfMeasure}
        setTwinsPrices={_setTwinsPrices}
        priceNoPrintByLevels={priceNoPrintByLevels?.[index]}
      />
    );
  };
  // todo check for title, text fix-price, fix-price-engi...

  const _uom = (uom: UoM) => {
    console.log(uom);
    setVisible(false);
    setLine(old => {
      return {
        ...old,
        UnitOfMeasure: uom.Name,
      }
    });
  }

  const f2 = Formatter.number2.format

  return (
    <View>
      <View style={{flexDirection: 'row'}}>
        <Text style={{fontSize: 25, flex: 1}}>{t("EDIT_LINE")}</Text>
        <MaterialCommunityIcons
              name="close"
              size={24}
              onPress={_close}/>
      </View>

      <View style={{flexDirection: "row", alignItems:"center"}}>
        <View style={{flex: 1, paddingRight: 6}}>
          <Accordion text={t("NAME")}>
            <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}}/>
            <View
              style={{
                margin: 3,
                backgroundColor: 'white',
                borderRadius: 15,
                paddingHorizontal: 6,
                paddingVertical: 1,
              }}
            >
              <TextInput multiline={true} onChangeText={setName} value={name} style={{height: 100}}/>
            </View>
          </Accordion>
        </View>
        <View style={{paddingTop: 6}}>
          <UoMComponent
            selected={line.UnitOfMeasure}
            onChange={_uom}
          />
        </View>
      </View>

      <Accordion text={t("INTERNAL_NAME")}>
        <View style={{borderBottomWidth: 1, borderBottomColor: theme.border}}/>
        <View
          style={{
            margin: 9,
            backgroundColor: 'white',
            borderRadius: 5,
            paddingHorizontal: 6,
            paddingVertical: 3,
          }}
        >
          <TextInput multiline={true} onChangeText={setInternalName} value={internalName} style={{height: 100}}/>
        </View>
        <Text style={{paddingVertical: 10, color: 'red'}}>{internalNameError}</Text>
      </Accordion>

      {
        (
          line.LineType === LineType.POSITION ||
          line.LineType === LineType.POSITION_CAR ||
          line.LineType === LineType.POSITION_ENGINEERING ||
          line.LineType === LineType.POSITION_EXTERNAL_WORK ||
          line.LineType === LineType.POSITION_SELL_WARRANTY
        ) &&
        <Accordion text={t("PRICING")}>
          <View style={{margin: 9}}>
            <View style={{flexDirection: 'row'}}>
              <View style={{flex: 1}}/>
              <Text style={{flex: 1}}>{t("QUANTITY")}</Text>
              <Text style={{flex: 1}}>{t("UNIT_OF_MEASURE")}</Text>
              <Text style={{flex: 1}}>{t("PRICE")}</Text>
              <View style={{flex: 1}}/>
              <Text style={{flex: 1}}>{t("DISCOUNT")}</Text>
              <Text style={{flex: 1}}>{t("LINE_TOTAL")}</Text>
              <Text style={{flex: 2}}>{t("VAT")}</Text>
            </View>

            {(line.LineLevel.length > 1 && allQuantity != undefined && (line.TwinIDs == undefined || line.TwinIDs.length == 0 || line.PrintTwin)) && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingTop: 9,
                }}
              >
                <Text style={{flex: 1}}>{t("ALL_LEVELS")}</Text>
                <Text style={{flex: 1}}>{f2(allQuantity)}</Text>
                <Text style={{flex: 1}}>{line.UnitOfMeasure}</Text>
                {allFixedPrice ? (
                  <Text style={{flex: 5}}>{t("NOT_AVAILABLE_FOR_FIXED_PRICE")}</Text>
                ): (
                  <>
                    <View style={{flex: 1, paddingHorizontal: 1}}>
                      <TextInput defaultValue={String(allPrice)}
                                onChangeText={_price}
                                style={{borderColor: theme.border, borderWidth: 1, borderRadius: 5, padding: 2}}
                                keyboardType={"number-pad"}/>
                    </View>

                    <View style={{flex: 1}}>
                      <DualSelector left={allDiscountNumeric}
                                    setLeft={_numericDiscount}
                                    width={"100%"}
                                    leftLabel={"DISCOUNT_NUMERIC"}
                                    rightLabel={"DISCOUNT_PERCENTAGE"}/>
                    </View>

                    <View style={{flex: 1, paddingHorizontal: 1}}>
                      <TextInput defaultValue={String(allDiscount)}
                                onChangeText={_discount}
                                style={{borderColor: theme.border, borderWidth: 1, borderRadius: 5, padding: 2}}
                                keyboardType={"number-pad"}/>
                    </View>

                    <Text style={{flex: 1}}>{f2(allLineTotal)}</Text>

                    <View style={{flex: 2}}>
                      <VATSelector
                        vat={allVat}
                        setVAT={_vat}
                      />
                    </View>
                  </>
                )}
              </View>
            )}

            {(line.PrintTwin) && (
              <FlatList
                data={line.LineLevel}
                renderItem={_render}
                key={`three-dots-line-${line.ID}`}
                // @ts-ignore
                listKey={`three-dots-line-${line.ID}`}
                keyExtractor={(item, index) => `three-dots-line-${line.ID}-${index}-${item.ID}`}
              />
            )}
          </View>
        </Accordion>
      }

      <View 
        style={{
          flexDirection: 'row',
          paddingTop: 9,
          justifyContent: 'center', 
          paddingVertical: 4,
        }}
      >
        <Button
          onPress={_addPosition}
          titleT="ADD_POSITION"
          style={{
            backgroundColor: theme.blue,
          }}
        />

        <Button
          onPress={_insertText}
          titleT="ADD_TEXT_LINE"
          style={{
            backgroundColor: theme.blue,
            marginHorizontal: 5,
          }}
        />

        <Button
          onPress={_insertFlatRateLine}
          titleT="INSERT_FLAT_RATE"
          style={{
            backgroundColor: theme.blue,
            marginHorizontal: 5,
          }}
        />

        <Button
          onPress={_insertEngineeringWarrantyFlatRateLine}
          titleT="INSERT_FLAT_RATE_ENGINEERING_WARRANTY"
          style={{
            backgroundColor: theme.blue,
            marginHorizontal: 5,
          }}
        />

        <Button
          onPress={_duplicateLine}
          titleT="DUPLICATE_LINE"
          style={{
            backgroundColor: theme.blue,
          }}
        />
      </View>
    </View>
  );
}

const DocLineThreeDotsModal = React.memo((props: Props) => {

  return (
    <View>
      <Modal
        isVisible={props.visible}
        onBackdropPress={() => props.setVisible(false)}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
        }}
      >
        <KeyboardAwareScrollView extraScrollHeight={100}>
          <ModalContent {...props} />
        </KeyboardAwareScrollView>
      </Modal>
    </View>
  );
}, areEqual);

export default DocLineThreeDotsModal;