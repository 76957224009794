import React from 'react';
import { ReadLevel } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import ReadLevelSelect from "./ReadLevelSelect";
import { t } from 'i18n-js';
import { useState } from "react";
import { getDocumentAsync } from "expo-document-picker";
import { TextInput, Text, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import getTheme from "../../constants/Colors";
import { uploadFile } from "../../api/FileAPI";
import Modal from 'react-native-modal';
import Button from '../Button';
import { sentryCapture } from '../../utils/sentryCapture';
import { alert } from '../../hooks/Alert';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  fileTypeId: number | null;
  contractId: number;
  setFileTypeId: (fileTypeId:number|null) => void;
}

const UploadNewDocumentModal = (props: Props) => {

  const theme = getTheme();

  const readLevel2 = useSelector((state:Reducer) => state.user.readLevel);

  const [readLevel, setReadLevel] = useState<ReadLevel>(readLevel2);
  const [name, setName] = useState<string>("");
  const [uri, setUri] = useState<string>("");
  const [size, setSize] = useState<number | null>();

  const [selected, setSelected] = useState<boolean>(false);

  const _onClose = () => {
    props.setFileTypeId(null);
    props.setVisible(false);
  }

  const _onPressChoseDocument = async () => {
    if (!selected) {
      // select document
      try {
        const res = await getDocumentAsync({
          type: "application/pdf",
          multiple: false,
        });
        if (!res.canceled && res.assets.length == 1) {
          if (name.length === 0) {
            setName(res.assets[0].name);
          }
          setUri(res.assets[0].uri);
          setSize(res.assets[0].size);
          setSelected(true);
        }
      } catch (e) {
        sentryCapture(e);
        alert('ERROR_LOADING_FILE', 'ERROR_LOADING_FILE_DESCRIPTION');
      }
    } else {
      // clear document
      setUri("");
      setSize(null);
      setSelected(false);
    }
  }

  const _onPressUpload = async () => {

    if (props.fileTypeId) {
      try {
        await uploadFile(
          uri,
          props.fileTypeId,
          name,
          readLevel.ID,
          props.contractId,
        );
        props.setVisible(false);
      } catch (e) {
        sentryCapture(e);
        alert('ERROR_UPLOADING_FILE', 'ERROR_UPLOADING_FILE')
      }
    } else {
      alert("WARNING", "SELECT_FILE_TYPE_BEFORE_UPLOADING")
    }
  }

  return (
    <Modal
      isVisible={props.visible}
      onBackdropPress={_onClose}
      style={{
        flex: undefined,
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flexDirection: 'row'}}>
        <MaterialCommunityIcons
          size={15}
          name="close"
          color='black'
          onPress={_onClose}
        />
      </View>
      <View>
        <Text style={{fontSize: 25, paddingTop: 12, paddingBottom: 9}}>{t('UPLOAD_NEW_FILE')}</Text>
      </View>
      <View style={{flexDirection: "row"}}>
        <Text style={{paddingTop: 9, paddingRight: 15}}>{t('READ_LEVEL')}</Text>
        <ReadLevelSelect readLevel={readLevel} setReadLevel={setReadLevel}/>
      </View>
      <View style={{flexDirection: 'row', paddingTop: 9}}>
        <Text style={{paddingTop: 3, paddingRight: 15}}>{t('NAME')}</Text>
        <TextInput
          value={name}
          onChangeText={setName}
          style={{
            flex: 1,
            padding: 6,
            borderRadius: 6,
            borderColor: theme.border,
          }}
        />
      </View>
      <Button
        onPress={_onPressChoseDocument}
        style={{
          marginTop: 2,
          backgroundColor: theme.blue,
        }}
        title={selected?t('CANCEL'):t('SELECT')}
      />
      <Button
        onPress={_onPressUpload}
        style={{
          marginTop: 2,
          backgroundColor: selected ? theme.mainColor : '#aaa',
        }}
        disabled={!selected}
        titleT='UPLOAD'
      />
    </Modal>
  )
}

export default UploadNewDocumentModal;