

export const roundToNearest15 = (date = new Date()) => {
  const minutes = 15
  const ms = 1000 * 60 * minutes

  // 👇️ replace Math.round with Math.ceil to always round UP
  return new Date(Math.round(date.getTime() / ms) * ms)
}

/**
 * Convert minutes into hours: 86 minutes = 1.43 hours.
 *
 * @param minutes Minutes to be inverted into hours: 86 minutes = 1.43 hours.
 * @returns Hours.
 */
export const minutesToHours = (minutes?: number): number | undefined => {
  if (!minutes) {
    return minutes
  }

  return Math.floor(minutes / 60) + minutes % 60 / 60
}

/**
 * Convert hours into minutes: 1.43 hours = 86 minutes.
 *
 * @param hours Hours to be converted into minutes: 1.43 hours = 86 minutes.
 * @returns Minutes.
 */
export const hoursToMinutes = (hours?: number): number | undefined => {
  if (!hours) {
    return hours
  }

  const roundedHours = Math.floor(hours)
  return roundedHours * 60 + (hours - roundedHours) * 60
}