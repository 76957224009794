import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ConstructionSite, Contract, Doc } from "../../models";
import { alert } from "../../hooks/Alert";
import { getConstructionSiteById } from "../../api/ConstructionSitesAPI";
import { createContract, createDoc } from "../../utils/doc/util";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import DocScreen from "./DocScreen";
import { DocMode } from "./utils";
import { ActivityIndicator, View } from "react-native";
import { getOrderByContractId } from "../../api/DocAPI";
import { sapDocToDocConvert } from '../../shared-utils/ConversionUtils'
import { logout } from "../../store/action/authAction";
import { sentryCapture } from "../../utils/sentryCapture";
import { setDocOffline } from "../../utils/doc-offline/util";

interface Props {
  route: {
    params?: {
      constructionSiteId?: number;
      contractId?: number;
      docTypeId?: number;
      warrantyId?: number;
    };
  };
}
const DocScreenCreateContract = ({route}:Props) => {
  
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const user = useSelector((state:Reducer) => state.user);
  const types = useSelector((state:Reducer) => state.doc.types);
  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  const [constructionSite, setConstructionSite] = useState<ConstructionSite>();
  const [contract, setContract] = useState<Contract>();
  const [doc, setDoc] = useState<Doc>();
  const [uuid, setUuid] = useState<string>()

  useEffect(() => {
    if (!route.params || !route.params.constructionSiteId) {
      // TODO logs
      alert("ERROR", "ERROR_CREATE_CONTRACT");
      navigation.goBack();
      return;
    }

    const type = types.find(t => t.ID === route.params?.docTypeId);

    getConstructionSiteById(route.params.constructionSiteId).then(constructionSite => {
      setConstructionSite(constructionSite);
      let contract = constructionSite.Contracts.find(c => c.ID === route.params?.contractId);
      let doc:Doc;
      if (!contract) {
        const c = createContract(-1, constructionSite.ID, user);
        contract = c.contract;
        doc = c.doc;
      } else {
        doc = createDoc(-1, contract.ID, user);
      }
      setContract(contract);
      if (type) {
        doc.Type = type;
        doc.TypeID = type.ID;
        if (type.ID === 4) { // integrative offer
          // get order
          getOrderByContractId(contract.ID).then(sapDoc => {
            const order = sapDocToDocConvert(sapDoc, catalogs);
            doc.Client = order.Client;
            doc.ClientID = order.ClientID;
            doc.ClientContact = order.ClientContact;
            doc.ClientContactID = order.ClientContactID;
            doc.Discounts = order.Discounts;
            if (contract) {
              setDocOffline(constructionSite, contract, doc).then(uuid => {
                setUuid(uuid)
                setDoc(doc)
              }).catch(sentryCapture)
            }
          }).catch(e => {
            if (e.response && e.response.code == 401) {
              alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
              dispatch(logout());
            } else {
              sentryCapture(e);
              alert("ERROR", "ERROR_LOADING_ORDER");
              navigation.goBack();
            }
          });
          return;
        } else if (type.ID === 5) { // warranty
          doc.FromContractID = route.params?.warrantyId;
        }
      }
      setDoc(doc);
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR", "ERROR_CONSTRUCTION_SITE");
        navigation.goBack();
      }
    });
  }, [])

  if (doc && contract && constructionSite) {
    return (
      <DocScreen
        initialMode={DocMode.CREATE}
        initialConstructionSite={constructionSite}
        initialContract={contract}
        initialDoc={doc}
        uuid={uuid}
      />
    );
  }

  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <ActivityIndicator />
    </View>
  );
}

export default DocScreenCreateContract;