import { t } from "i18n-js";
import React from "react";
import { View, Text } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

const NoElementTemplate = (i18nkey: string) => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text style={{ fontSize: RFValue(11) }}>{t(i18nkey)}</Text>
    </View>
  );
};

export default NoElementTemplate;
