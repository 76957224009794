import React, { useEffect, useState } from 'react'
import { Absence, Contract, ContractPlanned, DayReport, LineType, StartStop } from '../../../models'
import { getMyAssignments } from '../../../api/PlannningAPI'
import { alert } from '../../../hooks/Alert'
import { sentryCapture } from '../../../utils/sentryCapture'
import { getActivities, getMyDayReports } from '../../../api/ReportAPI'
import { ActivityIndicator, FlatList, Pressable, ScrollView, Text, View } from 'react-native'
import { t } from 'i18n-js'
import getTheme from '../../../constants/Colors'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { logout } from '../../../store/action/authAction'
import DayReportItem from '../DayReportItem'
import AbsenceItem from '../../../components/AbsenceItem'
import AbsenceModal from '../../../components/AbsenceModal'
import OfflineDayReports from './OfflineDayReports'
import ChooseOpenContractModal from '../../../components/ChooseOpenContractModal'
import { v4 } from 'uuid'
import { activitiesToNewReport } from '../../../shared-utils/reports/convert'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { getStartStopKey } from '../../../utils/reports/report-utils'
import { ReportsScreens } from '../ReportsScreens'
import { isActiveContract, OPEN_DAY_REPORT } from './util'
import ContractPlannedItem from './ContractPlannedItem'
import storage from '../../../storage'

const DayReportsDashboard = () => {

  const navigation = useNavigation()

  const theme = getTheme()
  const isFocused = useIsFocused()
  const dispatch = useDispatch()

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)
  const resource = useSelector((state: Reducer) => state.general.resource)
  const reportActivitiesLinesFilter = useSelector((state: Reducer) => state.user.reportActivitiesLinesFilter)

  const [loadingAssignmentsProgress, setLoadingAssignmentsProgress] = useState<number | undefined>()
  //const [plannedToday, setPlannedToday] = useState<ConstructionSitePlanned[] | undefined>([])
  const [plannedToday, setPlannedToday] = useState<ContractPlanned[] | undefined>()

  const [loadingMyDayReports, setLoadingMyDayReports] = useState<boolean>(false)
  const [reports, setReports] = useState<{
    [key: string]: {
      reports: DayReport[]
      totalTime: number
      timeTarget: number
      absences: Absence[]
    }
  }>()

  const [absence, setAbsence] = useState<Absence | undefined>()

  const [chooseOpenContractModalVisible, setChooseOpenContractModalVisible] = useState<boolean>(false)
  const _openChooseOpenContractModal = () => setChooseOpenContractModalVisible(true)
  const _closeChooseOpenContractModal = () => setChooseOpenContractModalVisible(false)

  const _newAbsence = (key: string) => {
    setAbsence({
      Date: key === 'yesterday' ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000) : new Date(),
      Time: 0,
      Notes: '',
      OfficeNotes: '',
      End: '',
      Start: '',
      Resource: resource!,
      ResourceID: resource!.ID
    })
  }
  const _closeAbsence = () => {
    setAbsence(undefined)
    pullMyDayReports()
  } 

  const pull = () => {
    pullMyDayReports()
    pullPlanning()
  }
  const pullPlanning = async () => {
    if (!serverReachable) {
      return
    }
    setLoadingAssignmentsProgress(0)
    setPlannedToday(undefined)
    try {
      const assignments = await getMyAssignments()
      let day = new Date().getDay()
      if (day == 0) {
        day = 6 
      } else {
        day--
      }
      if (day >= assignments.Days.length) {
        alert('ASSIGNMENT_ERROR', 'ASSIGNMENT_ERROR_DESC')
        sentryCapture(`error asssignement day ${day} not found in ${assignments.Days.length} days - Resource: ${assignments.Resource.ID}`)
        setLoadingAssignmentsProgress(undefined)
        return
      }

      let contracts = assignments
          .Days[day]
          .ConstructionSites
          .reduce((acc, cs) => [
            ...acc,
            ...cs.Contracts.filter(isActiveContract).map((c: Contract) => ({
              ...c,
              constructionSite: cs,
              levels: [],
              activities: [],
              ExpireDate: new Date(),
              DocumentContacts: [],
              OrderNotes: '',
              CatalogID: 0,
            }))
          ], [] as ContractPlanned[])

      setLoadingAssignmentsProgress(1 / (contracts.length + 1))

      let removeContracts: number[] = []
      for (let i = 0; i < contracts.length; i++) {
        try {
          const c = contracts[i]
          const activities = await getActivities(c.ID)
          contracts[i] = {
            ...c,
            ...activities,
          }
          setLoadingAssignmentsProgress((i + 2) / (contracts.length + 1))
        } catch (e) {
          removeContracts.push(contracts[i].ID) 
        }
      }
      contracts = contracts.filter(c => !removeContracts.includes(c.ID))

      setPlannedToday(contracts)
      storage.setItem('plannedToday', JSON.stringify(contracts))
    } catch (e) {
      alert('ASSIGNMENT_ERROR', 'ASSIGNMENT_ERROR_DESC')
      sentryCapture(e)
    } finally {
      setLoadingAssignmentsProgress(undefined)
    }
  }

  const pullMyDayReports = () => {
    if (!serverReachable) {
      return
    }
    setLoadingMyDayReports(true)
    setReports(undefined)
    getMyDayReports(1).then((reports) => {
      setReports({
        today: {
          reports: reports[0].reports,
          totalTime: reports[0].reports.reduce((acc, report) => {
            return acc + report.TotalTime + report.TravelTime - report.BreakTime
          }, 0) + reports[0].absences.reduce((acc, absence) => {
            return acc + (absence.Type?.Negative ? -1 : 1) * absence.Time
          }, 0),
          timeTarget: reports[0].timeTarget,
          absences: reports[0].absences,
        },
        yesterday: reports.length > 1 ? {
          reports: reports[1].reports,
          totalTime: reports[1].reports.reduce((acc, report) => {
            return acc + report.TotalTime + report.TravelTime - report.BreakTime
          }, 0) + reports[1].absences.reduce((acc, absence) => {
            return acc + (absence.Type?.Negative ? -1 : 1) * absence.Time
          }, 0),
          timeTarget: reports[1].timeTarget,
          absences: reports[1].absences,
        } : {reports: [], totalTime: 0, timeTarget: 0, absences: []},
      })
    }).catch(e => {
      if (e.response && e.response.code == 401) {
        alert('ERROR', 'YOUR_SESSION_IS_NOT_VALID')
        dispatch(logout())
      } else {
        sentryCapture(e)
        alert('ERROR', 'ERROR_LOADING_DAY_REPORTS')
      }
    }).finally(() => setLoadingMyDayReports(false))
  }

  useEffect(() => {
    if (serverReachable) {
      pull()
    } else {
      storage.getItem('plannedToday').then((plannedToday) => {
        if (plannedToday) {
          setPlannedToday(JSON.parse(plannedToday))
        } else {
          // TODO tell user not downloaded data and no internet connection
        }
      }).finally(() => setLoadingAssignmentsProgress(undefined))
    }
    storage.getItem(OPEN_DAY_REPORT).then(uuid => {
      if (uuid) {
        // @ts-ignore
        navigation.navigate(ReportsScreens.REPORT_DRAFT, {
          uuid,
        })
      }
    })
  }, [])

  useEffect(() => {
    isFocused && pull()
  }, [isFocused])

  const _choose = async (choosenContract: ContractPlanned) => {

    if (resource == null) {
      alert('ERROR', 'ERROR')
      return
    }

    const keys = await storage.getAllKeys()
    const startStopKeys = keys.filter(k => k.startsWith(getStartStopKey('')))
    let startStops = []
    for (let i = 0; i < startStopKeys.length; i++) {
      const value = await storage.getItem(startStopKeys[i])
      if (value) {
        startStops.push(JSON.parse(value) as StartStop)
      }
    }
    const s = startStops.find(s => s.dayReport.ContractID == choosenContract.ID)
    if (s) {
      storage.setItem(OPEN_DAY_REPORT, s.uuid)
      // @ts-ignore
      navigation.navigate(ReportsScreens.REPORT_DRAFT, {
        uuid: s.uuid,
      })
      return
    }

    const uuid = v4()
    const dayReport = activitiesToNewReport({
      activities: choosenContract
        .activities
        .filter(a => reportActivitiesLinesFilter.includes(a.Type))
        .filter((a, _, self) => self.filter(s => a.Type != LineType.TITLE || s.FatherID == a.Code).length > 0),
      levels: choosenContract.levels,
      constructionSite: choosenContract.constructionSite, 
      contract: choosenContract,
      DocumentContacts: choosenContract.DocumentContacts,
      OrderNotes: choosenContract.OrderNotes,
      CatalogID: choosenContract.CatalogID,
    }, resource)

    const startStop: StartStop = {
      uuid,
      dayReport,
      materials: [],
    }
    await storage.setItem(getStartStopKey(uuid), JSON.stringify(startStop))

    storage.setItem(OPEN_DAY_REPORT, uuid)
    // @ts-ignore
    navigation.navigate(ReportsScreens.REPORT_DRAFT, {
      uuid,
      initShowNotes: true,
    })
  }

  const _render = ({item, index}: {item: ContractPlanned, index: number}) => {
    return (
      <ContractPlannedItem
        contract={item}
        key={`planned-construction-site-${item.ID}-${index}`}
        choose={_choose}
      />
    )
  }

  return (
    <View style={{
      flex: 1,
      marginTop: 20,
      flexDirection: 'row',
      marginHorizontal: 10,
    }}>
      {absence && (
        <AbsenceModal
          visible={absence !== undefined}
          onClose={_closeAbsence}
          setAbsence={pull}
          absence={absence}
        />
      )}
      {chooseOpenContractModalVisible && (
        <ChooseOpenContractModal
          onSelect={_choose}
          close={_closeChooseOpenContractModal}
          visible={chooseOpenContractModalVisible}
        />
      )}
      <ScrollView style={{flex: 1, marginHorizontal: 3}}>
        <View>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={{fontSize: 24, marginBottom: 6, flex: 1}}>
              {t('PLANNED_FOR_TODAY')}
            </Text>
            {serverReachable && (loadingAssignmentsProgress ? (
              <ActivityIndicator />
            ) : (
              <MaterialCommunityIcons
                onPress={pull}
                style={{marginLeft: 6}}
                size={24}
                color={theme.text}
                name='refresh'
              />
            ))}
          </View>
          {loadingAssignmentsProgress != undefined && (
            <View style={{flexDirection: 'row'}}>
              <View style={{
                flex: loadingAssignmentsProgress,
                borderBottomColor: theme.blue,
                borderBottomWidth: 1,
              }}/>
              <View style={{flex: 1 - loadingAssignmentsProgress}}/>
            </View>
          )}
        </View>
        <View>
          <FlatList
            data={plannedToday}
            renderItem={_render}
          />
          {(loadingAssignmentsProgress == undefined && plannedToday == undefined) && (
            <Text>{t('NO_INTERNET_CONNECTION_NO_SAVED_DATA')}</Text>
          )}
          {serverReachable && (
            <Pressable
              onPress={_openChooseOpenContractModal}
              style={{
                padding: 10,
                backgroundColor: 'white',
                marginTop: 10,
                alignItems: 'center',
              }}
            >
              <Text style={{fontSize: 20}}>
                {t('CHOOSE_OTHER')}
              </Text>
            </Pressable>
          )}
        </View>

        <OfflineDayReports
          pull={pull}
        />
      </ScrollView>
      <ScrollView style={{flex: 1, paddingLeft: 15}}>
        {loadingMyDayReports && (
          <ActivityIndicator />
        )}
        {Object.keys(reports ?? {}).map((key) => {
          if (reports === undefined) {
            return null
          }
          const report = reports[key]

          return (
            <View key={key}>
              <View style={{flexDirection: 'row', marginBottom: 6, justifyContent: 'space-between', paddingRight: 6}}>
                <Text style={{fontSize: 24, textTransform: 'capitalize'}}>
                  {t(key.toUpperCase())}
                </Text>
                {serverReachable && (
                  <MaterialCommunityIcons
                    name='plus'
                    size={24}
                    onPress={() => _newAbsence(key)}
                  />
                )}
              </View>
              <View style={{backgroundColor: 'white', borderRadius: 8, padding: 9}}>
                {report.reports.map((r, index) => {
                  return (
                    <DayReportItem
                      key={r.ID}
                      dayReport={r}
                      borderBottom={index !== report.reports.length - 1}
                    />
                  )
                })}
                {report.absences.map((a, index) => (
                  <AbsenceItem
                    key={`${a.Code}-${index}`}
                    absence={a}
                    pull={pull}
                    index={index}
                  />
                ))}
              </View>
            </View>
          )
        })}
      </ScrollView>
    </View>
  )
}

export default DayReportsDashboard