import React from 'react'
import NativeModalPicker from '../NativeModalPicker'
import { t } from 'i18n-js'

export enum SearchOrderBy {
  CODE = 0,
  NAME = 1,
}

interface Props {
  searchOrderBy: SearchOrderBy
  setSearchOrderBy: (searchOrderBy: SearchOrderBy) => void
  disabled?: boolean
}


const SearchOrderByDropdown = ({searchOrderBy, setSearchOrderBy, disabled = false}: Props) => {

  const _change = (value: SearchOrderBy) => {
    if (searchOrderBy == value || disabled) {
      return
    }

    setSearchOrderBy(value)
  }

  return (
    <NativeModalPicker
      itemToLabel={code => code == SearchOrderBy.CODE ? t('CODE') : t('NAME')}
      itemToKey={code => code.toString()}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={searchOrderBy}
      onValueChange={_change}
      options={[SearchOrderBy.CODE, SearchOrderBy.NAME]}
    />
  )
}

export default SearchOrderByDropdown