import React from "react";
import { Line } from "../../../models";
import { Text, View } from "react-native";
import { DocLineColumnWidth, DocLineColumnWidthNoCode } from "./DocLinesHeader";
import HtmlText from "../../../components/HtmlText";

interface Props {
  line: Line;
}

const DocLineTitle = React.memo(({line}: Props) => {

  return (
    <View style={{flexDirection: 'row', flex: 1}}>
      <Text style={{width: DocLineColumnWidth.CODE}}>{line.Code}</Text>
      <View style={{flex: 1}}>
        <HtmlText html={line.Name} maxLength={100} width={"100%"}/>
      </View>
      <View style={{width: DocLineColumnWidthNoCode}}/>
    </View>
  );
});

export default DocLineTitle;