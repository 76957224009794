import {
  LOGOUT,
  MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS,
  MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS,
  MEASURE_SHOW_BILLED_QUANTITY,
  MEASURE_SHOW_DONE_QUANTITY_PREVIOUS,
  MEASURE_SHOW_SOLD,
  SET_SEARCH_STRING,
  SET_SHOW_PRICE,
  SHOW_DOCS_OFFLINE,
  SHOW_OFFLINE_MEASURES,
  SHOW_PLANNING_WEEK_DAYS,
} from '../types'

export type settings = {
  showPriceLevel: number;
  measureShowSold: boolean
  measureShowDoneQuantityPrevious: boolean
  measureShowApprovedQuantityPrevious: boolean
  measureShowBillableQuantityPrevious: boolean
  measureShowBilledQuantity: boolean
  showOfflineMeasures: boolean
  showDocsOffline: boolean
  searchString: string
  showPlanningWeekDays: 5 | 6 | 7
}

const initialState: settings = {
  showPriceLevel: 0,
  measureShowSold: true,
  measureShowDoneQuantityPrevious: true,
  measureShowApprovedQuantityPrevious: true,
  measureShowBillableQuantityPrevious: true,
  measureShowBilledQuantity: true,
  showOfflineMeasures: true,
  showDocsOffline: true,
  searchString: '',
  showPlanningWeekDays: 5,
}

export default function (
  state = initialState,
  action: { type: string; payload: any },
): settings {
  switch (action.type) {
    case SET_SHOW_PRICE:
      return {
        ...state,
        showPriceLevel: action.payload,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    case MEASURE_SHOW_SOLD:
      return {
        ...state,
        measureShowSold: !state.measureShowSold,
      }
    case MEASURE_SHOW_DONE_QUANTITY_PREVIOUS:
      return {
        ...state,
        measureShowDoneQuantityPrevious: !state.measureShowDoneQuantityPrevious,
      }
    case MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS:
      return {
        ...state,
        measureShowApprovedQuantityPrevious: !state.measureShowApprovedQuantityPrevious,
      }
    case MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS:
      return {
        ...state,
        measureShowBillableQuantityPrevious: !state.measureShowBillableQuantityPrevious,
      }
    case MEASURE_SHOW_BILLED_QUANTITY:
      return {
        ...state,
        measureShowBilledQuantity: !state.measureShowBilledQuantity,
      }
    case SHOW_OFFLINE_MEASURES:
      return {
        ...state,
        showOfflineMeasures: !state.showOfflineMeasures,
      }
    case SHOW_DOCS_OFFLINE:
      return {
        ...state,
        showDocsOffline: !state.showDocsOffline,
      }
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload.searchString,
      }
    case SHOW_PLANNING_WEEK_DAYS:
      return {
        ...state,
        showPlanningWeekDays: action.payload,
      }
    default:
      return state;
  }
}

const PriceLevelNoPrice = 0;
const PriceLevelClient = 1;
const PriceLevelInternal = 2;
export const PriceLevel = {
  NO_PRICE: PriceLevelNoPrice,
  CLIENT: PriceLevelClient,
  INTERNAL: PriceLevelInternal,
};