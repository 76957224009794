import React, { useState } from 'react'
import { Contract, ExternalWorkInvoice } from '../../../../models'
import { Text, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import ExternalWorkInvoiceItem from './ExternalWorkInvoiceItem'
import { t } from 'i18n-js'
import ExternalWorkInvoiceModal from '../../../../components/ExternalWorkInvoiceModal'

interface ExternalWorkInvoices {
  contract: Contract
  pull: () => void
}

const ExternalWorkInvoices = ({
  contract,
  pull,
}: ExternalWorkInvoices) => {

  const [
    externalWorkInvoice,
    setExternalWorkInvoice,
  ] = useState<ExternalWorkInvoice>()
  const [
    externalWorkInvoiceCreate,
    setExternalWorkInvoiceCreate,
  ] = useState<ExternalWorkInvoice>()

  const _setExternalWorkInvoice = (
    externalWorkInvoice: ExternalWorkInvoice,
    create: boolean = false,
  ) => {
    if (create) {
      setExternalWorkInvoiceCreate(
        externalWorkInvoice
      )
    } else {
      setExternalWorkInvoice(
        externalWorkInvoice
      )
    }
  }

  const _closeExternalWorkInvoice = () => {
    if (externalWorkInvoiceCreate && externalWorkInvoiceCreate.DayReportId == undefined) {
      pull()
    }
    setExternalWorkInvoice(undefined)
    setExternalWorkInvoiceCreate(undefined)
  }

  const _render = ({item, index}: {item: ExternalWorkInvoice, index: number}) => {
    return (
      <ExternalWorkInvoiceItem
        key={`external-work-item-${item.ID}-${index}`}
        item={item}
        index={index}
        setExternalWorkInvoice={_setExternalWorkInvoice}
      />
    )
  }

  return (
    <View style={{
      flex: 1,
      paddingTop: 10,
      paddingHorizontal: 5,
    }}>
      {externalWorkInvoice && (
        <ExternalWorkInvoiceModal
          contract={contract}
          externalWorkInvoice={externalWorkInvoice}
          onClose={_closeExternalWorkInvoice}
          pull={pull}
        />
      )}
      {externalWorkInvoiceCreate && (
        <ExternalWorkInvoiceModal
          contract={contract}
          externalWorkInvoice={externalWorkInvoiceCreate}
          onClose={_closeExternalWorkInvoice}
          pull={pull}
          initCreateExternalWorkInvoiceReport={true}
        />
      )}
      <View style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingHorizontal: 5,
      }}>
        <Text style={{width: 70}}>
          {t('NUMBER')}
        </Text>
        <Text style={{flex: 1.5}}>
          {t('SUPPLIER')}
        </Text>
        <Text style={{flex: 1}}>
          {t('REFERENCE')}
        </Text>
        <Text style={{flex: 1}}>
          {t('INVOICE_DATE')}
        </Text>
        <Text style={{flex: 1, paddingRight: 10, textAlign: 'right'}}>
          {t('TOTAL_VAT_EXCL')}
        </Text>
        <Text style={{flex: 1, paddingRight: 10, textAlign: 'right'}}>
          {t('TOTAL')}
        </Text>
        <Text style={{flex: 1.5}}>
          {t('INSERTED_BY')}
        </Text>
        <View style={{width: 20}} />
      </View>

      <FlatList
        data={contract.ExternalWorkInvoices}
        renderItem={_render}
        style={{
          flex: 1,
          backgroundColor: 'white',
          marginTop: 10,
          borderRadius: 10,
          paddingVertical: 10,
          paddingHorizontal: 5,
          marginBottom: 5,
        }}
      />
    </View>
  )
}

export default ExternalWorkInvoices
