import React, { useEffect, useState } from 'react';
import { Modal, StyleSheet, TextInput, Text, View } from "react-native";
import { t } from 'i18n-js';
import { Client } from "../../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import LoseOfferModalClientsList from "./LoseOfferModalClientsList";
import getTheme from '../../../constants/Colors';
import Button from '../../../components/Button';

interface Props {
  loseOfferModalVisible: boolean;
  setLoseOfferModalVisible: (visible: boolean) => void;
  loseHandler: (client: Client|null, notes: string) => void;
}

const LoseOfferModal = ({loseOfferModalVisible, setLoseOfferModalVisible, loseHandler}: Props) => {

  const theme = getTheme();

  const clients = useSelector((state: Reducer) => state.client.clients);

  const [client, setClient] = useState<Client | null>(null);
  const [filtered, setFiltered] = useState<Client[]>([]);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");

  let notesInputRef: {_root: {focus: () => void;};};

  useEffect(() => {
    setFiltered(clients);
  }, [clients])

  const _closeRequestHandler = () => {
    setClient(null);
    setNotes("");
    setLoseOfferModalVisible(false);
  }

  const _inputChangeHandler = (text: string) => {
    if (text.length > 0) {
      text = text.toLowerCase()
      setFiltered(clients.filter(v => v.Name.toLowerCase().includes(text)))
    } else {
      setFiltered(clients)
    }
  }

  const _setClientHandler = (client: Client) => {
    setClient(client);
    notesInputRef?._root?.focus();
    setInputFocus(false);
  }

  const _loseHandler = () => {
    loseHandler(client, notes);
    setLoseOfferModalVisible(false);
  }

  return (
    <Modal
      animationType="fade"
      visible={loseOfferModalVisible}
      onRequestClose={_closeRequestHandler}
    >
      <View style={styles.container}>
        <View style={styles.modal}>
          <Text style={styles.title}>{t('WHO_WON_OFFER')}</Text>
          <TextInput
            style={{
              width: 400,
              padding: 5,
              borderRadius: 5,
              borderColor: theme.border,
              borderWidth: 1,
            }}
            placeholder={t('COMPETITOR')}
            value={client?.Name}
            onChangeText={_inputChangeHandler}
          />
          <LoseOfferModalClientsList
            filtered={filtered}
            clientHandler={_setClientHandler}
          />
          <View style={{height: 10}}/>
          <TextInput
            style={{
              width: 400,
              padding: 5,
              borderRadius: 5,
              borderColor: theme.border,
              borderWidth: 1,
            }}
            placeholder={t('NOTES')}
            value={notes}
            onChangeText={setNotes}
          />
          <View style={{paddingTop: 10, flexDirection: 'row'}}>
            <Button
              style={{
                marginRight: 10,
                backgroundColor: theme.blue,
              }}
              onPress={_closeRequestHandler}
              titleT='CANCEL'
            />
            <Button
              style={{
                backgroundColor: theme.mainColor,
              }}
              onPress={_loseHandler}
              titleT='LOSE'
            />
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(128, 128, 128, 0.7)",
  },
  modal: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 50,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  button: {
    marginTop: 5,
    marginHorizontal: 5,
  },
});

export default LoseOfferModal;