import React from "react";
import { CatalogGroup, Doc, Level } from "../../../models";
import { FlatList, Text, View } from "react-native";
import { t } from "i18n-js";
import DocTableGroup from "./DocTableGroup";

interface Props {
  doc: Doc;
  setDoc: (doc: Doc) => void;
}


const DocTable = ({doc, setDoc}:Props) => {

  const _setGroupIndex = (group: CatalogGroup, index: number) => {
    setDoc({
      ...doc,
      Catalog: {
        ...doc.Catalog,
        Groups: doc.Catalog.Groups.map((g, gi) => {
          if (gi === index) {
            return {
              ...g,
              ...group,
            };
          };
          return g;
        })
      }
    })
  }

  const _render = ({item, index}: {item: CatalogGroup, index: number}) => {
    const _setGroup = (group: CatalogGroup) => {
      _setGroupIndex(group, index);
    }
    return (
      <DocTableGroup
        group={item}
        levels={doc.Levels}
        setGroup={_setGroup}
      /> 
    );
  }

  const _renderLevel = ({item, index}: {item: Level, index: number}) => {
    return <Text key={index} style={{flex: 1, textAlign: 'center'}}>{item.Name}</Text>
  }

  return (
    <View style={{width: "97%", display: "flex"}}>
      <View style={{flexDirection: "row"}}>
        <View style={{width: 400}} />
        <FlatList
          columnWrapperStyle={doc.Levels.length > 1 ? {
            flexWrap: "wrap"
          }:undefined}
          style={{
            flex: doc.Levels.length,
          }}
          numColumns={doc.Levels.length}
          key={`l-${doc.Levels.length}`}
          // @ts-ignore
          listKey={`l-${doc.Levels.length}`}
          data={doc.Levels}
          keyExtractor={(item, index) => `ll-${index}-${item.ID}`}
          renderItem={_renderLevel}
        />
        <Text style={{flex: 1/doc.Levels.length, textAlign: 'center'}}>{t("TOTAL")}</Text>
      </View>
      <FlatList
        data={doc.Catalog.Groups}
        renderItem={_render}
        extraData={doc.Levels}
        key={`l-${doc.Catalog.Groups.length}`}
        // @ts-ignore
        listKey={`l-${doc.Catalog.Groups.length}`}
        keyExtractor={(item, index) => `lcatalog-group-${index}-${item.ID}`}
      />
    </View>
  );
};

export default DocTable;