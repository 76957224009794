import React, { useEffect, useState } from "react";
import { Catalog, DocType } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  catalog: Catalog;
  setCatalog: (catalog: Catalog) => void;
  disabled?: boolean;
  docType?: DocType;
}

const CatalogDropdown = ({catalog, setCatalog, disabled = false, docType}: Props) => {

  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  const [filtered, setFiltered] = useState<Catalog[]>(catalogs);

  useEffect(() => {
    setFiltered(catalogs.filter(c => {
      return docType == undefined || c.Type === docType.CatalogType
    }));
  }, [docType]);

  const _change = (selectedCatalog: Catalog) => {
    if (catalog?.ID == selectedCatalog.ID || disabled) {
      return;
    }

    setCatalog(selectedCatalog)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={catalog}
      onValueChange={_change}
      placeholder='SELECT_CATALOG'
      options={filtered}
      showLabelAsOption={true}
    />
  );
};

export default CatalogDropdown;