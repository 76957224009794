import React, { useMemo } from 'react'
import { DocType } from '../../../models'
import { useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import FlatChoose from '../../../components/FlatChoose'
import { DocStatus } from '../../../constants/Status'

interface NativeDocTypeChooseProps {
  value?: DocType
  onChange: (value: DocType) => void
  contractStatus?: number
}

const NativeDocTypeChoose = ({
  value,
  onChange,
  contractStatus,
}: NativeDocTypeChooseProps) => {
  
  const types = useSelector((state: Reducer) => state.doc.types)

  const filteredTypes = useMemo(() => types.filter(t => {
    if (contractStatus == undefined) {
      return t.Config.TypeDropdown && t.Config.TypeFilter != 'integrative-offer'
    }
    switch (contractStatus) {
      case DocStatus.OFFER.code:
        return t.Config.TypeFilter == 'offer' || t.Config.TypeFilter == 'integrative-offer'
      case DocStatus.IN_EXECUTION.code:
      case DocStatus.OPEN.code:
      case DocStatus.TO_BE_APPROVED.code:
      case DocStatus.WIN.code:
      case DocStatus.TO_BE_BILLED.code:
        return t.Config.TypeFilter == 'integrative-offer'
    }
    return false
  }), [types])

  return (
    <FlatChoose
      options={filteredTypes}
      value={value}
      onChange={onChange}
      itemToKey={(item) => String(item.ID)}
      itemToString={(item) => item.Name}
      size='large'
    />
  )
}

export default NativeDocTypeChoose