import React from "react";
import { Contact } from "../../models";
import getTheme from "../../constants/Colors";
import { Pressable, Text, View } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

interface Props {
  contact: Contact;
  select: (contact:Contact) => void;
  selected?: Contact | null;
}

const ContactItem = ({contact, select, selected}:Props) => {

  const theme = getTheme();

  const background = contact.ID === selected?.ID? theme.background : "white";

  const _press = () => {
    select(contact);
  }

  return (
    <Pressable
      style={{
        flexDirection: 'column',
        borderBottomColor: theme.border,
        borderBottomWidth: 1,
        paddingBottom: 2,
        paddingTop: 3,
        backgroundColor: background,
      }}
      onPress={_press}
    >
      <Text style={{fontSize: 18}}>{contact.Firstname} {contact.Lastname}</Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 3,
        }}
      >
        <MaterialIcons name="person" />
        <Text style={{flex: 1}}>{contact.Mobile}</Text>
        <MaterialIcons name="mail" />
        <Text style={{flex: 1}}>{contact.Email}</Text>
        <MaterialIcons name="phone" />
        <Text style={{flex: 1}}>{contact.Phone}</Text>
      </View>
    </Pressable>
  );
};

export default ContactItem;