import React from 'react'
import getTheme from '../../constants/Colors'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { PriceLevel } from '../../store/reducers/settingsReducer'
import { View } from 'react-native'

interface AvancementProgressBarProps {
  workProgress?: number
  invoiceProgress?: number
}

const AvancementProgressBar = ({
  workProgress = 0,
  invoiceProgress = 0,
}: AvancementProgressBarProps) => {

  const theme = getTheme()
  
  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel)

  if (showPriceLevel == PriceLevel.NO_PRICE) {
    return (
      <View style={{ flexDirection: 'row'}}>
        <View style={{ flex: workProgress, backgroundColor: theme.blue, paddingTop: 2 }} />
        <View style={{ flex: 1 - workProgress }} />
      </View>
    )
  }

  if (workProgress == invoiceProgress) {
    return (
      <View style={{ flexDirection: 'row'}}>
        <View style={{ flex: workProgress }}>
          <View style={{ paddingTop: 3, backgroundColor: theme.blue }} />
          <View style={{ paddingTop: 3, backgroundColor: theme.mainColor }} />
        </View>
        <View style={{ flex: 1 - workProgress }} />
      </View>
    )
  }

  if (workProgress > invoiceProgress) {
    return (
      <View style={{ flexDirection: 'row'}}>
        <View style={{ flex: invoiceProgress, backgroundColor: theme.mainColor, paddingTop: 2 }} />
        <View style={{ flex: workProgress - invoiceProgress, backgroundColor: theme.blue, paddingTop: 2 }} />
        <View style={{ flex: 1 - workProgress }} />
      </View>
    )
  }

  return (
    <View style={{ flexDirection: 'row'}}>
      <View style={{ flex: workProgress, backgroundColor: theme.blue, paddingTop: 2 }} />
      <View style={{ flex: invoiceProgress - workProgress, backgroundColor: theme.mainColor, paddingTop: 2 }} />
      <View style={{ flex: 1 - invoiceProgress }} />
    </View>
  )
}

export default AvancementProgressBar