import { useEffect, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
const { createElement } = require("react-native-web");

interface Props {
  value: Date;
  onChange: (date: Date) => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const dateToStringDate = (date: Date) => {
  let d = date.getFullYear() + '-'
  if (date.getMonth() < 9) {
    d += '0'
  }
  d += (date.getMonth() + 1) + '-'
  if (date.getDate() < 10) {
    d += '0'
  }
  return d + date.getDate()
}

const WebDatePicker = ({ value, onChange, style, disabled }: Props) => {

  const [_value, _setValue] = useState(dateToStringDate(value))

  const _change = (event: any) => {
    if (event.target.value.length == 0) {
      return
    }
    _setValue(event.target.value)
  };

  useEffect(() => {
    const d = new Date(_value)
    d.setHours(0, 0, 0, 0)
    const timeoutId = setTimeout(() => onChange(d), 1000);
    return () => clearTimeout(timeoutId);
  }, [_value]);

  useEffect(() => {
    if (dateToStringDate(value) != _value) {
      _setValue(dateToStringDate(value))
    }
  }, [value])

  style = {
    ...(style as any),
    backgroundColor: "white",
    width: "99%",
    boxSizing: "border-box",
    border: "none",
    outline: "none",
    height: 25,
  };
  return createElement("input", {
    disabled: disabled || false,
    type: "date",
    value: _value,
    onChange: _change,
    style,
  });
};

export default WebDatePicker;
