import React, { useEffect, useState } from "react";
import { DimensionValue, Pressable, Text, View } from "react-native";
import getTheme from "../../constants/Colors";
import { t } from "i18n-js";

interface Props {
  left: boolean;
  setLeft: (left: boolean) => void;
  width: DimensionValue;
  leftLabel: string;
  rightLabel: string;
  readOnly?: boolean;
}

const DualSelector = ({left, setLeft, width, rightLabel, leftLabel, readOnly = false}: Props) => {

  const theme = getTheme();

  const [l, setL] = useState<boolean>(left);

  const leftBackground = !l ? "white" : theme.border;
  const rightBackground = l ? "white" : theme.border;

  useEffect(() => {
    if (left != l)
      setL(left)
  }, [left])

  const _left = () => {
    if (!readOnly) {
      setL(true);
      setLeft(true);
    }
  };

  const _right = () => {
    if (!readOnly) {
      setL(false);
      setLeft(false);
    }
  };

  return (
    <View 
      style={{
        flexDirection: 'row',
        borderRadius: 15,
        borderColor: theme.border,
        borderWidth: 1,
        width: width,
      }}
    >
      <Pressable
        style={{
          flex: 1,
          alignItems: 'center',
          padding: 6,
          backgroundColor: leftBackground,
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
        }}
        onPress={_left}
      >
        <Text>{t(leftLabel)}</Text>
      </Pressable>

      <Pressable
        style={{
          flex: 1,
          alignItems: 'center',
          padding: 6,
          backgroundColor: rightBackground,
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
        }}
        onPress={_right}
      >
        <Text>{t(rightLabel)}</Text>
      </Pressable>
    </View>
  );
};

export default DualSelector;