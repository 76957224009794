import React, { useState } from "react";
import { Client, Contact } from "../../models";
import { t } from "i18n-js";
import { FlatList, Pressable, Text, View } from "react-native";
import { newContact } from "./utils";
import getTheme from "../../constants/Colors";
import ClientContact from "./ClientContact";

interface Props {
  client: Client;
  setClient: (client: Client) => void;
}

const ClientContacts = ({client, setClient}: Props) => {

  const theme = getTheme();

  const [selected, setSelected] = useState<number>(0);

  const _contactItem = ({item, index}: {item: Contact, index: number}) => {

    const _press = () => {
      setSelected(index);
    };

    const background = selected === index ? 'white' : theme.background;

    return (
      <Pressable
        style={{
          backgroundColor: background,
          padding: 5,
          borderWidth: 1,
          borderColor: theme.border,
          borderRadius: 5,
          marginTop: 1,
          alignItems: 'center',
        }}
        onPress={_press}
      >
        <Text>{item.Firstname} {item.Lastname}</Text>
      </Pressable>
    );
  }

  const _new = () => {
    const _press = () => {
      let smallerId = 0;
      for (let i = 0; i < client.Contacts.length; i++) {
        if (client.Contacts[i].ID < smallerId) {
          smallerId = client.Contacts[i].ID;
        }
      }
      smallerId--;

      setClient({
        ...client,
        Contacts: [
          ...client.Contacts,
          newContact(smallerId, String(client.ID)),
        ],
      });
      setSelected(client.Contacts.length);
    };

    return (
      <Pressable
        onPress={_press}
        style={{
          width: "100%",
          paddingVertical: 2,
          borderWidth: 1,
          borderColor: theme.border,
          borderRadius: 5,
          marginTop: 1,
          alignItems: 'center',
        }}
      >
        <Text>+ {t('CREATE_NEW_CONTACT')}</Text>
      </Pressable>
    );
  }

  const setContact = (contact: Contact) => {
    setClient({
      ...client,
      Contacts: client.Contacts.map((item, index) => {
        if (index !== selected) {
          return item;
        }
        return contact;
      }),
    });
  };

  return (
    <View style={{flexDirection: 'column', paddingTop: 5}}>
      <Text style={{fontSize: 20}}>{t('DOC_CONTACTS')}</Text>

      <View style={{flexDirection: 'row', paddingTop: 3}}>
        <View style={{width: "15%"}}>
          <FlatList
            data={client.Contacts}
            renderItem={_contactItem}
            ListFooterComponent={_new}
            key={'client-contacts'}
            keyExtractor={(item, index) => `client-contact-${index}-${item.ClientID}-${client.ID}`}
          />
        </View>

        { client.Contacts[selected] && (
          <ClientContact
            contact={client.Contacts[selected]}
            setContact={setContact}
          />
        )}  
      </View>
    </View>
  );
};

export default ClientContacts;