import React, { useEffect, useState } from "react";
import { EmitterSubscription, Keyboard } from "react-native";


interface Props {
  children: React.ReactNode;
  active?: boolean;
}

const KeyboardHide = ({children, active = true}: Props) => {
  const [willShowListener, setWillShowListener] = useState<EmitterSubscription | null>(null);
  const [willHideListener, setWillHideListener] = useState<EmitterSubscription | null>(null);

  const [show, setShow] = useState<boolean>(true);

  const _keyboardWillShow = () => {
    setShow(false);
  }

  const _keyboardWillHide = () => {
    setShow(true);
  }

  useEffect(() => {
    setWillShowListener(Keyboard.addListener("keyboardWillShow", _keyboardWillShow));
    setWillHideListener(Keyboard.addListener("keyboardWillHide", _keyboardWillHide));

    return () => {
      willShowListener?.remove();
      willHideListener?.remove();
    }
  }, []);

  if (!show && active) {
    return <></>;
  }

  return <>{children}</>;
}

export default KeyboardHide;