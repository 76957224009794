import React from 'react'
import { DayReport, ExternalWorkInvoice } from '../../models'
import { Text, View } from 'react-native'
import { t } from 'i18n-js'
import getTheme from '../../constants/Colors'
import Formatter from '../../utils/formatters'
import DatePicker from '../DatePicker'

interface ExternalWorkInvoiceDataProps {
  externalWorkInvoice: ExternalWorkInvoice
  dayReport?: DayReport
  setDayReport?: React.Dispatch<React.SetStateAction<DayReport | undefined>>
  createDayReport?: boolean
}

const Value = ({value}: {value: string}) => {
  return (
    <Text style={{
      flex: 1,
      paddingRight: 10,
      textAlign: 'right',
    }}>
      {value}
    </Text>
  )
}

const Title = ({
  title,
  width = 100,
}: {title: string, width?: number}) => {
  return (
    <Text style={{width, fontWeight: 'bold'}}>
      {t(title)}:
    </Text>
  )
}

const ExternalWorkInvoiceData = ({
  externalWorkInvoice,
  dayReport,
  setDayReport,
  createDayReport,
}: ExternalWorkInvoiceDataProps) => {

  const theme = getTheme()

  const f = Formatter.currency(externalWorkInvoice.CurrencyID).format

  const _date = (date: Date | undefined) => {
    if (date && setDayReport) {
      setDayReport(d => ({
        ...d!,
        DateTime: date!,
      }))
    }
  }

  return (
    <View>
      <View style={{paddingTop: 15, flexDirection: 'row'}}>
        <View style={{flex: 6.5}}>
          <View style={{
            paddingTop: 15,
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Title title='INVOICE_NUMBER' width={150} />
            <Value value={externalWorkInvoice.IDString} />
            <Title title='INVOICE_DATE' />
            <Value value={externalWorkInvoice.Date.toLocaleDateString()} />
            <View style={{width: 100}} />
            <Value value='' />
          </View>

          <View style={{
            paddingTop: 15,
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Title title='SUPPLIER' width={150} />
            <Value
              value={`${externalWorkInvoice.Client.Name} (${externalWorkInvoice.ClientID})`}
            />
            <Title title='REFERENCE' />
            <Value value={externalWorkInvoice.ClientReference} />
            <Title title='INSERTED_BY' />
            <Value
              value={`${externalWorkInvoice.CreatedBy.FirstName} ${externalWorkInvoice.CreatedBy.LastName}`}
            />
          </View>

          <View style={{
            paddingTop: 15,
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Title title='TOTAL_VAT_EXCL' width={150} />
            <Value value={f(externalWorkInvoice.Total - externalWorkInvoice.VatTotal)} />
            <Title title='VAT' />
            <Value value={f(externalWorkInvoice.VatTotal)} />
            <Title title='TOTAL' />
            <Value value={f(externalWorkInvoice.Total)} />
          </View>

          <Text style={{paddingTop: 15, fontWeight: 'bold'}}>
            {t('NOTES')}:
          </Text>
        </View>
        {dayReport ? (
          <View style={{flex: 2}}>
            <View style={{
              paddingTop: 15,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <Value value='&nbsp;' />
            </View>
            <View style={{
              paddingTop: 15,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <Title title='DAY_REPORT' />
              <Value value={
                createDayReport
                    ? t('NEW')
                    : `${externalWorkInvoice.DayReportId}`
              }/>
            </View>
            <View style={{
              paddingTop: 15,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <Title title='DAY_REPORT_DATE' />
              {createDayReport ? (
                <View style={{alignItems: 'flex-end', flex: 1}}>
                  <DatePicker
                    onChange={_date}
                    value={dayReport.DateTime}
                  />
                </View>
              ) : (
                <Value value={dayReport.DateTime.toLocaleDateString()} />
              )}
            </View>
          </View>
        ) : (
          <View style={{flex: 2}} />
        )}
      </View>

      <Text style={{
        marginTop: 15,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: theme.border,
        padding: 10,
      }}>
        {
          (externalWorkInvoice.Notes?.length || 0) > 0
            ? externalWorkInvoice.Notes
            : '-'
        }
      </Text>
    </View>
  )
}

export default ExternalWorkInvoiceData