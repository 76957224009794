import React, { useState } from "react";
import { FlatList, Pressable, Text, View } from "react-native";
import getTheme from "../../constants/Colors";
import { t } from "i18n-js";

interface Props {
  views: React.ReactNode[] | ((index: number) => React.ReactNode),
  titlesT: string[];
  defaultViewIndex?: number;
}

const SegmentedView = ({views, titlesT, defaultViewIndex = 0}: Props) => {

  const theme = getTheme();

  const [selected, setSelected] = useState<number>(defaultViewIndex);

  const _render = ({item, index}: {item: string, index: number}) => {
    
    const _press = () => setSelected(index);

    return (
      <Pressable
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          paddingVertical: 5,
          backgroundColor: index == selected ? theme.mainColor : undefined,
        }}
        onPress={_press}
      >
        <Text
          style={{
            textAlign: 'center',
            color: index == selected ? 'white' : undefined,
          }}
        >
          {t(item)}
        </Text>
      </Pressable>
    )
  }

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          borderColor: theme.mainColor,
          borderWidth: 1,
          borderRadius: 3,
          flexDirection: 'row',
        }}
      >
        <FlatList
          data={titlesT}
          renderItem={_render}
          numColumns={titlesT.length}
          key={`segmented-view`}
          // @ts-ignore
          listKey={`segmented-view-${titlesT.length}`}
          keyExtractor={(_, index) => `segmented-view-${index}`}
        />
      </View>
      <View style={{flex: 1, paddingTop: 10}}>
        {
          selected == -1 || selected >= titlesT.length ? (
            <View style={{flex: 1, justifyContent: 'center', alignContent: 'center'}}>
              <Text>{t("NO_SELECTED_VIEW")}</Text>
            </View>
          ) : (
            Array.isArray(views) ? views[selected] : views(selected)
          )
        }
      </View>
    </View>
  )
}

export default SegmentedView;