import React, { useState } from "react";
import { Text, View } from "react-native";
import { t } from "i18n-js";
import { Client, ConstructionSite, Contract, Currency, Doc, Language, Seller } from "../../../../models";
import DocThreeDotsLanguages from "./DocThreeDotsLanguages";
import DocThreeDotsCurrency from "./DocThreeDotsCurrency";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import { DocMode } from "../../utils";
import { toConcordToOffer } from "../../../../utils/doc/util";
import DocThreeDotsSeller from "./DocThreeDotsSeller";
import Modal from "react-native-modal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Button from "../../../../components/Button";
import getTheme from "../../../../constants/Colors";
import SegmentedView from "../../../../components/SegmentedView";
import SendPdf from "../../../../components/SendPdf";
import PdfComponent from "../../../../components/PDFComponent";
import WinModal from "../../../../components/WinModal";
import LoseOfferModal from "../../LoseOfferModal";
import DocumentVersionsModal from "../../../../components/DocumentVersionsModal";
import DuplicateModal from "../../../../components/DuplicateModal";
import ChooseContractModal from "../../../../components/ChooseContractModal";

interface Props {
  mode: number;
  doc: Doc;
  contract: Contract
  constructionSite: ConstructionSite
  setDoc: (doc: Doc) => void;
  setContract: (contract: Contract) => void
  setConstructionSite: (constructionSite: ConstructionSite) => void
  setChanged: (changed: boolean) => void;
  setMode: (mode: number) => void;
  disableTwinIDAutomation: () => void;
  loseHandler: (client: Client | null, notes: string | null) => void
  twinIDAutomationActive: boolean;
}

const DocThreeDots = ({
                        mode,
                        doc,
                        contract,
                        constructionSite,
                        setDoc,
                        setContract,
                        setConstructionSite,
                        setChanged,
                        setMode,
                        disableTwinIDAutomation,
                        loseHandler,
                        twinIDAutomationActive,
                      }: Props) => {

  const theme = getTheme();

  const [open, setOpen] = useState<boolean>(false);
  const _open = () => setOpen(true);
  const _close = () => setOpen(false);

  const docTypeGroup = doc.Type?.Config?.ConstructionSiteViewGroup;

  const user = useSelector((state:Reducer) => state.user);
  const url = useSelector((state: Reducer) => state.auth.workSpace?.url);
  const token = useSelector((state: Reducer) => state.auth.token);

  const [winOfferModalVisible, setWinOfferModalVisible] = useState<boolean>(false)
  const _showWinOfferModal = () => setWinOfferModalVisible(true)
  const _closeWinModal = () => setWinOfferModalVisible(false)

  const [loseOfferModalVisible, setLoseOfferModalVisible] = useState<boolean>(false)
  const _showLoseOfferModal = () => setLoseOfferModalVisible(true)

  const [versionsModalVisible, setVersionsModalVisible] = useState<boolean>(false)
  const _showVersionsModal = () => setVersionsModalVisible(true)
  const _closeVersionsModal = () => setVersionsModalVisible(false)

  const [duplicateModalVisible, setDuplicateModalVisible] = useState<boolean>(false)
  const _showDuplicateModal = () => setDuplicateModalVisible(true)
  const _closeDuplicateModal = () => setDuplicateModalVisible(false)

  const [moveDocumentModalVisible, setMoveDocumentModalVisible] = useState<boolean>(false)
  const _showMoveDocumentModal = () => setMoveDocumentModalVisible(true)
  const _closeMoveDocumentModal = () => setMoveDocumentModalVisible(false)

  const dropdownTextStyle = {
    paddingHorizontal: 5,
  };

  const _language = (language: Language) => {
    setDoc({
      ...doc,
      Language: language,
      LanguageID: language.ID,
    });
  }

  const _currency = (currency: Currency) => {
    setDoc({
      ...doc,
      Currency: currency,
      CurrencyID: currency.ID,
    });
  }

  const _saleEmployee = (saleEmployee: Seller) => {
    setDoc({
      ...doc,
      SaleEmployee: saleEmployee,
      SaleEmployeeID: saleEmployee.ID,
    })
  }

  const _toConcordToOffer = () => {
    const d = toConcordToOffer(-1, doc, user)

    setDoc(d)
    setChanged(true)
    setMode(DocMode.CREATE)
  }

  const pdfUrls = ['getHoursListPDF', 'getMaterialsListPDF', 'getDocumentBillsOfMaterialsPDF'];

  const buildUrl = (path: string) => {
    return `${url}/${path}?documentType=${doc.TypeID}&documentEntry=${doc.ID}&token=${encodeURIComponent(token)}`;
  }

  const views = (index: number) => {
    if (index == 0) {
      return (
        <SendPdf
          doc={doc}
          contract={contract}
          constructionSite={constructionSite}
          close={_close}
        />
      )
    }
    
    return (
      <PdfComponent url={buildUrl(pdfUrls[index - 1])} />
    )
  }

  if (winOfferModalVisible) {
    return (
      <WinModal
        onClose={_closeWinModal}
        contract={contract}
        constructionSite={constructionSite}
        doc={doc}
      />
    )
  }

  if (loseOfferModalVisible) {
    return (
      <LoseOfferModal
        loseOfferModalVisible={true}
        setLoseOfferModalVisible={setLoseOfferModalVisible}
        loseHandler={loseHandler}
      />
    )
  }

  if (versionsModalVisible) {
    return (
      <DocumentVersionsModal
        constructionSite={constructionSite}
        contract={contract}
        doc={doc}
        onClose={_closeVersionsModal}
      />
    )
  }

  const _setDuplicate = (
    constructionSite: ConstructionSite,
    contract: Contract,
    doc: Doc,
  ) => {
    setDoc(doc)
    setContract(contract)
    setConstructionSite(constructionSite)
    setChanged(true)
    setMode(DocMode.CREATE)
    _close()
  }

  if (duplicateModalVisible) {
    return (
      <DuplicateModal
        onClose={_closeDuplicateModal}
        initConstructionSite={constructionSite}
        initContract={contract}
        initDoc={doc}
        set={_setDuplicate}
      />
    )
  }

  const _setMove = (
    constructionSite: ConstructionSite,
    contract: Contract,
  ) => {
    setConstructionSite(constructionSite)
    setContract(contract)
    setChanged(true)
    _close()
    _closeMoveDocumentModal()
  }

  if (moveDocumentModalVisible) {
    return (
      <ChooseContractModal
        onClose={_closeMoveDocumentModal}
        onChoose={_setMove}
        defaultConstructionSite={constructionSite}
        defaultContract={contract}
        includeOpenContracts={true}
      />
    )
  }

  return (
    <>
      <MaterialCommunityIcons
        name="dots-vertical"
        size={24}
        onPress={_open}
      />
      <Modal
        isVisible={open}
        onBackdropPress={_close}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
          flex: mode == DocMode.UPDATE ? 1 : undefined,
        }}
      >
        <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
          <MaterialCommunityIcons
            name="close"
            size={34}
            onPress={_close}
          />
        </View>
        
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 15,
            justifyContent: 'space-between',
          }}
        >
          <Text style={dropdownTextStyle}>{t("SELECT_LANGUAGE")}:</Text>
          <View>
            <DocThreeDotsLanguages language={doc.Language} setLanguage={_language} />
          </View>
          <Text style={dropdownTextStyle}>{t("SELECT_CURRENCY")}:</Text>
          <View>
            <DocThreeDotsCurrency currency={doc.Currency} setCurrency={_currency}/>
          </View>
          <Text style={dropdownTextStyle}>{t("SELECT_SALE_EMPLOYEE")}:</Text>
          <View>
            <DocThreeDotsSeller seller={doc.SaleEmployee} setSeller={_saleEmployee}/>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 15,
            justifyContent: 'space-between',
          }}
        >
          {(mode == 1 && docTypeGroup == 'offer') && (
            <>
              <Button onPress={_showWinOfferModal} titleT="WIN" style={{backgroundColor: theme.blue}}/>
              <Button onPress={_showLoseOfferModal} titleT="LOSE" style={{backgroundColor: theme.blue}}/>
              <Button onPress={_showDuplicateModal} titleT="DUPLICATE" style={{backgroundColor: theme.blue}}/>
              <Button onPress={_showMoveDocumentModal} titleT="MOVE" style={{backgroundColor: theme.blue}}/>
              <Button onPress={_toConcordToOffer} titleT="TO_CONCORD_TO_OFFER" style={{backgroundColor: theme.blue}}/>
            </>
          )}
          <Button onPress={_showVersionsModal} titleT="VERSIONS" style={{backgroundColor: theme.blue}}/>
          <Button
            onPress={disableTwinIDAutomation}
            titleT={twinIDAutomationActive ? "DISABLE_TWIN_ID_AUTOMATION" : "TWIN_ID_AUTOMATION_DISABLED" }
            style={{backgroundColor: theme.blue}}
            disabled={!twinIDAutomationActive}
          />
        </View>

        
        {mode === DocMode.UPDATE && (
          <SegmentedView
            titlesT={["SEND_PDF", "HOURS_LIST", "MATERIALS_LIST", "BILLS_OF_MATERIALS"]}
            views={views}
          />
        )}
      </Modal>
    </>
  );
};

export default DocThreeDots;
