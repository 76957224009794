import React from 'react'
import { Level, Report, ReportLevel } from '../../models'
import { FlatList, Text, View } from 'react-native'
import HtmlText from '../HtmlText'
import NumberInput from '../../screens/document/PostitionComponent/NumberInput'
import getTheme from '../../constants/Colors'

interface ExternalWorkInvoiceReportCreateProps {
  report: Report
  index: number
  setReport: (report: Report) => void
  levels: Level[]
}

const ExternalWorkInvoiceReportCreate = ({
  report,
  index,
  setReport,
  levels,
}: ExternalWorkInvoiceReportCreateProps) => {

  const theme = getTheme()

  const background = index % 2 == 0 ? theme.background : 'white'

  const _render = ({item, index}: {item: ReportLevel, index: number}) => {
    const level = levels.find(l => l.ID == item.LevelID)
    const _quantity = (quantity: number) => {

      const newReportLevels = report.Levels?.map((r, i) => i == index ? {
        ...r,
        Quantity: quantity,
      } : r) || []

      setReport({
        ...report,
        Levels: newReportLevels,
      })
    }
    return (
      <View
        key={`report-level-${index}-${item.ID}`}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {(report.Levels?.length || 0) > 1 && (
          <>
            <View style={{flex: 1}}/>
            <Text style={{fontWeight: 'bold', width: 100}}>
              {level?.Name}
            </Text>
          </>
        )}

        <Text style={{width: 70, textAlign: 'right', paddingRight: 5}}>
          {item.DoneQuantity || '-'}
        </Text>

        <Text style={{width: 70, textAlign: 'right', paddingRight: 5}}>
          {item.SoldQuantity || '-'}
        </Text>

        <NumberInput
          defaultValue={item.Quantity}
          onChange={_quantity}
          style={{width: 120}}
        />
      </View>
    )
  }

  return (
    <View style={{backgroundColor: background, padding: 3, borderRadius: 5}}>
      <View style={{flexDirection: 'row'}}>
        <Text style={{width: 100, paddingTop: 7}}>
          {report.ActivityIDString}
        </Text>
        <View style={{flex: 1, paddingTop: 7}}>
          <HtmlText
            html={report.Name}
            width='100%'
          />
        </View>
        
        <Text style={{width: 100, textAlign: 'center', paddingTop: 7}}>
          {report.UnitOfMeasure}
        </Text>

        {report.Levels?.length == 1 && (
          _render({item: report.Levels[0], index: 0})
        )}
      </View>
      {(report.Levels?.length || 0) > 1 && (
        <FlatList
          data={report.Levels}
          renderItem={_render}
        />
      )}
    </View>
  )
}

export default ExternalWorkInvoiceReportCreate
