import {
  CHECK_NETWORK,
  LOGOUT,
  SERVER_REACHABLE,
  GET_ALL_CLIENTS_LOADING,
  CONSTRUCTION_SITES_LOADING,
  CATALOGS_LOADING,
  GET_BASIC_DATA_LOADING,
  SET_USER_LOADING,
} from '../types'

export interface SyncReducer {
  synchronised: boolean;
  serverReachable: boolean;
  checkingNetwork: boolean
  networkCheckCounter: number
  clientsLoading: boolean
  constructionSitesLoading: boolean
  catalogsLoading: boolean
  basicsLoading: boolean
  userLoading: boolean
}

const initialState: SyncReducer = {
  synchronised: true,
  serverReachable: false,
  checkingNetwork: false,
  networkCheckCounter: 0,
  clientsLoading: false,
  constructionSitesLoading: false,
  catalogsLoading: false,
  basicsLoading: false,
  userLoading: false,
};

export default function (
  state: SyncReducer = initialState,
  action: {type: string; payload: any},
): SyncReducer {
  switch(action.type) {
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case SERVER_REACHABLE:
      return {
        ...state,
        serverReachable: action.payload,
        checkingNetwork: false,
      }
    case GET_BASIC_DATA_LOADING:
      return {
        ...state,
        basicsLoading: action.payload,
      }
    case CATALOGS_LOADING:
      return {
        ...state,
        catalogsLoading: action.payload,
      }
    case CHECK_NETWORK:
      return {
        ...state,
        networkCheckCounter: state.networkCheckCounter + 1,
        checkingNetwork: true,
      }
    case GET_ALL_CLIENTS_LOADING:
      return {
        ...state,
        clientsLoading: action.payload,
      }
    case CONSTRUCTION_SITES_LOADING:
      return {
        ...state,
        constructionSitesLoading: action.payload,
      }
    case SET_USER_LOADING:
      return {
        ...state,
        userLoading: action.payload,
      }
    default:
      return state;
  }
}