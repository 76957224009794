import React from 'react'
import { Pressable, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import { PriceLevel } from '../../store/reducers/settingsReducer'
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons'
import getTheme from '../../constants/Colors'
import { setShowPriceLevel } from '../../store/action/settingsAction'

const PriceLevelSelector = () => {

  const dispatch = useDispatch()
  const theme = getTheme()

  const priceLevel = useSelector((state: Reducer) => state.user.permissions.priceLevel)
  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel)

  const _internal = () => {
    if (showPriceLevel == PriceLevel.INTERNAL) {
      dispatch(setShowPriceLevel(PriceLevel.NO_PRICE))
      return
    }
    dispatch(setShowPriceLevel(PriceLevel.INTERNAL))
  }

  const _client = () => {
    if (showPriceLevel == PriceLevel.CLIENT) {
      dispatch(setShowPriceLevel(PriceLevel.NO_PRICE))
      return
    }
    dispatch(setShowPriceLevel(PriceLevel.CLIENT))
  }

  return (
    <>
      {(priceLevel >= PriceLevel.CLIENT) && (
        <Pressable
            style={{
            paddingVertical: 10,
            backgroundColor: showPriceLevel == PriceLevel.CLIENT ? theme.background : theme.secondaryBackground,
          }}
          onPress={_client}
        >
          <FontAwesome
            name='dollar'
            size={35}
            style={{
              alignSelf: 'center',
            }}
            color={showPriceLevel == PriceLevel.CLIENT ? theme.secondaryBackground : 'white'}
          />
        </Pressable>
      )}
      {(priceLevel >= PriceLevel.INTERNAL) && (
        <Pressable
          style={{
            paddingVertical: 15,
            backgroundColor: showPriceLevel == PriceLevel.INTERNAL ? theme.background : theme.secondaryBackground,
          }}
          onPress={_internal}
        >
          <FontAwesome5
            name='percent'
            size={30}
            style={{
              alignSelf: 'center',
            }}
            color={showPriceLevel == PriceLevel.INTERNAL ? theme.secondaryBackground : 'white'}
          />
        </Pressable>
      )}
    </>
  )
}

export default PriceLevelSelector