import React from "react";
import { Contract } from "../../../models";
import { DocStatus } from "../../../constants/Status";
import CreateDocButtonOffer from "./CreateDocButtonOffer";
import CreateDocButtonOpen from "./CreateDocButtonOpen";
import CreateDocButtonDone from "./CreateDocButtonDone";

interface Props {
  contract: Contract;
}

const CreateDocButton = ({contract}: Props) => {
  switch (contract.Status) {
    case DocStatus.OFFER.code:
      return <CreateDocButtonOffer contract={contract} />;
    case DocStatus.OPEN.code:
    case DocStatus.IN_EXECUTION.code:
    case DocStatus.TO_BE_APPROVED.code:
    case DocStatus.TO_BE_BILLED.code:
    case DocStatus.TO_BE_PAID.code:
      return <CreateDocButtonOpen contract={contract} />;
    case DocStatus.DONE.code:
      return <CreateDocButtonDone contract={contract} />;
    default:
      return <></>;
  }
}

export default CreateDocButton;