import React, { useEffect } from "react";
import WebTimePicker from "../WebTimePicker";
import DateTimePicker from "@react-native-community/datetimepicker";
import { Platform, StyleProp, ViewStyle } from "react-native";
import { getStringFromTime, getTimeFromString } from "../../utils/StringUtils";

type MinuteInterval = 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30

interface Props {
  value: string;
  onChange: (time: string) => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  minuteInterval?: MinuteInterval
  minimumTime?: string
}

const hoursMinutesToDate = (value: string):Date => {
  let [hours, minutes] = value ? value.toString().split(":") : ["0", "0"];
  const date = new Date();
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

const TimePicker = (props: Props) => {
  const [date, setDate] = React.useState(hoursMinutesToDate(props.value));

  useEffect(() => {
    setDate(hoursMinutesToDate(props.value));
  }, [props.value]);

  const minimumTime = props.minimumTime ? (getTimeFromString(props.minimumTime || "00:00") || 0) : undefined

  const os = Platform.OS;
  if (os === "web") {
    return (
      <WebTimePicker
        {...props}
        onChange={(time) => {
          const t = getTimeFromString(time) || 0
          if (minimumTime && t < minimumTime) {
            return
          }
          props.onChange(time)
        }}
        style={props.style}
        value={date.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
          hourCycle: "h23",
        })}
      />
    );
  }

  let minuteInterval: MinuteInterval  = 15
  if (props.minuteInterval) {
    minuteInterval = props.minuteInterval
  }

  return (
    <DateTimePicker
      onChange={(event, date) => {
        if (date) {
          let hours = date.getHours();
          let mins = date.getMinutes();

          if (minimumTime && hours * 60 + mins < minimumTime) {
            return
          }

          props.onChange(`${hours}:${mins}`);
        }
      }}
      locale="en_GB"
      value={date}
      style={props.style}
      mode="time"
      is24Hour={true}
      minuteInterval={minuteInterval}
      disabled={props.disabled || false}
    />
  );
};

export default TimePicker;