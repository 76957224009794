import * as ConstructionSiteAPI from './ConstructionSitesAPI';
import {
  Catalog,
  ConstructionSite,
  ConstructionSiteViewGroups,
  Contract,
  Doc,
  DocType,
  SapDoc,
  LineSumType,
  Client,
  OfferToCheck,
} from "../models";
import {docToSapDocConvert, sapDocToDocConvert} from '../shared-utils/ConversionUtils'

import axios, {Method} from "axios";
import { DocStatus } from "../constants/Status";
import { t } from "i18n-js";
import { testCreateConstructionSite } from "./ConstructionSitesAPI";
import { createClient, updateClient } from './ClientsAPI';

export const testCreateDoc = ({constructionSite, doc, contract}:createDocModel) => {
  const {errors, warnings } = testCreateConstructionSite(constructionSite);

  if (contract == undefined) {
    if (constructionSite?.Contracts != undefined && constructionSite?.Contracts.length > 0) {
      errors.push(t("DOC_TEST_MISSING_CONTRACT_CREATE"));
    } else {
      errors.push(t("DOC_TEST_MISSING_CONTRACT_CREATE_OR_UPDATE"));
    }
  } else {
    if (contract.Name == undefined || contract.Name.length == 0) {
      errors.push(t("DOC_TEST_MISS_CONTRACT_NAME"));
    }
  }

  if (constructionSite?.Contracts?.find((c: Contract) => c.Name === contract.Name && c.ID !== contract.ID) != undefined) {
    errors.push(t("DOC_TEST_CONTRACT_NAME_EXISTS"))
  }

  if (doc == undefined) {
    // TODO add log
    errors.push(t("DOC_TEST_MISSING_DOCUMENT"));
  } else {
    if (doc.Type == undefined) {
      errors.push(t("DOC_TEST_MISSING_DOCUMENT_TYPE"));
    }
    if (doc.Language == undefined) {
      errors.push(t("DOC_TEST_MISSING_DOCUMENT_LANGUAGE"));
    }
    if (doc.Currency == undefined) {
      errors.push(t("DOC_TEST_MISSING_DOCUMENT_CURRENCY"));
    }
    if (doc.Catalog == undefined) {
      errors.push(t("DOC_TEST_MISSING_DOCUMENT_CATALOG"));
    }

    if (doc.Client == undefined) {
      errors.push(t("DOC_TEST_MISSING_DOCUMENT_CLIENT"));
    }
    if (doc.Levels == undefined || doc.Levels.length == 0) {
      errors.push(t("DOC_TEST_MISSING_DOCUMENT_LEVELS"));
    }
    if (doc.Levels.filter(l => l.Name.length == 0).length > 0) {
      errors.push(t("DOC_LEVELS_EMPTY_NAME"));
    }

    let lineNotFound:boolean = true;
    if (doc.Lines == undefined) {
      doc.Lines = [];
    }
    for (let i = 0; i < doc.Lines.length; i++) {
      if (doc.Lines[i].LineLevel == undefined) {
        doc.Lines[i].LineLevel = [];
      }
      for (let j = 0; j < doc.Lines[i].LineLevel.length; j++) {
        if (doc.Lines[i].LineLevel[j].Quantity != undefined && doc.Lines[i].LineLevel[j].Quantity > 0) {
          lineNotFound = false;
        } else if (doc.Lines[i].LineLevel[j].LineSumType == LineSumType.PRICE_LIST) {
          lineNotFound = false;
        }
      }
    }
    if (lineNotFound) {
      warnings.push(t("DOC_TEST_MISSING_LINES"));
    }
    if (doc.Client && !doc.Client.DefaultBillAddressID) {
      errors.push(t("CLIENT_HAS_NO_ADDRESS"))
    }
    if (doc.By && !doc.By.DefaultBillAddressID) {
      errors.push(t("BY_HAS_NO_ADDRESS"))
    }
    if (doc.InvoiceTo && !doc.InvoiceTo.DefaultBillAddressID) {
      errors.push(t("INVOICE_TO_HAS_NO_ADDRESS"))
    }
  }

  for (let i = 0; i < doc?.DocumentContacts.length; i++) {
    if (doc.DocumentContacts[i].Type == null || doc.DocumentContacts[i].TypeID == "") {
      errors.push(`${t("DOCUMENT_CONTACT_TYPE_MISSING")} - ${i+1}`)
    }
  }

  return {
    errors,
    warnings
  };
}

interface createDocModel {
  constructionSite: ConstructionSite;
  doc: Doc;
  contract: Contract;
}

export const createDoc = async ({constructionSite, doc, contract}: createDocModel, catalogs: Catalog[]) => {
  let returnedConstructionSite: ConstructionSite;
  let returnedContract: Contract;
  let returnedDoc: Doc;

  if (doc.Name == undefined || doc.Name.length == 0) {
    doc.Name = contract.Name + " " + doc.Client.Name;
  }

  try {
    if (constructionSite.ID > 0) {
      returnedConstructionSite = await ConstructionSiteAPI.updateConstructionSite({...constructionSite, Contracts: []});
    } else {
      returnedConstructionSite = await ConstructionSiteAPI.createConstructionSite({...constructionSite, Contracts: []});
    }
  } catch (e) {
    throw {
      error: e,
      constructionSite,
      contract,
      doc,
    }
  }

  contract.ConstructionSiteID = returnedConstructionSite.ID;

  try {
    if (contract.ID > 0) {
      returnedContract = await ConstructionSiteAPI.updateContract({...contract, Docs: [], FileTypes: []});
    } else {
      returnedContract = await ConstructionSiteAPI.createContract({...contract, Docs: [], FileTypes: []});
    }
  } catch (e) {
    throw {
      error: e,
      constructionSite: returnedConstructionSite,
      contract,
      doc,
    }
  }

  doc.ContractID = returnedContract.ID;
  doc = await createClientsIfNotExists(doc)

  const docType = doc.Type;

  try {
    const sapDoc = docToSapDocConvert(doc)
    if (Number(sapDoc.Client.ID) <= 0) {
      sapDoc.Client = await createClient(sapDoc.Client)
      sapDoc.ClientID = String(sapDoc.Client.ID)
    }
    if (sapDoc.ClientContactID && sapDoc.ClientContactID <= 0) {
      for (let i = 0; i < sapDoc.Client.Contacts.length; i++) {
        if (sapDoc.Client.Contacts[i]?.Firstname == doc.ClientContact?.Firstname && sapDoc.Client.Contacts[i]?.Lastname == doc.ClientContact?.Lastname) {
          sapDoc.ClientContactID = sapDoc.Client.Contacts[i].ID
          sapDoc.ClientContact = sapDoc.Client.Contacts[i]
        }
      }
    }
    if (sapDoc.By && Number(sapDoc.By.ID) <= 0) {
      sapDoc.By = await createClient(sapDoc.By)
      sapDoc.ByID = String(sapDoc.By.ID)
    }
    if (sapDoc.ByContactID && sapDoc.ByContactID <= 0) {
      for (let i = 0; i < (sapDoc.By?.Contacts.length || 0); i++) {
        if (sapDoc.By?.Contacts[i]?.Firstname == doc.ByContact?.Firstname && sapDoc.By?.Contacts[i]?.Lastname == doc.ByContact?.Lastname) {
          sapDoc.ByContactID = sapDoc.Client.Contacts[i].ID
          sapDoc.ByContact = sapDoc.Client.Contacts[i]
        }
      }
    }
    if (sapDoc.InvoiceTo && Number(sapDoc.InvoiceTo.ID) <= 0) {
      sapDoc.InvoiceTo = await createClient(sapDoc.InvoiceTo)
      sapDoc.InvoiceToID = String(sapDoc.InvoiceTo.ID)
    }
    if (sapDoc.InvoiceToContactID && sapDoc.InvoiceToContactID <= 0) {
      for (let i = 0; i < (sapDoc.InvoiceTo?.Contacts.length || 0); i++) {
        if (sapDoc.InvoiceTo?.Contacts[i]?.Firstname == doc.InvoiceToContact?.Firstname && sapDoc.InvoiceTo?.Contacts[i]?.Lastname == doc.InvoiceToContact?.Lastname) {
          sapDoc.InvoiceToContactID = sapDoc.Client.Contacts[i].ID
          sapDoc.InvoiceToContact = sapDoc.Client.Contacts[i]
        }
      }
    }
    if (sapDoc.OfferNamedTo && Number(sapDoc.OfferNamedTo.ID) <= 0) {
      sapDoc.OfferNamedTo = await createClient(sapDoc.OfferNamedTo)
      sapDoc.OfferNamedToID = String(sapDoc.OfferNamedTo.ID)
    }
    if (sapDoc.OfferNamedToContactID && sapDoc.OfferNamedToContactID <= 0) {
      for (let i = 0; i < (sapDoc.OfferNamedTo?.Contacts.length || 0); i++) {
        if (sapDoc.OfferNamedTo?.Contacts[i]?.Firstname == doc.OfferNamedToContact?.Firstname && sapDoc.OfferNamedTo?.Contacts[i]?.Lastname == doc.OfferNamedToContact?.Lastname) {
          sapDoc.OfferNamedToContactID = sapDoc.Client.Contacts[i].ID
          sapDoc.OfferNamedToContact = sapDoc.Client.Contacts[i]
        }
      }
    }
    const response = await axios.request({
      method: docType.Config.Create?.Method as Method,
      url: docType.Config.Create?.URL,
      data: sapDoc,
    });

    if (response.status != 200) {
      throw response;
    }
    

    returnedDoc = sapDocToDocConvert({
      ...response.data,
      Type: {
        ...response.data.Type,
        Config: JSON.parse(response.data.Type.Config),
      },
    }, catalogs);

    return {
      constructionSite: returnedConstructionSite,
      contract: returnedContract,
      doc: returnedDoc,
    };
  } catch (e) {
    throw {
      error: e,
      constructionSite: returnedConstructionSite,
      contract: returnedContract,
      doc,
    }
  }
};

export const isDocSameVersion = async (doc: Doc):Promise<{changed:boolean,doc:Doc}> => {

  const serverDoc = await getDocById(doc.ID, doc.Type);

  if (serverDoc.Version !== doc.Version) {
    return {changed: true, doc: serverDoc};
  }

  if (serverDoc.UpdatedDate !== doc.UpdatedDate) {
    return {changed: true, doc: serverDoc};
  }

  return {changed: false, doc: serverDoc};
}

interface updateDocReturnType {
  constructionSite: ConstructionSite;
  doc: Doc;
  contract: Contract;
  uploaded: boolean;
}

const createClientIfNotExist = async (client: Client): Promise<Client> => {
  const clientId = Number(client.ID)
  if (client.toUpdate) {
    return await updateClient(client)
  } else if (isNaN(clientId) || clientId > 0) {
    return client
  }

  return await createClient(client)
}

const createClientsIfNotExists = async (doc: Doc): Promise<Doc> => {
  doc.Client = await createClientIfNotExist(doc.Client)
  doc.ClientID = String(doc.Client.ID)
  if (doc.By) {
    doc.By = await createClientIfNotExist(doc.By)
    doc.ByID = String(doc.By.ID)
  }
  if (doc.InvoiceTo) {
    doc.InvoiceTo = await createClientIfNotExist(doc.InvoiceTo)
    doc.InvoiceToID = String(doc.InvoiceTo.ID)
  }
  if (doc.OfferNamedTo) {
    doc.OfferNamedTo = await createClientIfNotExist(doc.OfferNamedTo)
    doc.OfferNamedToID = String(doc.OfferNamedTo.ID)
  }

  for (let i = 0; i < doc.DocumentContacts.length; i++) {
    if (doc.DocumentContacts[i].Client) {
      doc.DocumentContacts[i].Client = await createClientIfNotExist(doc.DocumentContacts[i].Client!)
      doc.DocumentContacts[i].ClientID = String(doc.DocumentContacts[i].Client!.ID)
    }
  }

  return doc
}

export const updateDoc = async ({constructionSite, doc, contract}: createDocModel, catalogs: Catalog[], newVersion:boolean):Promise<updateDocReturnType> => {

  const returnedConstructionSite = await ConstructionSiteAPI.updateConstructionSite(constructionSite)
  contract.ConstructionSiteID = returnedConstructionSite.ID

  const returnedContract = await ConstructionSiteAPI.updateContract(contract)
  doc.ContractID = returnedContract.ID

  doc = await createClientsIfNotExists(doc)

  const docType = doc.Type

  let returnedDoc: Doc

  if (newVersion) {
    doc.Version += 1
  }
  
  const sapDoc = docToSapDocConvert(doc)
  const response = await axios.request({
    method: docType.Config.Update?.Method as Method,
    url: docType.Config.Update?.URL,
    data: sapDoc,
  })

  returnedDoc = sapDocToDocConvert({
    ...response.data,
    Type: {
      ...response.data.Type,
      Config: JSON.parse(response.data.Type.Config),
    },
  }, catalogs);

  return {
    constructionSite: returnedConstructionSite,
    doc: returnedDoc,
    contract: returnedContract,
    uploaded: true,
  }
}



export const updateOfferStatus = async (doc: Doc, won: boolean, wonById: string | null, notes: string | null, catalogs: Catalog[]) => {
  const status = (won ? (DocStatus.WIN) : DocStatus.LOST).code;
  const data = {
    ID: doc.ID,
    Status: status,
    WonByID: wonById,
    Notes: notes,
  }

  const response = await axios.post("/offer/status", data)

  if (response.status != 200) {
    throw response;
  }

  return sapDocToDocConvert(response.data, catalogs)
}

export const getDocById = async (docId: number, type: DocType): Promise<SapDoc> => {
  const response = await axios.request({
    method: type.Config.Get?.Method as Method,
    url: type.Config.Get?.URL + "/" + docId + "?t=" + Date.now(),
  })

  if (response.status != 200) {
    throw response
  }

  const d = {
    ...response.data,
    CreatedDate: new Date(response.data.TaxDate),
    UpdatedDate: new Date(response.data.UpdatedDate),
    Type: {
      ...response.data.Type,
      Config: JSON.parse(response.data.Type.Config),
    },
  }

  if (d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice) {
    return {
      ...d,
      CreatedDate: new Date(response.data.TaxDate),
    }
  }

  return d
}
export const getOrderByContractId = async (id:number):Promise<SapDoc> => {
  const response = await axios.get(`/order/${id}?t=${Date.now()}`)

  if (response.status != 200) {
    throw response
  }
  
  const d = response.data
  if (d.DocumentContacts == undefined) {
    d.DocumentContacts = []
  }

  for (let i = 0; i < d.DocumentContacts.length; i++) {
    d.DocumentContacts[i].Type = d.DocumentContacts[i].DocumentContactType
    d.DocumentContacts[i].TypeID = d.DocumentContacts[i].DocumentContactType.ID
  }

  return {
    ...d,
    Type: {
      ...d.Type,
      Config: JSON.parse(d.Type.Config),
    },
  }
}

export const updateOrder = async (order:SapDoc):Promise<SapDoc> => {
  const response = await axios.post(`/order/`, {...order, Levels: undefined, Lines: undefined});

  if (response.status != 200) {
    throw response;
  }

  return response.data;
}

export const cancelOrder = async (order: SapDoc): Promise<void> => {
  const response = await axios.post(`/order/delete?orderId=${order.ID}`, {...order, Lines: undefined});

  if (response.status != 200) {
    throw response;
  }
}

export const updateOrderStatus = async (contractId: number, status: number) => {
  const order = await getOrderByContractId(contractId);

  await updateOrder({...order, Status: status});
}

export enum SendDocumentOrder {
  FirstPage = 'firstPage',
  Financial = 'financial',
  Beton = 'beton',
  Order = 'order',
}

export const sendDocumentViaEmail = async (doc: Doc, recipient?: string, subject?: string, message?: string, sendDocument?: SendDocumentOrder): Promise<void> => {
  let id = doc.ID;
  if (doc.Type.Config.ConstructionSiteViewGroup == 'order' && sendDocument && sendDocument != SendDocumentOrder.Order) {
    switch (sendDocument) {
      case SendDocumentOrder.Beton:
      case SendDocumentOrder.Financial:
      case SendDocumentOrder.FirstPage:
        id = doc.ContractID;
        break;
    }
  }
  let url = `sendDocumentViaEmail?documentType=${doc.TypeID}&documentEntry=${id}`;

  if (recipient) {
    url += `&recipient=${recipient}`;
  }
  if (subject) {
    url += `&subject=${encodeURIComponent(subject)}`;
  }
  if (message) {
    url += `&message=${encodeURIComponent(message+'\n')}`;
  }
  if (doc.Type.Config.ConstructionSiteViewGroup == 'order' && sendDocument && sendDocument != SendDocumentOrder.Order) {
    url += `&sendDocument=${sendDocument}`;
  }

  const res = await axios.post(url);
  if (res.status !== 200) {
    throw res;
  }
}

export const getOffersToCheck = async (): Promise<OfferToCheck[]> => {
  const response = await axios.get('/getOffersToBeChecked')

  if (response.status != 200) {
    throw response;
  }

  return response.data.map((d: any) => ({
    ...d,
    CreatedDate: new Date(response.data.CreatedDate),
    DocType: {
      ...d.DocType,
      Config: JSON.parse(d.DocType.Config),
    }
  }))
}
