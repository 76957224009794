import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { useState } from 'react'
import { Pressable, Text, View } from 'react-native'
import Modal from 'react-native-modal'
import DatePicker from '../DatePicker'
import Button from '../Button'
import getTheme from '../../constants/Colors'

interface ChooseDateProps {
  onClose: () => void
  date?: Date
  setDate: (date?: Date) => void
}

const ChooseDateModal = ({ onClose, date = new Date(), setDate }: ChooseDateProps) => {

  const theme = getTheme()

  const [d, setD] = useState<Date | undefined>(date)

  const _confirm = () => {
    setDate(d)
    onClose()
  }

  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      onBackButtonPress={onClose}
      style={{
        borderRadius: 15,
        padding: 15,
        alignSelf: 'center',
        width: 400,
        backgroundColor: 'white',
      }}
    >
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <Text style={{fontSize: 20}}>Select a date</Text>
        <MaterialCommunityIcons
          name='close'
          color='black'
          size={34}
        />
      </View>

      <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 15,
      }}>
        <DatePicker
          value={d || new Date()}
          onChange={setD}
        />
      </View>

      <View style={{flex: 1}} />

      <Button
        style={{
          marginTop: 15,
          backgroundColor: theme.mainColor,
        }}
        onPress={_confirm}
        titleT='CHOOSE'
      />
    </Modal>
  )
}

export default ChooseDateModal
