import {
  GET_ALL_CLIENTS_SUCCESS,
  UPDATE_CLIENT,
  NEW_CLIENT,
  LOGOUT,
  GET_BASIC_DATA_SUCCESS,
} from '../types'
import {
  Client,
  ClientType,
} from '../../models'

export type businessPartners = {
  clients: Client[]
  clientTypes: ClientType[]
}

const initialState: businessPartners = {
  clients: [],
  clientTypes: [],
}

export default function (
  state = initialState,
  action: { type: string; payload: any },
): businessPartners {
  switch (action.type) {
    case GET_BASIC_DATA_SUCCESS:
      return {
        ...state,
        clientTypes: action.payload.clientTypes,
      }
    case GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      }
    case NEW_CLIENT:
      return {
        ...state,
        clients: [action.payload, ...state.clients],
      }
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: state.clients.map(
          (c:Client) => c.ID === action.payload.ID ? action.payload : c
        ),
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
