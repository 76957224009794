import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop:10,
    paddingHorizontal: 10,
    width: '100%',
    alignSelf: "center",
  },
  horizontalContainer: {
    flexDirection: "row",
    width: "100%",
  },
  subContainer: {
    flex: 1,
    padding: 5,
  },
  flatList: {
    borderRadius: 10,
    backgroundColor: "white",
  },
  header: {
    flexDirection: "row",
  },
  headerText: {
    flex: 1,
    alignItems: "center",
  },
  listHeaderContainer: {
    alignItems: "center",
    paddingVertical: 10,
  },
  listHeader: {
    fontSize: RFValue(12),
    fontWeight: "bold",
  },
});

export default styles;
