import { DocStatus } from "../../../constants/Status"
import { Contract } from "../../../models"

export const OPEN_DAY_REPORT = 'OPEN_DAY_REPORT'

export const isActiveContract = (c: Contract): boolean => {
 switch (Number(c.Status)) {
    case DocStatus.IN_EXECUTION.code:
    case DocStatus.OPEN.code:
    case DocStatus.TO_BE_APPROVED.code:
    case DocStatus.TO_BE_BILLED.code:
      return true
    default:
      return false
  }
}