import React, { useMemo } from 'react'
import { Item, Level, Line, LineLevel, LineSumType } from "../../../models";
import { DocLineColumnWidth, DocLineColumnWidthSumFlat } from "./DocLinesHeader";
import HtmlText from "../../../components/HtmlText";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { PriceLevel } from "../../../store/reducers/settingsReducer";
import { FlatList, Text, View } from "react-native";
import DocLinePositionLevel from "./DocLinePositionLevel";
import Button from "../../../components/Button";
import getTheme from "../../../constants/Colors";
import Formatter from "../../../utils/formatters";
import { useNavigation } from "@react-navigation/native";
import { SearchScreens } from "../../search/SearchScreens";
import { PrintTwinLevel } from './utils';

interface Props {
  line: Line;
  setLine: (setLine: (old: Line) => Line) => void;
  levels: Level[];
  letters: string[];
  addPriceFixBlock: () => void;
  addItem: (item: Item | Item[], keepOrder: boolean) => void;
  priceNoPrintByLevels?: PrintTwinLevel[]
}

const areEqual = (p: Readonly<Props>, n:Readonly<Props>) => {
  return JSON.stringify(p) === JSON.stringify(n) && p === n;
}

const DocLinePosition = ({line, setLine, levels, letters, addPriceFixBlock, addItem, priceNoPrintByLevels}: Props) => {

  const theme = getTheme();
  const navigation = useNavigation()

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);
  const client = showPriceLevel >= PriceLevel.CLIENT;

  const _variablePosition = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.CREATE_POSITION, {
      onAdd: addItem,
      toEdit: line,
    })
  }

  const f2 = Formatter.number2.format;

  const _render = ({item, index}: {item: LineLevel, index: number}) => {

    const _setLineLevel = (lineLevel: LineLevel) => {
      setLine(old => {
        return {
          ...old,
          LineLevel: old.LineLevel.map((item2, index2) => {
            if (index !== index2) {
              return item2;
            }
            return lineLevel;
          }),
          Quantity: old.LineLevel.reduce((p, n, index2) => {
            if (index !== index2 && n.LineSumType === LineSumType.NORMAL) {
              return p + n.Quantity;
            } else if (index === index2 && lineLevel.LineSumType === LineSumType.NORMAL) {
              return p + lineLevel.Quantity;
            } else {
              return p;
            }
          }, 0),
          LineTotal: old.LineLevel.reduce((p, n, index2) => {
            if (index !== index2 && n.LineSumType === LineSumType.NORMAL && (n.FixedPriceBlock === "-" || n.FixedPriceBlock === "")) {
              return p + n.LineTotal;
            } else if (index === index2 && lineLevel.LineSumType === LineSumType.NORMAL && (lineLevel.FixedPriceBlock === "-" || lineLevel.FixedPriceBlock === "")) {
              return p + lineLevel.LineTotal;
            } else {
              return p;
            }
          }, 0),
        };
      });
    };

    return (
      <DocLinePositionLevel
        lineLevel={item}
        level={levels[index]}
        setLineLevel={_setLineLevel}
        key={index}
        letters={letters}
        addPriceFixBlock={addPriceFixBlock}
        priceNoPrintByLevel={priceNoPrintByLevels?.[index]}
        twinPrint={line.PrintTwin}
      />
    );
  };

  const lineTotal = useMemo(
    () => priceNoPrintByLevels?.reduce((p, n) => p + n.lineTotal, 0) || line.LineTotal,
    [priceNoPrintByLevels, line.LineTotal]
  )

  return (
    <View style={{flexDirection: 'row', flex: 1}}>
      <Text style={{width: DocLineColumnWidth.CODE}}>{line.Code}</Text>

      <View style={{flexDirection: "column", flex: 1}}>
        <View style={{flexDirection: "row", marginBottom: 9}}>
          <View style={{flex: 1}}>
            <HtmlText html={line.Name} maxLength={100} width={"100%"}/>
          </View>

          {
            line.Variable ? (
                <View style={{width: DocLineColumnWidth.QUANTITY, alignItems: "center"}}>
                  <Button
                    onPress={_variablePosition}
                    titleT="VARIABLE"
                    style={{backgroundColor: theme.blue}}
                  />
                </View>
              ) :
              <Text style={{width: DocLineColumnWidth.QUANTITY, textAlign: 'center'}}>{f2(line.Quantity)}</Text>
          }
          <Text style={{width: DocLineColumnWidth.UOM, textAlign: 'center'}}>{line.UnitOfMeasure}</Text>
          <View style={{width: DocLineColumnWidth.PRICE}}/>
          <Text style={{width: DocLineColumnWidth.TOTAL, textAlign: 'center'}}>{(client && line.PrintTwin) ? f2(lineTotal) : ""}</Text>
          <View style={{width: DocLineColumnWidthSumFlat}}/>
        </View>

        {
          !line.Variable && (
            <FlatList
              data={line.LineLevel}
              renderItem={_render}
              removeClippedSubviews={true}
              key={`line-${line.ID}`}
              // @ts-ignore
              listKey={`line-${line.ID}`}
              keyExtractor={(item, index) => `line-${line.ID}-${index}-${item.ID}`}
            />
          )
        }
      </View>
    </View>
  );
};

export default DocLinePosition;