import React from "react";
import { Address } from "../../models";
import { NativeSyntheticEvent, TextInputChangeEventData, View } from "react-native";
import LabelInput from "../LabelInput";
import CountryDropdown from "../CountryDropdown";
import { GooglePlaceData, GooglePlaceDetail, GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { t } from 'i18n-js'
import { googleMapsLinkToCoordinates } from "../../hooks/geo";

interface Props {
  address: Address;
  setAddress: (address: Address) => void;
}

const ClientAddress = ({address, setAddress}: Props) => {

  const token = useSelector((state: Reducer) => state.auth.token)
  const url = useSelector((state: Reducer) => state.auth.workSpace?.url || '')
  const branch = useSelector((state: Reducer) => state.user?.branch.Maps)
  const branchCoordinates = googleMapsLinkToCoordinates(branch).coords

  const parse = (address: Address):Address => ({
    ...address,
    ID: address.ID == '' ?  t('ADDRESS') : address.ID,
    Name: address.Name == '' ? t('ADDRESS') : address.Name,
  })

  const _name = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setAddress({
      ...address,
      ID: e.nativeEvent.text,
      Name: e.nativeEvent.text,
    });
  };

  const _street0 = (s: string) => {
    setAddress(parse({
      ...address,
      StreetLine0: s,
    }))
  };

  const _street1 = (s: string) => {
    setAddress(parse({
      ...address,
      StreetLine1: s,
    }))
  };

  const _street2 = (s: string) => {
    setAddress(parse({
      ...address,
      StreetLine2: s,
    }))
  };

  const _cap = (c: string) => {
    setAddress(parse({
      ...address,
      PostCode: c,
    }))
  };

  const _city = (c: string) => {
    setAddress(parse({
      ...address,
      City: c,
    }))
  };

  const _region = (r: string) => {
    setAddress(parse({
      ...address,
      Region: r,
    }))
  };

  const _country = (c: string) => {
    setAddress(parse({
      ...address,
      Country: c,
    }))
  };

  const _choose = (data: GooglePlaceData, details: GooglePlaceDetail | null) => {
    let StreetLine0 = details?.address_components.find(item => item.types.includes('route'))?.long_name || ''
    StreetLine0 += ' ' + details?.address_components.find(item => item.types.includes('street_number'))?.long_name || ''
    let PostCode = details?.address_components.find(item => item.types.includes('postal_code'))?.long_name || ''
    let City = details?.address_components.find(item => item.types.includes('locality'))?.long_name || ''
    let Country = details?.address_components.find(item => item.types.includes('country'))?.short_name || ''
    let Region = details?.address_components.find(item => item.types.includes('administrative_area_level_1'))?.short_name || ''
    setAddress({
      ...address,
      Name: data.description,
      StreetLine0,
      PostCode,
      City,
      Country,
      Region,
    })
  }

  return (
    <View style={{flexDirection: 'column', flex: 1, paddingLeft: 5}}>

      <View>
        <GooglePlacesAutocomplete
          fetchDetails={true}
          textInputProps={{
            onChange: _name,
            value: address.Name,
          }}
          placeholder='Search'
          onPress={_choose}
          query={{
            key: 'AIzaSyDqb1ADutBQksnAv43XLyjDQuJO9cTALNU',
            language: 'en',
            types: 'address',
            location: `${branchCoordinates.latitude},${branchCoordinates.longitude}`,
            radius: 5000,
            input: address.Name,
          }}
          requestUrl={{
            useOnPlatform: 'web',
            url: `${url}googleMapsApiProxy`,
            headers: {
              Authorization: token,
            },
          }}
        />
      </View>
      <LabelInput label={"STREET_LINE"} value={address.StreetLine0} setValue={_street0} maxLength={100}/>
      <LabelInput label={"STREET_LINE_1"} value={address.StreetLine1} setValue={_street1} maxLength={50}/>
      <LabelInput label={"STREET_LINE_2"} value={address.StreetLine2} setValue={_street2} maxLength={50}/>
      <LabelInput label={"POSTAL_CODE"} value={address.PostCode} setValue={_cap} maxLength={20}/>
      <LabelInput label={"CITY"} value={address.City} setValue={_city} maxLength={100}/>

      <View style={{height: 10}}/>
      <CountryDropdown
        setCountry={_country}
        setState={_region}
        country={address.Country}
        state={address.Region}
      />
    </View>
  );
};

export default ClientAddress;