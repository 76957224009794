import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { t } from "i18n-js";
import axios from "axios";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface Props {
  url: string;
  description: string;
  ok: () => void;
}

enum State {
  CHECKING,
  OK,
  ERROR,
}

const StateCircle = ({state}: {state: State}) => {

  if (state == State.CHECKING) {
    return (
      <ActivityIndicator />
    );
  }

  const backgroundColor = state == State.OK ? '#0f0' : '#f00';

  return (
    <View style={{
      padding: 10,
      borderRadius: 50,
      backgroundColor,
    }}/>
  );
};

const CheckingWorkSpaceUrl = ({url, description, ok}: Props) => {

  const [state, setState] = useState<State>(State.CHECKING);

  const check = () => {
    setState(State.CHECKING);
    axios.get(url + "files/active.txt").then(() => {
      setState(State.OK);
      ok();
    }).catch(e => {
      setState(State.ERROR);
    })
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <View style={{paddingVertical: 30, flex: 1, flexDirection: 'column'}}>
        <Text style={{fontSize: 20}}>
          {t("CHECKING_URL")}
        </Text>
        <Text>
          {description}
        </Text>
      </View>

      {
        state == State.ERROR && (
          <MaterialCommunityIcons
            name="reload"
            size={24}
            color="#555"
            onPress={check}
            style={{
              paddingRight: 10,
            }}
          />
        )
      }

      <View style={{aspectRatio: 1, justifyContent: 'center', alignItems: 'center'}}>
        <StateCircle state={state} />
      </View>
    </View>
  );
};

export default CheckingWorkSpaceUrl;