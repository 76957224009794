import React from "react";
import DateTimePicker from "@react-native-community/datetimepicker";
import { View, Platform, StyleProp, ViewStyle } from "react-native";
import WebDatePicker from "../WebDatePicker";

interface Props {
  value: Date;
  onChange: (date: Date | undefined) => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const DatePicker = (props: Props) => {
  const os = Platform.OS;
  if (os === "web") {
    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: 'rgb(212, 212, 212)',
          backgroundColor: 'white',
          minWidth: 85,
          borderRadius: 4,
        }}
      >
        <WebDatePicker {...props} />
      </View>
    );
  }

  return (
    <DateTimePicker
      disabled={props.disabled || false}
      value={props.value}
      onChange={(event, date) => {
        props.onChange(date);
      }}
      style={[{ minWidth: 85 }, props.style]}
    />
  );
};

export default DatePicker;
