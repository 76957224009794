const translations = {
  en: {
    SERVER: "Server",
    WRONG_USERNAME_PASSWORD: "Username and password is wrong",
    INVALID_LOGIN_TITLE: "Invalid Login",
    LOGOUT: "Logout",
    SEARCH: "Search",
    SHOW_PRICE_LEVEL_NO_PRICE: "Hide prices",
    SHOW_PRICE_LEVEL_CLIENT: "Client prices",
    SHOW_PRICE_LEVEL_INTERNAL: "Internal prices",
    SHOW_PRICE_LEVEL_PLACEHOLDER: "Select the price to be shown",
    FILTER_BY: "filter by:",
    NUMBER: "Number",
    NAME: "Name",
    GROUP: "Group",
    STATUS: "Status",
    CONSTRUCTION_SITES: "Construction Sites",
    PAGE_SIZE_TEXT: "entries per page",
    DOC_NAME: "Document",
    DOC_TYPE: "Document Type",
    DOC_DISTANCE: "Distance",
    DOC_CREATE_DATE: "Creation Date",
    UPDATE_DATE: "Update Date",
    CREATED_BY: "Created By",
    DOC_UPDATED_BY: "Updated By",
    CLIENT: "Client",
    DOC_CONTACTS: "Contacts",
    DOC_BY: "By",
    DOC_OFFER_NAMED_TO: "Offer named to",
    DOC_INVOICE_TO: "Invoice to",
    BY: "By",
    OFFER_NAMED_TO: "Offer named to",
    INVOICE_TO: "Invoice to",
    DOC_CLIENT_CODE: "Code",
    DOC_CLIENT_CONTACT: "Contact",
    PHONE: "Phone",
    DOC_ADD_CONTACT: "Add contact",
    CATALOG_DROPDOWN: "Catalog",
    CONTRACT_NAME: "Contract",
    CONSTRUCTION_SITE: "Construction Site",
    CONSTRUCTION_SITE_TYPE: "Type",
    CONSTRUCTION_SITE_ADDRESS: "Address",
    CONSTRUCTION_SITE_MAPS: "Maps",
    DELETE: "delete",
    WIN: "Win",
    LOSE: "Lose",
    DUPLICATE: "Duplicate",
    PDF: "PDF",
    PRINT: "Print",
    EMAIL: "Email",
    VERSIONS: "Versions",
    VERSION: "Version",
    CHOOSE_CONSTRUCTION_SITE: "Choose Construction Site",
    CREATE_NEW_CONSTRUCTION_SITE: "Create new Construction Site",
    CHOOSE_CONTRACT: "Choose Contract",
    CREATE_NEW_CONTRACT: "Create new Contract",
    STATUS_OPEN: "Open",
    STATUS_LOST: "Lost",
    OFFER: "Offer",
    STATUS_DONE: "Done",
    STATUS_CANCELLED: "Cancelled",
    STATUS_NOT_AWARDED: "Not awarded",
    STATUS_OLD_VERSION: "Old version",
    STATUS_WON: "Won",
    STATUS_OTHER: "Other",
    LEVELS: "Levels",
    LEVEL_PRINT_FLAG: "Print Levels",
    NEW_LEVEL: "New Level",
    CREATE: "Create",
    UPDATE: "Update",
    CANCEL: "Cancel",
    CLOSE: "Close",
    SEARCH_CATEGORY_ALL: "all",
    SEARCH_CATEGORY_OFFERS: "offers",
    SEARCH_CATEGORY_SITES: "construction sites",
    SEARCH_CATEGORY_CLIENTS: "Clients",
    SEARCH_RESULT: "Results",
    ADVANCED_TOGGLE_LABEL: "Advanced search",
    RESULTS_LABEL: "Results for",
    CLIENT_TYPE: "Client type",
    CREATE_NEW_REPORT: "Create new report",
    OFFERS: "Offers",
    INVOICES: "Invoices",
    CONSTRUCTION_SITE_ACCOUNTING: "Construction Site Accounting",
    WORK_REPORTS: "Work Reports",
    PHOTO: "Photo",
    OFFER_CONFIGURATOR_TABLE: "Table",
    OFFER_CONFIGURATOR_LIST: "List",
    CLIENT_TYPE_COMPANY: "Company",
    CLIENT_TYPE_PERSON: "Person",
    SELECT_CONTACT_TYPE: "Select contact type",
    FIELD_IS_MANDATORY_MESSAGE: "This field is mandatory",
    MORE_OPTIONS_MENU_LABEL: "More options menu",
    BRANCH: "Branch",
    VAT: "VAT",
    MOBILE_PHONE: "Mobile Phone",
    STREET_LINE: "Street line",
    STREET_LINE_1: "Street line 1",
    STREET_LINE_2: "Street line 2",
    POSTAL_CODE: "Postal code",
    CITY: "City",
    REGION: "Region",
    COUNTRY: "Country",
    CHOOSE_CLIENT: "Choose client",
    EDIT_CLIENT: "Edit Client",
    HAS: "has",
    CONTACTS_LOW: "contacts",
    CREATE_NEW_CONTACT: "Create new contact",
    CREATE_NEW_CLIENT: "Create new client",
    CREATE_EDIT_NEW_CONTACT: "Create/Edit new contact",
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    SAVE: "Save",
    NEW_CONTACT: "New contact",
    NEW_BUSINESS_PARTNER: "New Business Partner",
    BUSINESS_PARTNER: "Business Partner",
    SHOW_CLIENT_LIST: "Show Client List",
    POSITION: "Position",
    EXPAND_ALL: "Expand all",
    NEW_ADDRESS: "New address",
    NO_ENTERED_NAME: "No entered name",
    EDIT_LOW: "edit",
    FILE: "File",
    WELCOME: "Welcome",
    USERNAME: "Username",
    PASSWORD: "Password",
    SIGN_IN: "Sign in",
    PERMISSION_NOT_GRANTED: "Permission not granted",
    ALLOW_THE_APP_TO_USE_LOCATION_SERVICES:
      "Allow the app to use location services",
    NO_OFFER_FOUND_FOR_CLIENT: "No offer found",
    NO_CONSTRUCTION_SITE_FOUND_FOR_CLIENT: "No construction site found",
    NO_INVOICE_FOUND_FOR_CLIENT: "No invoice found",
    CODE: "Code",
    DESCRIPTION: "Description",
    QUANTITY: "Quantity",
    UOM: "UoM",
    PRICE: "Price",
    PERCENT: "%",
    TOTAL: "Total",
    TYPE: "Type",
    FLAT_RATE: "Flat rate",
    ADD_A_ROW: "Add a row",
    READ_LEVEL: "Read Level",
    INTERNAL: "Internal",
    ADMIN: "Admin",
    WORKER: "Worker",
    GUEST: "Guest",
    DISCOUNTS: "Discounts",
    DISCOUNT: "Discount",
    STANDARD: "Standard",
    ADD_DISCOUNT: "Add discount",
    WITHOUT_IVA: "Without IVA",
    INSERT_A_COMMENT: "Insert a comment",
    TO_BE_AGREED: "to be agreed",
    EDIT_CONTACT: "Edit contact",
    UNIT_PRICE: "Unit price",
    INSERT_A_QUANTITY: "Insert a quantity",
    SAVE_IN: "Save in",
    MY_POSITIONS: "my positions",
    NAME_OF_THE_POSITION: "Name of the position",
    SELECT_UNIT_OF_MEASURE: "Select unit of measure",
    UNIT_OF_MEASURE: "Unit of measure",
    SHOW_LEVELS: "Show levels",
    EXECUTE: "Execute",
    INVOICED: "Invoiced",
    CALCULATED: "Calculated",
    CREATE_POSITION: "Create position",
    SELECT_WHAT_TO_ADD: "Select what to add",
    INSERT_A_POSITION: "Insert a position",
    INSERT_A_TEXT: "Insert a text",
    INSERT_A_ENGINEERING_WARRANTY_BLOCK_PRICE:
      "Insert an engineering/warranty block price",
    INSERT_A_NORMAL_BLOCK_PRICE: "Insert a normal block price",
    TABLE_VIEW: "Table view",
    PLEASE_SELECT_A_CATALOG: "Please select a catalog",
    LEVEL: "Level",
    TYPOLOGY: "Typology",
    NO_DISCOUNT: "No discount",
    TOTAL_WITH_DISCOUNTS: "Total with discounts",
    RESOURCE_NAME: "Resource name",
    HOURS: "Hours",
    MINUTES: "Minutes",
    CHOOSE_A_LETTER: "Choose a letter",
    CHOOSE_A_VAT: "Choose a VAT",
    CHOOSE_A_CATALOG: "Choose a catalog",
    CHOOSE_A_SUM_TYPE: "Choose a sum type",
    ALL: "All",
    OPEN_AND_NOT_ASSIGNED: "Open and not assigned",
    OPEN_AND_ASSIGNED: "Open and assigned",
    SHARE: "Share",
    TODAY: "Today",
    PLANNING: "Planning",
    TOTAL_QUANTITY: "Total quantity",
    FOR: "For",
    TO_CONCORD: "To concord",
    CLEAR: "Clear",
    CONFIRM: "Confirm",
    SIGN_ABOVE: "Sign above",
    TODO: "To do",
    SIGN: "Sign",
    WARNING: "Warning",
    ARE_YOU_SURE: "Are you sure?",
    REPORT_MODAL_CLOSE_MESSAGE: `By pressing "Yes" you will delete this report and all of its data.`,
    YES: "Yes",
    NO: "No",
    TRIP_TIME: "Trip time",
    NO_AUTO: "No auto",
    BREAK: "Break",
    START_TIME: "Start time",
    START_TIME_ON_SITE: "Start time on site",
    END_TIME: "End time",
    END_TIME_ON_SITE: "End time on site",
    PREVIEW: "Preview",
    ADD_WORK: "Add work",
    NOTES: "Notes",
    SIGNED_BY: "Signed by",
    DATE: "Date",
    PLACE: "Place",
    SIGNATURE: "Signature",
    CLICK_HERE_TO_SIGN: "Click here to sign",
    SAVE_AND_CLOSE: "Save and close",
    SIGNATURE_NOT_AVAILABLE: "Please use an iPad to sign",
    REMOVE: "Remove",
    OPEN_CONSTRUCTION_SITE: "Open construction site",
    MODIFY: "Modify",
    NO_INTERNET_CONNECTION: "No Internet Connection",
    SELECT_VERSION: "Choose version",
    SELECT_LANGUAGE: "Select language",
    SELECT_CURRENCY: "Select currency",
    SELECT_SALE_EMPLOYEE: "Select seller",
    NO_EARLIER_VERSION_AVAILABLE: "No earlier version available",
    SEE_OLDER_VERSIONS: "See older versions",
    WOULD_YOU_CREATE_NEW_VERSION:
      "Would you create a new version of this document?",
    CREATE_NEW_VERSION: "Create new version",
    UPDATE_VERSION: "Update this version",
    CLOSE_WITHOUT_SAVING: "Close without saving?",
    ARE_YOU_SURE_CLOSE_WITHOUT_SAVING:
      "Are you sure you want to close the document without saving it?",
    NEW_DOCUMENT: "New Document",
    DOCUMENTS: "Documents",
    DOCUMENT_NUMBER: "Document Number",
    WHO_WON_OFFER: "Who won the offer?",
    COMPETITOR: "Competitor",
    LINE_TOTAL: "Line total",
    ORDER: "Order",
    NO_ORDER_FOR_THIS_CONTRACT: "No order for this contract",
    BILLED: "billed",
    SOLD: "sold",
    NOT_BILLABLE: "not billable",
    SHOW_AS_MONETARY_VALUE: "show as monetary value",
    EXECUTED: "Executed",
    EDIT: "edit",
    ADD_NEW_POSITION: "Add new position",
    PRICE_LIST: "Price list",
    DIGITAL: "Digital",
    VERSION_ALREADY_CHANGED: "Version already changed",
    VERSION_ALREADY_CHANGED_DESC: "{0} {1} has already changed the offer on {2} at {3}. Are you sure you want to overwrite the changes?",
    CLOSE_PREVIEW: "Close preview",
    EDIT_CONTRACT: "Edit contract",
    CONTRACT_CHANGED: "Contract changed",
    CONTRACT_CHANGED_DESC: "Are you sure you want to cancel changes?",
    NOT_SAFE_OPERATION: "Not safe operation",
    ARE_YOU_SURE_TO_CHANGE_CONSTRUCTION_SITE_ID_DESC: "Are you sure you want to change the ID of the construction site?\nThis could potentially be a destructive operation.",
    NOT_VALID_CONSTRUCTION_SITE_ID: "Not valid construction site ID",
    UPDATE_CONTRACT: "Update contract",
    NOT_VALID_CONTRACT_NAME: "Not valid contract name",
    CONSTRUCTION_SITE_CHANGED: "Construction site changed",
    CONSTRUCTION_SITE_CHANGED_DESC: "Are you sure you want to cancel changes?",
    EDIT_CONSTRUCTION_SITE: "Edit construction site",
    POSSIBLE_CONSTRUCTION_SITE_NUMBER: "Potential construction site number",
    CONSTRUCTION_SITE_NUMBER: "Construction site number",
    UPDATE_CONSTRUCTION_SITE: "Update construction site",
    ERROR_UPDATING_CONSTRUCTION_SITE: "Error updating construction site",
    ERROR_UPDATING_CONTRACT: "Error updating contract",
    ERROR_CONSTRUCTION_SITE: "Fehler loading construction site",
    ERROR_LOADING_ORDER: "Error loading order",
    SOME_FIELDS_ARE_NOT_VALID: "Some fields are not valid",
    SOME_FIELDS_MIGHT_BE_NOT_COMPLETE: "Some fields might be not complete, are you sure to proceed?",
    ERROR_LOADING_CONSTRUCTION_SITES: "Error loading construction sites",
    ERROR_ASSIGING_CONSTRUCTION_SITE: "Error assigning construction site",
    ERRORS: "Errors",
    WARNINGS: "Warnings",
    INFO: "Info",
    NO_PHOTOS: "No photos",
    CHOOSE: "Choose",
    NO_CONSTRUCTION_SITE_SELECTED: "No construction site selected, please select one or create a new one.",
    CONSTRUCTION_SITE_HAS_NO_CONTRACTS: "Construction site has no contracts, please create a new contract.",
    CLIENTS: "Clients",
    ERROR_LOADING_CLIENTS: "Error loading clients",
    ERROR_LOADING_CLIENTS_DESC: "Error loading clients, retry agian.",
    CHOOSE_CONTACT: "Choose a contact from the client",
    CLIENT_HAS_NO_CONTACTS: "Client has no contacts.\nEdit the client to add a new contact\nor proceed.",
    NO_CLIENT_SELECTED: "No client selected.",
    ERROR_CREATING_CLIENT: "Error creating client",
    ERROR_CREATING_CLIENT_DESC: "Error creating the client, please try again.",
    CLIENT_CHANGED: "Client changed",
    CLIENT_CHANGED_DESC: "Are you sure you want to cancel the changes?",
    CLIENT_ERROR: "Client data not valid",
    CLIENT_ERROR_DESC: "Some data in the client is not valid, please fix before creating client",
    CLIENT_WARNINGS: "Client data not complete",
    CLIENT_WARNINGS_DESC: "Some data in the client might not be filled, are you sure you want to continue?",
    ADDRESSES: "Addresses",
    CUSTOM_CONTACT_CHANGED: "Document contact changed",
    CUSTOM_CONTACT_CHANGED_DESC: "Are you sure you want to cancel the changes?",
    CUSTOM_CONTACT: "Custom contact",
    ADD_NEW_DOCUMENT_CONTACT: "Add new document contact",
    CONTACT_TYPE: "Contact type",
    CONTACT: "Contact",
    CONTACTS: "Contacts",
    SELECT_DOCUMENT_TYPE: "Select document type",
    SELECT_DOCUMENT_TYPE_DESC: "Select the document type before creating a new document contact",
    GENERAL: "General",
    SUM_TYPE: "Sum type",
    EDIT_LINE: "Edit line",
    INTERNAL_NAME: "Internal name",
    ADD_POSITION: "Add position",
    ADD_TEXT_LINE: "Add text line",
    INSERT_FLAT_RATE: "Add flat rate line",
    INSERT_FLAT_RATE_ENGINEERING_WARRANTY: "add engineering/warranty flat rate line",
    DUPLICATE_LINE: "Duplicate line",
    TEXT: "Text",
    NEW_BLOCK: "New block",
    NOT_AVAILABLE_FOR_FIXED_PRICE: "Settings not available for fixed price lines.",
    PRICING: "Pricing",
    VARIABLE: "Variable",
    TOTAL_TO_CONCORD: "Total to concord",
    NORMAL: "Normal",
    TOTAL_TYPE: "Total type:",
    DISCOUNT_NUMERIC: "5.-",
    DISCOUNT_PERCENTAGE: "%",
    TOTAL_WITH_DISCOUNT: "Total with discount",
    TOTAL_WITH_VAT: "Total with VAT",
    NEW_DISCOUNT: "new discount",
    MARGINALITY: "Marginality",
    MARGINALITY_DOC_CONSTRUCTION_SITE: "Show marginality for document or construction site",
    DOCUMENT: "Document",
    BASIC_OFFER: "Basic offer",
    MATERIAL_COST: "Material Cost",
    HUMAN_WORK_COST: "Human Work Cost",
    EXTERNAL_WORK_COST: "External Work Cost",
    TOTAL_COSTS: "Total Costs",
    MATERIAL_REVENUE: "Material Revenue",
    HUMAN_WORK_REVENUE: "Human Work Revenue",
    EXTERNAL_WORK_REVENUE: "External Work Revenue",
    TOTAL_REVENUE: "Total Revenue",
    MARGIN_BASIC_OFFER: "Basic Offer Margin",
    WARRANTY_ENGINEERING: "Warranty / Engineering",
    ENGINEERING_HOURS_COST: "Engineering Hours Cost",
    ENGINEERING_REVENUE: "Engineering Revenue",
    WARRANTY_COSTS_MATERIAL: "Warranty Costs",
    WARRANTY_COSTS_HUMAN_WORK: "Warranty Costs",
    WARRANTY_REVENUE: "Warranty Revenue",
    MARGIN_ENGINEERING_WARRANTY: "Engineering Warranty Margin",
    GENERAL_RESULT: "General Result",
    PERCENTAGE_DIFFERENCE: "Difference %",
    REMAINING_WARRANTY_YEARS: "years of remaining warranty",
    CREATE_DOC_WARNINGS: "Are you sure you want to create the document?\nThere are some warnings",
    UPDATE_DOC_WARNINGS: "Are you sure you want to update the document?\nThere are some warnings",
    ERROR_UPDATING: "Error updating",
    ERROR_UPDATING_DESC: "Error while updating offer status.",
    NOT_IMPLEMENTED: "Not yet implemented feature",
    ERROR: "Error",
    ORDER_ERROR_DOWNLOADING: "Error while downloading the order, retry!",
    ERROR_CREATING_DOC: "Error creating document",
    ERROR_UPDATING_DOC: "Error updating document",
    ERROR_UPDATING_OFFER_STATUS: "Error while updating offer status.",
    ERROR_OPENING_DOCUMENT: "Error opening document",
    ERROR_OPENING_DOCUMENT_DESC: "Error opening document, retry.",
    DOC_TEST_MISSING_CONTRACT_CREATE: "missing contract, please create a contract",
    DOC_TEST_MISSING_CONTRACT_CREATE_OR_UPDATE: "missing contract, please select or create a contract",
    DOC_TEST_MISS_CONTRACT_NAME: "missing contract name, please insert a contract name",
    DOC_TEST_MISSING_DOCUMENT: "error missing document, we are sorry but this is a major error. Please close the application (also from background) and re-open it. The error has been tracked please",
    DOC_TEST_MISSING_DOCUMENT_NAME: "missing document name, please insert the document name",
    DOC_TEST_MISSING_DOCUMENT_TYPE: "missing document type, please select a document type",
    DOC_TEST_MISSING_DOCUMENT_LANGUAGE: "missing document language, please select the document language, by opening the 3 dots menu at top right",
    DOC_TEST_MISSING_DOCUMENT_CURRENCY: "missing document currency, please select the document currency, by opening the 3 dots menu at top right",
    DOC_TEST_MISSING_DOCUMENT_CATALOG: "missing document catalog, please select the document catalog",
    DOC_TEST_MISSING_DOCUMENT_CLIENT: "missing document client, please select the client",
    DOC_TEST_MISSING_DOCUMENT_LEVELS: "missing document levels, please create at least one level",
    DOC_TEST_MISSING_LINES: "document has no content (all rows are empty)",
    MISSING_CONSTRUCTION_SITE_CREATE_OR_CHOOSE: "missing construction site, please create or choose a construction site",
    MISSING_CONSTRUCTION_SITE_NAME: "missing construction site name, please enter a name for this construction site",
    MISSING_CONSTRUCTION_SITE_TYPE: "missing construction site type, please select a construction site type",
    MISSING_CONSTRUCTION_SITE_BRANCH: "missing construction site branch, please select a construction site branch",
    MISSING_CONSTRUCTION_SITE_MAPS: "construction site google maps address not defined, this may not allow geo location",
    MISSING_CONSTRUCTION_SITE_ADDRESS: "construction site address not defined",
    UPLOAD_NEW_FILE: "Upload new file",
    SELECT: 'Select',
    UPLOAD: 'Upload',
    SIZE: 'Size',
    TIME_AND_MATERIAL: "Time and material",
    TIME: "Time",
    REPORT_TO_APPROVE: "Report to approve",
    RESOURCE: "Resource",
    REPORT_HOURS: "Report hours",
    MISSING_TIME: "Missing Time",
    ADD: "Add",
    DISABLE_TWIN_ID_AUTOMATION: "Disable twin position",
    TWIN_ID_AUTOMATION_DISABLED: "Twin position automation disabled",
    APPROVED: "Approved",
    BILLABLE: "Billable",
    APPROVE: "Approve",
    APPROVED_DURATION: "Approved duration",
    TO_BE_APPROVED: "To be approved",
    NOT_ALLOWED: "Resource not allowed",
    NOT_ALLOWED_DESC: "The requested resource is not allowed",
    DAY_REPORTS: "Daily Work Reports",
    FILTER_TO_APPROVE: "Filter to approve",
    APPROVED_QUANTITY: "Approved quantity",
    FILES: "Files",
    REPORT: "Day Report",
    DONE: "Done",
    PLANNED_FOR_TODAY: "Planned for today",
    YESTERDAY: "Yesterday",
    REQUESTED_MARGINALITY_PDF: "ASIP pdf sent via email.",
    ERROR_MARGINALITY_PDF: "Error sendingASIP pdf",
    TO_CONCORD_TO_OFFER: "To Concord > Offer",
    FIXED_PRICE_ENGINEERING_WARRANTY: "Flat rate Engineering/Warranty",
    CREATE_CUSTOM_CONTACT: "Create custom contact",
    NO_WORK_REPORTS: "No work reports",
    NO_WORK_REPORTS_TO_APPROVE: "No work reports to approve",
    DOC_CHANGED: "Document Changed",
    UPDATE_DOC_BEFORE_PDF: "Update Doc before loading PDF",
    ENGINEERING_WARRANTY_LINE_IN_NORMAL_FIXED_PRICE: "Engineering/Warranty Line can not be in a normal fixed price line.",
    NORMAL_LINE_IN_ENGINEERING_WARRANTY_FIXED_PRICE: "Normal line can not be in a Engineering/Warranty fixed price line.",
    CREATE_CONTRACT: "Create contract",
    WARRANTY_STATUS: "Warranty status",
    OVERVIEW: "Overview",
    EXPIRE: "Expire",
    CREATE_INTEGRATIVE_OFFER: "Create Integrative Offer",
    CREATE_EMPTY_DOC: "Create empty offer",
    CREATE_OFFER_BY_OFFER: "Create offer from existing offer",
    CREATE_OFFER: "Create Offer",
    CREATE_WARRANTY: "Create warranty",
    BILLS_OF_MATERIALS: "Bills of materials",
    HOURS_LIST: "Hours list",
    MATERIALS_LIST: "Materials list",
    APPROVAL_NOTES: "Approval notes",
    CLIENT_NOT_FOUND: "Client not found",
    WORK_FINISHED: "Work Finished",
    STATUS_TO_BE_PAID: "to be paid",
    STATUS_TO_BE_BILLED: "to be billed",
    STATUS_TO_BE_APPROVED: "to be approved",
    STATUS_IN_EXECUTION: "in execution",
    ERROR_SENDING_EMAIL: "Error sending email",
    SEND: "Send",
    VIA_EMAIL: "via email",
    PLEASE_INSERT_EMAIL: "Please insert an email",
    SUBJECT: "Subject",
    PLEASE_INSERT_SUBJECT: "Please insert a subject",
    CONTENT: "Content",
    SEND_ME: "Send me",
    QUICK_SEND: "Quick send",
    CLIENT_EMAIL: "Client email",
    CLIENT_CONTACT_EMAIL: "Client contact email",
    CHOOSE_RECIPIENT: "Choose Recipient",
    SEND_PDF: "Send PDF",
    DOCUMENT_CONTACT_TYPE_MISSING: "Missing document contact type",
    UNDER_MINIMUM_PRICE: "Under minimum price",
    TOO_MUCH_DISCOUNT: "Too much discount",
    MARGINALITY_CONSUNTIVE: "Marginality consuntive",
    RECEIVED_PLANS_DATE: "Received plans date",
    HAS_REPORT_ADDED: "has added a position",
    HAS_REPORTED_MORE: "has reported more than offered",
    HAS_REPORTED_MORE_AND_ADDED: "has reported more than offered and added position",
    HAS_FINSHED_REPORT: "has finished a work",
    HAS_OFFERED_UNDER_PRICE: "has offered under price",
    MINIMUM_PRICE: "minimum price",
    BREAK_CANT_BE_BIGGER_THAN_TOTAL_TIME: "Break can not be bigger than total time",
    HAS_WON_OFFER: "has won an offer",
    DAY_REPORT: "Day report",
    DETAILS: "Details",
    TOTAL_TIME: "Total time",
    CREATE_MEASURE: "create measure",
    MEASURES: "Measures",
    MATERIALS: "Materials",
    APPROVED_BY: "Approved by",
    ARE_YOU_SURE_CANT_BE_UNDONE: "Are you sure you want to save? This operation can not be undone.",
    MEASURED_BY: "Measured by",
    MEASURED: "Measured on the",
    TITLE: "Title",
    DEFAULT_BILL_ADDRESS: "Default bill address:",
    DEFAULT_SHIP_ADDRESS: "Default ship address:",
    ID: "Id",
    STATE: "State",
    TABLE: "Table",
    LIST: "List",
    APPALT: "Appalt",
    OFFER_NAME: "Offer Name",
    CLIENT_HAS_NO_ADDRESS: "Client has no address",
    BY_HAS_NO_ADDRESS: "By has no address",
    INVOICE_TO_HAS_NO_ADDRESS: "Invoice to has no address",
    FISCAL_CODE: "Fiscal Code",
    BUDGET_MATERIAL: "Budget Material",
    BUDGET_TIME: "Budget Time",
    DOC_LEVELS_EMPTY_NAME: "One or more levels of the document have empty name.",
    MINIMUM_VALUE: "Minimum value",
    MAXIMUM_VALUE: "Maximum value",
    BILL_PREVIEW_ORDER: "Bill preview for order",
    AFTER_DAY_REPORT: "After day report",
    BILL_PREVIEW: "Bill preview",
    HIDE_EMPTY: "Hide empty",
    AUTO_SELECT_TITLE: "Auto Select Title",
    AUTO_SELECT_TWINS: "Auto Select Twins",
    TOTAL_BEFORE_DISCOUNTS: "Total before discounts",
    NOT_VALID_NUMBER: "Not valid number",
    INSERT_WORK_SPACE_TO_SIGN_IN: "Insert your workspace name to login into your DRYapp account.",
    INSERT_YOUR_WORKSPACE_NAME: "Insert your workspace name...",
    PROCEED: "Proceed",
    INSERT_AT_LEAST_ONE_CHARACTER: "Insert at least one caracter",
    WORKSPACE_NOT_FOUND: "Workspace not found, check the spelling.",
    ERROR_STARTING_APP: "Error starting app.",
    CONTACT_SUPPORT: "Contact support",
    CUSTOM_WORKSPACE: "Custom workspace",
    PRIMARY_SERVER_URL: "Primary server URL",
    SECONDARY_SERVER_URL: "Secondary server URL",
    PRIMARY_URL_CANNOT_BE_EMPTY: "Primary server URL can not be empty.",
    SECONDARY_URL_CANNOT_BE_EMPTY: "Secondary server URL can not be empty.",
    CHECKING_WORKSPACE: "Checking workspace:",
    CHECKING_URL: "Checking URL:",
    SIGN_IN_INTO: "Sign in into workspace",
    ERROR_SIGNING_IN: "Error signing in",
    WRONG_USERNAME_OR_PASSWORD: "Wrong username or password",
    USERNAME_CANNOT_BE_EMPTY: "Username can not be empty",
    PASSWORD_CANNOT_BE_EMPTY: "Password can not be empty",
    ERROR_LOGOUT: "Error logging out",
    ACCESSIBLE: "Accessible",
    AQUIFER: "Aquifer",
    INTERNAL_NAME_CAN_BE_MAX_100: "Internal name can not be longther than 100 characters",
    YOUR_SESSION_IS_NOT_VALID: "Your session is not valid",
    ERROR_LOADING_DOCUMENT: "Error loading document",
    ERROR_LOADING_DAY_REPORTS: "Error loading day reports",
    ERROR_LOADING_DAY_REPORT: "Error loading day report",
    ERROR_CREATING_DAY_REPORT: "Error creating day report",
    ERROR_APPROVING_DAY_REPORT: "Error approving day report",
    ERROR_LOADING_ACTIVITIES: "Error loading activities",
    SELECT_BRANCH: 'Select branch',
    SELECT_CATALOG: 'Select catalog',
    SELECT_ADDRESS: 'Select address',
    SELECT_TYPE: 'Select type',
    SELECT_COUNTRY: 'Select country',
    SELECT_STATE: 'Select state',
    SELECT_STATUS: 'Select status',
    SELECT_VAT: 'Select VAT',
    CLICK_TO_SELECT: 'Click to select',
    WIN_OFFER: "Win Offer",
    CANCEL_ORDER: 'Cancel Order',
    ERROR_CANCELING_ORDER: 'Error canceling order',
    CONTINUE_CANCEL_ORDER_2: 'Are you sure you want to CANCEL the order?',
    CONTINUE_CANCEL_ORDER: 'Are you sure you want to cancel the order?',
    ERROR_LOADING_WEEK_REPORT: "Fehler beim Laden des Wochenberichts",
    ARE_YOU_SURE_YOU_WANT_UNAPPROVE_THE_DAY_REPORT: "Are you sure you want to unapprove the day report?",
    CONTINUE: "CONTINUE?",
    ERROR_UNAPPROVE_DAY_REPORT: "Error unapproving day report",
    ARE_YOU_SURE_YOU_WANT_DELETE_THE_DAY_REPORT: "Are you sure you want to delete the day report?",
    ERROR_DELETING_DAY_REPORT: "Error deleting day report",
    UNAPPROVE: "Unapprove",
    ERROR_UPLOADING_FILE: "Error uploading file.",
    SELECT_FILE_TYPE_BEFORE_UPLOADING: "Please select file type before uploading file.",
    ERROR_UPDATING_FILE: "Error updating file",
    ERROR_UPLOADING_SIGNATURE: "Error uploading signature",
    KEEP_ORDER: "Keep Order",
    PIT_WALL_M2: 'Pit, Wall, Slab tot m2:',
    TOTAL_VAT_EXCL: 'Total VAT excl.',
    ROUNDING: 'Rounding',
    TOTAL_VAT_INCL: 'Total VAT incl.',
    PAYMENT_DATE: 'Payment Date',
    PAYMENT_AMOUNT: 'Payment Amount',
    BETON: 'Beton',
    FINANCIAL_REPORT: 'Financial Report',
    FIRST_PAGE: 'First page',
    DAY_REPORT_EMPTY_CONTINUE: "Day report empty, are you sure you want to continue?",
    ERROR_IN_TIME_MANAGEMENT: "Error in time management.",
    DOC_FOOTER_SET_EQUAL_VAT: "Set unique VAT",
    DAY_TOTAL_TIME: "Day total time",
    SHOW_WEEK_REPORT: "Show week report",
    INDUSTRY: "Courtesy formula",
    SELECT_INDUSTRY: "Select",
    MEASURE_APPROVED_OF: "Approved {{approved}} of {{of}}",
    EDIT_ORDER_DETAILS: "Edit order details",
    WARRANTY_FOR: 'Warranty for',
    SELECT_CONTRACT: 'Select contract',
    CREATE_CUSTOM_POSITION_ERROR: "Error creating custom position",
    ERROR_CONNECTING_SERVER: "Error downloading basic data",
    DATE_BEFORE_WEEK_START: "Date of the previous week not allowed.",
    CHOOSE_ADDRESS_AND_LOCATION: "Choose address and location",
    MAP_MODAL_IF_YOU_WANT_TO_SAVE_ADDRESS: "If you want to save the address and move the pointer on the map, click \"Save\".",
    SAVE_ADDRESS: "Save Address",
    ABSENCE: "Absence",
    SELECT_ABSENCE_TYPE: "Select absence type",
    ERROR_INSERT_A_START_TIME_SMALLER_THAN_END_TIME: "Error: Insert a start time smaller than end time",
    ABSENCE_DATE_OUT_OF_LIMIT: "Absence date out of limit",
    ERROR_UPDATING_ABSENCE: "Error updating absence",
    ERROR_CREATING_ABSENCE: "Error creating absence",
    ERROR_DELETING_ABSENCE: "Error deleting absence",
    PLEASE_CHOOSE_AN_ABSENCE_TYPE: "Please choose an absence type",
    OFFICE_NOTES: "Office notes",
    UPDATE_ABSENCE: "Update absence",
    CREATE_ABSENCE: "Create absence",
    TRAVEL: "Travel",
    WORK: "Work",
    NO_CATALOGS: "No catalogs",
    RELOAD_CATALOGS: "Reload catalogs",
    NO_CATALOG_SELECTED: "No catalog selected",
    PHOTOS: "Photos",
    REVERSE: "Reverse",
    ORDER_BY: "Order by",
    MEASURE: "Measures",
    PUBLIC_PROCUREMENT: "Public procurement",
    CAN_NOT_APPROVE_REPORT_USE_MEASURE: "Cannot approve report, use measures.",
    UNAPPROVE_MEASURE: "Unapprove measure",
    ERROR_UNAPPROVING_DAY_REPORT: "Error unapproving day report",
    OPEN_AND_ASSIGNED_TO_WORKER: 'Open and assigned to worker',
    NORMAL_INVOICE: 'Normal invoice',
    ADVANCE_INVOICE: 'Advance invoice',
    MIDDLE_INVOICE: 'Middle invoice',
    AMOUNT_INVOICE: 'Amount invoice',
    SYNTHETIC: 'Synthetic',
    DETAILS_WITHOUT_PRICES: 'Details without prices',
    DETAILS_WITH_PRICES: 'Details with prices',
    FINAL_INVOICE: 'Final invoice',
    INVOICE_NUMBER: 'Invoice number',
    ADD_SIMILAR_POSITIONS: 'Add similar positions',
    DOC_TEST_CONTRACT_NAME_EXISTS: 'Contract name already exists',
    ALL_LEVELS: 'All levels',
    BILL_EMAIL: 'Bill email',
    ORIGINAL_OFFER_YEAR: 'Original offer year',
    FILTER: 'Filter',
    CHOOSE_OTHER: 'Choose other',
    OFFLINE: 'Offline',
    NO_ITEMS_TO_SYNC: 'No items to sync',
    BEGIN: 'Begin',
    END: 'End',
    NO_INTERNET_CONNECTION_NO_SAVED_DATA: 'No internet connection, no saved data',
    DAY_REPORT_SPLIT_BY: 'Day report split by {{id}}.\n{{notes}}',
    DAY_REPORT_SPLIT_WITH: 'Day report split with {{ids}}.\n{{notes}}',
    SAVE_AND_SPLIT: 'Save and split',
    CHOOSE_RESOURCES: 'Choose resources',
    APPROVE_AND_CLOSE: 'Approve and close',
    OTHER: 'Other',
    THEORICAL: 'Theorical',
    WRONG_PREVIOUS_TWIN: 'Wrong previous twin, maximum value: {{max}}',
    PLEASE_CHOOSE_A_WORK_GROUP: 'Please choose a work group',
    PLEASE_CHOOSE_A_LEVEL: 'Please choose a level',
    ADD_NOTES: 'Add notes',
    YOU_USED_A_LOT_OF_TIME_SOMETHING_HAPPENED: 'You used more time than expected, did something happened?',
    ERROR_PLACING_MATERIALS_AND_TIME: 'Error placing materials and time',
    SURE_TO_LOGOUT: 'Are you sure you want to logout?',
    SURE_TO_LOGOUT_DESC: 'You will be logged out from the app. All offline documents will be lost.',
    ADVANCEMENTS_DETAILS_LINE: 'Advancements details for line',
    NOT_VALID_CONTRACT: 'Not valid contract',
    APPROVABLE: 'Approvable',
    PREVIOUS_MEASURE: 'Previous measure',
    MEASURE_HELP: 'Measure help',
    MEASURE_HELP_DESC: 'Columns can be closed by clicking on the column title.\n\nColumns:\n- Sold: Quantities shown in offer.\n- Previous measure:\n   - Done: Done by worker.\n   - Appoved: Already approved.\n   - Billable: Quantity set for billable in previous approvals.\n- Billed: Already billed.',
    APPROVED_HOURS: 'Approved hours',
    BUDGET_HOURS: 'Budget hours',
    OFFLINE_MEASURES: 'Offline measures',
    ERROR_LOADING_OFFLINE_MEASURES: 'Error loading offline measures',
    ERROR_DELETING_OFFLINE_MEASURE: 'Error deleting offline measure',
    MEASURES_HELP: 'Measures help',
    MEASURES_HELP_TEXT: 'Measures are used to approve day reports and prepare invoices.\n\nIn this view are shown the list of measures for the selected contract, can be downloaded the measure excel file for the measures that have to be created, via the blue download button. The measures can be created by clicking on the blue plus button.\n\nOn the existing measures can be seen the number of the created measure, the creation date, the person who has created them, the number of day reports approved via the choosen measure.\nIf there is an invoice for this measure is shown with the bill icon, to open the invoice click on the icon. If there is a credit note for the invoice is shown a document icon with a minus.\nFor every measure can be downloaded the excel file with the details.\n\nBy default is proposed one travel for each day. If in one thay there have been more travels, will be shown only one.',
    MEASURES_HELP_INVOICE: 'Invoice',
    MEASURES_HELP_CREDIT_NOTE: 'Credit note',
    MEASURES_HELP_DOWNLOAD: 'Download',
    MEASURES_HELP_DAY_REPORT: 'Day report',
    ERROR_SAVING_WORK_PROGRESS_STATUS: 'Error saving work progress status',
    CONSTRUCTION_SITE_NOTES: 'Construction site notes',
    CONTRACT_NOTES: 'Contract notes',
    OFFER_NOTES: 'Offer notes',
    NOTES_FOR_DAY_REPORT: 'Notes for day report',
    NO_NOTES_TO_COPY: 'No notes to copy',
    ERROR_LOADING_DOCS_OFFLINE: 'Error loading documents offline',
    ERROR_DELETING_DOCS_OFFLINE: 'Error deleting documents offline',
    DOCS_OFFLINE: 'Documents offline',
    CREATED: 'Created',
    UPDATED: 'Updated',
    ERROR_UPDATING_DOCS_OFFLINE: 'Error updating document offline',
    ERROR_OPENING_OFFLINE_DOC: 'Error opening offline document',
    NOT_AVAILABLE_OFFLINE: 'Not available offline',
    MAPS: 'Maps',
    ADDRESS: 'Address',
    SELECT_DOCUMENT_ORDER: 'Select document order',
    NO_VAT: 'No VAT',
    LANGUAGE: 'Language',
    ALL_DAY: 'All day',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    SETTINGS: 'Settings',
    SHOW_DAYS_BY_WEEK: 'Show days by week',
    YOU_ARE_NOT_AUTHORIZED_TO_CREATE_CLIENTS: 'You are not authorized to create clients',
    YOU_ARE_NOT_AUTHORIZED_TO_UPDATE_CLIENTS: 'You are not authorized to update clients',
    SAVED_OFFLINE: 'Saved offline',
    DRAFT: 'Draft',
    OFFERS_TO_CHECK: 'Offers to check',
    CONSTRUCTION_SITE_NAME: 'Construction site name',
    CATALOG: 'Catalog',
    CONTRACT: 'Contract',
    DAY_REPORT_WRONG_PREVIOUS_TWINS: 'Day report wrong previous twins, are you sure you want to save?',
    DEPARTURE_TIME_TOO_EARLY: 'Departure time too early, insert the departure hour not the travel time',
    PLEASE_SET_ALL_DOCUMENT_CONTACT_TYPES: 'Please set all document contact types',
    DOC_DRAFTED_CREATE: 'had created a draft document',
    DOC_DRAFTED_UPDATE: 'had updated a draft document',
    NO_CONTRACTS_FOR_CONSTRUCTION_SITE: 'No contracts for this construction site',
    DUPLICATE_DOC: 'Duplicate document',
    DUPLICATE_CONTRACT: 'Duplicate contract',
    DUPLICATE_IN_NEW_CONTRACT: 'Duplicate in new contract',
    DUPLICATE_CONSTRUCTION_SITE: 'Duplicate construction site',
    DUPLICATE_IN_NEW_CONSTRUCTION_SITE_AND_NEW_CONTRACT: 'Duplicate in new construction site and new contract',
    CHANGE_DESTINATION: 'Change destination',
    MOVE: 'Move',
    SEARCH_CLIENT_TO_IMPORT_FROM_GOOGLE: 'Search client to import from google',
    ERROR_GETTING_CLIENT_DETAILS: 'Error getting client details',
    IMPORT: 'Import',
    EXTERNAL_WORKS_INVOICES: 'External Works Invoices',
    SUPPLIER: 'Supplier',
    REFERENCE: 'Reference',
    INVOICE_DATE: 'Invoice date',
    INSERTED_BY: 'Inserted by',
    EXTERNAL_WORK_INVOICE: 'External Work Invoice',
    NO_DAY_REPORT: 'No day reports',
    DAY_REPORT_DATE: 'Day report date',
    DAY_REPORT_NOTES: 'Day report notes',
    NEW: 'New',
    CHOOSE_DAY_REPORT_FOR_EXTERNAL_WORK_INVOICE: 'Choose day report for external work invoice',
    CHOOSE_EXTERNAL_WORK_INVOICE: 'Choose external work invoice',
    HOURS_LIST_BUDGET_EFFECTIVE: 'Hours list budget/effective',
    INDUSTRIAL_ACCOUNTING: 'Construction site accounting',
    CHOOSE_CONTRACTS: 'Choose contracts',
    DOWNLOAD: 'Download',
  },
  it: {
    SERVER: "Server",
    WRONG_USERNAME_PASSWORD: "Username e password errati",
    INVALID_LOGIN_TITLE: "Login Invalido",
    LOGOUT: "Logout",
    SEARCH: "Cerca",
    SHOW_PRICE_LEVEL_NO_PRICE: "Nascondi prezzi",
    SHOW_PRICE_LEVEL_CLIENT: "Prezzi cliente",
    SHOW_PRICE_LEVEL_INTERNAL: "Prezzi interni",
    SHOW_PRICE_LEVEL_PLACEHOLDER: "Seleziona la visualizzazione dei prezzi",
    PAGE_SIZE_TEXT: "Elementi per pagina",
    DOC_NAME: "Documento",
    DOC_TYPE: "Tipo Documento",
    DOC_DISTANCE: "Distanza",
    DOC_CREATE_DATE: "Data creazione",
    UPDATE_DATE: "Data modifica",
    CREATED_BY: "Creato da",
    DOC_UPDATED_BY: "Modificato da",
    DOC_ADD_CONTACT: "Aggiungi cliente",
    CLIENT: "Cliente",
    DOC_BY: "Presso",
    DOC_OFFER_NAMED_TO: "Offerta intestata a",
    DOC_INVOICE_TO: "Fatturare a",
    BY: "Presso",
    OFFER_NAMED_TO: "Offerta intestata a",
    INVOICE_TO: "Fatturare a",
    DOC_CLIENT_CODE: "Codice",
    NAME: "Nome",
    DOC_CLIENT_CONTACT: "Contatto",
    PHONE: "Telefono",
    CATALOG_DROPDOWN: "Catalogo",
    CONTRACT_NAME: "Contratto",
    CONSTRUCTION_SITE: "Cantiere",
    CONSTRUCTION_SITE_TYPE: "Tipo Cantiere",
    CONSTRUCTION_SITE_ADDRESS: "Indirizzo",
    CONSTRUCTION_SITE_BRANCH: "Filiale",
    CONSTRUCTION_SITE_MAPS: "Maps",
    DELETE: "delete",
    WIN: "Vinci",
    LOSE: "Perdi",
    DUPLICATE: "Duplica",
    PDF: "PDF",
    PRINT: "Stampa",
    EMAIL: "Email",
    VERSIONS: "Versioni",
    CHOOSE_CONSTRUCTION_SITE: "Scegliere Cantiere",
    CREATE_NEW_CONSTRUCTION_SITE: "Crea nuovo cantiere",
    CHOOSE_CONTRACT: "Scegliere Contratto",
    CREATE_NEW_CONTRACT: "Crea nuovo contratto",
    STATUS_OPEN: "Aperto",
    STATUS_LOST: "Perso",
    OFFER: "Offerta",
    STATUS_DONE: "Finito",
    STATUS_CANCELLED: "Annullato",
    STATUS_NOT_AWARDED: "Non ricevuto",
    STATUS_OLD_VERSION: "Versione precedente",
    STATUS_WON: "Vinta",
    STATUS_OTHER: "Altro",
    LEVELS: "Livelli",
    LEVEL_PRINT_FLAG: "Stampa Livelli",
    NEW_LEVEL: "Nuovo livello",
    CREATE: "Crea",
    UPDATE: "Aggiorna",
    CANCEL: "Cancella",
    CLOSE: "Chiudi",
    SEARCH_CATEGORY_ALL: "tutto",
    SEARCH_CATEGORY_OFFERS: "offerte",
    SEARCH_CATEGORY_SITES: "cantieri",
    SEARCH_CATEGORY_CLIENTS: "clienti",
    SEARCH_RESULT: "Risultati",
    ADVANCED_TOGGLE_LABEL: "Ricerca avanzata",
    RESULTS_LABEL: "Risultati per",
    CLIENT_TYPE: "tipo cliente",
    CREATE_NEW_REPORT: "Crea nuovo report",
    OFFERS: "Offerte",
    INVOICES: "Fatture",
    CONSTRUCTION_SITE_ACCOUNTING: "Contabilità di cantiere",
    WORK_REPORTS: "Report Lavori",
    PHOTO: "Foto",
    OFFER_CONFIGURATOR_TABLE: "Tabella",
    OFFER_CONFIGURATOR_LIST: "Lista",
    FILTER_BY: "Filtra per",
    NUMBER: "Numero",
    STATUS: "Stato",
    CONSTRUCTION_SITES: "Cantieri",
    DOC_CONTACTS: "Contatti",
    CLIENT_TYPE_COMPANY: "Impresa",
    CLIENT_TYPE_PERSON: "Persona",
    SELECT_CONTACT_TYPE: "Seleziona il tipo di contatto",
    FIELD_IS_MANDATORY_MESSAGE: "Questo campo è obbligatorio",
    MORE_OPTIONS_MENU_LABEL: "Opzioni avanzate",
    GROUP: "Gruppo",
    BRANCH: "Filiale",
    VAT: "IVA",
    MOBILE_PHONE: "Telefonino",
    STREET_LINE: "Indirizzo",
    STREET_LINE_1: "Indirizzo riga 1",
    STREET_LINE_2: "Indirizzo riga 2",
    POSTAL_CODE: "Codice Postale",
    CITY: "Città",
    REGION: "Regione",
    COUNTRY: "Paese",
    CHOOSE_CLIENT: "Seleziona un cliente",
    EDIT_CLIENT: "Modifica un cliente",
    HAS: "ha",
    CONTACTS_LOW: "contatti",
    CREATE_NEW_CONTACT: "Crea nuovo contatto",
    CREATE_NEW_CLIENT: "Crea nuovo cliente",
    CREATE_EDIT_NEW_CONTACT: "Crea/Modifica nuovo cliente",
    FIRST_NAME: "Nome",
    LAST_NAME: "Cognome",
    SAVE: "Salva",
    NEW_CONTACT: "Nuovo contatto",
    NEW_BUSINESS_PARTNER: "Nuovo Cliente",
    BUSINESS_PARTNER: "Cliente",
    SHOW_CLIENT_LIST: "Mostra elenco clienti",
    POSITION: "Posizione",
    EXPAND_ALL: "Espandi Tutto",
    NEW_ADDRESS: "Nuovo indirizzo",
    NO_ENTERED_NAME: "Nessun nome inserito",
    EDIT_LOW: "modifica",
    FILE: "File",
    WELCOME: "Benvenuto",
    USERNAME: "Nome utente",
    PASSWORD: "Password",
    SIGN_IN: "Accedi",
    PERMISSION_NOT_GRANTED: "Permessi non concessi",
    ALLOW_THE_APP_TO_USE_LOCATION_SERVICES:
      "Consenti all'app di utilizzare i servizi di localizzazione",
    NO_OFFER_FOUND_FOR_CLIENT: "Nessuna offerta trovata",
    NO_CONSTRUCTION_SITE_FOUND_FOR_CLIENT: "Nessun cantiere trovato",
    NO_INVOICE_FOUND_FOR_CLIENT: "Nessuna fattura trovata ",
    CODE: "Codice",
    DESCRIPTION: "Descrizione",
    QUANTITY: "Quantità",
    UOM: "UdM",
    PRICE: "Prezzo",
    PERCENT: "%",
    TOTAL: "Totale",
    TYPE: "Tipo",
    FLAT_RATE: "A corpo",
    ADD_A_ROW: "Aggiungi una riga",
    READ_LEVEL: "Permessi di lettura",
    INTERNAL: "Interno",
    ADMIN: "Admin",
    WORKER: "Operaio",
    GUEST: "Ospite",
    DISCOUNTS: "Sconti",
    DISCOUNT: "Sconto",
    STANDARD: "Standard",
    ADD_DISCOUNT: "Aggiungi sconto",
    WITHOUT_IVA: "IVA esclusa",
    INSERT_A_COMMENT: "Inserisci un commento",
    TO_BE_AGREED: "da concordare",
    EDIT_CONTACT: "Modifica contatto",
    UNIT_PRICE: "Prezzo d'unità",
    INSERT_A_QUANTITY: "Inserisci una quantità",
    SAVE_IN: "Salva nelle",
    MY_POSITIONS: "mie posizioni",
    NAME_OF_THE_POSITION: "Nome della posizione",
    SELECT_UNIT_OF_MEASURE: "Seleziona un unità di misura",
    UNIT_OF_MEASURE: "Unità di misura",
    SHOW_LEVELS: "Mostra livelli",
    EXECUTE: "Esegui",
    INVOICED: "Fatturato",
    CALCULATED: "Calcolato",
    CREATE_POSITION: "Crea posizione",
    SELECT_WHAT_TO_ADD: "Seleziona cosa aggiungere",
    INSERT_A_POSITION: "Inserisci una posizione",
    INSERT_A_TEXT: "Inserire un testo",
    INSERT_A_ENGINEERING_WARRANTY_BLOCK_PRICE:
      "Inserire un blocco di prezzo ingegneria/garanzia",
    INSERT_A_NORMAL_BLOCK_PRICE: "Inserire un blocco di prezzo normale",
    TABLE_VIEW: "Vista tabella",
    PLEASE_SELECT_A_CATALOG: "Selezionare un catalogo",
    LEVEL: "Livello",
    TYPOLOGY: "Tipologia",
    NO_DISCOUNT: "Neessuno sconto",
    TOTAL_WITH_DISCOUNTS: "Totale con sconti",
    RESOURCE_NAME: "Nome della risorsa",
    VERSION: "Versione",
    HOURS: "Ore",
    MINUTES: "Minuti",
    CHOOSE_A_LETTER: "Seleziona una lettera",
    CHOOSE_A_VAT: "Seleziona una IVA",
    CHOOSE_A_CATALOG: "Seleziona un catalogo",
    CHOOSE_A_SUM_TYPE: "Seleziona un tipo di somma",
    ALL: "Tutti",
    OPEN_AND_NOT_ASSIGNED: "Aperti e non assegnati",
    OPEN_AND_ASSIGNED: "Aperti ed assegnati",
    SHARE: "Condividi",
    TODAY: "Oggi",
    PLANNING: "Pianificazione",
    TOTAL_QUANTITY: "Quantità totale",
    FOR: "Per",
    TO_CONCORD: "Da concordare",
    CLEAR: "Cancella",
    CONFIRM: "Conferma",
    SIGN_ABOVE: "Firma qui sopra",
    TODO: "Da fare",
    SIGN: "Segno",
    WARNING: "Attenzione",
    ARE_YOU_SURE: "Sei sicuro?",
    REPORT_MODAL_CLOSE_MESSAGE: `Premendo "Sì" cancellerete questo rapporto e tutti i suoi dati`,
    SI: "Sì",
    NO: "No",
    TRIP_TIME: "Tempo di viaggio",
    NO_AUTO: "No auto",
    BREAK: "Pausa",
    START_TIME: "Ora di inizio",
    START_TIME_ON_SITE: "Inizio in cantiere",
    END_TIME: "Ora di fine",
    END_TIME_ON_SITE: "Fine in cantiere",
    PREVIEW: "Anteprima",
    ADD_WORK: "Aggiungi lavoro",
    NOTE: "Note",
    SIGNED_BY: "Firmato da",
    DATA: "Data",
    LUOGO: "Luogo",
    SIGNATURE: "Firma",
    CLICK_HERE_TO_SIGN: "Clicca qui per firmare",
    SAVE_AND_CLOSE: "Salva e chiudi",
    SIGNATURE_NOT_AVAILABLE: "Usa un iPad per firmare",
    YES: "Sì",
    NOTES: "Note",
    DATE: "Data",
    PLACE: "Luogo",
    REMOVE: "Rimuovi",
    OPEN_CONSTRUCTION_SITE: "Apri cantiere",
    MODIFY: "Modifica",
    NO_INTERNET_CONNECTION: "Nessuna connessione ad internet.",
    SELECT_VERSION: "Scegli la versione",
    SELECT_LANGUAGE: "Seleziona la lingua",
    SELECT_CURRENCY: "Seleziona la valuta",
    SELECT_SALE_EMPLOYEE: "Seleziona venditore",
    NO_EARLIER_VERSION_AVAILABLE: "Nessuna versione precedente disponibile",
    SEE_OLDER_VERSIONS: "Vedi versioni precedenti",
    WOULD_YOU_CREATE_NEW_VERSION:
      "Vuoi creare una nuova versione di questo documento?",
    CREATE_NEW_VERSION: "Crea nuova versione",
    UPDATE_VERSION: "Aggiorna questa versione",
    CLOSE_WITHOUT_SAVING: "Chiudere senza salvare?",
    ARE_YOU_SURE_CLOSE_WITHOUT_SAVING:
      "Sei sicuro di voler chiudere senza salvare?",
    NEW_DOCUMENT: "Nuovo Documento",
    DOCUMENTS: "Documenti",
    DOCUMENT_NUMBER: "Numero documento",
    WHO_WON_OFFER: "Chi ha vinto l'offerta?",
    COMPETITOR: "Competitor",
    LINE_TOTAL: "Totale riga",
    ORDER: "Ordine",
    NO_ORDER_FOR_THIS_CONTRACT: "Nessun Ordine per questo contratto",
    BILLED: "Fatturato",
    SOLD: "Venduto",
    NOT_BILLABLE: "non fatturabile",
    SHOW_AS_MONETARY_VALUE: "mostra come valore monetario",
    EXECUTED: "eseguito",
    ADD_NEW_POSITION: "aggiungere nuova posizione",
    EDIT: "modifica",
    PRICE_LIST: "per",
    DIGITAL: "Digitale",
    VERSION_ALREADY_CHANGED: "Versione già modificata",
    VERSION_ALREADY_CHANGED_DESC: "{0} {1} ha già modificato l'offerta il {2} alle {3}. Sei sicuro di voler sovrascrivere le modifiche?",
    CLOSE_PREVIEW: "Chiudi anteprima",
    EDIT_CONTRACT: "Modifica contratto",
    CONTRACT_CHANGED: "Contratto modificato",
    CONTRACT_CHANGED_DESC: "Sei sicuro di voler annullare le modifiche?",
    NOT_SAFE_OPERATION: "Operazione pericolosa",
    ARE_YOU_SURE_TO_CHANGE_CONSTRUCTION_SITE_ID_DESC: "Sei sicuro di volere cambiare il cantiere per questo contratto?\nQuesta operazione è potenzialmente distruttiva.",
    NOT_VALID_CONSTRUCTION_SITE_ID: "ID cantiere non valido",
    UPDATE_CONTRACT: "Aggiorna contratto",
    NOT_VALID_CONTRACT_NAME: "Nome del contratto non valido",
    CONSTRUCTION_SITE_CHANGED: "Cantiere modificato",
    CONSTRUCTION_SITE_CHANGED_DESC: "Sei sicuro di voler annullare le modifiche?",
    EDIT_CONSTRUCTION_SITE: "Modifica cantiere",
    POSSIBLE_CONSTRUCTION_SITE_NUMBER: "Numero cantiere potenziale",
    CONSTRUCTION_SITE_NUMBER: "Numero cantiere",
    UPDATE_CONSTRUCTION_SITE: "Aggiorna cantiere",
    ERROR_UPDATING_CONSTRUCTION_SITE: "Errore aggiornando il cantiere",
    ERROR_UPDATING_CONTRACT: "Errore aggiornando il contratto",
    ERROR_CONSTRUCTION_SITE: "Errore scardicando il cantiere",
    SOME_FIELDS_ARE_NOT_VALID: "Alcuni dati non sono validi",
    ERROR_LOADING_ORDER: "Errore scaricando l'ordine",
    SOME_FIELDS_MIGHT_BE_NOT_COMPLETE: "Alcuni campi sono incompleti, sei sicuro di voler procedere?",
    ERRORS: "Errori",
    WARNINGS: "Avvertimenti",
    INFO: "Info",
    NO_PHOTOS: "Nessuna foto",
    CHOOSE: "Seleziona",
    NO_CONSTRUCTION_SITE_SELECTED: "Nessun cantiere selezionato, perfavore selezionarne uno.",
    CONSTRUCTION_SITE_HAS_NO_CONTRACTS: "Il cantiere non ha contratti, per favore creare uno.",
    CLIENTS: "Clienti",
    ERROR_LOADING_CLIENTS: "Errore di download clienti",
    ERROR_LOADING_CLIENTS_DESC: "Errore durante il donwload dei clienti.",
    CHOOSE_CONTACT: "Selezionare un contatto dal cliente.",
    CLIENT_HAS_NO_CONTACTS: "Il cliente non ha contatti.\nModificare il cliente per aggiungere un cliente\noppure procedere.",
    NO_CLIENT_SELECTED: "Cliente non selezionato.",
    CUSTOM_CONTACT_CHANGED: "Contatto del documento modificato",
    CUSTOM_CONTACT_CHANGED_DESC: "Sei sicuro di voler annullare le modifiche?",
    CUSTOM_CONTACT: "Contatto del documento",
    ADD_NEW_DOCUMENT_CONTACT: "Aggiunguere un nuovo contatto",
    CONTACT_TYPE: "Tipo di contatto",
    CONTACT: "Contatto",
    CONTACTS: "Contatti",
    SELECT_DOCUMENT_TYPE: "Seleziona il tipo di documento",
    SELECT_DOCUMENT_TYPE_DESC: "Seleziona il tipo di documento prima di creare un contatto del documento.",
    GENERAL: "Generale",
    SUM_TYPE: "Tipo di somma",
    EDIT_LINE: "Modifica linea",
    INTERNAL_NAME: "Nome interno",
    ADD_POSITION: "Aggiungi posizione",
    ADD_TEXT_LINE: "Aggiungi linea di testo",
    INSERT_FLAT_RATE: "Aggiungi linea di \"A corpo\"",
    INSERT_FLAT_RATE_ENGINEERING_WARRANTY: "Aggiungi linea di \"A corpo\" engineering/garanzia",
    DUPLICATE_LINE: "Duplica linea",
    TEXT: "Testo",
    ERROR_CREATING_CLIENT: "Errore di creazione cliente",
    ERROR_CREATING_CLIENT_DESC: "Errore durante la creazione del cliente, perfavore riprovare.",
    CLIENT_CHANGED: "Cliente modificato",
    CLIENT_CHANGED_DESC: "Sei sicuro di voler annullare le modifiche?",
    CLIENT_ERROR: "Dati del cliente non validi",
    CLIENT_ERROR_DESC: "Alcuni dati del cliente non sono validi, correggerli per poter procedere.",
    CLIENT_WARNINGS: "Dati del cliente non completi",
    CLIENT_WARNINGS_DESC: "Alcuni dati del cliente non sono completati, sei sicuro di voler procedere?",
    ADDRESSES: "Indirizzi",
    NEW_BLOCK: "Nuovo blocco",
    NOT_AVAILABLE_FOR_FIXED_PRICE: "Impostazione non disponibili per posizioni a corpo.",
    PRICING: "Prezzi",
    VARIABLE: "Variabile",
    TOTAL_TO_CONCORD: "Totale da concordare",
    NORMAL: "Normale",
    TOTAL_TYPE: "Tipo totale:",
    DISCOUNT_NUMERIC: "5.-",
    DISCOUNT_PERCENTAGE: "%",
    TOTAL_WITH_DISCOUNT: "Totale con sconti",
    TOTAL_WITH_VAT: "Totale con IVA",
    NEW_DISCOUNT: "Nuovo sconto",
    MARGINALITY: "Marginalità",
    BASIC_OFFER: "Offerta base",
    MATERIAL_COST: "Costo materiale",
    HUMAN_WORK_COST: "Costo lavoro",
    EXTERNAL_WORK_COST: "Costo lavori esterni",
    TOTAL_COSTS: "Totale costi",
    MATERIAL_REVENUE: "Ricavi materiale",
    HUMAN_WORK_REVENUE: "Ricavi lavoro",
    EXTERNAL_WORK_REVENUE: "Ricavi lavori esterni",
    TOTAL_REVENUE: "Totale Ricavi",
    MARGIN_BASIC_OFFER: "Margine Offerta base",
    WARRANTY_ENGINEERING: "Garanzia / Engineering",
    ENGINEERING_HOURS_COST: "Costi ore Engineering",
    ENGINEERING_REVENUE: "Ricavi Engineering",
    WARRANTY_COSTS_MATERIAL: "Costi Garanzia - materiale",
    WARRANTY_COSTS_HUMAN_WORK: "Costi Garanzia - lavoro",
    WARRANTY_REVENUE: "Ricavi Garanzia",
    MARGIN_ENGINEERING_WARRANTY: "Margine Engineering Garanzia",
    GENERAL_RESULT: "Risultato generale",
    PERCENTAGE_DIFFERENCE: "Differenza %",
    REMAINING_WARRANTY_YEARS: "Anni rimanenti di garanzia",
    CREATE_DOC_WARNINGS: "Sei sicuro di voler crare il documento?\nCi sono degli avvertimenti!",
    UPDATE_DOC_WARNINGS: "Sei sicuro di voler aggiornare il documento?\nCi sono degli avvertimenti!",
    ERROR_UPDATING: "Errore di aggiornamento.",
    ERROR_UPDATING_DESC: "Error durante l'aggiornamento dello stato dell'offerta.",
    NOT_IMPLEMENTED: "Feature non ancora implementata.",
    ERROR: "Errore",
    ORDER_ERROR_DOWNLOADING: "Errore durante il download dell'offerta!",
    ERROR_CREATING_DOC: "Errore di creazione documento.",
    ERROR_UPDATING_DOC: "Errore di aggiornamento documento",
    ERROR_UPDATING_OFFER_STATUS: "Errore aggiornado lo stato dell'offerta.",
    ERROR_OPENING_DOCUMENT: "Errore di apertura documento.",
    ERROR_OPENING_DOCUMENT_DESC: "Errore di apertura documento, riprovare.",
    MARGINALITY_DOC_CONSTRUCTION_SITE: "Mostra marginalità per documento o cantiere intero.",
    DOCUMENT: "Documento",
    DOC_TEST_MISSING_CONTRACT_CREATE: "contratto mancante, perfavore creare un contratto",
    DOC_TEST_MISSING_CONTRACT_CREATE_OR_UPDATE: "contratto mancante, perfavore creare o selezionare un contratto",
    DOC_TEST_MISS_CONTRACT_NAME: "nome del contratto mancante, perfavore inserire un nome per il contratto",
    DOC_TEST_MISSING_DOCUMENT: "errore, documento mancante, perfavore chiudere l'applicazione (anche da quelle in background) e ri aprirla. L'errore è stato tracciato.",
    DOC_TEST_MISSING_DOCUMENT_NAME: "nome del documento mancante, perfavore inserire un nome per il documento",
    DOC_TEST_MISSING_DOCUMENT_TYPE: "tipo del documento mancante, perfavore selezionare un tipo per il documento",
    DOC_TEST_MISSING_DOCUMENT_LANGUAGE: "lingua del documento mancante, perfavore selezionare la lingua per il documento",
    DOC_TEST_MISSING_DOCUMENT_CURRENCY: "valuta del documento mancante, perfavore selezionare la valuta per il documento",
    DOC_TEST_MISSING_DOCUMENT_CATALOG: "catalogo del documento mancante, perfavore selezionare il catalogo per il documento",
    DOC_TEST_MISSING_DOCUMENT_CLIENT: "cliente del documento mancante, perfavore selezionare o creare un cliente per il documento",
    DOC_TEST_MISSING_DOCUMENT_LEVELS: "livelli mancanti per il documento, perfavore creare almeno un livello",
    DOC_TEST_MISSING_LINES: "il documento non ha righe, perfavore inserire almeno una riga",
    MISSING_CONSTRUCTION_SITE_CREATE_OR_CHOOSE: "cantiere mancante, crea o scegli un cantiere",
    MISSING_CONSTRUCTION_SITE_NAME: "manca il nome del cantiere, inserire un nome per questo cantiere",
    MISSING_CONSTRUCTION_SITE_TYPE: "tipo di cantiere mancante, selezionare un tipo di cantiere",
    MISSING_CONSTRUCTION_SITE_BRANCH: "Filiale del cantiere mancante, selezionare una filiale del cantiere",
    MISSING_CONSTRUCTION_SITE_MAPS: "indirizzo google maps del cantiere non definito, questo potrebbe non consentire la geolocalizzazione",
    MISSING_CONSTRUCTION_SITE_ADDRESS: "indirizzo del cantiere non definito",
    UPLOAD_NEW_FILE: "Caricare nuovo file",
    SELECT: 'Seleziona',
    UPLOAD: 'Carica',
    SIZE: 'Dimensione',
    TIME_AND_MATERIAL: "Ore e materiali",
    TIME: "Tempo",
    REPORT_TO_APPROVE: "Rapporti da approvare",
    RESOURCE: "Risorsa",
    REPORT_HOURS: "Ore rapporto",
    MISSING_TIME: "tempo mancante",
    ADD: "Aggiungere",
    DISABLE_TWIN_ID_AUTOMATION: "Disabilitare posizioni gemelle",
    TWIN_ID_AUTOMATION_DISABLED: "Automazione posizioni gemelle disabilitata",
    APPROVED: "Approvato",
    BILLABLE: "Fatturabile",
    APPROVE: "Approva",
    APPROVED_DURATION: "Tempo approvato",
    TO_BE_APPROVED: "Da approvare",
    NOT_ALLOWED: "Risorsa non accessibile",
    NOT_ALLOWED_DESC: "La risorsa richiesta non è accessibile.",
    DAY_REPORTS: "Rapporti di lavoro giornalieri",
    FILTER_TO_APPROVE: "Filtra per da approvare",
    APPROVED_QUANTITY: "Quantità approvata",
    FILES: "Files",
    REPORT: "Rapporto di lavoro",
    DONE: "Eseguito",
    PLANNED_FOR_TODAY: "Pianificato per oggi",
    YESTERDAY: "Ieri",
    REQUESTED_MARGINALITY_PDF: "ASIP pdf inviato via email.",
    ERROR_MARGINALITY_PDF: "Errore durante l'invio dell'ASIP pdf",
    TO_CONCORD_TO_OFFER: "Da Concordare > Offerta",
    FIXED_PRICE_ENGINEERING_WARRANTY: "Totale a corpo Engineering/Garanzia",
    CREATE_CUSTOM_CONTACT: "Crea contatto temporaneo",
    NO_WORK_REPORTS: "Nessun rapporto di lavoro",
    NO_WORK_REPORTS_TO_APPROVE: "Nessun rapporto di lavoro da approvare",
    DOC_CHANGED: "Documento modificato",
    UPDATE_DOC_BEFORE_PDF: "Aggiornare il documento prima di aprire il PDF",
    ENGINEERING_WARRANTY_LINE_IN_NORMAL_FIXED_PRICE: "Linea Engineering/Garanzia non puó essere utilizzata in un a corpo normale.",
    NORMAL_LINE_IN_ENGINEERING_WARRANTY_FIXED_PRICE: "Linea normale non puó essere utilizzata in un a cropo Engineering/Garanzia.",
    CREATE_CONTRACT: "Crea contratto",
    WARRANTY_STATUS: "Stato garanzia",
    OVERVIEW: "Overview",
    EXPIRE: "Scadenza",
    CREATE_INTEGRATIVE_OFFER: "Crea offerta integrativa",
    CREATE_EMPTY_DOC: "Crea offerta vuota",
    CREATE_OFFER_BY_OFFER: "Crea offerta da altra offerta.",
    CREATE_OFFER: "Crea offerta",
    CREATE_WARRANTY: "Crea garanzia",
    BILLS_OF_MATERIALS: "Distinte basi",
    HOURS_LIST: "Lista ore",
    MATERIALS_LIST: "Lista materiali",
    APPROVAL_NOTES: "Note di approvazione",
    CLIENT_NOT_FOUND: "Cliente non trovato",
    WORK_FINISHED: "Lavoro terminato",
    STATUS_TO_BE_PAID: "attesa pagamento",
    STATUS_TO_BE_BILLED: "da fatturare",
    STATUS_TO_BE_APPROVED: "da approvare",
    STATUS_IN_EXECUTION: "in esecuzione",
    ERROR_SENDING_EMAIL: "Errore durante l'invio della mail",
    SEND: "Invia",
    VIA_EMAIL: "via email",
    PLEASE_INSERT_EMAIL: "Perfavore inserire l'email",
    SUBJECT: "Oggetto",
    PLEASE_INSERT_SUBJECT: "Perfavore inserire un oggetto",
    CONTENT: "Contenuto",
    SEND_ME: "Invia a me",
    QUICK_SEND: "Invio veloce",
    CLIENT_EMAIL: "Email Cliente",
    CLIENT_CONTACT_EMAIL: "Email Contatto Cliente",
    CHOOSE_RECIPIENT: "Scegli destinatario",
    SEND_PDF: "Inva PDF",
    DOCUMENT_CONTACT_TYPE_MISSING: "Manca il tipo di contatto del documento",
    UNDER_MINIMUM_PRICE: "Sotto il prezzo minimo di vendita",
    TOO_MUCH_DISCOUNT: "Troppo sconto",
    MARGINALITY_CONSUNTIVE: "Marginalità consuntiva",
    RECEIVED_PLANS_DATE: "Data ricezione piani",
    HAS_REPORT_ADDED: "ha aggiunto una posizione",
    HAS_REPORTED_MORE: "ha rapportato più dell'offerta",
    HAS_REPORTED_MORE_AND_ADDED: "ha rapportato più dell'offerta ed aggiunto una pozione",
    HAS_FINSHED_REPORT: "ha finito un lavoro",
    HAS_OFFERED_UNDER_PRICE: "ha venduto sotto prezzo",
    MINIMUM_PRICE: "prezzo minimo",
    BREAK_CANT_BE_BIGGER_THAN_TOTAL_TIME: "La pausa non può essere maggiore del tempo totale",
    HAS_WON_OFFER: "ha vinto un offerta",
    DAY_REPORT: "Rapporto di lavoro",
    DETAILS: "Dettagli",
    TOTAL_TIME: "Tempo totale",
    CREATE_MEASURE: "crea misure",
    MEASURES: "Misure",
    MATERIALS: "Materiali",
    APPROVED_BY: "Approvato da",
    ARE_YOU_SURE_CANT_BE_UNDONE: "Sei sicuro di voler salvare? Questa operazione non può essere annullata.",
    MEASURED_BY: "Misurato da",
    MEASURED: "Misurato il",
    TITLE: "Titolo",
    DEFAULT_BILL_ADDRESS: "Indirizzo di fatturazione",
    DEFAULT_SHIP_ADDRESS: "Indirizzo di spedizione",
    ID: "Id",
    STATE: "Stato",
    TABLE: "Tabella",
    LIST: "Lista",
    APPALT: "Appalto",
    OFFER_NAME: "Nome offerta",
    CLIENT_HAS_NO_ADDRESS: "Il cliente non ha l'indirizzo di fatturazione",
    BY_HAS_NO_ADDRESS: "By non ha l'indirizzo",
    INVOICE_TO_HAS_NO_ADDRESS: "Fatturare a non ha un indirizzo di fatturazione",
    FISCAL_CODE: "Codice fiscale",
    BUDGET_MATERIAL: "Budget Materiale",
    BUDGET_TIME: "Budget Ore",
    DOC_LEVELS_EMPTY_NAME: "Uno o più livelli nel documento hanno il nome vuoto",
    MINIMUM_VALUE: "Valore minimo",
    MAXIMUM_VALUE: "Valore massimo",
    BILL_PREVIEW_ORDER: "Anteprima fattura per ordine",
    AFTER_DAY_REPORT: "Dopo rapporto di lavoro",
    BILL_PREVIEW: "Anteprima fattura",
    HIDE_EMPTY: "Nascondi vuote",
    AUTO_SELECT_TITLE: "Auto seleziona titoli",
    AUTO_SELECT_TWINS: "Auto seleziona gemelle",
    TOTAL_BEFORE_DISCOUNTS: "Totale prima degli sconti",
    NOT_VALID_NUMBER: "Numero non valido",
    INSERT_WORK_SPACE_TO_SIGN_IN: "Inserisci il nome del tuo workspace per accedere al tuo account DRYapp.",
    INSERT_YOUR_WORKSPACE_NAME: "Inserisci il nome del tuo workspace...",
    PROCEED: "Procedi",
    INSERT_AT_LEAST_ONE_CHARACTER: "Inserisci almeno un carattere",
    WORKSPACE_NOT_FOUND: "Workspace non trovato, controlla il nome.",
    ERROR_STARTING_APP: "Errore di avviamento dell'app.",
    CONTACT_SUPPORT: "Contatta il supporto.",
    CUSTOM_WORKSPACE: "Workspace presonalizzato.",
    PRIMARY_SERVER_URL: "URL server primario",
    SECONDARY_SERVER_URL: "URL server secondario",
    PRIMARY_URL_CANNOT_BE_EMPTY: "URL server primario non può essere vuoto.",
    SECONDARY_URL_CANNOT_BE_EMPTY: "URL server secondario non può essere vuoto.",
    CHECKING_WORKSPACE: "Controllo workspace workspace:",
    CHECKING_URL: "Controllo URL server:",
    SIGN_IN_INTO: "Accedi al tuo workspace",
    ERROR_SIGNING_IN: "Errore di accesso",
    WRONG_USERNAME_OR_PASSWORD: "Username o password errati",
    USERNAME_CANNOT_BE_EMPTY: "Username non puo essere vuoto",
    PASSWORD_CANNOT_BE_EMPTY: "Password non puo essere vuota",
    ERROR_LOGOUT: "Errore di disconnessione",
    ACCESSIBLE: "Accessibile",
    AQUIFER: "In Falda",
    INTERNAL_NAME_CAN_BE_MAX_100: "Nome interno non può essere più lungo di 100 caratteri",
    YOUR_SESSION_IS_NOT_VALID: "La tua sessione non è più valida",
    ERROR_LOADING_DOCUMENT: "Errore scaricando il documento",
    ERROR_LOADING_CONSTRUCTION_SITES: "Errore scaricando i cantieri",
    ERROR_ASSIGING_CONSTRUCTION_SITE: "Errore durante l'assegnazione del cantiere",
    ERROR_LOADING_DAY_REPORTS: "Errore scaricando i rapporti di lavoro",
    ERROR_LOADING_DAY_REPORT: "Errore scaricando il rapporto di lavoro",
    ERROR_CREATING_DAY_REPORT: "Errore creando il rapporto di lavoro",
    ERROR_APPROVING_DAY_REPORT: "Errore approvando il rapporto di lavoro",
    ERROR_LOADING_ACTIVITIES: "Errore scaricando le attività",
    SELECT_BRANCH: 'Seleziona filiale',
    SELECT_CATALOG: 'Seleziona catalogo',
    SELECT_ADDRESS: 'Seleziona indirizzo',
    SELECT_TYPE: 'Seleziona tipo',
    SELECT_COUNTRY: 'Seleziona nazione',
    SELECT_STATE: 'Seleziona stato',
    SELECT_STATUS: 'Seleziona stato',
    SELECT_VAT: 'Seleziona IVA',
    CLICK_TO_SELECT: 'Clicca per selezionare',
    WIN_OFFER: "Vincere offerta",
    ERROR_LOADING_WEEK_REPORT: "Errore scaricando rapporto settimanale",
    KEEP_ORDER: "Mantenere ordine",
    ARE_YOU_SURE_YOU_WANT_UNAPPROVE_THE_DAY_REPORT: "Sei sicuro di voler disapprovare il rapporto di lavoro?",
    CONTINUE: "Continuare?",
    ERROR_UNAPPROVE_DAY_REPORT: "Errore disapprovando il rapporto di lavoro.",
    ARE_YOU_SURE_YOU_WANT_DELETE_THE_DAY_REPORT: "Sei sicuro di voler eliminare il rapporto di lavoro?",
    ERROR_DELETING_DAY_REPORT: "Errore eliminando il rapporto di lavoro.",
    UNAPPROVE: "Disapprovare",
    ERROR_UPLOADING_FILE: "Errore caricando il file",
    SELECT_FILE_TYPE_BEFORE_UPLOADING: "Perfavore selezionare il tipo di documento prima di caricare il file.",
    ERROR_UPDATING_FILE: "Errore aggiornando il file",
    ERROR_UPLOADING_SIGNATURE: "Errore caricando la firma",
    CANCEL_ORDER: "Cancella ordine",
    ERROR_CANCELING_ORDER: "Errore cancellando l'ordine",
    CONTINUE_CANCEL_ORDER_2: "Sei sicuro sicuro di voler cancellare l'ordine?",
    CONTINUE_CANCEL_ORDER: "Sei sicuro di voler cancellare l'ordine?",
    PIT_WALL_M2: 'Platea, Parete, Soletta tot m2:',
    TOTAL_VAT_EXCL: 'Totale IVA escl.',
    ROUNDING: 'Arrotondamento',
    TOTAL_VAT_INCL: 'Totale IVA incl.',
    PAYMENT_DATE: 'Data pagamento',
    PAYMENT_AMOUNT: 'Pagamento',
    BETON: 'Calcestruzzo',
    FINANCIAL_REPORT: 'Rapporto finanziario',
    FIRST_PAGE: 'Fronte spizio',
    DAY_REPORT_EMPTY_CONTINUE: "Rapporto di lavoro vuoto, sei sicuro di voler continuare?",
    ERROR_IN_TIME_MANAGEMENT: "Errore nella gestione dei tempi. Ricontrolla che i tempi siano corretti.",
    DOC_FOOTER_SET_EQUAL_VAT: 'Settare IVA unica',
    DAY_TOTAL_TIME: "Tempo totale giorno",
    SHOW_WEEK_REPORT: "Mostra rapporto settimanale",
    INDUSTRY: "Formula di cortesia",
    SELECT_INDUSTRY: "Seleziona",
    MEASURE_APPROVED_OF: "Approvati {{approved}} di {{of}}",
    EDIT_ORDER_DETAILS: "Modifica dettagli ordine",
    WARRANTY_FOR: 'Garanzia per',
    SELECT_CONTRACT: 'Scegliere contratto',
    CREATE_CUSTOM_POSITION_ERROR: "Errore creando posizione personalizzata",
    ERROR_CONNECTING_SERVER: "Errore di collegamento al server",
    DATE_BEFORE_WEEK_START: "Non è possibile inserire una data della settimana precedente.",
    CHOOSE_ADDRESS_AND_LOCATION: "Scegliere un indirizzo e una posizione",
    MAP_MODAL_IF_YOU_WANT_TO_SAVE_ADDRESS: "Se vuoi salvare l'indirizzo e spostare la cartina clicca su \"Salva\".",
    SAVE_ADDRESS: "Salva Indirizzo",
    ABSENCE: "Assenza",
    SELECT_ABSENCE_TYPE: "Seleziona tipo di assenza",
    ERROR_INSERT_A_START_TIME_SMALLER_THAN_END_TIME: "Inserire un'ora di inizio minore dell'ora di fine.",
    ABSENCE_DATE_OUT_OF_LIMIT: "Assenza fuori dalla data limite",
    ERROR_UPDATING_ABSENCE: "Errore di aggiornamento assenza",
    ERROR_CREATING_ABSENCE: "Errore di creazione assenza",
    ERROR_DELETING_ABSENCE: "Errore di eliminazione assenza",
    PLEASE_CHOOSE_AN_ABSENCE_TYPE: "Pre favore scegliere un tipo di assenza",
    OFFICE_NOTES: "Note ufficio",
    UPDATE_ABSENCE: "Aggiornare assenza",
    CREATE_ABSENCE: "Creare assenza",
    TRAVEL: "Viaggio",
    WORK: "Lavoro",
    NO_CATALOGS: "Nessun catalogo",
    RELOAD_CATALOGS: "Ricarica cataloghi",
    NO_CATALOG_SELECTED: "Nessun catalogo selezionato",
    PHOTOS: "Foto",
    REVERSE: "Inverti ordine",
    ORDER_BY: "Ordina per",
    MEASURE: "Misure",
    PUBLIC_PROCUREMENT: "Appalto pubblico",
    CAN_NOT_APPROVE_REPORT_USE_MEASURE: "Non puoi approvare il rapporto, usa le misure",
    UNAPPROVE_MEASURE: "Disapprova misura",
    ERROR_UNAPPROVING_DAY_REPORT: "Errore disapprovando il rapporto di lavoro",
    OPEN_AND_ASSIGNED_TO_WORKER: 'Aperti e assegnati all\'operaio',
    NORMAL_INVOICE: 'Fattura normale',
    ADVANCE_INVOICE: 'Fattura d\'acconto',
    MIDDLE_INVOICE: 'Fattura intermedia',
    AMOUNT_INVOICE: 'Fattura a saldo',
    SYNTHETIC: 'Sintetica',
    DETAILS_WITHOUT_PRICES: 'Dettagli senza prezzi',
    DETAILS_WITH_PRICES: 'Dettagli con prezzi',
    FINAL_INVOICE: 'Fattura finale',
    INVOICE_NUMBER: 'Fattura numero',
    ADD_SIMILAR_POSITIONS: 'Aggiungi posizioni simili',
    DOC_TEST_CONTRACT_NAME_EXISTS: 'Nome del contratto già esistente',
    ALL_LEVELS: 'Tutti i livelli',
    BILL_EMAIL: 'Email di fatturazione',
    ORIGINAL_OFFER_YEAR: 'Anno offerta originale',
    FILTER: 'Filtra',
    CHOOSE_OTHER: 'Scegli altro',
    OFFLINE: 'Offline',
    NO_ITEMS_TO_SYNC: 'Nessun elemento da sincronizzare',
    BEGIN: 'Inizio',
    END: 'Fine',
    NO_INTERNET_CONNECTION_NO_SAVED_DATA: 'Nessuna connessione ad internet, nessun dato salvato',
    DAY_REPORT_SPLIT_BY: 'Rapporto di lavoro diviso per',
    DAY_REPORT_SPLIT_WITH: 'Rapporto di lavoro diviso con',
    SAVE_AND_SPLIT: 'Salva e dividi',
    CHOOSE_RESOURCES: 'Scegli risorse',
    APPROVE_AND_CLOSE: 'Approva e chiudi',
    OTHER: 'Altro',
    THEORICAL: 'Teorico',
    WRONG_PREVIOUS_TWIN: 'Gemella precedente errata, valore massimo: {{max}}',
    PLEASE_CHOOSE_A_WORK_GROUP: 'Per favore scegli un gruppo di lavoro',
    PLEASE_CHOOSE_A_LEVEL: 'Per favore scegli un livello',
    ADD_NOTES: 'Aggiungi note',
    YOU_USED_A_LOT_OF_TIME_SOMETHING_HAPPENED: 'Hai impiegato più tempo del previsto, è successo qualcosa?',
    ERROR_PLACING_MATERIALS_AND_TIME: 'Errore inserendo materiali e tempo',
    SURE_TO_LOGOUT: 'Sei sicuro di voler uscire?',
    SURE_TO_LOGOUT_DESC: 'Sei sicuro di voler uscire? Tutti i dati non salvati verranno persi.',
    ADVANCEMENTS_DETAILS_LINE: 'Dettaglio avanzamenti per riga',
    NOT_VALID_CONTRACT: 'Contratto non valido',
    APPROVABLE: 'Approvabile',
    PREVIOUS_MEASURE: 'Misura precedente',
    MEASURE_HELP: 'Aiuto misure',
    MEASURE_HELP_DESC: 'Le colonne possono essere chiuse cliccando sul titolo della colonna.\n\nColonne:\n- Venduto: Quantità mostrate nell\'offerta.\n- Misura precedente:\n   - Eseguito: Eseguito dall\'operaio.\n   - Approvato: Già approvato.\n   - Fatturabile: Quantità impostata per la fatturazione nelle approvazioni precedenti.\n- Fatturato: Già fatturato.',
    APPROVED_HOURS: 'Ore approvate',
    BUDGET_HOURS: 'Budget ore',
    OFFLINE_MEASURES: 'Misure offline',
    ERROR_LOADING_OFFLINE_MEASURES: 'Errore scaricando le misure offline',
    ERROR_DELETING_OFFLINE_MEASURE: 'Errore eliminando la misura offline',
    MEASURES_HELP: 'Aiuto misure',
    MEASURES_HELP_TEXT: 'Le misure vengono utilizzate per approvare i report giornalieri e preparare le fatture.\n\nIn questa vista viene mostrato l\'elenco delle misure per il contratto selezionato, può essere scaricato il file excel delle misure che devono essere create, tramite il pulsante di download blu. Le misure possono essere create cliccando sul pulsante più blu.\n\nSulle misure esistenti può essere visto il numero della misura creata, la data di creazione, la persona che le ha create, il numero di report giornalieri approvati tramite la misura scelta.\nSe c\'è una fattura per questa misura viene mostrata con l\'icona della fattura, per aprire la fattura cliccare sull\'icona. Se c\'è una nota di accredito per la fattura viene mostrata un\'icona del documento con un meno.\nPer ogni misura può essere scaricato il file excel con i dettagli.\n\nDi default viene proposto un viaggio per ogni giorno. Se in uno ci sono stati più viaggi, ne verrà mostrato solo uno.',
    MEASURES_HELP_INVOICE: 'Fattura',
    MEASURES_HELP_CREDIT_NOTE: 'Nota di credito',
    MEASURES_HELP_DOWNLOAD: 'Download',
    MEASURES_HELP_DAY_REPORT: 'Rapporto di lavoro',
    ERROR_SAVING_WORK_PROGRESS_STATUS: 'Errore salvando lo stato di avanzamento del lavoro',
    CONSTRUCTION_SITE_NOTES: 'Note cantiere',
    CONTRACT_NOTES: 'Note contratto',
    OFFER_NOTES: 'Note offerta',
    NOTES_FOR_DAY_REPORT: 'Note per il rapporto di lavoro',
    NO_NOTES_TO_COPY: 'Nessuna nota da copiare',
    ERROR_LOADING_DOCS_OFFLINE: 'Errore scaricando i documenti offline',
    ERROR_DELETING_DOCS_OFFLINE: 'Errore eliminando i documenti offline',
    DOCS_OFFLINE: 'Documenti offline',
    CREATED: 'Creato',
    UPDATED: 'Aggiornato',
    ERROR_UPDATING_DOCS_OFFLINE: 'Errore aggiornando il documento offline',
    ERROR_OPENING_OFFLINE_DOC: 'Errore apertura documento offline',
    NOT_AVAILABLE_OFFLINE: 'Non disponibile offline',
    MAPS: 'Maps',
    ADDRESS: 'Indirizzo',
    SELECT_DOCUMENT_ORDER: 'Seleziona ordine documento',
    NO_VAT: 'Nessuna IVA',
    LANGUAGE: 'Lingua',
    ALL_DAY: 'Tutto il giorno',
    START_DATE: 'Data inizio',
    END_DATE: 'Data fine',
    SETTINGS: 'Impostazioni',
    SHOW_DAYS_BY_WEEK: 'Mostra giorni per settimana',
    YOU_ARE_NOT_AUTHORIZED_TO_CREATE_CLIENTS: 'Non sei autorizzato a creare clienti',
    YOU_ARE_NOT_AUTHORIZED_TO_UPDATE_CLIENTS: 'Non sei autorizzato a modificare clienti',
    SAVED_OFFLINE: 'Salvato offline',
    DRAFT: 'Bozza',
    OFFERS_TO_CHECK: 'Offerte da controllare',
    CONSTRUCTION_SITE_NAME: 'Nome cantiere',
    CATALOG: 'Catalogo',
    CONTRACT: 'Contratto',
    DAY_REPORT_WRONG_PREVIOUS_TWINS: 'Posizione precedente errata, sicuro di voler continuare?',
    DEPARTURE_TIME_TOO_EARLY: 'Orario di partenza troppo presto, inserisci un orario di partenza non il tempo di viaggio.',
    PLEASE_SET_ALL_DOCUMENT_CONTACT_TYPES: 'Per favore inserisci tutti i tipi di contatto del documento',
    DOC_DRAFTED_CREATE: 'ha creato un documento in bozza',
    DOC_DRAFTED_UPDATE: 'ha aggiornato un documento in bozza',
    NO_CONTRACTS_FOR_CONSTRUCTION_SITE: 'Nessun contratto per il cantiere',
    DUPLICATE_DOC: 'Duplicare documento',
    DUPLICATE_CONTRACT: 'Duplicare contratto',
    DUPLICATE_IN_NEW_CONTRACT: 'Duplicare in un nuovo contratto',
    DUPLICATE_CONSTRUCTION_SITE: 'Duplicare cantiere',
    DUPLICATE_IN_NEW_CONSTRUCTION_SITE_AND_NEW_CONTRACT: 'Duplicare in un nuovo cantiere e nuovo contratto.',
    CHANGE_DESTINATION: 'Cambia destinazione',
    MOVE: 'Sposta',
    SEARCH_CLIENT_TO_IMPORT_FROM_GOOGLE: 'Cerca cliente da importare da Google',
    ERROR_GETTING_CLIENT_DETAILS: 'Errore ottenendo i dettagli del cliente',
    IMPORT: 'Importa',
    EXTERNAL_WORKS_INVOICES: 'External works invoices',
    SUPPLIER: 'Fornitore',
    REFERENCE: 'Riferimento',
    INVOICE_DATE: 'Data fattura',
    INSERTED_BY: 'Inserito da',
    EXTERNAL_WORK_INVOICE: 'Fattura lavori esterni',
    NO_DAY_REPORT: 'Nessun rapporto di lavoro',
    DAY_REPORT_DATE: 'Data rapporto di lavoro',
    DAY_REPORT_NOTES: 'Note rapporto di lavoro',
    NEW: 'Nuovo',
    CHOOSE_DAY_REPORT_FOR_EXTERNAL_WORK_INVOICE: 'Scegli rapporto di lavoro per fattura lavori esterni',
    CHOOSE_EXTERNAL_WORK_INVOICE: 'Scegli fattura lavori esterni',
    HOURS_LIST_BUDGET_EFFECTIVE: 'Lista ore budget/effettive',
    INDUSTRIAL_ACCOUNTING: 'Contabilità di cantiere',
    CHOOSE_CONTRACTS: 'Scegliere contratti',
    DOWNLOAD: 'Download',
  },
  de: {
    SERVER: "Server",
    WRONG_USERNAME_PASSWORD: "Falsches Username oder Password",
    INVALID_LOGIN_TITLE: "Ungültige Anmeldung",
    LOGOUT: "Abmelden",
    SEARCH: "Suche",
    SHOW_PRICE_LEVEL_NO_PRICE: "Presie verstecken",
    SHOW_PRICE_LEVEL_CLIENT: "Kunden Preise",
    SHOW_PRICE_LEVEL_INTERNAL: "Interne Preise",
    SHOW_PRICE_LEVEL_PLACEHOLDER: "Preise Ansicht wählen",
    FILTER_BY: "Filtern nach:",
    NUMBER: "Nummer",
    NAME: "Name",
    STATUS: "Status",
    CONSTRUCTION_SITES: "Baustellen",
    PAGE_SIZE_TEXT: "Einträge pro Seite",
    CLIENT: "Kunde",
    DOC_NAME: "Dokument",
    DOC_TYPE: "Dokumenttyp",
    DOC_BY: "Von",
    DOC_DISTANCE: "Distanz",
    DOC_OFFER_NAMED_TO: "Angebot an",
    DOC_INVOICE_TO: "Rechnung an",
    DOC_CLIENT_CODE: "Code",
    DOC_CLIENT_CONTACT: "Kontakt",
    BY: "Von",
    OFFER_NAMED_TO: "Angebot an",
    INVOICE_TO: "Rechnung an",
    PHONE: "Telefon",
    DOC_ADD_CONTACT: "Kunde einfügen",
    DOC_CREATE_DATE: "Erstellungsdatum",
    UPDATE_DATE: "Aktualisierungsdatum",
    CREATED_BY: "Erstellt von",
    DOC_UPDATED_BY: "Aktualisiert von",
    CATALOG_DROPDOWN: "Katalog",
    CONTRACT_NAME: "Vertrag",
    CONSTRUCTION_SITE: "Baustelle",
    CONSTRUCTION_SITE_TYPE: "Baustellentyp",
    CONSTRUCTION_SITE_ADDRESS: "Adresse",
    BRANCH: "Baustellenzweig",
    CONSTRUCTION_SITE_MAPS: "Karte",
    DELETE: "Löschen",
    WIN: "Gewinne",
    LOSE: "Verliere",
    DUPLICATE: "Dupliziere",
    PDF: "PDF",
    PRINT: "Drucken",
    EMAIL: "Email",
    VERSIONS: "Versionen",
    CHOOSE_CONSTRUCTION_SITE: "Baustelle wählen",
    CREATE_NEW_CONSTRUCTION_SITE: "Baustelle erstellen",
    CHOOSE_CONTRACT: "Vertrag wählen",
    CREATE_NEW_CONTRACT: "Vertrag erstellen",
    STATUS_OPEN: "Offnen",
    STATUS_LOST: "Verloren",
    OFFER: "Angebot",
    STATUS_DONE: "Fertig",
    STATUS_CANCELLED: "Storniert",
    STATUS_NOT_AWARDED: "nicht verliehen",
    STATUS_OLD_VERSION: "Alte version",
    STATUS_WON: "Gewint",
    STATUS_OTHER: "Anderes",
    LEVELS: "Ebenen",
    LEVEL_PRINT_FLAG: "Ebene Drucken",
    NEW_LEVEL: "Neue Ebene",
    CREATE: "Erstellen",
    UPDATE: "Aktualisieren",
    CANCEL: "Abbrechen",
    CLOSE: "Schliessen",
    SEARCH_CATEGORY_ALL: "Alle",
    SEARCH_CATEGORY_OFFERS: "Angebote",
    SEARCH_CATEGORY_SITES: "Baustellen",
    SEARCH_CATEGORY_CLIENTS: "Kunden",
    SEARCH_RESULT: "Ergebnisse",
    ADVANCED_TOGGLE_LABEL: "Erweiterte Suche",
    RESULTS_LABEL: "Ergebnisse für",
    CLIENT_TYPE: "Kundentyp",
    CREATE_NEW_REPORT: "Neuen Bericht erstellen",
    OFFERS: "Angebote",
    INVOICES: "Rechnungen",
    CONSTRUCTION_SITE_ACCOUNTING: "Baustellenabrechnung",
    WORK_REPORTS: "Arbeitsberichte",
    PHOTO: "Foto",
    OFFER_CONFIGURATOR_TABLE: "Tabelle",
    OFFER_CONFIGURATOR_LIST: "List",
    DOC_CONTACTS: "Kontakte",
    CLIENT_TYPE_COMPANY: "Firma",
    CLIENT_TYPE_PERSON: "Person",
    SELECT_CONTACT_TYPE: "Kontakt typ auswählen",
    FIELD_IS_MANDATORY_MESSAGE: "Dieses Feld ist obligatorisch",
    MORE_OPTIONS_MENU_LABEL: "Erweiterte Menüoptionen",
    GROUP: "Gruppe",
    MOBILE_PHONE: "Handy",
    VAT: "VAT",
    STREET_LINE: "Strasse",
    STREET_LINE_1: "Adresszusatz 1",
    STREET_LINE_2: "Adresszusatz 2",
    POSTAL_CODE: "Postleitzahl",
    CITY: "Stadt",
    REGION: "Region",
    COUNTRY: "Land",
    CHOOSE_CLIENT: "Kunde auswählen",
    EDIT_CLIENT: "Kunde ändern",
    HAS: "hat",
    CONTACTS_LOW: "kontakte",
    CREATE_NEW_CONTACT: "Neuen Kontakt erstellen",
    CREATE_NEW_CLIENT: "Neuen Kunden erstellen",
    CREATE_EDIT_NEW_CONTACT: "Neune Kontakt erstellen/ändern",
    FIRST_NAME: "Vorname",
    LAST_NAME: "Nachname",
    SAVE: "Speichern",
    NEW_CONTACT: "Neuer Kontakt",
    NEW_BUSINESS_PARTNER: "Neuer Geschäftspartner",
    BUSINESS_PARTNER: "Geschäftspartner",
    SHOW_CLIENT_LIST: "Kundenliste anzeigen",
    POSITION: "Position",
    EXPAND_ALL: "Alle erweitern",
    NEW_ADDRESS: "Neue Adresse",
    NO_ENTERED_NAME: "Kein eingegebener Name",
    EDIT_LOW: "ändern",
    FILE: "Datei",
    WELCOME: "Wilkommen",
    USERNAME: "Benutzername",
    PASSWORD: "Passwort",
    SIGN_IN: "Anmelden",
    PERMISSION_NOT_GRANTED: "Erlaubnis nicht erteilt",
    ALLOW_THE_APP_TO_USE_LOCATION_SERVICES:
      "Erlauben Sie der App die Verwendung von Ortungsdiensten",
    NO_OFFER_FOUND_FOR_CLIENT: "Kein Angebot gefunden",
    NO_CONSTRUCTION_SITE_FOUND_FOR_CLIENT: "Keine Baustelle gefunden",
    NO_INVOICE_FOUND_FOR_CLIENT: "Keine Rechnung gefunden",
    CODE: "Code",
    DESCRIPTION: "Beschreibung",
    QUANTITY: "Menge",
    UOM: "EdM",
    PRICE: "Preis",
    PERCENT: "%",
    TOTAL: "Total",
    TYPE: "Typ",
    FLAT_RATE: "Pauschal",
    ADD_A_ROW: "Zeile einfügen",
    READ_LEVEL: "Lese berechtigungen",
    INTERNAL: "Intern",
    ADMIN: "Admin",
    WORKER: "Arbeiter",
    GUEST: "Gast",
    DISCOUNTS: "Rabatte",
    DISCOUNT: "Rabatt",
    STANDARD: "Norm",
    ADD_DISCOUNT: "Rabatt hinzufügen",
    WITHOUT_IVA: "Ohne MwSt",
    INSERT_A_COMMENT: "Kommentar einfügen",
    TO_BE_AGREED: "zu vereinbaren",
    EDIT_CONTACT: "Kontakt ändern",
    UNIT_PRICE: "Einzelpreis",
    INSERT_A_QUANTITY: "Geben Sie eine Menge ein",
    SAVE_IN: "Speichern in",
    MY_POSITIONS: "meine Positionen",
    NAME_OF_THE_POSITION: "Name der Stelle",
    SELECT_UNIT_OF_MEASURE: "Wählen Sie eine Masseinheit",
    UNIT_OF_MEASURE: "Masseinheit",
    SHOW_LEVELS: "Ebenen anzeigen",
    EXECUTE: "Ausführen",
    INVOICED: "In Rechnung gestellt",
    CALCULATED: "Berechnet",
    CREATE_POSITION: "Position erstellen",
    SELECT_WHAT_TO_ADD: "Auswählen, was hinzugefügt werden soll",
    INSERT_A_POSITION: "Position einfügen",
    INSERT_A_TEXT: "Text einfügen",
    INSERT_A_ENGINEERING_WARRANTY_BLOCK_PRICE:
      "Preisblocks für Technik/Garantie einfügen",
    INSERT_A_NORMAL_BLOCK_PRICE: "Normalen Preisblock einfügen",
    TABLE_VIEW: "Tabellenansicht",
    PLEASE_SELECT_A_CATALOG: "Wählen Sie eine Katalog",
    LEVEL: "Stufe",
    TYPOLOGY: "Typ",
    NO_DISCOUNT: "Kein Rabatt",
    TOTAL_WITH_DISCOUNTS: "Gesamtbetrag mit Rabatt",
    RESOURCE_NAME: "Ressourcenname",
    VERSION: "Version",
    HOURS: "Stunden",
    MINUTES: "Minuten",
    CHOOSE_A_LETTER: "Wählen Sie ein Buchstaben",
    CHOOSE_A_VAT: "MwSt wählen",
    CHOOSE_A_CATALOG: "Wählen Sie ein Katalog",
    CHOOSE_A_SUM_TYPE: "Wählen Sie ein Summenart",
    ALL: "Alle",
    OPEN_AND_NOT_ASSIGNED: "Offen und nicht zugewiesen",
    OPEN_AND_ASSIGNED: "Offen und zugewiesen",
    SHARE: "Teilen",
    TODAY: "Heute",
    PLANNING: "Planung",
    TOTAL_QUANTITY: "Gesamtmenge",
    FOR: "Für",
    TO_CONCORD: "Zu vereinbaren",
    CLEAR: "Löschen",
    CONFIRM: "Bestätigen",
    SIGN_ABOVE: "Unterschreiben Sie oben",
    TODO: "Noch zu erledigen",
    SIGN: "Zeichen",
    WARNUNG: "Warnung",
    ARE_YOU_SURE: "Sind Sie sicher?",
    REPORT_MODAL_CLOSE_MESSAGE: `Durch Drücken von "Ja" werden dieser Bericht und alle seine Daten gelöscht`,
    YES: "Ja",
    NO: "Nein",
    TRIP_TIME: "TRIP_TIME",
    NO_AUTO: "Kein Auto",
    BREAK: "Unterbrechung",
    START_TIME: "Startzeit",
    START_TIME_ON_SITE: "Startzeit auf der Baustelle",
    END_TIME: "Endzeit",
    END_TIME_ON_SITE: "Endzeit auf der Baustelle",
    PREVIEW: "Vorschau",
    ADD_WORK: "Arbeit hinzufügen",
    HINWEISE: "Hinweise",
    SIGNED_BY: "Unterzeichnet von",
    DATE: "Datum",
    PLACE: "Ort",
    UNTERSCHRIFT: "Unterschrift",
    CLICK_HERE_TO_SIGN: "Klicken Sie hier, um zu unterschreiben",
    SAVE_AND_CLOSE: "Speichern und schließen",
    SIGNATURE_NOT_AVAILABLE: "Benutze ein Ipad für die Unterschrift",
    WARNING: "Achtung",
    NOTES: "Notizen",
    SIGNATURE: "Unterschrift",
    REMOVE: "Entfernen",
    OPEN_CONSTRUCTION_SITE: "Offene Baustelle",
    MODIFY: "Ändern",
    NO_INTERNET_CONNECTION: "Kein internet verbindung",
    SELECT_VERSION: "Wählen Sie eine Version",
    SELECT_LANGUAGE: "Sprache auswählen",
    SELECT_CURRENCY: "Währung auswählen",
    SELECT_SALE_EMPLOYEE: "Verkaufsman auswählen",
    NO_EARLIER_VERSION_AVAILABLE: "Keine frühere Version verfügbar",
    SEE_OLDER_VERSIONS: "Ältere Versionen anzeigen",
    WOULD_YOU_CREATE_NEW_VERSION:
      "Sie möchten eine neue Version dieses Dokuments erstellen?",
    CREATE_NEW_VERSION: "Neue Version erstellen",
    UPDATE_VERSION: "Aktualisieren Sie diese Version",
    CLOSE_WITHOUT_SAVING: "Schlissen ohne Speichern?",
    ARE_YOU_SURE_CLOSE_WITHOUT_SAVING:
      "Sind Sie sicher die sie wollen nicht speichern der dokument?",
    NEW_DOCUMENT: "Neue Dokument",
    DOCUMENTS: "Dokument",
    DOCUMENT_NUMBER: "Dokument Nummer",
    WHO_WON_OFFER: "Wie hat der offer gewonnen?",
    COMPETITOR: "Wettbewerberin",
    LINE_TOTAL: "Zeile gesamt",
    ORDER: "Auftrag",
    NO_ORDER_FOR_THIS_CONTRACT: "Keine Auftrag",
    BILLED: "abgerechnet",
    SOLD: "verkauft",
    NOT_BILLABLE: "nicht abrechenbar",
    SHOW_AS_MONETARY_VALUE: "als Geldwert zeigen",
    EXECUTED: "hingerichtet",
    ADD_NEW_POSITION: "hinzufugen neue position",
    EDIT: "bearbeiten",
    PRICE_LIST: "für",
    DIGITAL: "Digital",
    VERSION_ALREADY_CHANGED: "Version bereits geändert",
    VERSION_ALREADY_CHANGED_DESC: "{0} {1} hat am {2} um {3} schon das angebot geändert. Sind Sie sicher, dass Sie die Änderungen überschreiben möchten?",
    CLOSE_PREVIEW: "Vorschau schließen",
    EDIT_CONTRACT: "Vertrag ändern",
    CONTRACT_CHANGED: "Geänderter Vertrag",
    CONTRACT_CHANGED_DESC: "Möchten Sie die Änderungen wirklich rückgängig machen?",
    NOT_SAFE_OPERATION: "Gefährlicher Betrieb",
    ARE_YOU_SURE_TO_CHANGE_CONSTRUCTION_SITE_ID_DESC: "Möchten Sie die Werft für diesen Vertrag wirklich ändern?\nDieser Vorgang ist potenziell destruktiv.",
    NOT_VALID_CONSTRUCTION_SITE_ID: "Ungültige Baustellen-ID",
    UPDATE_CONTRACT: "Vertrag aktualisieren",
    NOT_VALID_CONTRACT_NAME: "Ungültiger Vertragsname",
    CONSTRUCTION_SITE_CHANGED: "Modifizierte Werft",
    CONSTRUCTION_SITE_CHANGED_DESC: "Möchten Sie die Änderungen wirklich rückgängig machen?",
    EDIT_CONSTRUCTION_SITE: "Umbau der Baustelle",
    POSSIBLE_CONSTRUCTION_SITE_NUMBER: "Mögliche Baustellennummer",
    CONSTRUCTION_SITE_NUMBER: "Baustellennummer",
    UPDATE_CONSTRUCTION_SITE: "Baustelle aktualisieren",
    ERROR_UPDATING_CONSTRUCTION_SITE: "Fehler beim Aktualisieren der Baustelle",
    ERROR_UPDATING_CONTRACT: "Fehler beim Aktualisieren des Vertrags",
    ERROR_CONSTRUCTION_SITE: "Fehler unterladen der Baustelle",
    ERROR_LOADING_ORDER: "Fehler beim Herunterladen der Bestellung",
    SOME_FIELDS_ARE_NOT_VALID: "Einige Daten sind ungültig",
    SOME_FIELDS_MIGHT_BE_NOT_COMPLETE: "Einige Felder sind unvollständig. Möchten Sie wirklich fortfahren?",
    ERRORS: "Fehlers",
    WARNINGS: "Warnungen",
    INFO: "Info",
    NO_PHOTOS: "Keine Fotos",
    CHOOSE: "Wählen",
    NO_CONSTRUCTION_SITE_SELECTED: "Keine Baustelle ausgewählt, bitte wählen Sie eine aus oder erstellen Sie eine neue.",
    CONSTRUCTION_SITE_HAS_NO_CONTRACTS: "Baustelle hat keine Verträge, bitte erstellen Sie einen neuen Vertrag.",
    CLIENTS: "Kunden",
    ERROR_LOADING_CLIENTS: "Kunden-Download-Fehler",
    ERROR_LOADING_CLIENTS_DESC: "Fehler beim Herunterladen von Clients.",
    CHOOSE_CONTACT: "Wählen Sie einen Kontakt des Kunden aus.",
    CLIENT_HAS_NO_CONTACTS: "Der Kunde hat keine Kontakte.\nBearbeiten Sie den Kunden, um einen Kunden hinzuzufügen\noder fortfahren.",
    NO_CLIENT_SELECTED: "Mandant nicht ausgewählt.",
    CUSTOM_CONTACT_CHANGED: "Benutzerdefinierter Kontakt geändert",
    CUSTOM_CONTACT_CHANGED_DESC: "Möchten Sie die Änderungen wirklich verwerfen?",
    CUSTOM_CONTACT: "Benutzerdefinierter Kontakt",
    ADD_NEW_DOCUMENT_CONTACT: "Neuen Dokumentenkontakt hinzufügen",
    CONTACT_TYPE: "Kontaktart",
    CONTACT: "Kontakt",
    CONTACTS: "Kontakte",
    GENERAL: "Allgemin",
    SUM_TYPE: "Sumtype",
    EDIT_LINE: "Zeile bearbeiten",
    INTERNAL_NAME: "Name internet",
    ADD_POSITION: "Position hinzufugen",
    ADD_TEXT_LINE: "Text Zeile Hinzufugen",
    INSERT_FLAT_RATE: "Pauschal Zeile hinzufugen",
    INSERT_FLAT_RATE_ENGINEERING_WARRANTY: "Engineering/Garantie pauschal zeile hinzufugen",
    DUPLICATE_LINE: "Duplikat zeile",
    TEXT: "Text",
    ERROR_CREATING_CLIENT: "Fehler beim Erstellen von Client",
    ERROR_CREATING_CLIENT_DESC: "Fehler beim Erstellen des Clients versuchen Sie es erneut.",
    CLIENT_CHANGED: "Kunde verändert",
    CLIENT_CHANGED_DESC: "Sind Sie sicher, dass Sie die Änderungen stornieren möchten?",
    CLIENT_ERROR: "Clientdaten nicht gültig",
    CLIENT_ERROR_DESC: "Einige Daten im Client sind nicht gültig. Bitte beheben Sie, bevor Sie den Client erstellen",
    CLIENT_WARNINGS: "Clientdaten nicht vollständig",
    CLIENT_WARNINGS_DESC: "Einige Daten im Client sind möglicherweise nicht gefüllt. Sind Sie sicher, dass Sie fortfahren möchten?",
    ADDRESSES: "Addressen",
    SELECT_DOCUMENT_TYPE: "Wählen Sie den Dokumenttyp",
    SELECT_DOCUMENT_TYPE_DESC: "Wählen Sie den Dokumenttyp aus, bevor Sie einen neuen Dokumentkontakt erstellen.",
    NEW_BLOCK: "Neue bloccke",
    NOT_AVAILABLE_FOR_FIXED_PRICE: "Einstellungen für feste Preisleitungen nicht verfügbar.",
    PRICING: "Preisgestaltung",
    VARIABLE: "Variable",
    TOTAL_TO_CONCORD: "Gesamt zu vereinbaren",
    NORMAL: "Normal",
    TOTAL_TYPE: "Gesamt Typ:",
    DISCOUNT_NUMERIC: "5.-",
    DISCOUNT_PERCENTAGE: "%",
    TOTAL_WITH_DISCOUNT: "Gesamt mit rabatt",
    TOTAL_WITH_VAT: "Gesamt mit MwSt",
    NEW_DISCOUNT: "neuer Rabatt",
    MARGINALITY: "Marginalität",
    MATERIAL_COST: "Materialkosten",
    HUMAN_WORK_COST: "Menschliche Arbeitskosten",
    EXTERNAL_WORK_COST: "External Work Cost",
    TOTAL_COSTS: "Externe Arbeitskosten",
    MATERIAL_REVENUE: "Materielle Einnahmen",
    HUMAN_WORK_REVENUE: "Einnahmen aus menschlicher Arbeit",
    EXTERNAL_WORK_REVENUE: "Einnahmen aus externer Arbeit",
    TOTAL_REVENUE: "Gesamteinnahmen",
    MARGIN_BASIC_OFFER: "Grundangebotsmarge",
    WARRANTY_ENGINEERING: "Gewährleistung / Engineering",
    ENGINEERING_HOURS_COST: "Kosten für Ingenieurstunden",
    ENGINEERING_REVENUE: "Engineering-Einnahmen",
    WARRANTY_COSTS_MATERIAL: "Warranty Costs",
    WARRANTY_COSTS_HUMAN_WORK: "Garantiekosten",
    WARRANTY_REVENUE: "Garantieeinnahmen",
    MARGIN_ENGINEERING_WARRANTY: "Technische Gewährleistungsmarge",
    GENERAL_RESULT: "Allgemeines Ergebnis",
    PERCENTAGE_DIFFERENCE: "Unterschied %",
    REMAINING_WARRANTY_YEARS: "Jahre Restgarantie",
    CREATE_DOC_WARNINGS: "Möchten Sie das Dokument wirklich erstellen?\nEs gibt einige Warnungen",
    UPDATE_DOC_WARNINGS: "Möchten Sie das Dokument wirklich aktualisiren?\nThere are some warnings",
    ERROR_UPDATING: "Fehler beim Aktualisieren",
    ERROR_UPDATING_DESC: "Fehler beim Aktualisieren des Angebotsstatus.",
    NOT_IMPLEMENTED: "Noch nicht implementiertes Feature",
    ERROR: "Fehler",
    ORDER_ERROR_DOWNLOADING: "Fehler beim Herunterladen der Bestellung, erneut versuchen!",
    ERROR_CREATING_DOC: "Fehler beim Erstellen des Dokuments",
    ERROR_UPDATING_DOC: "Fehler beim Aktualisieren des Dokuments",
    ERROR_UPDATING_OFFER_STATUS: "Fehler beim Aktualisieren des Angebotsstatus.",
    ERROR_OPENING_DOCUMENT: "Error opening document",
    ERROR_OPENING_DOCUMENT_DESC: "Fehler beim Öffnen des Dokuments, erneut versuchen.",
    MARGINALITY_DOC_CONSTRUCTION_SITE: "Marginalität für Dokument oder Baustelle zeigen",
    BASIC_OFFER: "Grung angebot",
    DOCUMENT: "Dokumnet",
    DOC_TEST_MISSING_CONTRACT_CREATE: "fehlender Vertrag, erstellen Sie bitte einen Vertrag",
    DOC_TEST_MISSING_CONTRACT_CREATE_OR_UPDATE: "fehlender Vertrag, bitte auswählen oder Vertrag erstellen",
    DOC_TEST_MISS_CONTRACT_NAME: "fehlender Vertragsname, bitte geben Sie einen Vertragsnamen ein",
    DOC_TEST_MISSING_DOCUMENT: "Fehler fehlendes Dokument, es tut uns leid, aber dies ist ein schwerwiegender Fehler. Bitte schließen Sie die Anwendung (auch im Hintergrund) und öffnen Sie sie erneut. Der Fehler wurde bitte verfolgt",
    DOC_TEST_MISSING_DOCUMENT_NAME: "fehlender Dokumentname, bitte Dokumentnamen einfügen",
    DOC_TEST_MISSING_DOCUMENT_TYPE: "fehlender Dokumenttyp, bitte wählen Sie einen Dokumenttyp aus",
    DOC_TEST_MISSING_DOCUMENT_LANGUAGE: "fehlende Dokumentsprache, wählen Sie bitte die Dokumentsprache aus, indem Sie das 3-Punkte-Menü oben rechts öffnen",
    DOC_TEST_MISSING_DOCUMENT_CURRENCY: "fehlende Dokumentenwährung, wählen Sie bitte die Dokumentenwährung, indem Sie das 3-Punkte-Menü oben rechts öffnen",
    DOC_TEST_MISSING_DOCUMENT_CATALOG: "fehlender Dokumentenkatalog, bitte wählen Sie den Dokumentenkatalog aus",
    DOC_TEST_MISSING_DOCUMENT_CLIENT: "fehlendes Dokument Mandant, bitte wählen Sie den Mandant aus",
    DOC_TEST_MISSING_DOCUMENT_LEVELS: "fehlende Dokumentebenen, bitte erstellen Sie mindestens eine Ebene",
    DOC_TEST_MISSING_LINES: "Dokument hat keinen Inhalt (alle Zeilen sind leer)",
    MISSING_CONSTRUCTION_SITE_CREATE_OR_CHOOSE: "fehlende Baustelle, bitte erstellen oder wählen Sie eine Baustelle aus",
    MISSING_CONSTRUCTION_SITE_NAME: "fehlender Baustellenname, bitte geben Sie einen Namen für diese Baustelle ein",
    MISSING_CONSTRUCTION_SITE_TYPE: "fehlender Baustellentyp, bitte wählen Sie einen Baustellentyp aus",
    MISSING_CONSTRUCTION_SITE_BRANCH: "fehlender Baustellenzweig, bitte wählen Sie einen Baustellenzweig aus",
    MISSING_CONSTRUCTION_SITE_MAPS: "baustelle google maps adresse nicht definiert, dies erlaubt möglicherweise keine geolokalisierung",
    MISSING_CONSTRUCTION_SITE_ADDRESS: "construction site address not defined",
    UPLOAD_NEW_FILE: "Hochladen neue dokument",
    SELECT: 'Selektion`',
    UPLOAD: 'Hochladen',
    SIZE: 'Große',
    ASSIGNMENT_ERROR: "Error",
    ASSIGNMENT_ERROR_DESC: "The assignement has an error.",
    TIME_AND_MATERIAL: "Zeit und Material",
    TIME: "Zeit",
    REPORT_TO_APPROVE: "Report to approve",
    RESOURCE: "Resource",
    REPORT_HOURS: "Report horus",
    MISSING_TIME: "Fehlende Zeit",
    ADD: "Hinzufugen",
    DISABLE_TWIN_ID_AUTOMATION: "Zwillingsposition deaktivieren",
    TWIN_ID_AUTOMATION_DISABLED: "Doppelpositionsautomatisierung deaktiviert",
    APPROVED: "Genehmigt",
    BILLABLE: "Abrechenbar",
    APPROVE: "Genehmigen",
    APPROVED_DURATION: "Genehmigte Zeit",
    TO_BE_APPROVED: "genehmigt",
    NOT_ALLOWED: "Ressource nicht erlaubt",
    NOT_ALLOWED_DESC: "Die angeforderte Ressource ist nicht zulässig.",
    DAY_REPORTS: "Tages arbeits rapporte",
    FILTER_TO_APPROVE: "Zum Genehmigen filtern",
    FILES: "Files",
    APPROVED_QUANTITY: "Zugelassene Menge",
    REPORT: "Tage rapport",
    DONE: "Gemacht",
    PLANNED_FOR_TODAY: "Für heute geplant",
    YESTERDAY: "Gestern",
    REQUESTED_MARGINALITY_PDF: "ASIP pdf gesendet via email.",
    ERROR_MARGINALITY_PDF: "Fehler senden ASIP pdf",
    TO_CONCORD_TO_OFFER: "Zu Vereinbaren > Offer",
    FIXED_PRICE_ENGINEERING_WARRANTY: "Pauschal Engineering/Garantie",
    CREATE_CUSTOM_CONTACT: "Erstellen kontakt",
    NO_WORK_REPORTS: "Keine Arbeitsrapport",
    NO_WORK_REPORTS_TO_APPROVE: "Nessun berichtete über die Genehmigung",
    DOC_CHANGED: "Ändert Dokument",
    UPDATE_DOC_BEFORE_PDF: "Hochladen Dokument vor PDF laden",
    ENGINEERING_WARRANTY_LINE_IN_NORMAL_FIXED_PRICE: "Die Technik-/Garantieleitung kann nicht in einer normalen Festpreislinie enthalten sein.",
    NORMAL_LINE_IN_ENGINEERING_WARRANTY_FIXED_PRICE: "Eine normale Leitung kann nicht in einer Technik-/Garantie-Festpreisleitung sein.",
    CREATE_CONTRACT: "Vertrag erstellen",
    WARRANTY_STATUS: "Status der Garantie",
    OVERVIEW: "Overview",
    EXPIRE: "Ablaufen",
    CREATE_INTEGRATIVE_OFFER: "Integratives Angebot erstellen",
    CREATE_EMPTY_DOC: "Leeres Angebot erstellen",
    CREATE_OFFER_BY_OFFER: "Angebot aus bestehendem Angebot erstellen",
    CREATE_OFFER: "Angebot erstellen",
    CREATE_WARRANTY: "Garantie erstellen",
    BILLS_OF_MATERIALS: "Stücklisten",
    HOURS_LIST: "Stundenliste",
    MATERIALS_LIST: "Liste der Materialien",
    APPROVAL_NOTES: "Genehmigungsvermerke",
    CLIENT_NOT_FOUND: "Kunde nicht gefund",
    WORK_FINISHED: "Arbeit beendet",
    STATUS_TO_BE_PAID: "Warten auf Zahlung",
    STATUS_TO_BE_BILLED: "in Rechnung stellen",
    STATUS_TO_BE_APPROVED: "zu genehmigen",
    STATUS_IN_EXECUTION: "Betrieb",
    ERROR_SENDING_EMAIL: "Fehler Schicken email",
    SEND: "Schicken",
    VIA_EMAIL: "via email",
    PLEASE_INSERT_EMAIL: "Bitte email hinzufugen",
    SUBJECT: "Objekt",
    PLEASE_INSERT_SUBJECT: "Bitte objekt hinzufugen",
    CONTENT: "Content",
    SEND_ME: "Schicken mir",
    QUICK_SEND: "Schenll schicken",
    CLIENT_EMAIL: "Kunde email",
    CLIENT_CONTACT_EMAIL: "Kunde kontakt email",
    CHOOSE_RECIPIENT: "Empfänger auswählen",
    SEND_PDF: "Schicken PDF",
    DOCUMENT_CONTACT_TYPE_MISSING: "Fehlt document contact typ",
    UNDER_MINIMUM_PRICE: "Unten mindesten preise",
    TOO_MUCH_DISCOUNT: "Zu viel rabat",
    MARGINALITY_CONSUNTIVE: "Endgültige Ränder",
    RECEIVED_PLANS_DATE: "Eingangsdatum des Plans",
    HAS_REPORT_ADDED: "einen Standort hinzugefügt",
    HAS_REPORTED_MORE: "berichtet mehr als das Angebot",
    HAS_REPORTED_MORE_AND_ADDED: "Er meldete mehr als das Angebot und fügte einen Trank hinzu",
    HAS_FINSHED_REPORT: "hat einen Job beendet",
    HAS_OFFERED_UNDER_PRICE: "wurde unterbewertet verkauft",
    MINIMUM_PRICE: "mindensten preise",
    HAS_WON_OFFER: "hat das offer gewonnen",
    BREAK_CANT_BE_BIGGER_THAN_TOTAL_TIME: "Die Pause darf nicht länger als die Gesamtzeit sein",
    DAY_REPORT: "Tages rapport",
    DETAILS: "Einzelheiten",
    TOTAL_TIME: "Gesamt zeit",
    CREATE_MEASURE: "Maß erstellen",
    MEASURES: "Maßnahmen",
    MATERIALS: "Materialien",
    APPROVED_BY: "Genehmigt durch",
    ARE_YOU_SURE_CANT_BE_UNDONE: "Sind Sie sicher, dass Sie speichern möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    MEASURED_BY: "Gemessen von",
    MEASURED: "Gemessen am",
    TITLE: "Title",
    DEFAULT_BILL_ADDRESS: "Standard rechnung addresse",
    DEFAULT_SHIP_ADDRESS: "Standard lieferung addresse",
    ID: "Id",
    STATE: "State",
    TABLE: "Tabelle",
    LIST: "Liste",
    APPALT: "Vertrag",
    OFFER_NAME: "Offer Name",
    CLIENT_HAS_NO_ADDRESS: "Kunde hat keine rechnung addresse",
    BY_HAS_NO_ADDRESS: "Von Kunde hat keine rechnung addresse",
    INVOICE_TO_HAS_NO_ADDRESS: "Rechnung an hat keine rechnung addresse",
    FISCAL_CODE: "Fiscal Code",
    BUDGET_MATERIAL: "Budget Material",
    BUDGET_TIME: "Budget Zeit",
    DOC_LEVELS_EMPTY_NAME: "Dokument Levels haben lehre name",
    MINIMUM_VALUE: "Minimum wert",
    MAXIMUM_VALUE: "Maximal wert",
    BILL_PREVIEW_ORDER: "Rechnung vorshau für Auftrag",
    AFTER_DAY_REPORT: "Nach Arbeitsrapporte",
    BILL_PREVIEW: "Rechnung vorshau",
    HIDE_EMPTY: "Leer verstecken",
    AUTO_SELECT_TITLE: "Auto Auswähl Title",
    AUTO_SELECT_TWINS: "Auto Auswähl Zwillinge",
    TOTAL_BEFORE_DISCOUNTS: "Total vor rabatt",
    NOT_VALID_NUMBER: "Nicht gültig Zahl",
    INSERT_WORK_SPACE_TO_SIGN_IN: "Geben Sie Ihren Workspace-Namen ein, um sich bei Ihrem DRYapp-Konto anzumelden.",
    INSERT_YOUR_WORKSPACE_NAME: "Geben Sie Ihren Arbeitsbereichsnamen ein...",
    PROCEED: "Weiter",
    INSERT_AT_LEAST_ONE_CHARACTER: "Geben Sie mindestens ein Zeichen ein",
    WORKSPACE_NOT_FOUND: "Arbeitsbereich nicht gefunden, überprüfen Sie die Rechtschreibung.",
    ERROR_STARTING_APP: "Fehler beim Starten der App.",
    CONTACT_SUPPORT: "Kontaktieren Sie Support",
    CUSTOM_WORKSPACE: "Benutzerdefinierter Arbeitsbereich",
    PRIMARY_SERVER_URL: "URL des primären Servers",
    SECONDARY_SERVER_URL: "Sekundärserver-URL",
    PRIMARY_URL_CANNOT_BE_EMPTY: "Die URL des Primärservers darf nicht leer sein.",
    SECONDARY_URL_CANNOT_BE_EMPTY: "Die URL des sekundären Servers darf nicht leer sein.",
    CHECKING_WORKSPACE: "Arbeitsbereich prüfen:",
    CHECKING_URL: "URL prüfen:",
    SIGN_IN_INTO: "Melden Sie sich im Arbeitsbereich an",
    ERROR_SIGNING_IN: "Fehler beim Anmelden",
    WRONG_USERNAME_OR_PASSWORD: "Benutzername oder Passwort falsch",
    USERNAME_CANNOT_BE_EMPTY: "Benutzername darf nicht leer sein",
    PASSWORD_CANNOT_BE_EMPTY: "Passwort kann nicht leer sein",
    ERROR_LOGOUT: "Fehler beim Abmelden",
    ACCESSIBLE: "Zugänglich",
    AQUIFER: "Grundwasserleiter",
    INTERNAL_NAME_CAN_BE_MAX_100: "Der interne Name darf nicht länger als 100 Zeichen sein",
    YOUR_SESSION_IS_NOT_VALID: "Ihre Sitzung ist ungültig",
    ERROR_LOADING_DOCUMENT: "Fehler unterladen dokument",
    ERROR_LOADING_CONSTRUCTION_SITES: "Fehler beim Laden von Baustellen",
    ERROR_ASSIGING_CONSTRUCTION_SITE: "Fehler bei der Zuordnung der Baustelle",
    ERROR_LOADING_DAY_REPORTS: "Fehler beim Laden der Tagesberichte",
    ERROR_LOADING_DAY_REPORT: "Fehler beim Laden der Tageberichte",
    ERROR_CREATING_DAY_REPORT: "Fehler beim Erstellen des Tagesberichts",
    ERROR_APPROVING_DAY_REPORT: "Fehler beim Genehmigen des Tagesberichts",
    ERROR_LOADING_ACTIVITIES: "Fehler beim Laden der Aktivitäten",
    SELECT_BRANCH: 'Zweig auswählen',
    SELECT_CATALOG: 'Katalog auswählen',
    SELECT_ADDRESS: 'Adresse auswählen',
    SELECT_TYPE: 'Typ auswählen',
    SELECT_COUNTRY: 'Land auswählen',
    SELECT_STATE: 'Staat wählen',
    SELECT_STATUS: 'Status auswählen',
    SELECT_VAT: 'Wählen Sie Mehrwertsteuer aus',
    CLICK_TO_SELECT: 'Klicke um auszuwählen',
    KEEP_ORDER: "Ordnung lassen",
    WIN_OFFER: "Offer gewinnen",
    ERROR_LOADING_WEEK_REPORT: "Fehler laden woch rapporte",
    ARE_YOU_SURE_YOU_WANT_UNAPPROVE_THE_DAY_REPORT: "Sind Sie sicher, dass Sie die Genehmigung des Tagesberichts aufheben möchten?",
    CONTINUE: "Weitermachen?",
    ERROR_UNAPPROVE_DAY_REPORT: "Fehler beim Nichtgenehmigen des Tagesberichts",
    ARE_YOU_SURE_YOU_WANT_DELETE_THE_DAY_REPORT: "Sind Sie sicher, dass Sie den Tagesbericht löschen möchten?",
    ERROR_DELETING_DAY_REPORT: "Fehler beim Löschen des Tagesberichts",
    UNAPPROVE: "Nicht genehmigen",
    ERROR_UPLOADING_FILE: "Fehler hochladen file.",
    SELECT_FILE_TYPE_BEFORE_UPLOADING: "Bitte wählen sie der file typ vor der file hochladen",
    ERROR_UPDATING_FILE: "Fehler beim Aktualisieren der Datei",
    ERROR_UPLOADING_SIGNATURE: "Fehler beim hochladen Unterschrift",
    CANCEL_ORDER: "Bestellung stornieren",
    ERROR_CANCELING_ORDER: "Fehler beim Stornieren der Bestellung",
    CONTINUE_CANCEL_ORDER_2: "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
    CONTINUE_CANCEL_ORDER: "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
    PIT_WALL_M2: 'Boden, Wanne, Platte gesamt m2:',
    TOTAL_VAT_EXCL: 'Gesamte exkl. MwSt.',
    ROUNDING: 'Rundung',
    TOTAL_VAT_INCL: 'Gesamte inkl. MwSt.',
    PAYMENT_DATE: 'Zahlungsdatum',
    PAYMENT_AMOUNT: 'Zahlungsbetrag',
    BETON: 'Beton',
    FINANCIAL_REPORT: 'Financial Report',
    FIRST_PAGE: 'First page',
    DAY_REPORT_EMPTY_CONTINUE: "Arbeits rapporte lehr, bist du siccher willst du weiter gehen?",
    ERROR_IN_TIME_MANAGEMENT: "Fehler in zeiten.",
    DOC_FOOTER_SET_EQUAL_VAT: "Setzen MwSt.",
    DAY_TOTAL_TIME: "Gesamt tage zeit",
    SHOW_WEEK_REPORT: "Woche report anzeigen",
    INDUSTRY: "Höflichkeitsformel",
    SELECT_INDUSTRY: "Auswahl",
    MEASURE_APPROVED_OF: "Genehmigt {{approved}} von {{of}}",
    EDIT_ORDER_DETAILS: "Bearbeiten auftrag",
    WARRANTY_FOR: 'Garantie für',
    SELECT_CONTRACT: 'Auftrag auswahl',
    CREATE_CUSTOM_POSITION_ERROR: "Error creating custom position",
    ERROR_CONNECTING_SERVER: "Fehler in server verbindung",
    DATE_BEFORE_WEEK_START: "Datum vorher woche nicht moglich.",
    CHOOSE_ADDRESS_AND_LOCATION: "Auswahl adresse und ort",
    MAP_MODAL_IF_YOU_WANT_TO_SAVE_ADDRESS: "Wenn Sie die Adresse speichern und den Zeiger auf der Karte bewegen möchten, klicken Sie auf \"Speichern\".",
    SAVE_ADDRESS: "Adresse Speichern",
    ABSENCE: "Abwesenheit",
    SELECT_ABSENCE_TYPE: "Art der Abwesenheit auswählen",
    ERROR_INSERT_A_START_TIME_SMALLER_THAN_END_TIME: "Geben Sie eine Startzeit ein, die kleiner als die Endzeit ist.",
    ABSENCE_DATE_OUT_OF_LIMIT: "Abwesenheit außerhalb der Frist",
    ERROR_UPDATING_ABSENCE: "Fehler bei der Abwesenheitsaktualisierung",
    ERROR_CREATING_ABSENCE: "Fehler bei der Abwesenheitserstellung",
    ERROR_DELETING_ABSENCE: "Fehler beim Löschen der Abwesenheit",
    PLEASE_CHOOSE_AN_ABSENCE_TYPE: "Bitte wählen Sie eine Abwesenheitsart aus",
    OFFICE_NOTES: "Büronotizen",
    UPDATE_ABSENCE: "Abwesenheit aktualisieren",
    CREATE_ABSENCE: "Abwesenheit erstellen",
    TRAVEL: "Fahrt",
    WORK: "Arbeit",
    NO_CATALOGS: "Keine Kataloge",
    RELOAD_CATALOGS: "Kataloge neu laden",
    NO_CATALOG_SELECTED: "Kein Katalog ausgewählt",
    PHOTOS: "Photos",
    REVERSE: "Reverse",
    ORDER_BY: "Order by",
    MEASURE: "Messung",
    PUBLIC_PROCUREMENT: "Öffentliche Beschaffung",
    CAN_NOT_APPROVE_REPORT_USE_MEASURE: "Kann den Bericht nicht genehmigen, verwenden Sie die Maßnahme",
    UNAPPROVE_MEASURE: "Maßnahme nicht genehmigen",
    ERROR_UNAPPROVING_DAY_REPORT: "Fehler beim Nichtgenehmigen des Tagesberichts",
    OPEN_AND_ASSIGNED_TO_WORKER: 'Offen und zugewiesen an mitarbeiter',
    NORMAL_INVOICE: 'Normale Rechnung',
    ADVANCE_INVOICE: 'Vorausrechnung',
    MIDDLE_INVOICE: 'Mittelrechnung',
    AMOUNT_INVOICE: 'Rechnungsbetrag',
    SYNTHETIC: 'Synthetisch',
    DETAILS_WITHOUT_PRICES: 'Details ohne Preise',
    DETAILS_WITH_PRICES: 'Details mit Preisen',
    FINAL_INVOICE: 'Endrechnung',
    INVOICE_NUMBER: 'Rechnungsnummer',
    ADD_SIMILAR_POSITIONS: 'Ähnliche Positionen hinzufügen',
    DOC_TEST_CONTRACT_NAME_EXISTS: 'Vertragsname existiert bereits',
    ALL_LEVELS: 'Alle Ebenen',
    BILL_EMAIL: 'Rechnung per E-Mail',
    ORIGINAL_OFFER_YEAR: 'Ursprüngliches Angebotsjahr',
    FILTER: 'Filter',
    CHOOSE_OTHER: 'Wählen Sie andere',
    OFFLINE: 'Offline',
    NO_ITEMS_TO_SYNC: 'Keine Elemente zum Synchronisieren',
    BEGIN: 'Beginnen',
    END: 'Ende',
    NO_INTERNET_CONNECTION_NO_SAVED_DATA: 'Keine Internetverbindung, keine gespeicherten Daten',
    DAY_REPORT_SPLIT_BY: 'Tagesbericht aufteilen nach',
    DAY_REPORT_SPLIT_WITH: 'Tagesbericht aufteilen mit',
    SAVE_AND_SPLIT: 'Speichern und aufteilen',
    CHOOSE_RESOURCES: 'Ressourcen auswählen',
    APPROVE_AND_CLOSE: 'Genehmigen und schließen',
    OTHER: 'Andere',
    THEORICAL: 'Theoretisch',
    WRONG_PREVIOUS_TWIN: 'Falscher vorheriger Zwilling, maximaler Wert: {{max}}',
    PLEASE_CHOOSE_A_WORK_GROUP: 'Bitte wählen Sie eine Arbeitsgruppe',
    PLEASE_CHOOSE_A_LEVEL: 'Bitte wählen Sie eine Ebene',
    ADD_NOTES: 'Notizen hinzufügen',
    YOU_USED_A_LOT_OF_TIME_SOMETHING_HAPPENED: 'Du hast benuzt mehr zeit als gerechnet, ist etwas passiert?',
    ERROR_PLACING_MATERIALS_AND_TIME: 'Fehler beim Platzieren von Materialien und Zeit',
    SURE_TO_LOGOUT: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
    SURE_TO_LOGOUT_DESC: 'Sie werden von der App abgemeldet. Alle Entwurfsdokumente gehen verloren.',
    ADVANCEMENTS_DETAILS_LINE: 'Vorschussdetails für Zeile',
    NOT_VALID_CONTRACT: 'Nicht gültiger Vertrag',
    APPROVABLE: 'Genehmigungsfähig',
    PREVIOUS_MEASURE: 'Vorherige Maßnahme',
    MEASURE_HELP: 'Maßnahmenhilfe',
    MEASURE_HELP_DESC: 'Die Maßnahme wird verwendet, um die Arbeit zu messen, die in einem bestimmten Zeitraum durchgeführt wurde. Sie können die Maßnahme erstellen, indem Sie die Arbeitszeit und die Materialien hinzufügen, die für die Arbeit verwendet wurden.',
    APPROVED_HOURS: 'Genehmigte Stunden',
    BUDGET_HOURS: 'Budgetstunden',
    OFFLINE_MEASURES: 'Offline-Maßnahmen',
    ERROR_LOADING_OFFLINE_MEASURES: 'Fehler beim Laden von Offline-Maßnahmen',
    ERROR_DELETING_OFFLINE_MEASURE: 'Fehler beim Löschen der Offline-Maßnahme',
    MEASURES_HELP: 'Maßnahmenhilfe',
    MEASURES_HELP_TEXT: 'Maßnahmen werden verwendet, um Tagesberichte zu genehmigen und Rechnungen zu erstellen.\n\nIn dieser Ansicht wird die Liste der Maßnahmen für den ausgewählten Vertrag angezeigt. Über den blauen Download-Button kann die Maßnahmen-Excel-Datei für die zu erstellenden Maßnahmen heruntergeladen werden. Die Maßnahmen können durch Klicken auf den blauen Plus-Button erstellt werden.\n\nBei den vorhandenen Maßnahmen sind die Nummer der erstellten Maßnahme, das Erstellungsdatum, die Person, die sie erstellt hat, und die Anzahl der über die ausgewählte Maßnahme genehmigten Tagesberichte zu sehen.\nWenn für diese Maßnahme eine Rechnung vorhanden ist, wird diese mit dem Rechnungssymbol angezeigt. Um die Rechnung zu öffnen, klicken Sie auf das Symbol. Wenn für die Rechnung eine Gutschrift vorhanden ist, wird ein Dokumentsymbol mit einem Minus angezeigt.\nFür jede Maßnahme kann die Excel-Datei mit den Details heruntergeladen werden.\n\nStandardmäßig wird für jeden Tag eine Fahrt vorgeschlagen. Wenn an einem Tag mehrere Fahrten stattgefunden haben, wird nur eine angezeigt.',
    MEASURES_HELP_INVOICE: 'Rechnung',
    MEASURES_HELP_CREDIT_NOTE: 'Gutschrift',
    MEASURES_HELP_DOWNLOAD: 'Download',
    MEASURES_HELP_DAY_REPORT: 'Tagesbericht',
    ERROR_SAVING_WORK_PROGRESS_STATUS: 'Fehler beim Speichern des Arbeitsfortschrittsstatus',
    CONSTRUCTION_SITE_NOTES: 'Baustellennotizen',
    CONTRACT_NOTES: 'Vertragsnotizen',
    OFFER_NOTES: 'Angebotsnotizen',
    NOTES_FOR_DAY_REPORT: 'Notizen für Tagesbericht',
    NO_NOTES_TO_COPY: 'Keine Notizen zum Kopieren',
    ERROR_LOADING_DOCS_OFFLINE: 'Fehler beim Laden von Dokumenten offline',
    ERROR_DELETING_DOCS_OFFLINE: 'Fehler beim Löschen von Dokumenten offline',
    DOCS_OFFLINE: 'Dokumente offline',
    CREATED: 'Erstellt',
    UPDATED: 'Aktualisiert',
    ERROR_UPDATING_DOCS_OFFLINE: 'Fehler beim Aktualisieren von Dokumenten offline',
    ERROR_OPENING_OFFLINE_DOC: 'Fehler beim Öffnen des Offline-Dokuments',
    NOT_AVAILABLE_OFFLINE: 'Nicht verfügbar offline',
    MAPS: 'Karten',
    ADDRESS: 'Adresse',
    SELECT_DOCUMENT_ORDER: 'Wählen Sie die Dokumentenreihenfolge',
    NO_VAT: 'Keine MwSt.',
    LANGUAGE: 'Sprache',
    ALL_DAY: 'Ganzer Tag',
    START_DATE: 'Startdatum',
    END_DATE: 'Enddatum',
    SETTINGS: 'Einstellungen',
    SHOW_DAYS_BY_WEEK: 'Tage pro Woche anzeigen',
    YOU_ARE_NOT_AUTHORIZED_TO_CREATE_CLIENTS: 'Sie sind nicht berechtigt, Kunden zu erstellen',
    YOU_ARE_NOT_AUTHORIZED_TO_UPDATE_CLIENTS: 'Sie sind nicht berechtigt, Kunden zu aktualisieren',
    SAVED_OFFLINE: 'Offline gespeichert',
    DRAFT: 'Entwurf',
    OFFERS_TO_CHECK: 'Angebote zu überprüfen',
    CONSTRUCTION_SITE_NAME: 'Baustellenname',
    CATALOG: 'Katalog',
    CONTRACT: 'Vertrag',
    DAY_REPORT_WRONG_PREVIOUS_TWINS: 'Falscher vorheriger Zwilling. Siccher, dass Sie fortfahren möchten?',
    DEPARTURE_TIME_TOO_EARLY: 'Abfahrtszeit zu früh, hinzufugen der Uhrzeit nicht der Zeit.',
    PLEASE_SET_ALL_DOCUMENT_CONTACT_TYPES: 'Bitte setzen Sie alle Dokumentkontakttypen',
    DOC_DRAFTED_CREATE: 'hat eine Entwurfsdokument erstellt',
    DOC_DRAFTED_UPDATE: 'hat ein Entwurfsdokument aktualisiert',
    NO_CONTRACTS_FOR_CONSTRUCTION_SITE: 'Keine Verträge für diese Baustelle',
    DUPLICATE_DOC: 'Duplizieren Dokument',
    DUPLICATE_CONTRACT: 'Duplizieren Vertrag',
    DUPLICATE_IN_NEW_CONTRACT: 'Duplizieren in neuem Vertrag',
    DUPLICATE_CONSTRUCTION_SITE: 'Duplizieren Baustelle',
    DUPLICATE_IN_NEW_CONSTRUCTION_SITE_AND_NEW_CONTRACT: 'Duplizieren in neuer Baustelle und neuem Vertrag',
    CHANGE_DESTINATION: 'Wechseln Sie das Ziel',
    MOVE: 'Bewegen',
    SEARCH_CLIENT_TO_IMPORT_FROM_GOOGLE: 'Suchen Sie den Kunden zum Importieren aus Google',
    ERROR_GETTING_CLIENT_DETAILS: 'Fehler beim Abrufen der Kundeninformationen',
    IMPORT: 'Importieren',
    EXTERNAL_WORKS_INVOICES: 'Externearbeit Rechnungen',
    SUPPLIER: 'Lieferant',
    REFERENCE: 'Referenz',
    INVOICE_DATE: 'Rechnungsdatum',
    INSERTED_BY: 'Eingefügt von',
    EXTERNAL_WORK_INVOICE: 'Externe Arbeit Rechnung',
    NO_DAY_REPORT: 'Kein Tagesbericht',
    DAY_REPORT_DATE: 'Tagesberichtsdatum',
    DAY_REPORT_NOTES: 'Tagesberichtsnotizen',
    NEW: 'Neu',
    CHOOSE_DAY_REPORT_FOR_EXTERNAL_WORK_INVOICE: 'Wählen Sie den Tagesbericht für die externe Arbeit Rechnung',
    CHOOSE_EXTERNAL_WORK_INVOICE: 'Wählen Sie die externe Arbeit Rechnung',
    HOURS_LIST_BUDGET_EFFECTIVE: 'Stundenliste Budget/effektiv',
    INDUSTRIAL_ACCOUNTING: 'Baustellenbuchhaltung',
    CHOOSE_CONTRACTS: 'Verträge auswählen',
    DOWNLOAD: 'Download',
  },
};

// snippet to verify translation completeness
let { en, it, de }: any = translations;

Object.keys(en).forEach((key) => {
  if (it[key] === undefined) {
    console.warn(
      "Missing key: ",
      key,
      " from it translation ( ",
      key,
      ': "", )'
    );
  }
  if (de[key] === undefined) {
    console.warn(
      "Missing key: ",
      key,
      " from de translation ( ",
      key,
      ': "", )'
    );
  }
});

export default translations;
