import React, { useState } from "react";
import { Catalog, CatalogTypes, Contract, Doc, DocType } from "../../../models";
import { Platform, Pressable, Text, TextInput, View } from "react-native";
import { t } from 'i18n-js';
import DocTypeDropdown from "../../../components/DocTypeDropdown";
import CatalogDropdown from "../../../components/CatalogDropdown";
import { DocMode, catalogGroupToCatalog, catalogToLines } from "../utils";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import WarrantyForDropdown from "../../../components/WarrantyForDropdown/WarrantyForDropdown";
import getTheme from "../../../constants/Colors";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import NativeDocTypeChoose from "./NativeDocTypeChoose";
import NativeCatalogChoose from "./NativeCatalogChoose";

interface Props {
  doc: Doc;
  setDoc: (doc: Doc) => void;
  mode: number;
  contract: Contract
  setContract: (contract: Contract) => void
  contacts: Contract[]
}

const DocDetails = ({doc, setDoc, mode, contacts, setContract, contract}: Props) => {

  const theme = getTheme()

  const isNativeApp = Platform.OS === 'ios' || Platform.OS === 'android'

  const salesFillBoMVariable = useSelector((state: Reducer) => state.user.permissions.salesFillBoMVariable)

  const [name, setName] = useState<string>(doc.Name);

  const [showNativeTypePicker, setShowNativeTypePicker] = useState(
    (doc.Type == undefined || doc.Type.ID == 0) && isNativeApp
  )
  const _toggleNativeTypePicker = () => {
    if (isNativeApp) {
      setShowNativeTypePicker(s => !s || doc.Type == undefined || doc.Type.ID == 0)
    }
  }
  const [showNativeCatalogPicker, setShowNativeCatalogPicker] = useState(
    (doc.Catalog == undefined || doc.Catalog.ID == 0) && (doc.Type != undefined && doc.Type.ID != 0) && isNativeApp
  )
  const _toggleNativeCatalogPicker = () => {
    if (isNativeApp) {
      setShowNativeCatalogPicker(s => !s || doc.Catalog == undefined || doc.Catalog.ID == 0)
    }
  }

  const _name = (name: string) => {
    setName(name);
    setDoc({
      ...doc,
      Name: name,
    });
  };

  const _type = (type: DocType) => {
    setDoc({
      ...doc,
      Type: type,
      DocumentContacts: doc.DocumentContacts.map((i) => {
        return {
          ...i,
          DocTypeID: type.ID,
        }
      })
    });
  };
  const _typeNative = (type: DocType) => {
    _type(type)
    setShowNativeTypePicker(false)
    setShowNativeCatalogPicker(true)
  }

  const _catalog = (catalog: Catalog) => {
    if (mode === DocMode.CREATE) {
      const lines = catalogToLines(catalog, doc.Levels, salesFillBoMVariable);
      setDoc({
        ...doc,
        Catalog: catalogGroupToCatalog(catalog, doc.Levels),
        CatalogID: catalog.ID,
        Lines: lines,
      });
    } else {
      setDoc({
        ...doc,
        Catalog: catalogGroupToCatalog(catalog, doc.Levels),
        CatalogID: catalog.ID,
      });
    }
  };

  const _catalogNative = (catalog: Catalog) => {
    _catalog(catalog)
    setShowNativeCatalogPicker(false)
  }

  const _setWarrantyForId = (warrantyForId?: number) => {
    setContract({...contract, WarrantyFor: warrantyForId})
  }

  const _receivedPlansDate = (date?: Date) => {
    setDoc({
      ...doc,
      ReceivedPlansDate: date,
    });
  }

  const _originalOfferYear = (year: String) => {
    setDoc({
      ...doc,
      OriginalOfferYear: year == '' ? undefined : Number(year),
    })
  }

  const _startReceivedPlansDate = () => _receivedPlansDate(new Date());
  const _stopReceivedPlansDate = () => _receivedPlansDate();

  return (
    <View style={{paddingTop: 15}}>
      <View style={{flexDirection: 'row', alignItems: "center", paddingVertical: 6}}>
        <Text style={{paddingRight: 6}}>{t('OFFER_NAME')}:</Text>
        <TextInput
          value={name}
          onChangeText={_name}
          style={{
            flex: 1,
            backgroundColor: 'white',
            padding: 9,
            borderRadius: 6,
          }}
        />
        <Text style={{paddingHorizontal: 6}}>{t('DOC_TYPE')}:</Text>
        {isNativeApp ? (
          <Pressable
            style={{paddingHorizontal: 10, flexDirection: 'row'}}
            onPress={_toggleNativeTypePicker}
          >
            <Text>{doc.Type?.Name || '-'}</Text>
            <MaterialCommunityIcons
              name={showNativeTypePicker ? 'chevron-down' : 'chevron-up'}
              size={20}
              style={{paddingLeft: 5}}
            />
          </Pressable>
        ) : (
          <DocTypeDropdown type={doc.Type} setType={_type} contractStatus={contract.Status} />
        )}
        <Text style={{paddingHorizontal: 6}}>{t('CATALOG_DROPDOWN')}:</Text>
        {isNativeApp ? (
          <Pressable
            style={{paddingHorizontal: 10, flexDirection: 'row'}}
            onPress={_toggleNativeCatalogPicker}
          >
            <Text>{doc.Catalog?.Name || '-'}</Text>
            <MaterialCommunityIcons
              name={showNativeTypePicker ? 'chevron-down' : 'chevron-up'}
              size={20}
              style={{paddingLeft: 5}}
            />
          </Pressable>
        ) : (
          <CatalogDropdown catalog={doc.Catalog} setCatalog={_catalog} docType={doc.Type}/>
        )}
        {doc.Type?.CatalogType == CatalogTypes.Warranty && (
          <>
            <Text style={{paddingHorizontal: 6}}>{t('WARRANTY_FOR')}:</Text>
            <WarrantyForDropdown
              contracts={contacts}
              setWarrantyForId={_setWarrantyForId}
              warrantyForId={contract.WarrantyFor}
            />
          </>
        )}
      </View>

      {showNativeTypePicker && (
        <View style={{paddingBottom: 15}}>
          <Text>{t('DOC_TYPE')}:</Text>
          <NativeDocTypeChoose
            value={doc.Type}
            onChange={_typeNative}
            contractStatus={contract.Status}
          />
        </View>
      )}

      {showNativeCatalogPicker && (
        <View style={{paddingBottom: 15}}>
          <Text>{t('CATALOG_DROPDOWN')}:</Text>
          <NativeCatalogChoose
            value={doc.Catalog}
            onChange={_catalogNative}
            docType={doc.Type}
          />
        </View>
      )}

      <View style={{flexDirection: "row", alignItems: "center", paddingVertical: 2}}>
        <Text style={{paddingRight: 2}}>{t('RECEIVED_PLANS_DATE')}:</Text>
        
        { doc.ReceivedPlansDate ? (
          <>
            <DatePicker
              value={doc.ReceivedPlansDate}
              onChange={_receivedPlansDate}
            />
            <MaterialCommunityIcons
              size={24}
              name="close"
              onPress={_stopReceivedPlansDate}
            />
          </>
        ) : (
          <Pressable 
            style={{
              width: 50,
              height: 30,
              backgroundColor: "white"
            }}
            onPress={_startReceivedPlansDate}
          />
        )}

        <Text style={{paddingRight: 2, paddingLeft: 10}}>{t('ORIGINAL_OFFER_YEAR')}:</Text>

        <TextInput
          keyboardType="decimal-pad"
          value={doc.OriginalOfferYear?.toString()}
          onChangeText={_originalOfferYear}
          style={{
            width: 60,
            backgroundColor: 'white',
            padding: 9,
            borderRadius: 6,
            borderColor: theme.border,
          }}
        />
      </View>
    </View>
  );
}

export default DocDetails;