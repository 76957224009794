import React from 'react';
import { View, Text } from "react-native";
import { t } from 'i18n-js';

import { Level, Line, LineLevel, LineSumType } from "../../../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import { PriceLevel } from '../../../../store/reducers/settingsReducer';
import Formatter from '../../../../utils/formatters';


const parseDiscount = (lineLevel:LineLevel):string => {
  if (lineLevel.Discount == null || lineLevel.Discount == 0) {
    return "";
  }

  if (lineLevel.DiscountNumeric) {
    return `${Formatter.number2.format(lineLevel.Discount)}`;
  } else {
    return `${Formatter.number2.format(lineLevel.Discount)} %`;
  }
}

interface Props {
  line:Line;
  lineLevel:LineLevel;
  level:Level;
  largeView:boolean;
  blockOffer: boolean;
}

const DocumentPreviewLineLevel = ({line, lineLevel, level, largeView, blockOffer}:Props) => {

  const showPriceLevel = useSelector((state:Reducer) => state.settings.showPriceLevel);
  const f2 = Formatter.number2.format;

  if (lineLevel.LineSumType == LineSumType.PRICE_LIST) {
    return (
      <View style={{flexDirection: "row"}} key={lineLevel.ID}>
        <View style={{flex: 1}}/>
        <Text style={{flex: 2, paddingLeft: 1}}>{level.Name}</Text>
        <Text style={{flex: 1, textAlign: 'center'}}>{t('FOR')}</Text>
        <Text style={{flex: 1}}>{line.UnitOfMeasure}</Text>
        <Text style={{flex: 1}}>{showPriceLevel > 0 && f2(lineLevel.Price)}</Text>
        <View style={{flex: 1}}/>
        {largeView && <View style={{flex: 2}}/>}
      </View>
    );
  }

  if (lineLevel.LineSumType == LineSumType.TO_CONCORD) {
    return (
      <View style={{flexDirection: "row"}}>
        <View style={{flex: 1}}/>
        <Text style={{flex: 1, paddingLeft: 1}}>{level.Name}</Text>
        <Text style={{flex: 1, textAlign:"right"}}>{t('TO_CONCORD')}</Text>
        <Text style={{flex: 1, textAlign: 'center'}}>({lineLevel.Quantity})</Text>
        <Text style={{flex: 1}}>{line.UnitOfMeasure}</Text>
        <Text style={{flex: 1}}>{showPriceLevel > 0 && f2(lineLevel.Price)}</Text>
        {largeView && <Text style={{flex: 1}}>{showPriceLevel > 0 && parseDiscount(lineLevel)}</Text>}
        <Text style={{flex: 1}}>{showPriceLevel > 0 && `(${f2(lineLevel.LineTotal)})`}</Text>
        {largeView && <Text style={{flex: 1}}>{showPriceLevel > 0 && lineLevel.FixedPriceBlock}</Text>}
      </View>
    );
  }

  let showPrice = showPriceLevel > PriceLevel.NO_PRICE;
  if (showPriceLevel == PriceLevel.CLIENT) {
    if (blockOffer) {
      showPrice = false;
    } else if (lineLevel.FixedPriceBlock?.length > 0) {
      showPrice = false;
    }
  }
  const priceColor = !blockOffer && (!lineLevel.FixedPriceBlock || lineLevel.FixedPriceBlock.length == 0) ? 'black' : 'gray';

  return (
    <View style={{flexDirection: "row"}}>
      <View style={{flex: 1}}/>
      <Text style={{flex: 2}}>{level.Name}</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>{lineLevel.Quantity}</Text>
      <Text style={{flex: 1}}>{line.UnitOfMeasure}</Text>
      <Text style={{flex: 1, color: priceColor}}>{showPrice && f2(lineLevel.Price)}</Text>
      {largeView && <Text style={{flex: 1, color: priceColor}}>{showPrice && parseDiscount(lineLevel)}</Text>}
      <Text style={{flex: 1, color: priceColor}}>{showPrice && f2(lineLevel.LineTotal)}</Text>
      {largeView && <Text style={{flex: 1}}>{lineLevel.FixedPriceBlock.length > 0 && lineLevel.FixedPriceBlock}</Text>}
    </View>
  );
}

export default DocumentPreviewLineLevel;