import React from 'react';
import { Version } from "../../models";
import { Pressable, Text, View } from "react-native";
import { t } from 'i18n-js';
import Status from "../Status";

interface Props {
  item: Version;
  onPress: (item: Version) => void;
}

const VersionItem = ({item, onPress}: Props) => {

  const date:Date = new Date(item.UpdatedDate);

  const _onPress = () => {
    onPress(item);
  }

  // TODO remove comment
  return (
    <Pressable
      onPress={_onPress}
      style={{
        flexDirection: 'column',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        width: 300,
        paddingVertical: 10
      }}
    >
      <Text>{t('VERSION')}: {item.Version}</Text>
      <Text>
        {t('UPDATE_DATE')}: {date.toLocaleDateString()} {date.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})}
      </Text>
      <View style={{flexDirection: 'row', justifyContent: "space-between"}}>
        <Text>{t('STATUS')}</Text>
        <Status status={item.Status} size={"m"}/>
      </View>
    </Pressable>
  );
}

export default VersionItem;