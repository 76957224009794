import React from "react";
import { t } from "i18n-js";
import { TextInput, Text, View  } from "react-native";
import getTheme from "../../constants/Colors";

interface Props {
  label: string;
  value: string;
  setValue: (value: string) => void;
  maxLength?: number;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
}

const LabelInput = ({label, value, setValue, maxLength, autoCapitalize}: Props) => {
  
  const theme = getTheme();

  return (
    <View style={{flexDirection: 'row', paddingTop: 2, alignItems: 'center'}}>
      <Text style={{width: "15%"}}>{t(label)}</Text>
      <TextInput
        value={value}
        onChangeText={setValue}
        style={{
          flex: 1,
          backgroundColor: 'white',
          paddingVertical: 8,
          paddingHorizontal: 12,
          borderRadius: 5,
          borderColor: theme.secondaryBackground,
          borderWidth: 1,
        }}
        maxLength={maxLength}
        autoCapitalize={autoCapitalize}
      />
    </View>
  );
}

export default LabelInput;