import React from "react";
import { DocumentContactType } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import NativeModalPicker from "../NativeModalPicker";

interface Props {
  type?: DocumentContactType;
  setType: (type: DocumentContactType) => void;
  disabled?: boolean;
}

const DocumentContactTypeSelector = ({type, setType, disabled = false}:Props) => {

  const documentContactTypes = useSelector((state:Reducer) => state.doc.documentContactTypes);

  const _change = (selected: DocumentContactType) => {
    if (type?.ID == selected.ID || disabled) {
      return;
    }

    setType(selected)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={type}
      onValueChange={_change}
      options={documentContactTypes}
      showLabelAsOption={true}
      placeholder='SELECT_TYPE'
    />
  );
};

export default DocumentContactTypeSelector;