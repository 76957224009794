import { locale } from "i18n-js";

const number2 = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2});
const currency = (currency: string) => new Intl.NumberFormat(locale, { style: 'currency', currency });

const Formatter = {
  number2,
  currency,
};

export default Formatter;