import React, { createRef, useState } from "react";
import { Branch, ConstructionSite, ConstructionSiteType } from "../../models";
import { t } from "i18n-js";
import { testCreateConstructionSite, updateConstructionSite } from "../../api/ConstructionSitesAPI";
import { View, Text, Switch, ActivityIndicator, TextInput, NativeSyntheticEvent, StyleSheet, TextInputChangeEventData, Platform } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ConstructionSiteTypeDropdown from "../ConstructionSiteTypeDropdown";
import BranchDropdown from "../BranchDropdown";
import ErrorWarningsLister from "../ErrorWarningLister";
import { alert, alertConfirm } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-native-modal";
import getTheme from "../../constants/Colors";
import Button from "../Button";
import { sentryCapture } from "../../utils/sentryCapture";
import { GooglePlaceData, GooglePlaceDetail, GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import MapView, { LatLng, Marker, PROVIDER_GOOGLE } from "react-native-maps";
import { coordinatesToGoogleMapsLink, googleMapsLinkToCoordinates } from "../../hooks/geo";
import { reverseGeocodeAsync } from "expo-location";
import { Reducer } from "../../store/reducers";

interface Props {
  reload: () => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  constructionSite: ConstructionSite;
}

const EditConstructionSiteModal = ({reload, visible, setVisible, constructionSite}: Props) => {

  const dispatch = useDispatch();
  const theme = getTheme();

  const mapRef = createRef<MapView>();

  const token = useSelector((state: Reducer) => state.auth.token)
  const url = useSelector((state: Reducer) => state.auth.workSpace?.url || '')
  const branch = useSelector((state: Reducer) => state.user.branch.Maps)
  const branchCoordinates = googleMapsLinkToCoordinates(branch).coords

  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);

  const [coordinate, setCoordinate] = useState<LatLng>(
    googleMapsLinkToCoordinates(constructionSite.Maps || '').coords,
  )
  const [savedAddress, setSavedAddress] = useState<boolean>(false)
  const _saveAddress = () => setSavedAddress(true)
  const [c, setC] = useState<ConstructionSite>({...constructionSite});

  const _onClose = async () => {
    if (loading) {
      return;
    }

    if (changed) {
      const cancelChanges = await alertConfirm("CONSTRUCTION_SITE_CHANGED", "CONSTRUCTION_SITE_CHANGED_DESC");

      if (cancelChanges) {
        setChanged(false);
        setVisible(false);
        setC({...constructionSite});
      }
    } else {
      setVisible(false);
    }
  }

  const _update = async () => {
    
    const { errors, warnings } = testCreateConstructionSite(c);
    setErrors(errors);
    setWarnings(warnings);
    
    if (errors.length > 0) {
      await alert("ERROR_UPDATING_CONSTRUCTION_SITE","SOME_FIELDS_ARE_NOT_VALID");
      return;
    }

    if (warnings.length > 0) {
      const safeProceed = await alertConfirm("WARNING", "SOME_FIELDS_MIGHT_BE_NOT_COMPLETE");

      if (!safeProceed) {
        return;
      }
    }

    setLoading(true);
    try {
      setC(await updateConstructionSite(c));
      reload();
      setChanged(false);
      setVisible(false);
    } catch (e: any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR", "ERROR_UPDATING_CONSTRUCTION_SITE");
      }
    } finally {
      setLoading(false);
    }
  }

  const _nameChange = (name: string) => {
    setChanged(true);
    setC({...c, Name: name});
  }

  const _typeChange = (type: ConstructionSiteType) => {
    setChanged(true);
    setC({...c, TypeID: type.ID, Type: type});
  }

  const _branchChange = (branch: Branch) => {
    setChanged(true);
    setC({...c, BranchID: branch.ID, Branch: branch});
  }

  const _address = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setChanged(true)
    setC({
      ...c,
      Address: e.nativeEvent.text,
      Maps: '.',
    })
  }

  const _addressChoose = (data: GooglePlaceData, detail: GooglePlaceDetail | null) => {
    if (Platform.OS === 'web') {
      mapRef.current?.animateCamera({
        center: {
          // @ts-ignore
          lat: detail?.geometry.location.lat || 0,
          lng: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
    } else {
      mapRef.current?.animateCamera({
        center: {
          latitude: detail?.geometry.location.lat || 0,
          longitude: detail?.geometry.location.lng || 0,
        },
        zoom: 16,
      })
    }
    const maps =  detail?.geometry.location ? coordinatesToGoogleMapsLink(
      detail.geometry.location.lat,
      detail.geometry.location.lng,
    ) : '.'
    setC({
      ...c,
      Address: data.description,
      Maps: maps,
    })
    setCoordinate({
      latitude: detail?.geometry.location.lat || 0,
      longitude: detail?.geometry.location.lng || 0,
    })
  }

  const _accessible = (accessbile: boolean) => {
    setChanged(true);
    setC({
      ...c,
      Accessible: accessbile,
    });
  };

  const _aquifer = (aquifer: boolean) => {
    setChanged(true);
    setC({
      ...c,
      Aquifer: aquifer,
    });
  };

  const _notes = (notes: string) => {
    setChanged(true);
    setC({...c, Notes: notes})
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={_onClose}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
        flex: 1,
      }}
    >
      {loading ?
        <ActivityIndicator/> :
        <View style={{flex: 1}}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text style={{fontSize: 25}}>
              {t("EDIT_CONSTRUCTION_SITE")}
            </Text>
            <MaterialCommunityIcons
              size={24}
              color='black'
              name='close'
              onPress={_onClose}
            />
          </View>
          
          <ErrorWarningsLister errors={errors} warnings={warnings} info={[]} />

          <View style={{flexDirection: 'row', paddingTop: 15}}>
            <Text style={{flex: 2}}>
              {t("POSSIBLE_CONSTRUCTION_SITE_NUMBER")}: {constructionSite.IDString}
            </Text>
            <Text style={{flex: 2}}>
              {t("CONSTRUCTION_SITE_NUMBER")}: {constructionSite?.Code.length > 0 ? constructionSite.Code : "-"}
            </Text>
            <Text style={{flex: 1}}>
              ({t("CODE")}: {constructionSite.ID})
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 15,
            }}
          >
            <Text style={{marginRight: 15}}>
              {t("NAME")}:
            </Text>
            <TextInput
              value={c.Name}
              onChangeText={_nameChange}
              style={{
                flex: 1,
                paddingVertical: 6,
                paddingHorizontal: 12,
                borderColor: theme.border,
                borderWidth: 1,
                borderRadius: 5,
                backgroundColor: theme.background,
              }}
            />
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 15,
              justifyContent: 'space-between',
            }}
          >
            <Text style={{paddingRight: 2}}>
              {t("TYPE")}:
            </Text>
            <ConstructionSiteTypeDropdown setType={_typeChange} type={c.Type}/>
            <Text style={{marginLeft: 15, paddingRight: 2}}>
              {t("BRANCH")}:
            </Text>
            <BranchDropdown setBranch={_branchChange} branch={c.Branch}/>
            <Text style={{paddingLeft: 20, paddingRight: 2}}>
              {t("ACCESSIBLE")}:
            </Text>

            <Switch value={c.Accessible} onValueChange={_accessible}/>

            <Text style={{paddingLeft: 20, paddingRight: 2}}>
              {t("AQUIFER")}:
            </Text>

            <Switch value={c.Aquifer} onValueChange={_aquifer}/>

            <Button
              onPress={_saveAddress}
              titleT='SAVE_ADDRESS'
              style={{backgroundColor: theme.secondaryBackground, marginLeft: 15}}
            />
          </View>

          <Text style={{paddingTop: 20}}>
            {t('CONSTRUCTION_SITE_ADDRESS')}:
          </Text>

          <Text style={{paddingVertical: 15}}>
            {t('MAP_MODAL_IF_YOU_WANT_TO_SAVE_ADDRESS')}
          </Text>

          <View style={{flexDirection: 'row', paddingTop: 10, flex: 1}}>
            <View
              style={{
                flex: 1,
                backgroundColor: theme.background,
                width: '100%',
                borderRadius: 5,
                borderWidth: 1,
                borderColor: theme.border,
                marginRight: 2,
                }}
              >
              <GooglePlacesAutocomplete
                //ref={ref}
                GooglePlacesDetailsQuery={{ fields: "geometry" }}
                fetchDetails={true}
                placeholder='Search'
                onPress={_addressChoose}
                textInputProps={{
                  onChange: _address,
                  value: c.Address,
                }}
                query={{
                  key: 'AIzaSyDqb1ADutBQksnAv43XLyjDQuJO9cTALNU',
                  language: 'en',
                  types: 'address',
                  location: `${coordinate?.latitude || branchCoordinates.latitude},${coordinate?.longitude || branchCoordinates.longitude}`,
                  radius: 1000,
                  input: c.Address,
                }}
                requestUrl={{
                  useOnPlatform: 'web',
                  url: `${url}googleMapsApiProxy`,
                  headers: {
                    Authorization: token,
                  },
                }}
              />
            </View>
            <View style={{flex: 1, marginLeft: 3}}>
            <MapView
              ref={mapRef}
              provider={PROVIDER_GOOGLE}
              showsUserLocation={Platform.OS !== 'web'}
              mapType="hybrid"
              showsPointsOfInterest={false}
              initialRegion={{
                ...coordinate,
                latitudeDelta: 0.0122,
                longitudeDelta: 0.0051,
              }}
              onRegionChange={(region) => {
                if (region) {
                  setCoordinate(region)
                }
              }}
              onRegionChangeComplete={(region) => {
                setCoordinate(region)
                if (savedAddress) {
                  setC({
                    ...c,
                    Address: c.Address,
                    Maps: coordinatesToGoogleMapsLink(region.latitude, region.longitude),
                  })
                } else {
                  if (Platform.OS === 'web') {
                    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${region.latitude},${region.longitude}&key=${'AIzaSyDqb1ADutBQksnAv43XLyjDQuJO9cTALNU'}`,{headers:{}})
                      .then(r => r.json())
                      .then(r => {
                        if (r.results.length > 0) {
                          setC({
                            ...c,
                            Address: `${r.results[0].formatted_address}`,
                            Maps: coordinatesToGoogleMapsLink(region.latitude, region.longitude),
                          })
                        }
                      })
                  } else {
                    reverseGeocodeAsync(region).then((response) => {
                      if (response.length > 0) {
                        setC({
                          ...c,
                          Address: `${response[0].name}, ${response[0].isoCountryCode}-${response[0].postalCode} ${response[0].city}`,
                          Maps: coordinatesToGoogleMapsLink(region.latitude, region.longitude),
                        })
                      }
                    })
                  }
                }
              }}
              style={{
                ...StyleSheet.absoluteFillObject,
                borderRadius: 5,
              }}
            >
              {Platform.OS == "web" ? (
                // @ts-ignore
                <MapView.Marker coordinate={coordinate} title="tt" description="ddd"/>
              ) : (
                <Marker coordinate={coordinate} title="tt" description="ddd"/>
              )}
            </MapView>
            </View>
          </View>

          <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingTop: 5}}>
            <Text style={{marginTop: 5, marginRight: 5}}>
              {t('NOTES')}:
            </Text>
            <TextInput
              style={{
                flex: 1,
                padding: 5,
                borderRadius: 5,
                borderColor: theme.border,
                borderWidth: 1,
              }}
              multiline={true}
              numberOfLines={3}
              value={c.Notes}
              onChangeText={_notes}
            />
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingTop: 15,
            }}
          >
            <Button
              onPress={_update}
              disabled={!changed}
              style={{
                backgroundColor: theme.mainColor,
              }}
              titleT='UPDATE_CONSTRUCTION_SITE'
            />
          </View>
        </View>
      }
    </Modal>
  )
};

export default EditConstructionSiteModal;