import {Text, View} from "react-native";
import * as React from "react";
import { t } from "i18n-js";

export default function NoInternetScreen() {

  return (
    <View>
      <Text>{t('NO_INTERNET_CONNECTION')}</Text>
    </View>
  );
}