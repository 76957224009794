import React from "react";
import { useState } from "react";
import { Absence } from "../../models";
import AbsenceModal from "../AbsenceModal";
import { Pressable, Text, View } from "react-native";
import { getStringFromTime } from "../../utils/StringUtils";
import getTheme from "../../constants/Colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";

interface Props {
  absence: Absence
  pull: () => void
  index: number
}

/**
 * 
 * @param props 
 * @returns 
 */
const AbsenceItem = ({absence, pull, index}:Props) => {

  const theme = getTheme()

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)

  const [visibleModal, setVisibleModal] = useState<boolean>(false)
  const _openModal = () => {
    if (serverReachable) {
      setVisibleModal(true)
    }
  }
  const _closeModal = () => setVisibleModal(false)

  return (
    <>
      <AbsenceModal
        absence={absence}
        setAbsence={pull}
        visible={visibleModal}
        onClose={_closeModal}
      />
      <Pressable
        onPress={_openModal}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
          borderTopColor: theme.border,
          borderTopWidth: index  == 0 ? 0 : 1,
        }}
      >
        <Text>{absence.Type?.Name}</Text>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={{
          marginRight: (absence.Notes?.length || 0) > 0 ? 5 : 0,
        }}>{getStringFromTime(absence.Time * 60)}</Text>
        {(absence.Notes?.length || 0) > 0 && <MaterialCommunityIcons name="comment" size={14} color='black'/>}
        {(absence.OfficeNotes?.length || 0) > 0 && <MaterialCommunityIcons name="office-building" size={14} color='black'/>}
        </View>
      </Pressable>
    </>
  )
}

export default AbsenceItem