import React, { useMemo } from "react";
import { Client } from "../../models";
import getTheme from "../../constants/Colors";
import { Pressable, Text, View } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { userCanUpdateBusinessPartners } from "../../models/permissions";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";

interface Props {
  client: Client;
  select: (client: Client) => void;
  selected?: Client | null;
  edit: (client: Client | null) => void; 
}

const ClientItem = ({client, select, selected, edit}: Props) => {

  const theme = getTheme();

  const user = useSelector((state: Reducer) => state.user)

  const background = client.ID === selected?.ID ? theme.background : "white";

  const address = useMemo(() => {
    const address = client.Addresses.find(a => a.ID == client.DefaultBillAddressID)

    if (!address) {
      return
    }

    return `${address.StreetLine0}, ${address.Country}-${address.PostCode} ${address.City}`
  }, [client])

  const _press = () => {
    select(client);
  }

  const _edit = () => edit(client);

  return (
    <Pressable
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: theme.border,
        borderBottomWidth: 1,
        paddingBottom: 2,
        paddingTop: 3,
        backgroundColor: background,
      }}
      onPress={_press}
    >
      <View style={{
        flexDirection: 'column',
        flex: 1,
      }}>
        <Text style={{fontSize: 18}}>{client.Name}</Text>
        <Text>{address}</Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 3,
          }}
        >
          <MaterialIcons name="person" />
          <Text style={{flex: 1}}>{client.Contacts.length}</Text>
          <MaterialIcons name="mail" />
          <Text style={{flex: 3}}>{client.Email}</Text>
          <MaterialIcons name="phone" />
          <Text style={{flex: 2}}>{client.Phone}</Text>
        </View>
      </View>
      {userCanUpdateBusinessPartners(user) ? (
        <MaterialIcons name="edit" size={20} onPress={_edit} />
      ) : (
        <View style={{width: 20}} />
      )}
    </Pressable>
  );
};

export default ClientItem;