import { LineLevel } from "../../../models";
import { LineSumType } from '../../../models'
import { PriceLevel } from "../../../store/reducers/settingsReducer";
import Formatter from "../../../utils/formatters";

export type PrintTwinLevel = {
  price: number
  discount: number
  discountNumeric: boolean
  lineTotal: number
}

export const lineLevelLineTotal = (lineLevel: LineLevel) => {
  if (lineLevel.DiscountNumeric) {
    return lineLevel.Quantity * lineLevel.Price - lineLevel.Discount;
  } else {
    return lineLevel.Quantity * lineLevel.Price * (1 - lineLevel.Discount / 100);
  }
}

export const lineLevelShow = (lineLevel:LineLevel, showPriceLevel: number, printTwinLevel?: PrintTwinLevel) => {
  const client = showPriceLevel >= PriceLevel.CLIENT;
  const internal = showPriceLevel >= PriceLevel.INTERNAL;
  const f2 = Formatter.number2.format;
  if (lineLevel.FixedPriceBlock !== "" && lineLevel.FixedPriceBlock !== "-") {
    if (internal) {
      return {
        price: String(f2(printTwinLevel?.price ?? lineLevel.Price)),
        lineTotal: String(f2(printTwinLevel?.lineTotal ?? lineLevel.LineTotal)),
        lineTotalBrackets: false,
        lineTotalColor: "gray",
      };
    } else {
      return {
        price: "",
        lineTotal: "",
        lineTotalColor: "black",
      }
    }
  } else {
    if (lineLevel.LineSumType === LineSumType.NORMAL && client) {
      return {
        price: String(f2(printTwinLevel?.price ?? lineLevel.Price)),
        lineTotal: String(f2(printTwinLevel?.lineTotal ?? lineLevel.LineTotal)),
        lineTotalColor: "black",
      }
    } else if (lineLevel.LineSumType === LineSumType.PRICE_LIST && client) {
      return {
        price: String(f2(printTwinLevel?.price ?? lineLevel.Price)),
        lineTotal: "",
        lineTotalColor: "black",
      }
    } else if (lineLevel.LineSumType === LineSumType.TO_CONCORD && client) {
      return {
        price: String(f2(printTwinLevel?.price ?? lineLevel.Price)),
        lineTotal: "("+String(f2(printTwinLevel?.lineTotal ?? lineLevel.LineTotal))+")",
        lineTotalColor: "black",
      }
    } else {
      return {
        price: "",
        lineTotal: "",
        lineTotalColor: "black",
      }
    }
  }
}

export const charToCode = (s:string):number => {
  // reverse
  s = s.split("").reverse().join("");
  // to upper case
  s = s.toUpperCase();

  // check if only letters
  if (!(/^[A-Z]+$/).test(s)) {
    return -1;
  }

  let m = 1, x = 0;
  for (let i = 0; i < s.length; i++) {
    if (i > 0) {
      x += (Number(s.charCodeAt(i) - 65)) * m;
    } else {
      x += Number(s.charCodeAt(i) - 65) * m;
    }
    m *= 26;
  }

  return x;
}