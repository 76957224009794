import React, { useEffect, useMemo, useState } from "react";
import { Absence, Resource, WeekReport, WeekReportAssignement } from "../../models";
import { getWeekReport } from "../../api/PlannningAPI";
import { useDispatch, useSelector } from "react-redux";
import { alert } from "../../hooks/Alert";
import { logout } from "../../api/AuthAPI";
import Table from "../../components/Table";
import { ActivityIndicator, Pressable, Text, View } from "react-native";
import getTheme from "../../constants/Colors";
import Status from "../../components/Status";
import { t } from "i18n-js";
import { getStringFromTime } from "../../utils/StringUtils";
import { sentryCapture } from "../../utils/sentryCapture";
import { Reducer } from "../../store/reducers";
import AbsenceItem from "../../components/AbsenceItem";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AbsenceModal from "../../components/AbsenceModal";
import WeekReportModal from "./WeekReportModal";

interface Props {
  weekSelector: number;
  mutate: () => void
}

const WeekReportView = ({weekSelector, mutate}: Props) => {

  const dispatch = useDispatch();
  const theme = getTheme();

  const showPlanningWeekDays = useSelector(
    (state: Reducer) => state.settings.showPlanningWeekDays
  )
  const { reportApprove } = useSelector((state: Reducer) => state.user.permissions)
  const resourceId = useSelector((state: Reducer) => state.general.resource?.ID)

  const [weekReprot, setWeekReport] = useState<WeekReport>();
  const [loading, setLoading] = useState<boolean>(true);
  const [absence, setAbsence] = useState<Absence | undefined>()
  const [weekReportAssignement, setWeekReportAssignement] = useState<WeekReportAssignement | undefined>()
  const _close = () => setWeekReportAssignement(undefined)

  const pull = () => getWeekReport(weekSelector)
    .then(weekReport => {
      if (reportApprove >= 2) {
        setWeekReport({
          ...weekReport,
          assignments: weekReport.assignments.sort(
            (a, b) => (b.Resource.PlanningWeight || 0) - (a.Resource.PlanningWeight || 0)
          ).sort(
            a => a.ResourceID == resourceId ? -1 : 0
          )
        })
      } else {
        setWeekReport({
          ...weekReport,
          assignments: weekReport.assignments.filter(r => r.ResourceID == resourceId).sort(
            (a, b) => (b.Resource.PlanningWeight || 0) - (a.Resource.PlanningWeight || 0)
          ).sort(
            a => a.ResourceID == resourceId ? -1 : 0
          )
        })
      }
    })
    .catch(e => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR", "ERROR_LOADING_WEEK_REPORT");
      }
    })
    .finally(() => setLoading(false))

  useEffect(() => {
    pull()
  }, [weekSelector]);

  const createAbsence = (date: Date, resource: Resource) => setAbsence({
    Date: date,
    Resource: resource,
    ResourceID: resource.ID,
    Start: "08:00",
    End: "08:00",
    Time: 0,
    Notes: "",
    OfficeNotes: "",
  })
  const closeAbsenceModal = () => {
    setAbsence(undefined)
    pull()
    mutate()
  }

  const tableHeader = useMemo(() => {
    if (!weekReprot) return [];

    return [<></>].concat(weekReprot.timeTargets.map((t, i) => (
      <View key={`${t.date}-${i}`} style={{borderLeftWidth: i == 0 ? 0 : 0.5, paddingLeft: 3}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <Text style={{textTransform: 'capitalize', fontSize: 18}}>
            {t.date.toLocaleDateString(undefined, {weekday: "long"})}
          </Text>
          <Text style={{marginRight: 5}}></Text>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <Text style={{flex: 1}}>{t.date.toLocaleDateString()}</Text>
          {reportApprove >= 0 && (
            <Text style={{marginRight: 5}}>{getStringFromTime(t.timeTarget * 60)} h</Text>
          )}
        </View>
      </View>
    )));
  }, [weekReprot]);

  const rows = useMemo(() => {
    if (!weekReprot) return [];


    return weekReprot.assignments.map(a => [(
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          flex: 1,
          marginRight: 6,
          minHeight: 200,
        }}
        key={`resource-${a.ResourceID}`}
      >
        <View
          style={{
            transform: [{rotate: "-90deg"}],
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Text numberOfLines={1} style={{width: "100%", fontSize: 18}}>
            {a.Resource.FirstName + " " + a.Resource.LastName}
          </Text>
        </View>
      </View>
    )].concat(
      a.Days.map((d, dayIndex) => (
        <View
          style={{
            paddingHorizontal: 6,
            borderLeftWidth: 1,
            flex: 1,
          }}
          key={`r-${a.ResourceID}-${d.Date}`}
          >
            {
              d.ConstructionSites.map((c, cIndex) => (
                <Pressable
                  key={c.ConstructionSite.ID + "" + cIndex + "" + dayIndex}
                  onPress={() => setWeekReportAssignement(c)}
                >
                  <View
                    key={c.ConstructionSite.ID + "" + cIndex + "" + dayIndex}
                    style={{
                      borderBottomWidth: d.ConstructionSites.length == 1 && cIndex == d.ConstructionSites.length - 1 ? 0 : 1,
                      marginBottom: 5,
                      paddingBottom: 3,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 6,
                      }}
                      key={"hstack" + c.ConstructionSite.ID + "" + cIndex + "" + dayIndex}
                    >
                      <Text key={"text" + c.ConstructionSite.ID + "" + cIndex + "" + dayIndex} style={{flex: 1}}>
                        {c.ConstructionSite.Code.length > 0
                          ? c.ConstructionSite.Code + " (" + c.ConstructionSite.IDString + ")"
                          : c.ConstructionSite.IDString}
                      </Text>

                      <Status status={c.ConstructionSite.Status} size="m" /> 
                    </View>
                    <Text key={"siteName" + c.ConstructionSite.ID + "" + cIndex + "" + dayIndex}>
                      {c.ConstructionSite.Name}
                    </Text>
                    <Text style={{fontSize: 12}}>{c.ConstructionSite.Address}</Text>
                    <Text style={{marginTop: 10}}>{t("TIME")}: {getStringFromTime(c.TotalTime)}</Text>
                  </View>
                </Pressable>
              ))
              .concat(d.Absences.map((a, aIndex) => (
                <AbsenceItem
                  absence={a}
                  pull={pull}
                  key={`absence-${a.Code}-${aIndex}`}
                  index={aIndex}
                />
              )))
              .concat([
                ((d.ConstructionSites.length > 0 || d.Absences.length > 0) && reportApprove >= 0) ? (
                  <>
                    <Text style={{marginTop: 10}} key={`r-${a.ResourceID}-${d.Date}-total`}>
                      {t("DAY_TOTAL_TIME")}: {getStringFromTime(
                        d.ConstructionSites.reduce((p, v) => p + v.TotalTime, 0) + d.Absences.reduce((p, v) => p + (v.Type?.Negative ? -1 : 1) * v.Time, 0) * 60
                      )}
                    </Text>
                    <Text style={{marginTop: 10}} key={`r-${a.ResourceID}-${d.Date}-total`}>
                      {t("ABSENCE")}: {getStringFromTime(
                        d.Absences.reduce((p, v) => p + (v.Type?.Negative ? -1 : 1) * v.Time, 0) * 60
                      )}
                    </Text>
                    <Text style={{marginTop: 10}} key={`r-${a.ResourceID}-${d.Date}-total`}>
                      {t("TRAVEL")}: {getStringFromTime(
                        d.ConstructionSites.reduce((p, v) => p + v.DayReports.reduce((p, v) => p + v.TravelTime, 0), 0)
                      )}
                    </Text>
                    <Text style={{marginTop: 10}} key={`r-${a.ResourceID}-${d.Date}-total`}>
                      {t("WORK")}: {getStringFromTime(
                        d.ConstructionSites.reduce((p, v) => p + v.DayReports.reduce((p, v) => p + v.TotalTime - v.BreakTime, 0), 0)
                      )}
                    </Text>
                  </>
                ): (<></>)
              ])
              .concat([
                (a.ResourceID == resourceId || reportApprove > 1) ? (
                  <MaterialCommunityIcons name="plus" size={25} onPress={() => createAbsence(new Date(d.Date), a.Resource)} />
                ) : (<></>)
              ])
            }
          </View>
      ))
    ));
  }, [weekReprot]);

  const tableSizes = useMemo(() => {
    let sizes = [1, 2, 2, 2, 2, 2];
    for (let i = 0; i < (showPlanningWeekDays || 5) - 5; i++) {
      sizes.push(2)
    }

    return sizes;
  }, [showPlanningWeekDays])

  return (
    <>
      {
        loading && (
          <View style={{width: 100, height: 100, opacity: 0.3, backgroundColor: '#000', justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator />
          </View>
        )
      }
      {
        absence && (
          <AbsenceModal
            absence={absence}
            setAbsence={() => {pull(); mutate()}}
            visible={true}
            onClose={closeAbsenceModal}
          />
        )
      }
      {
        weekReportAssignement && (
          <WeekReportModal
            visible={true}
            onClose={_close}
            assignment={weekReportAssignement}
          />
        )
      }
      <Table separator={() => <View style={{borderBottomWidth: 1, width: "96%", margin: "auto"}}/>}
        rowStyle={{marginVertical: 10}}
        style={{
          marginTop: 25,
        }}
        listStyle={{
          marginTop: 10,
          backgroundColor: theme.thirdBackground,
          borderRadius: 8,
        }}
        sizes={tableSizes}
        head={tableHeader}
        rows={rows}
      />
    </>
  );
};

export default WeekReportView;