import React, { useMemo, useState } from 'react'
import { Level, LineLevel, ReportLineLevel } from '../../models'
import { FlatList, Pressable, Text, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import getTheme from '../../constants/Colors'
import { t } from 'i18n-js'
import Formatter from '../../utils/formatters'

interface OrderLineReportsLevelProps {
  lineLevel: LineLevel
  level: Level
  first: boolean
  last: boolean
}

const OrderLineReportsLevel = ({lineLevel, level, first, last}: OrderLineReportsLevelProps) => {

  const theme = getTheme()

  const [open, setOpen] = useState<boolean>(first && last)
  const _toggleOpen = () => setOpen(o => !o)

  const reports = useMemo(() => lineLevel.Reports?.sort((a, b) => b.Datetime.getTime()- a.Datetime.getTime()), [lineLevel.Reports])

  const f2 = Formatter.number2.format

  const _render = ({item, index}: {item: ReportLineLevel, index: number}) => {
    return(
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 5,
        }}
        key={`line-level-${lineLevel.ID}-reports-${item.DayReportID}-${index}`}
      >
        <Text style={{flex: 1, paddingRight: 5}}>{item.Datetime.toLocaleDateString()}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{item.DayReportID}</Text>
        <Text style={{flex: 2, textAlign: 'center', paddingRight: 5}}>{item.Resource.FirstName} {item.Resource.LastName}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{f2(item.Quantity)}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{item.ApprovedQuantity ? f2(item.ApprovedQuantity) : '-'}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingRight: 5}}>{item.BillableQuantity ? f2(item.BillableQuantity) : '-'}</Text>
        <Text style={{flex: 2, textAlign: 'center', paddingRight: 5}}>{item.ApprovedBy && `${item.ApprovedBy.FirstName} ${item.ApprovedBy.LastName}`}</Text>
      </View>
    )
  }

  const length = (reports?.length || 0) > 0 ? reports?.length : '-'

  return (
    <View key={`line-level-${lineLevel.ID}-reports-${level.ID}`}>
      {!(first && last) && (
        <Pressable
          onPress={_toggleOpen}
          style={{
            borderBottomLeftRadius: last && !open ? 5 : 0,
            borderBottomRightRadius: last && !open ? 5 : 0,
            padding: 10,
            backgroundColor: theme.background,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text>{level.Name} ({length})</Text>
          <MaterialCommunityIcons
            name={open ? 'chevron-up' : 'chevron-down'}
            size={24}
          />
        </Pressable>
      )}
      {open && (
        <View style={{
          padding: 10,
          borderBottomLeftRadius: last ? 5 : 0,
          borderBottomRightRadius: last ? 5 : 0,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderColor: theme.background,
        }}>
          <FlatList
            data={reports}
            key={`line-level-${lineLevel.ID}-reports`}
            renderItem={_render}
          />
        </View>
      )}
    </View>
  )
}

export default OrderLineReportsLevel