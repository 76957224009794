import { FlatList, View, Text } from "react-native";
import React, { useEffect, useMemo, useState } from "react";
import { Level, Position, PositionLevel } from "../../../models";
import DocTableLevelPosition from "./DocTableLevelPosition";
import Formatter from "../../../utils/formatters";

interface Props {
  position: Position;
  setPosition: (position: Position) => void;
  levels: Level[];
}

const DocTablePosition = ({position, setPosition, levels}: Props) => {

  const [p, setP] = useState<Position>(position);

  useEffect(() => setP(position), [position]);

  const _setPositionLevelIndex = (positionLevel: PositionLevel, index: number) => {
    setP(p => ({
      ...p,
      Levels: p.Levels?.map((l, li) => {
        if (li === index) {
          return {
            ...l,
            Quantity: positionLevel.Quantity,
            Size: positionLevel.Size,
          };
        }
        return l;
      }),
    }))
    setPosition({
      ...p,
      Levels: p.Levels?.map((l, li) => {
        if (li === index) {
          return {
            ...l,
            Quantity: positionLevel.Quantity,
            Size: positionLevel.Size,
          };
        }
        return l;
      }),
    })
  }

  const _render = ({item, index}: {item: PositionLevel, index: number}) => {
    const _setPositionLevel = (positionLevel: PositionLevel) => {
      _setPositionLevelIndex(positionLevel, index);
    };
    return (
      <DocTableLevelPosition
        position={position}
        positionLevel={item}
        setPositionLevel={_setPositionLevel}
        key={`p-${index}`}
      />
    )
  }

  const quantity = useMemo(() => p.Levels?.reduce((p, v) => p + v.Quantity, 0), [p.Levels]);

  return(
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
      }}
      key={`${position.ID}`}>
      <View
        style={{
          flexDirection: 'row',
          paddingLeft: 1,
          height: position.ShowCompact ? 50 : 100,
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottomWidth: 1,
          borderRightWidth: 1,
          width: 300,
        }}
      >
        <Text>{position.Name}</Text>
        {!position.ShowCompact && (
          <View style={{flexDirection: 'column', borderLeftWidth: 1, height: '100%'}}>
            <View
              style={{
                borderBottomWidth: 1,
                paddingHorizontal: 1,
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text>{position.UnitOfMeasure}</Text>
            </View>
            <View style={{alignItems: "center", justifyContent: "center", flex: 1}}>
              <Text>{position.UnitOfQuantity}</Text>
            </View>
          </View>
        )}
      </View>

      <FlatList
        data={p.Levels}
        renderItem={_render}
        columnWrapperStyle={levels.length > 1 ? {
          flexWrap: "wrap"
        }:undefined}
        style={{flex: levels.length}}
        numColumns={levels.length}
        key={`doc-table-pos-${levels.length}-${p.ID}`}
        // @ts-ignore
        listKey={`doc-table-pos-${levels.length}-${p.ID}`}
        keyExtractor={(_, index) => `doc-table-pos-${p.ID}-${index}`}
        extraData={levels}
      />

      <View
        key={`total-${position.IDString}-${position.ID}`}
        style={{
          height: position.ShowCompact ? 50 : 100,
          flex: 1,
        }}
      >
        <Text style={{margin: "auto", textAlign: "center", justifyContent: "center"}}>
          {Formatter.number2.format(quantity || 0)}
        </Text>
      </View>

    </View>
  );
};

export default DocTablePosition;