import React, { useEffect, useState } from 'react';
import { WorkSpace } from '../../models';
import { Button, Pressable, Text, TextInput, View } from 'react-native';
import { t } from "i18n-js";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import getTheme from '../../constants/Colors';

interface Props {
  setWorkSpace: (workSpace: WorkSpace) => void;
  back: () => void;
}

const WorkSpaceCustom = ({setWorkSpace, back}: Props) => {

  const theme = getTheme();

  const [url1, setUrl1] = useState<string>("");
  const [url2, setUrl2] = useState<string>("");

  const [url1Error, setUrl1Error] = useState<string>("");
  const [url2Error, setUrl2Error] = useState<string>("");

  const _proceed = () => {
    if (url1.length == 0) {
      setUrl1Error(t("PRIMARY_URL_CANNOT_BE_EMPTY"));
    }
    if (url2.length == 0) {
      setUrl2Error(t("SECONDARY_URL_CANNOT_BE_EMPTY"));
    }
    if (url1.length == 0 || url2.length == 0) {
      return;
    }

    setWorkSpace({
      id: -1,
      name: 'dev',
      url: url1,
      secondaryUrl: url2,
    });
  }

  useEffect(() => {
    if (url1.length > 0) {
      setUrl1Error("");
    }
  }, [url1]);

  useEffect(() => {
    if (url2.length > 0) {
      setUrl2Error("");
    }
  }, [url2]);

  return (
    <>
      <View style={{flexDirection: 'row', paddingTop: 10, alignItems: 'center'}}>
        <Pressable onPress={back}>
          <MaterialCommunityIcons
            name="chevron-left-box"
            size={24}
            color="#555"
          />
        </Pressable>

        <Text style={{paddingLeft: 10, fontSize: 24, color: '#555'}}>
          {t("CUSTOM_WORKSPACE")}
        </Text>
      </View>
      <TextInput
        keyboardType='url'
        style={{
          marginTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: '#555',
          paddingBottom: 2,
          fontSize: 18,
        }}
        autoCapitalize='none'
        autoComplete='off'
        autoFocus={true}
        onSubmitEditing={_proceed}
        placeholderTextColor={'#555'}
        value={url1}
        onChangeText={setUrl1}
        placeholder={t("PRIMARY_SERVER_URL")}
      />
      <Text style={{color: 'red', paddingVertical: 5}}>
        {url1Error.length > 0 ? url1Error : ""}
      </Text>
      <TextInput
        keyboardType='url'
        style={{
          marginTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: '#555',
          paddingBottom: 2,
          fontSize: 18,
        }}
        autoCapitalize='none'
        autoComplete='off'
        onSubmitEditing={_proceed}
        placeholderTextColor={'#555'}
        value={url2}
        onChangeText={setUrl2}
        placeholder={t("SECONDARY_SERVER_URL")}
      />
      <View style={{flexDirection: 'row', paddingTop: 10}}>
        <Text style={{color: 'red'}}>
          {url2Error.length > 0 ? url2Error : ""}
        </Text>

        <View style={{justifyContent: 'flex-end', flexDirection: 'row', flex: 1}}>
          <Button 
            title={t("PROCEED")} 
            color={theme.mainColor}
            onPress={_proceed}
          />
        </View>
      </View>
    </>
  );
};

export default WorkSpaceCustom;