import React, { useEffect, useState } from "react";
import { WorkSpace } from "../../models";
import { Text, View, Pressable, Button, ActivityIndicator, TextInput } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import getTheme from "../../constants/Colors";
import { login } from "../../api/AuthAPI";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../../store/types";
import { setWorkSpace } from "../../store/action/authAction";
import { alert } from "../../hooks/Alert";
import { sentryCapture } from "../../utils/sentryCapture";
import { getDeviceUUID } from "../../utils/DeviceUUID";

interface Props {
  workSpace: WorkSpace;
  back: () => void;
}

const LoginComponent = ({workSpace, back}: Props) => {

  const theme = getTheme();

  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [errorUsername, setErrorUsername] = useState<string>("");
  const [error, setError] = useState<string>("");

  const _signIn = async () => {
    if (username.length == 0) {
      setErrorUsername(t("USERNAME_CANNOT_BE_EMPTY"));
    }
    if (password.length == 0) {
      setError(t("PASSWORD_CANNOT_BE_EMPTY"));
    }
    if (username.length == 0 || password.length == 0) {
      return;
    }

    setLoading(true);
    setError("");
    const uuid = await getDeviceUUID()
    login(workSpace, username, password, uuid).then(r => {
      // TODO store workspace object
      dispatch({
        type: LOGIN_SUCCESS,
        payload: r.data,
      });
      dispatch(setWorkSpace(workSpace));
    }).catch(e => {
      if (e.response && e.response.status) {
        setError(t("WRONG_USERNAME_OR_PASSWORD"));
      } else {
        sentryCapture(e);
        alert("ERROR", "ERROR_SIGNING_IN");
      }
    }).finally(() => setLoading(false));
  }

  useEffect(() => {
    if (username.length > 0) {
      setErrorUsername("");
    }
  }, [username]);

  useEffect(() => {
    if (password.length > 0) {
      setError("");
    }
  }, [password]);

  return (
    <>
      <View style={{
        flexDirection: 'row',
        paddingTop: 10,
        alignItems: 'center',
      }}>
        <Pressable
          onPress={back}
          disabled={loading}
        >
          <MaterialCommunityIcons
            name="chevron-left-box"
            size={24}
            color="#555"
          />
        </Pressable>

        <Text style={{
          paddingLeft: 10,
          fontSize: 24,
          color: '#555'
        }}>
          {t("SIGN_IN_INTO")}
        </Text>
        <Text style={{fontSize: 24, paddingLeft: 10}}>
          {workSpace.name}
        </Text>
      </View>
      <TextInput
        keyboardType='default'
        style={{
          marginTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: '#555',
          paddingBottom: 2,
          fontSize: 18,
        }}
        autoCapitalize='none'
        textContentType="username"
        autoComplete='username'
        autoFocus={true}
        placeholderTextColor={'#555'}
        value={username}
        onSubmitEditing={_signIn}
        onChangeText={setUsername}
        placeholder={t("USERNAME")}
        editable={!loading}
      />
      <Text style={{
        color: 'red',
        paddingVertical: 5,
      }}>
        {errorUsername.length > 0 ? errorUsername : ""}
      </Text>
      <TextInput
        keyboardType='visible-password'
        style={{
          marginTop: 15,
          borderBottomWidth: 1,
          borderBottomColor: '#555',
          paddingBottom: 2,
          fontSize: 18,
        }}
        autoCapitalize='none'
        textContentType="password"
        autoComplete='password'
        placeholderTextColor={'#555'}
        value={password}
        onChangeText={setPassword}
        onSubmitEditing={_signIn}
        placeholder={t("PASSWORD")}
        secureTextEntry={true}
        editable={!loading}
      />
      <View style={{
        flexDirection: 'row',
        paddingTop: 10,
      }}>
        <Text style={{color: 'red'}}>
          {error.length > 0 ? error : ""}
        </Text>

        <View style={{
          justifyContent: 'flex-end',
          flexDirection: 'row',
          flex: 1,
        }}>
          {
            loading ? (
              <ActivityIndicator />
            ): (
              <Button 
                title={t("PROCEED")} 
                color={theme.mainColor}
                onPress={_signIn}
              />
            )
          }
        </View>
      </View>
    </>
  );
};

export default LoginComponent;