import React from 'react'
import { DocumentContact } from '../../../models'
import { Text, View } from 'react-native'
import { t } from 'i18n-js'

interface DayReportContactProps {
  documentContact: DocumentContact
}

const DayReportContact = ({ documentContact }: DayReportContactProps) => {

  let type = t('CLIENT')
  if (documentContact.Type) {
    type = documentContact.Type.Name
  }

  if (!documentContact.Client) {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: 'white'}}>
        <Text style={{fontSize: 18, flex: 1, fontWeight: 'bold'}}>
          {type}
        </Text>
        <Text style={{fontSize: 18, flex: 1}}>
          {documentContact.Firstname} {documentContact.Lastname}
        </Text>
        <Text style={{fontSize: 18, flex: 1}}>
          {documentContact.Mobile}
        </Text>
      </View>
    )
  }

  let mobile = documentContact.Client.Mobile
  if (documentContact.Client.Phone.length > 0) {
    mobile = documentContact.Client.Phone
  }
  if (documentContact.ClientContact) {
    mobile = documentContact.ClientContact.Mobile
    if (documentContact.ClientContact.Phone.length > 0) {
      mobile = documentContact.ClientContact.Phone
    }
  }

  return (
    <View style={{flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: 'white'}}>
      <Text style={{fontSize: 18, flex: 1, fontWeight: 'bold'}}>
        {type}
      </Text>
      <View style={{flex: 1}}>
        <Text style={{fontSize: 18, flex: 1}}>
          {documentContact.Client.Name}
        </Text>
        {documentContact.ClientContact && (
          <Text style={{fontSize: 18, flex: 1}}>
            {documentContact.ClientContact.Firstname} {documentContact.ClientContact.Lastname}
          </Text>
        )}
      </View>
      <Text style={{fontSize: 18, flex: 1}}>
        {mobile}
      </Text>
    </View>
  )
}

export default DayReportContact