import {
  Absence,
  DayReport,
  GetActivitiesI,
} from "../models";
import axios from "axios";
import { GetSapActivitiesI,  SapDayReport } from "../models/sap";
import {
  dayReportToSapDayReportConvert,
  sapActivitiesToActivitiesConverter,
  sapDayReportToDayReportConvert
} from '../shared-utils/reports/convert'

/**
 * Returns the day reports created by the user in the last `days`.
 *
 * @param days Number of days to load the Day reports (0: today, 1: today and yesterday).
 */
export const getMyDayReports = async (
  days: number
): Promise<{ date: Date; reports: DayReport[]; timeTarget: number, absences: Absence[]}[]> => {
  const res = await axios.get<{ date: string; reports: SapDayReport[]; timeTarget: number, Absences: Absence[]}[]>(`/report/my?days=${days}`);

  if (res.status !== 200) {
    throw res;
  }

  return res.data.map(v => {
    return {
      date: new Date(v.date),
      reports: v.reports.map(r => {
        return sapDayReportToDayReportConvert(r, r.Levels);
      }),
      timeTarget: v.timeTarget,
      absences: v.Absences.map(a => ({...a, Date: new Date(a.Date)})),
    };
  }).sort((a, b) => a.date.getTime() - b.date.getTime()).reverse();
};

/**
 * Get a day report with his details.
 *
 * @param id Id of the day report.
 */
export const getDayReport = async (id: number): Promise<DayReport> => {
  const res = await axios.get<SapDayReport>(`/report/${id}`);

  if (res.status !== 200) {
    throw res;
  }

  return sapDayReportToDayReportConvert(res.data, res.data.Levels);
};

/**
 * Create a new day report.
 *
 * @param dayReport Day report to create.
 * @param close True closes the work.
 */
export const createDayReport = async (
  dayReport: DayReport,
  close: boolean
): Promise<DayReport | null> => {
  dayReport.Reports = dayReport.Reports?.sort((a, b) => {
    if (a.ID == -1 && b.ID != -1) {
      return 1
    }
    return b.ID - a.ID
  })
  const sapDayReport = dayReportToSapDayReportConvert(dayReport, dayReport.Levels);

  const res = await axios.post<SapDayReport>(`/report/create`, sapDayReport);

  if (res.status !== 200) {
    throw res;
  }

  return sapDayReportToDayReportConvert(res.data, res.data.Levels)
};

/**
 * Update a day report.
 *
 * @param dayReport Day report to update.
 */
export const updateDayReport = async (dayReport: DayReport): Promise<DayReport> => {
  dayReport.Reports = dayReport.Reports?.sort((a, b) => {
    if (a.ID == -1 && b.ID != -1) {
      return 1
    }
    return b.ID - a.ID
  })
  const sapDayReport = dayReportToSapDayReportConvert(dayReport, dayReport.Levels);

  const res = await axios.post<SapDayReport>(`/report/update`, sapDayReport);

  if (res.status !== 200) {
    throw res;
  }

  return sapDayReportToDayReportConvert(res.data, res.data.Levels)
};

/**
 * Approve a day report.
 *
 * @param dayReport Day report to approve.
 */
export const approveDayReport = async (
  dayReport: DayReport,
  measureId?: number,
): Promise<DayReport> => {
  const sapDayReport = dayReportToSapDayReportConvert(dayReport, dayReport.Levels);

  const res = await axios.post<SapDayReport>(`/report/approve`, sapDayReport);

  if (res.status !== 200) {
    throw res;
  }

  return {
    ...sapDayReportToDayReportConvert(res.data, res.data.Levels),
    measureId,
  }
};

/**
 * Returns the names of the last people who signed a day report.
 *
 * @param contractId Id of the contract to sort to.
 */
export const getLastSigners = async (
  contractId: number
): Promise<string[]> => {
  const res = await axios.get<string[]>(`/report/lastSigners?contract=${contractId}`);

  if (res.status !== 200) {
    throw res;
  }

  return res.data;
};

/**
 * Returns a composed object with the construction site of the contact, the contract and the activities which should be done.
 *
 * @param contractId Id of the contract.
 */
export const getActivities = async (contractId: number): Promise<GetActivitiesI> => {
  const response = await axios.get<GetSapActivitiesI>(`report/activities?contractID=${contractId}&t=${Date.now()}`);

  if (response.status != 200 || !response.data.activities) {
    throw response;
  }

  return {
    constructionSite: response.data.constructionSite,
    contract: response.data.contract,
    levels: response.data.levels,
    activities: sapActivitiesToActivitiesConverter(response.data.activities, response.data.levels),
    DocumentContacts: response.data.DocumentContacts,
    OrderNotes: response.data.OrderNotes,
    CatalogID: response.data.CatalogID,
  };
};

export const getDayReportsToApprove = async ():Promise<SapDayReport[]> => {
  const res = await axios.get<SapDayReport[]>(`/report/toApprove?t=${Date.now()}`);

  if (res.status !== 200) {
    throw res;
  }

  return res.data.map(r => ({...r, Reports: [], DocumentContacts: []}))
}

export const deleteDayReport = async (dayReport: DayReport): Promise<void> => {
  const res = await axios.post<SapDayReport>(`/report/delete?dayReportId=${dayReport.ID}&t=${Date.now()}`, dayReport);

  if (res.status !== 200) {
    throw res;
  }
};

export const unapproveDayReport = async (dayReport: DayReport): Promise<void> => {
  const res = await axios.post<SapDayReport>(`/report/unapprove?dayReportId=${dayReport.ID}&t=${Date.now()}`, dayReport);

  if (res.status !== 200) {
    throw res;
  }
};