import React, { useEffect } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import getTheme from '../../constants/Colors'
import { Reducer } from '../../store/reducers'
import Button from '../../components/Button'
import { useIsFocused } from '@react-navigation/native'
import { getStartStopKey } from '../../utils/reports/report-utils'
import { logout as logoutAPI } from '../../api/AuthAPI'
import { alertConfirm } from '../../hooks/Alert'
import { logout as logoutAction } from '../../store/action/authAction'
import storage from '../../storage'

const UserScreen = () => {

  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const theme = getTheme()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [offlineData, setOfflineData] = React.useState<boolean>(false)

  const user = useSelector((state: Reducer) => state.user)

  const loadOfflineData = async () => {
    const keys = await storage.getAllKeys()

    setOfflineData(keys.filter(k => k.startsWith(getStartStopKey(''))).length > 0)
  }

  useEffect(() => {
    loadOfflineData()
  }, [isFocused])

  const _logout = async () => {
    if (offlineData) {
      if (!await alertConfirm('SURE_TO_LOGOUT', 'SURE_TO_LOGOUT_DESCRIPTION')) {
        return
      }
    }
    setLoading(true)
    await storage.clear()

    await logoutAPI()
    dispatch(logoutAction())
    setLoading(false)
  }

  return (
    <View style={{padding: 10, flex: 1}}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <View style={{paddingHorizontal: 5}}>
          <Text style={{fontSize: 24}}>{user.name}</Text>
          <Text style={{fontSize: 20}}>{user.company}</Text>
        </View>
        <Text style={{fontSize: 24}}>{user.username}</Text>
      </View>

      {loading && (
        <View style={{marginVertical: 20}}>
          <ActivityIndicator />
        </View>
      )}

      <View style={{flexDirection: 'row', justifyContent: 'center', flex: 1, alignItems: 'center'}}>
        <Button
          titleT='LOGOUT'
          style={{
            marginTop: 20,
            backgroundColor: theme.mainColor,
            paddingHorizontal: 20,
          }}
          _textStyle={{fontSize: 60}}
          disabled={loading}
          onPress={_logout}
        />
      </View>
    </View>
  )
}

export default UserScreen