import React, { useState } from "react";
import { DocumentContact } from "../../models";
import { Text, View } from "react-native";
import { t } from "i18n-js";
import { MaterialIcons } from "@expo/vector-icons";
import LabelInput from "../LabelInput";
import { alertConfirm } from "../../hooks/Alert";
import Button from "../Button";
import Modal from "react-native-modal";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import getTheme from "../../constants/Colors";

interface Props {
  customContact: DocumentContact;
  setCustomContact: (customContact: DocumentContact) => void;
  setVisible: (visible: boolean) => void;
  visible: boolean;
}

const CustomContactModal = ({customContact, setCustomContact, setVisible, visible}: Props) => {

  const theme = getTheme();

  const [a] = useState(customContact);
  const [changed, setChanged] = useState<boolean>(false);

  const _close = async () => {
    if (changed) {
      if (!await alertConfirm(t("CUSTOM_CONTACT_CHANGED"), t("CUSTOM_CONTACT_CHANGED_DESC"))) {
        return;
      }
    }

    setCustomContact(a);
    setVisible(false);
  }

  const _confirm = () => {
    setVisible(false);
  }

  const _set = (customContact: DocumentContact) => {
    setChanged(true);
    setCustomContact({
      ...customContact,
      Client: null,
      ClientID: null,
      ClientContact: null,
      ClientContactID: null,
    });
  };

  const _firstname = (f: string) => {
    _set({
      ...customContact,
      Firstname: f,
    });
  };

  const _lastname = (l: string) => {
    _set({
      ...customContact,
      Lastname: l,
    });
  };

  const _email = (e: string) => {
    _set({
      ...customContact,
      Email: e,
    });
  };

  const _mobile = (m: string) => {
    _set({
      ...customContact,
      Mobile: m,
    });
  };

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={_close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <KeyboardAwareScrollView>
        <View style={{flexDirection: "row", alignItems: 'center'}}>
          <Text style={{flex: 1, fontSize: 24}}>{t('CUSTOM_CONTACT')}</Text>
          <MaterialIcons
            name="close"
            size={24}
            onPress={_close}
          />
        </View>

        <LabelInput label={"FIRST_NAME"}
                    value={customContact.Firstname || ""}
                    setValue={_firstname}/>
        <LabelInput label={"LAST_NAME"}
                    value={customContact.Lastname || ""}
                    setValue={_lastname}/>
        <LabelInput label={"EMAIL"}
                    value={customContact.Email}
                    setValue={_email}
                    autoCapitalize="none"/>
        <LabelInput label={"MOBILE_PHONE"}
                    value={customContact.Mobile}
                    setValue={_mobile}/>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 5,
          }}
        >
          <Button
            style={{
              backgroundColor: theme.mainColor,
            }}
            onPress={_confirm}
            titleT='SAVE'
          />
        </View>
      </KeyboardAwareScrollView>
    </Modal>
  );
};

export default CustomContactModal;