import React, { useMemo, useState } from 'react'
import { Pressable, Text, TextInput, View } from 'react-native'
import { Item, LineSumType, LineType, Report } from '../../../models'
import HtmlText from '../../../components/HtmlText'
import NumberInput from '../../document/PostitionComponent/NumberInput'
import Formatter from '../../../utils/formatters'
import getTheme from '../../../constants/Colors'
import ChoosePositionByFather from '../../../components/ChoosePositionByFather'
import { t } from 'i18n-js'
import Button from '../../../components/Button'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import { isHours, ReportTwinCheck } from '../../../utils/reports/report-utils'
import TimePicker from '../../../components/TimePicker'
import { getStringFromTime, getTimeFromString } from '../../../utils/StringUtils'

interface DayReportLineProps {
  report: Report
  setReport: (f: (report: Report) => Report) => void
  levelId: number
  index: number
  onAdd: (item: Item | Item[]) => void
  wrongPreviousTwins: ReportTwinCheck[]
  last: boolean
}

const DayReportLine = ({ report, setReport, levelId, index, onAdd, wrongPreviousTwins, last }: DayReportLineProps) => {

  const theme = getTheme()

  const workAddActivity = useSelector((state: Reducer) => state.user.permissions.workAddActivity)
  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)

  const background = report.BackgroundColor ?? (index % 2 == 0 ? 'white' : theme.background)
  const f2 = Formatter.number2.format

  const [showNotes, setShowNotes] = useState(false)
  const _toggleShowNotes = () => setShowNotes(!showNotes)

  const [choosePositionByFatherModalOpen, setChoosePositionByFatherModalOpen] = useState(false)
  const _openChoosePositionByFatherModal = () => setChoosePositionByFatherModalOpen(true)
  const _closeChoosePositionByFatherModal = () => setChoosePositionByFatherModalOpen(false)

  const level = useMemo(() => {
    return report.Levels?.find(l => l.LevelID == levelId)
  }, [report, levelId])

  const time = useMemo(
    () => getStringFromTime((level?.Quantity || 0) * 60),
    [level],
  )

  const wrongPreviousTwin = useMemo(() => {
    return wrongPreviousTwins.find(t => t.id == report.ID && t.levelId == levelId)
  }, [wrongPreviousTwins])

  const _quantity = (quantity: number) => {
    setReport(r => ({
      ...r,
      Levels: r.Levels?.map(l => {
        if (l.LevelID == levelId) {
          return {
            ...l,
            Quantity: quantity
          }
        }
        return l
      })
    }))
  }

  const _time = (time: string) => {
    const value = (getTimeFromString(time) || 0) / 60
    _quantity(value)
  }

  const _notes = (notes: string) => setReport(r => ({
    ...r,
    Notes: notes,
  }))

  if (report.Type == LineType.TITLE) {
    return (
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 10,
          paddingHorizontal: 5,
          backgroundColor: background,
          borderBottomWidth: last ? 5 : 0,
          borderBottomColor: theme.mainColor,
        }}
        key={`${report.ID}-${levelId}`}
      >
        <Text style={{width: 100, fontWeight: 'bold'}}>
          {report.ActivityIDString}
        </Text>
        <View style={{flex: 1}}>
          <HtmlText
            html={report.Name}
            width='100%'
            maxLength={150}
          />
        </View>
      </View>
    )
  }

  if (!level) {
    return <></>
  }

  const todoQuantity = (level.SoldQuantity || 0) - (level.DoneQuantity || 0)

  const todoBackground = todoQuantity <= 0 ? '#1DE33B' : undefined
  const todoColor = todoQuantity <= 0 ? 'white' : theme.tint

  const hours = isHours(report.UnitOfMeasure)

  let total = ''
  let todo = ''
  if (level.LineSumType == LineSumType.PRICE_LIST) {
    total = t('PRICE_LIST')
    todo = '-'
  } else {
    if (hours) {
      todo = getStringFromTime(todoQuantity * 60)
      total = (level.SoldQuantity || 0) > 0 ? getStringFromTime((level.SoldQuantity || 0) * 60) : '-'
    }else {
      total = (level.SoldQuantity || 0) > 0 ? f2(level.SoldQuantity || 0) : '-'
      todo = f2(todoQuantity)
    }
  }

  return (
    <View style={{
      backgroundColor: background,
      borderBottomWidth: last ? 5 : 0,
      borderBottomColor: theme.mainColor,
    }}>
      {(choosePositionByFatherModalOpen && report.FatherID) && (
        <ChoosePositionByFather
          onAdd={onAdd}
          fatherId={report.FatherID}
          onClose={_closeChoosePositionByFatherModal}
          open={choosePositionByFatherModalOpen}
        />
      )}
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 5,
        }}
        key={`${report.ID}-${levelId}`}
      >
        <View style={{paddingVertical: 10}}>
          <Text style={{width: 100, fontWeight: 'bold'}}>
            {report.ActivityIDString}
          </Text>
          {(report.FatherID && workAddActivity && serverReachable) && (
            <Button
              titleT='ADD'
              onPress={_openChoosePositionByFatherModal}
              style={{backgroundColor: theme.blue, marginLeft: 0, marginVertical: 5}}
            />
          )}
          {report.Notes.length == 0 && (
            <Pressable
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 5,
              }}
              onPress={_toggleShowNotes}
            >
              <Text style={{paddingRight: 5}}>{t('NOTES')}</Text>
              <MaterialCommunityIcons
                name={showNotes ? 'chevron-down' : 'chevron-up'}
                color='black'
                size={24}
              />
            </Pressable>
          )}
        </View>
        <View style={{flex: 1}}>
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <HtmlText
                html={report.Name}
                width='100%'
                maxLength={150}
              />
            </View>
            <Text style={{
              width: 100,
              paddingHorizontal: 2,
              textAlign: 'center',
              paddingTop: 16,
              paddingBottom: 10,
            }}>
              {report.UnitOfMeasure}
            </Text>
            <View style={{marginTop: 10}}>
              {hours ? (
                <TimePicker
                  onChange={_time}
                  value={time}
                />
              ) : (
                <NumberInput
                  defaultValue={level.Quantity}
                  onChange={_quantity}
                  style={{width: 110}}
                />
              )}
            </View>
            <Text style={{
              width: 80,
              paddingHorizontal: 2,
              textAlign: 'right',
              marginLeft: 5,
              paddingTop: 16,
              paddingRight: 5,
              paddingBottom: 10,
              backgroundColor: todoBackground,
              color: todoColor,
            }}>
              {todo}
            </Text>
            <Text style={{
              width: 80,
              paddingHorizontal: 2,
              textAlign: 'right',
              paddingTop: 16,
              paddingBottom: 10,
            }}>
              {total}
            </Text>
          </View>
          <View style={{flexDirection: 'row', paddingVertical: 5}}>
            <View style={{flex :1}}/>
            {wrongPreviousTwin && (
              <View style={{
                width: 270,
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                <MaterialCommunityIcons
                  style={{paddingLeft: 5, paddingRight: 15}}
                  color={theme.red}
                  name='alert'
                  size={20}
                />
                <Text style={{color: theme.red, flex: 1}}>
                  {t('WRONG_PREVIOUS_TWIN', {max: wrongPreviousTwin.maximum})}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>

      {(showNotes || report.Notes.length > 0) && (
        <View style={{flexDirection: 'row', paddingVertical: 10, paddingLeft: 5, paddingRight: 10}}>
          <View style={{width: 100}} />
          <Text style={{paddingRight: 5}}>{t('NOTES')}:</Text>
          <TextInput
            style={{
              flex: 1,
              borderColor: theme.border,
              borderWidth: 1,
              padding: 5,
              borderRadius: 5,
              minHeight: 20,
              textAlignVertical: 'top',
              backgroundColor: 'white',
            }}
            multiline={true}
            value={report.Notes}
            onChangeText={_notes}
          />
        </View>
      )}
    </View>
  )
}

export default DayReportLine