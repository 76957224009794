import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ViewStyle } from "react-native";

import getTheme from "../../../../constants/Colors";

interface Props {
  name: string;
  onPress: () => void;
  style: ViewStyle;
  disabled: boolean;
}

const NumberInputIcon = ({name, onPress, style, disabled}: Props) => {

  const theme = getTheme();

  return (
    <MaterialCommunityIcons
      style={[{
        backgroundColor: theme.secondaryBackground,
        padding: 8,
        borderRadius: 5,
      }, style]}
      bgColor={theme.secondaryBackground}
      onPress={onPress}
      size={11}
      // @ts-ignore
      name={name}
      disabled={disabled}
    />
  );
};

export default NumberInputIcon;