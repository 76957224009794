import { ConstructionSite, ConstructionSiteViewGroups, Contract, DayReport } from "../models";
import axios from "axios";
import { t } from "i18n-js";

export const testCreateConstructionSite = (constructionSite:ConstructionSite): {errors: string[], warnings: string[]} => {
  const errors = [];

  if (constructionSite == undefined) {
    errors.push(t("MISSING_CONSTRUCTION_SITE_CREATE_OR_CHOOSE"));
  } else {
    if(constructionSite.Name == undefined || constructionSite.Name.length == 0) {
      errors.push(t("MISSING_CONSTRUCTION_SITE_NAME"));
    }
    if(constructionSite.Type == undefined) {
      errors.push(t("MISSING_CONSTRUCTION_SITE_TYPE"));
    }
    if (constructionSite.Branch == undefined) {
      errors.push(t("MISSING_CONSTRUCTION_SITE_BRANCH"));
    }
  }

  return {
    errors,
    warnings: [],
  }
}

export const getConstructionSites = async (): Promise<ConstructionSite[]> => {
  const response = await axios.get(`/constructionSite?t=${Date.now()}`);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const createContract = async (contract: Contract): Promise<Contract> => {
  const response = await axios.post("/contract", contract);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const getContractById = async (id: number): Promise<Contract> => {
  const response = await axios.get(`/contract/${id}?t=${Date.now()}`)

  if (response.status != 200) {
    throw response
  }

  return {
    ...response.data,
    Docs: response.data.Docs?.map((doc: any) => {
      doc.Type.Config = JSON.parse(doc.Type.Config)
      if (doc.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice) {
        doc.CreatedDate = new Date(doc.TaxDate)
        doc.InvoiceType = doc.TipoFatt
        doc.PrintType = doc.TipoStampa
        doc.WPSNumber = doc.ProgrFattura
      }
      return {
        ...doc,
        UpdatedDate: new Date(doc.UpdatedDate),
        CreatedDate: new Date(doc.CreatedDate),
      }
    }),
    Reports: response.data.Reports?.map((report: DayReport) => ({
      ...report,
      DateTime: new Date(report.DateTime),
      SignedTime: report.SignedTime ? new Date(report.SignedTime) : undefined,
    })),
    ExternalWorkInvoices: response.data.ExternalWorkInvoices?.map(
      (externalWorksInvoice: any) => ({...externalWorksInvoice, Date: new Date(externalWorksInvoice.Date)})
    ),
  }
}

export const createConstructionSite = async (constructionSite: ConstructionSite): Promise<ConstructionSite> => {
  if (!constructionSite.Name.includes(constructionSite.Address)) {
    constructionSite.Name += " " + constructionSite.Address;
  }

  const response = await axios
    .post("/constructionSite", constructionSite);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const updateContract = async (contract: Contract): Promise<Contract> => {
  const response = await axios.post("/contract/update", contract);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const updateConstructionSite = async (constructionSite: ConstructionSite): Promise<ConstructionSite> => {
  const response = await axios.post("/constructionSite/update", constructionSite);

  if (response.status != 200) {
    throw response;
  }

  return response.data;
};

export const getConstructionSiteById = async (id:number):Promise<ConstructionSite> => {
  const response = await axios.get(`/constructionSite/${id}?t=${Date.now()}`);

  if (response.status != 200) {
    throw response;
  }

  return {
    ...response.data,
    Contracts: response.data.Contracts?.map((c: any) => ({
      ...c,
      Status: Number(c.Status),
      Docs: c.Docs?.map((d: any) => {
        d.Type.Config = JSON.parse(d.Type.Config);
        if (d.Type.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Invoice) {
          d.CreatedDate = new Date(d.TaxDate)
          d.InvoiceType = d.TipoFatt
          d.PrintType = d.TipoStampa
          d.WPSNumber = d.ProgrFattura
        }
        return {
          ...d,
          UpdatedDate: new Date(d.UpdatedDate),
          CreatedDate: new Date(d.CreatedDate),
        }
      }),
      Reports: c.Reports?.map((r: DayReport) => ({
        ...r,
        DateTime: new Date(r.DateTime),
        SignedTime: r.SignedTime ? new Date(r.SignedTime) : undefined,
      })),
      measures: c.measures?.map((m: any) => ({
        ...m,
        createdAt: new Date(m.createdAt),
      })),
      ExternalWorkInvoices: c.ExternalWorkInvoices?.map(
        (e: any) => ({...e, Date: new Date(e.Date)})
      ),
    }))
  };
}
