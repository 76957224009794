import React from "react";
import { Client, Contact } from "../../models";
import { t } from "i18n-js";
import { Pressable, Text, View } from "react-native";
import getTheme from "../../constants/Colors";

interface Props {
  email: string;
  setEmail: (email: string) => void;
  client: Client;
  clientContact?: Contact | null;
  description: string;
  useBillEmail?: boolean
}

const QuickSendPdfClient = ({email, setEmail, client, clientContact, description, useBillEmail = false}: Props) => {

  const theme = getTheme();

  const _clientEmail = () => setEmail((client.EmailBillAddress.length > 0 && useBillEmail) ? client.EmailBillAddress : client.Email)
  const _clientContactEmail = () => setEmail(clientContact?.Email || "");

  const clientBackground = client.Email === email ? 'white' : undefined;
  const clientContactBackground = clientContact?.Email === email ? 'white' : undefined;

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 6,
          borderTopColor: 'black',
          borderTopWidth: 1,
        }}
      >
        <Text style={{fontWeight: "bold"}}>{description}</Text>

        <Text style={{fontWeight: "bold"}}>{clientContact ? `${clientContact.Firstname} ${clientContact.Lastname} - ${client.Name}` : `${client.Name}`}</Text>
      </View>

      <Pressable onPress={_clientEmail} disabled={client?.Email?.length === 0}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: 6,
            borderTopColor: theme.border,
            borderTopWidth: 1,
            backgroundColor: clientBackground,
          }}
        >
          <Text>{clientContact ? t("CLIENT_EMAIL") : t("EMAIL")}</Text>

          <Text>{(client.EmailBillAddress.length > 0 && useBillEmail) ? client.EmailBillAddress : client.Email}</Text>
        </View>
      </Pressable>

      {
        clientContact && (
          <Pressable onPress={_clientContactEmail} disabled={clientContact?.Email?.length === 0}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingVertical: 6,
                borderTopColor: theme.border,
                borderTopWidth: 1,
                backgroundColor: clientContactBackground,
              }}
            >
              <Text>{t("CLIENT_CONTACT_EMAIL")}</Text>

              <Text>{clientContact.Email}</Text>
            </View>
          </Pressable>
        )
      }
    </View>
  );
};

export default QuickSendPdfClient;