import React, { useState } from "react";
import { Text, View } from "react-native";
import getTheme from "../../../../constants/Colors";
import { ConstructionSite, Contract, Doc } from "../../../../models";
import DocumentPreviewClients from "./DocumentPreviewClients";
import DocumentPreviewLines from "./DocumentPreviewLines";
import DocumentVersionsModal from "../../../../components/DocumentVersionsModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Status from "../../../../components/Status";
import DocumentDetails from "./DocumentDetails";
import { DocMode } from "../../../doc/utils";
import { SearchScreens } from "../../../search/SearchScreens";
import DocPreviewMenu from "../../../../components/DocPreviewMenu";
import DocFooter from "../../../doc/DocFooter";
import { Reducer } from "../../../../store/reducers";
import { useSelector } from "react-redux";
import { PriceLevel } from "../../../../store/reducers/settingsReducer";
import MarginalityModal from "../../../../components/MarginalityModal";
import { DocStatus } from "../../../../constants/Status";
import Button from "../../../../components/Button";
import { useNavigation } from "@react-navigation/native";

type Props = {
  documentSelected: Doc;
  largeView: boolean;
  pull: () => void;
  setLoading: (loading: boolean) => void;
  setSelectedViewOrder: () => void;
  contract: Contract;
  constructionSite?: ConstructionSite;
};
const DocumentView: React.FC<Props> = (props: Props) => {
  const theme = getTheme();
  const navigation = useNavigation();

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);
  const sales = useSelector((state: Reducer) => state.user.permissions.sales);

  const [documentVersionsModalVisible, setDocumentVersionsModalVisible] = useState<boolean>(false);
  const _closeDocumentVersionsModal = () => setDocumentVersionsModalVisible(false)
  const [marginalityModalVisible, setMarginalityModalVisible] = useState<boolean>(false);
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);

  const _details = () => {
    setDetailsVisible(!detailsVisible);
  }

  function editDocument(document: Doc) {
    // @ts-ignore
    navigation.navigate(SearchScreens.DOC, {
      mode: DocMode.UPDATE,
      docId: document.ID,
      docType: document.Type,
    })
  };

  const _marginality = () => {
    setMarginalityModalVisible(true)
  }

  if (props.documentSelected == null) {
    return (<View/>)
  }
  return (
    <View style={{flex: 1}}>
      {marginalityModalVisible && <MarginalityModal doc={props.documentSelected}
                                                    visible={marginalityModalVisible}
                                                    getPDFVisible={true}
                                                    constructionSite={props.constructionSite}
                                                    setVisible={setMarginalityModalVisible}/> }
      {(props.constructionSite && documentVersionsModalVisible) && (
        <DocumentVersionsModal
          onClose={_closeDocumentVersionsModal}
          doc={props.documentSelected}
          contract={props.contract}
          constructionSite={props.constructionSite}
        />
      )}
      <View
        style={{
          backgroundColor: "white",
          margin: 5,
          borderRadius: 5,
          padding: 8
        }}>
        <View 
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            borderBottomWidth: detailsVisible ? 1 : 0,
            borderBottomColor: theme.border,
            paddingBottom: detailsVisible ? 2 : 0,
          }}
        >
          <MaterialCommunityIcons
            name={detailsVisible?"chevron-up":"chevron-down"}
            size={24}
            color="black"
            style={{marginRight: 2}}
            onPress={_details}
          />
          <Text style={{flex: 1, fontSize: 17}}>
            {`${props.documentSelected.Type.Name} ${props.documentSelected.IDString}    ${props.documentSelected.Name}`}
          </Text>

          {props.documentSelected.ToBeChecked && (
            <MaterialCommunityIcons
              name='eye'
              size={20}
              color={theme.red}
              style={{paddingRight: 10}}
            />
          )}

          <Status status={props.documentSelected.Status} style={{marginRight: 10}} size="m"/>

          {(props.documentSelected.Versions?.length || 0) > 0 && (
            <Button
              style={{
                backgroundColor: theme.mainColor,
                marginRight: 2,
              }}
              onPress={() => setDocumentVersionsModalVisible(true)}
              titleT='VERSIONS'
            />
          )}
          {(props.documentSelected.Status == 0 || props.documentSelected.Status == 2) && (
            <Button
              onPress={() => {
                editDocument(props.documentSelected);
              }}
              style={{
                backgroundColor: theme.mainColor,
              }}
              titleT='EDIT_LOW'
            />
          )}
          {sales && (
            <Button
              style={{
                backgroundColor: theme.blue,
                marginRight: 2,
              }}
              onPress={_marginality}
              title='%'
            />
          )}
          {props.constructionSite && (
            <DocPreviewMenu
              doc={props.documentSelected}
              showWinLose={props.documentSelected.Status === DocStatus.OFFER.code || props.documentSelected.Status === DocStatus.OPEN.code}
              pull={props.pull}
              setLoading={props.setLoading}
              setSelectedViewOrder={props.setSelectedViewOrder}
              contract={props.contract}
              constructionSite={props.constructionSite}
            />
          )}
        </View>
        {detailsVisible &&
          <>
            <DocumentDetails doc={props.documentSelected}/>
            <View style={{flexDirection: "row", paddingTop: 15, alignItems: "center", marginBottom: 5}}>
              <DocumentPreviewClients doc={props.documentSelected}/>
            </View>
          </>
        }
      </View>

      <View
        style={{
          margin: 4,
          marginBottom: 2,
          flexDirection: 'row',
          flex: 1,
          marginHorizontal: 1,
          backgroundColor: 'white',
          borderRadius: 5,
          padding: 2,
        }}
      >
        <DocumentPreviewLines doc={props.documentSelected} largeView={props.largeView}/>
      </View>
      {
        showPriceLevel >= PriceLevel.CLIENT && (
          <DocFooter
            doc={props.documentSelected}
            readOnly={true}
            lineChangeCounter={0}
            setDoc={() => {}}
            setDocChanged={() => {}}
          />
        )
      }
    </View>
  );
};

export default DocumentView;
