import React from "react";
import { useState } from "react";
import getTheme from "../../constants/Colors";
import { Client, ConstructionSite, Contact, Contract, Doc } from "../../models";
import { SendDocumentOrder, sendDocumentViaEmail } from "../../api/DocAPI";
import { alert } from "../../hooks/Alert";
import ChooseClientModal from "../ChooseClientModal";
import { Text, TextInput, View } from "react-native";
import { t } from "i18n-js";
import Button from "../Button";
import QuickSendPdfModal from "../QuickSendPdfModal/QuickSendPdfModal";
import { sentryCapture } from "../../utils/sentryCapture";
import DocumentOrderDropdown from "./DocumentOrderDropdown";

interface Props {
  doc: Doc;
  contract: Contract
  constructionSite: ConstructionSite
  close?: () => void;
}

const baseSubject = (doc: Doc, contract: Contract, constructionSite: ConstructionSite) => {
  return `${doc.Type.Name} - ${constructionSite.Name} - ${contract.Name}`
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SendPdf = ({doc, contract, constructionSite, close}: Props) => {
  const theme = getTheme();

  const [sendDocument, setSendDocument] = useState<SendDocumentOrder>(SendDocumentOrder.Order);
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>(
    baseSubject(doc, contract, constructionSite)
  )
  const [content, setContent] = useState<string>(doc.SaleEmployee.DefaultEmailContent);

  const [quickSendModalOpen, setQuickSendModalOpen] = useState<boolean>(false);
  const _openQuickSendModal = () => setQuickSendModalOpen(true);
  const _closeQuickSendModal = () => setQuickSendModalOpen(false);

  const [chooseClientModalOpen, setChooseClientModalOpen] = useState<boolean>(false);
  const _openChooseClientModal = () => setChooseClientModalOpen(true);
  const [client, setClient] = useState<Client | null>(null);
  const [contact, setContact] = useState<Contact | null>(null);

  const _client = (client: Client) => {
    setClient(client);
    if (client?.Email?.length > 0 && emailRegex.test(client.Email)) {
      setEmail(client.Email);
    }
  }
  const _contact = (contact: Contact) => {
    setContact(contact);
    if (contact?.Email?.length > 0 && emailRegex.test(contact.Email)) {
      setEmail(contact.Email);
    }
  }

  const _sendMe = async () => {
    try {
      await sendDocumentViaEmail(doc, undefined, subject, content, sendDocument);
      close && close();
    } catch (e) {
      sentryCapture(e);
      alert('ERROR', 'ERROR_SENDING_EMAIL');
    }
  }

  const _send = async () => {
    try {
      if (!emailRegex.test(email)) {
        alert('ERROR', 'NOT_VALID_EMAIL');
        return;
      }
      if (subject.length == 0) {
        alert('ERROR', 'NOT_VALID_SUBJECT');
        return;
      }
      await sendDocumentViaEmail(doc, email, subject, content, sendDocument);
      close && close();
    } catch (e) {
      sentryCapture(e);
      alert('ERROR', 'ERROR_SENDING_EMAIL');
    }
  }

  return (
    <>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text>{t("EMAIL")}</Text>
        <TextInput
          style={{
            padding: 6,
            marginHorizontal: 6,
            flex: 1,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: emailRegex.test(email) ? theme.border : 'red',
          }}
          value={email}
          onChangeText={setEmail}
          autoCapitalize="none"
        />
        <Button
          onPress={_openChooseClientModal}
          titleT='CLIENTS'
          style={{backgroundColor: theme.blue}}
        />
      </View>
      <Text style={{paddingVertical: 6, color: 'red'}}>{!emailRegex.test(email) && t('PLEASE_INSERT_EMAIL')}</Text>

      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text>{t("SUBJECT")}</Text>
        <TextInput
          style={{
            padding: 6,
            marginHorizontal: 6,
            flex: 1,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: subject.length == 0 ? 'red' : theme.border,
          }}
          value={subject}
          onChangeText={setSubject}
        />
        {
          doc.Type.Config.ConstructionSiteViewGroup == 'order' && (
            <DocumentOrderDropdown
              sendDocument={sendDocument}
              setSendDocument={setSendDocument}
            />
          )
        }
      </View>
      <Text style={{paddingVertical: 6, color: 'red'}}>{subject.length === 0 && t('PLEASE_INSERT_SUBJECT')}</Text>

      <Text style={{paddingBottom: 12}}>{t('CONTENT')}</Text>

      <TextInput
        style={{
          padding: 6,
          marginHorizontal: 6,
          borderRadius: 6,
          borderWidth: 1,
          borderColor: content.length == 0 ? theme.border : 'red',
          flex: 1,
        }}
        value={content}
        multiline={true}
        onChangeText={setContent}
      />

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingTop: 1,
        }}
      >
        <Button
          onPress={_sendMe}
          style={{
            marginRight: 6,
            backgroundColor: theme.blue,
          }}
          titleT='SEND_ME'
        />
        <Button
          onPress={_openQuickSendModal}
          style={{
            marginRight: 6,
            backgroundColor: theme.blue,
          }}
          titleT='QUICK_SEND'
        />
        <Button
          onPress={_send}
          style={{backgroundColor: theme.mainColor}}
          disabled={!emailRegex.test(email) || subject.length === 0}
          titleT='SEND'
        />
      </View>
      {
        chooseClientModalOpen && (
          <ChooseClientModal 
            setClient={_client}
            setContact={_contact}
            visible={chooseClientModalOpen}
            setVisible={setChooseClientModalOpen}
            client={client}
            contact={contact}
          />
        )
      }
      {
        quickSendModalOpen && (
          <QuickSendPdfModal
            doc={doc}
            onClose={_closeQuickSendModal}
            open={quickSendModalOpen}
            setEmail={setEmail}
            email={email}
          />
        )
      }
    </>
  )
};

export default SendPdf;