import React from 'react'
import { DayReportMaterial } from '../../../models'
import { KeyboardAvoidingView, Pressable, Text, View } from 'react-native'
import getTheme from '../../../constants/Colors'
import { t } from 'i18n-js'
import { FlatList } from 'react-native-gesture-handler'
import NumberInput from '../../document/PostitionComponent/NumberInput'
import Formatter from '../../../utils/formatters'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface DayReportMaterialsProps {
  materials: DayReportMaterial[]
  setMaterials: React.Dispatch<React.SetStateAction<DayReportMaterial[]>>
  theoricalMaterial: {[key: string]: number}
  setChanged: ()=> void
}

const DayReportMaterials = ({ materials, setMaterials, theoricalMaterial, setChanged }: DayReportMaterialsProps) => {

  const theme = getTheme()

  const f2 = Formatter.number2.format

  const _renderMaterial = ({ item, index }: { item: DayReportMaterial, index: number }) => {
    const theoric = theoricalMaterial[item.MaterialID || ''] || 0
    const background = index % 2 == 0 ? 'white' : theme.background
    const _quantity = (quantity: number) => {
      setChanged()
      setMaterials(m => m.map((m, i) => i == index ? {...m, Quantity: quantity} : m))
    }
    const _setTeorical = () => _quantity(theoric)
    return (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        backgroundColor: background,
        borderBottomWidth: materials.length - 1 == index ? 5 : 0,
        borderBottomColor: theme.mainColor,
      }}>
        <Text style={{width: 100}}>
          {item.MaterialIDString || ''}
        </Text>
        <Text style={{flex: 1}}>
          {item.Name}
        </Text>
        <Text style={{paddingHorizontal: 15}}>
          {item.UnitOfMeasure}
        </Text>
        {(theoric > item.Quantity) && (
          <MaterialCommunityIcons
            style={{paddingLeft: 5, paddingRight: 15}}
            color={theme.red}
            name='alert'
            size={20}
          />
        )}
        <View style={{width: 110}}>
          <NumberInput
            defaultValue={item.Quantity}
            onChange={_quantity}
            style={{width: '100%'}}
          />
        </View>
        <Pressable onPress={_setTeorical}>
          <Text style={{width: 80, textAlign: 'right'}}>
            {f2(theoric)}
          </Text>
        </Pressable>
        <Text style={{width: 80, textAlign: 'right'}}>
          {f2(item.SoldQuantity)}
        </Text>
      </View>
    )
  }

  return (
    <>
      <View style={{
        flexDirection: 'row',
        padding: 10,
        backgroundColor: theme.background,
      }}>
        <Text style={{width: 100, fontWeight: 'bold'}}>
          {t('CODE')}
        </Text>
        <Text style={{flex: 1, fontWeight: 'bold'}}>
          {t('DESCRIPTION')}
        </Text>
        <Text style={{width: 110, fontWeight: 'bold', textAlign: 'center'}}>
          {t('QUANTITY')}
        </Text>
        <Text style={{width: 80, fontWeight: 'bold', marginLeft: 5, textAlign: 'right'}}>
          {t('THEORICAL')}
        </Text>
        <Text style={{width: 80, fontWeight: 'bold', textAlign: 'right'}}>
          {t('TOTAL')}
        </Text>
      </View>
      <KeyboardAvoidingView style={{flex: 1}} behavior='padding' keyboardVerticalOffset={100}>
        <FlatList
          data={materials}
          renderItem={_renderMaterial}
          keyExtractor={(item, index) => `day-report-materials-${item.ID}-${index}`}
          extraData={theoricalMaterial}
        />
      </KeyboardAvoidingView>
    </>
  )
}

export default DayReportMaterials