import React from "react";
import { ConstructionSite } from "../../../models";
import { Pressable, Text, View } from "react-native";
import Status from "../../../components/Status";
import getTheme from "../../../constants/Colors";

interface Props {
  constructionSite: ConstructionSite;
  select: (constructionSite: ConstructionSite) => void;
  selected?: ConstructionSite;
}

const ConstructionSiteItem = ({constructionSite, select, selected}: Props) => {

  const theme = getTheme();

  const background = constructionSite.ID === selected?.ID ? "white" : theme.background;

  const code = constructionSite.Code.length > 0 ? `${constructionSite.Code} (${constructionSite.IDString})` : constructionSite.IDString;

  const _press = () => {
    select(constructionSite);
  }

  return (
    <Pressable 
      style={{
        borderBottomColor: theme.border,
        borderBottomWidth: 2,
        paddingBottom: 1,
        backgroundColor: background,
      }}
      onPress={_press}
    >
      <Text style={{fontSize: 18}}>{constructionSite.Name}</Text>
      <Text>{constructionSite.Address}</Text>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={{flex: 1}}>{code}</Text>
        <Text style={{flex: 1}}>{constructionSite.Branch?.Name}</Text>
        <Status status={constructionSite.Status} size={"m"} style={{marginRight: 5}}/>
      </View>
    </Pressable>
  );
};

export default ConstructionSiteItem;