import React, { useEffect, useMemo, useState } from "react";
import { DayReport, Discount, Doc, Line, LineType } from '../../models'
import { t } from "i18n-js";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import BillPreviewLine from "./BillPreviewLine";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { PriceLevel } from "../../store/reducers/settingsReducer";
import getTheme from "../../constants/Colors";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { getLineTotal } from "./BillPreviewLineLevel";
import { round } from '../../shared-utils/NumberUtils'
import { ActivityIndicator, FlatList, Text, View } from "react-native";
import Modal from "react-native-modal";
import Formatter from "../../utils/formatters";

interface Props {
  dayReport: DayReport;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  initOrder: Doc;
  activityIdToOrderLine: (activityId: number) => Line;
}

const BillPreviewModal = ({dayReport, visible, setVisible, initOrder, activityIdToOrderLine}: Props) => {

  const theme = getTheme();

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);
  const showPrice = showPriceLevel >= PriceLevel.CLIENT;

  const [order, setOrder] = useState<Doc>(initOrder);
  const [loading, setLoading] = useState<boolean>(true);
  const [lines, setLines] = useState<Line[]>([]);
  const [hideEmpty, setHideEmpty] = useState<boolean>(false);

  const _hideEmpty = () => setHideEmpty(old => !old);

  useEffect(() => {
    setLoading(true);
    const order = initOrder;

    dayReport.Reports?.forEach(r => {
      const orderLine = activityIdToOrderLine(r.ActivityID);
      for (let i = 0; i < order.Lines.length; i++) {
        if (order.Lines[i].ID === orderLine.ID && r.Levels) {
          for (let j = 0; j < r.Levels.length; j++) {
            orderLine.LineLevel[r.Levels[j].LevelIndex] = {
              ...orderLine.LineLevel[r.Levels[j].LevelIndex],
              BillableQuantity: (orderLine.LineLevel[r.Levels[j].LevelIndex].BillableQuantity || 0) + (r.Levels[j].BillableQuantity || 0),
            }
          }
        }
      }
    })

    setOrder(order);
    setLoading(false);
  }, [dayReport]);

  useEffect(() => {
    if (hideEmpty) {
      setLines(order.Lines.filter(l => {
        if (l.LineType === LineType.FIXED_PRICE || l.LineType === LineType.FIXED_PRICE_ENGINEERING_WARRANTY) {
          return true;
        }

        const lineLevelsNotEmpty = l.LineLevel?.filter(ll => (ll.BillableQuantity || 0) > 0) || []
        return lineLevelsNotEmpty.length > 0;
      }))
    } else {
      setLines(order.Lines);
    }
  })

  const totalDiscountsExcluded = useMemo(() => lines.reduce((p, v) => {
    if (v.LineType === LineType.TITLE) {
      return p;
    }
    if (v.LineType === LineType.FIXED_PRICE || v.LineType === LineType.FIXED_PRICE_ENGINEERING_WARRANTY) {
      return p + v.LineTotal; // TODO fix calculation after clarified DRYapp #454
    }
    
    return p + (v.LineLevel?.reduce((p, v) => {
      if (v.FixedPriceBlock && v.FixedPriceBlock.length > 0 && v.FixedPriceBlock != "-") {
        return p;
      }

      return p + getLineTotal(v);
    }, 0) || 0);
  }, 0), [lines]);

  const total = useMemo(() => {
    let t = totalDiscountsExcluded;
    
    for (let i = 0; i < order.Discounts.length; i++) {
      if (order.Discounts[i].isNumeric) {
        t -= order.Discounts[i].value;
      } else {
        t -= t * order.Discounts[i].value / 100
      }
    }

    return t;
  }, [totalDiscountsExcluded])

  const currencyFormatter = Formatter.currency(order.Currency.ID);

  const _close = () => setVisible(false);

  const _renderLine = ({item, index}: {item: Line, index: number}) => {

    return (
      <BillPreviewLine
        index={index}
        levels={order.Levels}
        orderBlock={order.Block}
        orderLine={item}
        showPrice={showPrice}
        hideEmpty={hideEmpty}
        key={`bill-preview-modal-lines${order.ID}`}
      />
    )
  }

  const _renderDiscount = ({item, index}: {item: Discount, index: number}) => {
    if (item.isNumeric) {
      return (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{width: 100}}/>
          <Text style={{flex: 4}}>{item.name}</Text>
          <View style={{flex: 2}} />
          <Text style={{flex: 2, textAlign: 'right'}}>{currencyFormatter.format(-item.value)}</Text>
        </View>
      );
    }
    let totalValue = totalDiscountsExcluded;
    let discount = 0;

    for (let i = 0; i <= index; i++) {
      if (order.Discounts[i].isNumeric) {
        totalValue -= order.Discounts[i].value;
      } else {
        totalValue -= totalValue * order.Discounts[i].value / 100;
        discount = round(totalValue * order.Discounts[i].value / 100, 2);
      }
    }
    
    return (
      <View style={{flexDirection: "row", alignItems: "center"}}>
        <View style={{width: 100}} />
        <Text style={{flex: 4}}>{item.name}</Text>
        <Text style={{flex: 2, textAlign: 'right'}}>{Formatter.number2.format(-item.value)} %</Text>
        <Text style={{flex: 2, textAlign: 'right'}}>{currencyFormatter.format(-discount)}</Text>
      </View>
    );
  }

  return (
    <Modal
      isVisible={visible}
      onBackButtonPress={_close}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
    >
      <View style={{flex: 1}}>
        {loading &&
          <View style={{
            zIndex: 100,
            height: "100%",
            width: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,.7)"
          }}>
            <ActivityIndicator color={theme.mainColor} />
          </View>
        }
        <View style={{flexDirection: 'column'}}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <View style={{flexDirection: 'column', flex: 2}}>
              <Text style={{fontSize: 20}}>
                {t("BILL_PREVIEW_ORDER")}: {order.IDString} - {t("AFTER_DAY_REPORT")}: {dayReport.ID}
              </Text>
              <Text style={{fontSize: 16}}>{dayReport.ConstructionSite.Code} - {dayReport.ConstructionSite.Name}    -    {dayReport.Contract.Name}</Text>
            </View>

            <View style={{flexDirection: 'row', flex: 1}}>
              <BouncyCheckbox
                fillColor={theme.mainColor}
                iconStyle={{borderColor: theme.border, borderRadius: 10}}
                innerIconStyle={{borderColor: theme.border, borderRadius: 10}}
                size={20}
                onPress={_hideEmpty}
              />
              <Text>{t("HIDE_EMPTY")}</Text>
            </View>

            <MaterialCommunityIcons
              size={34}
              name="close"
              onPress={_close}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 5,
              alignItems: 'center',
            }}
          >
            <Text style={{width: 100}}>{t("CODE")}</Text>
            <Text style={{flex: 4}}>{t("NAME")}</Text>
            <Text style={{flex: 1, textAlign: 'center'}}>{t("UNIT_OF_MEASURE")}</Text>
            <Text style={{flex: 1, textAlign: 'right'}}>{t("QUANTITY")}</Text>
            <Text style={{flex: 1, textAlign: 'right'}}>{t("PRICE")}</Text>
            <Text style={{flex: 1, textAlign: 'right'}}>{t("TOTAL")}</Text>
          </View>
        </View>

        <View style={{paddingVertical: 10, flex: 1}}>
          <FlatList data={lines} renderItem={_renderLine} key={`flat-list-bill-preview-modal-lines`} />
        </View>

        <View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text style={{fontWeight: 'bold'}}>{t("TOTAL_BEFORE_DISCOUNTS")}</Text>
            <Text style={{textAlign: 'right', fontWeight: 'bold'}}>{currencyFormatter.format(totalDiscountsExcluded)}</Text>
          </View>
          
          <Text>{t("DISCOUNTS")}</Text>
          
          <FlatList
            data={order.Discounts}
            renderItem={_renderDiscount}
            key={`flat-list-bill-preview-modal-discounts`}
            keyExtractor={(item, index) => `bill-preview-modal-dicount-${index}`}
          />

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text style={{fontWeight: 'bold'}}>{t("TOTAL")}</Text>
            <Text style={{fontWeight: 'bold', textAlign: 'right'}}>{currencyFormatter.format(total)}</Text>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default BillPreviewModal;