import React from 'react';
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { ReadLevel } from "../../models";
import { t } from "i18n-js";
import NativeModalPicker from '../NativeModalPicker';

interface Props {
  readLevel?: ReadLevel;
  setReadLevel: (readLevel:ReadLevel) => void;
}

const ReadLevelSelect = ({readLevel, setReadLevel}:Props) => {

  const readLevels = useSelector((state:Reducer) => state.general.readLevels);

  const _onValueChange = (selectedReadLevel: ReadLevel) => {
    if (readLevel?.ID === selectedReadLevel.ID) {
      return;
    }

    setReadLevel(selectedReadLevel)
  };

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        backgroundColor: 'white',
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
      }}
      accessibilityLabel={t("READ_LEVEL")}
      placeholder={'READ_LEVEL'}
      selectedValue={readLevel}
      onValueChange={_onValueChange}
      options={readLevels}
      showLabelAsOption={true}
    />
  )
}

export default ReadLevelSelect;