import React from 'react';
import { ConstructionSiteType } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import NativeModalPicker from '../NativeModalPicker';

interface Props {
  type?: ConstructionSiteType;
  setType: (type: ConstructionSiteType) => void;
  disabled?: boolean;
}

const ConstructionSiteTypeDropdown = ({type, setType, disabled = false}: Props) => {

  const types = useSelector((state: Reducer) => state.constructionSite.types);

  const _change = (selectedType: ConstructionSiteType) => {
    if (type?.ID == selectedType.ID || disabled) {
      return;
    }

    setType(selectedType)
  }

  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={type}
      onValueChange={_change}
      placeholder='SELECT_TYPE'
      options={types}
      showLabelAsOption={true}
    />
  );
};

export default ConstructionSiteTypeDropdown;