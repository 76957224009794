import React, { useEffect, useMemo, useState } from "react";
import { Client, Contact, DocumentContact } from "../../models";
import getTheme from "../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { getAllClients } from "../../store/action/clientsActions";
import { ActivityIndicator, FlatList, ScrollView, TextInput } from "react-native";
import { t } from "i18n-js";
import ClientItem from "./ClientItem";
import ContactItem from "./ContactItem";
import { Text, View } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import CustomContactModal from "./CustomContactModal";
import { alert } from "../../hooks/Alert";
import { SearchScreens } from "../../screens/search/SearchScreens";
import Modal from "react-native-modal";
import Button from "../Button";
import { userCanCreateBusinessPartners, userCanUpdateBusinessPartners } from "../../models/permissions";

interface Props {
  client: Client | null;
  contact: Contact | null;
  setClient: (client: Client) => void;
  setContact: (contact: Contact) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setCustomContact?: (customContact: DocumentContact) => void;
  customContact?: DocumentContact;
}

const ChooseClientModal = ({
                             client,
                             contact,
                             setClient,
                             setContact,
                             visible,
                             setVisible,
                             setCustomContact,
                             customContact
                           }: Props) => {

  const theme = getTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const user = useSelector((state: Reducer) => state.user)

  const [showCustomContactModal, setShowCustomContactModal] = useState<boolean>(false);

  const clients = useSelector((state: Reducer) => state.client.clients);
  const clientsLoading = useSelector((state: Reducer) => state.sync.clientsLoading);

  const [search, setSearch] = useState<string>("");
  const [filtered, setFiltered] = useState<Client[]>(clients);

  const ordered = useMemo(() => {
    if (!client) {
      return filtered;
    }

    return filtered.sort((a, b) => {
      if (a.ID == client.ID) {
        return -1;
      }
      return 0;
    })
  }, [filtered])

  useEffect(() => {
    if (search.length == 0) {
      setFiltered(clients);
    }
  }, [clients]);

  const _refresh = () => {
    dispatch(getAllClients());
  }

  const _newClient = () => {
    setVisible(false);
    // @ts-ignore
    navigation.navigate(SearchScreens.CREATE_CLIENT, {
      setClient,
    });
  }

  const _edit = (c: Client | null) => {
    if (client) {
      setVisible(false);
      // @ts-ignore
      navigation.navigate(SearchScreens.UPDATE_CLIENT, {
        setClient,
        clientId: String(client.ID),
      })
    }
    if (c) {
      setVisible(false);
      // @ts-ignore
      navigation.navigate(SearchScreens.UPDATE_CLIENT, {
        setClient,
        clientId: String(c.ID),
      })
    }
  }

  const _searchHandler = (search: string) => {
    setSearch(search);
    const s = search.trim().toLowerCase();

    setFiltered(clients.filter(c => {
      // TODO search in contacts
      return String(c.ID).toLowerCase().includes(s) ||
        c.Name.toLowerCase().includes(s);
    }));
  }

  const _close = () => {
    setVisible(false);
  }

  const _setClient = (item: Client) => {
    setClient(item);
    if (!item.DefaultBillAddressID) {
      alert("WARNING", "CLIENT_HAS_NO_ADDRESS");
    }
    if (item.Contacts.length === 0) {
      setVisible(false);
    }
  }

  const _renderClient = ({item}: {item: Client}) => (
    <ClientItem client={item} select={_setClient} selected={client} edit={_edit} />
  );

  const _setContact = (item: Contact) => {
    setContact(item);
    setVisible(false);
  }

  const _renderContact = ({item}: {item: Contact}) => (
    <ContactItem contact={item} select={_setContact} selected={contact}/>
  );

  const _choose = () => {
    setVisible(false);
  }

  const _createCustomContact = () => {
    setVisible(false);
    setShowCustomContactModal(true);
  }

  return (
    <View>
      <Modal
        isVisible={visible}
        onBackdropPress={_close}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
        }}  
      >
        <View style={{flexDirection: 'row'}}>
          <Text style={{fontSize: 25, flex: 1}}>{t('CHOOSE_CLIENT')}</Text>
          <MaterialIcons
            name="close"
            size={34}
            onPress={_close}
          />
        </View>

        <TextInput
          style={{
            backgroundColor: 'white',
            padding: 5,
            borderColor: theme.border,
            borderWidth: 1,
            borderRadius: 5,
            marginVertical: 10,
          }}
          placeholder={t('SEARCH')}
          onChangeText={_searchHandler}
        />

        <View style={{flexDirection: 'row', flex: 1, paddingTop: 5}}>
          <View
            style={{
              flexDirection: 'column',
              flex: 1,
              paddingRight: 10,
              paddingHorizontal: 2
            }}
          >
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{fontSize: 20, paddingBottom: 2, flex: 1}}>{t('CLIENTS')}</Text>
              {userCanCreateBusinessPartners(user) && (
                <Button
                  onPress={_newClient}
                  titleT={'CREATE_NEW_CLIENT'}
                  style={{backgroundColor: theme.secondaryBackground}}
                  leftComponent={<MaterialIcons name="add" color="white" />}
                />
              )}

              {
                (setCustomContact && customContact) && (
                  <Button
                    onPress={_createCustomContact} 
                    style={{
                      marginLeft: 2,
                      backgroundColor: theme.secondaryBackground,
                    }}
                    titleT='CREATE_CUSTOM_CONTACT'
                  />
                )
              }

            </View>
            <ScrollView>
              <FlatList
                data={filtered}
                renderItem={_renderClient}
                refreshing={clientsLoading}
                onRefresh={_refresh}
                key={`flat-list-clients`}
                keyExtractor={(item, index) => `client-${index}-${item.ID}`}
              />
            </ScrollView>
          </View>
          <View
            style={{
              flexDirection: 'column',
              flex: 1,
              paddingLeft: 10
            }}
          >
            {client ? <>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={{fontSize: 20, paddingBottom: 2, flex: 1}}>{t('CHOOSE_CONTACT')}</Text>
                {userCanUpdateBusinessPartners(user) && (
                  <Button 
                    onPress={() => _edit(null)}
                    titleT="EDIT_CLIENT"
                    style={{backgroundColor: theme.secondaryBackground}}
                    leftComponent={<MaterialIcons name="edit" color="white" />}
                  />
                )}
              </View>
              <FlatList
                data={client.Contacts}
                renderItem={_renderContact}
                ListEmptyComponent={<Text>{t('CLIENT_HAS_NO_CONTACTS')}</Text>}
                key={`flat-list-contacts`}
                keyExtractor={(item, index) => `contact-${index}-${client.ID}-${item.ID}`}
              />
            </> : <Text>{t('NO_CLIENT_SELECTED')}</Text>}
          </View>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <Button
            onPress={_refresh} 
            disabled={clientsLoading}>
          {
            clientsLoading ? (
              <ActivityIndicator color="white" />
            ) : (
              <MaterialIcons name="refresh" color="shite" size={14} />
            )
          }
          </Button>
          <Button 
            style={{
              backgroundColor: theme.mainColor
            }}
            onPress={_choose}
            titleT="CHOOSE"
          />
        </View>
      </Modal>

      {
        (customContact && setCustomContact) && (
          <CustomContactModal customContact={customContact}
                              setCustomContact={setCustomContact}
                              setVisible={setShowCustomContactModal}
                              visible={showCustomContactModal} />
        )
      }
    </View>
  );
};

export default ChooseClientModal;