import React from "react";
import { Client, DocumentContact } from "../../models";
import QuickSendPdfClient from "./QuickSendPdfClient";

interface Props {
  documentContact: DocumentContact;
  email: string;
  setEmail: (email: string) => void;
  useBillEmail?: boolean;
}

const QuickSendPdfDocumentContact = ({documentContact, email, setEmail, useBillEmail = false}: Props) => {
  if (documentContact.Client) {
    return (
      <QuickSendPdfClient
        client={documentContact.Client}
        clientContact={documentContact.ClientContact}
        description={documentContact.Type?.Name || ""}
        email={email}
        setEmail={setEmail}
        useBillEmail={useBillEmail}
      />
    );
  }

  const c = {
    Name: `${documentContact.Firstname} ${documentContact.Lastname}`,
    Email: documentContact.Email,
  } as Client;

  return (
    <QuickSendPdfClient
      client={c}
      description={documentContact.Type?.Name || ""}
      email={email}
      setEmail={setEmail}
      useBillEmail={useBillEmail}
    />
  );
};

export default QuickSendPdfDocumentContact;