import React, { useMemo } from "react";
import { ConstructionSiteViewGroups, DocType } from "../../models";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import NativeModalPicker from "../NativeModalPicker";
import { DocStatus } from "../../constants/Status";

interface Props {
  type: DocType;
  setType: (type: DocType) => void;
  disabled?: boolean;
  contractStatus?: number
  orderDocType?: boolean
}

const DocTypeDropdown = ({
  type,
  setType,
  disabled = false,
  contractStatus,
  orderDocType,
}:Props) => {

  const types = useSelector((state:Reducer) => state.doc.types);

  const filteredTypes = useMemo(() => types.filter(t => {
    if (orderDocType) {
      return t.Config.ConstructionSiteViewGroup == ConstructionSiteViewGroups.Order
    }
    if (contractStatus == undefined) {
      return t.Config.TypeDropdown && t.Config.TypeFilter != 'integrative-offer'
    }
    switch (contractStatus) {
      case DocStatus.OFFER.code:
        return t.Config.TypeFilter == 'offer' || t.Config.TypeFilter == 'integrative-offer'
      case DocStatus.IN_EXECUTION.code:
      case DocStatus.OPEN.code:
      case DocStatus.TO_BE_APPROVED.code:
      case DocStatus.WIN.code:
      case DocStatus.TO_BE_BILLED.code:
        return t.Config.TypeFilter == 'integrative-offer'
    }
    return false
  }), [types]);

  const _change = (selectedType: DocType) => {
    if (type?.ID == selectedType.ID || disabled) {
      return;
    }

    setType(selectedType)
  }
    
  return (
    <NativeModalPicker
      itemToLabel={item => item.Name}
      itemToKey={item => String(item.ID)}
      style={{
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 5,
        backgroundColor: 'white',
      }}
      selectedValue={type}
      onValueChange={_change}
      placeholder='SELECT_TYPE'
      options={filteredTypes}
      showLabelAsOption={true}
    />
  );
};

export default DocTypeDropdown;