import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import getTheme from "../../../constants/Colors";
import Button from "../../../components/Button";

interface Props {
  onBefore: () => void;
  onAfter: () => void;
  onReset: () => void;
}

const WeekSelector = ({ onBefore, onAfter, onReset }: Props) => {
  const theme = getTheme();

  return (
    <>
      <MaterialCommunityIcons
        onPress={onBefore}
        name="chevron-left"
        size={24}
        style={{paddingLeft: 6}}
      />
      <Button
        onPress={onReset}
        style={{
          borderColor: theme.secondaryBackground,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          paddingHorizontal: 10,
          borderRadius: 0,
        }}
        _textStyle={{color: 'black'}}
        titleT="TODAY"
      />
      <MaterialCommunityIcons
        onPress={onAfter}
        name="chevron-right"
        size={24}
        style={{paddingRight: 6}}
      />
    </>
  );
};

export default WeekSelector;
