import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Pressable, View, Text } from "react-native";
import getTheme from "../../../../constants/Colors";
import { statusCodeToText } from "../../../../constants/Status";
import { ConstructionSite } from "../../../../models";
import styles from "./style";
import Status from "../../../../components/Status";

interface ClientOverviewConstructionSiteItemProps {
  item: ConstructionSite
  index: number
}

const ClientOverViewConstructionSiteItem = ({item, index}: ClientOverviewConstructionSiteItemProps) => {
  const theme = getTheme();

  const navigation = useNavigation();

  const _press = () => {
    // @ts-ignore
    navigation.navigate("ConstructionSitesDetails", item);
  };

  return (
    <Pressable
      onPress={_press}
      style={{
        borderTopColor: theme.border,
        borderTopWidth: index == 0 ? 0 : 1,
        paddingVertical: 5,
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 2,
        justifyContent: 'space-between'
      }}>
        <Text>
          {item.Code?.length > 0 ? item.Code : item.IDString}
        </Text>

        <Status status={item.Status} size="xs" />
      </View>
      
      <Text style={{fontSize: 16}}>
        {item.Name}
      </Text>
      <Text>
        {item.Address}
      </Text>
      
    </Pressable>
  );
};

export default ClientOverViewConstructionSiteItem;
