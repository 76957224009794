import React, { useEffect, useMemo, useState } from "react";
import { ConstructionSite, Contract } from "../../models";
import { ActivityIndicator, Text, View, TextInput, Switch } from "react-native";
import { t } from "i18n-js";
import { getConstructionSiteById, updateContract } from "../../api/ConstructionSitesAPI";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { alert, alertConfirm } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import { useDispatch } from "react-redux";
import Modal from "react-native-modal";
import getTheme from "../../constants/Colors";
import Button from "../Button";
import { sentryCapture } from "../../utils/sentryCapture";
import WarrantyForDropdown from "../WarrantyForDropdown";

interface Props {
  reload: () => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  contract: Contract;
}

const EditContractModal = ({reload, visible, setVisible, contract}: Props) => {

  const dispatch = useDispatch();
  const theme = getTheme();

  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const [c, setC] = useState<Contract>({...contract});
  const [constructionSiteId, setConstructionSiteId] = useState<string>(String(contract.ConstructionSiteID));

  const [constructionSite, setConstructionSite] = useState<ConstructionSite>()

  useEffect(() => {
    getConstructionSiteById(Number(constructionSiteId))
      .then(setConstructionSite)
  }, [constructionSiteId])

  const constructionSiteIdRef = React.createRef();

  const _onClose = async () => {
    if (loading) {
      return;
    }
    if (changed) {
      const cancelChanges = await alertConfirm("CONTRACT_CHANGED", "CONTRACT_CHANGED_DESC");

      if (cancelChanges) {
        setChanged(false);
        setVisible(false);
        setC({...contract});
      }
    } else {
      setVisible(false);
    }
  }

  const _update = async () => {
    if (c.Name.length === 0) {
      alert('NOT_VALID_CONTRACT_NAME').then();
      return;
    }
    if (c.ConstructionSiteID === 0) {
      alert('NOT_VALID_CONSTRUCTION_SITE_ID').then();
      return;
    }

    if (c.ConstructionSiteID != contract.ConstructionSiteID) {
      const safeProceed = await alertConfirm(
        "NOT_SAFE_OPERATION",
        "ARE_YOU_SURE_TO_CHANGE_CONSTRUCTION_SITE_ID_DESC"
      );
      if (!safeProceed) {
        return;
      }
    }

    setLoading(true);
    try {
      const updated = await updateContract(c);
      setC(updated);
      reload();
      setChanged(false);
      setVisible(false);
    } catch (e: any) {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR", "ERROR_UPDATING_CONTRACT");
      }
    } finally {
      setLoading(false);
    }
  }

  const _constructionSiteChange = (value: string) => {
    setChanged(true);
    setConstructionSiteId(value);
  }

  const _constructionSiteBlur = () => {
    const id = Number(constructionSiteId);

    if (isNaN(id)) {
      alert("NOT_VALID_CONSTRUCTION_SITE_ID").then();
      // @ts-ignore
      constructionSiteIdRef.current?.focus();
    }

    setC({...c, ConstructionSiteID: id});
  }

  const _nameChange = (name: string) => {
    setChanged(true);
    setC({...c, Name: name});
  }

  const _notesChange = (notes: string) => {
    setChanged(true);
    setC({...c, Notes: notes});
  }

  const _setWarrantyForId = (warrantyForId?: number) => {
    setChanged(true)
    setC({...c, WarrantyFor: warrantyForId})
  }

  const _measure = (measure: boolean) => {
    setChanged(true)
    setC({
      ...contract,
      Measure: measure,
    })
  }

  const _publicProcurement = (publicProcurement: boolean) => {
    setChanged(true)
    setC({
      ...contract,
      PublicProcurement: publicProcurement,
    })
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={_onClose}
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
        flex: 1,
      }}
    >
      {loading ?
        <ActivityIndicator/> :
        <View
          style={{
            flexDirection: 'column',
            backgroundColor: 'white',
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text>
              {t("EDIT_CONTRACT")} ({t("CODE")}: {contract.ID})
            </Text>
            <MaterialCommunityIcons
              size={24}
              color='black'
              name='close'
              onPress={_onClose}
            />
          </View>

          <View 
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 15,
            }}
          >
            <Text style={{marginRight: 15}}>
              {t("CONSTRUCTION_SITE")} ID: {constructionSiteId}
            </Text>
          </View>

          <View 
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 15,
            }}
          >
            <Text style={{marginRight: 15}}>
              {t("NAME")}:
            </Text>
            <TextInput
              value={c.Name}
              onChangeText={_nameChange}
              style={{
                flex: 1,
                padding: 6,
                borderRadius: 6,
                borderColor: theme.border,
                borderWidth: 1,
              }}
            />
          </View>

          <Text style={{marginRight: 15, paddingTop: 15}}>
            {t("NOTES")}:
          </Text>

          <TextInput
            value={c.Notes}
            onChangeText={_notesChange}
            multiline={true}
            style={{
              flex: 1,
              padding: 6,
              borderRadius: 6,
              borderColor: theme.border,
              borderWidth: 1,
            }}
          />

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: 15,
            }}
          >
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}>
              <Text style={{paddingRight: 6}}>{t('WARRANTY_FOR')}:</Text>
              <WarrantyForDropdown
                contracts={constructionSite?.Contracts || []}
                setWarrantyForId={_setWarrantyForId}
                warrantyForId={c.WarrantyFor}
              />
            </View>

            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <Text style={{paddingHorizontal: 10}}>{t('MEASURE')}</Text>
              <Switch value={c.Measure} onValueChange={_measure} disabled={c.Reports.filter(r => r.ApprovedBy != undefined).length > 0} />
              <Text style={{paddingHorizontal: 10}}>{t('PUBLIC_PROCUREMENT')}</Text>
              <Switch value={c.PublicProcurement} onValueChange={_publicProcurement} />
            </View>
          </View>

          <View style={{flex: 1}} />

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingTop: 15,
            }}
          >
            <Button
              onPress={_update}
              disabled={!changed}
              titleT='UPDATE_CONTRACT'
              style={{
                backgroundColor: theme.mainColor,
              }}
            />
          </View>
        </View>
      }
    </Modal>
  )
}

export default EditContractModal;