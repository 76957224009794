import { ConstructionSite, Contract, Discount, Doc, DocumentContact, User, LineType, LineSumType } from '../../models'
import { t } from "i18n-js";

export const createDoc = (id: number, contractId: number, user: User): Doc => {
  if (!user.DefaultSaleEmployee) {
    throw "Cannot create an offer without DefaultSaleEmployee";
  }

  return {
    ID: id,
    IDString: "",
    Name: "",
    ContractID: contractId,
    Language: user.language,
    LanguageID: user.language.ID,
    Currency: user.currency,
    CurrencyID: user.currency.ID,
    Branch: user.branch,
    BranchID: user.branch.ID,
    SaleEmployee: user.DefaultSaleEmployee,
    SaleEmployeeID: user.DefaultSaleEmployee.ID,
    ShowLevelsEnabled: true,
    PrintLevels: false,
    ClientDigitalDocuments: false,
    ByDigitalDocuments: false,
    OfferNamedToDigitalDocuments: false,
    InvoiceToDigitalDocuments: false,
    DocumentContacts: [] as DocumentContact[],
    Levels: [
      {
        ID: -1,
        Name: t("GENERAL"),
      }
    ],
    Discounts: [] as Discount[],
    Block: false,
    RoundingDiff: 0,
  } as Doc
}

export const toConcordToOffer = (id: number, doc: Doc, user: User) => {
  const d = duplicateDoc(id, doc, user)

  return {
    ...d,
    Lines: d.Lines.map(l => {
      switch (l.LineType) {
        case LineType.TITLE:
          return {
            ...l,
          }
        case LineType.POSITION:
        case LineType.POSITION_CAR:
        case LineType.POSITION_ENGINEERING:
        case LineType.POSITION_EXTERNAL_WORK:
        case LineType.POSITION_SELL_WARRANTY:
        case LineType.POSITION_WORK_WARRANTY:
          return {
            ...l,
            LineLevel: l.LineLevel.map(ll => {
              if (ll.LineSumType === LineSumType.TO_CONCORD) {
                let LineTotal;
                if (ll.DiscountNumeric) {
                  LineTotal = ll.Quantity * ll.Price - ll.Discount
                } else {
                  LineTotal = ll.Quantity * ll.Price * (1 - ll.Discount / 100)
                }
                return {
                  ...ll,
                  LineSumType: LineSumType.NORMAL,
                  LineTotal: LineTotal,
                }
              }
              return {
                ...ll,
                Quantity: 0,
                LineTotal: 0,
                FixedPriceBlock: "",
              }
            }),
            Quantity: l.LineLevel.reduce((p, v) => {
              if (v.LineSumType === LineSumType.TO_CONCORD) {
                return p + v.Quantity
              } else {
                return p
              }
            }, 0),
            LineTotal: l.LineLevel.reduce((p, v) => {
              if (v.LineSumType === LineSumType.TO_CONCORD) {
                if (v.DiscountNumeric) {
                  return p + v.Quantity * v.Price - v.Discount
                } else {
                  return p + v.Quantity * v.Price * (1 - v.Discount / 100)
                }
              } else {
                return p
              }
            }, 0),
          }
      }
      return {
        ...l,
      }
    })
  }
}

export const duplicateDoc = (id:number, doc:Doc, user: User):Doc => {
  const d = createDoc(-1, doc.ContractID, user);
  d.Lines = doc.Lines;
  d.Type = doc.Type;
  d.TypeID = doc.TypeID;
  d.Discounts = doc.Discounts;
  d.PriceBlock = doc.PriceBlock;
  d.Total = doc.Total;
  d.Levels = doc.Levels;
  d.PrintLevels = doc.PrintLevels;
  d.Block = doc.Block;
  d.Currency = doc.Currency;
  d.CurrencyID = doc.CurrencyID;
  d.CatalogID = doc.CatalogID;
  d.Catalog = doc.Catalog;
  d.DocumentContacts = doc.DocumentContacts;
  d.BranchID = doc.BranchID;
  d.Branch = doc.Branch;
  d.LanguageID = doc.LanguageID;
  d.Language = doc.Language;
  d.Notes = doc.Notes;

  return d;
}

export const createContract = (id: number, constructionSiteId: number, user: User): {contract: Contract, doc: Doc} => {
  return {
    contract: {
      ID: id,
      ConstructionSiteID: constructionSiteId,
      Name: "",
      Measure: true,
    } as Contract,
    doc: createDoc(-1, id, user),
  };
}

export const createConstructionSite = (id: number, user: User): {constructionSite: ConstructionSite, contract: Contract, doc: Doc} => {
  return {
    constructionSite: {
      ID: id,
      Branch: user.branch,
      BranchID: user.branch.ID,
      Code: "",
      IDString: "",
      Name: "",
      Address: "",
      Maps: ".",
      Accessible: false,
      Aquifer: false,
      Notes: '',
    } as ConstructionSite,
    ...createContract(-1, id, user),
  };
}