import { t } from 'i18n-js'

export type WorkSpace = {
  id: number;
  name: string;
  url: string;
  secondaryUrl: string;
};

export type ResourceType = {
  ID: number;
  IDString: string;
  Name: string;
  Operative: boolean
};

export type Resource = {
  ID: number;
  IDString: string;
  ProfileImage: string;
  FirstName: string;
  LastName: string;
  Username: string;
  Email?: string;
  Phone?: string;
  Internal: boolean;
  HourlyCost?: number;
  ResourceType: ResourceType;
  Active: boolean;
  PlanningWeight?: number
};

export type Photo = {
  ID: number;
  URL: string;
  LowDefinitionURL: string;
  Resource: Resource;
  DateTime: number;
};

export type ActivityLevel = {
  SoldQuantity: number;
  DoneQuantity: number;
  LineSumType: string;
  LevelID: number;
  ActivityID: number;
};

export const LineType = {
  POSITION_EXTERNAL_WORK: "position-external-work",
  POSITION_SELL_WARRANTY: "position-sell-warranty",
  POSITION_WORK_WARRANTY: "position-work-warranty",
  POSITION_ENGINEERING: "position-engineering",
  POSITION_CAR: "position-car",
  POSITION: "position",
  TITLE: "title",
  FIXED_PRICE: "fixed-price",
  FIXED_PRICE_ENGINEERING_WARRANTY: "fixed-price-engineering-warranty",
  TEXT: "text",
  ITEM: "item",
  DISCOUNT: "discount",
}

export const LineSumType = {
  NORMAL: "normal",
  PRICE_LIST: "price-list",
  TO_CONCORD: "to-concord",
}

export enum WorkGroupFilter {
  OTHER = 'OTHER',
  MATERIALS = 'MATERIALS',
  ADD_WORK = 'ADD_WORK',
  HOURS_POSITION = 'HOURS_POSITION',
}

export type WorkGroup = {
  name: string
  weight: number
  workGroupFilter: string
}

export type Activity = {
  ID: number;
  IDString: string;
  Code: string;
  Name: string;
  UnitOfMeasure?: string;
  Type: string;
  UnitTime?: number;
  SoldHours?: number;
  DoneHours?: number;
  ActivityGroup?: string;
  Materials?: Material[];
  Levels?: ActivityLevel[];
  FatherID?: string;
  BackgroundColor?: string
  WorkGroup: string
  TwinIDs: string[]
};

export type Material = {
  ID: string;
  IDString: string;
  Name: string;
  Type: string;
  Quantity?: number; // used in report
  ApprovedQuantity?: number;
  UnitQuantity: number;
  UnitOfMeasure: string;
  MaterialID?: string;
  MaterialIDString?: string;
};

export type DayReportMaterial = Omit<Material, 'Quantity'> & {
  SoldQuantity: number
  Quantity: number
}

export type ReportLevel = {
  ID: number;
  ReportID: number;
  LevelID: number;
  ActivityID: number;
  LevelIndex: number;
  Quantity: number;
  ApprovedQuantity?: number;
  BillableQuantity?: number;
  BilledQuantity?: number;
  BillLaterQuantity?: number;
  InvoiceID?: number;
  SoldQuantity?: number;
  DoneQuantity?: number;
  LineSumType?: string;
};

export type Report = {
  ID: number;
  IDString?: string;
  DayReportID: number;
  ActivityID: number;
  ActivityIDString: string;
  LineCode: string
  Name: string;
  UnitOfMeasure?: string;
  UnitTime?: number;
  Materials?: Material[];
  Type: string;
  Duration?: number;
  ApprovedDuration?: number;
  FatherID?: string;
  Levels?: Array<ReportLevel>;
  ActivityGroup?: string;
  Notes: string
  BackgroundColor?: string
  WorkGroup: string
  TwindIDs: string[]
};

export type DayReport = {
  ID: number;
  IDString?: string;
  ContractID: number;
  ConstructionSite: ConstructionSite;
  Contract: Contract;
  TotalTime: number; // Time from the arrive to the work place to the end
  BreakTime: number; // Break taken
  DateTime: Date; // Arrive date time to the work place
  TravelTime: number;
  Reports?: Report[];
  Resource: Resource;
  ResourceID: number;
  Notes: string;
  ApprovalNotes?: string;
  SignedBy?: string;
  SignedTime?: Date;
  SignedPlace?: string;
  Signature?: string;
  ApprovedByID?: number;
  ApprovedBy?: Resource;
  Levels: Level[];
  Billed?: boolean;
  DocumentContacts: DocumentContact[]
  OrderNotes: string
  CatalogID: number
  measureId?: number
  ExternalWorkInvoiceId?: number
  ExternalWorkInvoice?: ExternalWorkInvoice
};

export type ReadLevel = {
  ID: number;
  Name: string;
  Level: number;
};

export type File = {
  FileTypeID: number;
  ID: number;
  Name: string;
  CreatedBy: Resource;
  CreatedDate: Date;
  UpdatedBy: Resource;
  UpdatedDate: Date;
  URL: string;
  ReadLevel: ReadLevel;
  Version: number;
  Versions: File[];
};

export type FileType = {
  ID: number;
  Name: string;
  Files: File[];
};

export type UoM = {
  ID: number;
  IDString: string;
  Hours: boolean;
  Name: string;
};

export type VAT = {
  ID: string;
  IDString: string;
  Name: string;
  Percentage: number;
};

export type Planning = {
  Resource: Resource;
  StartDate: Date;
  EndDate: Date;
  Days: {Date: Date; ConstructionSites: ConstructionSite[]}[];
};

export type Address = {
  ID: string;
  ClientID: string; // reference to client
  Name: string;
  StreetLine0: string;
  StreetLine1: string;
  StreetLine2: string;
  PostCode: string;
  City: string;
  Region: string;
  Country: string;
};

export type State = {
  ID: string;
  Name: string;
}

export type Country = {
  ID: string;
  Name: string;
  States?: State[];
}

export type Client = {
  ID: string | number | null;
  Company: boolean; // true company, false person
  Name: string;
  Type: ClientType | null;
  VAT: string;
  Phone: string;
  Mobile: string;
  Email: string;
  Contacts: Contact[];
  Addresses: Address[];
  FiscalCode: string;
  DefaultShipAddressID?: string;
  DefaultBillAddressID?: string;
  Industry: Industry;
  IndustryID: number;
  EmailBillAddress: string
  FreeText: string
  toUpdate?: boolean
};

export type ClientType = {
  ID: number | null;
  Name: string;
};

export type Contact = {
  ID: number;
  ClientID: string; // reference to client
  Firstname: string;
  Lastname: string;
  Position: string;
  Phone: string;
  Mobile: string;
  Email: string;
  Title: string;
};

export type Invoice = Omit<Doc, 'Lines'> & {
  constructionSite: ConstructionSite
  Lines: (SapLine & {OrderLineNum: number})[]
}

export type ClientOverview = {
  Offers: ConstructionSite[];
  ConstructionSites: ConstructionSite[];
  Invoices: Invoice[];
} & Client;

export type ConstructionSiteType = {
  ID: number;
  Name: string;
  Group: string;
};

export type ConstructionSite = {
  ID: number;
  IDString: string;
  Code: string;
  Name: string;
  Type?: ConstructionSiteType;
  TypeID: number;
  Address: string;
  Maps: string;
  Status: number;
  Notes: string;
  Branch?: Branch;
  BranchID: number;
  Contracts: Contract[];
  Revenue: number;
  Cost: number;
  HumanWorkCost: number;
  MaterialCost: number;
  EngineeringHours: number;
  ExternalWorkCost: number;
  ExternalWorkRevenue: number;
  HumanWorkHours: number;
  HumanWorkRevenue: number;
  MaterialRevenue: number;
  EngineeringRevenue: number;
  EngineeringCost: number;
  WarrantyRevenue: number;
  WarrantyHumanCost: number;
  WarrantyMaterialCost: number;
  Aquifer?: boolean;
  Accessible?: boolean;
  workProgress?: number
  invoiceProgress?: number
};

export type Contract = {
  ID: number;
  IDString: string;
  Name: string;
  Docs: Doc[];
  Status: number;
  Amount: number;
  Notes: string;
  Reports: DayReport[];
  FileTypes: FileType[];
  Photos: Photo[];
  WarrantyExpire?: Date;
  ConstructionSiteID: number;
  sellerEmail?: string;
  WarrantyFor?: number;
  Snapsite_folder_ID?: string
  Measure: boolean
  PublicProcurement: boolean
  measures: Measure[]
  workProgress?: number
  invoiceProgress?: number
  ExternalWorkInvoices: ExternalWorkInvoice[]
};

export const CatalogTypes = {
  Normal: 'NormalCatalogType',
  Warranty: 'WarrantyCatalogType',
  Items: 'ItemsCatalogType',
}

export enum AccountingDocumentType {
  Invoice = 'invoice',
  CreditNote = 'creditNote',
}

export type DocType = {
  ID: number;
  Name: string;
  Default: boolean;
  CatalogType: string;
  Config: {
    Update?: {
      URL: string;
      Method: string;
    };
    Create?: {
      URL: string;
      Method: string;
    };
    Get?: {
      URL: string;
      Method: string;
    };
    ConstructionSiteViewGroup: string;
    TypeDropdown: boolean;
    TypeFilter?: string
    AccountingDocument?: AccountingDocumentType
  };
};

export const ConstructionSiteViewGroups = {
  Offer: "offer",
  Order: "order",
  Invoice: "invoice",
  Warranty: "warranty",
};

export type Doc = {
  ID: number;
  IDString: string;
  Name: string;
  CatalogID: number;
  Catalog: Catalog;
  TableView: boolean;
  ContractID: number;
  Branch: Branch;
  Type: DocType;
  TypeID: number;
  PrintLevels: boolean;
  Levels: Level[];
  Lines: Line[];
  Total: number; // after discounts
  Gain: number;
  Language: Language;
  LanguageID: number;
  Currency: Currency;
  CurrencyID: string;
  BranchID: number;
  SaleEmployeeID: number;
  SaleEmployee: Seller;
  Client: Client;
  ClientDigitalDocuments: boolean;
  ClientID: string;
  ClientContact: Contact | null;
  ClientContactID: number | null;
  By: Client | null;
  ByDigitalDocuments: boolean;
  ByID: string | null;
  ByContact: Contact | null;
  ByContactID: number | null;
  OfferNamedTo: Client | null;
  OfferNamedToDigitalDocuments: boolean;
  OfferNamedToID: string | null;
  OfferNamedToContact: Contact | null;
  OfferNamedToContactID: number | null;
  InvoiceTo: Client | null;
  InvoiceToDigitalDocuments: boolean;
  InvoiceToID: string | null;
  InvoiceToContact: Contact | null;
  InvoiceToContactID: number | null;
  DocumentContacts: DocumentContact[];
  Distance: number;
  CreatedDate: string;
  UpdatedDate: string;
  CreatedBy: Resource | null;
  UpdatedBy: Resource | null;
  Status: number;
  Version: number;
  ShowLevelsEnabled: boolean;
  Versions: Version[];
  Discounts: Discount[];
  Block: boolean;
  PriceBlock: number; // before discounts
  RoundingDiff: number;
  FromContractID?: number;
  Notes: string;
  ReceivedPlansDate?: Date;
  SapDocTotal?: number;
  SapVatSum?: number;
  SapRoundDif?: number;
  Payments?: Payment[];
  OriginalOfferYear?: number
  invoiceId?: number
  ToBeChecked: boolean
};

export type Payment = {
  Amount: number;
  Date: string;
  Client: Client;
  ClientID: string;
}

export type DocumentContact = {
  ID: number;
  DocumentID: number;
  DocTypeID: number;
  ClientID: string | number | null;
  ClientContactID?: string | number | null;
  Name: string;
  Firstname?: string;
  Lastname?: string;
  Type?: DocumentContactType;
  TypeID: string | null;
  Email: string;
  Mobile: string;
  Client: Client | null;
  ClientContact: Contact | null;
  DigitalDocuments: boolean;
  Deleted?: boolean;
};

export type DocumentContactType = {
  ID: string | number;
  Name: string;
};

export type Discount = {
  name: string;
  value: number;
  isNumeric: boolean;
  totalValue: number;
};

export type Line = {
  ID: string;
  LineNum: number;
  Code: string;
  Name: string;
  InternalName: string;
  MinPrice: number;
  CatalogPrice: number;
  LineTotal: number;
  LineTotalCalculated: number;
  Cost: number;
  Quantity: number;
  HumanWorkCost: number;
  HumanWorkRevenue: number;
  MaterialCost: number;
  MaterialRevenue: number;
  UnitOfMeasure: string;
  Block: boolean;
  LineType: string; // position (external work, sell warranty, work warranty, engineering, car, normal), title, text, flat rate line,
  LineLevel: LineLevel[];
  Group: string;
  LineSumType: string;
  LineLetter: string;
  Discount: number;
  TwinIDs: string[] | null;
  FatherID: string | null;
  Variable: boolean | null;
  Items: [] | null;
  FixedPriceBlock: string | null;
  BackgroundColor?: string
  PrintTwin: boolean
};

export type LineLevel = {
  ID: number;
  Name: string;
  Quantity: number; // Cannot be smaller than ExecutedQuantity and smaller than InvoicedQuantity+NotBillableQuantity
  DoneQuantity?: number;
  DoneApprovedQuantity?: number;
  InvoicedQuantity?: number;
  BillableQuantity?: number;
  NotBillableQuantity?: number;
  SoldQuantity?: number;
  Price: number;
  Discount: number;
  DiscountNumeric: boolean;
  LineTotal: number;
  LineTotalCalculated: number;
  PriceCalculated: number;
  FixedPriceBlock: string;
  LineSumType: string; // pl/dc/ds
  Group: string;
  Hours: number;
  HumanWorkCost: number;
  MaterialCost: number;
  HumanWorkRevenue: number;
  MaterialRevenue: number;
  Vat?: VAT;
  VatID: string;
  Reports?: ReportLineLevel[]
};

export type ReportLineLevel = {
  ResourceID: string
  Resource: Resource
  ApprovedById?: string
  ApprovedBy?: Resource
  Datetime: Date
  DayReportID: string
  Quantity: number
  ApprovedQuantity?: number
  BillableQuantity?: number
  measureId?: number
}

export type Level = {
  ID: number;
  Name: string;
};

export type Item = {
  ID: string;
  IDString: string;
  InternalName: string;
  Name: string;
  UnitOfMeasure: string | null;
  Price: number | null;
  MinimumPrice: number | null;
  Cost: number | null;
  HumanWorkCost: number | null;
  HumanWorkRevenue: number | null;
  MaterialCost: number | null;
  MaterialRevenue: number | null;
  Type: string;
  FatherID: string | null;
  RangeMin: number | null;
  RangeMax: number | null;
  Special: string | null;
  TwinIDs: string[] | null;
  Variable: boolean;
  Items: Item[];
  AutoComplete: boolean;
  Hours: number;
  VAT: VAT;
  Quantity?: number; // for line.Items
  ItemType?: string;
  BackgroundColor?: string
  PrintTwin: boolean
  WorkGroup: string
};

export const ItemType = {
  WORK: "L",
  SPECIAL_MATERIAL: "TC",
  TOOLS: "a",
  MATERIAL: "p",
};

export type PositionLevel = {
  Quantity: number;
  Size: number;
}

export type Position = {
  ID: number;
  IDString: string;
  Name: string;
  ShowCompact: boolean;
  ItemsID: string[];
  IntervalType: number;
  UnitOfMeasure: string;
  UnitOfQuantity: string;
  Levels?: PositionLevel[];
  Quantity?: number;
};

export type CatalogGroup = {
  ID: number;
  IDString: string;
  Name: string;
  Positions: Position[];
};

export type Catalog = {
  ID: number;
  IDString: string;
  Name: string;
  Items: Item[];
  Groups: CatalogGroup[];
  TableView: boolean;
  Type: string;
};

export type Branch = {
  ID: number;
  Name: string;
  Maps: string;
};

export type Currency = {
  ID: string;
  Name: string;
};

export type Language = {
  ID: number;
  Name: string;
  Image: string;
};

export enum ReportClientConfirmation {
  HIDE = 0,
  OPTIONAL = 1,
  REQUIRED = 2,
}

export type User = {
  name: string;
  username: string;
  profileImage: string;
  company: string;
  branch: {
    ID: number;
    Name: string;
    Maps: string;
  };
  currency: {
    ID: string;
    Name: string;
  };
  language: {
    ID: number;
    Name: string;
    Image: string;
  };
  DefaultSaleEmployee?: Seller;
  permissions: {
    priceLevel: number;
    sales: boolean;
    salesManageBusinessPartners: number;
    salesDiscount: number;
    salesUnderMinimumPrice: number;
    salesChangeUoM: boolean;
    salesFillBoMVariable: boolean;
    salesCreateCustomBom: boolean;
    salesChangePrice: boolean;
    documentAddFile: boolean;
    orderUpdate: boolean;
    resourceAssign: number;
    resourceAssignView: number;
    reportCreate: number;
    reportApprove: number;
    reportUpdate: number;
    workClose: boolean;
    workAddActivity: boolean;
    invoiceView: number;
    invoiceCreate: number;
    reportClientConfirmation: ReportClientConfirmation
  };
  readLevel: ReadLevel;
  ShowDayReports: number;
  error: "";
  ReportEditTimeLimit: number;
  reportActivitiesLinesFilter: LineTypeM[];
  ReportCreateLimitFromWeekStart: number;
};

export type LineTypeM = typeof LineType.POSITION 
| typeof LineType.POSITION_CAR
| typeof LineType.POSITION_ENGINEERING
| typeof LineType.POSITION_SELL_WARRANTY
| typeof LineType.POSITION_WORK_WARRANTY
| typeof LineType.POSITION_EXTERNAL_WORK
| typeof LineType.FIXED_PRICE
| typeof LineType.FIXED_PRICE_ENGINEERING_WARRANTY
| typeof LineType.TEXT
| typeof LineType.ITEM
| typeof LineType.TITLE;

export type Version = {
  ID: number;
  UpdatedDate: Date;
  Version: number;
  Status: number;
};

export type SapLine = {
  ID: number;
  docID: number;
  levelID: number;
  IDString: string;
  Code: string;
  InternalName: string;
  Name: string;
  LineType: string;
  FatherID: string;
  Price: number;
  Discount: number;
  DiscountNumeric: boolean;
  CatalogPrice: number;
  MinPrice: number;
  PriceCalculated: number;
  LineTotalCalculated: number;
  LineTotal: number;
  Cost: number;
  Quantity: number;
  HumanWorkCost: number;
  MaterialCost: number;
  HumanWorkRevenue: number;
  MaterialRevenue: number;
  UnitOfMeasure: string;
  LineSumType: string;
  TwinIDs: string | null;
  FixedPriceBlock: string;
  Group: string;
  Hours: number;
  VatID?: string;
  Vat?: VAT;
  DoneQuantity?: number;
  DoneApprovedQuantity?: number;
  InvoicedQuantity?: number;
  BillableQuantity?: number;
  SoldQuantity?: number;
  NotBillableQuantity?: number;
  Reports?: SapReportLineLevel[]
  BackgroundColor?: string
  PrintTwin: boolean
};

export type SapReportLineLevel = Omit<ReportLineLevel, "Datetime"> & {
  Datetime: string
}

export type SapDoc = Doc & {
  Lines: SapLine[];
  ReceivedPlansDate?: string;
};

export interface AddressMaps {
  address: string;
  maps: string;
}

export type Marginality = {
  engineeringCost: number;
  materialCost: number;
  humanWorkCost: number;
  externalWorkCost: number;
  warrantyMaterialCost?: number;
  warrantyHumanWorkCost?: number;
  engineeringHours: number;
  humanWorkHours: number;
  minPrice: number;
  warrantyRevenue: number;
  engineeringRevenue: number;
  externalWorkRevenue: number;
  materialRevenue: number;
  humanWorkRevenue: number;
  catalogPrice: number;
  doc?: Doc;
};

export interface GetActivitiesI {
  constructionSite: ConstructionSite;
  contract: Contract;
  levels: Level[];
  activities: Activity[];
  OrderNotes: string
  DocumentContacts: DocumentContact[]
  CatalogID: number
}

export type AlertEmail = {
  Code: string;
  Type: string;
  Email: string;
  Language: string;
};

export type MeasureLevel = {
  Level: Level
  SoldQuantity: number
  DoneQuantityPrevious: number
  ApprovedQuantityPrevious: number
  BillableQuantityPrevious: number
  BilledQuantity: number
  Quantity: number
  ApprovableQuantity: number
  BillableQuantity: number
};

export type MeasureReport = {
  Report: Report;
  DayReport: DayReport;
}

export type MeasureLine = {
  MeasureReports: MeasureReport[];
  ActivityID: number;
  ActivityIDString: string;
  Name: string;
  UnitOfMeasure?: string;
  Type: string;
  Duration?: number;
  ApprovedDuration?: number;
  SoldQuantity: number
  DoneQuantityPrevious: number
  ApprovedQuantityPrevious: number
  BillableQuantityPrevious: number
  BilledQuantity: number
  Quantity: number
  ApprovableQuantity: number
  BillableQuantity: number
  Levels?: MeasureLevel[];
  BackgroundColor?: string
}

export type MeasureApprovedBilled = {
  activityId: number
  levelId: number
  quantity: number
  approved: number
  billable: number
  billed: number
}

export type Measure = {
  id?: number
  createdAt: Date
  createdById: number
  createdBy: Resource
  constructionSite: ConstructionSite;
  contract: Contract;
  DayReports: DayReport[];
  measureLines: MeasureLine[];
  order: Doc
  notes: string
  contractId: number
  invoiceId?: number
  number?: number
  approvedBilled: MeasureApprovedBilled[]
  invoices: Invoice[]
}

export type Seller = {
  ID: number;
  Name: string;
  DefaultEmailContent: string;
}

export type WeekReportAssignement = {
  TotalTime: number,
  DayReports: {
    ID: number,
    TotalTime: number,
    TravelTime: number,
    BreakTime: number,
    ContractID: number,
    ApprovedById?: number,
    ApprovedBy?: Resource,
    Contract: {
      ID: number,
      Name: string,
    },
  }[],
  ConstructionSite: ConstructionSite,
}

export type WeekReport = {
  timeTargets: {
    date: Date,
    timeTarget: number,
  }[],
  assignments: {
    ResourceID: number,
    StartDate: Date,
    EndDate: Date,
    Resource: Resource,
    Days: {
      Date: Date,
      ConstructionSites: WeekReportAssignement[],
      Absences: Absence[],
    }[],
  }[],
};

export type Industry = {
  ID: number,
  Name: string,
}

export type AbsenceType = {
  Code: string
  Name: string
  Negative: boolean
}

export type Absence = {
  Code?: string
  Type?: AbsenceType
  TypeID?: string
  Start: string
  End: string
  Time: number
  Notes?: string
  OfficeNotes?: string
  ResourceID: number
  Resource: Resource
  Date: Date
}

export enum InvoiceType {
  Normal = 'FN',
  Advance = 'FF',
  Middle = 'FI',
  Amount = 'FS',
}

export enum InvoicePrintType {
  Synthetic = 'SI',
  DetailsWithoutPrices = 'DN',
  DetailsWithPrices = 'DV',
}

export type InvoiceDoc = Doc & {
  InvoiceType: InvoiceType
  FinalInvoice: boolean
  WPSNumber: number
  PrintType: InvoicePrintType
}

export const invoiceTypeToString = (type: InvoiceType) => {
  switch (type) {
    case InvoiceType.Normal:
      return t('NORMAL_INVOICE')
    case InvoiceType.Advance:
      return t('ADVANCE_INVOICE')
    case InvoiceType.Middle:
      return t('MIDDLE_INVOICE')
    case InvoiceType.Amount:
      return t('AMOUNT_INVOICE')
  }
}

export const invoicePrintTypeToString = (type: InvoicePrintType) => {
  switch (type) {
    case InvoicePrintType.Synthetic:
      return t('SYNTHETIC')
    case InvoicePrintType.DetailsWithoutPrices:
      return t('DETAILS_WITHOUT_PRICES')
    case InvoicePrintType.DetailsWithPrices:
      return t('DETAILS_WITH_PRICES')
  }
}

export const invoiceDetailsToString = (invoice: InvoiceDoc) => {
  let r = invoiceTypeToString(invoice.InvoiceType)
  if (invoice.FinalInvoice) {
    r += ' - ' + t('FINAL_INVOICE')
  }
  if (invoice.InvoiceType == InvoiceType.Middle) {
    r += ` ${t('INVOICE_NUMBER')} ${invoice.WPSNumber}`
  }
  return `${r} (${invoicePrintTypeToString(invoice.PrintType)})`
}

export type ConstructionSitePlanned = ConstructionSite & {
  Activities: {
    ContractId: number
    activtities: Activity[]
    levels: Level[]
    Downloaded: boolean
    ExpireDate: Date
    DocumentContacts: DocumentContact[]
    OrderNotes: string
    CatalogID: number
  }[]
}

export type ContractPlanned = Contract & {
  constructionSite: ConstructionSite
  activities: Activity[]
  levels: Level[]
  DocumentContacts: DocumentContact[]
  OrderNotes: string
  CatalogID: number
}

export type StartStop = {
  uuid: string
  departureTime?: string
  dayReport: DayReport
  materials: DayReportMaterial[]
}

export type OfflineMeasure = {
  measureNumber?: number
  uuid: string
  constructionSiteCode: string
  constructionSiteName: string
  contractName: string
  date: Date
}

export type DocOfflineMetaData = {
  uuid: string
  constructionSiteCode: string
  constructionSiteName: string
  client?: string
  docTypeName?: string
  catalogName?: string
  contractName: string
  createdAt: Date
}

export type OfferToCheck = {
  ID: number
  IDString: string
  ClientName: string
  CatalogID: number
  CreatedDate: Date
  ContractID: number
  CatalogName: string
  DocTypeID: number
  DocType: DocType
  CreatedBy: Resource
  ConstructionSite: ConstructionSite
  Contract: Contract
}

export type ExternalWorkInvoice = {
  ID: number
  IDString: string
  ContractID: number
  OrderIDString: string
  Date: Date
  Total: number
  VatTotal: number
  CurrencyID: string
  Currency: Currency
  ClientID: string
  Client: Client
  ClientReference: string
  CreatedByID: number
  CreatedBy: Resource
  Notes: string
  DayReportId?: number
}
