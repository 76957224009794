import React, { useMemo } from 'react'
import { Resource } from '../../models'
import Modal from 'react-native-modal'
import { Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { FlatList } from 'react-native-gesture-handler'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import ResourceItem from './ResourceItem'
import Button from '../Button'
import getTheme from '../../constants/Colors'

interface ChooseResourcesModalProps {
  onChoose: (resources: Resource[], drivers?: Resource[]) => void
  onClose: () => void
  initResources?: Resource[]
  includeSelf?: boolean
  chooseDrivers?: boolean
  selfDriving?: boolean
}

const ChooseResourcesModal = ({
  onChoose,
  onClose,
  initResources,
  includeSelf = false,
  chooseDrivers = false,
  selfDriving = false,
}: ChooseResourcesModalProps) => {

  const theme = getTheme()

  const allResources = useSelector((state: Reducer) => state.general.resources)
  const resource = useSelector((state: Reducer) => state.general.resource)

  const resources = initResources ?? allResources

  const filteredResources = useMemo(() => {
    const r = resources.filter(r => r.Active && r.ResourceType.Operative && (r.ID !== resource?.ID || includeSelf))
    const length = resources.length % 3
    for (let i = 0; i < length; i++) {
      r.push({ID: -i-1, FirstName: '', LastName: '', ProfileImage: ''} as Resource)
    }
    return r
  }, [resources])

  const [selectedResources, setSelectedResources] = React.useState<Resource[]>((includeSelf && resource) ? [resource] : [])
  const [selectedDrivers, setSelectedDrivers] = React.useState<Resource[]>(
    (includeSelf && selfDriving && resource) ? [resource] : []
  )

  const _selectResource = (resource: Resource) => {
    if (selectedResources.find(r => r.ID == resource.ID)) {
      setSelectedResources(selectedResources.filter(r => r.ID !== resource.ID))
      setSelectedDrivers(selectedDrivers.filter(r => r.ID !== resource.ID))
    } else {
      setSelectedResources([...selectedResources, resource])
    }
  }

  const _selectDriver = (driver: Resource) => {
    if (selectedDrivers.find(d => d.ID == driver.ID)) {
      setSelectedDrivers(selectedDrivers.filter(r => r.ID !== driver.ID))
    } else {
      if (selectedResources.find(d => d.ID == driver.ID)) {
        setSelectedDrivers([...selectedDrivers, driver])
      }
    }
  }

  const _render = ({item, index}: {item: Resource, index: number}) => (
    <ResourceItem
      key={`resource-${index}-${item.ID}`}
      resource={item}
      onSelect={_selectResource}
      onSelectDriver={_selectDriver}
      defaultSelected={includeSelf && item.ID === resource?.ID}
      chooseDrivers={chooseDrivers}
      defaultSelectedDriver={includeSelf && selfDriving && item.ID === resource?.ID}
    />
  )

  const _save = () => {
    onChoose(selectedResources, selectedDrivers)
    onClose()
  }

  return (
    <Modal
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
      }}
      onBackdropPress={onClose}
      isVisible={true}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Text style={{fontSize: 24}}>{t('CHOOSE_RESOURCES')}</Text>

        <MaterialCommunityIcons
          onPress={onClose}
          name='close'
          color='black'
          size={34}
        />
      </View>

      <FlatList
        style={{marginTop: 10, flex: 1}}
        data={filteredResources}
        numColumns={3}
        renderItem={_render}
      />
      
      <View style={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}>
        <Button
          titleT='SAVE'
          onPress={_save}
          style={{
            backgroundColor: theme.mainColor,
          }}
        />
      </View>
    </Modal>
  )
}

export default ChooseResourcesModal