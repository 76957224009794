import axios from "axios";
import { Absence, AbsenceType, Resource } from "../models";
import { getTimeFromString } from "../utils/StringUtils";

export const createAbsence = async (absence: Absence): Promise<Absence> => {
  const response = await axios.post<Absence>("/absence/create", absence)

  if (response.status != 200) {
    throw response
  }

  return {
    ...response.data,
    Date: new Date(response.data.Date),
  }
}

export const createAbsences = async (type: AbsenceType, startDate: Date, endDate: Date, notes: string, officeNotes: string, resource: Resource, time: string): Promise<Absence[]> => {
  const totalTime = (getTimeFromString(time) || 0) / 60
  let absence: Absence = {
    Type: type,
    TypeID: type.Code,
    Date: startDate,
    Start: '00:00',
    End: time,
    Time: totalTime,
    Notes: notes,
    OfficeNotes: officeNotes,
    Resource: resource,
    ResourceID: resource.ID,
  }

  const absences: Absence[] = []

  let date = new Date(startDate)
  while (date <= endDate) {
    absences.push(await createAbsence({
      ...absence,
      Date: date,
    }))
    date.setDate(date.getDate() + 1)
  }

  return absences
}

export const updateAbsence = async (absence: Absence): Promise<Absence> => {
  const response = await axios.post<Absence>("/absence/update", absence)

  if (response.status != 200) {
    throw response
  }

  return {
    ...response.data,
    Date: new Date(response.data.Date),
  }
}

export const deleteAbsence = async (absence: Absence) => {
  const response = await axios.post(`/absence/delete/${absence.Code}`)

  if (response.status != 200) {
    throw response
  }

  return response.data
}