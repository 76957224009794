import React, { useState } from "react";
import { Pressable, Text, View, Switch } from "react-native";
import { t } from 'i18n-js';
import { Client, Contact, Doc } from "../../../models";
import ChooseClientModal from "../../../components/ChooseClientModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ClientNotesModal from "../../../components/ClientNotesModal";

export enum ClientOptions {
  CLIENT = "CLIENT",
  BY = "BY",
  OFFER_NAMED_TO = "OFFER_NAMED_TO",
  INVOICE_TO = "INVOICE_TO",
}

interface Props {
  option: ClientOptions;
  doc: Doc;
  setDoc: (doc: Doc) => void;
}

const setDocClient = (setDoc: (doc: Doc) => void, doc: Doc, client: Client | null, contact: Contact | null, digital: boolean, option: ClientOptions) => {
  switch (option) {
    case ClientOptions.CLIENT:
      if (client == null) {
        throw "e";
      }
      setDoc({
        ...doc,
        Client: client,
        ClientID: String(client?.ID || ""),
        ClientContact: contact,
        ClientContactID: contact?.ID || null,
        ClientDigitalDocuments: digital,
      });
      break;
    case ClientOptions.BY:
      setDoc({
        ...doc,
        By: client,
        ByID: String(client?.ID || ""),
        ByContact: contact,
        ByContactID: contact?.ID || null,
        ByDigitalDocuments: digital,
      });
      break;
    case ClientOptions.OFFER_NAMED_TO:
      setDoc({
        ...doc,
        OfferNamedTo: client,
        OfferNamedToID: String(client?.ID || ""),
        OfferNamedToContact: contact,
        OfferNamedToContactID: contact?.ID || null,
        OfferNamedToDigitalDocuments: digital,
      });
      break;
    case ClientOptions.INVOICE_TO:
      setDoc({
        ...doc,
        InvoiceTo: client,
        InvoiceToID: String(client?.ID || ""),
        InvoiceToContact: contact,
        InvoiceToContactID: contact?.ID || null,
        InvoiceToDigitalDocuments: digital,
      });
  }
}

const getDocClient = (doc: Doc, option: ClientOptions): {client: Client | null, contact: Contact | null, digital: boolean} => {
  switch (option) {
    case ClientOptions.CLIENT:
      return {
        client: doc?.Client,
        contact: doc?.ClientContact,
        digital: doc?.ClientDigitalDocuments,
      };
    case ClientOptions.BY:
      return {
        client: doc?.By,
        contact: doc?.ByContact,
        digital: doc?.ByDigitalDocuments,
      };
    case ClientOptions.OFFER_NAMED_TO:
      return {
        client: doc?.OfferNamedTo,
        contact: doc?.OfferNamedToContact,
        digital: doc?.OfferNamedToDigitalDocuments,
      };
    case ClientOptions.INVOICE_TO:
      return {
        client: doc?.InvoiceTo,
        contact: doc?.InvoiceToContact,
        digital: doc?.InvoiceToDigitalDocuments,
      };
  }
}

const DocDetailsClient = ({option, doc, setDoc}: Props) => {

  const [chooseModalVisible, setChooseModalVisible] = useState<boolean>(false)
  const [showClientNotes, setShowClientNotes] = useState<boolean>(false)
  const _openClientNotesModal = () => setShowClientNotes(true)
  const _closeClientNotesModal = () => setShowClientNotes(false)

  const {
    client,
    contact,
    digital
  } = getDocClient(doc, option);

  const _press = () => {
    setChooseModalVisible(true);
  }

  const _client = (client: Client) => {
    setDocClient(setDoc, doc, client, null, digital, option);
  }

  const _contact = (contact: Contact) => {
    setDocClient(setDoc, doc, client, contact, digital, option);
  }

  const _digital = (digital: boolean) => {
    if (option === ClientOptions.CLIENT && client == null) {
      return;
    }
    setDocClient(setDoc, doc, client, contact, digital, option);
  }

  const _remove = () => setDocClient(setDoc, doc, null, null, false, option);

  const contactName = contact != null ? `${contact.Firstname} ${contact.Lastname}` : "";
  const email = contact != null ? contact.Email : client?.Email;
  const phone = contact != null ? contact.Phone : client?.Phone;

  return (
    <View style={{paddingVertical: 6, flexDirection: 'row', alignItems: 'center'}}>
      {chooseModalVisible && <ChooseClientModal client={client}
                         contact={contact}
                         setClient={_client}
                         setContact={_contact}
                         visible={chooseModalVisible}
                         setVisible={setChooseModalVisible}/>}

      <Text style={{width: "10%"}}>{t(option)}</Text>
      <View style={{width: 50}}>
        {
          (client != null && option !== ClientOptions.CLIENT) && (
            <MaterialCommunityIcons
              onPress={_remove}
              size={24}
              name="close"
            />
          )
        }
      </View>
      <Pressable
        onPress={_press}
        style={{
          flex: 1,
          backgroundColor: 'white',
          padding: 6,
          borderRadius: 5,
          flexDirection: 'row',
        }}
      >
        {client ? (
          <>
            <Text style={{flex: 1}}>{client?.ID}</Text>
            <Text style={{flex: 2}}>{client?.Name}</Text>
            <Text style={{flex: 2}}>{contactName}</Text>
            <Text style={{flex: 2}}>{email}</Text>
            <Text style={{flex: 2}}>{phone}</Text>
          </> 
        ) : (
          <Text style={{textAlign: 'center'}}>{t("CLICK_TO_SELECT")}</Text>
        )}
      </Pressable>
      {(client?.FreeText.length || 0) > 0 && (
        <Pressable onPress={_openClientNotesModal}>
          <MaterialCommunityIcons
            name='comment'
            size={24}
            color='black'
            style={{paddingHorizontal: 5}}
          />
        </Pressable>
      )}
      <Text style={{paddingHorizontal: 6}}>{t('DIGITAL')}</Text>
      <Switch value={digital} onValueChange={_digital}/>

      {(showClientNotes && client) && (
        <ClientNotesModal
          client={client}
          onClose={_closeClientNotesModal}
        />
      )}
    </View>
  )
};

export default DocDetailsClient;