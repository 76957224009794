import React, { useState } from 'react'
import { DayReport } from '../../models'
import { useNavigation } from '@react-navigation/native'
import { ReportsScreens } from './ReportsScreens'
import { Pressable, Text, View } from 'react-native'
import { getStringFromTime } from '../../utils/StringUtils'
import Status from '../../components/Status'
import DayReportPreviewModal from '../../components/DayReportPreviewModal'
import { t } from 'i18n-js'
import { Reducer } from '../../store/reducers'
import { useSelector } from 'react-redux'

interface Props {
  dayReport: DayReport;
  borderBottom: boolean;
  close?: () => void
}

const DayReportItem = ({dayReport, borderBottom, close}: Props) => {

  const navigation = useNavigation();

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)

  const [previewModalVisible, setPreviewModalVisible] = useState<boolean>(false);

  const _closePreviewModalVisible = () => setPreviewModalVisible(false);

  const _press = () => {
    if (!serverReachable) {
      return
    }
    if (dayReport.ApprovedBy) {
      setPreviewModalVisible(true);
    } else {
      // @ts-ignore
      navigation.navigate(ReportsScreens.EDIT_REPORT, {
        dayReportId: dayReport.ID,
      })
      close && close()
    }
  }

  return (
    <View style={{borderBottomWidth: borderBottom ? 1 : 0, paddingVertical: 6}}>
      <Pressable onPress={_press}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{fontSize: 18}}>{dayReport.ConstructionSite.Name}</Text>
          <Text style={{fontSize: 14}}>{dayReport.ConstructionSite.Code + " (" + dayReport.ConstructionSite.IDString + ")"}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text>{dayReport.ConstructionSite.Address}</Text>
          <Text style={{alignItems: "center", marginRight: 9}}>
            {dayReport.ApprovedBy && t("APPROVED")}
          </Text>
          <Text style={{alignItems: "center", marginRight: 15}}>
            {dayReport.ID}
          </Text>
          <Text style={{alignItems: "center", marginRight: 15}}>
            {getStringFromTime(dayReport.TotalTime + dayReport.TravelTime - dayReport.BreakTime)}
          </Text>
          <Status status={dayReport.ConstructionSite.Status} size={"m"}/>
        </View>
        <Text style={{fontSize: 12, marginTop: 6}}>{dayReport.Contract.Name}</Text>
      </Pressable>
      {previewModalVisible && <DayReportPreviewModal dayReportId={dayReport.ID}
                                                     visible={previewModalVisible}
                                                     close={_closePreviewModalVisible} />}
    </View>
  )
}

export default DayReportItem