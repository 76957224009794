import React from 'react';
import { ScrollView, Text, View } from "react-native";
import { t } from 'i18n-js';

import { Doc, Line } from "../../../../models";
import DocumentPreviewLine from "./DocumentPreviewLine";

const DocumentPreviewLinesHeader = ({largeView}:{largeView:boolean}) => {
  return (
    <View style={{flexDirection: "row", paddingHorizontal: 7}}>
      <Text style={{flex: 3}}>{t('CODE')}</Text>
      <Text style={{flex: 1, textAlign: "center"}}>{t('QUANTITY')}</Text>
      <Text style={{flex: 1}}>{t('UNIT_OF_MEASURE')}</Text>
      <Text style={{flex: 1}}>{t('PRICE')}</Text>
      {largeView && <Text style={{flex: 1}}>{t('DISCOUNT')}</Text>}
      <Text style={{flex: 1}}>{t('LINE_TOTAL')}</Text>
      {largeView && <Text style={{flex: 1}}>{t('FLAT_RATE')}</Text>}
    </View>
  );
};

const DocumentPreviewLines = ({doc, largeView}:{doc:Doc,largeView:boolean}) => {

  const _lineMapper = (line:Line, index:number) => {
    return <DocumentPreviewLine line={line} levels={doc.Levels} blockOffer={doc.Block} largeView={largeView} index={index} key={`${line.ID}-${index}-mapperPreviewLinesLine`}/>;
  }

  return (
    <View style={{flexDirection: "column", flex: 1}}>
      <DocumentPreviewLinesHeader largeView={largeView}/>
      <ScrollView >
        {doc.Lines.map(_lineMapper)}
      </ScrollView>
    </View>
  );
}

export default DocumentPreviewLines;