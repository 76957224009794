import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CatalogTypes, DayReport, Measure, Item, MeasureLine, AccountingDocumentType } from '../../models'
import { ActivityIndicator, FlatList, Pressable, Text, TextInput, View } from 'react-native'
import { t } from 'i18n-js'
import { alert, alertConfirm, alertText } from '../../hooks/Alert'
import { FontAwesome6, MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { decimalHoursToSexagesimal } from '../../utils/reports/report-utils'
import getTheme from '../../constants/Colors'
import MeasureDetailsModal from './MeasureDetailsModal'
import { round } from '../../shared-utils/NumberUtils'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import MeasureItem from './MeasureItem'
import { logout } from '../../store/action/authAction'
import Button from '../../components/Button'
import { sentryCapture } from '../../utils/sentryCapture'
import DayReportPreviewModal from '../../components/DayReportPreviewModal'
import { minutesToHours } from '../../shared-utils/reports/report-time-utils'
import { SearchScreens } from '../search/SearchScreens'
import { itemToReport } from '../../shared-utils/reports/convert'
import MeasureTableHeader from './MeasureTableHeader'
import { canUnapproveMeasure, downloadWorkProggressStatusNew, getMeasureInvoiceCreditNoteId, removeOfflineMeasure, setApprovableQuantity, setOfflineMeasures } from '../../utils/measure/util'
import { createMeasure, deleteMeasure } from '../../api/MeasureAPI'
import MeasureInvoicePreview from '../../components/MeasureInvoicePreview'
import MeasureHelpModal from './MeasureHelpModal'

interface Props {
  initMeasure: Measure
  readOnlyInit?: boolean
  uuid?: string
}

const MeasureView = ({ initMeasure, readOnlyInit = false, uuid }: Props) => {

  const navigation = useNavigation()
  const dispatch = useDispatch()

  const theme = getTheme()

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)
  const resource = useSelector((state: Reducer) => state.general.resource)

  const token = useSelector((state: Reducer) => state.auth.token)
  const url = useSelector((state: Reducer) => state.auth.workSpace?.url)

  const [progress, setProgress] = useState<number>()
  const [showBillPreview, setShowBillPreview] = useState<boolean>(false)
  const [workProgressStatusLoading, setWorkProgressStatusLoading] = useState<boolean>(false)

  const [measure, setMeasure] = useState<Measure>(initMeasure)
  const [approvalNotes, setApprovalNotes] = useState<string>()
  const [measureLineDetailsModal, setMeasureLineDetailsModal] = useState<MeasureLine>()
  const [dayReportModal, setDayReportModal] = useState<DayReport>()

  const closeMeasureDetailsModal = () => setMeasureLineDetailsModal(undefined)
  const _closeDayReportModal = () => setDayReportModal(undefined)

  const [showHelpModal, setShowHelpModal] = useState<boolean>(false)
  const _toggleShowHelpModal = () => setShowHelpModal(s => !s)

  const _billPreview = () => setShowBillPreview(b => !b)

  const readOnly = readOnlyInit || progress != undefined

  const timer = useRef<any>(null)

  useEffect(() => {
    if (uuid) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        setOfflineMeasures(measure, uuid)
      }, 5000)
    }
  }, [measure])

  const _removeDayReport = (dayRepot: DayReport) => {
    setMeasure(
      measure => ({
        ...measure,
        DayReports: measure.DayReports.filter(d => d.ID != dayRepot.ID),
        measureLines: measure.measureLines.map(m => {
          const measureReports = m.MeasureReports.filter(mr => mr.DayReport.ID != dayRepot.ID)
          return {
            ...m,
            MeasureReports: measureReports,
            Quantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => p + v.Quantity, 0) || 0), 0),
            ApprovableQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => p + (v.ApprovedQuantity || 0), 0) || 0), 0),
            BillableQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => p + (v.BillableQuantity || 0), 0) || 0), 0),
            Levels: m.Levels?.map(l => ({
              ...l,
              Quantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => {
                if (v.LevelID === l.Level.ID) {
                  return p + (v.Quantity || 0)
                }
                return p
              }, 0) || 0), 0),
              ApprovableQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => {
                if (v.LevelID === l.Level.ID) {
                  return p + (v.ApprovedQuantity || 0)
                }
                return p
              }, 0) || 0), 0),
              BillableQuantity: measureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => {
                if (v.LevelID === l.Level.ID) {
                  return p + (v.BillableQuantity || 0)
                }
                return p
              }, 0) || 0), 0),
            })),
          }
        }),
      })
    )
  }

  const setMeasureLine = (measureLine: MeasureLine) => {
    setMeasure(measure => ({
      ...measure,
      measureLines: measure.measureLines.map(m => {
        if (m.ActivityID === measureLineDetailsModal?.ActivityID) {
          return {
            ...measureLine,
            Levels: measureLine.Levels?.map(l => {
              return {
                ...l,
                ApprovedQuantity: measureLine.MeasureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => {
                  if (v.LevelID === l.Level.ID) {
                    return p + (v.ApprovedQuantity || 0)
                  }
                  return p
                }, 0) || 0), 0),
                BillableQuantity: measureLine.MeasureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => {
                  if (v.LevelID === l.Level.ID) {
                    return p + (v.BillableQuantity || 0)
                  }
                  return p
                }, 0) || 0), 0),
              }
            }),
          }
        }
        return m
      }),
    })) // times update day reports
  }

  const setApprovableQuantityMeasure = (quantity: number, levelIndex: number, measureLineIndex: number) => {
    setMeasure(
      measure => setApprovableQuantity(measure, quantity, levelIndex, measureLineIndex)
    )
  }

  const setBillableQuantityMeasure = (quantity: number, levelIndex: number, measureLineIndex: number) => {
    const level = measure.measureLines[measureLineIndex].Levels?.[levelIndex]?.Level
    const levelsApprovedQuantity = measure.measureLines[measureLineIndex].MeasureReports.reduce((p, v) => p + (v.Report.Levels?.reduce((p, v) => {
      if (v.LevelID === level?.ID) {
        return p + (v.ApprovedQuantity || 0)
      }
      return p
    }, 0) || 0), 0)
    const billable = measure.measureLines.reduce((p, m, i) => {
      if (i === measureLineIndex) {
        return p + m.MeasureReports.reduce((p, mr) => p + (mr.Report.Levels?.reduce((p, l, i) => {
          if (i === levelIndex) {
            return p + round(quantity / levelsApprovedQuantity * (l.ApprovedQuantity || 0), 2)
          }
          return p
        }, 0) || 0), 0)
      }
      return p
    }, 0)
    const diff = quantity - billable

    // check if day reports quantity is 0, then split billable quantity over all day reports
    const check = measure.measureLines.reduce((p, m, i) => {
      if (i === measureLineIndex) {
        return p + m.MeasureReports.reduce((p, mr) => p + (mr.Report.Levels?.reduce((p, l, i) => {
          if (i === levelIndex) {
            return p + l.Quantity
          }
          return p
        }, 0) || 0), 0)
      }
      return p
    }, 0)
    if (check == 0) {
      setMeasure(measure => ({
        ...measure,
        measureLines: measure.measureLines.map((m, i) => {
          if (i === measureLineIndex) {
            return {
              ...m,
              BillableQuantity: quantity,
              MeasureReports: m.MeasureReports.map((mr, mri) => ({
                ...mr,
                Report: {
                  ...mr.Report,
                  Levels: mr.Report.Levels?.map((l, i) => {
                    if (i === levelIndex) {
                      const dayReportsWithActivity = measure
                        .DayReports
                        .filter(d => (
                          d.Reports?.filter(r => r.ActivityID === m.ActivityID).length || 0) > 0
                        )
                      return {
                        ...l,
                        BillableQuantity: round(quantity / dayReportsWithActivity.length, 2),
                      }
                    }
                    return l
                  }),
                },
              })),
            }
          }
          return m
        })
      }))
      return
    }

    setMeasure(measure => ({
      ...measure,
      measureLines: measure.measureLines.map((m, i) => {
        if (i === measureLineIndex) {
          return {
            ...m,
            BillableQuantity: m.MeasureReports.reduce((p, v, mri) => p + (v.Report.Levels?.reduce((p, l, i) => {
              if (i === levelIndex) {
                return p + round(quantity / levelsApprovedQuantity * (l.ApprovedQuantity || 0), 2) + (m.MeasureReports.length - 1 === mri ? diff : 0)
              }
              return p + (l.BillableQuantity || 0)
            }, 0) || 0), 0),
            MeasureReports: m.MeasureReports.map((mr, mri) => ({
              ...mr,
              Report: {
                ...mr.Report,
                Levels: mr.Report.Levels?.map((l, i) => {
                  if (i === levelIndex) {
                    return {
                      ...l,
                      BillableQuantity: round(quantity / levelsApprovedQuantity * (l.ApprovedQuantity || 0), 2) + (m.MeasureReports.length - 1 === mri ? diff : 0),
                    }
                  }
                  return l
                }),
              },
            }))
          }
        }
        return m
      }),
    }))
  }

  const _render = ({ item, index }: { item: MeasureLine, index: number }) => {

    return (
      <MeasureItem
        index={index}
        item={item}
        readOnly={readOnly}
        setApprovableQuantityMeasure={setApprovableQuantityMeasure}
        setBillableQuantityMeasure={setBillableQuantityMeasure}
        setMeasureDetailsModal={setMeasureLineDetailsModal}
        key={index}
      />
    )
  }

  const _renderDay = ({ item }: { item: DayReport, index: number }) => {
    const _press = () => setDayReportModal(item)
    const _remove = () => _removeDayReport(item)

    return (
      <Pressable
        onPress={_press}
        style={{
          marginTop: 6,
          borderTopColor: theme.border,
          borderTopWidth: 1,
          padding: 5,
          flexDirection: 'row',
        }}
      >
        <Text style={{ flex: 1 }}>{item.ID}</Text>
        <Text style={{ flex: 1 }}>{item.DateTime.toLocaleDateString()}</Text>
        <Text style={{ flex: 1 }}>{item.Resource.FirstName} {item.Resource.LastName}</Text>
        <Text style={{ flex: 1 }}>{item.DateTime.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})}</Text>
        <Text style={{ flex: 1 }}>{decimalHoursToSexagesimal(minutesToHours(item.TotalTime - item.BreakTime + item.TravelTime) || 0)}</Text>
        <View style={{ width: 20, alignItems: 'center', marginRight: 10 }}>
          {(item.Notes && item.Notes.length > 0) && (
            <MaterialCommunityIcons
              name='comment'
              size={20}
            />
          )}
        </View>
        {readOnly ? (
          <View style={{ width: 30 }} />
        ) : (
          <View style={{ width: 20, alignItems: 'center', marginRight: 10 }}>
            <MaterialCommunityIcons
              name='close'
              size={20}
              onPress={_remove}
            />
          </View>
        )}
      </Pressable>
    )
  }

  const _close = async () => {
    if (showBillPreview) {
      setShowBillPreview(false)
      return
    }
    if (readOnly || await alertConfirm('CLOSE', 'CLOSE_WITHOUT_SAVING')) {
      navigation.goBack()
    }
  }

  const approve = async (close: boolean) => {
    if (!resource) {
      alert('ERROR', 'MISSING_RESOURCE')
      return
    }
    if (await alertConfirm('SAVE', 'ARE_YOU_SURE_CANT_BE_UNDONE')) {
      try {
        await createMeasure(measure, setProgress)
        if (uuid) {
          removeOfflineMeasure(uuid)
          timer.current && clearTimeout(timer.current)
          timer.current = null
        }
        navigation.goBack()
      } catch (e: any) {
        if (e.response && e.response.code == 401) {
          alert('ERROR', 'YOUR_SESSION_IS_NOT_VALID')
          dispatch(logout())
        } else {
          sentryCapture(e)
          alert('ERROR_UPDATING', 'ERROR_UNAPPROVING_DAY_REPORT')
        }
      } finally {
        setProgress(undefined)
      }
    }
  }

  const _onAdd = (item: Item | Item[]) => {
    setMeasure(old => {
      const lastDayReport = old.DayReports[old.DayReports.length - 1]
      const smallestReportId = lastDayReport.Reports?.reduce((p, v) => v.ID < p ? v.ID : p, 0) || 0
      const reports = itemToReport(
        item,
        lastDayReport.Levels,
        lastDayReport.ID,
        lastDayReport.Reports?.[lastDayReport.Reports?.length - 1],
        smallestReportId,
      )
      lastDayReport.Reports = [...lastDayReport.Reports || [], ...reports]
      const measureLines: MeasureLine[] = reports.map(r => ({
        ActivityID: r.ActivityID,
        ActivityIDString: r.ActivityIDString,
        Name: r.Name,
        Type: r.Type,
        ApprovedDuration: r.ApprovedDuration,
        ApprovedQuantity: 0,
        BackgroundColor: r.BackgroundColor,
        BillableQuantity: 0,
        Quantity: 0,
        BilledQuantity: 0,
        Duration: r.Duration,
        SoldQuantity: 0,
        ApprovedQuantityPrevious: 0,
        BillableQuantityPrevious: 0,
        DoneQuantityPrevious: 0,
        UnitOfMeasure: r.UnitOfMeasure,
        Levels: r.Levels?.map(l => ({
          Level: lastDayReport.Levels.find(ll => ll.ID === l.LevelID)!,
          ApprovedQuantity: 0,
          BillableQuantity: 0,
          SoldQuantity: 0,
          ApprovedQuantityPrevious: 0,
          BillableQuantityPrevious: 0,
          DoneQuantityPrevious: 0,
          BilledQuantity: 0,
          Quantity: 0,
          ApprovableQuantity: 0,
        })),
        MeasureReports: [{ Report: r, DayReport: lastDayReport }],
        ApprovableQuantity: 0,
      }))
      return {
        ...old,
        dayReports: old.DayReports.map((value, index, array) => {
          if (index === array.length - 1) {
            return {
              ...value,
              Reports: [
                ...value.Reports || [],
                ...reports,
              ],
            }
          }
          return value
        }),
        measureLines: [
          ...old.measureLines,
          ...measureLines,
        ],
      }
    })
  }

  const _addWork = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.INSERT_POSITION, {
      onAdd: _onAdd,
      showCheckboxes: false,
      showCreate: false,
      initialCatalogId: initMeasure.DayReports?.[0]?.CatalogID,
    })
  }

  const _unApprove = async () => {
    if (await alertConfirm('UNAPPROVE', 'UNAPPROVE_MEASURE')) {
      try {
        setProgress(0)
        await deleteMeasure(measure, setProgress)
        if (uuid) {
          removeOfflineMeasure(uuid)
        }
        navigation.goBack()
      } catch (e: any) {
        if (e.response && e.response.code == 401) {
          alert('ERROR', 'YOUR_SESSION_IS_NOT_VALID')
          dispatch(logout())
        } else {
          sentryCapture(e)
          alert('ERROR_UPDATING', 'ERROR_APPROVING_DAY_REPORT')
        }
      } finally {
        setProgress(undefined)
      }
    }
  }

  const _approve = () => approve(false)
  const _approveAndClose = () => approve(true)

  const creditNoteId = useMemo(
    () => getMeasureInvoiceCreditNoteId(measure),
    [measure],
  )

  const canUnapprove = useMemo(
    () => canUnapproveMeasure(measure),
    [measure],
  )

  const _download = () => {
    if (measure.id) {
      // TODO implement - download measure by id
      alertText('Not implemented', 'We are working on this feature')
    } else {
      downloadWorkProggressStatusNew(
        url || '',
        token,
        measure.contract.ID,
        measure.constructionSite.Code,
        setWorkProgressStatusLoading,
      )
    }
  }

  const _invoice = () => {
    // TODO implement
    const idString = measure
      .invoices
      .find(i => i.Type.Config.AccountingDocument == AccountingDocumentType.Invoice
        && i.invoiceId == measure.invoiceId)?.IDString
    alertText('Not implemented', 'We are working on this feature - Invoice: ' + idString)
  }

  const _creditNote = () => {
    // TODO implement
    const idString = measure
      .invoices
      .find(i => i.Type.Config.AccountingDocument == AccountingDocumentType.CreditNote
        && i.invoiceId == measure.invoiceId)?.IDString
    alertText('Not implemented', 'We are working on this feature - Credit Note: ' + idString)
  }

  const isWarrantyOrder = measure.contract.Docs.find(d => d.Type.CatalogType == CatalogTypes.Warranty) != undefined

  return (
    <View style={{ padding: 3, flex: 1 }}>
      <View style={{ flexDirection: 'row', marginVertical: 2, alignItems: 'center' }}>
        <MaterialCommunityIcons
          size={34}
          onPress={_close}
          name='close'
          style={{ padding: 5 }}
        />
        <View style={{ marginLeft: 6, flex: 1 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold', paddingRight: 10 }}>
              {measure.constructionSite.Code}
            </Text>
            <Text style={{ fontSize: 18, flex: 1 }}>
              {measure.constructionSite.Name}
            </Text>
          </View>
          <Text style={{ fontSize: 16 }}>{measure.contract.Name}</Text>
        </View>

        {showHelpModal && (
          <MeasureHelpModal onClose={_toggleShowHelpModal} />
        )}
        <MaterialCommunityIcons
          name='help-circle'
          color='black'
          size={24}
          onPress={_toggleShowHelpModal}
        />

        {workProgressStatusLoading ? (
          <ActivityIndicator
            style={{
              paddingLeft: 10,
              paddingRight: measure.number ? 0 : 5,
            }}
          />
        ) : (
          <MaterialCommunityIcons
            name='download'
            color='black'
            size={24}
            style={{ paddingLeft: 10, paddingRight: measure.number ? 0 : 5 }}
            onPress={_download}
          />
        )}

        {measure.number && (
          <View style={{
            backgroundColor: theme.mainColor,
            alignSelf: 'stretch',
            justifyContent: 'center',
            paddingHorizontal: 15,
            marginHorizontal: 15
          }}>
            <Text style={{ fontSize: 20, color: 'white' }}>
              {measure.number}
            </Text>
          </View>
        )}

        {readOnlyInit ? (
          <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 5 }}>
            <View style={{ marginLeft: 6, marginRight: 5 }}>
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Text>{t('MEASURED_BY')}:</Text>
                <Text style={{ fontWeight: 'bold', paddingLeft: 5 }}>{measure.createdBy?.FirstName} {measure.createdBy?.LastName}</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: 5 }}>
                <Text>{t('MEASURED')}:</Text>
                <Text style={{ fontWeight: 'bold', paddingLeft: 5 }}>{measure.createdAt?.toLocaleDateString()}</Text>
              </View>
            </View>

            {canUnapprove && (
              <Button
                style={{
                  backgroundColor: theme.red,
                }}
                onPress={_unApprove}
                disabled={progress != undefined}
              >
                <MaterialCommunityIcons
                  name='delete'
                  color='white'
                  size={24}
                />
              </Button>
            )}
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {!showBillPreview && (
              <Button
                onPress={_billPreview}
                style={{
                  backgroundColor: theme.blue,
                }}
                titleT='BILL_PREVIEW'
                disabled={progress != undefined}
              />
            )}
            {isWarrantyOrder && (
              <Button
                onPress={_approveAndClose}
                style={{
                  backgroundColor: theme.blue,
                }}
                titleT='APPROVE_AND_CLOSE'
                disabled={progress != undefined}
              />
            )}
            <Button
              onPress={_approve}
              style={{
                backgroundColor: theme.mainColor,
              }}
              titleT={progress == undefined ? 'APPROVE' : undefined}
              disabled={progress != undefined || !serverReachable}
            >
              {progress != undefined && (
                <ActivityIndicator color='white' />
              )}
            </Button>
          </View>
        )}

        {measure.invoiceId && (
          <FontAwesome6
            name='file-invoice-dollar'
            color='black'
            size={24}
            style={{ paddingHorizontal: 10 }}
            onPress={_invoice}
          />
        )}

        {creditNoteId && (
          <FontAwesome6
            name='file-circle-minus'
            color='black'
            size={24}
            style={{ paddingRight: 10 }}
            onPress={_creditNote}
          />
        )}
      </View>

      {progress != undefined && (
        <View style={{ flexDirection: 'row', padding: 10 }}>
          <View style={{ flex: progress * 100, backgroundColor: theme.mainColor, paddingTop: 2 }} />
          <View style={{ flex: (1 - progress) * 100 }} />
        </View>
      )}

      {showBillPreview ? (
        <MeasureInvoicePreview
          measure={measure}
        />
      ) : (<>
        <MeasureTableHeader />
        <View style={{ paddingHorizontal: 5 }}>
          <View style={{
            borderTopWidth: 1,
            borderTopColor: theme.border,
          }} />
        </View>

        <KeyboardAwareScrollView
          style={{
            flex: 1,
            paddingHorizontal: 5,
          }}
          extraHeight={250}
        >
          <FlatList
            renderItem={_render}
            data={measure.measureLines}
            key={`measure`}
            // @ts-ignore
            listKey={`measure`}
            keyExtractor={(item, index) => `measure-${index}`}
          />
          {(!readOnly) && (
            <View style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingVertical: 5,
            }}>
              <Button
                style={{
                  backgroundColor: theme.mainColor,
                }}
                onPress={_addWork}
                titleT='ADD_WORK'
              />
            </View>
          )}
          <Text
            style={{
              fontSize: 18,
              marginTop: 12,
              fontWeight: 'bold',
            }}>{t('DAY_REPORTS')}</Text>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 6,
              borderTopColor: theme.border,
              borderTopWidth: 1,
              paddingTop: 1,
              justifyContent: 'space-between',
              paddingHorizontal: 5,
            }}
          >
            <Text style={{ flex: 1 }}>{t('NUMBER')}</Text>
            <Text style={{ flex: 1 }}>{t('DATE')}</Text>
            <Text style={{ flex: 1 }}>{t('RESOURCE')}</Text>
            <Text style={{ flex: 1 }}>{t('START_TIME')}</Text>
            <Text style={{ flex: 1 }}>{t('TOTAL_TIME')}</Text>
            <View style={{ width: 60 }} />
          </View>
          <FlatList
            renderItem={_renderDay}
            data={measure.DayReports}
            // @ts-ignore
            listKey={`measure-day`}
            key={`2measure-day`}
            keyExtractor={(item, index) => `measure-day-${index}-${item.ID}`}
          />
          <View style={{ marginVertical: 5 }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ paddingTop: 5 }}>{t('APPROVAL_NOTES')}:</Text>
              <TextInput
                style={{
                  flex: 1,
                  marginLeft: 6,
                  backgroundColor: readOnly ? theme.background : 'white',
                  borderColor: theme.border,
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 5,
                }}
                multiline={true}
                value={approvalNotes}
                onChangeText={setApprovalNotes}
                readOnly={readOnly}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </>)}
      {measureLineDetailsModal && (
        <MeasureDetailsModal
          close={closeMeasureDetailsModal}
          isOpen={measureLineDetailsModal !== undefined}
          measureLine={measureLineDetailsModal}
          setMeasureLine={setMeasureLine}
          readOnly={readOnly}
        />
      )}
      {
        dayReportModal && (
          <DayReportPreviewModal
            close={_closeDayReportModal}
            visible={dayReportModal !== undefined}
            dayReportId={dayReportModal.ID}
          />
        )
      }
    </View>
  )
}

export default MeasureView