import React, { useState } from "react";
import { ConstructionSite } from "../../../models";
import styles from "./style";
import { statusCodeToText } from "../../../constants/Status";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { t } from "i18n-js";
import { Linking, Text, View } from "react-native";
import getTheme from "../../../constants/Colors";
import EditConstructionSiteModal from "../../../components/EditConstructionSiteModal";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import AvancementProgressBar from "../../../components/AvancementProgressBar";

interface Props {
  constructionSite: ConstructionSite;
  reload: () => void;
  navigation: {
    goBack: () => void;
  }
}

const ConstructionSiteDetailsHeader = ({constructionSite, reload, navigation}: Props) => {

  const theme = getTheme();
  const sales = useSelector((state: Reducer) => state.user.permissions.sales);

  const [
    editConstructionSiteModalVisible,
    setEditConstructionSiteModalVisible
  ] = useState<boolean>(false);

  const _edit = () => {
    setEditConstructionSiteModalVisible(true);
  }

  return (
    <>
      <View
        style={[
          styles.header,
          {
            borderRightColor: statusCodeToText(constructionSite.Status).color,
            backgroundColor: theme.background,
          },
        ]}
      >
        <EditConstructionSiteModal reload={reload}
                                  visible={editConstructionSiteModalVisible}
                                  setVisible={setEditConstructionSiteModalVisible}
                                  constructionSite={constructionSite}/>
        <View style={{flex: 1, flexDirection: "row", alignItems: "center"}}>
          <MaterialCommunityIcons
            size={34}
            name="close"
            color="black"
            onPress={() => navigation.goBack()}
          />
          <View style={{flex: 1}}>
            <Text style={{flex: 1}}>
              {constructionSite.Code
                ? constructionSite.Code + " (" + constructionSite.IDString + ")"
                : constructionSite.IDString}{" "}
              - {constructionSite.Name}
            </Text>

            <Text>{constructionSite.Address}</Text>
          </View>
        </View>
        <Text style={{paddingHorizontal: 25}}>
          {t("BRANCH")}: {constructionSite.Branch?.Name}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <MaterialCommunityIcons
            size={24}
            name="map-marker"
            onPress={() => Linking.openURL(constructionSite.Maps)}
            style={{marginRight: 12}}
          />
          {
            sales && (
              <MaterialCommunityIcons
                size={24}
                color={"black"}
                name="pencil"
                onPress={_edit}
                style={{paddingTop: 2, marginHorizontal: 6}}
              />
            )
          }
          <MaterialCommunityIcons
            size={24}
            color={"black"}
            name="sync"
            onPress={reload}
            style={{paddingTop: 2, marginHorizontal: 6}}
          />
        </View>
      </View>
      <AvancementProgressBar
        workProgress={constructionSite.workProgress}
        invoiceProgress={constructionSite.invoiceProgress}
      />
    </>
  );
}

export default ConstructionSiteDetailsHeader;