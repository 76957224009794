import React, { useState } from "react";
import { Client, ClientType, Industry } from "../../models";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { t } from 'i18n-js';
import ClientTypeDropdown from "../../components/ClientTypeDropdown";
import { checkClient } from "./util";
import ErrorWarningsLister from "../../components/ErrorWarningLister";
import ClientContacts from "../../components/ClientContacts";
import ClientAddresses from "../../components/ClientAddresses";
import { alert, alertConfirm } from "../../hooks/Alert";
import ClientAddressDropdown from "../../components/ClientAddressDropdown";
import Button from "../../components/Button";
import getTheme from "../../constants/Colors";
import { StyleSheet, Text, View, TextInput } from "react-native";
import IndustryDropdown from "../../components/IndustryDropdown";
import GoogleSearchClient from "../../components/GoogleSearchClientModal";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";

interface Props {
  client: Client;
  setClient: (client: Client) => void;
  mode: ClientMode;
  save: () => void;
}

export enum ClientMode {
  CREATE = 0,
  UPDATE = 1,
}

const ClientScreen = ({client, setClient, mode, save}: Props) => {

  const navigation = useNavigation();
  const theme = getTheme();

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)

  const [changed, setChanged] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);

  const [showImportModal, setShowImportModal] = useState<boolean>(false)
  const _importModalShow = () => setShowImportModal(true)
  const _importModalClose = () => setShowImportModal(false)

  const onImportChoose = (c: Client) => {
    const addresses = [...client.Addresses]
    if (c.Addresses.length > 0) {
      addresses.push(c.Addresses[0])
    }
    setClient({
      ...client,
      Name: c.Name,
      Phone: c.Phone,
      Addresses: addresses,
    })
  }

  const _back = async () => {
    if (changed) {
      if (!await alertConfirm('CLIENT_CHANGED', 'CLIENT_CHANGED_DESC')) {
        return;
      }
    }

    navigation.goBack();
  };

  const _set = (client:Client) => {
    setChanged(true);
    setClient(client);
  }

  const _name = (name: string) => {
    _set({
      ...client,
      Name: name,
    });
  };

  const _type = (type: ClientType) => {
    _set({
      ...client,
      Type: type,
    });
  };

  const _industry = (industry: Industry) => {
    _set({
      ...client,
      Industry: industry,
      IndustryID: industry.ID,
    })
  }

  const _vat = (vat: string) => {
    _set({
      ...client,
      VAT: vat,
    });
  };

  const _fiscalCode = (fiscalCode: string) => {
    _set({
      ...client,
      FiscalCode: fiscalCode,
    });
  };

  const _email = (email: string) => {
    _set({
      ...client,
      Email: email,
    });
  };

  const _emailBillAddress = (email: string) => _set({
    ...client,
    EmailBillAddress: email,
  })

  const _phone = (phone: string) => {
    _set({
      ...client,
      Phone: phone,
    });
  };

  const _mobile = (mobile: string) => {
    _set({
      ...client,
      Mobile: mobile,
    });
  };

  const _notes = (notes: string) => _set({
    ...client,
    FreeText: notes,
  })

  const _defaultBillAddress = (addressId: string) => {
    _set({
      ...client,
      DefaultBillAddressID: addressId,
    });
  };

  const _defaultShipAddress = (addressId: string) => {
    _set({
      ...client,
      DefaultShipAddressID: addressId,
    });
  };

  const _save = async () => {
    const {
      errors,
      warnings,
    } = checkClient(client);

    setErrors(errors);
    setWarnings(warnings);

    if (errors.length > 0) {
      await alert("CLIENT_ERROR", "CLIENT_ERROR_DESC");
      return;
    }

    if (warnings.length > 0) {
      if (!await alertConfirm('CLIENT_WARNINGS', "CLIENT_WARNINGS")) {
        return;
      }
    }

    save();
  }

  const isUpdate = mode === ClientMode.UPDATE;

  return (
    <View style={{padding: 5, flex: 1}}>
      {(showImportModal && serverReachable) && (
        <GoogleSearchClient
          onClose={_importModalClose}
          onChoose={onImportChoose}
        />
      )}
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <MaterialCommunityIcons
          size={34}
          name="chevron-left"
          onPress={_back}
        />
        <Text style={{fontSize: 20, flex: 1}}>
          {isUpdate
            ? `${t("BUSINESS_PARTNER")} ${client.ID}`
            : t("NEW_BUSINESS_PARTNER")}
        </Text>
        {serverReachable && (
          <Button
            titleT='IMPORT'
            style={{backgroundColor: theme.blue, marginRight: 10}}
            _textStyle={{fontSize: 18}}
            onPress={_importModalShow}
          />
        )}
        <Button
          onPress={_save}
          style={{
            backgroundColor: theme.mainColor,
          }}
          _textStyle={{fontSize: 18}}
          titleT={isUpdate ? "UPDATE" : "CREATE"}
        />
      </View>

      <KeyboardAwareScrollView
        extraHeight={250}
        enableOnAndroid={true}
        keyboardShouldPersistTaps='handled'
      >
        <View style={{paddingBottom: 100, paddingHorizontal: 15}}>
          <ErrorWarningsLister errors={errors}
                               warnings={warnings}
                               info={[]}/>

          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={{width: 150}}>{t("TYPE")}:</Text>
            <ClientTypeDropdown setType={_type} type={client.Type || undefined}/>
            <Text style={{paddingHorizontal: 5}}>{t("INDUSTRY")}</Text>
            <IndustryDropdown setIndustry={_industry} industry={client.Industry} />
          </View>

          <View style={styles.line}>
            <Text style={styles.label}>{t('NAME')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border}]}
              value={client.Name}
              onChangeText={_name}
              maxLength={100}
            />
          </View>

          <View style={styles.line}>
            <Text style={styles.label}>{t('VAT')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border}]}
              value={client.VAT}
              onChangeText={_vat}
              maxLength={32}
            />
          </View>

          <View style={styles.line}>
            <Text style={styles.label}>{t('FISCAL_CODE')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border}]}
              value={client.FiscalCode}
              onChangeText={_fiscalCode}
              maxLength={64}
            />
          </View>

          <View style={styles.line}>
            <Text style={styles.label}>{t('EMAIL')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border}]}
              value={client.Email}
              onChangeText={_email}
              maxLength={50}
              autoCapitalize="none"
            />
          </View>

          <View style={styles.line}>
            <Text style={styles.label}>{t('BILL_EMAIL')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border}]}
              value={client.EmailBillAddress}
              onChangeText={_emailBillAddress}
              maxLength={100}
              autoCapitalize="none"
            />
          </View>

          <View style={styles.line}>
            <Text style={styles.label}>{t('PHONE')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border}]}
              value={client.Phone}
              onChangeText={_phone}
              maxLength={64}
            />
          </View>

          <View style={styles.line}>
            <Text style={styles.label}>{t('MOBILE_PHONE')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border}]}
              value={client.Mobile}
              onChangeText={_mobile}
              maxLength={64}
            />
          </View>

          <View style={{
            flexDirection: 'row',
            paddingTop: 5,
          }}>
            <Text style={styles.label}>{t('NOTES')}:</Text>
            <TextInput
              style={[styles.input, {borderColor: theme.border, height: 60}]}
              value={client.FreeText}
              onChangeText={_notes}
              maxLength={64}
              multiline={true}
            />
          </View>

          <View style={[styles.line, {paddingTop: 25}]}>
            <ClientAddressDropdown
              client={client}
              label="DEFAULT_BILL_ADDRESS"
              addressId={client.DefaultBillAddressID}
              setAddressId={_defaultBillAddress}
            />
            <ClientAddressDropdown
              client={client}
              label="DEFAULT_SHIP_ADDRESS"
              addressId={client.DefaultShipAddressID}
              setAddressId={_defaultShipAddress}
            />
          </View>

          <View style={{paddingTop: 20}}/>

          <ClientContacts client={client} setClient={_set}/>

          <View style={{paddingTop: 20}}/>

          <ClientAddresses client={client} setClient={_set}/>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  line: {
    flexDirection: 'row',
    paddingTop: 5,
    alignItems: 'center',
  },
  label: {
    marginTop: 10,
    width: 150,
  },
  input: {
    flex: 1,
    backgroundColor: 'white',
    padding: 6,
    borderRadius: 6,
  }
});

export default ClientScreen;