import React from 'react'
import { ExternalWorkInvoice } from '../../../../models'
import { Pressable, Text } from 'react-native'
import getTheme from '../../../../constants/Colors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Formatter from '../../../../utils/formatters'

interface ExternalWorkInvoiceItemProps {
  item: ExternalWorkInvoice
  index: number
  setExternalWorkInvoice: (externalWorkInvoice: ExternalWorkInvoice, close?: boolean) => void
}

const ExternalWorkInvoiceItem = ({
  item,
  index,
  setExternalWorkInvoice,
}: ExternalWorkInvoiceItemProps) => {
  
  const theme = getTheme()

  const f = Formatter.currency(item.CurrencyID).format

  const _open = () => setExternalWorkInvoice(item)

  const _create = () => {
    if (item.DayReportId == undefined) {
      setExternalWorkInvoice(item, true)
    }
  }

  return (
    <Pressable
      onPress={_open}
      style={{
        borderTopWidth: index == 0 ? 0 : 1,
        borderTopColor: theme.border,
        paddingVertical: 5,
        flexDirection: 'row',
        alignItems: 'flex-start',
      }}
    >
      <Text style={{width: 70}}>
        {item.IDString}
      </Text>
      <Text style={{flex: 1.5}}>
        {item.Client.Name}
      </Text>
      <Text style={{flex: 1}}>
        {item.ClientReference}
      </Text>
      <Text style={{flex: 1}}>
        {item.Date.toLocaleDateString()}
      </Text>
      <Text style={{flex: 1, paddingRight: 10, textAlign: 'right'}}>
        {f(item.Total - item.VatTotal)}
      </Text>
      <Text style={{flex: 1, paddingRight: 10, textAlign: 'right'}}>
        {f(item.Total)}
      </Text>
      <Text style={{flex: 1.5}}>
        {item.CreatedBy.FirstName} {item.CreatedBy.LastName}
      </Text>

      <MaterialCommunityIcons
        name={item.DayReportId ? 'file-document' : 'plus'}
        color='black'
        size={20}
        onPress={_create}
      />
    </Pressable>
  )
}

export default ExternalWorkInvoiceItem
