import * as ConstructionSitesAPI from "../../api/ConstructionSitesAPI";
import {
  CONSTRUCTION_SITES_LOADING,
  GET_CONSTRUCTION_SITES_SUCCESS,
} from "../types";
import { Reducer } from "../reducers";
import { alert } from "../../hooks/Alert";
import { logout } from "./authAction";
import { sentryCapture } from "../../utils/sentryCapture";

export const getConstructionSites = () => async (
  dispatch: ({}) => void,
  getState: () => Reducer,
) => {
  if (!getState().sync.serverReachable) {
    return
  }
  if (getState().sync.constructionSitesLoading) {
    return
  }
  setConstructionSitesLoading(true)(dispatch);
  try {
    let constructionSites = await ConstructionSitesAPI.getConstructionSites()

    dispatch({
      type: GET_CONSTRUCTION_SITES_SUCCESS,
      payload: constructionSites,
    })
  } catch (e: any) {
    if (e.response && e.response.code == 401) {
      alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
      dispatch(logout())
    } else {
      sentryCapture(e)
      alert("ERROR", "ERROR_LOADING_CONSTRUCTION_SITES")
    }
  } finally {
    setConstructionSitesLoading(false)(dispatch)
  }
}

const setConstructionSitesLoading = (
  loading: boolean,
) => (
  dispatch: ({}) => void,
) => {
  dispatch({
    type: CONSTRUCTION_SITES_LOADING,
    payload: loading,
  });
}
