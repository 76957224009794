import { SapActivity, SapDayReport, SapReport } from "../../models/sap";
import {
  Activity,
  DayReport,
  GetActivitiesI, Item,
  Level,
  LineType,
  Material,
  Report,
  ReportLevel,
  Resource
} from "../../models"
import { hoursToMinutes, minutesToHours } from './report-time-utils'
import { isValidDate } from '../date-utils'

export const sapActivitiesToActivitiesConverter = (sapActivities: SapActivity[], levels: Level[]): Activity[] => {
  const activities: Activity[] = [];

  let lastAddedCode = "";
  for (let i = 0; i < sapActivities.length; i++) {
    const sa: SapActivity = sapActivities[i];
    const a: Activity = {
      ID: sa.ID,
      Levels: [],
      FatherID: sa.FatherID,
      ActivityGroup: sa.ActivityGroup,
      Code: sa.Code,
      IDString: sa.IDString,
      Name: sa.Name,
      UnitOfMeasure: sa.UnitOfMeasure,
      DoneHours: hoursToMinutes(sa.DoneHours),
      Type: sa.Type,
      SoldHours: hoursToMinutes(sa.SoldHours),
      Materials: sa.Materials,
      UnitTime: sa.UnitTime,
      BackgroundColor: sa.BackgroundColor,
      WorkGroup: sa.WorkGroup,
      TwinIDs: (sa.TwinID?.length || 0) > 0 ? JSON.parse(sa.TwinID) : [],
      LineSumType: sa.LineSumType,
    } as Activity;
    lastAddedCode = sa.Code;

    switch (sapActivities[i].Type) {
      case LineType.POSITION:
      case LineType.POSITION_CAR:
      case LineType.POSITION_ENGINEERING:
      case LineType.POSITION_EXTERNAL_WORK:
      case LineType.POSITION_SELL_WARRANTY:
      case LineType.POSITION_WORK_WARRANTY:
        for (let j = 0; j < levels.length && lastAddedCode == sapActivities[i].Code; j++) {
          if (sapActivities[i].LevelID === levels[j].ID) {
            a.Levels?.push({
              ActivityID: sapActivities[i].ID,
              LevelID: sapActivities[i].LevelID,
              SoldQuantity: sapActivities[i].SoldQuantity,
              DoneQuantity: sapActivities[i].DoneQuantity,
              LineSumType: sapActivities[i].LineSumType,
            });
            if (i < sapActivities.length - 1 && sapActivities[i + 1].Code == lastAddedCode) {
              i++;
            }
          }
        }
        break;
    }

    activities.push(a);
  }

  return activities;
};

export const activitiesToNewReport = (getActivitiesI: GetActivitiesI, resource: Resource, startTime?: Date): DayReport => {

  var date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  const travelTime = 0
  let totalTime = 0;
  let breakTime = 0;
  if (startTime) {
    date = new Date(startTime.getTime() + travelTime * 60000)
  } else {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }
  //totalTime = (Date.now() - date.getTime()) / 60000
  const dayReport:DayReport = {
    ID: -1,
    IDString: "-1",
    Reports: [],
    Notes: "",
    ResourceID: resource.ID,
    Levels: getActivitiesI.levels,
    ApprovalNotes: "",
    ApprovedBy: undefined,
    ApprovedByID: undefined,
    BreakTime: breakTime,
    ConstructionSite: getActivitiesI.constructionSite,
    Contract: getActivitiesI.contract,
    ContractID: getActivitiesI.contract.ID,
    DateTime: date,
    Resource: resource,
    Signature: undefined,
    SignedBy: undefined,
    SignedPlace: undefined,
    SignedTime: date,
    TotalTime: totalTime,
    TravelTime: travelTime,
    OrderNotes: getActivitiesI.OrderNotes,
    DocumentContacts: getActivitiesI.DocumentContacts,
    CatalogID: getActivitiesI.CatalogID,
  };

  let travelPositions = 0;
  let id = -1;
  for (let i = 0; i < getActivitiesI.activities.length; i++) {
    if (getActivitiesI.activities[i].Type == LineType.POSITION_CAR) {
      travelPositions++;
    }
    const report: Report = {
      ID: id,
      Levels: [],
      FatherID: getActivitiesI.activities[i].FatherID,
      Type: getActivitiesI.activities[i].Type,
      IDString: String(id),
      Name: getActivitiesI.activities[i].Name,
      UnitOfMeasure: getActivitiesI.activities[i].UnitOfMeasure,
      ActivityID: getActivitiesI.activities[i].ID,
      DayReportID: -1,
      Duration: 0,
      UnitTime: getActivitiesI.activities[i].UnitTime,
      ApprovedDuration: undefined,
      LineCode: getActivitiesI.activities[i].Code,
      ActivityIDString: getActivitiesI.activities[i].IDString,
      Materials: (getActivitiesI.activities[i].Materials || []).map((m, i) => {
        return {
          ID: String(-1-i),
          MaterialID: m.ID,
          MaterialIDString: m.IDString,
          Name: m.Name,
          UnitOfMeasure: m.UnitOfMeasure,
          IDString: String(-1-i),
          Quantity: m.Quantity,
          Type: m.Type,
          UnitQuantity: m.UnitQuantity,
          ApprovedQuantity: 0,
        }
      }),
      ActivityGroup: getActivitiesI.activities[i].ActivityGroup,
      Notes: '',
      BackgroundColor: getActivitiesI.activities[i].BackgroundColor,
      WorkGroup: getActivitiesI.activities[i].WorkGroup,
      TwindIDs: getActivitiesI.activities[i].TwinIDs,
    };

    for (let j = 0; j < (getActivitiesI.activities[i].Levels||[]).length; j++) {
      const reportLevel: ReportLevel = {
        ID: id,
        LevelID: (getActivitiesI.activities[i].Levels||[])[j].LevelID,
        ActivityID: (getActivitiesI.activities[i].Levels||[])[j].ActivityID,
        LevelIndex: getActivitiesI.levels.findIndex(l => l.ID === (getActivitiesI.activities[i].Levels||[])[j].LevelID),
        BilledQuantity: undefined,
        ReportID: report.ID,
        Quantity: 0,
        BillLaterQuantity: undefined,
        InvoiceID: undefined,
        ApprovedQuantity: undefined,
        BillableQuantity: undefined,
        SoldQuantity: (getActivitiesI.activities[i].Levels||[])[j].SoldQuantity,
        DoneQuantity: (getActivitiesI.activities[i].Levels||[])[j].DoneQuantity,
        LineSumType: (getActivitiesI.activities[i].Levels||[])[j].LineSumType,
      };
      id--;
      report.Levels?.push(reportLevel);
    }

    dayReport.Reports?.push(report);
  }

  const firstCarIndex = dayReport.Reports?.findIndex(d => d.Type == LineType.POSITION_CAR) || -1
  if (firstCarIndex != -1 && (dayReport.Reports![firstCarIndex].Levels?.length || 0) > 0) {
    dayReport.Reports![firstCarIndex].Levels![0].Quantity = 1
  }
  for (let i = 0; i < dayReport.Reports!.length; i++) {
    if (dayReport.Reports![i].Type == LineType.POSITION_CAR) {
      dayReport.Reports![i].Duration = dayReport.TravelTime / travelPositions;
    }
  }

  dayReport.Reports = dayReport.Reports?.sort(
    (a, b) => a.ActivityIDString.localeCompare(b.ActivityIDString)
  )

  return dayReport;
}

export const dayReportToSapDayReportConvert = (dayReport: DayReport, levels: Level[]): SapDayReport => {
  const sapDayReport: SapDayReport = {
    ...dayReport,
    DateTime: dayReport.DateTime.toISOString(),
    SignedTime: isValidDate(dayReport.SignedTime) ? dayReport.SignedTime?.toISOString() || "" : "",
    Reports: [],
  };

  if (dayReport.Reports != undefined) {
    for (let i = 0; i < dayReport.Reports.length; i++) {
      const sapReport: SapReport = {
        ID: dayReport.Reports[i].ID,
        DayReportID: sapDayReport.ID,
        IDString: dayReport.Reports[i].IDString,
        Name: dayReport.Reports[i].Name,
        UnitOfMeasure: dayReport.Reports[i].UnitOfMeasure,
        ActivityID: dayReport.Reports[i].ActivityID,
        ActivityIDString: dayReport.Reports[i].ActivityIDString,
        FatherID: dayReport.Reports[i].FatherID,
        Type: dayReport.Reports[i].Type,
        UnitTime: dayReport.Reports[i].UnitTime,
        ApprovedDuration: dayReport.Reports[i].ApprovedDuration,
        Duration: dayReport.Reports[i].Duration,
        ActivityGroup: dayReport.Reports[i].ActivityGroup,
        Notes: dayReport.Reports[i].Notes,
        BackgroundColor: dayReport.Reports[i].BackgroundColor,
      } as SapReport;

      let quantity = (dayReport.Reports[i].Levels || []).reduce((p, v) => {
        return p + (v.Quantity || 0);
      }, 0);
      let approvedQuantity = (dayReport.Reports[i].Levels || []).reduce((p, v) => {
        return p + (v.ApprovedQuantity || 0);
      }, 0);

      for (let j = 0; j < (dayReport.Reports[i].Levels || []).length; j++) {
        const r = (dayReport.Reports[i].Levels || [])[j];
        const materials: Material[] = [];
        for (let k = 0; k < (dayReport.Reports[i].Materials || []).length; k++) {
          materials.push((dayReport.Reports[i].Materials || [])[j]);
        }


        let twinHasQuantities = false
        for (let k = 0; k < dayReport.Reports[i].TwindIDs.length; k++) {
          const twinIndex = dayReport.Reports.findIndex(r => r.LineCode == dayReport.Reports![i].TwindIDs[k])
          if (twinIndex != -1) {
            const twinQuantity = (dayReport.Reports[twinIndex].Levels || []).find(r => r.LevelID == r.LevelID)?.Quantity || 0
            if (twinQuantity > 0) {
              twinHasQuantities = true
            }
          }
        }
        if (r.ActivityID != -1 || r.Quantity != 0 || r.ApprovedQuantity != 0 || r.BillableQuantity != 0 || twinHasQuantities) {
          sapDayReport.Reports?.push({
            ...sapReport,
            ID: r.ID,
            LevelID: r.LevelID,
            ActivityID: r.ActivityID,
            ApprovedQuantity: r.ApprovedQuantity, //(r.ApprovedQuantity || 0) * r.Quantity / quantity,
            BillableQuantity: r.BillableQuantity, //(r.BillableQuantity || 0) * r.Quantity / quantity,
            BilledQuantity: r.BilledQuantity, //(r.BilledQuantity || 0) * r.Quantity / quantity,
            BillLaterQuantity: r.BillableQuantity, //(r.BillableQuantity || 0) * r.Quantity / quantity,
            InvoiceID: r.InvoiceID,
            Quantity: r.Quantity,
            Duration: sapReport.Type === LineType.POSITION_CAR && quantity === 0 ? sapReport.Duration / (dayReport.Reports[i].Levels?.length || 1) : sapReport.Duration * r.Quantity / quantity,
            ApprovedDuration: sapReport.Type === LineType.POSITION_CAR && quantity === 0 ? sapReport.Duration / (dayReport.Reports[i].Levels?.length || 1) : (sapReport.ApprovedDuration || 0) * (r.ApprovedQuantity || 0) / approvedQuantity,
            Materials: materials,
            Notes: sapReport.Notes,
            BackgroundColor: sapReport.BackgroundColor,
          } as SapReport);
        }
      }

      if (dayReport.Reports[i].Levels == undefined || (dayReport.Reports[i].Levels || []).length == 0) {
        sapDayReport.Reports?.push(sapReport);
      }
    }
  }

  return sapDayReport;
}

export const sapDayReportToDayReportConvert = (sapDayReport: SapDayReport, levels: Level[]): DayReport => {
  const dayReport:DayReport = {
    ...sapDayReport,
    DateTime: new Date(sapDayReport.DateTime),
    SignedTime: sapDayReport?.SignedTime ? new Date(sapDayReport.SignedTime) : new Date(),
    Reports: [] as Report[],
    ExternalWorkInvoice: sapDayReport.ExternalWorkInvoice != undefined ? ({
      ...sapDayReport.ExternalWorkInvoice,
      Date: new Date(sapDayReport.ExternalWorkInvoice.Date),
    }) : undefined,
  };

  if (sapDayReport.Reports !== undefined) {
    for (let i = 0; i < sapDayReport.Reports.length; i++) {
      const report: Report = {
        ID: sapDayReport.Reports[i].ID,
        DayReportID: sapDayReport.Reports[i].DayReportID,
        IDString: sapDayReport.Reports[i].IDString,
        Name: sapDayReport.Reports[i].Name,
        UnitOfMeasure: sapDayReport.Reports[i].UnitOfMeasure,
        ActivityID: sapDayReport.Reports[i].ActivityID,
        ActivityIDString: sapDayReport.Reports[i].ActivityIDString,
        FatherID: sapDayReport.Reports[i].FatherID,
        Type: sapDayReport.Reports[i].Type,
        UnitTime: sapDayReport.Reports[i].UnitTime,
        Levels: [] as ReportLevel[],
        ActivityGroup: sapDayReport.Reports[i].ActivityGroup,
        Materials: [] as Material[],
        Notes: sapDayReport.Reports[i].Notes,
        BackgroundColor: sapDayReport.Reports[i].BackgroundColor,
        LineCode: sapDayReport.Reports[i].LineCode,
        WorkGroup: sapDayReport.Reports[i].WorkGroup,
        TwindIDs: (sapDayReport.Reports[i].TwinID?.length || 0) > 0 ? JSON.parse(sapDayReport.Reports[i].TwinID) : [],
      };

      let duration = 0;
      let approvedDuration =  0;
      let lastAdded = report.ActivityIDString;

      switch (sapDayReport.Reports[i].Type) {
        case LineType.POSITION:
        case LineType.POSITION_CAR:
        case LineType.POSITION_ENGINEERING:
        case LineType.POSITION_EXTERNAL_WORK:
        case LineType.POSITION_SELL_WARRANTY:
        case LineType.POSITION_WORK_WARRANTY:
          for (let j = 0; j < levels.length && lastAdded == sapDayReport.Reports[i].ActivityIDString; j++) {
            let rl: ReportLevel;
            if (sapDayReport.Reports[i].LevelID === levels[j].ID) {
              report.Levels?.push({
                ID: sapDayReport.Reports[i].ID,
                ActivityID: sapDayReport.Reports[i].ActivityID,
                LevelIndex: levels.findIndex(l => l.ID == sapDayReport.Reports?.[i].LevelID),
                ApprovedQuantity: sapDayReport.Reports[i].ApprovedQuantity,
                BillableQuantity: sapDayReport.Reports[i].BillableQuantity,
                ReportID: report.ID,
                BilledQuantity: sapDayReport.Reports[i].BilledQuantity,
                BillLaterQuantity: sapDayReport.Reports[i].BillableQuantity,
                SoldQuantity: sapDayReport.Reports[i].SoldQuantity,
                DoneQuantity: sapDayReport.Reports[i].DoneQuantity,
                Quantity: sapDayReport.Reports[i].Quantity,
                InvoiceID: sapDayReport.Reports[i].InvoiceID,
                LevelID: sapDayReport.Reports[i].LevelID,
                LineSumType: sapDayReport.Reports[i].LineSumType,
              });
              duration += sapDayReport.Reports[i].Duration;
              approvedDuration += (sapDayReport.Reports[i].ApprovedDuration || 0);

              if (sapDayReport.Reports[i].Materials) {
                for (let k = 0; k < (sapDayReport.Reports[i].Materials||[])?.length; k++) {
                  let materialIndex = report.Materials?.findIndex(m => m.MaterialID === ((sapDayReport.Reports||[])[i].Materials || [])[k].MaterialID);
                  if (materialIndex && materialIndex >= 0) {
                    if (!(report.Materials || [])[materialIndex].Quantity) {
                      (report.Materials || [])[materialIndex].Quantity = 0;
                    }
                    (report.Materials || [])[materialIndex].Quantity = ((report.Materials || [])[materialIndex].Quantity || 0) + (((sapDayReport.Reports || [])[i].Materials || [])[k].Quantity || 0);
                  } else {
                    report.Materials?.push(((sapDayReport.Reports||[])[i].Materials || [])[k]);
                  }
                }
              }
              if (i < sapDayReport.Reports.length - 1 && sapDayReport.Reports[i + 1].ActivityIDString == lastAdded) {
                i++;
              }
            }
          }
          break;
      }

      report.ApprovedDuration = approvedDuration
      report.Duration = duration
      dayReport.Reports?.push(report);
    }
  }

  return dayReport;
};

export const itemToReport = (item: Item | Item[], levels: Level[], dayReportId: number, lastReport?: Report, smallestReportId?: number) => {

  // TODO check algorithm if works with backend
  let items: Item[];
  if (Array.isArray(item)) {
    items = item;
  } else {
    items = [item];
  }

  const reports: Report[] = [];

  const lastOk = lastReport == undefined;
  for (let i = 0; i < items.length; i++) {
    let l;
    l = levels.map((level, li) => ({...level, LevelIndex: li}));

    if (!lastOk && lastReport.ActivityIDString == items[i].IDString) {
      l = l.filter(l => lastReport.Levels?.findIndex(reportLevel => reportLevel.LevelID == l.ID) == -1)

      if (items[i].Type == LineType.TITLE || l.length == 0) {
        l = undefined;
      }
    }

    if (l) {
      reports.push({
        ID: (smallestReportId || 0) -1 - i,
        Name: items[i].Name,
        UnitOfMeasure: items[i].UnitOfMeasure || "",
        Type: items[i].Type,
        UnitTime: items[i].Hours,
        ActivityGroup: items[i].Special || "",
        ActivityID: -1 - i,
        IDString: items[i].IDString,
        Levels: l.map(level => ({
          ID: (smallestReportId || 0) -1 - i,
          ActivityID: -1,
          LevelIndex: level.LevelIndex,
          LevelID: level.ID,
          ApprovedQuantity: 0,
          BillableQuantity: 0,
          ReportID: -1 - i,
          BilledQuantity: 0,
          Quantity: 0,
          BillLaterQuantity: 0,
          DoneQuantity: 0,
          SoldQuantity: 0,
        })),
        Duration: 0,
        DayReportID: dayReportId,
        FatherID: items[i].FatherID || "",
        Materials: items[i].Items?.map((m, mi) => ({
          ID: String((smallestReportId || 0) -1 - i - mi),
          IDString: String((smallestReportId || 0) -1 - i - mi),
          Name: m.Name,
          Type: m.ItemType || '',
          UnitQuantity: m.Quantity || 0,
          Quantity: 0,
          MaterialID: m.ID,
          MaterialIDString: m.IDString,
          UnitOfMeasure: m.UnitOfMeasure || '',
          ApprovedQuantity: 0,
        })) || [],
        ActivityIDString: items[i].IDString,
        ApprovedDuration: 0,
        Notes: '',
        BackgroundColor: items[i].BackgroundColor || "",
        LineCode: items[i].ID,
        WorkGroup: items[i].WorkGroup,
        TwindIDs: items[i].TwinIDs || [],
      });
    }
  }

  return reports;
}