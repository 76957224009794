import React from "react";
import SignatureScreen, {
  SignatureViewRef,
} from "react-native-signature-canvas";
import SignatureViewProps from "./SignatureViewProps";

const SigScreen = React.forwardRef<SignatureViewRef, SignatureViewProps>(
  (props, ref) => {
    return <SignatureScreen ref={ref} {...props} />;
  }
);

SigScreen.displayName = "SignatureScreen";

export default SigScreen;
