import {
  LOGOUT,
  GET_BASIC_DATA_SUCCESS,
} from "../types";
import {
  DocumentContactType,
  DocType,
} from "../../models";

export type doc = {
  types: DocType[];
  documentContactTypes: DocumentContactType[];
};

const initialState: doc = {
  types: [],
  documentContactTypes: [],
};

export default function (state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case GET_BASIC_DATA_SUCCESS:
      return {
        ...state,
        types: action.payload.docTypes,
        documentContactTypes: action.payload.documentContactTypes,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}