import React, { useEffect, useState } from 'react'
import { ConstructionSite, Contract, Measure, Resource } from '../../models'
import { alert } from '../../hooks/Alert'
import { useNavigation } from '@react-navigation/native'
import MeasureView from './MeasureView'
import { getMeasureById } from '../../api/MeasureAPI'
import { ActivityIndicator, View } from 'react-native'
import getTheme from '../../constants/Colors'

interface Props {
  route: {
    params?: {
      id?: number
      constructionSite?: ConstructionSite
      contract?: Contract
    }
  }
}

const MeasurePreviewView = ({ route }: Props) => {

  const theme = getTheme()
  const navigation = useNavigation()

  const [progress, setProgress] = useState<number>(0)
  const [measure, setMeasure] = useState<Measure>()

  useEffect(() => {
    if (!route.params?.id || !route.params?.constructionSite || !route.params?.contract) {
      alert('ERROR', 'ERROR')
      navigation.goBack()
      return
    }

    getMeasureById(
      route.params?.contract,
      route.params?.constructionSite,
      route.params.id,
      setProgress
    ).then(setMeasure).catch(e => {
      alert('ERROR', 'ERROR')
      navigation.goBack()
    })


  }, [route.params?.id])

  if (!measure) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator
          color={theme.mainColor}
        />
        <View style={{ flexDirection: 'row', maxWidth: 300, paddingTop: 20 }}>
          <View style={{ flex: (progress * 100), backgroundColor: theme.mainColor, paddingTop: 2 }} />
          <View style={{ flex: (1 - progress) * 100, backgroundColor: theme.background }} />
        </View>
      </View>
    )
  }

  return (
    <MeasureView initMeasure={measure} readOnlyInit={true} />
  )
}

export default MeasurePreviewView