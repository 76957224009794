import React, { useState } from "react"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { ActivityIndicator, Text, View } from "react-native"
import Button from "../Button"
import { Absence, AbsenceType, Resource } from "../../models"
import { t } from "i18n-js"
import AbsenceTypeDropdown from "../AbasenceTypeDropdown/AbsenceTypeDropdown"
import DatePicker from "../DatePicker"
import TimePicker from "../TimePicker"
import { getStringFromTime, getTimeFromString } from "../../utils/StringUtils"
import getTheme from "../../constants/Colors"
import { alert } from "../../hooks/Alert"
import { createAbsence, createAbsences, deleteAbsence, updateAbsence } from "../../api/AbsenceAPI"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../store/action/authAction"
import { sentryCapture } from "../../utils/sentryCapture"
import { Reducer } from "../../store/reducers"
import { canEditAbsence, isWithinPreviousWeekLimit } from "../../utils/reports/report-utils"
import { TextInput } from "react-native-gesture-handler"
import Modal from "react-native-modal"
import BouncyCheckbox from "react-native-bouncy-checkbox"
import AbsenceDay from "./AbsenceDay"
import AbsenceDays from "./AbsenceDays"

interface Props {
  absence: Absence
  setAbsence: (absence: Absence) => void
  visible: boolean
  onClose: () => void
}

/**
 * 
 * @param props If absence.Code is null, it will be a new absence, otherwise it will be a modification
 * @returns 
 */
const AbsenceModal = ({absence, setAbsence, visible, onClose}:Props) => {

  const theme = getTheme()
  const dispatch = useDispatch()

  const resource = useSelector((state: Reducer) => state.general.resource)
  const reportApprove = useSelector((state: Reducer) => state.user.permissions.reportApprove)
  const reportCreateLimitFromWeekStart = useSelector((state: Reducer) => state.user.ReportCreateLimitFromWeekStart)
  const reportEditTimeLimit = useSelector((state: Reducer) => state.user.ReportEditTimeLimit)

  const [changed, setChanged] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [_absence, _setAbsence] = useState<Absence>(absence)

  const [allDay, setAllDay] = useState<boolean>(false)
  const [allDayStart, setAllDayStart] = useState<Date | undefined>(_absence.Date)
  const [allDayEnd, setAllDayEnd] = useState<Date | undefined>(_absence.Date)
  const [allDayTime, setAllDayTime] = useState<string>('00:00')

  const _absenceType = (absenceType: AbsenceType) => {
    setChanged(true)
    _setAbsence(a => ({
      ...a,
      Type: absenceType,
      TypeID: absenceType.Code,
    }))
  }

  const _allDay = (allDay: boolean) => {
    if (!_absence.Code) {
      setAllDay(allDay)
    }
  }

  const _notes = (notes: string) => {
    setChanged(true)
    _setAbsence(a => ({...a, Notes: notes}))
  }

  const _officeNotes = (officeNotes: string) => {
    setChanged(true)
    _setAbsence(a => ({...a, OfficeNotes: officeNotes}))
  }

  const save = () => {
    if (!_absence.Type) {
      alert('ERROR', 'PLEASE_CHOOSE_AN_ABSENCE_TYPE')
      return
    }
    if (_absence.Time < 0) {
      alert('ERROR', 'ERROR_INSERT_A_START_TIME_SMALLER_THAN_END_TIME')
      return
    }

    if (!reportApprove) {
      if (!isWithinPreviousWeekLimit(_absence.Date, reportCreateLimitFromWeekStart)) {
        alert('ERROR', 'ABSENCE_DATE_OUT_OF_LIMIT')
        return
      }
    }

    setLoading(true)
    if (_absence.Code) {
      if (!reportApprove && canEditAbsence(_absence, reportEditTimeLimit)) {
        alert('ERROR', 'ABSENCE_DATE_OUT_OF_LIMIT')
        setLoading(false)
        return
      }
      updateAbsence(_absence).then(absence => {
        setAbsence(absence)
        _setAbsence(absence)
        setChanged(false)
      }).catch(e => {
        if (e.error?.response && e.error.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR", "ERROR_UPDATING_ABSENCE");
        }
      }).finally(() => setLoading(false))
    } else {
      if (allDay) {
        createAbsences(
          _absence.Type,
          allDayStart || new Date(),
          allDayEnd || new Date(),
          _absence.Notes || '',
          _absence.OfficeNotes || '',
          absence.Resource,
          allDayTime,
        ).then(absences => {
          if (absences.length > 0) {
            setAbsence(absences[0])
          }
          onClose()
        }).catch(e => {
          if (e.error?.response && e.error.response.code == 401) {
            alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
            dispatch(logout());
          } else {
            sentryCapture(e);
            alert("ERROR", "ERROR_CREATING_ABSENCE");
          }
        }).finally(() => setLoading(false))
      } else {
        createAbsence(_absence).then(absence => {
          setAbsence(absence)
          onClose()
        }).catch(e => {
          if (e.error?.response && e.error.response.code == 401) {
            alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
            dispatch(logout());
          } else {
            sentryCapture(e);
            alert("ERROR", "ERROR_CREATING_ABSENCE");
          }
        }).finally(() => setLoading(false))
      }
    }
  }

  const remove = () => {
    if (!reportApprove) {
      if (!isWithinPreviousWeekLimit(_absence.Date, reportCreateLimitFromWeekStart)) {
        alert('ERROR', 'ABSENCE_DATE_OUT_OF_LIMIT')
        return
      }
      if (!canEditAbsence(_absence, reportEditTimeLimit)) {
        alert('ERROR', 'ABSENCE_DATE_OUT_OF_LIMIT')
        return
      }
    }
    setLoading(true)
    deleteAbsence(_absence).then(() => {
      setAbsence({} as Absence)
      onClose()
    }).catch(e => {
      if (e.error?.response && e.error.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ERROR_DELETING_ABSENCE");
      }
    }).finally(() => setLoading(false))
  }

  return (
    <>
    {loading &&
      <View style={{
          zIndex: 100,
          height: "100%",
          width: "100%",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,.7)"
        }}>
          <ActivityIndicator color={theme.mainColor} />
        </View>
      }
      <Modal
        isVisible={visible}
        onBackButtonPress={onClose}
        //onBackdropPress={onClose}
        onAccessibilityEscape={onClose}
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
          width: 400,
          alignSelf: 'center',
        }}
      >
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Text style={{fontSize: 34}}>{t("ABSENCE")}</Text>
          <MaterialCommunityIcons
            onPress={onClose}
            size={24}
            name="close"
          />
        </View>

        <AbsenceTypeDropdown
          absenceType={_absence.Type}
          setAbsenceType={_absenceType}
        />

        <Text style={{color: 'red'}}>
          {(changed && !_absence.Type) && t('PLEASE_CHOOSE_AN_ABSENCE_TYPE')}
        </Text>

        {_absence.Code == undefined && (
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}>
            <Text>{t('ALL_DAY')}</Text>
            <BouncyCheckbox
              size={25}
              fillColor={theme.mainColor}
              isChecked={allDay}
              onPress={_allDay}
            />
          </View>
        )}

        {allDay ? (
          <AbsenceDays
            startDate={allDayStart}
            endDate={allDayEnd}
            time={allDayTime}
            setStartDate={setAllDayStart}
            setEndDate={setAllDayEnd}
            setTime={setAllDayTime}
          />
        ) : (
          <AbsenceDay
            absence={_absence}
            setAbsence={_setAbsence}
            setChanged={setChanged}
          />
        )}

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10,
        }}>
          <Text>{t('RESOURCE')}</Text>
          <Text>{_absence.Resource.FirstName} {_absence.Resource.LastName}</Text>
        </View>

        <Text style={{paddingTop: 10}}>{t('NOTES')}</Text>

        <TextInput
          multiline={true}
          style={{
            flex: 1,
            backgroundColor: 'white',
            padding: 9,
            borderRadius: 6,
            borderColor: theme.border,
            borderWidth: 1,
          }}
          value={_absence.Notes}
          onChangeText={_notes}
        />

        {(reportApprove > 0 && absence.ResourceID == resource?.ID) || (reportApprove > 1) && (<>
          <Text style={{paddingTop: 10}}>{t('OFFICE_NOTES')}</Text>

          <TextInput
            multiline={true}
            style={{
              flex: 1,
              backgroundColor: 'white',
              padding: 9,
              borderRadius: 6,
              borderColor: theme.border,
              borderWidth: 1,
            }}
            value={_absence.OfficeNotes}
            onChangeText={_officeNotes}
          />
        </>)}

        <Button
          onPress={save}
          style={{
            backgroundColor: theme.mainColor,
          }}
          disabled={(!_absence.Type || _absence.Time < 0)}
          titleT={_absence.Code ? 'UPDATE_ABSENCE' : 'CREATE_ABSENCE'}
        />

        {
          _absence.Code && (
          <Button
            onPress={remove}
            style={{
              backgroundColor: theme.red,
            }}
            titleT="REMOVE"
          />
        )}
      </Modal>
    </>
  )
}

export default AbsenceModal;