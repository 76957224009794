import React, { useMemo, useState } from 'react'
import { ConstructionSite, Contract } from '../../models'
import Button from '../Button'
import Modal from 'react-native-modal'
import { ActivityIndicator, FlatList, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { getContractsWithOpenOrder, getContractsWithOrder } from './util'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
import getTheme from '../../constants/Colors'
import Status from '../Status'
import { downloadIndustrialAccountingMultiContracts } from '../../utils/measure/util'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'

interface IndustrialAccountingMultiContractsModalProps {
  constructionSite: ConstructionSite
}

const IndustrialAccountingMultiContractsModal = ({
  constructionSite,
}: IndustrialAccountingMultiContractsModalProps) => {

  const theme = getTheme()

  const workSpace = useSelector((state: Reducer) => state.auth.workSpace)
  const token = useSelector((state: Reducer) => state.auth.token)

  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedContractsIds, setSelectedContractsIds] = useState<number[]>(
    getContractsWithOpenOrder(constructionSite).map(c => c.ID)
  )

  const contracts = useMemo(
    () => getContractsWithOrder(constructionSite),
    [constructionSite],
  )

  const _toggleShow = () => setShow(!show)

  const _render = ({ item, index }: {item: Contract, index: number}) => {

    const selected = selectedContractsIds.includes(item.ID)

    const _change = () => {
      if (selected) {
        setSelectedContractsIds(
          selectedContractsIds.filter(id => id != item.ID)
        )
      } else {
        setSelectedContractsIds([
          ...selectedContractsIds,
          item.ID,
        ])
      }
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 5,
          borderTopColor: theme.border,
          borderTopWidth: index == 0 ? 0 : 1,
        }}
        key={`multi-contract-${item.ID}-${index}`}
      >
        <BouncyCheckbox
          onPress={_change}
          isChecked={selected}
          fillColor={theme.mainColor}
          iconStyle={{borderColor: theme.border, borderRadius: 10}}
          innerIconStyle={{borderColor: theme.border, borderRadius: 10}}
          disableBuiltInState
        />
        <Text style={{
          flex: 1,
          fontSize: 16,
          paddingTop: 3,
        }}>
          {item.Name}
        </Text>
        <Status
          status={item.Status}
          size='s'
          style={{
            marginTop: 2,
            alignSelf: 'flex-start',
          }}
        />
      </View>
    )
  }

  const _download = () => {
    downloadIndustrialAccountingMultiContracts(
      workSpace!.secondaryUrl,
      workSpace!.name,
      token,
      selectedContractsIds,
      `${constructionSite.Code}-${constructionSite.Name}`,
      setLoading,
    )
  }

  return (
    <>
      <Button
        titleT='INDUSTRIAL_ACCOUNTING'
        onPress={_toggleShow}
        style={{
          backgroundColor: theme.blue,
        }}
      />

      {show && (
        <Modal
          isVisible={show}
          onBackdropPress={_toggleShow}
          style={{
            padding: 15,
            borderRadius: 15,
            backgroundColor: 'white',
            width: 500,
            alignSelf: 'center',
          }}
        >
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 15,
          }}>
            <Text style={{
              fontSize: 34,
              flex: 1,
            }}>
              {t('INDUSTRIAL_ACCOUNTING')}
            </Text>

            <MaterialCommunityIcons
              name='close'
              size={34}
              color='black'
              onPress={_toggleShow}
            />
          </View>

          <Text style={{paddingBottom: 15}}>
            {t('CHOOSE_CONTRACTS')}:
          </Text>

          <FlatList
            data={contracts}
            renderItem={_render}
          />

          <View style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
            <Button
              titleT={loading ? undefined : 'DOWNLOAD'}
              style={{backgroundColor: theme.blue}}
              onPress={_download}
            >
              {loading && (
                <ActivityIndicator color='white' />
              )}
            </Button>
          </View>
        </Modal>
      )}
    </>
  )
}

export default IndustrialAccountingMultiContractsModal
