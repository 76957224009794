import React, { useMemo, useState } from "react"
import { FlatList } from "react-native"

interface NestedListProps<T> {
  parentKeyExtractor: (item: T) => string | null
  keyExtractor: (item: T) => string
  items: T[]
  item?: T
  nestedIndex?: number
  renderItem: (item: T, index: number, nestedIndex: number, showChildren?: boolean, toggleShowChildren?: () => void) => React.JSX.Element
  defaultShow?: boolean
}

const NestedList = <T,>({parentKeyExtractor, keyExtractor, items, item, nestedIndex = -1, renderItem, defaultShow = true}: NestedListProps<T>):React.JSX.Element => {

  const [showChildren, setShowChildren] = useState<boolean>(defaultShow)
  const toggleShowChildren = () => setShowChildren(s => !s)

  const children = useMemo(() => {
    if (item) {
      return items.filter(t => parentKeyExtractor(t) == keyExtractor(item))
    } else {
      return items.filter(t => parentKeyExtractor(t) == null || parentKeyExtractor(t) == '')
    }
  }, [items])

  const render = ({item}: {item: T}) => {
    return <NestedList 
      parentKeyExtractor={parentKeyExtractor}
      keyExtractor={keyExtractor}
      items={items}
      item={item}
      nestedIndex={nestedIndex + 1}
      defaultShow={defaultShow}
      renderItem={renderItem}
    />
  }

  return (
    <>
      { item && renderItem(item, 0, nestedIndex, children && showChildren, (children.length > 0) ? toggleShowChildren : undefined)}
      {showChildren && (
        <FlatList
          data={children}
          keyExtractor={(item, index) => `${keyExtractor(item)}-${index}-${nestedIndex}`}
          renderItem={render}
        />
      )}
    </>
  )
}

export default NestedList