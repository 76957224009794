import { Text, View } from "react-native";
import React from "react";

import { DocumentContact } from "../../../../models";
import DocumentPreviewClient from "./DocumentPreviewClient";

const DocumentPreviewContact = ({documentContact}:{documentContact:DocumentContact}) => {

  if (!documentContact.Client) {
    return (
      <View>
        <Text>{documentContact.Type?.Name}: {documentContact.Firstname} {documentContact.Lastname} {documentContact.Mobile} {documentContact.Email}</Text>
      </View>
    )
  }

  return (
    <DocumentPreviewClient
      text={documentContact.Type?.Name}
      client={documentContact.Client}
      contact={documentContact.ClientContact}
    />
  );
}

export default DocumentPreviewContact;