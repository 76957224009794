import React, { useEffect, useState } from "react";
import { WorkSpace } from "../../models";
import { Button, Text, TextInput, View } from "react-native";
import { t } from "i18n-js";
import getTheme from "../../constants/Colors";
import WorkSpaceCustom from "./WorkSpaceCustom";

interface Props {
  setWorkSpace: (workSpace?: WorkSpace) => void;
  workSpaces: WorkSpace[];
  workSpace?: WorkSpace;
  workSpacePlaceholder?: string;
}

const WorkSpaceInput = ({workSpaces, workSpace, setWorkSpace, workSpacePlaceholder = ""}: Props) => {

  const theme = getTheme();

  const [workSpaceName, setWorkSpaceName] = useState<string>(workSpacePlaceholder);
  const [custom, setCustom] = useState<boolean>(false);

  const [error, setError] = useState<string>("");

  const _press = () => {
    if (workSpaceName.length == 0) {
      setError(t("INSERT_AT_LEAST_ONE_CHARACTER"));
      return;
    }

    if (workSpaceName === "dev") {
      setCustom(true);
      return;
    }

    const workSpace = workSpaces.find(w => w.name === workSpaceName);

    if (!workSpace) {
      setError(t("WORKSPACE_NOT_FOUND"));
      return;
    }

    setWorkSpace(workSpace);
  }

  useEffect(() => {
    if (workSpace) {
      setWorkSpaceName(workSpace.name);
    }
  }, []);

  useEffect(() => {
    if (workSpaceName.length > 0) {
      setError("");
    }
  }, [workSpaceName]);

  const closeWorkSpaceCustom = () => setCustom(false);

  if (custom) {
    return (
      <WorkSpaceCustom
        setWorkSpace={setWorkSpace}
        back={closeWorkSpaceCustom}
      />
    );
  }

  return (
    <>
      <TextInput
        keyboardType="default"
        style={{
          borderBottomWidth: 1,
          borderBottomColor: '#555',
          paddingBottom: 2,
          fontSize: 24,
        }}
        autoCapitalize={'none'}
        autoComplete="off"
        autoFocus={true}
        placeholderTextColor={'#555'}
        value={workSpaceName}
        onChangeText={setWorkSpaceName}
        onSubmitEditing={_press}
        placeholder={t("INSERT_YOUR_WORKSPACE_NAME")}
      />
      <View style={{flexDirection: 'row', paddingTop: 10}}>
        <Text style={{color: 'red'}}>
          {error.length > 0 ? error : ""}
        </Text>

        <View style={{justifyContent: 'flex-end', flexDirection: 'row', flex: 1}}>
          <Button 
            title={t("PROCEED")} 
            color={theme.mainColor}
            onPress={_press}
          />
        </View>
      </View>
    </>
  )
};

export default WorkSpaceInput;