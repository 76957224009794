import { combineReducers } from "redux";
import authReducer, { auth } from "./authReducer";
import settingsReducer, { settings } from "./settingsReducer";
import userReducer from "./userReducer";
import generalReducer, { general } from "./generalReducer";
import docReducer, { doc } from "./docReducer";
import constructionSiteReducer, { constructionSite } from "./constructionSiteReducer";
import clientsReducer, { businessPartners } from "./clientsReducer";
import {
  User,
} from "../../models";
import syncReducer, {SyncReducer} from "./syncReducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  settings: settingsReducer,
  general: generalReducer,
  doc: docReducer,
  constructionSite: constructionSiteReducer,
  client: clientsReducer,
  sync: syncReducer,
});

export type Reducer = {
  auth: auth;
  user: User;
  settings: settings;
  general: general;
  doc: doc;
  constructionSite: constructionSite;
  client: businessPartners;
  sync: SyncReducer;
};
